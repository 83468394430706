import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'whiteLogos'
})
export class WhiteLogosPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.replace(new RegExp('\\bblack/\\b','g'), '');
  }

}
