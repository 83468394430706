<div class="item">
  <div class="item-row __top">
    <div class="item-logo">
      <div class="item-img"></div>
    </div>
    <div class="item-exw">
      <span class="item-label"></span>
      <span class="item-value"></span>
    </div>
  </div>
  <div class="item-row __middle">
    <div class="item-desc">
      <span class="item-value"></span>
      <span class="item-value"></span>
      <span class="item-value"></span>
      <span class="item-value"></span>
    </div>
  </div>
  <div class="item-row">
    <div class="item-col">
       <span class="item-label"></span>
       <span class="item-value"></span>
    </div>
    <div class="item-col">
       <span class="item-label"></span>
       <span class="item-value"></span>
    </div>
    <div class="item-col">
       <span class="item-label"></span>
       <span class="item-value"></span>
    </div>
    <div class="item-col __total">
       <span class="item-label"></span>
       <span class="item-value"></span>
    </div>
  </div>
</div>