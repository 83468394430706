<div class="nothing-found">
		<div class="info-block">
			<img *ngIf="!isArchive" width="60" height="60" src="assets/images/notification/empty-state.svg">
			<img *ngIf="isArchive" width="60" height="60" src="assets/images/notification/archive-empty.svg">
			<span>
				You Have No {{ text }} Yet!
			</span>
		</div>
</div>

<div class="nothing-found-wrap">
	<div class="notification-info-section first">
		<div class="notification-info-section__col">
			<div class="txt">
				<h2>Get ready to receive notifications from your buyers.</h2>
				<p>Buyers have the ability to notify you sellers 3 ways.</p>
				<div class="desc-row">
					<img src="assets/images/vendor-icons/32-price-drop.svg" width="32" height="32" alt="price drop" />
					<span>Waiting for Price Drops</span>
				</div>
				<div class="desc-row">
					<img src="assets/images/vendor-icons/32-back-to-stock.svg" width="32" height="32" alt="back to stock" />
					<span>Waiting for sold out items to come back to stock</span>
				</div>
				<div class="desc-row">
					<img src="assets/images/vendor-icons/32-more-inventory.svg" width="32" height="32" alt="more inventory" />
					<span>Waiting for more inventory to be Added</span>
				</div>
			</div>
		</div>
	</div>

	<div class="notification-info-section second">
		<div class="notification-info-section__col">
			<div class="txt">
				<h2>Instantly notify <br> your buyers!</h2>
				<p>By updating your listing, the below buyers will instantly receive a SMS, Email, and notifications in their HUBX buyers Portal! </p>
			</div>
		</div>
	</div>
</div>