import { Component, OnInit } from '@angular/core';
import { TopService } from '../../services/top.service';
import { BiddeskBannerService } from './biddesk-banner.service';


@Component({
  selector: 'app-biddesk-banner',
  templateUrl: './biddesk-banner.component.html',
  styleUrls: ['./biddesk-banner.component.scss']
})
export class BiddeskBannerComponent implements OnInit {

  
  constructor(public topService: TopService,
    public biddeskBannerService: BiddeskBannerService) { }

  ngOnInit(): void {
  }









}

