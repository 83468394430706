import { Component, OnInit } from '@angular/core';
import { DropdownCategoriesComponent } from '../../buyer/categories/dropdown-categories.component';

@Component({
  selector: 'bottom-nav-mobile-ext-dropdown',
  template: `
    <li [class._opened]="selectedCategoryId === 1" *ngFor="let category of itemsService.navigationTree"
        [ngClass]="{'hide': category.navName!=='Mobile Phones'&&category.navName!=='Tablets'}">
      <span class="categories-menu-l
ink" (click)="toggleSubMenu(1)">
          <i class="icon">
            <img src="../../../assets/images/icons/mobile/menu/m-close-white.svg" width="24" height="24" alt="Close">
          </i>
        <span class="label">{{ category.navName }}</span>
        <span class="count">{{ category.count }}</span>
      </span>
    <ul class="categories-menu-sub">
     <li *ngFor="let subNav of category.navChilds">
       <a class="categories-menu-link">
        <span class="label">{{ subNav.navName }}</span>
        <span class="count">{{ subNav.count }}</span>
       </a>
     </li>
     <!--<li>-->
       <!--<a class="categories-menu-link _active">-->
        <!--<span class="label">Symbian</span>-->
        <!--<span class="count">2453</span>-->
       <!--</a>-->
     <!--</li>-->
    </ul>
    </li>
    
    <!--<li [class._opened]="selectedCategoryId === 2">-->
    <!--<span class="categories-menu-link" (click)="toggleSubMenu(2)">-->
    <!--<i class="icon">-->
    <!--<img src="../../../assets/images/icons/mobile/menu/m-close-white.svg" width="24" height="24" alt="Close">-->
    <!--</i>-->
    <!--<span class="label">Tablets</span>-->
    <!--<span class="count">123</span>-->
    <!--</span>-->
    <!--<ul class="categories-menu-sub">-->
    <!--<li>-->
    <!--<a class="categories-menu-link">-->
    <!--<span class="label">Android</span>-->
    <!--<span class="count">2453</span>-->
    <!--</a>-->
    <!--</li>-->
    <!--<li>-->
    <!--<a class="categories-menu-link _active">-->
    <!--<span class="label">Symbian</span>-->
    <!--<span class="count">2453</span>-->
    <!--</a>-->
    <!--</li>-->
    <!--</ul>-->
    <!--</li>-->
  `,
  styleUrls: ['./bottom-nav-mobile.component.scss']
})

export class BottomNavMobileExtDropdown extends DropdownCategoriesComponent implements OnInit {
  selectedCategoryId = null;

  ngOnInit() {
  }

  toggleSubMenu(id: number): void {
    this.selectedCategoryId = this.selectedCategoryId === id ? null : id;
  }


}

