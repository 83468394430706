<div class="col-md-12 new-bc-titles hide-mobile">
  <div>
    <div class="row">
      <div class="col-md-6 titles">
        <ul>
          <li>
            <span>
              {{ navTitle }}
            </span>
          </li>
        </ul>
      </div>
      <div class="col-md-6 view">
        <div class="row">
          <div class="col-12 text-left">
            <button [ngClass]="{'active': topService.gridView}" class="btn left">
              <svg width="26px" height="26px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs></defs>
                <g id="icon-excel-grid-active" stroke="none" stroke-width="1" fill-rule="evenodd">
                  <path d="M17.2857143,12.7142857 L6.71428571,12.7142857 C6.31979661,12.7142857 6,13.0340823 6,13.4285714 L6,13.4285714 C6,13.8230605 6.31979661,14.1428571 6.71428571,14.1428571 L17.2857143,14.1428571 C17.6802034,14.1428571 18,13.8230605 18,13.4285714 L18,13.4285714 C18,13.0340823 17.6802034,12.7142857 17.2857143,12.7142857 Z M17.2857143,15.5714286 L6.71428571,15.5714286 C6.31979661,15.5714286 6,15.8912252 6,16.2857143 L6,16.2857143 C6,16.6802034 6.31979661,17 6.71428571,17 L17.2857143,17 C17.6802034,17 18,16.6802034 18,16.2857143 L18,16.2857143 C18,15.8912252 17.6802034,15.5714286 17.2857143,15.5714286 Z M6.71428571,11.2857143 L17.2857143,11.2857143 C17.6802034,11.2857143 18,10.9659177 18,10.5714286 L18,10.5714286 C18,10.1769395 17.6802034,9.85714286 17.2857143,9.85714286 L6.71428571,9.85714286 C6.31979661,9.85714286 6,10.1769395 6,10.5714286 L6,10.5714286 C6,10.9659177 6.31979661,11.2857143 6.71428571,11.2857143 Z M6.71428571,8.42857143 L17.2857143,8.42857143 C17.6802034,8.42857143 18,8.10877482 18,7.71428571 L18,7.71428571 C18,7.31979661 17.6802034,7 17.2857143,7 L6.71428571,7 C6.31979661,7 6,7.31979661 6,7.71428571 L6,7.71428571 C6,8.10877482 6.31979661,8.42857143 6.71428571,8.42857143 Z"
                    id="Shape" fill-rule="nonzero"></path>
                </g>
              </svg> Detailed View</button>
            <button [ngClass]="{'active': topService.gridView}" class="btn right">
              <svg width="26px" height="26px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs></defs>
                <g id="icon-listings-copy" stroke="none" stroke-width="1" fill-rule="evenodd">
                  <path d="M6.80537587,17 L6.80537587,15.3076923 L17.1538462,15.3076923 C17.621164,15.3076923 18,15.6865283 18,16.1538462 L18,16.1538462 C18,16.621164 17.621164,17 17.1538462,17 L6.80537587,17 Z M6.86813187,17 L6.84615385,17 C6.37883598,17 6,16.621164 6,16.1538462 L6,16.1538462 C6,15.6865283 6.37883598,15.3076923 6.84615385,15.3076923 L6.86813187,15.3076923 C7.33544973,15.3076923 7.71428571,15.6865283 7.71428571,16.1538462 L7.71428571,16.1538462 C7.71428571,16.621164 7.33544973,17 6.86813187,17 Z M7.71428571,12 L7.71428571,12 C7.71428571,12.4673179 7.33544973,12.8461538 6.86813187,12.8461538 L6.84615385,12.8461538 C6.37883598,12.8461538 6,12.4673179 6,12 L6,12 C6,11.5326821 6.37883598,11.1538462 6.84615385,11.1538462 L6.86813187,11.1538462 C7.33544973,11.1538462 7.71428571,11.5326821 7.71428571,12 Z M6.80537587,11.1538462 L17.1538462,11.1538462 C17.621164,11.1538462 18,11.5326821 18,12 L18,12 C18,12.4673179 17.621164,12.8461538 17.1538462,12.8461538 L6.80537587,12.8461538 L6.80537587,11.1538462 Z M7.71428571,7.84615385 L7.71428571,7.84615385 C7.71428571,8.31347171 7.33544973,8.69230769 6.86813187,8.69230769 L6.84615385,8.69230769 C6.37883598,8.69230769 6,8.31347171 6,7.84615385 L6,7.84615385 C6,7.37883598 6.37883598,7 6.84615385,7 L6.86813187,7 C7.33544973,7 7.71428571,7.37883598 7.71428571,7.84615385 Z M6.80537587,7 L17.1538462,7 C17.621164,7 18,7.37883598 18,7.84615385 L18,7.84615385 C18,8.31347171 17.621164,8.69230769 17.1538462,8.69230769 L6.80537587,8.69230769 L6.80537587,7 Z"
                    id="Combined-Shape" fill-rule="nonzero"></path>
                </g>
              </svg> Excel View</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #defaultTopList>
    <div *ngIf="!topService.loading">
      <div class="row">
        <div class="col-md-8 titles">
          <ul *ngIf="!leftCategoriesService.isManufacturerSelected">
            <li>
              <span> {{ itemsService.selectedTopAttribute }} </span>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div class="col-sm-12 hide-desktop titles-mobile">
  <div class="row">
    <div class="col-12">
      <span *ngIf="navTitle && navTitle.length>42">{{navTitle | slice:0:39}}... ({{itemsService.itemsData ? itemsService.itemsData.length : 0}})</span>
      <span *ngIf="navTitle && navTitle.length<=42">{{navTitle}} ({{itemsService.itemsData ? itemsService.itemsData.length : 0}})</span>
    </div>
  </div>
</div>
