<div class="notification-center-wrapper-all" [class.dashboard]="isFromDashboard">
    <app-notification-header (exportNotifications)="exportNotifications(payload)" (manufacturerFilter)="onManufacturerFilter($event)" (search)="onSearch($event)"
                             [exporting]="exporting"
                             [indeterminate]="notificationBulkActionState.total > notificationBulkActionState.selected && notificationBulkActionState.selected"
                             [allSelected]="notificationBulkActionState.total == notificationBulkActionState.selected && notificationBulkActionState.selected"
                             (selectAll)="selectUnselectAll($event)"
                             *ngIf="!isFromDashboard"></app-notification-header>

    <div class="row align-items-center remove-margins" *ngIf="!isFromDashboard && showNewItemsBtn()"
      [class.hiding-btn]="hideMoreBtn || (showNewItems.value === 0 || activeTab !== showNewItems.tab)">
      <div class="col show-items">
        <a role="button" class="show-new-item"
          [class.showing-btn]="showNewItemsBtn()"
          (click)="showNewItemsBtnClicked()">
          <span>
            Show {{ showNewItems.value }} More
          </span>
        </a>
      </div>
    </div>

    <div
    *ngIf="notifications.length > 0 && isFromDashboard && !loading"
    class="notification-items-wrapper __dashboard">
    <virtual-scroller [items]="notifications"
        (vsEnd)="onScrollDown($event)"
        [scrollAnimationTime]="0"
        #scrollNotifications>
        <ng-container *ngFor="let notification of scrollNotifications.viewPortItems; let i = index">
          <app-notification [notification]="notification" (openNotificationPopup)="openNotificationPopup($event, i)" [isFromDashboard]="isFromDashboard" (updateTab)="updateAfterVendorAction()" [isSelected]="selectedNotificationIndex===i">
          </app-notification>
        </ng-container>
        <app-dashboard-notification-preloader [class.hide]="!onScrollLoading"></app-dashboard-notification-preloader>
    </virtual-scroller>
  </div>
  

    <div
      *ngIf="notifications.length > 0 && !isFromDashboard && !loading"
      class="notification-items-wrapper"
      [class.__bottom-controls]="( notificationBulkActionState.selected || payload.pagination.totalCount > payload.pagination.pageSize ) && !isFromDashboard"
      [style.top]="getItemsWrapperTop()"
      [style.height]="getItemsWrapperHeight()">
      <div *ngFor="let notification of notifications; let i = index">
        <app-notification [notification]="notification" (openNotificationPopup)="openNotificationPopup($event, i)" [isFromDashboard]="isFromDashboard" (updateTab)="updateAfterVendorAction()" (selected)="onSelect($event)" [isSelected]="selectedNotificationIndex===i">
        </app-notification>
      </div>
    </div>

    <app-vendor-items-preloader [source]="!isFromDashboard ? 'notifications' : 'dashboardNotifications'" *ngIf="loading"></app-vendor-items-preloader>
    
    <div class="notification-bottom-controls" *ngIf="( notificationBulkActionState.selected || payload.pagination.totalCount > payload.pagination.pageSize ) && !isFromDashboard">

        <div class="selected-notifications-wrap" *ngIf="notificationBulkActionState.selected">
          <div class="selected-notifications">
            <div class="selected-notifications__left">
               <div class="selected-notifications-summary">
                <div>
                  <span>{{notificationBulkActionState.selected}}</span>
                </div>
                 selected
               </div>
               <button type="button" class="selected-notifications-btn" (click)="selectUnselectAll(false)">Deselect</button>
             </div>

             <div class="selected-notifications__actions">
              <button type="button" class="selected-notifications-btn _has-icon" (click)="exportSelectedNotifications()">
                 <i *ngIf="exporting" class="loader"></i>
                 <img *ngIf="!exporting" src="assets/images/notification-center/vendor-select-items/export.svg" width="24" height="24" alt="archive" />
                 Export
               </button>
               <button type="button" class="selected-notifications-btn _has-icon" (click)="archiveSelected()" *ngIf="activeTab!==4">
                 <img src="assets/images/notification-center/vendor-select-items/archive.svg" width="24" height="24" alt="archive" />
                 Archive
               </button>
             </div>
          </div>
        </div>

        <div class="notification-bottom-controls-pagination" *ngIf="payload.pagination.totalCount > payload.pagination.pageSize">
          <pagination
            [totalItems]="payload.pagination.totalCount"
            [ngModel]="payload.pagination.pageNumber"
            [itemsPerPage]="payload.pagination.pageSize" (pageChanged)="pageChanged($event)"
            [maxSize]="5" [boundaryLinks]="true">
          </pagination>
        </div>

    </div>

    <!-- Notification Modal -->
    <div class="notification-modal" *ngIf="isNotificationPopupShown && selectedNotification">
      <div class="notification-modal-scroll">
        <div
          class="notification-modal-wrap animated"
          [class.zoomIn]="isNotificationPopupShown"
          (click)="clickedInside($event)">
          <app-notification-popup
          [notification]="selectedNotification"
          [errorModal]="errorModal"
          (updateTab)="updateAfterVendorAction()"
          (closeModal)="hideNotificationPopup()">
          </app-notification-popup>
        </div>
      </div>
    </div>

    <div
      *ngIf="notifications.length < 1 && !loading && !isFromDashboard"
      class="nothing-found-wrapper"
      [style.top]="getItemsWrapperTop()"
      [style.height]="getItemsWrapperHeight()">
      <app-notification-helper></app-notification-helper>
    </div>

</div>

<app-error-modal
  [title]="'Error'"
  [isError]="errorModal.isError400"
  [bodyText]="errorModal.errorMessage400"
  (onConfirmError)="hideErrorAlert()">
</app-error-modal>
