import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

@Component({
  selector: 'app-mobile-coming-soon',
  templateUrl: './mobile-coming-soon.component.html',
  styleUrls: ['./mobile-coming-soon.component.scss']
})
export class MobileComingSoonComponent implements OnInit {

  constructor(
    private router: Router
    ) { }

  ngOnInit(): void {
  }

  gotoHome(): void {
    this.router.navigate(['buyer/home']);
  }

}
