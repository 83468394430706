<div class="saved-searches-modal" [class.mobile]="topService.isMobile">
  <div class="saved-searches">
    <div class="saved-searches__header">
      <a role="button" routerLink="/user/filters">
        Saved Searches
      </a>
    </div>
    <div class="loader" *ngIf="userFilterService.loading"></div>
    <ul class="saved-searches-list">
      <li class="saved-searches-list-item" *ngFor="let filter of userFilterService.filteredUserList$ | async"
      (click)="navigateToItems(filter)">
        <i class="saved-searches-list-item__icon"></i>
        <div class="saved-searches-list-item__label">
          <b>{{filter.name}}</b>
          <span>{{filter.redableText}}</span>
        </div>
      </li>
    </ul>
  </div>
</div>
