import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IVendorItemInfo } from '../../interface/IVendorItemInfo';
import { UtilityService } from '../../../core/utility.service';
import { VendorService } from '../../vendor.service';

@Component({
  selector: 'app-item-analytics',
  templateUrl: './item-analytics.component.html',
  styleUrls: ['./item-analytics.component.scss']
})
export class ItemAnalyticsComponent implements OnInit {
  @Input() item: IVendorItemInfo;
  @Output() editDetailedMode = new EventEmitter<IVendorItemInfo>();
  loader = false;
  addHighlight = false;

  constructor(private utilityService: UtilityService, public vendorService: VendorService) { }

  ngOnInit() {
  }

  openEditDetailedMode(): void {
    this.item.selected = !this.item.selected;
    this.editDetailedMode.emit(this.item);
  }

  updateRowStyle(isHighlight: boolean): void {
    this.addHighlight = isHighlight;
  }

  copyText(val: string): void {
    this.utilityService.copyTextToClipboard(val);
    // this.copied = 'Copied!';
  }
}
