import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IVendorItemComment } from '../../interface/IVendorItemComment';
import * as _ from 'lodash';
import { VendorService } from '../../vendor.service';
import { filter } from 'rxjs-compat/operator/filter';
import { FormGroup, FormBuilder, FormArray, AbstractControl } from '@angular/forms';
import { ItemDetailedComponent } from '../item-detailed/item-detailed.component';
import { ItemService } from '../item.service';

@Component({
  selector: 'app-item-comment',
  templateUrl: './item-comment.component.html',
  styleUrls: ['./item-comment.component.scss']
})
export class ItemCommentComponent implements OnInit {
  @Input() fg: FormGroup;
  @Output() closeModal = new EventEmitter();
  showInfoModal = false;
  comments: IVendorItemComment[];
  newCommentsFG: FormGroup;

  constructor(private fb: FormBuilder,
    private itemService: ItemService) {}

  ngOnInit() {
    this.comments = this.fg.getRawValue().comments;
    this.newCommentsFG = this.fb.group({commentsArray:
      this.itemService.getCommentsArrayControls(this.comments)
    });
  }

  patchComment() {
    const newValues = this.newCommentsFG.getRawValue().commentsArray;
    this.fg.controls.comments.patchValue(newValues);
    this.closeCommentModal();
  }

  closeCommentModal(): void {
    this.closeModal.emit();
  }
}
