import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ICreditCard, ICreditCardRequest } from './../../shared/interfaces/ICreditCard';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class CreditCardService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  public selectedCreditCard: ICreditCard;
  public creditCardList: ICreditCard[];

  constructor(private http: HttpClient, private router: Router) { }

  getCreditCardList(): Observable<ICreditCard[]> {
    const url = environment.notificationCenterUrl + '/api/creditcards';
    this.creditCardList = [];
    return this.http.get(url, { headers: this.headers }).pipe(
      map((res: any) => {
        if (res.cards?.length) {
          res.cards.forEach(_cc => {
            this.creditCardList.push({
              id: _cc.cardId,
              nickname: _cc.cardName,
              type: _cc.cardType,
            } as ICreditCard);
          });
        }

        return this.creditCardList;
      })
    );
  }

  getCreditCard(creditCardId: string): Observable<ICreditCardRequest> {
    const url = environment.notificationCenterUrl + `/api/creditcards/${creditCardId}`;
    return this.http.get<ICreditCardRequest>(url, { headers: this.headers });
  }

  createCreditCard(cardRequest: ICreditCardRequest): Observable<ICreditCard> { // save and return the card
    const url = environment.notificationCenterUrl + '/api/creditcards/create';
    let card: ICreditCard = null;
    return this.http.post(url, cardRequest, { headers: this.headers }).pipe(
      map((cardResponse: any) => {
        if (cardResponse) {
          card = {
            id: cardResponse.cardId,
            nickname: cardResponse.cardName,
            type: cardResponse.cardType,
            error: cardResponse.error
          } as ICreditCard;
        }
        return card;
      })
    );
  }

  updateCreditCard(cardRequest: ICreditCardRequest, cardId: string): Observable<ICreditCard> {
    const url = environment.notificationCenterUrl + `/api/creditcards/${cardId}/update`;
    let card: ICreditCard = null;
    return this.http.put(url, cardRequest, { headers: this.headers }).pipe(
      map((cardResponse: any) => {
        if (cardResponse) {
          card = {
            id: cardResponse.card.cardId,
            nickname: cardResponse.card.cardName,
            type: cardResponse.card.cardType,
            error: cardResponse.error
          } as ICreditCard;
        }
        return card;
      })
    );
  }

  deleteCreditCard(creditCardId: string): Observable<any> {
    const url = environment.notificationCenterUrl + `/api/creditcards/${creditCardId}/delete`;
    return this.http.delete(url, { headers: this.headers });
  }

  verifyCreditCardCode(ccv: string, cardId: string): Observable<any> {
    const url = environment.notificationCenterUrl + `/api/creditcards/${cardId}/validation`;
    const param = { 'CardCCV': ccv };
    return this.http.post(url, param, { headers: this.headers });
  }

  verifyUrl(): string {
    let url = '';
    if (this.router.url === '/user/credit_cards') {
      url = 'user-profile';
    } else if (['/cart', '/cart?updatingOrder=Y'].includes(this.router.url)) {
      url = 'cart';
    }
    return url;
  }

  checkCreditCardListEmpty() {
    const url = environment.notificationCenterUrl + '/api/creditcards/count';
    return this.http.get(url, { headers: this.headers });
  }

}
