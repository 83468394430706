import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { VendorService } from '../../vendor.service';
import { IPo } from '../../interface/IPo';
import { IPoStatus } from '../../interface/IPoStatus';
import { SharedService } from '../../../shared/shared.service';
import { IDropdown } from '../../interface/IDropdown';
import { IPOSearchFilter } from '../../interface/IPOSearchFilter';
import { Subscription } from 'rxjs';
import { PoSortCondition } from '../../enums/po-sort-condition';
import { IPoTrackingInformationRequest } from '../../interface/IPoTrackingTrackingInformationRequest';
import * as _ from 'lodash';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { IPoTracking } from '../../interface/IPoTracking';
import { filter, pairwise } from 'rxjs/operators';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';
import { LoaderService } from '../../../shared/services/loader.service';

@Component({
  selector: 'app-po',
  templateUrl: './po.component.html',
  styleUrls: ['./po.component.scss']
})
export class PoComponent implements OnInit, OnDestroy {
  loading = false;
  purchaseOrders: IPo[] = [];
  pageSize = 100;
  paginationMaxSize = 5;
  poPayload: IPOSearchFilter;
  poSortEnum = PoSortCondition;
  poTotal = 0;
  poSearched = false;
  poStatusList: IPoStatus[] = [{ name: 'All' }, { name: 'Open' }, { name: 'Closed' }];
  poSelectedStatus: IPoStatus = { name: 'All' };
  showTrackingModal = false;
  currentPo: IPo;
  form: FormGroup;
  formErrors: any;
  subscriptions: Subscription[] = [];
  paginationSubscription: Subscription;
  previousUrl: string;
  docEntryToHighlight;
  trackingInformationRequest: IPoTrackingInformationRequest = {
    vendorInvoiceNo: null,
    shippingInformation: {}
  };
  selectedStatusName = this.poSelectedStatus.name;

  constructor(
    private elemR: ElementRef,
    private formBuilder: FormBuilder,
    private vendorService: VendorService,
    private router: Router,
    private sharedService: SharedService,
    private pubSubService: PubSubService,
    private loaderService: LoaderService
  ) {
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.vendorService.previousUrl = events[0].urlAfterRedirects;
      });
  }

  ngOnInit() {
    this.poPayload = this.getDefaultPOPayload();
    this.comingFromPoDetail();
    this.subscribeToCustomerVendorChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.paginationSubscription.unsubscribe();
  }

  subscribeToCustomerVendorChanges() {
    this.subscriptions.push(
      this.pubSubService.sharedSubject.subscribe(data => {
        if (data.name === SharedSource.changeCustomerVendor) {
          this.purchaseOrders = [];
          this.poPayload = this.getDefaultPOPayload();
          this.getPurchaseOrders();
        }
      })
    );
  }

  getDefaultPOPayload(searchText?: string): IPOSearchFilter {
    return {
      pagination: {
        pageNumber: 1,
        pageSize: this.pageSize,
      },
      sortDirection: 'DESC',
      sortCondition: PoSortCondition.Date,
      searchText: searchText ? searchText : ''
    };
  }

  getPurchaseOrders(payload?: IPOSearchFilter): void {
    this.loading = true;
    if (this.vendorService.isVendorManager && !this.vendorService.isImpersonationReady) {
      return;
    }    
    this.loaderService.setLoaderVendorVisible(true);
    if (this.paginationSubscription) { this.paginationSubscription.unsubscribe(); }
    this.paginationSubscription = this.vendorService.getPOs(payload || this.poPayload).subscribe(
      data => {
        if (data) {
          this.poTotal = data.pagination ? data.pagination.totalCount : 0;
          if (data.values && data.values.length) {
            this.purchaseOrders = data.values;
          }
        }
        if (payload) {
          this.currentPo = _.cloneDeep(this.purchaseOrders.find(po => po.docEntry === +this.docEntryToHighlight));
        }
        this.loading = false;
        this.loaderService.setLoaderVendorVisible(false);
      },
      err => {
        console.error('Error getting purchase orders: ', err);
        this.loading = false;
        this.loaderService.setLoaderVendorVisible(false);
      }, () => {
        this.triggerScrollTo();
      });
  }

  pageChanged(event): void {
    this.poPayload.pagination.pageNumber = event.page;
    this.getPurchaseOrders();
  }

  sortPurchaseOrders(condition: PoSortCondition): void {
    switch (condition) {
      case PoSortCondition.Date:
        if (this.poPayload.sortCondition === PoSortCondition.Date) {
          this.poPayload.sortDirection = this.poPayload.sortDirection === 'ASC' ? 'DESC' : 'ASC';
        } else {
          this.poPayload.sortDirection = 'DESC';
        }
        this.poPayload.sortCondition = PoSortCondition.Date;
        break;

      case PoSortCondition.DocNumber:
        if (this.poPayload.sortCondition === PoSortCondition.DocNumber) {
          this.poPayload.sortDirection = this.poPayload.sortDirection === 'ASC' ? 'DESC' : 'ASC';
        } else {
          this.poPayload.sortDirection = 'DESC';
        }
        this.poPayload.sortCondition = PoSortCondition.DocNumber;
        break;

      case PoSortCondition.Amount:
        if (this.poPayload.sortCondition === PoSortCondition.Amount) {
          this.poPayload.sortDirection = this.poPayload.sortDirection === 'ASC' ? 'DESC' : 'ASC';
        } else {
          this.poPayload.sortDirection = 'DESC';
        }
        this.poPayload.sortCondition = PoSortCondition.Amount;
        break;

      case PoSortCondition.Status:
        if (this.poPayload.sortCondition === PoSortCondition.Status) {
          this.poPayload.sortDirection = this.poPayload.sortDirection === 'ASC' ? 'DESC' : 'ASC';
        } else {
          this.poPayload.sortDirection = 'DESC';
        }
        this.poPayload.sortCondition = PoSortCondition.Status;
        break;
      default: break;
    }
    this.purchaseOrders = [];
    this.poPayload.pagination.pageNumber = 1;
    this.getPurchaseOrders();
  }

  verifyIfSearch(event: any): void {
    if (event.key === 'Enter' && this.poPayload.searchText.length > 0) {
      this.poSearched = true;
      this.searchPurchaseOrder();
    } else if (this.poPayload.searchText.length === 0 && this.poSearched) {
      this.poSearched = false;
      this.searchPurchaseOrder();
    }
  }

  searchPurchaseOrder(): void {
    this.poPayload = this.getDefaultPOPayload(this.poPayload.searchText);
    this.purchaseOrders = [];
    this.getPurchaseOrders();

    this.poSelectedStatus = { name: 'All' };
    this.selectedStatusName = this.poSelectedStatus.name;
  }


  filterPurchaseOrders(status: IPoStatus): void {
    const sortCondition = this.poPayload.sortCondition;
    this.poSelectedStatus = status;

    this.poPayload = this.getDefaultPOPayload();
    this.poPayload.orderStatus = status.name;
    this.poPayload.sortCondition = sortCondition;

    this.purchaseOrders = [];
    this.getPurchaseOrders();
  }

  showTracking(po: IPo): void {
    po.isOnHover = false;
    this.currentPo = _.cloneDeep(po);
    this.formErrors = this.vendorService.getDefaultTrackingFormErrors();
    this.form = this.vendorService.createTrackingForm(this.currentPo);
    this.vendorService.prepareTrackingToUpdate(this.currentPo, this.form);
    this.vendorService.setSubscriptions(this.formErrors, this.form);
    this.showTrackingModal = true;
  }

  closeTracking(): void {
    if (this.loading) {
      return;
    }
    this.showTrackingModal = false;
  }

  saveTracking(): void {
    if (this.form.valid) {
      this.vendorService.prepareTrackingToSave(this.form, this.currentPo, this.trackingInformationRequest);
      this.loading = true;
      this.subscriptions.push(
        this.vendorService.addTrackingToPurchaseOrder(this.trackingInformationRequest, this.currentPo.docEntry).subscribe(
          data => {
            const index = this.purchaseOrders.findIndex(po => po.docNumber === this.currentPo.docNumber);
            this.purchaseOrders[index] = _.cloneDeep(this.currentPo);
            this.loading = false;
            this.closeTracking();
          },
          err => {
            console.error('Error saving the tracking: ', err);
            this.loading = false;
            this.closeTracking();
          }
        )
      );
    } else {
      this.vendorService.validateTrackingForm(this.formErrors, this.form);
    }
  }

  getPODetails(po: IPo): void {
    this.vendorService.poPayload = this.poPayload;
    this.router.navigate(['vendor/po-full-details', po.docEntry]);
  }

  comingFromPoDetail() {
    if (this.vendorService.previousUrl &&
      this.vendorService.previousUrl.includes('/vendor/po-full-details/') && this.vendorService.poPayload) {
      this.poPayload = _.cloneDeep(this.vendorService.poPayload);

      if (this.poPayload.orderStatus && this.poPayload.orderStatus.length) {
        this.poSelectedStatus = { name: this.poPayload.orderStatus };
        const dropdownData: IDropdown = { group: 'purchaseOrders', action: 'setSelectedValue', name: this.poPayload.orderStatus };
        this.sharedService.setDropdown(dropdownData);
      }

      this.docEntryToHighlight = this.vendorService.previousUrl.substring(24);
      this.vendorService.poPayload = null;
      this.vendorService.previousUrl = null;

      const payload = _.cloneDeep(this.poPayload);

      this.getPurchaseOrders(payload);
    } else {
      this.getPurchaseOrders();
    }
  }

  triggerScrollTo(): void {
    setTimeout(() => {
      const dom: HTMLElement = this.elemR.nativeElement as HTMLElement;
      const element = dom.querySelector(`#po_${this.docEntryToHighlight}`) as HTMLElement;
      if(element){ 
        element.scrollIntoView({ behavior: 'smooth' }); 
        this.docEntryToHighlight = null;
      }else{
        this.elemR.nativeElement.closest('.vendor-home').scrollTo({top: 0});
      }
    });
  }

}

