import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class PageErrorService {
  errorMessage: string;
  errorCode: number;

  constructor(private router: Router) { }

  goToError(code: number, description: string): void {
    this.errorCode = code;
    this.errorMessage = description;
    this.router.navigate(['page-error']);
  }
}
