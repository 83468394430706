
<div  class="confirm-modal" [class.mobile]="isMobile">
  <div class="confirm-modal-wrap animated" [class.zoomIn]="true" [class.zoomOut]="false">
    <div class="confirm-modal-header">
      <button type="button" class="close" aria-label="Close" (click)="confirm(false)">
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="close-black" fill-rule="nonzero" fill="#000000">
                    <polygon id="Shape" points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"></polygon>
                </g>
            </g>
        </svg>
      </button>
      <div class="confirm-modal-header__txt">
        <h2>{{auctionItem.Title}}</h2>
        <p [innerHTML]="auctionItem.Description"></p>
      </div>
    </div>
    <div class="confirm-modal-message">
        <span class="confirm-modal-message__span">
          Confirm Bid of
        </span>
        <span class="confirm-modal-message__price">
          <span *ngIf="!bidAmount && auctionItem.ActionCount">
            {{auctionItem.CurrentPrice + auctionItem.Increment | CustomCurrency}}
          </span>
          <span *ngIf="!bidAmount && !auctionItem.ActionCount">
            {{auctionItem.CurrentPrice | CustomCurrency}}
          </span>
          <span *ngIf="bidAmount">
            {{bidAmount | CustomCurrency}}
          </span>
        </span>
    </div>

    <div class="confirm-modal-buttons">
      <button type="button" class="confirm-modal-buttons__continue" (click)="confirm(true)">Confirm</button>
    </div>

  </div>
</div>