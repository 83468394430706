import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { TopService } from '../shared/services/top.service';
import { UserService } from '../user/user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, public topservice: TopService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.auth.isAuthenticated() && !this.auth.isGuest) {
      this.router.navigate(['']);
    }
    this.topservice.xPath = this.topservice.xPath + 1;

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getAccessToken()}`,
        'Access-Control-Allow-Origin': '*',
        'X-Generation': this.topservice.xPath.toString()
      }
    });

    return next.handle(request);
  }
}
