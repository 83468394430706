<div class="terms" [class.mobile]="topService.isMobile" [class.show]="true" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="terms-dialog" role="document">
    <div class="terms-content">
      <div class="terms-header">
      	<h2>All Purchases are Subject to the following Terms and Conditions</h2>
        <button type="button" class="terms-header__close" aria-label="Close" (click)="onClose()">
          <img src="assets/images/close.svg" width="24" height="24">
        </button>
      </div>
      <div class="terms-body">
      	<div class="terms-body__text">
			<p>
				By placing a check mark in the adjacent box, you are indicating your acceptance and agreement with the foregoing terms and conditions, including a 3% Convenience Fee for all online purchases by card credit (incurred as a result of the ease and convenience associated with conducting this transaction online), and acknowledging that you are a legally authorized user of this credit card.
			</p>
			<p>
				The prices and available quantities of certain Products are subject to fluctuate due to market conditions beyond our control. We do not provide warranties for Products, other than limited warranties for certain select Products clearly identified on such Product’s listing ID.   However, we do not provide or administer any warranty service. To the extent your Product is covered by any warranty, we would refer you to the warranty information contained in your Product’s original packaging.  It is your sole responsibility to verify the accuracy of your shipping information, part numbers, and product specifications.
			</p>
			<p><b>NO RETURNS OR REFUNDS. ALL SALES ORDERS ARE FINAL – A 15% RESTOCKING FEE APPLIES ALL CANCELLATIONS.</b></p>
			<p>
				In an effort to provide you the ease and convenience of making online purchases of our Products, we allow our customers to make purchases by credit card, subject to a 3% Convenience Fee.  HUBX reserves the right to cancel any Sales Order if confirmation of payment is not received within 72 business hours. At the time of the receipt of your shipment, any damage to exterior packaging must be noted prior to signing for merchandise. If the carrier does not have such notes an insurance claim will not be filed, and you will be deemed to have inspected and accepted the Products.  Orders may take up to 2 business days, after credit release, to pick and pack.
			</p>
      	</div>
      	<div class="terms-body__form">
			<form [formGroup]="formAgreement" >
				<div class="terms-body-inputs">
					<div class="terms-body__input">
						<input type="text" autocomplete="off" name="first-name" placeholder="First Name" formControlName="firstName"
						[class.required]="formErrors.firstName.required || formErrors?.firstName?.validFirstNameLength === false">
					</div>
					<div class="terms-body__input">
						<input type="text" autocomplete="off" name="last-name" placeholder="Last Name" formControlName="lastName"
						[class.required]="formErrors.lastName.required || formErrors?.lastName?.validLastNameLength === false">
					</div>
				</div>
				<div class="terms-body-checkbox" [class.required]="formErrors.agreement.required || formErrors?.agreement?.validAgree === false">
					<input type="checkbox" name="agreement" id="agreement" formControlName="agreement">
					<label for="agreement"></label>
					<label class="terms-body-checkbox__text" for="agreement">
						I agree to terms and conditions of placing order and I am an authorized agent to place order.
					</label>
				</div>
				<div class="terms-body-ctrl">
				 <button type="button" class="terms-body-ctrl__btn"
					(click)="onAccept()"
					[disabled]="!formAgreement.valid">
				   Place Order
				</button>
			  </div>
			</form>
      	</div>
      </div>
    </div>
  </div>
</div>
