import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-items-empty',
  templateUrl: './items-empty.component.html',
  styleUrls: ['./items-empty.component.scss']
})
export class ItemsEmptyComponent implements OnInit {

  items = [];

  constructor() { }

  ngOnInit() {
  	this.items.length = 3;
  }

}
