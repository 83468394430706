<div class="biddesk-banner" [class.mobile]="topService.isMobile">
  <div class="biddesk-banner-content">
    <div class="biddesk-banner-content__text">
      <div class="biddesk-banner-content__logo"></div>
      <h1>Submit Your Offer</h1>
      <div class="buttons">
        <button (click)="biddeskBannerService.isBiddeskModalShown = true">Submit Bid</button>
      </div>
      <span>*Offers need a minimum of $10K and cannot be less than 5% off listed price.</span>
    </div>
  </div>
</div>


<app-biddesk-popup *ngIf="biddeskBannerService.isBiddeskModalShown || biddeskBannerService.isBidSubmited"></app-biddesk-popup>