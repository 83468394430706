<div class="filters">
  <div
    class="filters-back"
    [class.hide]="!auctionsService.isDetailsPage"
    [routerLink]="[getAuctionsUrl()]">
    <a class="filters-back-btn" role="button">
      <i class="filters-back-btn__icon"></i>
      <span class="filters-back-btn__text">Back to Live Auctions</span>
    </a>
  </div>
  <div [class.hide]="auctionsService.isDetailsPage">
    <div class="filters-top">
      <h3 class="filters__title">
        <span
        [routerLink]="guestUrl + '/auctions'"
        routerLinkActive="active-class">{{ auctionsService.titleText }}</span>
        <span class="_count" *ngIf="auctionsService.totalsReady"
        [class.zeroCount]="auctionsService.totalToDisplay === 0">
          {{ auctionsService.totalToDisplay }}
        </span>
      </h3>
      <div class="filters-top__right" *ngIf="sessionService.userRole !== 'SUPER'">
        <ul class="filter-nav">
          <li><span
              class="filter-nav-item"
              [class._active]="router.url === guestUrl + '/auctions/my_bids/watching'"
              (click)="goToWatching()">
            <img class="_icon" src="../../../../assets/images/icons/auctions/eye-white.svg" width="24" height="24"
                 alt="eye">
            Watching</span></li>
          <li><span
            class="filter-nav-item"
            [class._active]="router.url === guestUrl + '/auctions/my_bids'"
            (click)="goToMyBids()">My Bids</span></li>
        </ul>
      </div>
    </div>
    <div class="filters-btm">
      <div class="filters-btm__left">
        <div class="filters-btm-item">
          <ul class="filter-tabs">
            <li (click)="selectQuickFilter(statusAuctionsEnum.active)">
              <span
              class="filter-tabs-item"
              [class._active]="auctionsService.isAuctionsPage && auctionsService.searchPayload.statusFilter === statusAuctionsEnum.active">
              Live<span class="_count">{{auctionsService.headerTotals?.LiveTotal}}</span></span>
            </li>
            <li (click)="selectQuickFilter(statusAuctionsEnum.comingSoon)">
              <span
              class="filter-tabs-item"
              [class._active]="auctionsService.isAuctionsPage && auctionsService.searchPayload.statusFilter === statusAuctionsEnum.comingSoon">
              Coming Soon<span
              class="_count">{{auctionsService.headerTotals?.CommingSoonTotal}}</span></span></li>
            <li (click)="selectQuickFilter(statusAuctionsEnum.completed)">
              <span
              class="filter-tabs-item"
              [class._active]="auctionsService.isAuctionsPage && auctionsService.searchPayload.statusFilter === statusAuctionsEnum.completed">
              Completed<span
              class="_count">{{auctionsService.headerTotals?.CompletedTotal}}</span></span></li>
          </ul>
        </div>
      </div>
      <div class="filters-btm__right">
        <div class="filters-btm-item _search">
          <div class="filter-search">
            <span class="_icon" (click)="searchAuctionItems()"></span>
            <input type="text" placeholder="Search Auctions" [formControl]="searchControl" (keyup)="searchOnEnter($event)">
            <span class="_icon __close" (click)="resetSearch()" *ngIf="searchControl.value.length">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="delete/white" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <polygon id="Shape" fill="#FFFFFF" fill-rule="nonzero" points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"></polygon>
                  </g>
              </svg>
            </span>
          </div>
        </div>
        <!--<div class="filters-btm-item _activity" [ngStyle]="{'margin-right': (auctionsService.hideChangeViews) ? '0' : '16px'}">-->
        <div class="filters-btm-item _activity">
          <app-dropdown darkTheme="true" [items]="auctionsService.sortOptions" [fieldToShow]="'Text'"
           [fieldToSave]="'Text'"[isFromBuyerPortal]="true" [(ngModel)]="selectedSortOption"  
           [defaultTitle]="selectedSortOption" (onSelect)="onSortOptionSelected($event)">
          </app-dropdown>
        </div>
        <!--<div class="filters-btm-item" *ngIf="!auctionsService.hideChangeViews">-->
          <!--<div class="filter-view">-->
            <!--<span class="filter-view-item" title="Listing View" [class._active]="!auctionsService.isGridView" (click)="auctionsService.isGridView = false">-->
              <!--<img src="../../../../assets/images/icons/auctions/view-listing.svg" width="24" height="24"/>-->
            <!--</span>-->
            <!--<span class="filter-view-item" title="Cards View" [class._active]="auctionsService.isGridView" (click)="auctionsService.isGridView = true">-->
              <!--<img src="../../../../assets/images/icons/auctions/view-cards.svg" width="24" height="24"/>-->
            <!--</span>-->
          <!--</div>-->
        <!--</div>-->
      </div>
    </div>
  </div>
</div>
