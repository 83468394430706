<div class="history-wrap" [class.mobile]="topService.isMobile">
  <h2 class="history-wrap__title">Bid History </h2>

    <div class="history-list" *ngIf="auctionsService?.bidHistoryPayload?.List?.length">
      <div class="history-list-item" *ngFor="let bid of auctionsService?.bidHistoryPayload?.List; let i = index" >
        <div class="history-list-item__details">
          <span class="history-list-item__details-icon">
            <img src="../../../../assets/images/icons/auctions/bid.svg" width="40px" height="40px" />
          </span>
          <div class="history-list-item__details-desc">
            <span>{{moment(bid.ActionDTTM).format('l')}}</span>
            <span>{{moment(bid.ActionDTTM).format('LTS')}} </span>
          </div>
        </div>
        <div class="history-list-item__bid">
          <span class="bid-owner" *ngIf="bid.IsMine"
          [ngClass]="{'_live': bid.IsMine && i === 0 && auctionsService?.currentAuctionItem?.IsWinning}">Your bid</span>
        </div>
        <div class="history-list-item__amount">{{bid.Amount | CustomCurrency}}</div>
      </div>
    </div>
    <div *ngIf="!auctionsService?.bidHistoryPayload?.List?.length" class="history-empty-state">
      <div class="history-empty-state__content">
        <img src="../../../../assets/images/icons/auctions/bid-history-empty@2x.png" width="156" height="155" alt="auctions empty">
        <h3>No bids yet!</h3>
        <p>Your bid will be first</p>
      </div>
    </div>
  </div>
