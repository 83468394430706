import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VendorHomeComponent } from './vendor-home/vendor-home.component';
import { AuthGuardVendorService } from '../auth/auth-guard-vendor-service';
import { UserComponent } from '../user/user.component';
import { PoComponent } from './orders/po/po.component';
import { ItemsComponent } from './products/items/items.component';
import { PoFullDetailsComponent } from './orders/po-full-details/po-full-details.component';
import { MainDashboardComponent } from './dashboard/main-dashboard/main-dashboard.component';
import { PendingSoldComponent } from './orders/pending-sold/pending-sold.component';
import { NotificationCenterVendorComponent } from './notifications/notification-center-vendor/notification-center-vendor.component';
import { UserProfileComponent } from '../user/user-profile/user-profile.component';
import { MobileNotificationsComponent } from '../user/mobile-notifications/mobile-notifications.component';
import { OffersComponent } from '../user/offers/offers.component';
import { OffersResolver } from '../user/offers/offers-resolver.service';

export const routes: Routes = [
  {
    path: 'vendor', component: VendorHomeComponent, children: [
      { path: 'items', component: ItemsComponent, canActivate: [AuthGuardVendorService] },
      { path: 'items/status/:statusName', component: ItemsComponent, canActivate: [AuthGuardVendorService] },
      { path: 'items/:days', component: ItemsComponent, canActivate: [AuthGuardVendorService] },
      { path: 'dashboard', component: MainDashboardComponent, canActivate: [AuthGuardVendorService] },
      { path: 'notifications', component: NotificationCenterVendorComponent, canActivate: [AuthGuardVendorService] },
      { path: 'purchase-orders', component: PoComponent, canActivate: [AuthGuardVendorService] },
      { path: 'pending-sold', component: PendingSoldComponent, canActivate: [AuthGuardVendorService] },
      { path: 'po-full-details/:id', component: PoFullDetailsComponent, canActivate: [AuthGuardVendorService] },
      { path: 'user/mobile_notifications', component: MobileNotificationsComponent, canActivate: [AuthGuardVendorService]},
      { path: 'user/profile', component: UserProfileComponent, canActivate: [AuthGuardVendorService]},
      { path: 'user/offers', component: OffersComponent, canActivate: [AuthGuardVendorService]},
      { path: 'user/offers/:id', component: OffersComponent, canActivate: [AuthGuardVendorService], resolve: {id: OffersResolver }},
      { path: "**", redirectTo: '/vendor-login'}
    ]
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class VendorRoutingModule { }
