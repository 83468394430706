import { Injectable } from '@angular/core';
import * as models from '../../buyer/interfaces/model';
import { IJustSoldQuickFilter } from '../interfaces/IJustSoldQuickFilter';
import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';
import { ITopNav } from '../../buyer/interfaces/ITopNav';
import { IUserFilter, IPreselectedUserFilter } from '../../buyer/interfaces/IUserFilter';

@Injectable({
  providedIn: 'root'
})
export class ItemFilterService {
  totalOfLevelZero;
  isFilterMobileOpen = false;

  private _selectedManufacturer;
  private _selectedSecondLevel;
  private _selectedFirstLevel;
  private _selectedThirdLevel;
  private _selectedCondition;
  private _selectedSearch;
  private _secondLevelCats = [];
  public minPrice;
  public maxPrice;


  private _breadcrumbAllObj = {
    search: null,
    zeroLevel: null,
    firstLevel: null,
    maufacturer: null,
    secondLevel: null,
    thirdLevel: null,
    condition: null
  };
  preselectedUserFilter: IPreselectedUserFilter;

  set selectedManufacturer(m) {
    this._breadcrumbAllObj.maufacturer = (m) ? m.name : undefined;
    this._selectedManufacturer = m;
  }

  get selectedManufacturer() {
    return this._selectedManufacturer;
  }
  // for nested routes in floating nav e.g 'Laptops' or 'Desktop'
  private _selectedZeroLevel: ITopNav;

  set selectedZeroLevel(levelZero) {
    this._selectedZeroLevel = levelZero;
    this.pubSubService.sharedSubject.next({ name: SharedSource.selectedZeroLevelChanged, data: true });

    if (levelZero) {
      this._selectedZeroLevel.redableTitle = (levelZero.label) ? levelZero.label : String(levelZero.navName).replace(/\_/g, ' ');
    }

    this._breadcrumbAllObj.zeroLevel = (levelZero) ? this._selectedZeroLevel.redableTitle : undefined;
  }

  get selectedZeroLevel() {
    return this._selectedZeroLevel;
  }
  // Categories in child nav child left nav
  set selectedFirstLevel(val) {
    this._selectedFirstLevel = val;
    this._breadcrumbAllObj.firstLevel = (val) ? val.navName : undefined;
  }

  get selectedFirstLevel() {
    return this._selectedFirstLevel;
  }

  // category first level in outlet detail or category when you are in a parent route of floating nav
  set selectedSecondLevel(val) {
    this._selectedSecondLevel = val;
    this._breadcrumbAllObj.secondLevel = (val) ? val.navName : undefined;
  }

  get selectedSecondLevel() {
    return this._selectedSecondLevel;
  }

  // category second level
  set selectedThirdLevel(val) {
    this._selectedThirdLevel = val;
    this._breadcrumbAllObj.thirdLevel = (val) ? val.navName : undefined;
  }

  get selectedThirdLevel() {
    return this._selectedThirdLevel;
  }

  // condition
  set selectedCondition(val) {
    this._selectedCondition = val;
    this._breadcrumbAllObj.condition = (val) ? val.value : undefined;
  }

  get selectedCondition() {
    return this._selectedCondition;
  }

  set selectedSearch(val) {
    this._selectedSearch = val ? `Search results for: ${val}` : val;
    this._breadcrumbAllObj.search = this._selectedSearch;
  }

  get selectedSearch() {
    return this._selectedSearch;
  }

  get breadcrumbAllArray() {
    const breacrumbsArray = [];

    for (const item in this._breadcrumbAllObj) {
      if (this._breadcrumbAllObj[item]) {
        breacrumbsArray.push({ propName: item, text: this._breadcrumbAllObj[item] });
      }
    }
    return breacrumbsArray;
  }

  set secondLevelCats(val: ITopNav[]) {
    this._secondLevelCats = val;
  }

  get secondLevelCats() {
    return this._secondLevelCats;
  }

  isOutletsSinglePage = false;
  allManufacturers: models.Attribute[];
  allReactiveManufacturers: models.Attribute[];

  pastPurchasesManufacturers: IJustSoldQuickFilter[] = [{ selected: false, id: '', name: 'All Manufacturers' }];
  justSoldManufacturers: IJustSoldQuickFilter[] = [{ selected: false, id: '', name: 'All Manufacturers' }];

  // closing and opening sub-levels
  isSecondLevelExpanded = false;
  isThirdLevelExpanded = false;
  userFilter: models.IRequest;
  isOutletUserFilter;
  searchesList = false;

  constructor(public pubSubService: PubSubService) {
  }

  clearAll(exceptZero?: boolean, selectedManufacturer?: boolean) {
    if (!selectedManufacturer) {
      this.selectedManufacturer = undefined;
    }
    if (!exceptZero) {
      this.selectedZeroLevel = undefined;
    }
    this.selectedFirstLevel = undefined;
    this.selectedSecondLevel = undefined;
    this.selectedThirdLevel = undefined;
    this.selectedCondition = undefined;
    this.selectedSearch = undefined;
    this.secondLevelCats = [];
  }
}
