import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TopService } from '../../../shared/services/top.service';
import { UserFiltersService } from '../user-filters.service';

@Component({
  selector: 'app-user-filters-typeahead',
  templateUrl: './user-filters-typeahead.component.html',
  styleUrls: ['./user-filters-typeahead.component.scss']
})
export class UserFiltersTypeaheadComponent implements OnInit, OnDestroy {

  constructor(public topService: TopService,
    public router: Router,
    public userFilterService: UserFiltersService) { }

  ngOnInit() {
    if (this.topService.isMobile) {
      document.documentElement.classList.add('is-modal');
    }
  }

  ngOnDestroy() {
    if (this.topService.isMobile) {
      document.documentElement.classList.remove('is-modal');
    }
  }

  navigateToItems(filter) {
    if (!this.router.url.includes('buyer/user_filters/')) {
      this.userFilterService.navigateToItems(filter);
      return;
    }     

    if (this.userFilterService.selectedUserFilter) {
      this.userFilterService.navigateToItems(filter);
    } else {
      this.userFilterService.getUserFilterAndNavigate(filter.id);
    }
  }
}
