<div class="col-12 wrap no-padding">
  <div *ngIf="!showForgot" class="animated" [ngClass]="{'flipInY': fromForgot}">
    <div class="col-12 no-padding title text-center">Login</div>

    <div class="col-12 login-form no-padding" >
      <form>
        <div class="form-group">
          <input type="email" class="form-control" name="email" tabindex="1" autofocus #email="ngModel" placeholder="Email" [(ngModel)]="emailLogin" autofocus>
        </div>
        <div class="form-group">
          <input (keyup.enter)="login(email.value, password.value)" tabindex="2" type="password" class="form-control"
            #password placeholder="Password">
        </div>
        <a [class.loadingHelper]="userService.loading" (click)="login(email.value, password.value)"
        class="login-button">
          <!-- <span *ngIf="!loading"></span> -->
          <span *ngIf="userService.loading" class="mobile-loader"></span>
          <span *ngIf="!userService.loading">Login</span>
          <span *ngIf="userService.loading">Logging in...</span>
        </a>
      </form>
    </div>

    <div class='error-box' [ngClass]="{'hide': !loginFail}" *ngIf="!showForgot">
      Login failed!
    </div>

    <div *ngIf="!showForgot" class="forgot animated">
      <a (click)="forgot()">Forgot Password?</a>
    </div>
  </div>

  <app-forgot-password [mobile]="mobile" (hideForgotPwd)="showLogin($event)" [shownEmail]="emailLogin" *ngIf="showForgot"></app-forgot-password>
</div>
