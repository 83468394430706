<header *ngIf="!userService.isInIframe && (!role || role === 'null'  || !authService.completedLoad)" class="header _mobile _dark-header"
  [ngClass] = "{
    '_corp-site-header': sharedService.isCorpSite
  }"
>
  <div class="wrapper-header" [class.nav-opened]="navToggle === true">
    <a (click)="clickLogoToReload()">
      <img src="../../assets/images/logo-white-title.svg" width="76" alt="HUBX" class="logo _white-title" />
    </a>
    <div class="select-buttons" *ngIf="!sharedService.isCorpSite">
        <a *ngIf="!isOnBuyerLogin()" (click)="navigateTo('guest/home')" class="select-button __buyer">Buyer Portal</a>
        <a *ngIf="!isOnVendorLogin() && !maintenanceModeOn" (click)="navigateTo('vendor-login')" class="select-button __vendor">Vendor Portal</a>
    </div>
    <nav class="nav-wrap" [ngClass]="{'mobile-nav': navToggle}">
      <ul (click)="close()" class="nav">
        <li><a [ngClass]="{'activeLink': sharedService.corporateSiteSelectedLink === 'corphome'}" (click)="navigateTo('corphome')">Our Company</a></li>
        <li><a [ngClass]="{'activeLink': sharedService.corporateSiteSelectedLink === 'products'}" (click)="navigateTo('products')" >Products</a></li>
        <li><a [ngClass]="{'activeLink': sharedService.corporateSiteSelectedLink === 'sell'}" (click)="navigateTo('sell')" >Sell On HUBX</a></li>
        <li><a [ngClass]="{'activeLink': sharedService.corporateSiteSelectedLink === 'aboutUs'}"  (click)="navigateTo('aboutUs')">About Us</a></li>
        <li><a [ngClass]="{'activeLink': sharedService.corporateSiteSelectedLink === 'faq'}" (click)="navigateTo('faq')">FAQ</a></li>
        <li><a [ngClass]="{'activeLink': sharedService.corporateSiteSelectedLink === 'contact-support'}" (click)="navigateTo('contact-support')" (click)="navigateTo('contact-support')">Contact</a></li>
      </ul>
      <ul (click)="close()" class="nav buttons">
        <li *ngIf="!isOnBuyerLogin() && !isOnVendorLogin()"><a (click)="navigateTo('guest/home')" class="buyer-button">Buyer Portal</a></li>
        <li *ngIf="!isOnBuyerLogin() && !isOnVendorLogin() && !maintenanceModeOn"><a (click)="navigateTo('vendor-login')" class="vendor-button">Vendor Portal</a></li>
      </ul>
    </nav>
    <div class="toggle-mobile-menu" [class.nav-open]="navToggle === true" (click)="toggleNav()" *ngIf="!isAuthenticated()">
      <div class="top"></div>
      <div class="middle"></div>
      <div class="bottom"></div>
    </div>

  </div>
</header>
<i class="header-btm-bg-fix" *ngIf="isOnBuyerLogin()  && !sharedService.isCorpSite"></i>

<app-guest-top-full *ngIf="!userService.isInIframe && (!role || role === 'null' || !authService.completedLoad)"></app-guest-top-full>



<app-top-mobile *ngIf = "!userService.isInIframe && authService.completedLoad && (role === 'BUYER' || role==='SALES' || role ==='SUPER')" ></app-top-mobile>

<app-top-vendor-mobile *ngIf = "authService.completedLoad && (role === 'SELLER' || role === 'ADMIN' || role === 'VENDORMGR')"></app-top-vendor-mobile>
<app-guest-top-mobile *ngIf="!userService.isInIframe && authService.completedLoad && role === 'GUEST' && !sharedService.isCorpSite"
></app-guest-top-mobile>

<app-error-modal [bodyText]="sessionErrorModal.bodyText" [title]="sessionErrorModal.title" [isError]="!isAuthenticated() && sessionService._is_logged_in === 'true'"
  (onConfirmError)="handleExpiredSession()"></app-error-modal>
