export enum ListingSkuStatusNamesEnum {
  active = 'Active',
  inactive = 'Inactive',
  soldOut = 'Sold Out',
  paused = 'Paused',
  removed = 'Removed',
  archived = 'Archived'
}


export enum ListingSkuStatusValuesEnum {
  all,
  active,
  inactive,
  soldOut,
  paused,
  removed,
  archived
}
