import { Component, OnInit } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { UserService } from '../../../user/user.service';
import { ItemsService } from '../../../shared/services/items.service';
import { SharedService } from '../../../shared/shared.service';
import { SessionService } from '../../../services/session.service';
import { DEFAULT_URL } from '../../../shared/static';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';
import { GuestService } from '../../../shared/services/guest.service';

@Component({
  selector: 'app-guest-login-mobile',
  templateUrl: './guest-login-mobile.component.html',
  styleUrls: ['./guest-login-mobile.component.scss']
})
export class GuestLoginMobileComponent implements OnInit {
  emailLogin = '';
  password = '';
  firstTry: boolean;
  showForgot = false;
  fromForgot = false;
  mobile = true;
  loginFail: any;

  constructor(
    private titleService: Title,
    private router: Router,
    private userService: UserService,
    private pubSubService: PubSubService,
    private itemsService: ItemsService,
    public sharedService: SharedService,
    public sessionService: SessionService,
    private guestService: GuestService) { }

  ngOnInit() {
    this.userService.auth.completedLoad = false;

    if (this.userService.auth.isAuthenticated()) {
      this.userService.auth.completedLoad = true;
      if (this.sessionService.userRole === 'SELLER' || this.sessionService.userRole === 'ADMIN' || this.sessionService._user_role === 'VENDORMGR') {
        this.router.navigate(['/vendor/dashboard']);
      } else if (this.sessionService.userRole === 'BUYER' ||
      this.sessionService.userRole === 'SALES' ||
      this.sessionService.userRole === 'SUPER') {
          this.router.navigate(['/buyer/home']);
      } else if (this.sessionService.userRole === 'GUEST') {
        this.router.navigate(['/guest/home']);
      }
    }

    this.titleService.setTitle('HUBX Portal');
    this.itemsService.itemsRequest = this.itemsService.getEmptyRequestPayload();
  }

  login(email: string, password: string): void {
    if(this.userService.loading == true){ return; }
    this.pubSubService.sharedSubject.subscribe(
      mySharedValues => {
        if (mySharedValues.name === SharedSource.LoggedInInternal) {
          this.userService.loading = false;
        }
        if (mySharedValues.name === SharedSource.LoggedInGuest) {
          this.userService.loading = false;
          this.loginFail = !mySharedValues.data;
        }
      });
      this.userService.loading = true;
      this.userService.login(email, password);
  }

  showForgotPwd() {
    this.showForgot = true;
  }

  forgot() {
    this.showForgot = true;
  }

  showLogin(event) {
    this.showForgot = false;
    if (event === true) {
      this.fromForgot = true;
    }
  }

  hideLogin() {
    this.showForgot = false;
  }
}
