<div class="item">
	<div class="item-part __button">
		<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
			<g id="plus-grid" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<polygon id="+" points="13.2712871 10.7287129 18 10.7287129 18 13.2712871 13.2712871 13.2712871 13.2712871 18 10.7287129 18 10.7287129 13.2712871 6 13.2712871 6 10.7287129 10.7287129 10.7287129 10.7287129 6 13.2712871 6"></polygon>
			</g>
		</svg>
	</div>
	<div class="item-part __date">
		<span></span>
	</div>
	<div class="item-part __part-number">
		<span></span>
	</div>
	<div class="item-part">
		<span></span>
	</div>
	<div class="item-part">
		<span></span>
	</div>
	<div class="item-part __revenue">
		<span></span>
	</div>
</div>