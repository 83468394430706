
<div class="excel-btn" *ngIf="!isCart">
  <div class="float-right">
    <button [disabled]="data && !data.length? true : false">
      <span (click)="exportToExcel()">Export</span>
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="excel" fill-rule="nonzero">
            <path d="M13.6541294,17.6609524 L13.6541294,4.10412698 L12.5562587,4.10412698 C9.73643781,4.61206349 6.92425871,5.08190476 4.09424876,5.56952381 L4.09424876,18.392381 C6.87840796,18.8825397 9.69822886,19.3726984 12.5002189,19.8806349 L13.6464876,19.8806349 L13.6464876,17.6609524 L13.6541294,17.6609524 Z M9.50718408,14.8469841 C9.15595921,14.0982015 8.84808416,13.3299566 8.58507463,12.5460317 C8.33034826,13.2901587 7.97882587,13.9911111 7.69098507,14.72 C7.28851741,14.72 6.88604975,14.6971429 6.48358209,14.6819048 C6.95482587,13.76 7.41078607,12.8304762 7.89731343,11.9161905 C7.48465672,10.9714286 7.02869652,10.0469841 6.60330348,9.10984127 L7.82089552,9.04126984 C8.09345274,9.75746032 8.39402985,10.4660317 8.62073632,11.2 C8.87546269,10.4203175 9.22443781,9.67619048 9.53520398,8.93460317 C9.95125705,8.90582011 10.3681592,8.88042328 10.7859104,8.8584127 C10.2968358,9.86074074 9.80351575,10.8656085 9.30595025,11.8730159 C9.81540299,12.8888889 10.3248557,13.9174603 10.8343085,14.9434921 C10.3910846,14.9079365 9.95040796,14.88 9.50718408,14.8469841 Z"
              id="Shape"></path>
            <polygon id="Shape" points="14.3573568 5.8615873 14.3573568 18.1155556 19.1385707 18.1155556 19.1385707 5.8615873"></polygon>
          </g>
        </g>
      </svg>
    </button>
    &lt;!&ndash; <a>
    </a> &ndash;&gt;
  </div>
</div>

<div *ngIf="isCart">
  <div class="excel-btn-cart">
    <div class="float-right" (click)="exportToExcel()"> 
      <button [disabled]="data && !data.length? true : false">
        <span>Export</span>
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="excel" fill-rule="nonzero">
              <path d="M13.6541294,17.6609524 L13.6541294,4.10412698 L12.5562587,4.10412698 C9.73643781,4.61206349 6.92425871,5.08190476 4.09424876,5.56952381 L4.09424876,18.392381 C6.87840796,18.8825397 9.69822886,19.3726984 12.5002189,19.8806349 L13.6464876,19.8806349 L13.6464876,17.6609524 L13.6541294,17.6609524 Z M9.50718408,14.8469841 C9.15595921,14.0982015 8.84808416,13.3299566 8.58507463,12.5460317 C8.33034826,13.2901587 7.97882587,13.9911111 7.69098507,14.72 C7.28851741,14.72 6.88604975,14.6971429 6.48358209,14.6819048 C6.95482587,13.76 7.41078607,12.8304762 7.89731343,11.9161905 C7.48465672,10.9714286 7.02869652,10.0469841 6.60330348,9.10984127 L7.82089552,9.04126984 C8.09345274,9.75746032 8.39402985,10.4660317 8.62073632,11.2 C8.87546269,10.4203175 9.22443781,9.67619048 9.53520398,8.93460317 C9.95125705,8.90582011 10.3681592,8.88042328 10.7859104,8.8584127 C10.2968358,9.86074074 9.80351575,10.8656085 9.30595025,11.8730159 C9.81540299,12.8888889 10.3248557,13.9174603 10.8343085,14.9434921 C10.3910846,14.9079365 9.95040796,14.88 9.50718408,14.8469841 Z"
                id="Shape"></path>
              <polygon id="Shape" points="14.3573568 5.8615873 14.3573568 18.1155556 19.1385707 18.1155556 19.1385707 5.8615873"></polygon>
            </g>
          </g>
        </svg>
      </button>
    </div>
  </div>
</div>
