
<div class="picker-wraper __dark" (clickOutside)="(isActive) ? closePicker($event) : ''">
  <button class="btn dropdown-toggle"
  [style.border-radius]="borderRadius"
  [style.min-width]="minWidth"
  [style.height]="height ? height : '40px;'"
  [class.active]="isActive"
  (click)="toggleDropdown($event)"
  [disabled]="disabled">
  <i class="_icon" *ngIf="iconSrc">
    <img [src]="iconSrc" width="24" height="24" alt="warehouse" />
  </i>
    <span class="selected-item _text">
      {{(selectedItem) ? selectedItem[fvalue] : label}}
    </span>
  </button>
  <div class="dropdown-menu" [class.show]="isActive && data && data.length" [class.hide]="!isActive">
    <ng-container *ngIf="data && data.length">
      <!-- Label or default opt -->
      <div class="dropdown-item" *ngIf="addDefaultOpt" [class.active]="selectedItem && selectedItem[fkey] === null">
        <a (click)="selectionChanged(allOption)" class="_text">
          {{  allOption[fvalue] }}
        </a>
      </div>

      <div class="dropdown-item" *ngFor="let item of data" [class.active]="selectedItem && selectedItem[fkey] === item[fkey]">
        <a (click)="selectionChanged(item)" class="_text">
          {{  item[fvalue] }}
        </a>
        <i class="_edit" *ngIf="allowEdit">
          <img src="assets/images/vendor-icons/24-edit.svg" width="24" height="24" alt="edit" />
        </i>
      </div>

      <div class="dropdown-item _add" *ngIf="allowAdd">
        {{ addLabel }}
      </div>
    </ng-container>
  </div>
</div>
