// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  name: 'staging',
  production: false,
  maintenanceMode: false,
  googleAnalyticsAppId: 'UA-112404134-2',
  gtmId: 'GTM-5L33D9Q',
  buyerPortalBaseUrl: 'https://buyer-staging.hubx.com/api/',
  vendorPortalBaseUrl: 'https://vendor-staging.hubx.com/',
  imageBaseUrl: 'https://cdn.hubx.com',
  imageDefaultLogoUrl: '/assets/images/item-placeholder.svg',
  adminUrl: 'https://admin-staging.hubx.com/api/',
  adminBaseUrl: 'https://admin-staging.hubx.com/',
  hubx_auth_grantType: 'password',
  hubx_auth_domain: 'https://hubx-authenticationapi-dev.azurewebsites.net/',
  hubx_auth_apiUrl: 'https://hubx-authadminapi-dev.azurewebsites.net/',
  hubx_auth_claimKey: 'https://www.hubx.com/app_metadata',
  hubx_auth_clientId: '80e67b2ad9fe42b8bb8d48b0c436847e',
  hubx_auth_realm: 'portal-staging',
  hubx_auth_scope: 'hubx-web-apis-stg',
  hubx_auth_guest_realm: 'portal-guest-staging',
  hubx_auth_RedirectUrl: 'https://portal-staging.hubx.com/callback',
  hubx_auth_RedirectUrl_LocalHost: 'http://localhost:4200/callback',
  notificationHubUrl: 'https://hubx-notificationhub-stg.azurewebsites.net',
  notificationCenterUrl: 'https://hubx-buyerapi-staging.azurewebsites.net',
  auctionUrl: 'https://auctionapi-staging.hubx.com/api/',
  auctionSignalRUrl: 'https://auctionapi-staging.hubx.com/signalr',
  userFilterUrl: 'https://buyer-staging.hubx.com/userFilter',
  currentDeal: {
    displayLabel: 'Today\'s Deals',
    displaySingularLabel: 'Today\'s Deal',
    displayLabelAlt: 'Todays Deals',
    url: 'todays_deals'
  },
  itemsInPage: 9999,
  globalSearchIndicator: '#',
  orderCommentsLength : 1024,
  PDFOrderCommentsLength: 219,
  errorMapping: [
    {server: 'Quantity must be equal or greater than Minimum Order Quantity',
      client: {
        title: 'Minimum Quantity Error',
        message: 'Quantity is below minimum order quantity, please adjust the quantity accordingly.'
      }
    },
    {server: 'This item has a Max Unit Quantity, you cannot purchase more than 40 units for this item. Please adjust quantity accordingly',
      client: {
        title: 'Max Unit Quantity',
        message: 'This item has a Max Unit Quantity, you cannot purchase more than 40 units for this item. Please adjust quantity accordingly'
      }
    },
    {server: 'Not enough inventory',
      client: {
        title: 'Inventory Availability',
        message: 'Not enough inventory'
      }
    },
    {server: 'Quantity must be equal or greater than Minimum Order Quantity of 5',
      client: {
        title: 'Minimum Quantity Error',
        message: 'Quantity must be equal or greater than Minimum Order Quantity of 5'
      }
    },
  ],
  defaultVendor: 'HUBX',
  poPageSize: 100,
  vendorItemsPageSize: 25,
  vendorItemsMessage: 'Nothing Found!',
  vendorItemsMinorMessage: 'Try changing the search query',
  vendorItemsButtonText: 'Reset',
  vendorPendingSoldMessage: 'No orders Yet!',
  vendorPendingSoldMinorMessage: 'When items sell, they will appear here as pending purchase orders.',
  vendorPOMessage: 'No orders Yet!',
  vendorPOMinorMessage: 'After your first order, your orders will appear here.',
  noItemPendingPoErrorMessage: 'This item does not exist in our web DB',
  recaptchaKey: '6LfGBZgUAAAAAE448JXoHC4dkLtzxyYRVo5SETDf',
  forgotPasswordPreMessage: {
    message: 'Please check your email!',
  },
  faq:
  {
  customer: [
      {
        question: 'What is HUBX?',
        answer: `HUBX is the first hybrid B2B marketplace distributor for buying and selling
        computers, mobile and consumer electronics. Our platform gives buyers
        access to an exclusive collection of top brands at or below wholesale prices.`
      },
      {
        question: 'What types of products are on HUBX?',
        answer: 'The core focus is computers and consumer electronics.'
      },
      {
        question: 'I signed up, what’s next?',
        answer: `Once you have signed up, your information goes through an approval process.
         If you are suitable for our marketplace we will be in touch with you.`
      },
      {
        question: 'Who is behind HUBX?',
        answer: `HUBX was founded by a team of serial entrepreneurs who have played
        an integral role in building some of the largest marketplace platforms in the world`
      },
      {
        question: `I'm an individual how do I buy a product?`,
        answer: 'HUBX is a B2B marketplace platform for business wholesale buyers only.  We do not sell to individuals.'
      },
      {
        question: 'Do you ship International?',
        answer: 'Yes, we ship to over 60 countries.'
      },
      {
        question: 'I placed an order, how can I track it?',
        answer: `Once an order is shipped we will provide you with tracking information via email
        and accessible in your portal. If you don't
        receive this information, please feel free to contact us and we can provide that for you.`
      },
      {
        question: 'Do you provide expedited shipping options?',
        answer: 'All product availability is clearly stated per individual SKU. Our average shipping timeframe is 4-5 business days.'
      },
      {
        question: 'I placed an order, can I pick it up at your logistics center?',
        answer: 'Yes, you will have to contact your sales representative to arrange a local pick-up.'
      },
      {
        question: 'What are the prices on HUBX?',
        answer: 'HUBX offers our customers the most competitive prices the market has to offer.  Prices are either at or below wholesale.'
      },
      {
        question: 'Do you take Credit Cards?',
        answer: 'At this time, we do not accept credit cards. Customers either pre-pay or receive NET terms on approved applications.'
      },
      {
        question: 'Do you charge sales tax?',
        answer: 'HUBX is a wholesale marketplace, therefore sales taxes does not apply.'
      },
      {
        question: 'Where do you ship from?',
        answer: 'We ship from our fulfillment center in Miami, Florida'
      },
      {
        question: 'Can my company get NET terms?',
        answer: 'We extend terms on approved applications.'
      },
      {
        question: 'How do I contact a sales rep?',
        answer: 'Once you have been approved you will be assigned a dedicated sales representative.'
      },
      {
        question: 'What type of quality is Refurbished?',
        answer: 'Unless stated otherwise all HUBX products are New, Factory Sealed or Grade A Factory Refurbished product.'
      },
      {
        question: 'Are your products packaged in Retail or Brown boxed?',
        answer: `Packing information is made available to you in the product listing.
        In the case that this information may not be available please feel free to contact us directly
         with specific questions.`
      },
      {
        question: `Do your products come with a warranty?`,
        answer: `The majority of HUBX products offer a warranty. Warranties vary depending on the products you are
                 interested in. Detailed warranty information is made available to you in the product listing.
                 If you have any specific questions, please don't hesitate to contact us.`
      },
      {
        question: 'Is there a membership fee to gain access?',
        answer: 'There is no membership fee to gain access to HUBX.'
      }
    ],
  vendor: [
      {
        question: 'What is HUBX?',
        answer: `HUBX is the first hybrid B2B marketplace distributor for buying and selling computers,
         mobile and consumer electronics. Our platform gives buyers access to an exclusive collection of top brands
          at or below wholesale prices.`
    },
    {
      question: 'What type of vendors are invited to sell on HUBX?',
      answer: `HUBX vendors primarily are manufacturers, distributors, wholesalers, retailers,
       reverse logistic companies, EOL Vendors, liquidators and off-price vendors.`
    },
        {
          question: 'Do I have to ship internationally?',
          answer: 'No, all shipments will be sent to our distribution center.'
      },
      {
        question: 'Do I have to drop ship to end users?',
        answer: 'No, orders are sent to our logistics center. From there, we consolidate shipments to customers.'
    },
    {
      question: 'How do I get paid?',
      answer: `We wire payment upon receipt of confirmation of shipment. It's that simple.`
    },
    {
      question: 'Do I have to hold stock?',
      answer: 'Yes, similar to selling on all marketplaces such as Amazon you will have to hold stock you are selling on HUBX.'
    },
    {
      question: 'How often do I update inventory?',
      answer: 'We recommend you monitor inventory levels on a daily basis.'
    },
    {
      question: 'How do I update inventory?',
      answer: 'Inventory is updated real-time through your vendor dashboard.'
    },
    {
      question: 'Can I restrict regions?',
      answer: 'Yes, your product listings can include any product restrictions you would like to communicate to our customers.'
    },
    {
      question: 'Do I receive customer information?',
      answer: `No, HUBX does not release customer information. HUBX acts as the customer on all orders.
       Orders will be received to HUBX Logistics Center and sent to the customer.`
    },
    {
      question: 'How do I receive orders?',
      answer: 'Orders will be emailed to the order email address designated in your vendor portal.'
    },
    {
      question: 'What information will be available in my dashboard?',
      answer: `All the functions you need to sell, manage, maintain and fulfill your
                product are available in your vendor portal.  For example, from your vendor portal you can add products,
                 manage inventory, update pricing and receive sales analytics and order reports.`
    },
    {
      question: 'Do you have an API?',
      answer: 'We are in the process of pushing out a RESTful product API for endless all integration purposes.'
    },
    {
      question: 'Can I sell any type of product quality?',
      answer: `Each of our vendors are fully vetted and meet the highest quality standards.
      Exceeding customer expectations is what we strive for with every transaction.
      Product requirements are: new, factory refurbished or grade-A refurbished with an end user warranty.`
    },
    {
      question: 'Who is behind HUBX?',
      answer: `HUBX was founded by a team of serial entrepreneurs who have played an integral role
       in building some of the largest marketplace platforms in the world.`
    },
    {
      question: 'What is the cost to sell on HUBX?',
      answer:  `There are no fees to become a vendor on HUBX. It is free to list products on our platform.
        HUBX is a CPA (cost per acquisition) business model whereby we only take a commission once your product sells.`
    },
  ]
    },
    guestRegisterMessageX: {
      message: 'HUBX provides you access to hundreds of Daily Deals at or below wholesale ' +
        'prices for new and refurbished Mobile Phones, Laptops, Desktops, AIOs, Tablets, Gaming Devices, Consoles & more.'
    },
    todaysDeals: '19dc4e71-4e14-408f-953e-ba05fe986b6c',
    todaysDealsAttribute: '433dd94e-e3ab-4c5d-9942-c9c31a726312',
    todaysDealsLabel: 'Today\'s Deals',
    notificationRefreshingWait: 250,
    offerListPageSize: 100
};
