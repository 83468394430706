<div class="modal animated fadeInUp" [class.fadeOutDown]="onModalClose">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title-wrapper">
          <h1 class="modal-title">*$500 OFF Your First Mobile Phone Order</h1>
          <p class="modal-description">This offer applies to existing HUBX customers or new customer sign-ups. Please contact your sales representative after you place your order to apply the $500 discount.
          	(*$500 off first order of $10K or more. Applies to ONLY mobile phone’s, all other products are excluded from this offer)
          </p>
        </div>
        <div class="info-wrapper">
        	<h2>Choose from thousands of units from Apple, Samsung, BLU & More.</h2>
        	<ul>
        		<li>New Factory Sealed</li>
        		<li>Certified Preowned (CPO)</li>
        		<li>Factory Refurbished</li>
        	</ul>
        </div>
        <button type="button" class="close" (click)="closeModal()" aria-label="Close">
          <img src="assets/images/icons/delete-white.svg" width="24" height="24">
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <h3 class="header-bold">Apple & Samsung Refurbished A/B</h3>
            <p>We pride ourselves on our sleek and elegant packaging and designs. All packaging, designs, inserts are FREE from any HUBX references, messaging or branding. All A/B refurbished phones are brought back to life by our experts at our state-of-the-art refurbishing facility. </p>
          </div>
        </div>
        <div class="row phone-row">
          <div class="col">
            <div class="phone-values">
              <div class="phone-value">
                <!--<img src="assets/images/bonus-full/1l-tested.png" width="75" height="75">-->
                <i class="_1l-tested"></i>
                <span>L1 Tested</span>
              </div>
              <div class="phone-value">
                <!--<img src="assets/images/bonus-full/tested-battery.png" width="75" height="75">-->
                <i class="_tested-battery"></i>
                <span>Tested Battery</span>
              </div>
              <div class="phone-value">
                <!--<img src="assets/images/bonus-full/functional.png" width="75" height="75">-->
                <i class="_functional"></i>
                <span>100% Functional</span>
              </div>
              <div class="phone-value">
                <!--<img src="assets/images/bonus-full/unlocked.png" width="75" height="75">-->
                <i class="_unlocked"></i>
                <span>Unlocked</span>
              </div>
              <div class="phone-value">
                <!--<img src="assets/images/bonus-full/90-day-warranty.png" width="75" height="75">-->
                <i class="_warranty"></i>
                <span>90 Day Warranty</span>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
      </div>
    </div>
  </div>
</div>
