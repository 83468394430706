import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  activeRequests = 0;
  browserRefresh = false;

  constructor(
    private loaderService: LoaderService,
    private router: Router
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.browserRefresh = !router.navigated;
      }
      if (val instanceof NavigationEnd && !this.browserRefresh) {
        this.loaderService.hideLoader();
        this.activeRequests = 0;
      }
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers = {};

    if (!this.loaderService.nonDecrementalUrls.includes(req.url)) {
      if (!this.router.url.match(/offers/)) { // avoid spinner in offers
        this.activeRequests++;
      }      
    }

    if (this.activeRequests > 0) {
      this.loaderService.showLoader();
    }

    const request = req.clone({
      setHeaders: headers,
      params: null,
      withCredentials: false
    });

    return next.handle(request).pipe(
      finalize(() => {
        if ( !this.loaderService.nonDecrementalUrls.includes(request.url)) {
          this.activeRequests--;
        } else {
          this.loaderService.removeNonDecrementalUrl(request.url);
        }
        if (this.activeRequests === 0) {
          this.loaderService.hideLoader();
        }
      })
    );
  }
}
