import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PubSubService } from '../../../core/pubsub.service';
import { TopService } from '../../../shared/services/top.service';
import { SharedSource } from '../../../core/shared-source';
import { NotificationFiltersEnum } from '../../../shared/interfaces/INotificationDetail';

@Component({
  selector: 'app-notification-center-helper',
  templateUrl: './notification-center-helper.component.html',
  styleUrls: ['./notification-center-helper.component.scss']
})
export class NotificationCenterHelperComponent implements OnInit, OnDestroy {
  isArchive = false;
  text = 'Notifications';
  subscriptions: Subscription[] = [];

  constructor(private _pubSharedSvc: PubSubService, public topService: TopService) { }

  ngOnInit() {
    this.subscriptions.push(
      this._pubSharedSvc.sharedSubject
      .subscribe(event => {
        if (event.name === SharedSource.notificationTabBuyer) {
          this.isArchive = (event.data === NotificationFiltersEnum.archive);
          this.text = (this.isArchive) ? 'Archives' : 'Notifications';
        }
      })
  );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
