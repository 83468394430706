<div [ngClass]="{'mobile': topService.isMobile, 'animated flipInY': mobile || !mobile, 'from-vendor': fromVendor}">
    <div class="login-header">Reset Password</div>
    <div class="login-body">
      <form>
        <div class="form-group">
          <input (keyup)="clearMessages();" [ngClass]="{'mobile': isMobile}" 
          (keyup.enter)="validateEmail(email.value);" [value]="shownEmail ? shownEmail : ''" type="email" class="form-control"
          #email placeholder="Email" >

        </div>
        <a (click)="validateEmail(email.value);" class="login-button">
          <span *ngIf="!loading">Reset Password</span>
          <div *ngIf="loading" class="login-loader"></div>
        </a>
      </form>
    </div>
    
    <div class='message-wrapper'>
      <div *ngIf="error_on_email_input" class='message-error'>
        Please input valid email
      </div>
      <div *ngIf="error_on_email_not_found" class='message-error'>
        Email not found. Please register.
      </div>
      <div *ngIf="resetFailed" class='message-error'>
        {{ errorMsg }}
      </div>
      <div *ngIf="resetIsuccessful" class='message-success'>
        {{ forgotPassPreMessage }}
      </div>
    </div>

    <div class="login-footer"><a (click)="showLogin()" class="link-button">Cancel</a></div>
</div>