<div *ngIf="topService.loading && !['ADMIN', 'SELLER', 'VENDORMGR'].includes(sessionService.userRole)" class="loader"></div>
<div class="user-profile" [class.mobile]="topService.isMobile">
  <div class="user-profile-section">
    <div class="user-profile-info">
      <div class="user-profile-info_company">{{userService.companyName}}</div>
      <div class="user-profile-info_group">
        <span class="user-profile-info_group-name">SAP BP Code</span>
        <span class="user-profile-info_group-value">{{userService.bpCode}}</span>
      </div>
      <div class="user-profile-info_group">
        <span class="user-profile-info_group-name">Contact</span>
        <span class="user-profile-info_group-value">{{userService.contactEmail}}</span>
      </div>
    </div>
    <div class="user-profile-buttons">
      <div class="user-profile-buttons_group">
        <a (click)="changePassword()" class="user-profile-buttons_reset">Reset Password</a>
        <a (click)=logout(); class="user-profile-buttons_logout">Log Out</a>
      </div>
    </div>
  </div>

  <div *ngIf="resetAlert" class="reset-pwd-modal">
    <div class="add-to-wrap animated" [class.zoomIn]="resetAlert" [class.zoomOut]="animateOut">
      <div class="modal-content">
        <div class="modal-header">
          <button (click)="resetAlert = !resetAlert" type="button" class="close" aria-label="Close"></button>
          <div class="d-flex align-items-start">
            <div class="align-self-center modal-title">Reset Password</div>
          </div>
        </div>
        <div class="modal-body">
          Please check your email to reset your HUBX password!
        </div>
        <div class="modal-footer">
          <button type="button" (click)="resetAlert = !resetAlert" class="btn">Got it</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Vendor Profile -->
<!-- <div class="user-account-global" *ngIf="userService.isVendor">
  <div class="col-md-12 main-profile">
    <div class="profile col-md-3">
      <div class="col-md-12 company">{{userService.companyName}}</div>
      <div class="col-12 info">
        <ul>
          <li class="title"><img src="assets/images/location.fw.png" width="12px" height="16px" /> LOCATION</li>
          <li>{{fullAddress}}</li>
        </ul>
        <ul>
          <li class="title"><img src="assets/images/user.fw.png" width="14px" height="16px" /> CONTACT</li>
          <li>{{userService.contactEmail}}</li>
        </ul>
        <ul>
          <li class="title"><img src="assets/images/sap.fw.png" width="16px" height="16px" /> SAP BP CODE</li>
          <li>{{userService.bpCode}}</li>
        </ul>
        <div class="col-md-12 buttons">
          <a (click)="changePassword()" class="btn reset">Reset Password</a>
          <a (click)="logout()" class="btn logout">Log Out</a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="resetAlert" class="reset-pwd-modal">
    <div class="add-to-wrap animated" [class.zoomIn]="resetAlert" [class.zoomOut]="animateOut">
      <div class="modal-content">
        <div class="modal-header">
          <button (click)="resetAlert = !resetAlert" type="button" class="close" aria-label="Close"></button>
          <div class="d-flex align-items-start">
            <div class="align-self-center title-icon"><img src="../../../assets/images/reset-password-icon.svg" width="48"
                heigh="48" /></div>
            <div class="align-self-center modal-title">Reset Password</div>
          </div>
        </div>
        <div class="modal-body">
          Please check your email!
        </div>
        <div class="modal-footer">
          <button type="button" (click)="resetAlert = !resetAlert" class="btn btn-modal btn-modal-confirm">Got it</button>
        </div>
      </div>
    </div>
  </div>
</div> -->