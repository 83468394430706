<div class="loader" *ngIf="topService.loading && calledFrom === 'user-profile'"></div>
<div class="credit-cards" [class._user-profile]="calledFrom === 'user-profile'" [class.mobile]="topService.isMobile">
  <div class="header" *ngIf="calledFrom === 'user-profile'">
    <h2>Credit Cards</h2>
  </div>
  <div class="cards-list">
      <div class="card" *ngFor="let card of creditCardService.creditCardList" [class._expanded]="card.showVerificationForm">
        <div class="card-info" (click)="chooseCard(card)">
          <div role="button" class="card-logo"           
          [ngClass]="{'_selected': card?.id === creditCardService?.selectedCreditCard?.id,
                      'no-action': calledFrom === 'user-profile'}">
            <img alt="AMEX 4567" [src]="'assets/images/icons/credit-cards/' + card.type + '.svg'" width="32"
              height="32" />
            <i class="card-logo-check" *ngIf="calledFrom === 'cart'"></i>
          </div>
          <div class="card-name">
            {{ card.nickname | uppercase }}
          </div>
          <div class="card-controls" (click)="$event.stopPropagation()">
            <a role="button" class="card-controls__edit" (click)="showCreditCard(card.id)">
              <i> <img alt="Edit Card" src="assets/images/icons/rebranding/edit.svg" width="24" height="24" /> </i>
            </a>
            <a role="button" class="card-controls__delete" (click)="delete(card.id)">
              <i> <img alt="Delete Card" src="assets/images/icons/rebranding/delete-gray.svg" width="24" height="24" /> </i>
            </a>
          </div>
        </div>

      <div class="card-edit">
        <div class="card-form">
          <div class="card-form__left">
            <div class="control">
              <div class="control__label">Enter CCV</div>
              <div>
                <input type="text" placeholder="123" [(ngModel)]="card.verficationCode" [class.invalid]="card.valid === false"
                autocomplete="off" [maxLength]="4" digitOnly>
              </div>
            </div>
          </div>
          <div class="card-form__right">
            <button type="button" class="confrim-button" [disabled]="!card.verficationCode || topService.loading" (click)="verifyCardCode(card)">
              Confirm
            </button>
          </div>
        </div>
      </div>
  </div>
  <div class="cards-add">
    <a role="button" (click)="showCreditCard()"> + Add a New Card</a>
  </div>
</div>

<app-credit-card *ngIf="showCreditCardModal" [creditCardId]="creditCardIdToEdit"
  (closeCrditCardModal)="closeCreditCardModal($event)"></app-credit-card>