<div class="price-list-loader-modal-wrap">
  <div class="price-list-loader-modal" [class.mobile]="topService.isMobile">
    <div class="price-list-loader-modal__icon" *ngIf="isVector">
      <img [src]="iconUrl"
      [style.width.px]="(topService.currentListingMeta) ? '94': '40'"
      [style.height.px]="(topService.currentListingMeta) ? '56': '40'"
      alt="Price List"/>
    </div>
    <div class="price-list-loader-modal__bg" *ngIf="!isVector">
      <div [style.background-image] = "'url('+iconUrl+')'"></div>
    </div>
    <div class="price-list-loader-modal__title">
       <ng-container *ngIf="!topService.currentListingMeta; else specificListing">
        Preparing a Price <br>List for Download
      </ng-container>

      <ng-template #specificListing>
        <div class="specific-listing">
          Preparing<br>{{topService.currentListingMeta?.label}} for Download
        </div>
      </ng-template>
    </div>
    <span class="price-list-loader-modal__txt">
    	Do not close the window, the <br>download will start automatically <br>after preparation.
	</span>
    <div class="price-list-loader-modal__img">
      <img src="../../../assets/images/spinner-blue.gif" width="64" height="64" alt="loading"/>
    </div>
    <div class="price-list-loader-modal__bottom">
    	<span>
    		This usually takes less than a minute
    	</span>
   	</div>
   	<span class="price-list-loader-modal__close" (click)="topService.cancelPriceListDownload()">
  	</span>
  </div>
</div>
