import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ICartItem} from '../../../interfaces/model';
import { CartService } from '../../cart.service';
import { TopService } from '../../../../shared/services/top.service';
import { SharedService } from '../../../../shared/shared.service';
import { SessionService } from '../../../../services/session.service';
import { ItemsService } from '../../../../shared/services/items.service';
import { PubSubService } from '../../../../core/pubsub.service';
import { SharedSource } from '../../../../core/shared-source';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pre-cart-child',
  templateUrl: './pre-cart-child.component.html',
  styleUrls: ['./pre-cart-child.component.scss']
})
export class PreCartChildComponent implements OnInit {
  @Input() preCartItem: ICartItem;
  @Input() showDetailsActive = -1;
  @Input() index = 0;
  @Input() deleteSpinner: boolean;
  @Input() deleteSpinnerItem: string;
  @Output() updateItemQty = new EventEmitter<{cartItem: ICartItem, newQty: number}>();
  @Output() deleteItemEmitter = new EventEmitter<ICartItem>();
  pubServiceSubscription: Subscription;
  user_role: string;
  itemDescription = '';
  currentIndex: number;

  constructor(public cartService: CartService,
    public topService: TopService,
    public sharedService: SharedService,
    private pubSubService: PubSubService,
    public itemsService: ItemsService,
    public sessionService: SessionService) { }

  ngOnInit() {
    this.user_role = this.sessionService.userRole;
    this.setupPubSub();
  }

  setupPubSub(): void {
    this.pubServiceSubscription = this.pubSubService.sharedSubject.subscribe(myEvent => {
      if (myEvent.name === SharedSource.newPriceUpdated) {
        if (myEvent.data.id === this.preCartItem.item.id) {
          this.preCartItem.unitPrice = myEvent.data.baseUnitPrice;
        }
      }
    });
  }

  deleteItem(): void {
    if (this.deleteSpinner) {
      return;
    }
    this.deleteItemEmitter.emit(this.preCartItem);
  }

  handleText(item: ICartItem, value: string): void {
    item.previousITemQuantity = item.quantity;
  }

  updateQty(cartItem: ICartItem, newQty: number): void {
    this.updateItemQty.emit({cartItem, newQty});
    // this.sharedService.newPrice = true;
  }

  isQtyEditable(item: ICartItem): boolean {
    return !(this.user_role !== 'SALES' || item.orderLineNum === -1);
  }

  truncateDescription(description: string, length: number): string {
    if (description.length > length) {
      description = description.substring(0, length);
      this.itemDescription = description.replace(/w+$/, '') + '...';
    } else {
      this.itemDescription = description;
    }
    return this.itemDescription;
  }

  showDetails(index: number): void {
    if (this.currentIndex !== index || this.showDetailsActive === -1) {
      this.currentIndex = index;
      this.showDetailsActive = index;
    } else {
      this.showDetailsActive = -1;
    }
  }
}
