import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'CustomOfferCurrency' })

export class CustomOfferCurrencyPipe implements PipeTransform {
  transform(value: number, precision = 2): string {
    value = ((value) ? value : 0);
    let displayPrice = '$';
    try {
      displayPrice = '$' + value.toFixed(precision).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      return displayPrice;
    } catch (ex) {
      // console.log(ex);
      return displayPrice;
    }
  }
}
