<div class="notifier" [class.mobile]="topService.isMobile" 
	*ngIf="topService.newVersionAvailable && authService.isAuthenticated() && !cartService.isPurchaseInProgress">
	<div class="notifier-wrap">
		<div class="notifier-body">
			<div class="notifier-body__img">
			
			</div>
			<div class="notifier-body__text">
				<h2>New version of HUBX is available</h2>
				<p>We are constantly improving in order to provide you with the best experience possible. Reload the page to access the new version.</p>
			</div>
			<div class="notifier-body-ctrl">
				<a role="button" class="notifier-body-ctrl__btn" 
				(click)="hardReload()">
					Reload
				</a>
			</div>
		</div>
	</div>
</div>