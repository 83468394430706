<div class="cart-top-box">
    <div class="box-title">
     {{addressTitle}}
      <div class="box-title-edit" *ngIf="addresses && addresses.length>1"
        (click)="showPopup = !showPopup "
      ></div>

    </div>
    <div class="box-name">{{address.addressCode}}</div>
    <div class="box-content">{{address.address1}}</div>
    <div class="box-content">{{address.address2}}</div>
    <div class="box-content">{{address.city}} {{address.state}} {{address.country}} {{address.zip}}</div>
  </div>


  <div *ngIf="showPopup" class="address-modal">
      <div class="add-to-wrap animated" [class.zoomIn]="showPopup" [class.zoomOut]="animateOut">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" (click)="showPopup = !showPopup" class="close" aria-label="Close"></button>
            <h4 class="modal-title" id="billToAddressLabel">{{dropdownTitle}}</h4>
          </div>
          <div class="modal-body">
            <div *ngFor="let address of addresses; let i = index" class="modal-address-radio"
            [ngClass]="{'active':address.addressCode === currentAddress}">
              <div class="form-group">
                <div class="address-descriptlon-block">
                  <div class="address-title">
                    {{address.addressCode}}
                  </div>
                  <div class="address-description-text">
                    <p>{{address.address1}} {{address.address2}}</p>
                    <p>{{address.city}} {{address.state}} {{address.zip}} {{address.country}}</p>
                  </div>
                </div>
                <div class="address-radio-block">
                  <input type="radio"
                         [(ngModel)]="currentAddress"
                         (click)="currentAddress=address.addressCode"
                         name="addressCode"
                         id="addressCode{{i + 1}}"
                         value="{{address.addressCode}}">
                  <label for="addressCode{{i + 1}}"></label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" (click)="currentAddress=addressCode;closeModal()" class="btn btn-modal btn-modal-cancel"
              data-dismiss="modal">Cancel</button>
            <button type="button" (click)="selectAddress()" class="btn btn-modal btn-modal-confirm">Confirm</button>
          </div>
        </div>
      </div>
    </div>
