<div class="purchases-guide" [class.mobile]="topService.isMobile">
	<div class="nothing-found row">
		<div class="col">
			<div class="info-block">
				<img width="60" height="60" src="assets/images/purchases/empty-past-purchase.svg">
				<span>
					You have not bought anything yet!
				</span>
			</div>
		</div>
	</div>

	<div class="purchases-info-section row justify-content-start align-items-center">
		<div class="col-7" *ngIf="!topService.isMobile">
			<img width="529" height="455" src="assets/images/purchases/empty-past-purchase@2x.png">
		</div>
		<div class="col-12" *ngIf="topService.isMobile">
			<img width="100%" height="auto" src="assets/images/purchases/past-purchased-mobile.png">
		</div>
		<div class="col-4">
			<h2>You have not bought <br> anything yet!</h2>
			<p>Past item purchases will show up here where you can search, compare current and past prices and repurchase.</p>
		</div>
	</div>
</div>
