import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-item-restriction',
  templateUrl: './item-restriction.component.html',
  styleUrls: ['./item-restriction.component.scss']
})
export class ItemRestrictionComponent implements OnInit {
  @Input() fg: FormGroup;
  @Input() currentRestriction: string;
  @Output() closeModal = new EventEmitter();
  showInfoModal;
  newRestriction: AbstractControl;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    const currentControlVal = this.fg.controls.restrictionDescription.value;
    if ( currentControlVal === this.currentRestriction) {
      this.newRestriction = this.fb.control('');
    } else {
      this.newRestriction = this.fb.control(currentControlVal);
    }
  }

  close(): void {
    this.closeModal.emit();
  }

  patchRestriction() {
    this.fg.controls.restrictionDescription.patchValue(this.newRestriction.value);
    this.close();
  }

  useOldRestriction() {
    this.newRestriction.patchValue(this.currentRestriction);
  }

}
