import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import * as models from '../../../interfaces/model';
import { CartItemsService } from '../cart-items.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SessionService } from '../../../../services/session.service';
import { IItemAttribute } from '../../../interfaces/model';
import { ItemsService } from '../../../../shared/services/items.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-cart-items-mobile',
  templateUrl: './cart-items-mobile.component.html',
  styleUrls: ['./cart-items-mobile.component.scss']
})
export class CartItemsMobileComponent implements OnInit {
  @Input() i: number;
  @Input() cartItem: models.ICartItem;
  @Input() imageBaseUrl: string;
  @Output() copyStateEmitter = new EventEmitter();
  @Input() userRole: string;
  @Input() isOutOfStock: boolean;
  @Output() handlePriceUpdateEmitter = new EventEmitter<object>();
  @Input() showWarning: boolean;
  @Output() handleQuantityUpdateEmitter = new EventEmitter<models.ICartItem>();
  @Input() deleteSpinner: boolean;
  @Input() deleteSpinnerItem: string;
  @Input() loading: boolean;
  @Output() deleteItemEmitter = new EventEmitter<models.ICartItem>();
  @Output() gradingGuideEmitter = new EventEmitter<models.IItem>();

  @Output() notificationTooltipEmitter = new EventEmitter<{ item: models.IItem, event: Event }>();
  @Output() onTooltipClose = new EventEmitter<null>();


  isDetailsOpened = false;
  subscription: Subscription;
  searchSubject: BehaviorSubject<models.ICartItem> = new BehaviorSubject(null);
  environment = environment;


  constructor(
    public cartItemsService: CartItemsService,
    private sessionService: SessionService,
    public itemsService: ItemsService,
  ) { }

  ngOnInit() {
    this.subscription = this.searchSubject.pipe(
      debounceTime(500)
    ).subscribe(cartI => {
      if (!cartI || !cartI.quantity) { return; }
      this.handleQuantityUpdateEmitter.emit(cartI);
    }
    );
  }

  toggleDetails(event: Event, shouldClose?: boolean): void {
    this.isDetailsOpened = shouldClose ? false : !this.isDetailsOpened;
  }

  showDetails() {
    this.isDetailsOpened = true;
  }
  hideDetails() {
    this.isDetailsOpened = false;
  }

  openNotificationTooltip(event: any): void {
    const item = this.cartItem.item;
    item.qty = this.cartItem.quantity;
    item.added = true;
    item.inCart = true;
    item.unitPrice = this.cartItem.unitPrice;

    event.preventDefault();
    this.notificationTooltipEmitter.emit({ item, event });
    event.stopPropagation();
  }

  closeNotificationTooltip(event) {
    this.onTooltipClose.emit();
  }

  customerIsSelectedFlag(): string {
    return this.sessionService.isCustomerSelected;
  }

  getVisibleAttributes(): IItemAttribute[] {
    return this.cartItem.item.attributes.filter(a => !a.hide);
  }

  isTopAttributes(attr): boolean {
    const topAttrArr = attr.filter(attr => attr.label === 'Just Launched'
      || attr.label === 'Today\'s Deals' || attr.label === 'Price Drop');
    return topAttrArr.length > 0;
  }


}
