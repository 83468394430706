import { IVendorItemPrice } from '../interface/IVendorItemPrice';

export class Globals {
   public static ESCAPE_KEYCODE = 27;

   // creates a deep copy of any object/list and returns the newly created list
   public static jsonStringifyParser(obj: any): any {
        return JSON.parse(JSON.stringify(obj));
   }

   public static createDiscountQtys(prices: IVendorItemPrice[]): IVendorItemPrice[] {
    if (prices.length === 3) {
        return prices;
    }

    const qtysRemainingCount = 3 - prices.length;

    for (let i = 0; i < qtysRemainingCount; i++) {
        prices.push({fromQty: null, unitPrice: null});
    }

    return prices;
}
}
