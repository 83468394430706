import { SharedSource } from './../core/shared-source';
import { IAuthResult } from './../shared/interfaces/IAuthResult';
import { environment } from './../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';
import qs from 'qs';
import IdTokenVerifier from 'idtoken-verifier';
import { SharedService } from '../shared/shared.service';
@Injectable({
  providedIn: 'root'
})
export class HubxAuthService {
  domain: string;
  adminDomain: string;
  clientID: string;
  redirectUri: string;
  audience: string;
  responseType: string;
  scope: string;

  headersConfig = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  constructor(
    private http: HttpClient, 
    private sharedService: SharedService) {}

  WebAuth(_params: any): HubxAuthService {
    this.domain = _params.domain;
    this.adminDomain = _params.adminDomain;
    this.clientID = _params.clientID;
    this.redirectUri = _params.redirectUri;
    this.audience = _params.audience;
    this.responseType = _params.responseType;
    this.scope = _params.scope;
    

    return this;
  }

  private requestToken(options) {
    const params = new HttpParams()
    .set('audience', this.audience)
    .set('client_id', this.clientID)
    .set('username', options.realm + '\\' + options.username)
    .set('grant_type', 'password')
    .set('password', options.password)
    // .set('scope', this.scope);
    // this.http.post(this.domain + `connect/token`, params, this.headersConfig).subscribe();
    // tslint:disable-next-line:no-shadowed-variable
    return new Promise((resolve, reject) => {
        this._httpRequest(this.domain + `connect/token`, 'POST', params, resolve, reject);
      });
  }

  authorize() {}

  // tslint:disable-next-line:no-shadowed-variable
  private _httpRequest(path, type, _params, resolve, reject) {
    const url = `${path}`;
    const headers = new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded');
    const params = _params; // { params: this.paramsToUrlencoded(_params) };
    if (type === 'GET') {
      this.http.get(url, {headers, params})
        .subscribe(response => {
          resolve(response);
        }, error => {
          this._httpResponseError(error, path, type, params, resolve, reject);
        });
    } else if (type === 'POST') {
      this.http.post(url, params, {headers})
        .subscribe(response => {
          resolve(response);
        }, error => {
          this._httpResponseError(error, path, type, params, resolve, reject);
        });
    } else if (type === 'DELETE') {
      this.http.delete(url, {headers})
        .subscribe(response => {
          resolve(response);
        }, error => {
          this._httpResponseError(error, path, type, params, resolve, reject);
        });
    } else if (type === 'PATCH') {
      this.http.patch(url, params, {headers})
        .subscribe(response => {
          resolve(response);
        }, error => {
          this._httpResponseError(error, path, type, params, resolve, reject);
        });
    }
  }

  login = function(options, cb) {
    this.requestToken(options).then( _res => {
      const response = JSON.parse(JSON.stringify(_res));
      const authResult = {
        accessToken: response.access_token,
        expiresIn: response.expires_in,
        tokenType: response.token_type,
        refreshToken: null,
        idToken: null,
      } as IAuthResult;
      return cb(null, authResult);
    }, error => {
      return cb(error, null);
    });
  };

  parseHash = function(options, cb?) {
    let parsedQs;
    let err;

    if (!cb && typeof options === 'function') {
      cb = options;
      options = {};
    } else {
      options = options || {};
    }

    // let _window = windowHelper.getWindow();

    let hashStr =
      options.hash === undefined ? window.location.hash : options.hash;
    hashStr = hashStr.replace(/^#?\/?/, '');

    parsedQs = qs.parse(hashStr);

    if (parsedQs.hasOwnProperty('error')) {
      err = this.buildResponse(parsedQs.error, parsedQs.error_description);

      if (parsedQs.state) {
        err.state = parsedQs.state;
      }

      return cb(err);
    }

    if (
      !parsedQs.hasOwnProperty('access_token') &&
      !parsedQs.hasOwnProperty('id_token') &&
      !parsedQs.hasOwnProperty('refresh_token')
    ) {
      return cb(null, null);
    }
    const responseTypes = (
      this.baseOptions.responseType ||
      options.responseType ||
      ''
    ).split(' ');
    if (
      responseTypes.length > 0 &&
      responseTypes.indexOf('token') !== -1 &&
      !parsedQs.hasOwnProperty('access_token')
    ) {
      return cb(
        this.buildResponse(
          'invalid_hash',
          'response_type contains `token`, but the parsed hash does not contain an `access_token` property'
        )
      );
    }
    if (
      responseTypes.length > 0 &&
      responseTypes.indexOf('id_token') !== -1 &&
      !parsedQs.hasOwnProperty('id_token')
    ) {
      return cb(
        this.buildResponse(
          'invalid_hash',
          'response_type contains `id_token`, but the parsed hash does not contain an `id_token` property'
        )
      );
    }
    return this.validateAuthenticationResponse(options, parsedQs, cb);
  };

  changePassword = function(options, cb) {
    const params = {
      userName: options.connection + '\\' + options.email,
      clientId: environment.hubx_auth_clientId
    };
    this.http.post(this.adminDomain + 'api/Users/forgotreqs', params)
      .subscribe((_res) => {
        const response = JSON.parse(JSON.stringify(_res));
        if (response.result) {
          this.sharedService.forgotPwdFailed = false;
          return cb(response.result, _res);
        }
      }, error => {
        this.sharedService.forgotPwdFailed = true;
        return cb(error, null);
      });
  };

  buildResponse(error, description) {
    return {
      error: error,
      errorDescription: description
    };
  }

  invalidToken(description) {
    return this.buildResponse('invalid_token', description);
  }

  validateAuthenticationResponse = function(options, parsedHash, cb) {
    const _this = this;
    options.__enableIdPInitiatedLogin =
      options.__enableIdPInitiatedLogin || options.__enableImpersonation;
    const state = parsedHash.state;
    const transaction = this.transactionManager.getStoredTransaction(state);
    const transactionState =
      options.state || (transaction && transaction.state) || null;

    const transactionStateMatchesState = transactionState === state;
    const shouldBypassStateChecking =
      !state && !transactionState && options.__enableIdPInitiatedLogin;

    if (!shouldBypassStateChecking && !transactionStateMatchesState) {
      return cb({
        error: 'invalid_token',
        errorDescription: '`state` does not match.'
      });
    }
    const transactionNonce =
      options.nonce || (transaction && transaction.nonce) || null;

    const appState = options.state || (transaction && transaction.appState) || null;

    const callback = function(err, payload?) {
      if (err) {
        return cb(err);
      }
      if (transaction && transaction.lastUsedConnection) {
        let sub;
        if (payload) {
          sub = payload.sub;
        }
        _this.ssodataStorage.set(transaction.lastUsedConnection, sub);
      }
      return cb(null, this.buildParseHashResponse(parsedHash, appState, payload));
    };

    if (!parsedHash.id_token) {
      return callback(null, null);
    }
    return this.validateToken(parsedHash.id_token, transactionNonce, function(
      validationError,
      payload
    ) {
      if (!validationError) {
        if (!parsedHash.access_token) {
          return callback(null, payload);
        }
        // id_token's generated by non-oidc applications don't have at_hash
        if (!payload.at_hash) {
          return callback(null, payload);
        }
        // here we're absolutely sure that the id_token's alg is RS256
        // and that the id_token is valid, so we can check the access_token
        return new IdTokenVerifier().validateAccessToken(
          parsedHash.access_token,
          'RS256',
          payload.at_hash,
          function(err) {
            if (err) {
              return callback(this.invalidToken(err.message));
            }
            return callback(null, payload);
          }
        );
      }
      if (
        validationError.error !== 'invalid_token' ||
        validationError.errorDescription === 'Nonce does not match.'
      ) {
        return callback(validationError);
      }
      // if it's an invalid_token error, decode the token
      const decodedToken = new IdTokenVerifier().decode(parsedHash.id_token);
      // if the alg is not HS256, return the raw error
      if (decodedToken.header.alg !== 'HS256') {
        return callback(validationError);
      }
      if ((decodedToken.payload.nonce || null) !== transactionNonce) {
        return callback({
          error: 'invalid_token',
          errorDescription: 'Nonce does not match.'
        });
      }
      if (!parsedHash.access_token) {
        const noAccessTokenError = {
          error: 'invalid_token',
          description:
            'The id_token cannot be validated because it was signed with the HS256 algorithm and public clients (like a browser) can’t store secrets. Please read the associated doc for possible ways to fix this. Read more: https://auth0.com/docs/errors/libraries/auth0-js/invalid-token#parsing-an-hs256-signed-id-token-without-an-access-token'
        };
        return callback(noAccessTokenError);
      }
      // if the alg is HS256, use the /userinfo endpoint to build the payload
      return _this.client.userInfo(parsedHash.access_token, function(
        errUserInfo,
        profile
      ) {
        // if the /userinfo request fails, use the validationError instead
        if (errUserInfo) {
          return callback(errUserInfo);
        }
        return callback(null, profile);
      });
    });
  };

  buildParseHashResponse(qsParams, appState, token) {
    return {
      accessToken: qsParams.access_token || null,
      idToken: qsParams.id_token || null,
      idTokenPayload: token || null,
      appState: appState || null,
      refreshToken: qsParams.refresh_token || null,
      state: qsParams.state || null,
      expiresIn: qsParams.expires_in ? parseInt(qsParams.expires_in, 10) : null,
      tokenType: qsParams.token_type || null,
      scope: qsParams.scope || null
    };
  }

  // tslint:disable-next-line:no-shadowed-variable
  private _httpResponseError(error, path, type, params, resolve, reject) {
    if (error.status === 401) { // unauthorized - csrf_token expired
      console.error(error.error);
    } else {
      reject(error);
    }
  }

  recordCookieLogin(){
    const { isVendorRole, isBuyerRole, isVendorLoginUrl, isVendorPortalUrl } = this.sharedService
    const isVendorPage = isVendorLoginUrl || isVendorPortalUrl || false;

    if(!isVendorPage && isVendorRole){ return; }
    if(isVendorPage && isBuyerRole){ return; }

    let path = 'users/cookielogin';
    const URL = environment.adminUrl + path;

    this.http.post(URL, {})
    .subscribe(
      (res)=>{},
      (err)=>{console.error(err)}
    );
  }

}
