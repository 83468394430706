import { Component, OnInit, OnDestroy } from '@angular/core';

import { PubSubService } from '../../core/pubsub.service';
import { UserService } from '../../user/user.service';
import { Email } from '../../buyer/interfaces/Email';
import { UserProfile } from '../interfaces/UserProfile';
import { IProfile } from '../interfaces/model';
import { PageErrorService } from '../../page-error/page-error.service';
import { SharedSource } from '../../core/shared-source';
import { Subscription } from 'rxjs';
import { SessionService } from '../../services/session.service';
import {EmailService} from '../../services/email.service';
import { CartService } from '../../buyer/cart/cart.service';

@Component({
  selector: 'app-user-support',
  templateUrl: './user-support.component.html',
  styleUrls: ['./user-support.component.css']
})
export class UserSupportComponent implements OnInit, OnDestroy {
  public showModalTrigger = false;
  public animateIn = false;
  public animateOut = false;
  private supportEmailAddress = 'support@hubx.com';
  private error = false;
  private complete = false;
  public message = '';
  private user: UserProfile;
  private profile: IProfile;
  private role: string;
  private profileDataSubscrive: Subscription;

  constructor(
    private cartService: CartService,
    private userSvc: UserService,
    private pageErrorSvc: PageErrorService,
    private pubSubService: PubSubService,
    public sessionService: SessionService,
    public emailService: EmailService
  ) { }

  ngOnInit() {
    this.profile = this.userSvc.getEmptyProfile();
    this.profileDataSubscrive = this.pubSubService.sharedSubject.subscribe(profileEvent => {
      const customer = this.getSelectedCustomer();
      if (profileEvent.name === SharedSource.profileData) {
        this.role = this.userSvc.getRole();
        if (!(this.role === 'SUPER' || this.role === 'SALES') || (customer !== null)) {
          const res: IProfile = profileEvent.data;
          this.profile = res;
        }
      }
    });
    this.userSvc.getLoggedInUserProfile()
      .subscribe(
        (res) => {
          this.user = res;
          this.userSvc.userProfile = res;
          this.userSvc.SalesDiscount = res.discountLimit;
        },
        (err) => {
          if (err.status === 500) {
            this.closeModal();
            this.pageErrorSvc.goToError(err.status, err.statusText);
          }
        }
      );
  }

  ngOnDestroy() {
    this.profileDataSubscrive.unsubscribe();
  }

  sendMessage(e: Event): void {
    e.stopPropagation();
    this.error = false;
    const msg = this.message.trim();
    if (!msg) {
      this.error = true;
      return;
    }

    let companyStr;
    const customer = this.getSelectedCustomer();

    if (this.role === 'SUPER' || this.role === 'SALES') {
      companyStr = `Selected Customer: ${(customer === null)
        ? 'NONE'
        : customer}`;
    } else {
      companyStr = `Company: ${this.profile.bpCode} - ${this.profile.companyName}`;
    }

    const data: Email = {
      senderName: `${this.user.firstName} ${this.user.lastName}`,
      from: this.user.email,
      recipient: this.supportEmailAddress,
      subject: 'Help requested',
      htmlContent: `
        ${companyStr}<br>
        Role: ${this.role}<br><br>
        Message: ${msg}`
    };

    this.emailService.send(data);
    this.message = '';
    this.complete = true;
  }

  onClickedOutside(e: Event): void {
    this.closeModal();
  }

  closeModal(): void {
    this.animateOut = true;
    this.animateIn = false;
    this.error = false;
    setTimeout(() => {
      this.showModalTrigger = false;
      this.complete = false;
      this.message = '';
      this.animateOut = false;
    }, 400);
  }

  getSelectedCustomer(): string {
    return this.sessionService.selectedCustomer;
  }
}
