import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as models from '../../buyer/interfaces/model';
import { environment } from '../../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { IAuctionsHeadersTotals } from '../interfaces/IAuctionsHeadersTotal';
import { ISortOptionsAuctions } from '../interfaces/ISortOptionsAuctions';
import { IAuctionSearchPayload } from '../interfaces/IAuctionSearchPayload';
import { TopService } from '../../shared/services/top.service';
import { BidStatusEnum } from '../enums/bidStatusEnum';
import { SortAuctionsTypesEnum } from '../enums/sortAuctionsTypesEnum';
import { IAuctionMyBidsPayload } from '../interfaces/IAuctionMyBidsPayload';
import { Router, NavigationEnd } from '@angular/router';
import { StatusFilterEnum } from '../enums/statusFilterEnum';
import { IAuctionWatch } from '../interfaces/IAuctionWatch';
import { IAuctionBid } from '../interfaces/IAuctionBid';
import { IAuctionBidResponse } from '../interfaces/IAuctionBidResponse';
import { IAuctionBidRequest } from '../interfaces/IBidHistoryRequest';
import { IBidHistoryPayload } from '../interfaces/IAuctionBidHistoryPayload';
import { IMyBidsLeftNavTotals } from '../interfaces/IMyBidsLeftNavTotals';
import { SessionService } from '../../services/session.service';
import { IActionsDataSignalR } from '../interfaces/IAuctionsDataSignalR';
import { ItemsService } from '../../shared/services/items.service';
import { IAuctionItem } from '../../buyer/interfaces/model';
import { SharedService } from '../../shared/shared.service';
import * as _ from 'lodash';
import { GuestService } from '../../shared/services/guest.service';
import { AuthService } from '../../auth/auth.service';
import { StaticService } from '../../static/static.service';
import { UserService } from '../../user/user.service';
import { AuctionSignalrService } from './signalr/auction-signalr.service';

@Injectable({
  providedIn: 'root'
})
export class AuctionsService {
  private _auctionItems: models.IAuctionItem[] = [];
  currentAuctionItem: models.IAuctionItem;
  bidHistoryPayload: IBidHistoryPayload;
  bidAmount: number = null;
  showMobileHeader = false;
  totalsReady = false;

  private _searchPayload: IAuctionSearchPayload;
  private _myBidsPayload: IAuctionMyBidsPayload;
  private _myWatchPayload: IAuctionMyBidsPayload;
  private _sortOptions: ISortOptionsAuctions[] = [];
  private _selectedSortOption: ISortOptionsAuctions;
  private _sortType: SortAuctionsTypesEnum = SortAuctionsTypesEnum.BrowseOptions;


  auctionsSubscription: Subscription[] = [];
  myBidsTotals: IMyBidsLeftNavTotals;
  isGridView = true;
  connected = false;
  LiveAuctionTitleText = 'Live Auctions';
  MyBidsTitleText = 'My Bids';
  titleText = 'Live Auctions';
  isAuctionsPage;
  isDetailsPage;
  headerTotals: IAuctionsHeadersTotals;
  hideChangeViews = false;
  currentAuctionStatusFilter: StatusFilterEnum  = StatusFilterEnum.active;
  itemsReady = false;

  liveAuctionCounter = 0;
  totalToDisplay = 0;

  constructor(
    private _sessionSvc: SessionService,
    private http: HttpClient,
    public topService: TopService,
    private _sessionService: SessionService,
    private _itemsService: ItemsService,
    private sharedService: SharedService,
    private _router: Router,
    public guestService: GuestService,
    public authService: AuthService,
    public staticService: StaticService,
    private userService: UserService,
    private auctionSignalrService: AuctionSignalrService) {
    this._searchPayload = this.getDefaultSearchPayload();
    this._myBidsPayload = this.getDefaultMyBidsPayload();
    this._myWatchPayload = this.getDefaultMyWatchPayload();

    this._router.events
      .subscribe(e => {
        if (e instanceof NavigationEnd && (!!e.url.match(/auctions/))) {
          this._itemsService.selectedTopAttribute = '';
          this.setUpItemsAndSortByURL(e.url);
          this.setHeaderTotalToDisplay();
        }
      });

    this.subscribeToAllSignalREvents();
    // if you refresh in my_bids sometimes the calls get out of sync and
    // my bids show live auctions
    if (!location.pathname.match(/my\_bids|\/vendor|\/home/)) {
      this.getAuctionItems();
    }
  }

  set auctionItems(items) {
    this._auctionItems = items;
    this.itemsReady = true;
    // registering objects with signalR every time the list changes
    if (items) {
      const testObjIds = items.map(i => i.ID);
      this.registerInterestingObjects(testObjIds);
    }
  }

  get auctionItems(): models.IAuctionItem[] {
    return this._auctionItems;
  }

  set sortOptions(list) {
    this._sortOptions = list;
  }

  get sortOptions() {
    return this._sortOptions;
  }

  set sortType(type: SortAuctionsTypesEnum) {
    this._sortType = type;
    this.getSortingOptions();
  }

  get sortType(): SortAuctionsTypesEnum {
    return this._sortType;
  }

  set selectedSortOption(selectedOpt) {
    this._selectedSortOption = selectedOpt;
  }

  get selectedSortOption() {
    return this._selectedSortOption;
  }

  updateItem(item: models.IAuctionItem, data) {

    item.CurrentPrice = data.Price;
    item.Increment = data.Increment;
    item.ActionCount = data.AcceptedActionCount;
    item.IsWinning = this._sessionService.userEmail === data.CurrentListingActionUserName &&
      (data.Properties.ReserveDefined === 'false' || (data.Properties.ReserveDefined === 'true' && data.Properties.ReserveMet === 'true'));

    // if (data.Properties.ReserveDefined === 'false') {
    //   item.ReserveMet = false;
    // } else if (data.Properties.ReserveDefined === 'true') {
    //   item.ReserveMet = data.Properties.ReserveMet === 'true' ? true : false;
    // } else if (data.Properties.ReserveDefined === null) {
    //   item.ReserveMet = null;
    // }
    item.ReserveMet = data.Properties.ReserveMet === 'true' ? true : false;
  }

  registerInterestingObjects(objectsToRegister) {
    const email = this._sessionService.userEmail;
    if (this.connected) {
      if (objectsToRegister.length > 0 && $ && email) {
        ($ as any).connection.listingHub.server.registerMultipleInterest(objectsToRegister, email);
        // ($ as any).connection.listingHub.server.registerListingInterest(objectsToRegister[0]);
        ($ as any).connection.listingHub.server.registerEventInterest('SignalR_UpdateListingStatus');
        // console.log('registered queued Interesting Objects: ' + objectsToRegister.length);
      } else {
        return;
        // console.error('Error trying to register objects: ', objectsToRegister, ' jquery: ', $, ' email: ', email);
      }
    } else {
      setTimeout(() => {
        this.registerInterestingObjects(objectsToRegister);
      }, 500);
    }
  }

  setUpItemsAndSortByURL(url) {
    // Inside my bids
    if (url.match(/my\_bids/)) {

      this.isAuctionsPage = false;
      this.isDetailsPage = false;
      this.titleText = this.MyBidsTitleText;
      this.getMyBidsLeftNavTotals();

      if (url.match(/watching/)) {
        this.sortType = SortAuctionsTypesEnum.BidWatchOptions;
        this.getMyWatchItems();
      } else if (url.match(/won/)) {
        this.sortType = SortAuctionsTypesEnum.BidWonOptions;
        this.setMyBidsPayload({ status: BidStatusEnum.won });
      } else if (url.match(/not\_winning/)) {
        this.sortType = SortAuctionsTypesEnum.BidActiveOptions;
        this.setMyBidsPayload({ status: BidStatusEnum.notWinning });
      } else if (url.match(/winning/)) {
        this.sortType = SortAuctionsTypesEnum.BidActiveOptions;
        this.setMyBidsPayload({ status: BidStatusEnum.winning });
      } else if (url.match(/my\_bids$/)) {
        // my bids
        this.sortType = SortAuctionsTypesEnum.BidActiveOptions;
        this.resetMyBidsPayload();
        this.getMyBidsItems();
      }
    } else if (url.match(/auctions$/)) {
      // auctions page
      this.isAuctionsPage = true;
      this.isDetailsPage = false;
      this.titleText = this.LiveAuctionTitleText;

      // first time the user lands in the page, get default
      if (!this.sortOptions.length) {
        this.sortType = SortAuctionsTypesEnum.BrowseOptions;
      }
      this.getAuctionItems();
    } else if (url.match(/details/)) {
      // details page
      this.isAuctionsPage = false;
      this.isDetailsPage = true;
    }

  }

  getDefaultSearchPayload(): IAuctionSearchPayload {
    return {
      page: 0,
      size: 1000,
      sortOption: SortAuctionsTypesEnum.BrowseOptions,
      statusFilter: StatusFilterEnum.active,
      descending: true,
      fullTextQuery: '',
    };
  }

  get searchPayload(): IAuctionSearchPayload {
    return this._searchPayload;
  }

  setSearchPayload(updatedProperties: IAuctionSearchPayload) {
    Object.assign(this._searchPayload, updatedProperties);
    this.getAuctionItems();
  }

  resetSearchPayload() {
    // if (this.userService.isGuest) {
    //   this.guestService.guestPop();
    //   this._router.navigate(['guest/auctions']);
    // } else {
      Object.assign(this._searchPayload, this.getDefaultSearchPayload());
    // }
  }

  getDefaultMyBidsPayload(): IAuctionMyBidsPayload {
    return {
      status: BidStatusEnum.all,
      page: 0
    };
  }

  get myBidsPayload() {
    return this._myBidsPayload;
  }

  setMyBidsPayload(updatedProperties: IAuctionMyBidsPayload) {
    Object.assign(this._myBidsPayload, updatedProperties);
    this.getMyBidsItems();
  }

  resetMyBidsPayload() {
    Object.assign(this._myBidsPayload, this.getDefaultMyBidsPayload());
  }

  getDefaultMyWatchPayload(): IAuctionMyBidsPayload {
    return {
      status: BidStatusEnum.all,
      page: 0
    };
  }

  get myWatchPayload() {
    return this._myWatchPayload;
  }

  setMyWatchPayload(updatedProperties: IAuctionMyBidsPayload) {
    Object.assign(this._myWatchPayload, updatedProperties);
    this.getMyWatchItems();
  }

  resetMyWatchPayload() {
    Object.assign(this._myWatchPayload, this.getDefaultMyWatchPayload());
    this.getMyWatchItems();
  }

  getAuctionItems() {
    this.topService.loading = true;
    const url = environment.auctionUrl + 'listing/search';

    this.cancelAuctionsSubscriptions();

    this.auctionsSubscription.push(
      this.http.post<models.IAuctionItem[]>(url, this.searchPayload)
        .subscribe(
          items => {
            this.auctionItems = (<any>items).List;
            if ( this.searchPayload.statusFilter === StatusFilterEnum.active) {
              this.liveAuctionCounter = this.auctionItems.length;
              this.sharedService['setAuctionItems'] = this.auctionItems;
            }

            this.auctionItems.map(x => {
              if ((x.IsWinning && x.ReserveMet === null) || (x.IsWinning && x.ReserveMet)) {
                x.IsWinning = true;
              } else {
                x.IsWinning = false;
              }

              x.ReserveMet = (x.ReserveMet === null || x.ReserveMet === true);
            });

            this.topService.loading = false;
          },
          err => {
            console.error('Error retriving auction items', err);
            this.topService.loading = false;
          })
    );
  }

  getMyWatchItems() {
    this.cancelAuctionsSubscriptions();
    this.topService.loading = false;

    if ( this.userService.isGuest ) {
      this.auctionItems = [];
      return;
    }

    const url = environment.auctionUrl + 'listing/myWatch';
    this.auctionsSubscription.push(
      this.http.post<models.IAuctionItem[]>(url, this.myWatchPayload)
        .subscribe(
          items => {
            this.auctionItems = (<any>items).List;
            this.topService.loading = false;
          },
          err => {
            console.error('Error retriving myWatch items', err);
            this.topService.loading = false;
          })
    );
  }

  getMyBidsItems() {
    this.cancelAuctionsSubscriptions();
    this.topService.loading = true;

    if ( this.userService.isGuest ) {
      this.auctionItems = [];
      return;
    }

    const url = environment.auctionUrl + 'listing/myBids';
    this.auctionsSubscription.push(
      this.http.post<models.IAuctionItem[]>(url, this.myBidsPayload)
        .subscribe(
          items => {
            this.auctionItems = (<any>items).List;
            this.topService.loading = false;
          },
          err => {
            console.error('Error retriving myBids items', err);
            this.topService.loading = false;
          })
    );
  }

  getHeaderTotals(): void {
    this.totalsReady = false;
    const url = environment.auctionUrl + 'listing/filters/totals';
    this.http.get<IAuctionsHeadersTotals>(url)
      .subscribe(
        (data) => {
          this.headerTotals = data;
          this.totalsReady = true;
          this.totalToDisplay = this.headerTotals.LiveTotal;
        },
        (err) => {
          this.totalsReady = true;
          console.error('Error retriving myBids items', err);
        });
  }

  setHeaderTotalToDisplay() {

    if (this.headerTotals
      && this.titleText === this.LiveAuctionTitleText) {
        this.totalToDisplay = this.headerTotals.LiveTotal;
    } else if (this.myBidsTotals  && this.titleText === this.MyBidsTitleText) {
        this.totalToDisplay = this.myBidsTotals.AllTotal;
    } else {
      this.totalToDisplay = 0;
    }
  }

  getSortingListByPage(type): Observable<ISortOptionsAuctions[]> {
    const url = environment.auctionUrl + `listing/sorting/${type}`;
    return this.http.get<ISortOptionsAuctions[]>(url);
  }

  getSortingOptions() {
    this.getSortingListByPage(this.sortType)
      .subscribe(data => {
        const mappedData = data.map(e => {
          const transformedElement = e;
          transformedElement.Text = this.specialDecamelize(e.Text);
          return transformedElement;
        });
        this.sortOptions = mappedData;
        this.selectedSortOption = undefined;
      });
  }

  specialDecamelize(str) {
    return str
      .replace(/([A-Z]+)([\d]+)/g, '$1 $2')
      .replace(/([\d]+)([a-z]+)/g, '$1 $2')
      .replace(/([a-z]+)([\d]+)/g, '$1 $2')
      .replace(/([a-z\d])([A-Z])/g, '$1 $2')
      .replace(/([a-z\d])([A-Z])/g, '$1 $2')
      .replace(/Ato|ATo/g, 'A to')
      .replace(/Zto|ZTo/g, 'Z to')
      .replace(/\s+/g, ' ');
  }

  addToWatchlist(item: IAuctionItem, callbackObj = null): void {
    if (this.userService.isGuest) {
      this.userService.guestPop();
    } else {
      if (this._sessionSvc.userRole !== 'SUPER') {
        item.WatchLoading = true;
        const reqObj: IAuctionWatch = {
          ListingId: item.ID,
          IsAdding: !item.IsWatching
        };
        const url = environment.auctionUrl + 'listing/watchlist';
        this.http.post<boolean>(url, reqObj)
          .subscribe(
            (data) => {
              if (data) {
                item.IsWatching = !item.IsWatching;
                item.WatchLoading = false;
                if (callbackObj) { callbackObj.fn(callbackObj.scope, item); }
              }
            },
            (err) => {
              console.log('Error trying to watch item: ' + err);
              item.WatchLoading = false;
              if (callbackObj) { callbackObj.fn(callbackObj.scope, item); }
            }
          );
      }
    }

  }

  bid(item: IAuctionItem, amount: number): void {
    const reqObj: IAuctionBid = {
      ListingId: item.ID,
      BidAmount: amount
    };

    const url = environment.auctionUrl + 'bid';
    this.http.post<IAuctionBidResponse>(url, reqObj)
      .subscribe(
        (data) => {
          // we dont update this with signalr so we do it here
          item.HasBid = true;
          setTimeout(() => {
            if (!this.connected) {
              this.auctionSignalrService.startConnection();
            }
            // signalR is assumed to have failed or is not connected
            if (item.BidLoading) {
              this.refetchItemDataNoMedia();
              item.BidLoading = false;
              this.bidAmount = null;
            }
          }, 3000);
        },
        (err) => {
          console.log('Error trying to bid: ' + err);
          item.BidLoading = false;
        }
      );
  }

  refetchItemDataNoMedia() {
    this.getAuctionItemRequest(this.currentAuctionItem.ID)
      .subscribe((itemData) => {
        Object.keys(this.currentAuctionItem).forEach(key => {
          // updating mediaResponse property brakes carosel so we dont update it
          if (key !== 'MediaResponse') {
            this.currentAuctionItem[key] = itemData[key];
          }
        });
        this.mapCurrentAuctionItem(itemData);
      });
  }

  quickBid(item: IAuctionItem): void {
    item.ActionCount ? this.bid(item, item.CurrentPrice + item.Increment) : this.bid(item, item.CurrentPrice);
  }

  manualBid(item: IAuctionItem, amount: number) {
    item.BidLoading = true;
    if (this.validBidAmount(item, amount)) {
      this.bid(item, this.bidAmount);
    }
  }

  validBidAmount(item: IAuctionItem, amount: number): boolean {
    if (item && item.ActionCount) {
      if (amount && amount >= item.CurrentPrice + item.Increment) {
        return true;
      } else {
        return false;
      }
    } else if (item && !item.ActionCount) {
      if (amount && amount >= item.CurrentPrice) {
        return true;
      } else {
        return false;
      }
    }
  }

  getAuctionItemRequest(id: number) {
    const url = environment.auctionUrl + `listing/${id}`;
    return this.http.get<models.IAuctionItem>(url);
  }

  getAuctionItem(id, callbackObj = null): void {
    this.getAuctionItemRequest(id)
      .subscribe(data => {
        this.currentAuctionItem = data;
        this.mapCurrentAuctionItem(data);

        if (callbackObj) {
          callbackObj.fn(callbackObj.scope);
        }
      });
  }

  mapCurrentAuctionItem(itemData) {
    this.currentAuctionItem.ReserveMet = (itemData.ReserveMet === null || itemData.ReserveMet === true);
    if ((this.currentAuctionItem.IsWinning && this.currentAuctionItem.ReserveMet === null) ||
      (this.currentAuctionItem.IsWinning && this.currentAuctionItem.ReserveMet)) {
      this.currentAuctionItem.IsWinning = true;
    } else {
      this.currentAuctionItem.IsWinning = false;
    }
  }

  getBidHistory(): void {
    if (this.currentAuctionItem) {
      const url = environment.auctionUrl + 'listing/history';
      const reqObj: IAuctionBidRequest = {
        ListingId: this.currentAuctionItem.ID,
        Page: 0,
        Size: 1000,
        SortOption: '',
        Descending: true,
      };
      this.http.post<IBidHistoryPayload>(url, reqObj)
        .subscribe(
          (data) => {
            this.bidHistoryPayload = data;
            this.topService.loading = false;
          },
          (err) => {
            console.log('Error trying to get bid history: ' + err);
            this.topService.loading = false;
          });
    }

  }

  getMyBidsLeftNavTotals() {
    this.totalsReady = false;
    const url = environment.auctionUrl + 'listing/mybids/totals';
    this.http.get<IMyBidsLeftNavTotals>(url)
      .subscribe(totals => {
        this.myBidsTotals = totals;
        this.totalToDisplay = this.myBidsTotals.AllTotal;
        this.totalsReady = true;
      },
        err => {
          console.error('Error getting left nav totals', err);
          this.totalsReady = true;
        });
  }

  setNotificationMessagesFromActionResponse(data): IAuctionItem {

    let itemReference: IAuctionItem;
    if (this.isDetailsPage) {
      itemReference = this.currentAuctionItem;
    } else {
      itemReference = this.auctionItems.find(i => i.ID === data.Action_ListingID);
    }

    if (data.Context_Status === 'CURRENT_HIGH_BIDDER_RESERVE_NOT_MET') {
      itemReference.NotificationMessages = 'You are the current high bidder, but the reserve price has not been met.';
    } else if (data.Context_Status === 'LOSING' && data.ReasonCode === 29 /*ProxyBeatingNewBid*/) {
      itemReference.NotificationMessages = 'Another bidder\'s proxy bid is beating your new bid.';
    } else if (data.Context_Status === 'WINNING' && data.ReasonCode === 31 /*CantLowerProxyBid*/) {
      itemReference.NotificationMessages = 'You cannot lower your previously higher proxy bid.';
    } else if (data.Context_Status === 'WINNING' && data.ReasonCode === 114 /*IncreasedOwnMaxBid*/) {
      itemReference.NotificationMessages = 'Increased Own Max Bid.';
    } else if (data.Context_Status === 'LOSING' && data.ReasonCode === 30 /*BidAmountTooLow*/) {
      itemReference.NotificationMessages = 'Bid amount to low.';
    } else if (data.ReasonCode === 34 /*ListingNotActive*/) {
      itemReference.Status = null;
      if (['NO_ACTIVITY', 'LOST_RESERVE_NOT_MET'].includes(data.Context_Status)) {
        itemReference.NotificationMessages = 'Auction item not active.';
      } else if (data.Context_Status === 'LISTING_DELETED') {
        itemReference.NotificationMessages = 'Auction item was removed.';
      } else if (data.Context_Status === 'WON') {
        itemReference.NotificationMessages = 'Auction item not active. Has finished early and you won.';
      }
    } else {
      itemReference.NotificationMessages = '';
    }

    return itemReference;
  }

  setNotificationMessageFromListingAction(data) {

    if (this._sessionService.userEmail !== data.UserName) { // person who did not make the bid
      if (this._sessionService.userEmail !== data.CurrentListingActionUserName) {
        if (this.isDetailsPage) {
          this.currentAuctionItem.NotificationMessages = '';
        } else {
          const item = this.auctionItems.find(i => i.ID === data.ListingID);
          item.NotificationMessages = '';
          // necessary for auction slider on home page (update item manually)
          this.sharedService['updateAuctionNotifMsg'] = item;
        }
      }
    }

    // necessary for auction slider on home page (update item manually)
    const auctionItem = this.auctionItems.find(i => i.ID === data.ListingID);
    this.sharedService['updateAuctionItem'] = auctionItem;
  }

  subscribeToAllSignalREvents() {
    // These all events you can listen to
    $(document).on('SignalR_UpdateListingAction', (event, data: IActionsDataSignalR) => {
      // console.log('Auctions SignalR UpdateListingAction triggered, data: ', data, ' event: ', event);
      const item = this.auctionItems.find(i => i.ID === data.ListingID);
      if (item) {
        this.updateItem(item, data);
      }
      if (this.currentAuctionItem) {
        this.updateItem(this.currentAuctionItem, data);
      }

      if (this.isDetailsPage) {
        this.getBidHistory();
      }
      this.setNotificationMessageFromListingAction(data);
    });

    // Response after biding
    $(document).on('SignalR_ListingActionResponse', (event, data) => {
      // console.log('Auctions SignalR ListingActionResponse triggered, data: ', data, ' event: ', event);
      // if (data.Accepted) { console.log('Your bid was acepted, context status: ', data.Context_Status); }

      if (this.isDetailsPage && (this.currentAuctionItem && this.currentAuctionItem.MaxBid)) {
        this.currentAuctionItem.MaxBid =
          data.Action_ProxyAmount > this.currentAuctionItem.MaxBid ? data.Action_ProxyAmount : this.currentAuctionItem.MaxBid;
      }

      const item = this.setNotificationMessagesFromActionResponse(data);
      // necessary for auction slider on home page (update item manually)
      this.sharedService['updateAuctionNotifMsg'] = item;
      item.BidLoading = false;
      this.bidAmount = null;
    });


    $(document).on('SignalR_UpdateListingDTTM', (event, data) => {
      // console.log('Auctions SignalR UpdateListingStatus triggered, data: ', data, ' event: ', event);
    });

    $(document).on('SignalR_UpdateListingStatus', (event, data) => {
      // console.log('Auctions SignalR UpdateListingDTTM triggered, data: ', data, ' event: ', event);
    });

    $(document).on('SignalR_UpdateEventStatus', (event, data) => {
      // console.log('Auctions SignalR Update Event Status triggered, data: ', data, ' event: ', event);
    });

    // Other events
    // Connection status events
    $(document).on('SignalR_ConnectionSlow', (event, data) => {
      // console.log('Auctions SignalR connection slow');
    });
    $(document).on('SignalR_Started', (event, data) => {
      this.connected = true;
      // console.log('Auctions SignalR connection started');
    });
    $(document).on('SignalR_Disconnected', (event, data) => {
      this.connected = false;
      // console.log('Auctions SignalR Disconected');
    });
    $(document).on('SignalR_Reconnecting', (event, data) => {
      // console.log('Auctions SignalR is Reconnecting');
    });
  }

  cancelAuctionsSubscriptions() {
    this.auctionsSubscription.forEach(sub => {
      sub.unsubscribe();
    });
    this.auctionsSubscription = [];
  }

  getEmptyStateMsgForMyBids() {
    const currentUrl = this._router.url.replace(/\/guest/g, '');
    const nothingFoundMsg = [
      {
        header: 'You have no activity yet!',
        body: 'All your bids will show here',
        url: '/auctions/my_bids'
      }, {
        header: 'You have no winning bids',
        body: 'All winning bids will show here',
        url: '/auctions/my_bids/winning'
      }, {
        header: 'You are not winning any bids',
        body: 'Not winning bids will show here',
        url: '/auctions/my_bids/not_winning'
      }, {
        header: 'You have not won any auction',
        body: 'Won auction will show here',
        url: '/auctions/my_bids/won'
      }, {
        header: 'You are not watching any auctions',
        body: 'Watch auctions will show here',
        url: '/auctions/my_bids/watching'
      }
    ];
    return _.find(nothingFoundMsg, function (x) { return x.url === currentUrl; });
  }

  getEmptyStateFromAuctions() {
    const nothingFoundMsg = [
      {
        state: StatusFilterEnum.active,
        header: 'No Live Auctions',
        body: 'Check back soon!',
      }, {
        state: StatusFilterEnum.comingSoon,
        header: 'No Coming Soon Auctions',
        body: 'Check back soon!',
      }, {
        state: StatusFilterEnum.completed,
        header: 'No Completed Auctions',
        body: 'Check back soon!',
      }
    ];
    const that = this;
    return _.find(nothingFoundMsg, function (x) { return x.state === that.currentAuctionStatusFilter; });
  }

}
