<div class="preloader">
	<ng-container *ngIf="source === 'detailed'">
		<app-detailed-item-preloader [class.checkbox]="isCheckbox" *ngFor="let preloaderItem of preloaderItemList"></app-detailed-item-preloader>
	</ng-container>
	<ng-container *ngIf="source === 'simple'">
		<app-listing-item-preloader [class.checkbox]="isCheckbox" *ngFor="let preloaderItem of preloaderItemList"></app-listing-item-preloader>
	</ng-container>
	<ng-container *ngIf="source === 'notifications'">
		<app-notification-item-preloader *ngFor="let preloaderItem of preloaderItemList"></app-notification-item-preloader>
	</ng-container>
	<ng-container *ngIf="source === 'pendingPo'">
		<app-po-item-preloader *ngFor="let preloaderItem of preloaderItemList"></app-po-item-preloader>
	</ng-container>
	<ng-container *ngIf="source === 'purchase'">
		<app-purchase-item-preloader *ngFor="let preloaderItem of preloaderItemList"></app-purchase-item-preloader>
	</ng-container>
	<ng-container *ngIf="source === 'dashboardNotifications'">
		<app-dashboard-notification-preloader *ngFor="let preloaderItem of preloaderItemList"></app-dashboard-notification-preloader>
	</ng-container>
	<ng-container *ngIf="source === 'dashboardJustSold'">
		<app-dashboard-just-sold-preloader *ngFor="let preloaderItem of preloaderItemList"></app-dashboard-just-sold-preloader>
	</ng-container>
</div>
