<div class="item">
	<div class="item-checkbox">
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
		    <g id="Icons/24-checkbox-off" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.5">
		        <path d="M6,3 C4.34314575,3 3,4.34314575 3,6 L3,18 C3,19.6568542 4.34314575,21 6,21 L18,21 C19.6568542,21 21,19.6568542 21,18 L21,6 C21,4.34314575 19.6568542,3 18,3 L6,3 Z M6,2 L18,2 C20.209139,2 22,3.790861 22,6 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 Z" id="Rectangle" fill="#000000" fill-rule="nonzero"/>
		    </g>
		</svg>
	</div>
	<div class="item-details">
		<div class="item-logo">
			<div></div>
			<span></span>
		</div>
		<div class="item-desc">
			<span></span>
			<span></span>
			<span></span>
		</div>
		<div class="item-box">
			<div class="item-box-icon">
				<svg height="64" viewBox="0 0 64 64" width="64" xmlns="http://www.w3.org/2000/svg"><path d="m34.2588136 47.0743285c0 1.2689318.9968503 2.1135545 2.2205527 1.8427388 0 0-.6661864.1885644.081774-.022446 7.1738265-2.0238402 12.4388597-8.7041735 12.4388597-16.6331361 0-9.533255-7.6111592-17.2614852-17-17.2614852-9.3888407 0-17 7.7282302-17 17.2614852 0 7.9429348 5.2836054 14.6328359 12.4768021 16.6437918.7361867.2058109.0440257.0121199.0440257.0121199 1.226582.2510753 2.2209232-.5759111 2.2209232-1.8430023v-14.4290254c0-.3179388-.1785815-.3937367-.4013359-.1667307 0 0-1.8319407 1.8658441-2.4897707 2.5440036-2.1502695 2.1914062-5.3443974-1.0634569-3.215167-3.2331738l6.9190986-6.9937449c.7756203-.7828277 2.0377303-.7866872 2.8185543-.0081691l6.9913923 7.001914c2.1292304 2.1697169-1.0648975 5.42458-3.1941279 3.2548631-.6766372-.697873-2.5096023-2.5649793-2.5096023-2.5649793-.2220067-.2262501-.4019788-.151648-.4019788.1660145z" fill="#dadada" transform="matrix(-1 0 0 -1 64 64)"/></svg>
			</div>
			<div class="item-box-text">
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
		<div class="item-box">
			<div class="item-box-icon">
				<svg height="64" viewBox="0 0 64 64" width="64" xmlns="http://www.w3.org/2000/svg"><path d="m12 20.8709677 1.1356998 3.6102747h1.0265345c.9556751 0 1.7778182.6761128 1.9623584 1.6138014l2.7071613 13.7556709c.1845402.9376887 1.0066833 1.6138014 1.9623585 1.6138014h27.3582392c1.020428 0 1.8476483-.8272203 1.8476483-1.8476483s-.8272203-1.8476483-1.8476483-1.8476483h-25.0846715c-.7795356 0-1.4500202-.551798-1.5999957-1.3167707l-2.7381339-13.9662627c-.1839677-.9383547-1.0064184-1.6152184-1.9626368-1.6152184zm32.7875405-3.8709677h-18.5331895c-1.2794497 0-2.3166487 1.0245683-2.3166487 2.2884374v13.810222c0 1.2638691 1.037199 2.2884374 2.3166487 2.2884374h18.5331895c1.2794497 0 2.3166486-1.0245683 2.3166486-2.2884374v-13.810222c0-1.2638691-1.0371989-2.2884374-2.3166486-2.2884374zm-19.1376582 26.6645161c1.1916854 0 2.157663.97049 2.157663 2.167742s-.9659776 2.1677419-2.157663 2.1677419c-1.1916853 0-2.157663-.9704899-2.157663-2.1677419s.9660579-2.167742 2.157663-2.167742zm19.1353223 0c1.1916411 0 2.1575828.97049 2.1575828 2.167742s-.9659417 2.1677419-2.1575828 2.1677419c-1.1917213 0-2.1577432-.9704899-2.1577432-2.1677419s.9660219-2.167742 2.1577432-2.167742z" fill="#dadada"/></svg>
			</div>
			<div class="item-box-text">
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
		<div class="item-box">
			<div class="item-box-icon">
				<svg height="64" viewBox="0 0 64 64" width="64" xmlns="http://www.w3.org/2000/svg"><path d="m39.2727273 33.4444444v4.3333334h2.9090909v-4.3333334h4.8484848c.5355489 0 .969697.4341482.969697.969697v9.6161616c0 .5355489-.4341481.969697-.969697.969697h-12.6060606c-.5355488 0-.9696969-.4341481-.9696969-.969697v-9.6161616c0-.5355488.4341481-.969697.9696969-.969697zm-17.4545455 0v4.3333334h2.9090909v-4.3333334h4.8484849c.5355488 0 .9696969.4341482.9696969.969697v9.6161616c0 .5355489-.4341481.969697-.9696969.969697h-12.6060606c-.5355489 0-.969697-.4341481-.969697-.969697v-9.6161616c0-.5355488.4341481-.969697.969697-.969697zm0-14.4444444v4.3333333h2.9090909v-4.3333333h4.8484849c.5355488 0 .9696969.4341481.9696969.969697v9.6161616c0 .5355488-.4341481.969697-.9696969.969697h-12.6060606c-.5355489 0-.969697-.4341482-.969697-.969697v-9.6161616c0-.5355489.4341481-.969697.969697-.969697zm20.3636364 1.4444444-.0001818 2.888 2.9092727.0008889v2.8888889l-2.9092727-.0007778.0001818 2.8896667h-2.9090909l-.0000909-2.8896667-2.909.0007778v-2.8888889l2.909-.0008889.0000909-2.888z" fill="#dadada"/></svg>
			</div>
			<div class="item-box-text">
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
		<div class="item-controls">
			<div class="item-controls-top">
				<div class="item-controls-info">
					<span></span>
					<span></span>
				</div>
				<div class="item-controls-info __second">
					<span></span>
					<span></span>
				</div>
				<div class="item-controls-close">
					<div>
						<svg _ngcontent-clp-c228="" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g _ngcontent-clp-c228="" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g _ngcontent-clp-c228="" fill="#D7D7D7" fill-rule="nonzero"><polygon _ngcontent-clp-c228="" points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"></polygon></g></g></svg>
					</div>
				</div>
			</div>
			<div class="item-controls-button">
				<div></div>
			</div>
		</div>
	</div>
</div>