import { Component, OnInit } from '@angular/core';

import { TopService } from '../../services/top.service';

@Component({
  selector: 'app-card-banner',
  templateUrl: './card-banner.component.html',
  styleUrls: ['./card-banner.component.scss']
})
export class CardBannerComponent implements OnInit {

  constructor(public topService: TopService) { }

  ngOnInit() {
  }

}
