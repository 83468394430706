import { Injectable } from '@angular/core';
import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';
import { ILoaderLevel, LoaderLevelType } from '../interfaces/ILoaderLevel';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _nonDecrementalUrls: string[] = [];
  defautLevel: LoaderLevelType = LoaderLevelType.content;
  level: LoaderLevelType | number;

  constructor(
    private pubSubService: PubSubService) { }
  /**
   * Show/Hide Loader
   */
  showLoader() {
    const loaderData: ILoaderLevel = { show: true, level: (this.level || this.defautLevel) };
    this.pubSubService.sharedSubject.next({ name: SharedSource.loaderStatus, data: loaderData });
    this.level = null;
  }

  hideLoader() {
    const loaderData: ILoaderLevel = { show: false };
    this.pubSubService.sharedSubject.next({ name: SharedSource.loaderStatus, data: loaderData });
  }

  get nonDecrementalUrls(): string[] {
    return this._nonDecrementalUrls;
  }

  /**
   * Add NonDecrementalUrl
   * @param url 
   */
  addNonDecrementalUrl(url) {
    this._nonDecrementalUrls.push(url);
  }

  /**
   * Remove NonDecrementalUrl
   * @param url 
   */
  removeNonDecrementalUrl(url) {
    this._nonDecrementalUrls.splice(this._nonDecrementalUrls.indexOf(url), 1);
  }

  // Toggle spinner visibility
  setLoaderVisible(visible: boolean) {
    this.pubSubService.sharedSubject.next({ name: SharedSource.loaderVisibility, data: !visible });
  }

  // Toggle spinner vendor visibility
  setLoaderVendorVisible(visible: boolean) {
    this.pubSubService.sharedSubject.next({ name: SharedSource.loaderVendorVisibility, data: visible });
  }
}
