import { SessionService } from '../../../services/session.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, AfterContentInit, AfterViewChecked, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import * as models from './../../../shared/interfaces/model';
import { environment } from './../../../../environments/environment';
import * as enums from './../../../shared/enums/enum';
import { OfferService } from '../offer.service';
import { PubSubService } from './../../../core/pubsub.service';
import { Subscription } from 'rxjs';
import { SharedSource } from './../../../core/shared-source';
import { TopService } from './../../../shared/services/top.service';

@Component({
  selector: 'app-offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.scss']
})
export class OfferListComponent implements OnInit, OnDestroy {
  @ViewChild(VirtualScrollerComponent) private virtualScroll: VirtualScrollerComponent;
  @Input() offersByStatus: models.OffersByStatusResponse;
  @Input() totalOffersByStatus: models.TotalOfferByStatusResponse[];
  @Input() isBuyer: boolean;
  @Output() onOffersByStatusRequest: EventEmitter<models.OffersByStatusRequest> = new EventEmitter<models.OffersByStatusRequest>();
  @Output() onSelectOffer = new EventEmitter<string>();
  infiniteScrollConfig = {
    'throttle': 15,
    'scrollDistance': 1,
    'scrollUpDistance': 2,
    'direction': ''
  }
  offerEventTypeMessages = new Map();
  imageBaseUrl = environment.imageBaseUrl;
  @Input() isLoading = false;
  OfferEventTypes = enums.OfferEventTypes;
  @Input() defaultTitleDropdown: string;
  @Input() paramOfferId;
  

  preloaderItems = [];
  fromOfferList = true;
  isSuperOrSaleImpersonating = false;
  isVendor: boolean;
  userRole = '';
  pubSubSubscription: Subscription;

  constructor(public offerService: OfferService,
    private sessionService: SessionService,
    private cdr: ChangeDetectorRef,
    public topService: TopService,
    private pubSubService: PubSubService) { }

  ngOnInit(): void {
    this.userRole = this.sessionService.userRole;
    this.isBuyer = ['SUPER', 'SALES', 'BUYER'].includes(this.userRole);
    this.isVendor = ['SELLER', 'VENDORMGR', 'ADMIN'].includes(this.userRole);
    this.isSuperOrSaleImpersonating = ['SUPER', 'SALES'].includes(this.userRole) && sessionStorage.getItem('isCustomerSelected') == 'YES';
    this.offerEventTypeMessages.set(enums.OfferEventTypes.OfferMade, "Offer made");
    this.offerEventTypeMessages.set(enums.OfferEventTypes.VendorAcceptedOffer, "Offer accepted");
    this.offerEventTypeMessages.set(enums.OfferEventTypes.VendorAcceptedPreviousOffer, "Offer accepted");
    this.offerEventTypeMessages.set(enums.OfferEventTypes.VendorCounteroffered, (this.isBuyer) ? "Seller sent counteroffer" : "Counteroffer made");
    this.offerEventTypeMessages.set(enums.OfferEventTypes.VendorRejectedOffer, "Offer rejected");
    this.offerEventTypeMessages.set(enums.OfferEventTypes.BuyerAcceptedOffer, "Offer accepted");
    this.offerEventTypeMessages.set(enums.OfferEventTypes.BuyerAcceptedPreviousOffer, "Offer accepted");
    this.offerEventTypeMessages.set(enums.OfferEventTypes.BuyerCounteroffered, (this.isBuyer) ? "Counteroffer made" : "Buyer sent you a counteroffer");
    this.offerEventTypeMessages.set(enums.OfferEventTypes.BuyerRejectedOffer, "Offer rejected");
    this.offerEventTypeMessages.set(enums.OfferEventTypes.BuyerCancelledOffer, "Offer canceled");
    this.offerEventTypeMessages.set(enums.OfferEventTypes.VendorCancelledOffer, "Offer canceled");
    this.offerEventTypeMessages.set(enums.OfferEventTypes.SystemCancelledOffer, "Offer canceled");
    this.offerEventTypeMessages.set(enums.OfferEventTypes.BuyersOfferExpired, "Offer expired");
    this.offerEventTypeMessages.set(enums.OfferEventTypes.VendorsOfferExpired, "Offer expired");
    this.offerEventTypeMessages.set(enums.OfferEventTypes.OrderCreated, "Order created");

    this.offerEventTypeMessages.set(enums.OfferEventTypes.VendorUpdatedOfferEvent, "Offer updated");
    this.offerEventTypeMessages.set(enums.OfferEventTypes.BuyerUpdatedOfferEvent, "Offer updated");

    this.preloaderItems.length = 9;
    this.offerService.selectedOfferStatusFilter = {
      displayName: 'All Offers',
      status: enums.FullOfferStatusEnum.All,
      total: this.totalOffersByStatus.length
    };

    if (!this.paramOfferId) {
      this.offerService.selectedOfferStatusFilter.status = enums.FullOfferStatusEnum.Pending;
    }

    this.pubSubSubscription = this.pubSubService.sharedSubject.subscribe(data => {
      switch (data.name) {
        case SharedSource.offerSummary:
        case SharedSource.bpUnseenEventCountUpdated:
          this.cdr.detectChanges();
          break;
      }
    });
  }

  ngOnDestroy() {
    this.pubSubSubscription.unsubscribe();
  }

  onScrollDown() {
    const endPerPage = this.offersByStatus.pagination.currentPage * this.offersByStatus.pagination.pageSize;
    if (endPerPage >= this.offersByStatus.pagination.totalCount)
      return;

    this.onOffersByStatusRequest.emit({
      pageSize: environment.offerListPageSize,
      pageNumber: (this.offersByStatus.pagination.currentPage + 1),
      status: this.offerService.selectedOfferStatusFilter.status,
      resetSelectedOffer: false
    } as models.OffersByStatusRequest);
  }

  onSelectOfferStatusFilter(event: models.TotalOfferByStatusResponse) {
    if (this.offerService.selectedOfferStatusFilter?.status !== event.status) {
      this.onOffersByStatusRequest.emit({
        pageSize: environment.offerListPageSize,
        pageNumber: 1,
        status: event.status,
        resetSelectedOffer: true
      } as models.OffersByStatusRequest);
      this.offerService.selectedOfferStatusFilter = event;
    }
  }

  selectOffer(offerId: string, itemCode: string, manufacturerLogoUrl: string): void {
    this.offerService.selectedOfferItem.itemCode = itemCode;
    this.offerService.selectedOfferItem.manufacturerLogoUrl = manufacturerLogoUrl;
    this.onSelectOffer.emit(offerId);
  }
}
