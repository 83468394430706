import { ICreditCard, ICreditCardType, ICreditCardRequest, ICreditCardError } from './../../../shared/interfaces/ICreditCard';
import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, NgZone } from '@angular/core';
import { TopService } from '../../../shared/services/top.service';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import creditCardType, { getTypeInfo, types as CardType } from 'credit-card-type';
import * as _ from 'lodash';
import { IGoogleAddress } from '../../../shared/interfaces/IGoogleAddress';
import { CreditCardService } from '../credit-card.service';
import { request } from 'http';
import { Subscription } from 'rxjs';
import { UserService } from '../../../user/user.service';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit, OnDestroy {
  @Output() closeCrditCardModal = new EventEmitter();
  @Input() creditCardId: string;

  public formCredit: FormGroup;
  formErrors: any;
  mask: any;
  creditCardLastDigits = '';
  currentCreditCardType: ICreditCardType;
  public creditCardTypeList: ICreditCardType[] = [];
  subscriptions: Subscription[] = [];
  states = [
    { name: 'Alabama', abbrev: 'AL' },
    { name: 'Alaska', abbrev: 'AK' },
    { name: 'Arizona', abbrev: 'AZ' },
    { name: 'Arkansas', abbrev: 'AR' },
    { name: 'California', abbrev: 'CA' },
    { name: 'Colorado', abbrev: 'CO' },
    { name: 'Connecticut', abbrev: 'CT' },
    { name: 'Delaware', abbrev: 'DE' },
    { name: 'Florida', abbrev: 'FL' },
    { name: 'Georgia', abbrev: 'GA' },
    { name: 'Hawaii', abbrev: 'HI' },
    { name: 'Idaho', abbrev: 'ID' },
    { name: 'Illinois', abbrev: 'IL' },
    { name: 'Indiana', abbrev: 'IN' },
    { name: 'Iowa', abbrev: 'IA' },
    { name: 'Kansas', abbrev: 'KS' },
    { name: 'Kentucky', abbrev: 'KY' },
    { name: 'Louisiana', abbrev: 'LA' },
    { name: 'Maine', abbrev: 'ME' },
    { name: 'Maryland', abbrev: 'MD' },
    { name: 'Massachusetts', abbrev: 'MA' },
    { name: 'Michigan', abbrev: 'MI' },
    { name: 'Minnesota', abbrev: 'MN' },
    { name: 'Mississippi', abbrev: 'MS' },
    { name: 'Missouri', abbrev: 'MO' },
    { name: 'Montana', abbrev: 'MT' },
    { name: 'Nebraska', abbrev: 'NE' },
    { name: 'Nevada', abbrev: 'NV' },
    { name: 'New Hampshire', abbrev: 'NH' },
    { name: 'New Jersey', abbrev: 'NJ' },
    { name: 'New Mexico', abbrev: 'NM' },
    { name: 'New York', abbrev: 'NY' },
    { name: 'North Carolina', abbrev: 'NC' },
    { name: 'North Dakota', abbrev: 'ND' },
    { name: 'Ohio', abbrev: 'OH' },
    { name: 'Oklahoma', abbrev: 'OK' },
    { name: 'Oregon', abbrev: 'OR' },
    { name: 'Pennsylvania', abbrev: 'PA' },
    { name: 'Rhode Island', abbrev: 'RI' },
    { name: 'South Carolina', abbrev: 'SC' },
    { name: 'South Dakota', abbrev: 'SD' },
    { name: 'Tennessee', abbrev: 'TN' },
    { name: 'Texas', abbrev: 'TX' },
    { name: 'Utah', abbrev: 'UT' },
    { name: 'Vermont', abbrev: 'VT' },
    { name: 'Virginia', abbrev: 'VA' },
    { name: 'Washington', abbrev: 'WA' },
    { name: 'West Virginia', abbrev: 'WV' },
    { name: 'Wisconsin', abbrev: 'WI' },
    { name: 'Wyoming', abbrev: 'WY' },
    { name: 'American Samoa', abbrev: 'AS' },
    { name: 'Federated States of Micronesia', abbrev: 'FM' },
    { name: 'Guam', abbrev: 'GU' },
    { name: 'Marshall Islands', abbrev: 'MH' },
    { name: 'Northern Mariana Islands', abbrev: 'MP' },
    { name: 'Puerto Rico', abbrev: 'PR' },
    { name: 'Palau', abbrev: 'PW' },
    { name: 'U.S. Virgin Islands', abbrev: 'VI' },
    { name: 'U.S. Minor Outlying Islands', abbrev: 'UM' },
  ];
  error: ICreditCardError;
  calledFrom: string;
  animateIn: boolean = false;
  animateOut: boolean = false;


  constructor(
    public topService: TopService,
    private formBuilder: FormBuilder,
    private creditCardService: CreditCardService,
    private zone: NgZone,
    private userService: UserService
  ) {
    this.formErrors = this.getDefaultFormErrors();
    this.mask = this.getDefaultMask();
  }


  ngOnInit() {
    this.animateIn = true;
    document.documentElement.classList.add('gg-modal');
    if (this.topService.isMobile) {
      document.documentElement.classList.add('is-modal');
    }

    this.calledFrom = this.creditCardService.verifyUrl();
    this.formCredit = this.createCreditForm();
    this.setUpSubscriptions();

    if (this.creditCardId) {
      this.getCreditCard();
    }
  }

  ngOnDestroy() {
    document.documentElement.classList.remove('gg-modal');
    if (this.topService.isMobile) {
      document.documentElement.classList.remove('is-modal');
    }
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  validateForm() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.formCredit.get(field) ? this.formCredit.get(field) : this.formCredit.get('billInfo.' + field);
      if (control && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }

  setUpSubscriptions() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      const control = this.formCredit.get(field) ? this.formCredit.get(field) : this.formCredit.get('billInfo.' + field);
      if (control) {
        this.subscriptions.push(
          control.valueChanges.subscribe(newValue => {
            if (control && !control.valid) {
              this.formErrors[field] = control.errors;
            } else {
              this.formErrors[field] = {};
            }
          })
        );
      }
    }
  }

  getCreditCard() {
    this.topService.loading = true;
    this.creditCardService.getCreditCard(this.creditCardId)
      .subscribe(
        (cc: ICreditCardRequest) => {
          this.topService.loading = false;
          const creditCard: ICreditCard = this.prepareDataToUpdate(cc);
          this.formCredit.patchValue(creditCard);
        },
        (err) => {
          console.log('Error trying to get a credit card: ' + err);
          this.topService.loading = false;
        }
      );
  }

  close() {
    this.animateIn = false;
    this.animateOut = true;
    setTimeout(() => {
      this.animateOut = false;
      this.closeCrditCardModal.emit();
    }, 200);
  }

  save() {
    if (this.formCredit.valid) {
      if (this.creditCardId) { // update
        const requestObj: ICreditCardRequest = this.prepareDataToSave();

        this.topService.loading = true;
        this.creditCardService.updateCreditCard(requestObj, this.creditCardId)
          .subscribe(
            (card: ICreditCard) => {
              this.topService.loading = false;
              if (card) {
                if (card.error) {
                  this.onCardRejectedByServer(card.error);
                } else {
                  this.userService.checkCreditCardListEmpty();
                  const index = this.creditCardService.creditCardList.findIndex(cc => cc.id === this.creditCardId);
                  this.creditCardService.creditCardList[index] = card;
                  this.animateIn = false;
                  this.animateOut = true;
                  setTimeout(() => {
                    this.animateOut = false;
                    this.closeCrditCardModal.emit(this.formCredit.value);
                  }, 200);
                }
              }
            },
            (err) => {
              this.topService.loading = false;
              console.log('Error trying to update a credit card: ' + err);
            }
          );
      } else { // create
        const requestObj: ICreditCardRequest = this.prepareDataToSave();

        this.topService.loading = true;
        this.creditCardService.createCreditCard(requestObj)
          .subscribe(
            (card: ICreditCard) => {
              if (card) {
                this.userService.checkCreditCardListEmpty();
                this.topService.loading = false;
                if (card.error) {
                  this.onCardRejectedByServer(card.error);
                } else {
                  this.creditCardService.creditCardList.push(card);
                  if (this.creditCardService.creditCardList.length === 1 && this.calledFrom === 'cart') {
                    this.creditCardService.selectedCreditCard = this.creditCardService.creditCardList[0];
                  }
                  this.animateIn = false;
                  this.animateOut = true;
                  setTimeout(() => {
                    this.animateOut = false;
                    this.closeCrditCardModal.emit(this.formCredit.value);
                  }, 200);
                }
              }
            },
            (err) => {
              console.log('Error trying to create a credit card: ' + err);
              this.topService.loading = false;
            }
          );
      }
    } else {
      this.validateForm();
    }
  }

  prepareDataToSave(): ICreditCardRequest {
    const cc: ICreditCard = this.formCredit.value;
    const requestObj: ICreditCardRequest = {
      billingAddress: {
        firstName: cc.billInfo.firstName,
        lastName: cc.billInfo.lastName,
        companyName: cc.billInfo.companyName,
        address: {
          address: cc.billInfo.address,
          apto: cc.billInfo.aptSuite,
          city: cc.billInfo.city,
          state: cc.billInfo.state,
          zip: cc.billInfo.zipCode
        },
        email: cc.billInfo.email,
        phone: cc.billInfo.phoneNumber
      },
      card: {
        cardName: cc.nickname,
        cardHolderName: cc.holderName,
        cardExpMonth: +cc.expirationDate.substring(0, 2),
        cardExpYear: +('20' + cc.expirationDate.substring(2, 4)),
      }
    };

    if (!this.creditCardId) {
      requestObj.card.cardNumber = cc.number;
      requestObj.card.cardCCV = cc.ccv.toString();
    }

    return requestObj;
  }

  prepareDataToUpdate(cardResponse: ICreditCardRequest): ICreditCard {
    this.creditCardLastDigits = cardResponse.card.cardLast4;
    const creditCard: ICreditCard = {
      id: this.creditCardId,
      nickname: cardResponse.card.cardName,
      type: cardResponse.card.cardType,
      holderName: cardResponse.card.cardHolderName,
      expirationDate: this.formatExpirationDate(cardResponse.card.cardExpMonth, cardResponse.card.cardExpYear),
      billInfo: {
        firstName: cardResponse.billingAddress.firstName,
        lastName: cardResponse.billingAddress.lastName,
        companyName: cardResponse.billingAddress.companyName,
        aptSuite: cardResponse.billingAddress.address.apto,
        city: cardResponse.billingAddress.address.city,
        state: cardResponse.billingAddress.address.state,
        email: cardResponse.billingAddress.email,
        phoneNumber: cardResponse.billingAddress.phone,
        zipCode: cardResponse.billingAddress.address.zip,
        address: cardResponse.billingAddress.address.address,
      }
    } as ICreditCard;

    this.currentCreditCardType = { type: creditCard.type };
    return creditCard;
  }

  formatExpirationDate(cardExpMonth: number, cardExpYear: number): string {
    let expirationDate = cardExpMonth.toString() + cardExpYear.toString().substring(2, 4);
    if (expirationDate.length === 3) {
      expirationDate = '0' + expirationDate;
    }
    return expirationDate;
  }

  getDefaultFormErrors() {
    return {
      firstName: {},
      nickname: {},
      number: {},
      holderName: {},
      expirationDate: {},
      ccv: {},
      lastName: {},
      companyName: {},
      address: {},
      aptSuite: {},
      city: {},
      state: {},
      email: {},
      phoneNumber: {},
      zipCode: {},
    };
  }

  getDefaultMask() {
    return {
      phoneNumber: '(000) 000-0000 0*',
      cardNumber: '0000 - 0000 - 0000 - 0000',
      ccv: '000',
      expirationDate: '00 / 00',
      zipCode: '0*'
    };
  }

  getDefaultCreditCardType(): ICreditCardType {
    return {
      type: '',
      niceType: '',
      gaps: [],
      lengths: [],
      code: null
    };
  }

  createCreditForm(): FormGroup {
    const formCredit: FormGroup = this.formBuilder.group({
      billInfo: this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        companyName: ['', Validators.required],
        address: ['', Validators.required],
        aptSuite: [''],
        city: ['', Validators.required],
        state: ['', Validators.required],
        zipCode: ['', Validators.required],
        email: ['', [Validators.required, Validators.email, this.validateEmailExtension.bind(this)]],
        phoneNumber: ['', [Validators.required /*this.validatePhoneNumberLength.bind(this)*/]]
      }),
      // number: ['', [Validators.required, this.validateCreditCard.bind(this)]],
      holderName: ['', Validators.required],
      nickname: ['', Validators.required],
      expirationDate: ['', [Validators.required, this.validateExpirationDate.bind(this)]],
      // ccv: ['', [Validators.required, this.validateCCV.bind(this)]]
    });

    if (!this.creditCardId) {
      formCredit.addControl('number', new FormControl('', [Validators.required, this.validateCreditCard.bind(this)]));
      formCredit.addControl('ccv', new FormControl('', [Validators.required, this.validateCCV.bind(this)]));
    }

    return formCredit;
  }

  validateEmailExtension(control: AbstractControl) {
    const email = control.value;
    if ( !(/\S+@\S+\.\S+/).test(email) ) {
      return { validaEmailExtension: false};
    }
    return null;
  }

  validatePhoneNumberLength(control: AbstractControl) {
    const phoneNbr = control.value;
    if (phoneNbr.length > 0 && phoneNbr.length !== 10) {
      return { validPhoneNumberLength: false };
    }
    return null;
  }

  validateCreditCard(control: AbstractControl) {
    // identify what kind of credit card is
    this.creditCardTypeList = creditCardType(control.value).filter(function (card) {
      return [CardType.MASTERCARD, CardType.VISA, CardType.AMERICAN_EXPRESS].includes(card.type);
    });

    // TODO: move this to the end (show the credit card img just when the card number is valid)
    if (this.creditCardTypeList.length === 1) {
      this.currentCreditCardType = this.creditCardTypeList[0];
      this.setMask();
    } else {
      this.currentCreditCardType = this.getDefaultCreditCardType();
      this.mask = this.getDefaultMask();
    }

    // If unrecognized
    if (control.value !== '' && this.creditCardTypeList.length === 0) {
      return { /*validCreditCardType*/validCreditCardNumber: false };
    }

    // If ambiguous
    if (control.value !== '' && this.creditCardTypeList.length > 1) {
      return { /*validCreditCardUnique*/validCreditCardNumber: false };
    }

    // If invalid length
    if (this.creditCardTypeList.length === 1 && !this.creditCardTypeList[0].lengths.includes(control.value.length)) {
      return { /*validCreditCardLength*/validCreditCardNumber: false };
    }

    // If invalid Luhn algorithm
    if (control.value !== '' && !this.validLuhn(control.value)) {
      return { /*validCreditCardLuhn*/validCreditCardNumber: false };
    }

    // If invalid credit card pattern (format)
    if (control.value !== '' && !this.validPattern(control.value, this.creditCardTypeList[0])) {
      return { /*validCreditCardPattern*/validCreditCardNumber: false };
    }

    return null;
  }

  validateExpirationDate(control: AbstractControl) { // validation according to the client machine time
    const expDate = control.value.replace(/\D/g, '');
    if (expDate !== '' && expDate.length !== 4) {
      return { validCreditCardExpDate: false };
    }

    if (expDate.length === 4) {
      const currentMonth = +(new Date().getMonth() + 1);
      const currentYear = +(new Date().getFullYear().toString().substring(2, 4));
      const month = +expDate.substring(0, 2);
      const year = +expDate.substring(2, 4);

      if (month < 1 || month > 12 ||
        year < currentYear ||
        (year === currentYear && month < currentMonth)
      ) {
        return { validCreditCardExpDate: false };
      }
    }
    return null;
  }

  validateCCV(control: AbstractControl) {
    const ccv = control.value.toString().replace(/\D/g, '');

    // If there is no a recognized credit card, then do not validate ccv (return valid)
    if (this.currentCreditCardType.type === '' || ccv === '') {
      return null;
    }

    if (this.currentCreditCardType.type === CardType.AMERICAN_EXPRESS && !(/^\d{4}$/).test(ccv)) {
      return { validCreditCardCCV: false };
    }

    if ([CardType.MASTERCARD, CardType.VISA].includes(this.currentCreditCardType.type) && !(/^\d{3}$/).test(ccv)) {
      return { validCreditCardCCV: false };
    }

    return null;
  }

  validLuhn(value: string): boolean { // Luhn Algorithm:
    value = value.replace(/\D/g, ''); // remove all non digit characters
    let sum = 0;
    let shouldDouble = false;

    for (let i = value.length - 1; i >= 0; i--) { // loop through values starting at the rightmost side
      let digit = +value.charAt(i);
      if (shouldDouble) {
        if ((digit *= 2) > 9) {
          digit -= 9;
        }
      }
      sum += digit;
      shouldDouble = !shouldDouble;
    }
    return (sum % 10) === 0;
  }

  validPattern(value: string, creditCardTypeIdentified: ICreditCardType): boolean {
    const regExPatterns = {
      'visa': /^4[0-9]{12}(?:[0-9]{3})?$/,
      'mastercard': /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
      'american-express': /^3[47][0-9]{13}$/
    };
    const regex: RegExp = regExPatterns[creditCardTypeIdentified.type];
    return regex.test(value);
  }

  onCardRejectedByServer(error: ICreditCardError): void {
    this.error = error;

    // forced errors like in mock up
    this.formErrors.holderName.required = true;
    this.formErrors.number.required = true;
    this.formErrors.expirationDate.required = true;
    this.formErrors.ccv.required = true;
  }

  setMask() {
    this.formCredit.patchValue(
      { ccv: this.formCredit.value.ccv.toString().substring(0, this.formCredit.value.ccv.length) }
    );

    if (this.currentCreditCardType.type === CardType.AMERICAN_EXPRESS) {
      this.mask.cardNumber = '0000 000000 00000';
      this.mask.ccv = '0000';
    } else if ([CardType.MASTERCARD, CardType.VISA].includes(this.currentCreditCardType.type)) {
      this.mask.cardNumber = '0000 0000 0000 0000';
      this.mask.ccv = '000';
    }
  }

  setAddress(addrObj: IGoogleAddress, form) {
    this.zone.run(() => {
      this.formCredit.patchValue({ city: addrObj.locality ? addrObj.locality : '' });
      this.formCredit.patchValue({ state: addrObj.admin_area_l1 ? addrObj.admin_area_l1 : '' });
      this.formCredit.patchValue({ zipCode: addrObj.postal_code ? addrObj.postal_code : '' });
      let address = '';
      address += addrObj.street_number ? addrObj.street_number + ' ' : '';
      address += addrObj.route ? addrObj.route : '';
      this.formCredit.patchValue({ address: address.length ? address : '' });
    });
  }

  showCreditCardImage(type: string): boolean {
    if (this.creditCardId) {
      if (this.currentCreditCardType) {
        return this.currentCreditCardType.type === type;
      }
      return false;
    } else {
      if (this.creditCardTypeList.length) {// in case the nbr is ambiguous, then show corresponding images
        const card = _.find(this.creditCardTypeList, function (cc) { return cc.type === type; });
        return this.currentCreditCardType.type === type || card !== undefined;
      }
    }
  }


}
