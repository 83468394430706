import { Component, OnInit, OnDestroy } from '@angular/core';
import { TopService } from '../../shared/services/top.service';
import { AuctionsService } from './auctions.service';
import { SharedService } from '../../shared/shared.service';
import { StatusFilterEnum } from '../enums/statusFilterEnum';
import { SessionService } from '../../services/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auctions',
  templateUrl: './auctions.component.html',
  styleUrls: ['./auctions.component.scss']
})
export class AuctionsComponent implements OnInit, OnDestroy {

  constructor(public topService: TopService,
    public auctionsService: AuctionsService,
    public sharedService: SharedService,
    private sessionService: SessionService,
    private router: Router,
    ) { 
      this.topService.trendingNavVisible = true;
    }

  ngOnInit() {
    if (this.sessionService.userRole === 'SELLER' || this.sessionService.userRole === 'ADMIN') {
      this.router.navigate(['/vendor/dashboard']);
      return;
    }
    this.topService.showFilterBtn = true;
    this.sharedService.isCorpSite = false;
  }

  ngOnDestroy() {
    this.topService.trendingNavVisible = false;
    this.auctionsService.searchPayload.statusFilter = StatusFilterEnum.active;
  }

}
