<div class="filters-banner" [class.mobile]="topService.isMobile">
  <div class="filters-banner-content">
    <div class="filters-banner-content__text">
      <h1>Save Your Searches</h1>
      <div class="buttons">
        <button *ngIf="sessionService.userRole !== 'GUEST' && sessionService.userRole" type="button" (click)="openUserFilterModal($event)">Add New Search</button>
        <a [routerLink]="['/user/filters']">Saved Searches</a>
      </div>
      <span (click)="isFaqIsOpen = true">How it works?</span>
    </div>
  </div>
</div>

<app-user-filters-modal *ngIf="itemsService.userFilterModalOpen" (closeFilterModal)="itemsService.userFilterModalOpen = false">
</app-user-filters-modal>

<app-user-filters-faq *ngIf="isFaqIsOpen" (onCloseModal)="isFaqIsOpen = false;"></app-user-filters-faq>
