<div 
  class="control" 
  [class.customer-select]="enableFilter" 
  [class.__buyer-portal]="isFromBuyerPortal" 
  [class.__dark-theme]="darkTheme"
  [class.__white-bordered-theme]="whiteBorderedTheme"
  [class.__offers-select]="offersSelect"

>
  <div class="control__label" *ngIf="label">{{label}}</div>
  <div class="app-dropdown" (click)="toggleDropdown()" (clickOutside)="onClickOutside()" [attachOutsideOnClick]="true">
    <!-- [exclude]="'.app-dropdown'" -->
    <div class="app-dropdown-white">
      <button class="btn dropdown-toggle" [class.active]="expanded" [disabled]="disabled" (click)="setFocusToFilter()" [class.__item-removed]="showInRed">
        <span class="items-filter-select-name">
          {{titlePrefix}}{{getTitle()}}
        </span>
      </button>

      <div class="dropdown-menu" [class.show]="expanded" *ngIf="items">
        <div *ngIf="enableFilter" class="dropdown-search-wrapper">
          <input type="text" autocomplete="off" class="dropdown-search"
            placeholder=""
            id="{{'ddf_' + sufix}}" 
            #inputFilter
            (click)="stopPropagation($event)"
            (keyup.enter)="onKeyupEnter($event)"
            (keydown.esc)="onKeydownEsc($event)"
            >
        </div>
        <div class="dropdown-search-results">
          <a class="dropdown-item" *ngIf="showNoResultsFound" (click)="stopPropagation($event)" > No results found </a>
          <a class="dropdown-item" *ngFor="let item of items" [class.active]="selectedValue === item[fieldToSave]"
            (click)="onSelectItem(item, $event)"
            [hidden]="(enableFilter && selectedValue && !selectedValueBelongToFilteredList && selectedValue === item[fieldToSave]) || 
            (hideSelectedValueFromOptions && selectedValue === item[fieldToSave])"
            [class.option-disabled]="item?.dropdownOptionDisabled">
            {{item[fieldToShow]}}
            {{getCustomDropdownOption(item)}}
          </a>
        </div>

        <div class="reduce-tooltip" *ngIf="showCostPopup">
          <img src="/assets/images/alert.svg" class="reduce-tooltip__icon" width="30px" height="30px" alt="alert"/>
          <span class="reduce-tooltip__txt">In order to reactivate, reduce cost by 2%</span>
        </div>
      </div>
    </div>
  </div>
</div>