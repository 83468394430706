<div class="po-wrap">
  <div class="po-modal-wrapper" *ngIf="showTrackingModal"></div>
  <div class="po-header-wrapper">
    <div class="po-header">

      <!-- TOTAL -->
      <div class="po-header-heading">
        <img src="assets/images/vendor-menu-icons/manage-order.svg" width="28" height="28px" alt="Purchase Orders">
        <span>Purchase Orders</span>
        <span class="__bage"> {{poTotal}}</span>
      </div>

      <!-- SEARCH -->
      <div class="po-header-search">
        <button (click)="verifyIfSearch({key: 'Enter'})" [disabled]="!poPayload.searchText.length">
          <svg height="24" viewBox="0 0 32 32" width="24" xmlns="http://www.w3.org/2000/svg">
            <path fill="#fff"
              d="m25.84375 22.8029159-4.4166667-4.4727935c.71875-1.2548816 1.09375-2.6503515 1.09375-4.0562354 0-4.56131215-3.7083333-8.273887-8.2604166-8.273887-4.55208337 0-8.2604167 3.71257485-8.2604167 8.273887 0 4.5613122 3.70833333 8.273887 8.2604167 8.273887 1.453125 0 2.890625-.4009372 4.171875-1.1663629l4.3958333 4.4571726c.0989583.0989326.2395833.1614163.3802083.1614163s.28125-.0572768.3802084-.1614163l2.2552083-2.2806561c.2083333-.213486.2083333-.5467326 0-.7550117zm-11.5833333-13.57979692c2.78125 0 5.0416666 2.26503512 5.0416666 5.05076802s-2.2604166 5.050768-5.0416666 5.050768-5.0416667-2.2650351-5.0416667-5.050768 2.2604167-5.05076802 5.0416667-5.05076802z" />
          </svg>
        </button>
        <input type="text" id="search-items-input" placeholder="Search" autocomplete="off"
          [(ngModel)]="poPayload.searchText" (keyup)="verifyIfSearch($event)" />
      </div>

      <!-- STATUS FILTER  -->
      <div class="po-header-filter">
        <app-dropdown 
        [items]="poStatusList" 
        [fieldToShow]="'name'" [fieldToSave]="'name'" [titlePrefix]="'Status '"
        [(ngModel)]="selectedStatusName" 
        [defaultTitle]="poSelectedStatus.name"
        (onSelect)="filterPurchaseOrders($event)" darkTheme="true">
      </app-dropdown>

      </div>
    </div>
  </div>


  <div class="po-items" *ngIf="purchaseOrders?.length || loading" [class.margin-bottom]="poTotal > pageSize && purchaseOrders?.length">
    <!-- SORT -->
    <div class="po-items-sort-wrapper">
      <div class="po-items-sort">
        <button type="button" (click)="sortPurchaseOrders(poSortEnum.Date)" class="__date">
          Date
          <ng-container *ngIf="poPayload.sortCondition === poSortEnum.Date">
            <img src="assets/images/vendor-icons/icon-sort-1.svg" *ngIf="poPayload.sortDirection === 'DESC'" />
            <img src="assets/images/vendor-icons/icon-sort-2.svg" *ngIf="poPayload.sortDirection === 'ASC'" />
          </ng-container>
          <img src="assets/images/vendor-icons/icon-sort-default.svg"
            *ngIf="poPayload.sortCondition !== poSortEnum.Date" />
        </button>

        <button type="button" (click)="sortPurchaseOrders(poSortEnum.DocNumber)" class="__po">
          Purchase Order
          <ng-container *ngIf="poPayload.sortCondition === poSortEnum.DocNumber">
            <img src="assets/images/vendor-icons/icon-sort-1.svg" *ngIf="poPayload.sortDirection === 'DESC'" />
            <img src="assets/images/vendor-icons/icon-sort-2.svg" *ngIf="poPayload.sortDirection === 'ASC'" />
          </ng-container>
          <img src="assets/images/vendor-icons/icon-sort-default.svg"
            *ngIf="poPayload.sortCondition !== poSortEnum.DocNumber" />
        </button>

        <button type="button" (click)="sortPurchaseOrders(poSortEnum.Amount)">
          Order Amount
          <ng-container *ngIf="poPayload.sortCondition === poSortEnum.Amount">
            <img src="assets/images/vendor-icons/icon-sort-1.svg" *ngIf="poPayload.sortDirection === 'DESC'" />
            <img src="assets/images/vendor-icons/icon-sort-2.svg" *ngIf="poPayload.sortDirection === 'ASC'" />
          </ng-container>
          <img src="assets/images/vendor-icons/icon-sort-default.svg"
            *ngIf="poPayload.sortCondition !== poSortEnum.Amount" />
        </button>

        <button type="button" (click)="sortPurchaseOrders(poSortEnum.Status)">
          Status
          <ng-container *ngIf="poPayload.sortCondition === poSortEnum.Status">
            <img src="assets/images/vendor-icons/icon-sort-1.svg" *ngIf="poPayload.sortDirection === 'DESC'" />
            <img src="assets/images/vendor-icons/icon-sort-2.svg" *ngIf="poPayload.sortDirection === 'ASC'" />
          </ng-container>
          <img src="assets/images/vendor-icons/icon-sort-default.svg"
            *ngIf="poPayload.sortCondition !== poSortEnum.Status" />
        </button>
      </div>
    </div>

    <div class="po-items-lines">
      <ng-container *ngIf="purchaseOrders.length && !loading" >
          <app-po-line *ngFor="let po of purchaseOrders" (click)="getPODetails(po)" class="app-po-line" id="{{'po_' + po.docEntry}}"
         [poLine]="po" [highlight]="showTrackingModal && po?.docEntry === currentPo?.docEntry"
          (showModal)="showTracking(po)">
        </app-po-line>
      </ng-container>
      

      <app-vendor-items-preloader [source]="'purchase'" *ngIf="loading"></app-vendor-items-preloader>
    </div>
  </div>

  <app-po-empty *ngIf="!purchaseOrders?.length && !loading"></app-po-empty>
</div>



<!-- TRACKING -->
<div class="add-tracking-wrapper" *ngIf="showTrackingModal">
  <div class="window add-tracking">
    <div class="close" (click)="closeTracking()">
      <img src="../../../../assets/images/close.svg" />
    </div>
    <div class="add-tracking-title">Shipment Tracking <br> PO {{currentPo.docNumber}}</div>

    <form class="add-tracking-form" [formGroup]="form">
      <div class="add-tracking-form__half-wrapper">
        <div class="add-tracking-form__half">
          <div class="add-tracking-form-input">
            <label for="vendor-invoice">Vendor Invoice</label>
            <input type="text" name="vendor-invoice" id="vendor-invoice" formControlName="invoice" autocomplete="off"
              [class.required]="formErrors.invoice.required || formErrors.invoice.maxlength"/>
          </div>
          <div class="add-tracking-form-input">
            <label for="carrier">Carrier Name<i class="__required"></i></label>
            <input type="text" name="carrier" id="carrier" formControlName="carrierName" autocomplete="off"
              [class.required]="formErrors.carrierName.required || formErrors.carrierName.maxlength || formErrors.carrierName.emptySpaces === false" />
          </div>
          <div class="add-tracking-form-input">
            <label for="bol">BOL #</label>
            <input type="text" name="bol" id="bol" formControlName="bol" [class.required]="formErrors.bol.required || formErrors.bol.maxlength"
              autocomplete="off" />
          </div>
        </div>

        <div class="add-tracking-form__half">
          <div class="add-tracking-form-input">
            <label for="shipping-date">Shipment Date<i class="__required"></i></label>
            <input type="text" name="shipping-date" id="shipping-date" placeholder="mm/dd/yyyy" autocomplete="off"
              [bsConfig]="{containerClass: 'theme-calendar', showWeekNumbers: false}" bsDatepicker
              formControlName="shipmentDate"
              [class.required]="formErrors.shipmentDate.required || formErrors.shipmentDate.validDate === false  || formErrors.shipmentDate.emptySpaces === false" />
          </div>
          <div class="add-tracking-form-input">
            <label for="transportation-mode">Shipment Type</label>
            <input type="text" name="transportation-mode" id="transportation-mode" formControlName="shipmentType"
              autocomplete="off" [class.required]="formErrors.shipmentType.required || formErrors.shipmentType.maxlength" />
          </div>
          <div class="add-tracking-form-input">
            <label for="eta">ETA to HUBX<i class="__required"></i></label>
            <input type="text" name="eta" id="eta" formControlName="etaDate" placement="top" placeholder="mm/dd/yyyy"
              [bsConfig]="{containerClass: 'theme-calendar', showWeekNumbers: false}" bsDatepicker autocomplete="off"
              [class.required]="formErrors.etaDate.required || formErrors.etaDate.validDate === false || formErrors.etaDate.emptySpaces === false" />
          </div>
        </div>
      </div>

      <div class="add-tracking-form__full">
        <div class="add-tracking-form-input">
          <label for="tracking-number">Tracking Number<i class="__required"></i></label>
          <input type="text" name="tracking-number" id="tracking-number" formControlName="trackingNbr"
            autocomplete="off"
            [class.required]="formErrors.trackingNbr.required || formErrors.trackingNbr.maxlength || formErrors.trackingNbr.emptySpaces === false" />
        </div>
        <div class="add-tracking-form-input">
          <label for="notes">Shipping notes</label>
          <textarea name="notes" id="notes" rows="4" cols="46" formControlName="notes"
            [class.required]="formErrors.notes.required || formErrors.notes.maxlength"></textarea>
        </div>
      </div>

      <div class="add-tracking-form__ctrl">
        <button class="__cancel" (click)="closeTracking()" [disabled]="loading">Cancel</button>
        <button class="__submit" (click)="saveTracking()" [disabled]="loading || !form.valid">
          Save
        </button>
      </div>
    </form>
  </div>
</div>

<div class="items-bottom-controls" *ngIf="poTotal > pageSize && purchaseOrders?.length">
  <pagination [totalItems]="poTotal"
    [itemsPerPage]="pageSize" (pageChanged)="pageChanged($event)" [(ngModel)]="poPayload.pagination.pageNumber"
    [maxSize]="paginationMaxSize" [boundaryLinks]="true" [disabled]="false">
  </pagination>
</div>
