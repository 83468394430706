
<!-- ORDER COMPLETED MESSAGE -->
<div class="thank-you" [class.mobile]="topService.isMobile">
	<div class="thank-you-title">Thank You!</div>
	<div class="thank-you-message">Your Order is in Progress. <span>Order Number: {{ ordersService.orderChosen.docNumber }}</span></div>
	<div class="thank-you-ctrls">
		<a (click)="!topService.loading?gotoCatalog():''" class="thank-you-btn">Continue Shopping</a>
    	<a (click)="gotoHistory($event)" class="thank-you-btn _details">View Order</a>
	</div>
	<div class="loader-red" *ngIf="topService.loading"></div>
</div>
