import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { IItem, eNotificationStatusBuyer } from '../interfaces/IItem';
import { INotificationCenterTab } from '../interfaces/INotificationCenterTab';
import { NotificationCenterBuyerService } from './notification-center.service';
import { Subscription } from 'rxjs';
import { PubSubService } from '../../core/pubsub.service';
import * as _ from 'lodash';
import {NotificationSortingDirection, NotificationTypesEnum} from '../../shared/interfaces/INotificationDetail';
import { NotificationService } from '../../shared/services/notification.service';
import { map } from 'rxjs/operators';
import { SharedService } from '../../shared/shared.service';
import { SharedSource } from '../../../app/core/shared-source';
import { NotificationFiltersEnum, eNotificationAction } from '../../shared/interfaces/INotificationDetail';
import { NotificationCenterHeaderComponent } from './notification-center-header/notification-center-header.component';
import { TopService } from '../../shared/services/top.service';
import { Angular2Csv } from 'angular2-csv';
import { ItemsService } from '../../shared/services/items.service';
import { SessionService } from '../../services/session.service';
import { UserService } from '../../user/user.service';
import { environment } from '../../../environments/environment';
import * as uuid from 'uuid';



@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnInit, OnDestroy {
  @ViewChild(NotificationCenterHeaderComponent, { static: true }) notifCenterHeaderComponent: NotificationCenterHeaderComponent;
  pubServiceSubscription: Subscription;

  NotificationFiltersEnum = NotificationFiltersEnum;
  eNotificationAction = eNotificationAction;

  notifications = [];
  activeTab = NotificationFiltersEnum.all;
  sortDirection = NotificationSortingDirection.desc;
  notificationRequest: INotificationBuyerRequest = {
    pagination: { pageSize: 25 },
    orderBy: {
      sortDirection: 0
    }
  };
  newNotificationQty = 0;
  showNewNotification = false;
  hideMoreBtn = false;
  paginationSubscription: Subscription;

  notificationBulkActionState = {
    selected: 0,
    total: 0
  };

  constructor(private _pubSubSvc: PubSubService,
    public notificationSvcBuyer: NotificationCenterBuyerService,
    private _notificationSvcShared: NotificationService,
    public topService: TopService,
    private itemsService: ItemsService,
    private userService: UserService,
    private _sessionService: SessionService,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.itemsService.resetNavigationAndCorpSite();
    this.topService.showFilterBtn =  this.topService.isMobile ? true : false;
    // since notifications items show in listing form
    // we need to change it for all other views for consistency
    this.topService.gridView = true;
    // remove active item in top navigation e.g: todays deals
    this.itemsService.selectedTopAttribute = '';
    if ( !this.userService.isGuest ) {
      this.getNotifications(true, this.activeTab);
    }
    this.pubServiceSubscription = this._pubSubSvc.sharedSubject.subscribe(myEvent => {
      if (myEvent.name === SharedSource.notificationTabBuyer) {
        this.topService.loading = true;
        this.onNotificationTabChage(myEvent);
      } else if ([SharedSource.priceDropFromVendor, SharedSource.waitlistFromVendor, SharedSource.moreInventoryFromVendor]
        .includes(myEvent.name)) {
        if (myEvent.data.notificationType === this.getNotificationType(this.activeTab) || this.activeTab === NotificationFiltersEnum.all) {
          this.showNewNotification = true;
          this.newNotificationQty++;
        }
      }
    });

    this.topService.isCatalog = false;
  }

  ngOnDestroy() {
    this.topService.showFilterBtn = false;
    this.markNotificationsAsRead();
    if (this.pubServiceSubscription) {
      this.pubServiceSubscription.unsubscribe();
    }
   if (this.paginationSubscription){
      this.paginationSubscription.unsubscribe();
    }
  }

  pageChanged(event?) {
      this.notificationRequest.pagination.pageNumber = event.page;
      if (event.page === 1) {
        this.getNotifications(true, this.activeTab);
      } else {
        this.getNotifications(false, this.activeTab);
      }
  }

  updatePagination() {
    if (this.notificationRequest.pagination.pageNumber > 1 && !this.notifications.length) {
      this.notificationRequest.pagination.pageNumber--;
    }
    this.getNotifications(this.notificationRequest.pagination.pageNumber === 1, this.activeTab);
  }

  getNotifications(isFirstPage?: boolean, notificationFilter?: NotificationFiltersEnum): void {
    this.topService.loading = true;
    this.notificationRequest.notificationType = notificationFilter;
    this.notificationRequest.orderBy.sortDirection = this.notificationSvcBuyer.sortDirection;
    if (isFirstPage) {
      this.notificationRequest.pagination.pageNumber = 1;
      this.showNewNotification = false;
      this.newNotificationQty = 0;
    }
    if (this.paginationSubscription) { this.paginationSubscription.unsubscribe(); }
    this.paginationSubscription = this.notificationSvcBuyer.getNotifications(this.notificationRequest)
      .subscribe(
        (data) => {
            this.notificationRequest.pagination.pageNumber = data.pagination.currentPage;
            this.notificationRequest.pagination.totalCount = data.pagination.totalCount;
            this.notificationRequest.pagination.totalPages = data.pagination.totalPages;

          const notifications = data.buyerNotifications.map(it => {
            it.item.notificationDetails = it.notificationDetails;
            it.item.lastNotificationDate = it.lastNotificationDate;
            it.item.isSelected = false;
            return it.item;
          });
          this.sortNotificationDetails(notifications);

          if (this.notifications && this.notifications.length && !isFirstPage) {
            this.notifications = notifications;
          } else {
            this.notifications = notifications;
          }
          this._notificationSvcShared.updateItemLogo(this.notifications);
          this.notifications.map(item => {
            item.highlightId = uuid.v4();
          });
          this.topService.loading = false;

          this.resetNotificationBulkActionState();
          this.notificationBulkActionState.total = this.notifications.length;
        },
        (err) => { this.topService.loading = false; }
      );
  }

  onNotificationTabChage(obj) {
    this.markNotificationsAsRead();
    this.notifications = [];
    this.activeTab = obj.data;
    this.getNotifications(true, this.activeTab);
  }

  markNotificationsAsRead() {
    if (this.activeTab !== NotificationFiltersEnum.all && this.activeTab !== NotificationFiltersEnum.archive) {
      const type = this.getNotificationType(this.activeTab);
      this.notificationSvcBuyer.markNotificationsAsRead(type)
        .subscribe(
          (data) => {
            this.notifCenterHeaderComponent.getCounters();
          }
        );
    } else {
      this.notifCenterHeaderComponent.getCounters();
    }
  }

  getNotificationType(filter: NotificationFiltersEnum): NotificationTypesEnum {
    if (filter === NotificationFiltersEnum.priceDrop) {
      return NotificationTypesEnum.priceDrop;
    } else if (filter === NotificationFiltersEnum.moreInventory) {
      return NotificationTypesEnum.moreInventory;
    } else if (filter === NotificationFiltersEnum.waitlist) {
      return NotificationTypesEnum.waitlist;
    }
    return null;
  }

  sortNotificationDetails(notifications) {
    notifications.forEach(notification => {
      const sortedDetails = [];

      notification.notificationDetails.forEach(nd => {
        if (nd.notificationType === NotificationTypesEnum.priceDrop) {
          sortedDetails[0] = nd;
        } else if (nd.notificationType === NotificationTypesEnum.waitlist) {
          sortedDetails[1] = nd;
        } else if (nd.notificationType === NotificationTypesEnum.moreInventory) {
          sortedDetails[2] = nd;
        }
      });
      for (let i = 0; i < 3; i++) {
        if (!sortedDetails[i]) {
          sortedDetails[i] = {
            isNew: null,
            notificationType: null,
            notificationStatus: null,
            notificationValue: null,
            timeStamp: null,
          };
          if (i === 0) {
            sortedDetails[i].notificationType = NotificationTypesEnum.priceDrop;
          } else if (i === 1) {
            sortedDetails[i].notificationType = NotificationTypesEnum.waitlist;
          } else if (i === 2) {
            sortedDetails[i].notificationType = NotificationTypesEnum.moreInventory;
          }
        }
      }
      notification.notificationDetails = sortedDetails;
    });
  }

  resetNotificationBulkActionState(){
    this.notificationBulkActionState.selected = 0;
    this.notificationBulkActionState.total = 0;
  }

  onSelect(isSelected: boolean){
    this.notificationBulkActionState.selected =
      isSelected ? this.notificationBulkActionState.selected + 1 : this.notificationBulkActionState.selected - 1;
  }

  selectUnselectAll(areSelectedAll: boolean){
    this.notifications.forEach(notification => {
      notification.isSelected = areSelectedAll;
    });
    this.notificationBulkActionState.selected = areSelectedAll ? this.notificationBulkActionState.total : 0;
  }

  manageNotifications(action: eNotificationAction, activeTab: NotificationFiltersEnum) {
    this.topService.loading = true;
    const notificationsList = this.selectedNotifications();
    this.notificationSvcBuyer.notifyMeList(action, activeTab, notificationsList)
    .subscribe(
      (data) => {
        setTimeout(() => {
          this.resetNotificationBulkActionState();
          this.updatePagination();
        }, environment.notificationRefreshingWait * notificationsList.length);
      },
      (err) => { this.topService.loading = false; }
    );
  }

  selectedNotifications(){
    let notifications = this.notifications.filter(notification => notification.isSelected);
    return notifications;
  }
}

export interface INotificationBuyerRequest {
  notificationType?: NotificationFiltersEnum;
  vendorId?: string;
  pagination?: {
    pageSize?: number,
    pageNumber?: number,
    totalCount?: number,
    totalPages?: number
  };
  searchQuery?: string;
  orderBy?: {
    orderByName?: string,
    sortDirection?: number
  };
  fields?: string;
}
