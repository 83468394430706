import { Component, Input , OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SharedService } from '../shared.service';
import { TopService } from '../services/top.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-grading-guide2',
  templateUrl: './grading-guide2.component.html',
  styleUrls: ['./grading-guide2.component.scss']
})
export class GradingGuide2Component implements OnInit {
  innerContentHeight: string;
  @Input() isMobile: boolean;

  constructor(public sanitizer: DomSanitizer,
              sharedService: SharedService,
              public topService: TopService) {

    if (this.topService.isMobile) {
      const mobileUrl = sharedService.gradingGuideHtmlPath.replace(new RegExp('\\index6.html\\b','g'), 'mobile/index6-mobile.html');
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(mobileUrl);
    } else {
      const desktopUrl = sharedService.gradingGuideHtmlPath.replace(new RegExp('\\index6.html\\b', 'g'), 'desktop/index6.html');
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(desktopUrl);
    }

  }
  urlSafe: SafeResourceUrl;
  ngOnInit() {
    this.innerContentHeight = '9065px';
    document.documentElement.classList.add('gg-modal');
    if (this.topService.isMobile) {
      document.documentElement.classList.add('is-modal');
      this.innerContentHeight = '6415px';
    }
    // if (this.isMobile === true) {
    //   this.innerContentHeight = '5180px'; // 5880
    // } else {
    //   this.innerContentHeight = '8140px';
    // }
  }

  ngOnDestroy() {
    document.documentElement.classList.remove('gg-modal');
    if (this.topService.isMobile) {
      document.documentElement.classList.remove('is-modal');
    }
  }
}
