import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IDashboardVendorRequest } from '../interface/IDashboardVendorRequest';
import { IOpenPurchaseOrder } from '../interface/IOpenPurchaseOrder';
import { Router } from '@angular/router';
import { IOrderCounters } from '../interface/IOrderCounters';
import { IVendorStatus } from '../interface/IVendorStatus';
import { VendorService } from '../vendor.service';
import { IPerformanceChart, IPerformanceChartResponse } from '../interface/IPerformanceChart';

@Injectable()
export class DashboardService {
  private _listingStatuses: IVendorStatus[] = [];
  restApi = '';
  userRole = '';
  totalListing: IVendorStatus;
  vendorHasItems = true;

  constructor(private http: HttpClient,
    private router: Router) {
    this.setUserRole();
  }

  set listingStatuses(val) {
    this.totalListing = val.find(status => status.name === 'All');
    this._listingStatuses = val.filter(s => s.name !== 'All');
  }

  get listingStatuses() {
    return this._listingStatuses;
  }

  getAllDashboardData(dashboardVendorRequest?: IDashboardVendorRequest): Observable<any> {
    const openPurchaseOrdersEndpoint = this.getOpenPurchaseOrders(dashboardVendorRequest.vendorId);
    return forkJoin([openPurchaseOrdersEndpoint]);
  }

  getOpenPurchaseOrders(vendorId?: string): Observable<IOpenPurchaseOrder> {
    this.restApi = 'po/opensummary/' + vendorId;
    const url = environment.vendorPortalBaseUrl + this.restApi;
    return this.http.get<IOpenPurchaseOrder>(url);
  }

  private setUserRole(): void {
    try {
      this.userRole = sessionStorage.getItem('user_role');
    } catch (err) {
      // alert('User Role not found in session storage');
      this.router.navigate(['']);
    }
  }

  isUser(users: string[]): boolean {
    const a = users.filter(user => user === this.userRole);
    return a.length > 0;
  }

  getOrderCounters(): Observable<IOrderCounters> {
    this.restApi = `po/totals`;
    const url = environment.vendorPortalBaseUrl + this.restApi;
    return this.http.get<IOrderCounters>(url);
  }

  getPerformanceChartData(fromDate: string, toDate: string): Observable<IPerformanceChartResponse[]> {
    const url = `${environment.vendorPortalBaseUrl}api/charts/performance?from=${fromDate}&to=${toDate}`;
    return this.http.get<IPerformanceChartResponse[]>(url);
  }

}
