import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { TopService } from '../../services/top.service';

@Component({
  selector: 'app-items-preloader',
  templateUrl: './items-preloader.component.html',
  styleUrls: ['./items-preloader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemsPreloaderComponent implements OnInit {

  @Input() isFromPastPurchases: boolean = false;
  @Input() isFromJustSold: boolean = false;
  @Input() isFromNotificationCenter: boolean = false;

  preloaderItemList = Array(4);

  constructor(
  	public topService: TopService,
  	) { }

  ngOnInit(): void {
		if (this.topService.gridView || this.isFromPastPurchases || this.isFromJustSold || this.isFromNotificationCenter) {
  		this.preloaderItemList.length = 4;
  	} else if (!this.topService.gridView) {
  		this.preloaderItemList.length = 15;
  	}
  }

}
