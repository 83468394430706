import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { IItem } from '../../interfaces/IItem';
import { IItemAttribute } from '../../interfaces/model';

@Component({
  selector: 'app-item-attributes',
  templateUrl: './item-attributes.component.html',
  styleUrls: ['./item-attributes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemAttributesComponent implements OnInit  {
  @Input() item: IItem;
  @Input() hideText: boolean;
  @Input() moreThanOnHand: boolean;
  @Input() isCart: boolean;
  @Input() isJustSold: boolean;
  @Input() isNotificationItem: boolean = false;
  environment = environment;
  attributes: IItemAttribute[];

  constructor() { }

  ngOnInit() {
    this.getVisibleAttributes();
  }

  getVisibleAttributes():void {
    this.attributes = this.item.attributes?.filter(a => !a.hide);
  }
}
