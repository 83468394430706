import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class RedirectAfterBuyerLoginGuard implements CanActivate {

  constructor(public router: Router, public authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isAuthenticated()  && (
      sessionStorage.getItem('user_role') === 'BUYER'
      || sessionStorage.getItem('user_role') === 'SALES'
      || sessionStorage.getItem('user_role') === 'SUPER')) {
      return true;
    } else {
      this.authService.redirectUrl = state.url;
      this.router.navigate(['guest/home'], {queryParams: {redirectTo: state.url}});
      return false;
    }
  }
}
