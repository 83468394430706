import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyDigits]'
})
export class OnlyDigitsDirective {
  inputElement: HTMLElement;
  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData
      .getData('text/plain')
      .replace(/\D/g, ''); // get a digit-only string
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('input', ['$event'])
  onInputChange(event) {
    const initValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initValue.replace(/[^0-9]*/g, '');
    if ( initValue !== this.el.nativeElement.value ) {
      event.stopPropagation();
    }
  }

  // @HostListener('drop', ['$event'])
  // onDrop(event: DragEvent) {
  //   event.preventDefault();
  //   const textData = event.dataTransfer.getData('text').replace(/\D/g, '');
  //   this.inputElement.focus();
  //   document.execCommand('insertText', false, textData);
  // }
}
