import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { TopService } from '../../shared/services/top.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(private sharedService: SharedService, public topService: TopService) { }

  ngOnInit() {
    this.sharedService.isStaticHtml = true;
    this.sharedService.isCorpSite = true;
  }

}
