export class Dictionary {
    items: any;

    constructor() {
        this.items = {};
    }

    has(key: string) {
        return this.items.hasOwnProperty(key);
    }

    set(key: string, value: any) {
        this.items[key] = value;
    }

    remove(key: string) {
        if (this.has(key)) {
            delete this.items[key];
            return true;
        }
        return false;
    }

    get(key: string) {
        return this.has(key) ? this.items[key] : undefined;
    }

    values() {
        const values = [];
        for (const key in this.items) {
            if (this.has(key)) {
                values.push(this.items[key]);
            }
        }
        return values;
    }

    size() {
        return Object.keys(this.items).length;
    }

    keys() {
        return Object.keys(this.items);
    }

    getItems() {
        return this.items;
    }
}
