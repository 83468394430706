import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SharedSource } from '../../../core/shared-source';

import * as models from '../../interfaces/model';
import { TopComponent } from '../../../buyer/top/top.component';

@Component({
  selector: 'app-guest-top',
  templateUrl: './guest-top.component.html',
  styleUrls: ['./guest-top.component.scss'],
})
export class GuestTopComponent extends TopComponent implements OnInit {
  disabledClassApplied: boolean;
  errors: any; // errors from server
  user_role = '';
  bulkapi_response = [];
  showMobileMenu = false;
  animateOut = false;
  bulkUploadInProgress = false;
  bulkUploadHasErrorAndSucess = false;
  gotFirst = 0;
  showloadString = ``;
  disabledClass = '';
  customersLoading = false;
  currentCustomer = '';
  currentUserEmail = '';
  loading = false;
  isResponse200 = false;
  searchAlert = false;
  isMobileSorting = false;
  topErrorModal: models.IBuyerErrorModal = {
    bodyText: '',
    title: 'Server Error',
    isShown: false
  };
  isEditOrder = false;
  selectedLink = '';
  navToggle = false;

  isCategoriesOpened = false;

  ngOnInit() {
    this.currentUserEmail = this.sessionService.userEmail;
    this.user_role = this.sessionService.userRole;

    this.pubSubService.sharedSubject.subscribe(
      (sharedValue) => {
        if (sharedValue.name === SharedSource.mobileSortingPopUpOpen) {
          this.isMobileSorting = sharedValue.data;
        }
        if (sharedValue.name === SharedSource.editOrderMode) {
          this.isEditOrder = sharedValue.data;
        }
      }
    );
  }

  gotoCart(): void {
    if (this.topService.loading) { return; }
    this.isEditOrder ?
      this.router.navigate(['cart'], { queryParams: { updatingOrder: 'Y' } }) : this.router.navigate(['cart']);
  }

  navigateTo(url: string): void {
    this.selectedLink = url;
    this.sharedService.staticHtmlUrl = url;

    if (this.sharedService.isVendorLoginUrl || url === 'guest/home') {
      this.sharedService.isStaticHtml = false;
      document.body.style.paddingTop = '';
      document.body.style.overflowY = '';
    } else {
      this.sharedService.isCorpSite = true;
      this.sharedService.isStaticHtml = true;
    }

    this.router.navigate([ '/' + url]);
  }

  navigateToAuctions(url: string) {
    this.selectedLink = url;
    this.router.navigate([ '/' + url]);
  }

  navigateToOutlets(url: string) {
   this.selectedLink = url;
   this.router.navigate(['/' + url]);
  }

  getCurrent(): string {
    return '';
  }

  ShowGuestPopUp(): void {
    this.selectedLink = ' ';
    this.sharedService.isCorpSite = false;
    this.userService.guestPop();
  }

  goToBuyerPortal(): void {
    this.selectedLink = ' ';
    this.sharedService.isCorpSite = false;


    this.navigateTo('/guest/home');
  }

  toggleNav(): void {
    this.navToggle = !this.navToggle;
  }

  close() {
    this.navToggle = false;
  }

  pastPurchaseCliked() {
    this.itemsService.isLandingPage = false;
    this.itemsService.isPastPurchase = true;
  }

}
