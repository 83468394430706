<div class="left-menu-wrapper">
	<div class="left-menu-block">
		<ul class="left-menu-ul">
			<li class="left-menu-item">
				<a [routerLink]="['/vendor/dashboard']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
				 (click)="goTo()">
					<img src="assets/images/vendor-menu-icons/dashboard.svg" />
					<span>Dashboard</span>
				</a>
			</li>
			<li class="left-menu-item">
				<a [routerLink]="['/vendor/notifications']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
				 (click)="goTo()">
					<img src="assets/images/notification/notification.svg" />
					<span>Notifications</span>
					<span class="new-notification" *ngIf="notificationCount > 0"></span>
				</a>
			</li>
			<li class="left-menu-item">
				<a [routerLink]="['/vendor/items']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="goTo()">
					<img src="assets/images/vendor-menu-icons/product-list.svg" />
					<span>Product Listings</span>
				</a>
			</li>
			<li class="left-menu-item">
				<a [routerLink]="['/vendor/purchase-orders']" routerLinkActive="active" [ngClass]="{ 'active': vendorService.isFullDetailsPage || vendorService.isPurchaseOrdersPage }"
				 (click)="goTo()">
					<img src="assets/images/vendor-menu-icons/manage-order.svg" />
					<span>Purchase Orders</span>
				</a>
			</li>
			<li class="left-menu-item ">
				<a [routerLink]="['/vendor/pending-sold']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
				 (click)="goTo()">
					<img src="assets/images/vendor-menu-icons/clock.svg" />
					<span>Pending PO</span>
				</a>
			</li>
			<li class="left-menu-item">
				<a [routerLink]="['/vendor/user/offers']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="goTo()">
					<img src="assets/images/icons/rebranding/offers-header.svg" />
					<span>Offers</span>
					<span class="new-offer" *ngIf="['ADMIN','VENDORMGR'].includes(userRole) && offerService.bpUnseenEventCount > 0"></span>
					<span class="new-offer" *ngIf="userRole === 'SELLER' && offerService.offersSummary?.unseenEventCount > 0"></span>
				</a>
			</li>
			<li class="left-menu-item">
				<a [routerLink]="['/vendor/user/mobile_notifications']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="goTo()">
					<img src="assets/images/icons/profile/mobile-sms.svg" />
					<span>Mobile | SMS</span>
				</a>
			</li>
			<li class="left-menu-item">
				<a [routerLink]="['/vendor/user/profile']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="goTo()">
					<img src="assets/images/vendor-menu-icons/login.svg" />
					<span>Login / User Settings</span>
				</a>
			</li>
		</ul>
	</div>
</div>
