import { Component, OnInit } from '@angular/core';
import { TopService } from '../../shared/services/top.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  constructor(
  	public topService: TopService
  	) { }

  ngOnInit() {
  	document.documentElement.scrollTop = 0;
  }

}
