<div class="trending-blocks-wrapper" [class.mobile]="topService.isMobile">
  <div class="block deals" (click)="goTo(environment.currentDeal.displayLabel)">
    <ng-container *ngIf="topService.getTopFilterByName(environment.currentDeal.displayLabel) as todayDeals">
      <div class="block__desc">
        <span class="block__desc__qty">{{ todayDeals.count }}</span>
        <span class="block__desc__name">{{ todayDeals.navName }}</span>
      </div>
    </ng-container>
    <div class="block__img">
      <i class="img"></i>
    </div>
  </div>
  <div class="block price-drop" (click)="goTo(topFiltersEnum.priceDrop)">
    <ng-container *ngIf="topService.getTopFilterByName(topFiltersEnum.priceDrop) as priceDrop">
      <div class="block__desc">
        <span class="block__desc__qty">{{ priceDrop.count }}</span>
        <span class="block__desc__name">{{ priceDrop.navName }}</span>
      </div>
    </ng-container>
    <div class="block__img">
      <i class="img"></i>
    </div>
  </div>
  <div class="block just-launched" (click)="goTo(topFiltersEnum.justLaunched)">
    <ng-container *ngIf="topService.getTopFilterByName(topFiltersEnum.justLaunched) as justLaunched">
      <div class="block__desc">
        <span class="block__desc__qty">{{ justLaunched.count }}</span>
        <span class="block__desc__name">{{ justLaunched.navName }}</span>
      </div>
    </ng-container>
    <div class="block__img">
      <i class="img"></i>
    </div>
  </div>
  <div class="block just-sold" (click)="goTo(topFiltersEnum.justSold)">
    <div class="block__desc">
      <!-- <span class="block__desc__qty">{{ justSoldValues.totalLineItems }}</span>
    	<span class="block__desc__name">Just Sold</span> -->
      <span
        class="block__desc__qty">{{ sharedService.justSoldValues.totalLineItems ? sharedService.justSoldValues.totalLineItems : 0 }}</span>
      <span class="block__desc__name">Just Sold</span>
    </div>
    <div class="block__img">
      <i class="img"></i>
    </div>
  </div>
</div>
