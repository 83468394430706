import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';

@Injectable()
export class ResetPasswordService {
  message: string;
  loading = false;

  constructor(private auth: AuthService) {
  }

  public resetPassword(email: string, connection: string): void {
    this.auth.resetPassword(email, connection);
    // if (doneOk) {
    //   this.message = 'We\'ve just sent you an email to reset your password.';
    // }
  }
}
