import { Component, OnInit, OnDestroy } from '@angular/core';
import { TopService } from '../../../shared/services/top.service';
import { UserFiltersService } from '../user-filters.service';
import { ItemsService } from '../../../shared/services/items.service';
import { MappedUserFilter } from '../../interfaces/MappedUserFilter';
import { Router } from '@angular/router';
import { UserFilter } from '../../interfaces/UserFilter';
import { ItemFilterService } from '../../../shared/item-filter/item-filter.service';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';

@Component({
  selector: 'app-user-filters-list',
  templateUrl: './user-filters-list.component.html',
  styleUrls: ['./user-filters-list.component.scss']
})
export class UserFiltersListComponent implements OnInit, OnDestroy {
  isFormIsOpen = false;
  isFaqIsOpen = false;
  subscription = new Subscription();
  userFilterId;
  showDelSavedSearch: number;

  constructor(
    public topService: TopService,
    public userFiltersService: UserFiltersService,
    private itemsService: ItemsService,
    public itemFilterService: ItemFilterService,
    private router: Router,
    public sharedService: SharedService,
    private pubSubService: PubSubService
  ) { }

  ngOnInit() {
    if (!this.userFiltersService.userFilterList?.length &&
      !this.userFiltersService.filterSubscription) {
      this.userFiltersService.getUserFilters();
    }


  }

  openForm(event: MouseEvent, filter?: UserFilter): void {
    if (this.userFiltersService.loading) { return; }
    this.itemFilterService.searchesList = false;
    event.stopPropagation();
    this.userFilterId = filter ? filter.id : null;
    this.userFiltersService.selectedUserFilter = filter;
    this.isFormIsOpen = true;
  }

  closeForm(): void {
    this.userFiltersService.selectedUserFilter = undefined;
    this.isFormIsOpen = false;
    this.userFilterId = null;
  }

  openFaq(): void {
    this.isFaqIsOpen = true;
  }

  closeFaq(): void {
    this.isFaqIsOpen = false;
  }

  showDelete(index: number) {
    event.stopPropagation();
    this.showDelSavedSearch = index;
  }

  removeFilter(id: string, event: Event) {
    event.stopPropagation();
    this.userFiltersService.deleteUserFilter(id);
    this.showDelSavedSearch = null;
  }

  onTooltipClick(event: Event): void {
    event.stopPropagation();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
