import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as models from '../../../buyer/interfaces/model';
import { IPriceListMetadata } from '../../interfaces/IPriceListMetadata';

@Injectable({
  providedIn: 'root'
 })
export class TopCategoriesFileSaverService {
  restapiverb: string;
  constructor(private http: HttpClient) {}

  downloadPriceList(priceList?:string): Observable<Blob> {
    this.restapiverb = (priceList) ? `globalitems/${priceList}` : 'items/pricelist';
    return this.http.get<Blob>(environment.buyerPortalBaseUrl + this.restapiverb, {responseType: 'blob' as 'json'});
  }
  downloadItemList(  itemsRequest: models.IRequest): Observable<HttpResponse<Blob>> {
    this.restapiverb = 'items/exportcatalog';
    return this.http.post<Blob>(environment.buyerPortalBaseUrl
      + this.restapiverb, itemsRequest, {observe: 'response', responseType: 'blob' as 'json'});
  }

  getPriceListingMetadata(urlId: string): Observable<IPriceListMetadata> {
    this.restapiverb = `globalItems/priceList/metadata/${urlId}`;
    return this.http.get<IPriceListMetadata>(environment.buyerPortalBaseUrl + this.restapiverb);
  }
}

