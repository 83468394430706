export interface ILoaderLevel {
    show: boolean;
    level?: LoaderLevelType | number;
    hide?: boolean;
}

/**
 * top = content + left
 */
export enum LoaderLevelType {
    content = 200,
    top = 9999,
}
