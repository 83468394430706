<div *ngIf="po" class="container-fluid po-details-container">
  <div class="po-header-container">
    <div class="po-header-title">
      <div class="po-header-title__icon" (click)="goBackToPOList()">
        <img src="../../../../assets/images/icons/arrow-left-gray.svg" alt="">
      </div>
      <div class="po-header-title__label">
        <span>Purchase Order  {{po.docNumber}}</span>
      </div>
    </div>

    <!-- read only mode -->
    <div class="po-header-tracking" *ngIf="!editTrackingEnabled">
      <div class="po-header-tracking-col">
        <span class="po-header-tracking-col__label">Vendor Invoice</span>
        <div class="po-header-tracking-col__control">
          <span>{{po.vendorInvoiceNo}}</span>
        </div>
      </div>
      <div class="po-header-tracking-col">
        <span class="po-header-tracking-col__label">Shipment Date</span>
        <div class="po-header-tracking-col__control">
          <span>{{po.shippingInformation.shippingDate | date:'MM.dd.yyyy'}}</span>
        </div>
      </div>
      <div class="po-header-tracking-col">
        <span class="po-header-tracking-col__label">Carrier Name</span>
        <div class="po-header-tracking-col__control">
          <span>{{po.shippingInformation.carrier}}</span>
        </div>
      </div>
      <div class="po-header-tracking-col">
        <span class="po-header-tracking-col__label">Shipment Type</span>
        <div class="po-header-tracking-col__control">
          <span>{{po.shippingInformation.transportationMode}}</span>
        </div>
      </div>
      <div class="po-header-tracking-col">
        <span class="po-header-tracking-col__label">BOL #</span>
        <div class="po-header-tracking-col__control">
          <span>{{po.shippingInformation.billOfLadingNumber}}</span>
        </div>
      </div>
      <div class="po-header-tracking-col">
        <span class="po-header-tracking-col__label">Shipping ETA</span>
        <div class="po-header-tracking-col__control">
          <span>{{po.shippingInformation.eta | date:'shortDate'}}</span>
        </div>
      </div>
      <div class="po-header-tracking-col">
        <span class="po-header-tracking-col__label">Tracking Number</span>
        <div class="po-header-tracking-col__control">
          <span>{{po.shippingInformation.trackingNumber}}</span>
        </div>
      </div>
      <div class="po-header-tracking-col _has-icon-btn">
        <div class="po-header-tracking-col__control __icon">
          <img src="../../../../assets/images/icons/24-email-white.svg">

          <div class="tooltip">
            <div class="title">Note</div>
            <div class="content">
              {{po.shippingInformation.comments}}
            </div>
          </div>
        </div>
      </div>

      <div class="po-header-tracking-btns">
        <a class="btn __save" (click)="editTracking()">Edit</a>
      </div>

    </div>

    <!-- form mode -->
    <form class="po-header-tracking __editMode" [formGroup]="form" *ngIf="editTrackingEnabled">
      <div class="po-header-tracking-col">
        <span class="po-header-tracking-col__label">Vendor Invoice</span>
        <div class="po-header-tracking-col__control" [class.required]="formErrors.invoice.required || formErrors.invoice.maxlength">
          <input type="text" formControlName="invoice" autocomplete="off" />
        </div>
      </div>
      <div class="po-header-tracking-col">
        <span class="po-header-tracking-col__label">Shipment Date *</span>
        <div class="po-header-tracking-col__control" [class.required]="formErrors.shipmentDate.required || formErrors.shipmentDate.validDate === false  || formErrors.shipmentDate.emptySpaces === false">
          <input type="text" placeholder="mm/dd/yyyy" autocomplete="off"
          [bsConfig]="{containerClass: 'theme-calendar', showWeekNumbers: false}" bsDatepicker formControlName="shipmentDate"/>
        </div>
      </div>
      <div class="po-header-tracking-col">
        <span class="po-header-tracking-col__label">Carrier Name *</span>
        <div class="po-header-tracking-col__control" [class.required]="formErrors.carrierName.required || formErrors.carrierName.emptySpaces === false || formErrors.carrierName.maxlength">
          <input type="text"formControlName="carrierName"autocomplete="off" />
        </div>
      </div>
      <div class="po-header-tracking-col">
        <span class="po-header-tracking-col__label">Shipment Type</span>
        <div class="po-header-tracking-col__control" [class.required]="formErrors.shipmentType.required || formErrors.shipmentType.maxlength">
          <input type="text" formControlName="shipmentType" autocomplete="off" />
        </div>
      </div>
      <div class="po-header-tracking-col">
        <span class="po-header-tracking-col__label">BOL #</span>
        <div class="po-header-tracking-col__control" [class.required]="formErrors.bol.required || formErrors.bol.maxlength">
          <input type="text" formControlName="bol" autocomplete="off"/>
        </div>
      </div>
      <div class="po-header-tracking-col">
        <span class="po-header-tracking-col__label">Shipping ETA *</span>
        <div class="po-header-tracking-col__control"  [class.required]="formErrors.etaDate.required || formErrors.etaDate.validDate === false || formErrors.etaDate.emptySpaces === false">
          <input type="text" formControlName="etaDate" placeholder="mm/dd/yyyy"
              [bsConfig]="{containerClass: 'theme-calendar', showWeekNumbers: false}" bsDatepicker autocomplete="off" />
        </div>
      </div>
      <div class="po-header-tracking-col">
        <span class="po-header-tracking-col__label">Tracking Number *</span>
        <div class="po-header-tracking-col__control" [class.required]="formErrors.trackingNbr.required || formErrors.trackingNbr.emptySpaces === false || formErrors.trackingNbr.maxlength">
          <input type="text" formControlName="trackingNbr" autocomplete="off" />
        </div>
      </div>
      <div class="po-header-tracking-col _has-icon-btn">
        <div class="po-header-tracking-col__control __icon">
          <img src="../../../../assets/images/icons/24-email-white.svg">

          <div class="tooltip">
            <div class="container-fluid tt-top">
              <div class="row">
                <div class="col text-left _normal">Note</div>
                <div class="col text-right tt-note-alert">All Changes Saved</div>
              </div>
            </div>
            <div class="content">
              <textarea rows="2" class="form-control" formControlName="notes" [class.required]="formErrors.notes.required || formErrors.notes.maxlength"></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="po-header-tracking-btns">
        <a class="btn __cancel" (click)="cancelTracking()">Cancel</a>
        <a class="btn __save" (click)="saveTracking()" [class.disabled]="!form.valid">
          Save
        </a>

      </div>

    </form>
  </div>

  <div class="full-order-global" >
    <div class="col-lg-12 invoice">
      <div class="back">
        <div class="clearfix"></div>
        <div class="container">
          <div class="logo-wrap">
            <div class="logo">
              <img src="assets/images/logo-black.svg" height="42" width="157" />
            </div>
          </div>
          <div class="top-right">
            <div class="top-right-container">
              <div class="labels">
                <ul>
                  <li>
                    <b>Purchase Order No.:</b>
                  </li>
                  <li>
                    <b>Date:</b>
                  </li>
                  <li>
                    <b>Due Date:</b>
                  </li>
                  <li>
                    <b>Seller No.:</b>
                  </li>
                </ul>
              </div>
              <div class="info">
                <ul>
                  <li>{{po.docNumber}}</li>
                  <li>{{po.orderDate | date:'shortDate'}}</li>
                  <li>{{po.DueDate ? (po.DueDate | date:'shortDate') : ' - '}}</li>
                  <li>{{po.cardCode}}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="billing-shipping">
            <div class="billing">
              <ul>
                <li class="label">PAY TO</li>
                <li>{{po.billingAddressCode}}</li>
                <li *ngFor="let billingAddr of billingAddressList">
                  {{billingAddr}}
                </li>
                <li></li>
              </ul>
            </div>
            <div class="shipping">
              <ul>
                <li class="label">SHIP TO</li>
                <li>{{po.shippingAddressCode}}</li>
                <li *ngFor="let shippingAddr of shippingAddressList">
                  {{shippingAddr}}
                </li>
                <li></li>
              </ul>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="empty">
            <ul>
            </ul>
            <div class="clearfix"></div>
          </div>
          <div class="clearfix"></div>
          <div class="b-s-info">
            <ul>
              <li>
                <b>Buyer Employee:</b> {{po.salesEmployee}}</li>
              <li>
                <b>Contact Name:</b> {{po.contactName}}</li>
              <li>
                <b>Contact Email:</b> {{po.contactEmail}}</li>
            </ul>
          </div>
          <div class="clearfix"></div>
          <div class="item-info">
            <table>
              <thead>
                <tr>
                  <th class="text-left">Item No.</th>
                  <th class="text-left">Description</th>
                  <th class="text-right">Unit Price</th>
                  <th class="text-right">Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let poLine of po.orderDetails">
                  <td>{{poLine.itemCode}}</td>
                  <td>
                    {{poLine.itemName}}
                  </td>
                  <td>{{ poLine.unitPrice | currency:'USD':'symbol-narrow' }}</td>
                  <td>{{ poLine.quantity }}</td>
                  <td>{{ poLine.unitPrice * poLine.quantity | currency:'USD':'symbol-narrow' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div class="total">
              <div class="total-wrap">
                <div class="total-container">
                  <ul>
                    <li>Subtotal</li>
                    <li>Discount</li>
                    <li>Shipping</li>
                    <li>Tax</li>
                    <li>
                      <b>Total Order Value</b>
                    </li>
                  </ul>
                </div>
                <div class="price-block">
                  <ul>
                    <li>{{ po.subtotal | currency:'USD':'symbol-narrow' }}</li>
                    <li>{{ po.orderDiscountAmount | currency:'USD':'symbol-narrow' }}</li>
                    <li>{{ po.shipping | currency:'USD':'symbol-narrow' }}</li>
                    <li>{{ po.tax | currency:'USD':'symbol-narrow' }}</li>
                    <li>{{ po.orderTotal | currency:'USD':'symbol-narrow' }}</li>
                  </ul>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="terms">
            <span>
              <b>TERMS &amp; CONDITIONS</b>
            </span>
            <br>
            <ul>
              <li>Must ship within 24 hours, or be subjected to fees</li>
              <li>Cancellation of PO or reduction of quantity of unit(s) marketplace vendor will be charged 10% per
                PO/unit as a cancellation fee</li>
              <li>No substitutions </li>
              <li>Authentic manufacture product only</li>
              <li>PO# must appear on packaging and pallets per the shipping guide</li>
              <li>Must ship part number provided at the time of sale or will be returned and charged a 15% processing
                fee</li>
              <li>PO subject to all terms and conditions per the HUBX Shipment & Fulfillment Guide </li>
            </ul>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</div>
