import { UserService } from '../user/user.service';
import { HubxAuthService } from './hubx-auth.service';
import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { TopService } from '../shared/services/top.service';
import { Observable, Subscription, of, timer } from 'rxjs';
import { flatMap, mergeMap } from 'rxjs/operators';

import * as JWT from 'jwt-decode';

import { AUTH_CONFIG } from './hubx-auth-variables';
import { CookieService } from 'ngx-cookie-service';
import { IAuthResult } from '../shared/interfaces/IAuthResult';
import { environment } from '../../environments/environment';
import { PubSubService } from '../core/pubsub.service';
import { SharedSource } from '../core/shared-source';
import { IOffersSummary } from '../buyer/interfaces/IOffersSummary';
import { OfferService } from '../user/offers/offer.service';
import * as uuid from 'uuid';
import { UserProfile } from '../shared/interfaces/UserProfile';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService {
  private _completedLoad = true;
  refreshSubscription: Subscription;
  roleChanged = new EventEmitter<string>();
  userRole = '';
  redirectUrl = '';

  guest_full_name = '';
  guest_email = '';
  guest_mobile = '';
  authConfig = this.hubxAuth.WebAuth({
    domain: AUTH_CONFIG.domain,
    adminDomain: AUTH_CONFIG.adminDomain,
    clientID: AUTH_CONFIG.clientID,
    redirectUri: AUTH_CONFIG.callbackURL,
    responseType: 'token id_token',
    scope: AUTH_CONFIG.scope
  });

  set completedLoad(val) {
    this._completedLoad = val;
  }

  get completedLoad() {
    return this._completedLoad;
  }

  constructor(private router: Router,
    private topService: TopService,
    public hubxAuth: HubxAuthService,
    private cookieService: CookieService,
    private pubSubService: PubSubService,
    private offerService: OfferService,
    private http: HttpClient,
  ) {
  }

  public login(): void {
    this.authConfig.authorize();
  }

  public handleAuthentication(): void {
    this.topService.loading = true;
    this.authConfig.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.topService.loading = false;
        this.setSession(authResult);
        window.location.reload();

      } else if (err) {
        this.router.navigate(['']);
        this.topService.loading = false;
        // alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  public setSession(authResult: IAuthResult): void {
    const access_token_decoded = JWT(authResult.accessToken);
    const app_metadata = JSON.parse(access_token_decoded['https://www.hubx.com/app_metadata']);
    const user_info = JSON.parse(access_token_decoded['https://www.hubx.com/user_info']);
    const expiresAt = JSON.parse(access_token_decoded['exp']) * 1000;//Convert seconds to milliseconds

    this.userRole = app_metadata.Role;
    sessionStorage.setItem('access_token', authResult.accessToken);
    sessionStorage.setItem('id_token', authResult.idToken);
    sessionStorage.setItem('expires_at', JSON.stringify(expiresAt));
    sessionStorage.setItem('user_id', app_metadata.UserId);
    sessionStorage.setItem('partner_id', app_metadata.PartnerId);
    sessionStorage.setItem('user_email', user_info.email);
    sessionStorage.setItem('user_role', this.userRole);
    sessionStorage.setItem('show_phone_popup', '1');

    if (!sessionStorage.getItem('sessionId')) {
      sessionStorage.setItem('sessionId', uuid.v4()); // user can have several open sessions and sessionId will be useful to handle user notifications
    }
    // this.guest_full_name = access_token_decoded['https://www.hubx.com/app_metadata']['full_name'];
    // this.guest_email = access_token_decoded['https://www.hubx.com/app_metadata']['email'];
    // this.guest_mobile = access_token_decoded['https://www.hubx.com/app_metadata']['mobile'];

    this.roleChanged.emit(this.userRole);

    if (this.userRole !== 'GUEST') {
      this.setCookies(authResult.accessToken, expiresAt);
      this.getLoggedInUserProfile().subscribe(data => {
        sessionStorage.setItem('show_phone_popup', '1');
        this.pubSubService.sharedSubject.next({
          name: SharedSource.userProfileLoaded,
          data: data
        });
      });
    }

    this.getInitialOfferData();

    if (app_metadata.PartnerId) {
      this.pubSubService.sharedSubject.next({ name: SharedSource.partnerIdReady, data: true });
    }

  }

  getLoggedInUserProfile(): Observable<UserProfile> {
    const url = environment.adminUrl + 'users';
    return this.http.get<UserProfile>(url);
  }

  public logout(isVendor: boolean = false): void {
    this.clearSession();
    this.deleteCookies();
    if (!isVendor) {
      this.router.navigate(['/guest/home']);
    } else {
      this.router.navigate(['/vendor-login']);
    }
  }

  private clearSession(): void {
    sessionStorage.clear();
    localStorage.clear();
    this.topService.searchString = null;
    this.userRole = '';
    this.roleChanged.emit(this.userRole);
    this.router.navigate(['login']);
  }

  public clearSessionIfNotVendor(): void {
    this.deleteCookies();
    sessionStorage.clear();
    localStorage.clear();
    this.topService.searchString = null;
    this.userRole = '';
    this.roleChanged.emit(this.userRole);
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    if (sessionStorage.getItem('access_token')) {
      const expiresAt = JSON.parse(sessionStorage.getItem('expires_at'));
      return new Date().getTime() < expiresAt;
    }
    return false;
  }

  public getAccessToken(): string {
    return sessionStorage.getItem('access_token');
  }

  public scheduleRenewal(): void {
    if (!this.isAuthenticated()) {
      return;
    }

    this.unscheduleRenewal();
    const expiresAt = JSON.parse(sessionStorage.getItem('expires_at'));
    const source = of(expiresAt).pipe(
      mergeMap(
        // tslint:disable-next-line:no-shadowed-variable
        expiresAt => {
          const now = Date.now();
          return timer(Math.max(1, expiresAt - now));
        }
      )
    );

    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.refreshSubscription = source.subscribe(() => {
      this.scheduleRenewal();
    });
  }

  public unscheduleRenewal(): void {
    // tslint:disable-next-line:curly
    if (!this.refreshSubscription) return;
    this.refreshSubscription.unsubscribe();
  }

  public resetPassword(userEmail: string, connection: string): boolean {
    this.authConfig.changePassword({
      connection: connection,
      email: userEmail
    },
      function (err, res) {
        return false;
      });
    return true;
  }

  get isGuest() {
    // Duplicated from User Service
    // Needed for Auth Interceptor(HUBX-4370)
    return !sessionStorage.getItem('user_role') ||
      ['GUEST', 'null'].includes(sessionStorage.getItem('user_role'));
  }

  public setCookies(accessToken: string, expiresAt: number) {
    const expirationDate = new Date(expiresAt);
    const domain = environment.production ? '.hubx.com' : location.hostname;
    const env = environment.name;

    if (!this.cookieService.check(env + '_access_token')) {
      this.cookieService.set(env + '_access_token', accessToken, expirationDate, '/', domain, true);
      this.cookieService.set(
        env + '_expires_at', JSON.stringify(expiresAt), expirationDate, '/', domain, true);
    }
  }

  verifyCookies(callbackObj): boolean {
    const env = environment.name;
    const cookieExists: boolean = this.cookieService.check(env + '_access_token');

    if (cookieExists && !sessionStorage.getItem('access_token')) {
      const authResult: IAuthResult = {
        accessToken: this.cookieService.get(env + '_access_token'),
        expiresIn: +this.cookieService.get(env + '_expires_at'),
        tokenType: 'Bearer',
        idToken: null
      };
      callbackObj.fn(authResult, callbackObj.scope);
      return true;
    }
    return false;
  }

  deleteCookies() {
    const env = environment.name;
    const domain = environment.production ? '.hubx.com' : location.hostname;

    this.cookieService.delete(env + '_access_token', '/', domain);
    this.cookieService.delete(env + '_expires_at', '/', domain);
  }

  getInitialOfferData() {
    this.userRole = sessionStorage.getItem('user_role');

    // BUYER
    if (['SUPER', 'SALES'].includes(this.userRole) && sessionStorage.getItem('isCustomerSelected') === 'YES') {
      return;
    }
    if (['SUPER', 'SALES'].includes(this.userRole) && !sessionStorage.getItem('isCustomerSelected')) {
      this.offerService.getUnseenOffersCounterNoStream();
      return;
    }
    if (this.userRole === 'BUYER') {
      this.offerService.getOffersSummaryNoStream();
      return;
    }

    // VENDOR
    if (this.userRole === 'SELLER') {
      this.offerService.getOffersSummaryNoStream();
      this.offerService.getBpUnseenOffersCounterNoStream();
    }

    if (['ADMIN', 'VENDORMGR'].includes(this.userRole)) {
      const partnerId = sessionStorage.getItem('partner_id');
      if (partnerId) {
        this.offerService.getOffersSummaryNoStream();
        this.offerService.getBpUnseenOffersCounterNoStream();
      }
    }
  }
}
