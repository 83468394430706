import { Component, OnInit } from '@angular/core';
import { TopService } from '../../../../shared/services/top.service';

@Component({
  selector: 'app-nothing-found-purchases',
  templateUrl: './nothing-found-purchases.component.html',
  styleUrls: ['./nothing-found-purchases.component.scss']
})
export class NothingFoundPurchasesComponent implements OnInit {

  constructor(public topService: TopService) { }

  ngOnInit() {
  }

}
