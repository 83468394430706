<div class="scrollable-wrap" [class.mobile]="topService.isMobile">
  <div class="main-wrap">
    <div class="decorate-header no-before">
      <div class="display-flex inner-header">
        <h1>Customer Application</h1>
      </div>
    </div>
    <div class="contact-page-section">
      <div class="wrapper">
        <div class="contact-form-col">
          <form id="vendor-form" #f="ngForm">
            <div class="controls-row">
              <div class="control-item desktop-half">
                <div class="control-label-top">First Name</div>
                <div class="control-textbox" [ngClass]="{'error': firstname.touched && !firstname.valid}">
                  <input type="text" name="firstname" autocomplete="off" [(ngModel)]="firstName" pattern="[a-zA-Z ]*"
                    required #firstname="ngModel" [disabled]="sessionService.isLoggedIn === 'true'" />
                </div>
              </div>
              <div class="control-item desktop-half">
                <div class="control-label-top">Last Name</div>
                <div class="control-textbox" [ngClass]="{'error': lastname.touched && !lastname.valid}">
                  <input type="text" name="lastname" autocomplete="off" [(ngModel)]="lastName" pattern="[a-zA-Z ]*"
                    required #lastname="ngModel" [disabled]="sessionService.isLoggedIn === 'true'" />
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item desktop-half">
                <div class="control-label-top">Company Name</div>
                <div class="control-textbox" [ngClass]="{'error': companyname.touched && !companyname.valid}">
                  <input type="text" name="companyname" [(ngModel)]="userInfo.companyName" #companyname="ngModel" required
                  [disabled]="sessionService.isLoggedIn === 'true'" />
                </div>
              </div>
              <div class="control-item desktop-half">
                <div class="control-label-top">Company Website</div>
                <div class="control-textbox" [ngClass]="{'error': companywebsite.touched && !companywebsite.valid}">
                  <input type="text" name="companywebsite" [(ngModel)]="userInfo.companyWeb" #companywebsite="ngModel"
                    required [disabled]="sessionService.isLoggedIn === 'true'" />
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item desktop-half">
                <div class="control-label-top">Email</div>
                <div class="control-textbox" [ngClass]="{'error': email.touched && !email.valid}">
                  <input type="email" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" [(ngModel)]="userInfo.email" #email="ngModel" required
                  [disabled]="sessionService.isLoggedIn === 'true'" />
                  <label class="error" for="Email"
                    *ngIf="!email.valid && email.touched">Invalid Email</label>
                </div>
              </div>
              <div class="control-item desktop-half">
                <div class="control-label-top">Country</div>
                <div class="control-select dropdown-contact-us" [ngClass]="{'error': country.touched && !country.valid}">
                  <select name="country" [(ngModel)]="userInfo.country" #country="ngModel" [disabled]="sessionService.isLoggedIn === 'true'">
                    <option value="" selected>-Select-</option>
                    <option value="United States of America (USA)">United States of America (USA)</option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brazil">Brazil</option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cabo Verde">Cabo Verde</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Central African Republic (CAR)">Central African Republic (CAR)</option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
                    <option value="Republic of the Congo">Republic of the Congo</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote d'Ivoire">Cote d'Ivoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">Dominican Republic</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Greece">Greece</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Kosovo">Kosovo</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macedonia (FYROM)">Macedonia (FYROM)</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia">Micronesia</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar (Burma)">Myanmar (Burma)</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="North Korea">North Korea</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Palestine">Palestine</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Korea">South Korea</option>
                    <option value="South Sudan">South Sudan</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Timor-Leste">Timor-Leste</option>
                    <option value="Togo">Togo</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates (UAE)">United Arab Emirates (UAE)</option>
                    <option value="United Kingdom (UK)">United Kingdom (UK)</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican City (Holy See)">Vatican City (Holy See)</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item">
                <div class="control-label-top">Address</div>
                <div class="control-textbox" [ngClass]="{'error': address.touched && !address.valid}">
                  <input type="text" name="address" [(ngModel)]="address1" #address="ngModel" required />
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item">
                <div class="control-label-top">Address 2</div>
                <div class="control-textbox" >
                  <input type="text" name="addresstwo" [(ngModel)]="address2" #addresstwo="ngModel"  />
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item desktop-half">
                <div class="control-label-top">City</div>
                <div class="control-textbox" [ngClass]="{'error': city.touched && !city.valid}">
                  <input type="text" name="city" [(ngModel)]="userInfo.city" #city="ngModel" required />
                </div>
              </div>
              <div class="control-item desktop-half">
                <div class="controls-row">
                    <div class="control-item tablet-45">
                        <div class="control-label-top">State/Province</div>
                        <div class="control-textbox" >
                          <input type="text" name="state" [(ngModel)]="userInfo.state" #state="ngModel" required />
                        </div>
                      </div>
                  <div class="control-item tablet-55">
                    <div class="control-label-top">ZIP</div>
                    <div class="control-textbox" [ngClass]="{'error': zip.touched && !zip.valid}">
                      <input type="text" name="zip" [(ngModel)]="userInfo.zip" #zip="ngModel" required />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item desktop-half">
                <div class="control-label-top">Phone Number</div>
                <div class="control-textbox" [ngClass]="{'error': phone.touched && !phone.valid}">
                  <input name="phone" minlength="5" [(ngModel)]="userInfo.phoneNumber" type="number" #phone="ngModel"
                    required [disabled]="sessionService.isLoggedIn === 'true'" />
                    <label class="error" for="phone"
                      *ngIf="phone.touched && !phone.valid">Only Digits</label>
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item desktop-half">
                <div class="control-label-top">Total Annual Sales</div>
                <div class="control-select dropdown-contact-us" [ngClass]="{'error': totalSales.touched && !totalSales.valid}">
                  <select name="totalSales" [(ngModel)]="userInfo.companyRevenue" #totalSales="ngModel" required>
                    <option value="" selected>-Select-</option>
                    <option value="0 - $999,999">0 - $999,999</option>
                    <option value="$1,000,000-$4,999,999">$1,000,000-$4,999,999</option>
                    <option value="$5,000,000-$9,999,9999">$5,000,000-$9,999,999</option>
                    <option value="$10,000,000-$19,999,999">$10,000,000-$19,999,999</option>
                    <option value="$20,000,000-$49,999,999">$20,000,000-$49,999,999</option>
                    <option value="$50,000,000-$99,999,999">$50,000,000-$99,999,999</option>
                    <option value="$100,000,000+">$100,000,000+</option>
                  </select>
                </div>
              </div>
              <div class="control-item desktop-half">
                <div class="control-label-top">Annual Computer Sales</div>
                <div class="control-select dropdown-contact-us" [ngClass]="{'error': computerSales.touched && !computerSales.valid}">
                  <select name="computerSales" [(ngModel)]="userInfo.computerSales" #computerSales="ngModel" required>
                    <option value="" selected>-Select-</option>
                    <option value="0 - $999,999">0 - $999,999</option>
                    <option value="$1,000,000-$4,999,999">$1,000,000-$4,999,999</option>
                    <option value="$5,000,000-$9,999,999">$5,000,000-$9,999,999</option>
                    <option value="$10,000,000-$19,999,999">$10,000,000-$19,999,999</option>
                    <option value="$20,000,000-$49,999,999">$20,000,000-$49,999,999</option>
                    <option value="$50,000,000-$99,999,999">$50,000,000-$99,999,999</option>
                    <option value="$100,000,000+">$100,000,000+</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item desktop-half">
                <div class="control-label-top">Type of Company</div>
                <div class="control-select dropdown-contact-us" [ngClass]="{'error': contactType.touched && !contactType.valid}">
                  <select name="contactType" [(ngModel)]="userInfo.contactType" #contactType="ngModel" required>
                    <option value="" selected>-Select-</option>
                    <option value="Retailer/Reseller">Retailer/Reseller</option>
                    <option value="Manufacturer">Manufacturer</option>
                    <option value="Distributor">Distributor</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div class="control-item desktop-half">
                <div class="control-label-top">Do You Export?</div>
                <div class="display-flex" style="padding-top: 14px;">
                  <div style="width: 50%;">
                    <input type="radio" value="Yes" id="yes" name="Radio" [(ngModel)]="isExportCompany" checked>
                    <label class="control-label-top" for="yes">Yes</label>
                  </div>
                  <div>
                    <input type="radio" id="no" value="No" name="Radio" [(ngModel)]="isExportCompany">
                    <label class="control-label-top" for="no">No</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item">
                <div class="control-label-top">
                  Tell us a little more about your company
                  <span class="control-label-optional">optional</span>
                </div>
                <div class="control-textarea height-80">
                  <textarea name="aboutYourCompany" class="textarea-contact-us" [(ngModel)]="userInfo.aboutYourCompany"
                    #aboutYourCompany="ngModel"></textarea>
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item">
                  <re-captcha siteKey="{{recaptchaKey}}" (resolved)="resolved($event)"></re-captcha>
              </div>
          </div>
            <button class="form-submin-btn" type="submit" [disabled]="!f.valid || !isResolved" (click)="onSubmit(f,2,$event)">
                <div class="static-apps-loader" *ngIf="loading"></div>
              Send</button>
          </form>
          <div class="application-disclaimer">
            <p style="text-align: justify">HUBX will treat as confidential, and will not disclose to any third
              party, customer's non-public financial information as provided on or in support of this
              Customer Application. Customer will provide HUBX with sales and marketing information relating
              to customer's business, including information that is requested herein.</p>
          </div>
        </div>
        <div class="contact-schedule-col">
          <strong>Customer Support Hours of Operation</strong>
          <p>Mon-Fri: 8:00am - 5:30pm (Eastern)<br />Have you seen our FAQ section?<br />It contains answers to common
            questions about HUBX.</p>
          <a (click)="navigateTo('faq')" class="ask-question-link dark">FAQ</a>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<app-error-modal [bodyText]="errorModal.bodyText" [title]="errorModal.title" [isError]="errorModal.isShown" (onConfirmError)="hideError()"></app-error-modal>
