import * as models from './model';

export enum AttrDataType {
    tag = 0,
    range = 1,
    multiple = 2
}
export enum AttrType {
    manufacturer = 0,
    item = 1,
    vendorItem = 2
}
export interface Attribute {
    id?: string;
    name?: string;
    count?: number;
    attrType?: AttrType;
    attrDataType?: AttrDataType;
    attributeValues?: models.AttributeValue[];
    logoUrl?: string;
    selected?: boolean;
    heroImageUrl?: string;
    blackLogoUrl?: string;
}

