<footer class="footer" [class.mobile]="topService.isMobile">
  <div class="footer-wrapper display-flex">
      <a href="index.html">
          <img src="../../../assets/corp-site/img/logo-footer.svg" width="121" height="32" alt="HUBX" class="logo" />
      </a>
      <ul class="nav display-flex footer-menu">
          <!-- <li><a href="contact.html">Contact</a></li> -->
          <li><a (click)="navigateTo('contact-support')">Contact</a></li>
          <li><a (click)="navigateTo('faq')">FAQ</a></li>
          <li><a (click)="navigateTo('terms-of-use')">Terms of Use</a></li>
          <li><a (click)="navigateTo('private-policy')">Privacy Policy</a></li>
      </ul>
      <p class="copyright">{{currentYear}} © HUBX </p>
  </div>
</footer>
