import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import * as models from '../../../interfaces/model';
import { CartService } from '../../cart.service';

@Component({
  selector: 'app-add-to-existing-mobile',
  templateUrl: './add-to-existing-mobile.component.html',
  styleUrls: ['./add-to-existing-mobile.component.scss']
})
export class AddToExistingMobileComponent implements OnInit {
  @Input() selectedOpenOrder: models.IOrder;
  @Input() openOrdersDetails: boolean;
  @Input() showModal: boolean;
  @Input() isMobile: boolean;
  @Input() loading: boolean;
  @Input() loading2: boolean;
  @Input() openOrders: models.IOrder[] = [];
  @Output() closeOrderModalEmitter = new EventEmitter();
  @Output() selectOrderEmitter = new EventEmitter();
  @Output() placeExistingEmitter = new EventEmitter();
  isShownOrderDetails: boolean = false;

  constructor(private cartService: CartService) { }

  ngOnInit() {
    document.documentElement.classList.add('is-modal');
  }

  openOrderDetail(order: models.IOrder): void {
    this.selectOrderEmitter.emit(order);
    this.isShownOrderDetails = true;

  }

  closeModal(): void {
    if (!this.loading2) {
      this.closeOrderModalEmitter.emit();
      this.isShownOrderDetails = false;
    }

  }

  hideOrderDetail(): void {
    if (!this.loading2) {
      this.isShownOrderDetails = false;
    }
  }

  onSelectOrder(order: models.IOrder): void {
    this.selectOrderEmitter.emit(order);
  }

  onPlaceExisting(docEntry: Number): void {
    if (!this.loading2) {
      this.placeExistingEmitter.emit(docEntry);
    }
  }

  ngOnDestroy() {
    document.documentElement.classList.remove('is-modal');
  }

}
