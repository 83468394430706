<div class="cancellation-modal">
  <div class="cancellation-modal-dialog animated" [class.zoomIn]="animateIn" [class.zoomOut]="animateOut">
    <div class="cancellation-modal-content">
      <div class="cancellation-modal-header">
        <span class="cancellation-modal-header__close" (click)="close()">
          <img src="assets/images/close.svg" width="24" height="24" alt="close">
        </span>
        <div class="cancellation-modal-header-content">
          <div class="cancellation-modal-header-content__image">
            <img src="assets/images/icons/offers/36-alert.svg" width="36" height="36" alt="alert">
          </div>
          <div class="cancellation-modal-header-content__text">
            <h3>Offer Cancellation Warning</h3>
            <p>Pending offers exist for some items. Changes to inventory or pricing <br> will cancel these offers.
              Please review them before submitting.</p>
          </div>
        </div>
        <div class="cancellation-modal-header-refresh" *ngIf="showRefreshMsg" (click)="refresh()">
          <span>Offer(s) changed</span> <a role="button">Refresh</a>
        </div>
      </div>
      <form [formGroup]="pendingOffersForm" (ngSubmit)="update()">

        <div class="cancellation-modal-body" formArrayName="offers">

          <div class="offer" *ngFor="let itemGroup of pendingOffersForm.get('offers')?.controls; let i = index"
            [formGroupName]="i" [class.has-error]="itemGroup.controls.selectedOption.invalid && pendingOffers[i]?.hasPendingOffers">

            <div class="offer-item">

              <div class="offer-item__logo">
                <div *ngIf="!pendingOffers[i].manufacturerLogoUrl">
                  <img src="http://cdn.hubx.com/assets/images/item-placeholder.svg">
                </div>
                <div *ngIf="pendingOffers[i].manufacturerLogoUrl">
                  <img src="{{'https://cdn.hubx.com' + pendingOffers[i].manufacturerLogoUrl}}">
                </div>
              </div>

              <div class="offer-item__right">
                <div class="offer-item__line">
                  <div class="offer-item-code">
                    <span>{{ pendingOffers[i].partNumber }}</span>
                  </div>
                  <div class="offer-item-controls">

                    <div class="offer-item-controls__radio">
                      <label class="radio-btn">
                        <input type="radio" formControlName="selectedOption" [value]="offersAction.CANCEL">
                        <span class="radio-btn__label">Cancel Offer(s)</span>
                        <span class="radio-btn-tooltip">
                          <span class="radio-btn-tooltip__text">
                            Canceling this offer updates SKU with your changes, like inventory and cost
                          </span>
                        </span>
                      </label>
                      <label class="radio-btn">
                        <input type="radio" formControlName="selectedOption" [value]="offersAction.KEEP">
                        <span class="radio-btn__label">Keep Offer(s)</span>
                        <span class="radio-btn-tooltip">
                          <span class="radio-btn-tooltip__text">
                            Keeping this offer leaves SKU unchanged, including inventory and cost
                          </span>
                        </span>
                      </label>
                    </div>

                    <div *ngIf="false" class="offer-item-controls__updated">
                      <span>SKU updated</span>
                      <img src="assets/images/icons/offers/24-sku-updated.svg" width="24" height="24" alt="updated">
                    </div>

                  </div>
                </div>
                <div class="offer-item__line">
                  <div class="offer-item-description">
                    <p>{{ pendingOffers[i].itemDescription }}</p>
                  </div>
                </div>
              </div>

            </div>
            <div class="offer-details">
              <div class="offer-detail" *ngFor="let offer of pendingOffers[i].offers">
                <div class="offer-detail-icon">
                  <img *ngIf="!offerService.loadingOfferCancelationData" src="assets/images/icons/offers/16-offer.svg"
                    width="16" height="16" alt="offer">
                  <img *ngIf="offerService.loadingOfferCancelationData"
                    src="assets/images/icons/offers/spinner-black.gif" width="16" height="16" alt="updating">
                </div>
                <div class="offer-detail-text" [class.__line]="offer?.status !== OfferStatusCancelationWarning.Pending">
                  Offer price {{ offer.itemPrice | CustomOfferCurrency:2}} &#8594; {{ offer.offerPrice | CustomOfferCurrency:2}} &#903; Units {{ offer.units }}
                </div>
                <div class="offer-detail-total">
                  <span *ngIf="offer?.status === OfferStatusCancelationWarning.Pending">Total {{ offer.total |
                    CustomOfferCurrency:2}}</span>
                  <span *ngIf="offer?.status === OfferStatusCancelationWarning.CanceledByBuyer"
                    class="__status">Canceled by buyer</span>
                  <span *ngIf="offer?.status === OfferStatusCancelationWarning.CanceledByVendor"
                    class="__status">Canceled by vendor</span>
                  <span *ngIf="offer?.status === OfferStatusCancelationWarning.CanceledBySystem"
                    class="__status">Canceled by system</span>
                  <span *ngIf="offer?.status === OfferStatusCancelationWarning.OfferExpired" class="__status">Offer
                    expired</span>
                    <span *ngIf="offer?.status === OfferStatusCancelationWarning.AcceptedByBuyer"
                    class="__status">Accepted by buyer</span>
                  <span *ngIf="offer?.status === OfferStatusCancelationWarning.AcceptedByVendor"
                    class="__status">Accepted by vendor</span>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div class="cancellation-modal-footer">
          <div class="cancellation-modal-footer-controls">
            <button type="button" class="_secondary _back" (click)="close()">
              Back
            </button>
            <button type="button" class="_secondary" formtarget="_blank"
              (click)="offerService.showOffersDisabledMessage(true)" 
              [disabled]="disableReviewOffersButton || !totalPendingOffers">
              Review Offers {{totalPendingOffers > 0 ? '(' + totalPendingOffers + ')' : ''}}
            </button>
            <button type="submit" class="_primary"
              [disabled]="pendingOffersForm.invalid || offerService.loadingOfferCancelationData || showRefreshMsg">
              Update
            </button>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>

<!-- Offers cancellation -->
<app-confirmation-popup *ngIf="offerService.offersCancellationPopupSettings.show"
  [settings]="offerService.offersCancellationPopupSettings" [isFromOfferCancellation]="true" (onReject)="update()">
</app-confirmation-popup>