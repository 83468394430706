import { Injectable } from '@angular/core';
import { ITopNav } from '../../buyer/interfaces/ITopNav';
import { INav } from '../../buyer/interfaces/INav';
import { TopFiltersEnum } from '../../buyer/enums/topFiltersEnum';
import { SharedService } from '../shared.service';
import { ActivatedRoute } from '@angular/router';
import { IItem } from '../../buyer/interfaces/model';
import { SharedSource } from '../../core/shared-source';
import { PubSubService } from '../../core/pubsub.service';
import { TopCategoriesFileSaverService } from '../../buyer/catalog/top-categories/top-categories-file-saver.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { environment } from '../../../environments/environment';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IPriceListMetadata } from '../../buyer/interfaces/IPriceListMetadata';
import { ConfirmationPopupSettings } from '../interfaces/IConfirmationPopupSettings';


@Injectable()
export class TopService {
  private _loading = false;
  isDone = true;
  showSearch = true;
  isCatalog = true;
  searchString = '';
  pastPurchasesSearchString = '';
  isCustomerSelected = false;
  topFilters: ITopNav[];
  private _topFiltersOutlet: ITopNav[];
  hasOpenOrders = false;
  xPath = 1;
  openMobileInfo = '';
  isMobile = false;
  showMobileSearch = false;
  gridView = false;
  trendingNavVisible = false;
  hideMenusOnError = false;
  isSearchActive = false;
  isExporting = false;
  date = new Date();
  fileName = 'HUBX-Price-List-' + this.date.toLocaleString() + '.xlsx';
  priceListSubscription: Subscription;
  newVersionAvailable = false;
  // for mobile
  private _showFilterBtn = false;
  currentListingMeta: IPriceListMetadata;
  confirmationPopupSettings: ConfirmationPopupSettings={show: false};

  notificationTooltipHovered = false;

  constructor(public sharedService: SharedService,
    public pubSubService: PubSubService,
    private activatedRoute: ActivatedRoute,
    private topCategoriesFileSaverService: TopCategoriesFileSaverService) { }

  set loading(val) {
    this._loading = val;
    this.sharedService.triggerChangeDetection();
  }

  get loading() {
    return this._loading;
  }

  set showFilterBtn(val) {
    this._showFilterBtn = val;
  }

  get showFilterBtn() {
    return this._showFilterBtn;
  }

  set topFiltersOutlet(outletTop: ITopNav[]) {
    this._topFiltersOutlet = outletTop.filter(o => o.count > 0);
    this.pubSubService.sharedSubject.next({ name: SharedSource.topFiltersOutletChanged, data: this._topFiltersOutlet });
  }

  setTopFiltersOutletWithoutReaction(outletTop: ITopNav[]) {
    this._topFiltersOutlet = outletTop.filter(o => o.count > 0);
  }

  get topFiltersOutlet(): ITopNav[] {
    return this._topFiltersOutlet;
  }

  getTopFilterByName(filter: string) {

    if (this.topFilters) {
      switch (filter) {
        case environment.currentDeal.displayLabel:
        case environment.todaysDealsLabel:
          return this.topFilters.find(f => f.navName === environment.currentDeal.displayLabel) ?? { count: 0, navName: filter };
        case TopFiltersEnum.priceDrop:
          return this.topFilters.find(f => f.navName === TopFiltersEnum.priceDrop) ?? { count: 0, navName: filter };
        case TopFiltersEnum.justLaunched:
          return this.topFilters.find(f => f.navName === TopFiltersEnum.justLaunched) ?? { count: 0, navName: filter };
      }
    } else {
      return { count: 0, navName: '' };
    }
  }

  getURLByTopFilter(topFilter) {
    if (!topFilter) { return; }

    const userUrl = this.sharedService.isBuyerPortal() ? 'buyer' : 'guest';

    switch (topFilter.navName) {
      case environment.currentDeal.displayLabel: return `/${userUrl}/${environment.currentDeal.url}`;
      case 'Price Drop': return `/${userUrl}/price_drop`;
      case 'Just Launched': return `/${userUrl}/just_launched`;
      case 'Just Sold': return `/${userUrl}/just_sold`;
    }
  }

  getTopFilterByUrl(url: string) {
    let splitedUrl: string[] = url?.split('/') ?? [];
    if (splitedUrl && splitedUrl?.length > 0) {
      switch (splitedUrl[splitedUrl.length - 1]) {
        case environment.currentDeal.url: return environment.currentDeal.displayLabel;
        case 'price_drop': return 'Price Drop';
        case 'just_launched': return 'Just Launched';
        case 'just_sold': return 'Just Sold';
      }
    }
    return '';
  }

  getPriceList(priceList?: string) {
    if ((this.loading && !priceList) || this.isExporting) {
      return;
    }
    this.isExporting = true;
    this.priceListSubscription = this.topCategoriesFileSaverService.downloadPriceList(priceList)
      .pipe(
        catchError((err) => {
          this.cancelPriceListDownload();
          return throwError(err);
        })
      )
      .subscribe(blob => {
        this.isExporting = false;
        this.priceListSubscription = undefined;
        importedSaveAs(blob, this.fileName);
        this.currentListingMeta = undefined;
        if (window.location.search) {
          window.history.replaceState({}, '', window.location.pathname);
        }
      });
  }

  isMobileMode() {
    try {
      if (/Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i.test(navigator.userAgent)) {
        document.documentElement.classList.add('mobile');
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    } catch (e) { console.log("Error in isMobile"); }
  }

  // removeMobile() {
  //   this.isMobile = false;
  //   document.documentElement.classList.remove('mobile');
  // }

  cancelPriceListDownload() {
    if (this.priceListSubscription) {
      this.priceListSubscription.unsubscribe();
      this.priceListSubscription = undefined;
    }

    if (window.location.search) {
      window.history.replaceState({}, '', window.location.pathname);
    }
    this.isExporting = false;
    this.currentListingMeta = undefined;
  }

  get isDowloadPriceUrl() {
    return /listings/.test(window.location.search) &&
      /home$/.test(window.location.pathname) && this.currentListingMeta;
  }

  getPriceListMetadata(urlQuery: string) {
    return this.topCategoriesFileSaverService.getPriceListingMetadata(urlQuery)
      .subscribe((priceListMeta) => {
        if (priceListMeta.assets) {
          this.currentListingMeta = priceListMeta;
          this.getPriceList(urlQuery);
        } else {
          this.cancelPriceListDownload();
        }
      });
  }
}

