<div class="row justify-content-md-between align-items-center edit-order-top">
  <div class="col-md-auto top-total__numbers">
    <div class="row justify-content-md-between justify-content-sm-start">
      <div class="col-md-auto col-sm-3 col-5 edit-order-no" [class.disabled]="cartService.isPurchaseInProgress">
        <span class="back-btn" routerLink='/user/orders'><i class="back-btn-icon"></i></span>
        Edit Order #{{ selected_doc_number }}
      </div>
    </div>
  </div>

  <div class="col-md-auto top-total__btns">
    <div class="row align-items-center justify-content-sm-between justify-content-center">
      <div class="d-flex p-12 send-email">
        <div class="align-self-center checkbox">
          <input type="checkbox" name="sendEmail" class="hubx-radio" id="sendEmail" [(ngModel)]="cartService.sendMail">
          <label for="sendEmail"></label>
        </div>
        <div class="align-self-center">
          Send Email
        </div>
      </div>
      <div class="col-auto edit-order-wrap pr-0">
        <button class="edit-order btn btn-cancel" (click)="cancelCart();" [disabled]="cartService.isPurchaseInProgress">Cancel</button>
      </div>
      <div class="col-auto pl-0">
        <button *ngIf="selected_order_number"
          [disabled]="findAllErrors() || loading || cartService.isPurchaseInProgress || topService.loading"
          [ngClass]="{'disabled': errorCollection.size > 0}"
          class="edit-order btn btn-update-order"
          (click)="placeNew()">
          <span *ngIf="loading" class="cart-loader"></span>
          <span *ngIf="!loading">Update Order</span>
          <span *ngIf="loading">Updating Order...</span>
        </button>
      </div>
    </div>
  </div>
</div>
