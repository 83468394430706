import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable ,  forkJoin, of, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { IVendorItemRequest } from './interface/IVendorItemRequest';
import { IVendorItemInfo } from './interface/IVendorItemInfo';
import { IVendor } from './interface/IVendor';
import { IVendorStaticInfo } from './interface/IVendorStaticInfo';

import { IVendorSearch } from './interface/IVendorSearch';
import { IVendorItem } from './interface/IVendorItem';
import { IPoMain } from './interface/IPoMain';
import { IPo } from './interface/IPo';
import { IPoPdfRequest } from './interface/IPoPdfRequest';
import { IPoTrackingInformationRequest } from './interface/IPoTrackingTrackingInformationRequest';
import { IPoFilter } from './interface/IPoFilter';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { IPOSearchFilter } from './interface/IPOSearchFilter';
import { IVendorItemCodeRequest } from './interface/IVendorItemCodeRequest';
import { IVendorPendingSoldList } from './interface/IVendorPendingSoldList';
import { IVendorPendingSoldFilter } from './interface/IVendorPendingSoldFilter';
import { IVendorStatus } from './interface/IVendorStatus';
import { IVendorItemsSavedInfo } from './interface/IVendorItemsSavedInfo';
import { IItemsExportRequest } from './interface/IItemsExportRequest';
import { PoSortCondition } from './enums/po-sort-condition';
import { LoaderService } from '../shared/services/loader.service';
import { FormBuilder, Validators, AbstractControl, FormGroup } from '@angular/forms';
import { IPoTracking } from './interface/IPoTracking';
import { filter, finalize, tap } from 'rxjs/operators';
import { DashboardService } from './dashboard/dashboard.service';


@Injectable()
export class VendorService {
  searchString = '';
  totalItemsPerPage = environment.vendorItemsPageSize;
  pendingSoldUpdatedItems: IVendorItemInfo[] = [];
  pendingVendorItemsRequest: IVendorItemRequest[] = [];
  editedItemCopy: IVendorItemInfo = {};
  itemsExportRequest: IItemsExportRequest;
  portal = 'vendor';
  vendors: IVendor[] = [];
  selectedPoLine: IPo = {};
  isFullDetailsPage = false;
  isPurchaseOrdersPage = false;
  poScrollPosition = 0;
  newCustomerCode: string;
  vendorId: string;
  isPendingPo = false;
  isPendingPoAll = false;
  poFilters: IPoFilter = {
    status: { name: 'All' },
    vendor: {},
    searchString: ''
  };
  vendorManagerVendors = [];

  poSearchFilter: IPOSearchFilter = {
    pagination: {
      pageNumber: 1,
      pageSize: environment.poPageSize
    },
    sortDirection: 'DESC',
    sortCondition: PoSortCondition.Date
  };

  pendingSoldFilters: IVendorPendingSoldFilter = {
    vendor: {},
    searchString: ''
  };

  vendorItemsSavedInfo: IVendorItemsSavedInfo = {
    itemsFilters: {
    },
    statusSelectedChoice: 'All',
    updatedItems: [],
    vendorItemsRequest: [],
    useAnalytics: false,
    viewType: 'excel'
  };

  updatedMappedItems: Map<string, {itemRequest: IVendorItemRequest, updateItem: IVendorItemInfo}> = new Map();
  isShowAllUpdatedItems = false;

  search: IVendorSearch;
  totalPOsPerPage = environment.poPageSize;
  userRole = '';
  isPoFullDetailMode = false;
  restAPI = '';
  defaultShippingAddress = '78 SW 7th Street';
  defaultCityStateZip = 'Miami FL 33130';

  poPayload: IPOSearchFilter;
  previousUrl: string;

  /**
   * For vendor manager we need to wait for impersonation after login
   */
  isImpersonationReady = false;

  pubSubSubscription = new Subscription();

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private loaderService: LoaderService,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
  ) {
    this.authService.roleChanged.subscribe(role => this.onRoleChanged(role));
    this.setUserRole();
  }

  getAllCustomers() {
    this.restAPI = 'api/users/vendorcustomers';
    const url = environment.adminBaseUrl + this.restAPI;
    return this.http.get(url);
  }

  impersonate(code) {
    this.restAPI = 'api/users/vendorimpersonate/' + code;
    const url = environment.adminBaseUrl + this.restAPI;
    return this.http.put<null>(url, '')
    .pipe(tap(() => {
      this.isImpersonationReady = true;
      sessionStorage.setItem('impersonation_ready', 'true');
    }));
  }

  get isAdmin() {
    return sessionStorage.getItem('user_role') === 'ADMIN';
  }

  get isVendorManager() {
    return sessionStorage.getItem('user_role') === 'VENDORMGR';
  }

  get isSeller() {
    return sessionStorage.getItem('user_role') === 'SELLER';
  }

  onRoleChanged(role: string): void {
    this.userRole = role;
  }

  getItems(search: IVendorSearch): Observable<IVendorItem> {
    this.restAPI = 'VendorItem/Items';
    const url = environment.vendorPortalBaseUrl + this.restAPI;
    return this.http.post<IVendorItem>(url, search);
  }

  getStatusCounts(search: IVendorSearch): Observable<IVendorStatus[]> {
    this.restAPI = 'VendorItem/StatusCounts';
    const url = environment.vendorPortalBaseUrl + this.restAPI;
    return this.http.post<IVendorStatus[]>(url, search);
  }

  getStatusCountAndItems(search: IVendorSearch): Observable<any> {
    const items = this.http.post(environment.vendorPortalBaseUrl + 'VendorItem/Items', search);
    const statusCounts = this.http.post(environment.vendorPortalBaseUrl + 'VendorItem/StatusCounts', search);
    return forkJoin([items, statusCounts]);
  }

  getVendorItemById(ids: string[], nonDecrementalUrl = true): Observable<IVendorItemInfo[]> {
    this.restAPI = 'VendorItem/ItemsById';
    const url = environment.vendorPortalBaseUrl + this.restAPI;
    if (nonDecrementalUrl) {
      this.loaderService.addNonDecrementalUrl(url);
    }
    return this.http.post<IVendorItemInfo[]>(url, ids);
  }

  getPendingSoldItems(vendorCode?: string): Observable<IVendorPendingSoldList> {
    this.isPendingPoAll ? this.restAPI = 'po/pending?all=true' : this.restAPI = 'po/pending?all=false';
    const url = environment.vendorPortalBaseUrl + this.restAPI;
    return this.http.get<IVendorPendingSoldList>(url);
  }

  public getPOPDF(docEntry: number): Observable<Blob> {
    const headers = new HttpHeaders({
      'Accept': 'application/pdf',
      'Content-Type': 'application/json',
      'responseType': 'blob'
    });
    const request: IPoPdfRequest = {
      reportName: 'PurchaseOrder',
      renderFormat: 'pdf',
      parameters: {
        docEntry: docEntry
      }
    };
    const url = environment.adminUrl + 'reports';
    return this.http
      .post(url, request, {
        responseType: 'blob'
      });
  }

  getVendorDropDownData(): Observable<any> {
    this.restAPI = 'VendorItem/staticInfo';
    const dropdownReq = this.http.get<IVendorStaticInfo>(environment.vendorPortalBaseUrl + this.restAPI);
    const allExwOpt = this.http.get(environment.vendorPortalBaseUrl + 'VendorItem/exwPoints');
    return forkJoin([dropdownReq, allExwOpt]);
  }

  getPOId(id: number): Observable<IPo> {
    this.restAPI = 'po/' + id;
    const url = environment.vendorPortalBaseUrl + this.restAPI;
    return this.http.get<IPo>(url);
  }

  getPOs(searchFilter: IPOSearchFilter): Observable<IPoMain> {
    this.restAPI = 'po/orders';
    const url = environment.vendorPortalBaseUrl + this.restAPI;
    return this.http.post<IPoMain>(url, searchFilter);
  }

  updateVendorItems(vendorItems: IVendorItemRequest[]): Observable<null> {
    this.restAPI = 'VendorItem/internalVendorItem';
    const url = environment.vendorPortalBaseUrl + this.restAPI;
    this.loaderService.addNonDecrementalUrl(url);
    return this.http.post<null>(url, vendorItems);
  }

  addTrackingToPurchaseOrder(trackingInfo: IPoTrackingInformationRequest, docEntry: number): Observable<null> {
    this.restAPI = 'po/' + docEntry;
    const url = environment.vendorPortalBaseUrl + this.restAPI;
    return this.http.put<null>(url, trackingInfo);
  }

  getAllVendors(): Observable<IVendor[]> {
    this.restAPI = 'VendorItem/sellers';
    const url = environment.vendorPortalBaseUrl + this.restAPI;
    return this.http.get<IVendor[]>(url);
  }

  validateVendorItemCode(updatedItemCode: IVendorItemCodeRequest): Observable<null> {
    this.restAPI = 'VendorItem/vendorItemCode/' + updatedItemCode.vendorItemId;
    const url = environment.vendorPortalBaseUrl + this.restAPI;
    return this.http.put<null>(url, updatedItemCode);
  }

  isUser(users: string[]): boolean {
    const a = users.filter(user => user === this.userRole);
    return a.length > 0;
  }

  private setUserRole(): void {
    try {
      this.userRole = sessionStorage.getItem('user_role');
    } catch (err) {
      // alert('User Role not found in session storage');
      this.router.navigate(['']);
    }
  }

  exportItemList(  itemsExportRequest: IItemsExportRequest ): Observable<HttpResponse<Blob>> {
    this.restAPI = 'VendorItem/exportVendorProdFeed';
    const url = environment.vendorPortalBaseUrl + this.restAPI;
    return this.http.post<Blob>(url, itemsExportRequest, {observe: 'response', responseType: 'blob' as 'json'});
  }

  // Handle order tracking form
  getDefaultTrackingFormErrors() {
    return {
      invoice: {},
      shipmentDate: {},
      shipmentType: {},
      carrierName: {},
      bol: {},
      etaDate: {},
      trackingNbr: {},
      notes: {},
    };
  }

  createTrackingForm(po: IPo): FormGroup {
    return this.formBuilder.group({
      invoice:      [{ value: '', disabled: po.orderStatus === 'closed' }, [Validators.maxLength(20)]],
      shipmentType: [{ value: '', disabled: po.orderStatus === 'closed' }, [Validators.maxLength(10)]],
      bol:          [{ value: '', disabled: po.orderStatus === 'closed' }, [Validators.maxLength(40)]],
      notes:        [{ value: '', disabled: po.orderStatus === 'closed' }, [Validators.maxLength(1024)]],
      shipmentDate: [{ value: '', disabled: po.orderStatus === 'closed' }, [Validators.required, this.validateDate.bind(this), this.validateEmptySpaces.bind(this)]],
      carrierName:  [{ value: '', disabled: po.orderStatus === 'closed' }, [Validators.required, Validators.maxLength(60), this.validateEmptySpaces.bind(this)]],
      etaDate:      [{ value: '', disabled: po.orderStatus === 'closed' }, [Validators.required, this.validateDate.bind(this), this.validateEmptySpaces.bind(this)]],
      trackingNbr:  [{ value: '', disabled: po.orderStatus === 'closed' }, [Validators.required, Validators.maxLength(254), this.validateEmptySpaces.bind(this)]],
    });
  }

  validateDate(control: AbstractControl) {
    const date = control.value;
    if (date !== '' && isNaN(Date.parse(date))) {
      return { validDate: false };
    }
    return null;
  }

  validateEmptySpaces(control: AbstractControl) {
    const field = (control.value && (control.value + '') || '').trim();
    if (field.length === 0) {
      return { emptySpaces: false };
    }
    return null;
  }

  validateTrackingForm(formErrors, form: FormGroup) {
    for (const field in formErrors) {
      if (!formErrors.hasOwnProperty(field)) {
        continue;
      }
      formErrors[field] = {};
      const control = form.get(field);
      if (control && !control.valid) {
        formErrors[field] = control.errors;
      }
    }
  }

  setSubscriptions(formErrors, form: FormGroup) {
    for (const field in formErrors) {
      if (!formErrors.hasOwnProperty(field)) {
        continue;
      }
      const control = form.get(field);
      if (control) {
        control.valueChanges.subscribe(newValue => {
          if (control && !control.valid) {
            formErrors[field] = control.errors;
          } else {
            formErrors[field] = {};
          }
        });
      }
    }
  }

  prepareTrackingToUpdate(po, form: FormGroup): void {
    const today = new Date();
    if (!po.shippingInformation.shippingDate) {
      po.shippingInformation.shippingDate = this.router.url === '/vendor/purchase-orders' ? today : '';
    } else {
      po.shippingInformation.shippingDate = new Date(po.shippingInformation.shippingDate);
    }
    if (!po.shippingInformation.eta) {
      po.shippingInformation.eta = '';
    } else {
      po.shippingInformation.eta = new Date(po.shippingInformation.eta);
    }
    const tracking: IPoTracking = {
      invoice: po.vendorInvoiceNo,
      shipmentDate: po.shippingInformation.shippingDate,
      shipmentType: po.shippingInformation.transportationMode,
      carrierName: po.shippingInformation.carrier,
      bol: po.shippingInformation.billOfLadingNumber,
      etaDate: po.shippingInformation.eta,
      trackingNbr: po.shippingInformation.trackingNumber,
      notes: po.shippingInformation.comments,
    } as IPoTracking;
    form.patchValue(tracking);
  }

  prepareTrackingToSave(form: FormGroup, po: IPo, trackingInformationRequest: IPoTrackingInformationRequest): void {
    const tracking: IPoTracking = form.value;
    po.shippingInformation.comments = tracking.notes;
    if (po.orderStatus === 'open') {
      po.vendorInvoiceNo = tracking.invoice;
      po.shippingInformation.shippingDate = tracking.shipmentDate;
      po.shippingInformation.transportationMode = tracking.shipmentType;
      po.shippingInformation.carrier = tracking.carrierName;
      po.shippingInformation.billOfLadingNumber = tracking.bol;
      po.shippingInformation.eta = tracking.etaDate;
      po.shippingInformation.trackingNumber = tracking.trackingNbr;
    }
    trackingInformationRequest.vendorInvoiceNo =  po.vendorInvoiceNo || '';
    trackingInformationRequest.shippingInformation.shippingDate = po.shippingInformation.shippingDate || '';
    trackingInformationRequest.shippingInformation.carrier = po.shippingInformation.carrier || '';
    trackingInformationRequest.shippingInformation.transportationMode = po.shippingInformation.transportationMode || '';
    trackingInformationRequest.shippingInformation.trackingNumber = po.shippingInformation.trackingNumber || '';
    trackingInformationRequest.shippingInformation.billOfLadingNumber = po.shippingInformation.billOfLadingNumber || '';
    trackingInformationRequest.shippingInformation.eta = po.shippingInformation.eta || '';
    trackingInformationRequest.shippingInformation.comments =  po.shippingInformation.comments || '';
  }

  downloadPendingPOList(): Observable<HttpResponse<Blob>> {
    this.restAPI = this.isPendingPoAll ? 'po/exportPendingPO?all=true' : 'po/exportPendingPO?all=false';
    const url = environment.vendorPortalBaseUrl + this.restAPI;
    return this.http.get<Blob>(url, {observe: 'response', responseType: 'blob' as 'json'});
  }

  resetDashboardVariables() {
    sessionStorage.setItem('impersonation_ready', 'false');
    this.isImpersonationReady = false;
    this.dashboardService.listingStatuses = [];
    this.dashboardService.totalListing = null;
    this.dashboardService.vendorHasItems = true;
    this.unsubscribePubSub();
  }

  unsubscribePubSub() {
    this.pubSubSubscription.unsubscribe();
    this.pubSubSubscription = new Subscription();
  }

  onBrowserRefreshEvent(): Observable<any> {
    return this.router.events.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd));
  }
}
