<div class="filter-animation" [class.__show]="show" (swipedown)="hideFilter()">
  <div class="filter-wrap">
    <div class="filter-wrap-header">
      <div class="filter-top">
        <div class="filter">
          <div class="filter-row">
            <div class="filter-current">
              <span class="filter-current__value">
                Notification Center
                <span *ngIf="notificationSvcBuyer.activeTab===NotificationFiltersEnum.all">All Notifications</span>
                <span *ngIf="notificationSvcBuyer.activeTab===NotificationFiltersEnum.archive">Archive</span>
                <span *ngIf="notificationSvcBuyer.activeTab===NotificationFiltersEnum.priceDrop">Price Dropped</span>
                <span *ngIf="notificationSvcBuyer.activeTab===NotificationFiltersEnum.waitlist">Back in Stock!</span>
                <span *ngIf="notificationSvcBuyer.activeTab===NotificationFiltersEnum.moreInventory">Inventory Added</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <i class="filter-wrap-header__close" (click)="hideFilter()">
        <img src="../../../../../assets/images/icons/mobile/menu/m-close-white.svg" width="24" height="24" alt="close">
      </i>
    </div>
    <div class="filter-wrap-content">
      <div class="filter-items-row">
        <div class="filter-items-row__content">
          <div class="filter-item-wrap" (click)="onTabClicked(NotificationFiltersEnum.all)">
            <div class="filter-item" [class.active]="notificationSvcBuyer.activeTab===NotificationFiltersEnum.all">
              <span class="filter-item__icon">
                <i class="icon-img _all"></i>
              </span>
              <div class="filter-item__label">All<br /><span>Notifications</span></div>
              <span class="filter-item__count" *ngIf="counter.all > 0">{{counter.all}}</span>
            </div>
          </div>
          <div class="filter-item-wrap" (click)="onTabClicked(NotificationFiltersEnum.archive)">
            <div class="filter-item" [class.active]="notificationSvcBuyer.activeTab===NotificationFiltersEnum.archive">
              <span class="filter-item__icon">
                <i class="icon-img _archive"></i>
              </span>
              <div class="filter-item__label">Archive</div>
            </div>
          </div>
          <div class="filter-item-wrap" (click)="onTabClicked(NotificationFiltersEnum.priceDrop)">
            <div class="filter-item" [class.active]="notificationSvcBuyer.activeTab===NotificationFiltersEnum.priceDrop">
              <span class="filter-item__icon">
                <i class="icon-img _price-drop"></i>
              </span>
              <div class="filter-item__label">Price<br />Dropped</div>
              <span class="filter-item__count" *ngIf="counter.dropPrice > 0">{{counter.dropPrice}}</span>
            </div>
          </div>
          <div class="filter-item-wrap" (click)="onTabClicked(NotificationFiltersEnum.waitlist)">
            <div class="filter-item" [class.active]="notificationSvcBuyer.activeTab===NotificationFiltersEnum.waitlist">
              <span class="filter-item__icon">
                <i class="icon-img _back-in-stock"></i>
              </span>
              <div class="filter-item__label">Back in<br />Stock!</div>
              <span class="filter-item__count" *ngIf="counter.waitlist > 0">{{counter.waitlist}}</span>
            </div>
          </div>
          <div class="filter-item-wrap" (click)="onTabClicked(NotificationFiltersEnum.moreInventory)">
            <div class="filter-item" [class.active]="notificationSvcBuyer.activeTab===NotificationFiltersEnum.moreInventory">
              <span class="filter-item__icon">
                <i class="icon-img _inventory-added"></i>
              </span>
              <div class="filter-item__label">Inventory<br />Added</div>
              <span class="filter-item__count" *ngIf="counter.moreInventory > 0">{{counter.moreInventory}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
