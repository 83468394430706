import { IVendorSalesInfo } from './IVendorSalesInfo';
import { IVendorInventoryInfo } from './IVendorInventoryInfo';
import { IVendorItemAttributes } from './IVendorItemAttributes';
import { IVendorItemPrice } from './IVendorItemPrice';
import { IVendorItemStatus } from './IVendorItemStatus';
import { IValidationFailure } from './IValidationFailure';
import { ILoadValidationError } from './ILoadValidationError';
import { IKeyValue } from './IKeyValue';
import { INotificationDetail } from './INotificationDetail';
import { IVendorItemComment } from './IVendorItemComment';

export interface IVendorItemInfo {
  id?: string;
  version?: string;
  vendorItemCode?: string;
  description?: string;
  exwPointValue?: number;
  manufacturer?: string;
  mpn?: string;
  cost?: number;
  unitPrice?: number;
  status?: IVendorItemStatus;
  vendorStatusCode?: number;
  inventoryInfo?: IVendorInventoryInfo;
  lastActivatedOn?: string;
  lastUpdatedOn?: string;
  updatedNow?: boolean;
  prices?: IVendorItemPrice[];
  attributes?: IVendorItemAttributes[];
  mappedAttributes?: {
    condition?: IVendorItemAttributes,
    warranty?: IVendorItemAttributes,
    packaging?: IVendorItemAttributes,
    restrictions?: IVendorItemAttributes
  };
  salesInfo?: IVendorSalesInfo;
  errors?: ILoadValidationError;
  errorIndex?: number;
  categories?: string[];
  selected?: boolean;
  showError?: boolean; // indicates whether to show the errors list on a single item
  seller?: IKeyValue;
  manufacturerLogoUrl?: string;
  isItemCodeLocked?: boolean;
  descriptionCanEdit?: boolean;
  moq?: number;
  mxq?: number;
  hubxItemCode?: string;
  notificationDetails?: INotificationDetail[];
  leadTimeDays?: number;
  comments?: IVendorItemComment[];
  initialCost?: number;
  index?: number;
  reactivationThreshold?: number;
  disabled?: boolean;
}

export enum itemOperationFlagAttr {
  information = 1,
  price = 2,
  inventory = 4,
  status = 8
}
