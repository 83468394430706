import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ListingViewItemComponent } from '../listing-view-item.component';
import * as models from '../../../buyer/interfaces/model';
import { environment } from '../../../../environments/environment';
import { NotificationFiltersEnum } from '../../interfaces/INotificationDetail';

@Component({
  selector: 'app-listing-view-item-mobile',
  templateUrl: './listing-view-item-mobile.component.html',
  styleUrls: ['./listing-view-item-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListingViewItemMobileComponent extends ListingViewItemComponent implements OnInit {
  @Input() item: models.IItem;
  @Input() source = '';
  @Input() currentNotFilter: NotificationFiltersEnum;
  @Output() notificationTooltipEmitter = new EventEmitter<{ item: models.IItem, event: Event }>();
  @Output() gradGuideEmitter = new EventEmitter<models.IItem>();
  @Output() offerPopupEmitter = new EventEmitter<models.IItem>();
  isDetailsOpened = false;
  environment = environment;
  showItemMXQ = false;

  toggleDetails(event: Event, shouldClose: boolean): void {
    this.isDetailsOpened = shouldClose ? false : !this.isDetailsOpened;
  }

  showDetails() {
    this.isDetailsOpened = true;
  }
  hideDetails() {
    this.isDetailsOpened = false;
  }

  getAttrUrl(attr) {
    switch (attr.label) {
      case 'Price Drop' :
        return '../../../../assets/images/icons/mobile/24-price-drop.svg';
      case 'Just Launched' :
        return '../../../../assets/images/icons/mobile/24-just-lauched.svg';
      case 'Today\'s Deals' :
        return '../../../../assets/images/icons/mobile/24-todays-deals.svg';
      default :
        return false;
    }
  }

  isTopAttributes(attr): boolean {
    const topAttrArr = attr.filter((attr) => attr.label === 'Just Launched'
      || attr.label === 'Today\'s Deals' || attr.label === 'Price Drop');
    return topAttrArr.length > 0;
  }

  showGradingGuide(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.gradGuideEmitter.emit(this.item);
  }

  qtyInputClicked(element:any): void{
    if (element){
      this.item.qty = null;
      element.focus();
      element.select();
    }
  }

  itemMXQTooltip() {
    this.showItemMXQ = !this.showItemMXQ;
  }

  openOfferModal(event: any): void {
    this.offerPopupEmitter.emit(this.item);
  }

}


