export enum OfferEventTypes {
  All = 0,
  OfferMade = 1,
  VendorAcceptedOffer = 2,
  VendorCounteroffered = 3,
  VendorRejectedOffer = 4,
  BuyerAcceptedOffer = 5,
  BuyerCounteroffered = 6,
  BuyerRejectedOffer = 7,
  BuyerCancelledOffer = 8,
  VendorCancelledOffer = 9,
  SystemCancelledOffer = 10,
  BuyersOfferExpired = 11,
  VendorsOfferExpired = 12,
  OrderCreated = 13,
  VendorAcceptedPreviousOffer = 14,
  BuyerAcceptedPreviousOffer = 15,
  VendorUpdatedOfferEvent = 16,
  BuyerUpdatedOfferEvent = 17,

}
