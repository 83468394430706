import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-nothing-found',
  templateUrl: './nothing-found.component.html',
  styleUrls: ['./nothing-found.component.scss']
})
export class NothingFoundComponent implements OnInit {
  @Input() isVisible = false;
  @Input() message = '';
  @Input() minorMessage = '';
  @Input() buttonTxt;
  @Output() onRefresh = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  refresh() {
    this.onRefresh.emit();
  }
}
