
<div class="add-to-wrap animated" [class.zoomIn]="showModal">
	<div class="add-to">

		<div class="add-to-header">
		   <div *ngIf="isShownOrderDetails" (click)="hideOrderDetail()" class="back"></div>
		   <h3 *ngIf="isShownOrderDetails">Order {{selectedOpenOrder.docNumber }}</h3>
		   <h3 *ngIf="!isShownOrderDetails">Open Orders</h3>
		   <div (click)="closeModal()" class="close"></div>
		</div>

		<div class="add-to-body">

			<div class="add-to-list-wrap" [class.__is-hide]="isShownOrderDetails">
				<ul class="add-to-list">
		            <li *ngFor="let openOrder of openOrders" (click)="onSelectOrder(openOrder)" [class._selected]="selectedOpenOrder.docEntry === openOrder.docEntry">
		              <span class="add-to-list__circle">
		                <span *ngIf="selectedOpenOrder.docEntry === openOrder.docEntry"></span>
		              </span>
		              <span class="add-to-list__data">{{openOrder.orderDate | date:'shortDate'}}</span>
		              <span class="add-to-list__number">{{openOrder.docNumber}}</span>
		              <span class="add-to-list__arrow" (click)="openOrderDetail(openOrder)"></span>
		            </li>
		        </ul>
			</div>

	        <div class="add-to-datails" [class.__is-shown]="isShownOrderDetails">

	        	<div class="add-to-datails-header">
	        		<div class="add-to-datails-header-wrap">
	        			<div class="add-to-datails-header-item">
	        				<span class="add-to-datails-header-item__key">Order Id</span>
	        				<span class="add-to-datails-header-item__val">{{selectedOpenOrder.docNumber }}</span>
	        			</div>
	        			<div class="add-to-datails-header-item">
	        				<span class="add-to-datails-header-item__key">Order Date</span>
	        				<span class="add-to-datails-header-item__val">{{selectedOpenOrder.orderDate | date:'shortDate'}}</span>
	        			</div>
	        			<div class="add-to-datails-header-item">
	        				<span class="add-to-datails-header-item__key">Amount</span>
	        				<span class="add-to-datails-header-item__val">{{selectedOpenOrder.orderTotal | CustomCurrency}}</span>
	        			</div>
	        		</div>
		          </div>

		          <div class="add-to-datails-table">
		          	<div class="add-to-datails-table-wrap">
		          		<ul>
		          			<li class="add-to-datails-table-item __header">
		          				<span>Part</span>
		          				<span>Price</span>
		          				<span>QTY</span>
		          				<span>Total</span>
		          			</li>
		          			<li class="add-to-datails-table-item" *ngFor="let item of selectedOpenOrder.orderDetails">
		          				<span>{{ item.itemCode }}</span>
		          				<span>{{((item.overwrittenPrice)?item.overwrittenPrice:item.unitPrice) | CustomCurrency}}</span>
		          				<span>{{item.quantity}}</span>
		          				<span>{{((item.overwrittenPrice)?item.overwrittenPrice:item.unitPrice) * item.quantity |CustomCurrency}}</span>
		          			</li>
		          		</ul>
		          	</div>
		          </div>

	        </div>

		</div>

		<div class="add-to-footer">
		   <div *ngIf="selectedOpenOrder.docNumber > 0" class="add-to-controls">

            <a *ngIf="!isShownOrderDetails" (click)="closeModal()"   class="add-to-control__cancel" [class.disabled]="loading"><span>Cancel</span></a>

            <a *ngIf="isShownOrderDetails" (click)="hideOrderDetail()"   class="add-to-control__cancel"><span>Back</span></a>

            <a (click)="onPlaceExisting(selectedOpenOrder.docEntry)" class="add-to-control__add" [class.disabled]="loading">
			  <span >Add to Order #{{selectedOpenOrder.docNumber}}</span>
            </a>
          </div>
		</div>

	</div>
	
</div>
