import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ItemsService } from '../../../shared/services/items.service';
import { AuthService } from '../../../auth/auth.service';
import { UserService } from '../../../user/user.service';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';
import { GlobalSearchService } from '../../../services/search.service';
import { SharedService } from '../../../shared/shared.service';
import { SessionService } from '../../../services/session.service';
import { environment } from '../../../../environments/environment';

import * as models from '../../../shared/interfaces/model';
import * as modelsS from '../../../shared/interfaces/model';
import { TopService } from '../../../shared/services/top.service';

@Component({
  selector: 'app-guest-top-full',
  templateUrl: './guest-top-full.component.html',
  styleUrls: ['./guest-top-full.component.scss']
})
export class GuestTopFullComponent implements OnInit {
  disableBulkSumitButton: boolean;
  disabledClassApplied: boolean;
  bulkUploadFieldData = '';
  errors: any; // errors from server
  salesCustomers: modelsS.ICustomers;
  selectedCustomer = '';
  user_role = '';
  showAdminNav = false;
  isCustomerSelected = false;
  hideAdminMenu = false;
  bulkapi_response = [];
  showMobileMenu = false;
  animateOut = false;
  bulkUploadInProgress = false;
  bulkUploadHasErrorAndSucess = false;
  gotFirst = 0;
  showloadString = ``;
  disabledClass = '';
  customersLoading = false;
  currentCustomer = '';
  currentUserEmail = '';
  showBulk = false;
  showLoad = false;
  showDelCart = false;
  uploadedContent: string[];
  excelData: string;
  showBulkErr = false;
  loading = false;
  isResponse200 = false;
  searchAlert = false;
  isMobileSorting = false;
  maintenanceModeOn = false;

  constructor(
    public router: Router,
    private itemsService: ItemsService,
    public authService: AuthService,
    public userService: UserService,
    private pubSubService: PubSubService,
    private searchService: GlobalSearchService,
    public sharedService: SharedService,
    public sessionService: SessionService,
    public topService: TopService
  ) { }

  ngOnInit() {
    this.currentUserEmail = this.sessionService.userEmail;
    this.user_role = this.sessionService.userRole;
    this.disableBulkSumitButton = true;

    if (environment.maintenanceMode) {
      this.maintenanceModeOn = true;
    }
  }

  validateBulkUploadBeforeSubmit(): void {
    if (this.excelData && this.excelData.trim()) {
      if (this.excelData !== '') {
        this.disableBulkSumitButton = false;
        this.showLoad = !this.showLoad;
        this.showDelCart = !this.showDelCart;
      }
    } else {
      this.disableBulkSumitButton = true;
    }
  }

  continueLogicallyAfterButlAttempt(): void {
    // if bulk upload has success and error
    if (this.bulkUploadHasErrorAndSucess) {
      this.showLoad = false;
      this.showBulkErr = !this.showBulkErr;
      this.hideAdminMenu = true;
      this.showBulk = false;
      this.sharedService.cartData = true;
      this.router.navigate(['cart']);
    } else {
      this.showLoad = false;
      this.disableContinueButton();
    }
  }

  disableContinueButton() {
    return this.disabledClassApplied = true;
  }

  checkBulkDataLength(): boolean {
    return !this.bulkUploadFieldData.length;
  }


  totalUnits(): number {
    return 2;
  }

  closeDelCart(): void {
    this.animateOut = true;
    setTimeout(() => {
      this.showDelCart = false;
      this.animateOut = false;
    }, 200);
  }


  getCurrent(): string {
    return '';
    //  return  this.userService.currentCustomer;
  }

  onCancelBulkUpload(): void {
    this.hideAdminMenu = true;
    this.showLoad = false;
    this.showBulk = false;
  }

  onActivateBulkUpload(): void {
    this.hideAdminMenu = false;
    this.showBulk = this.excelData && this.excelData.trim().length ? true : false;
  }

  clickLogoToReload(): void {
    this.router.navigate(['/guest/home']);
  }

  onClickedOutside(e: Event): void {
    this.showLoad = false;
    const element = e.srcElement as Element;
    if (element.className && (element.className.toString() === 'select2-search__field'
      || element.className.toString() === 'select2-results__option select2-results__message')) {
      return;
    } else {
      this.showAdminNav = false;
    }
  }

  resetDefault(): void {
    this.userService.billingDefault = {
      addressCode: '',
      isDefault: false,
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zip: ''
    };
    this.userService.shippingDefault = {
      addressCode: '',
      isDefault: false,
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zip: ''
    };
  }

  checkForSearchErr(): boolean {
    return this.searchService.showErr;
  }

  closeSearchErr(): void {
    this.searchService.showErr = false;
  }

  showErrMessage(): string {
    if (this.searchService.showErr) {
      return this.searchService.errorMessage;
    }
  }

  showErrTitle(): string {
    if (this.searchService.showErr) {
      return this.searchService.orderDocNumber;
    }
  }

  getSalesText(): string {
    if (this.selectedCustomer && this.userService.bpCode !== this.sessionService.userEmail) {
      return 'Account - ' + this.userService.bpCode;
    } else {
      return this.currentUserEmail;
    }
  }

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }


}
