<div class="notification-header-wrapper">
	<div class="notification-header">
		<div class="notification-header-select-all">
		    <label class="notification-checkbox">
		      <input type="checkbox" [(checked)]="allSelected" [indeterminate]="indeterminate" (change)="selectUnselectAll($event)">
		      <i class="_icon"></i>
		    </label>
		</div>
		<div class="notification-heading">
			<h1>Notification <br>Center</h1>
		</div>
		<div class="notification-box-wrapper" [class.darken-active]="darkenActive">
			<div class="notification-box" [class.active]="activeTab === 0" (click)="onTabClicked(0)"
			(mouseenter)="onTabHovered($event)"
            (mouseleave)="onTabMouseLeave($event)">
				<div class="notification-box-img">
					<img width="64" height="64" src="assets/images/notification/bar-all.svg">
				</div>
				<div class="notification-box-txt">
					<div class="notification-box-txt__val">
						{{totals.all}}
					</div>
					<div class="notification-box-txt__key">
						All Notifications
					</div>
				</div>
				<div class="notification-box-bage" *ngIf="counter.allNew > 0">
					<span>{{counter.allNew}}</span>
				</div>
			</div>
			<div class="notification-box _price-drop" [class.active]="activeTab === 1" (click)="onTabClicked(1)"
			(mouseenter)="onTabHovered($event)"
            (mouseleave)="onTabMouseLeave($event)">
				<div class="notification-box-sort" [ngClass]="{
					'_asc': activeTab === 1 && _notifCenterSvc.tabSortDirection === 'ASC',
					'_desc': activeTab === 1 && _notifCenterSvc.tabSortDirection === 'DESC'}">
					<div class="notification-box-sort-icon">
						<i class="notification-box-sort-icon__row _top"></i>
						<i class="notification-box-sort-icon__row _middle"></i>
						<i class="notification-box-sort-icon__row _btm"></i>
					</div>
				</div>
				<div class="notification-box-img">
					<img width="64" height="64" src="assets/images/notification/bar-price-drop.svg">
				</div>
				<div class="notification-box-txt">
					<div class="notification-box-txt__val">
						{{totals.dropPrice}}
					</div>
					<div class="notification-box-txt__key">
						Buyers waiting <br>for price drop
					</div>
				</div>
				<div class="notification-box-bage" *ngIf="counter.dropPriceNew > 0">
					<span>{{counter.dropPriceNew}}</span>
				</div>
			</div>
			<div class="notification-box _inventrory" [class.active]="activeTab === 2" (click)="onTabClicked(2)"
			(mouseenter)="onTabHovered($event)"
            (mouseleave)="onTabMouseLeave($event)">
				<div class="notification-box-sort" [ngClass]="{
					'_asc': activeTab === 2 && _notifCenterSvc.tabSortDirection === 'ASC',
					'_desc': activeTab === 2 && _notifCenterSvc.tabSortDirection === 'DESC'}">
					<div class="notification-box-sort-icon">
						<i class="notification-box-sort-icon__row _top"></i>
						<i class="notification-box-sort-icon__row _middle"></i>
						<i class="notification-box-sort-icon__row _btm"></i>
					</div>
				</div>
				<div class="notification-box-img">
					<img width="64" height="64" src="assets/images/notification/bar-sold.svg">
				</div>
				<div class="notification-box-txt">
					<div class="notification-box-txt__val">
						{{totals.waitlist}}
					</div>
					<div class="notification-box-txt__key">
						Buyers waiting <br>for inventory
					</div>
				</div>
				<div class="notification-box-bage" *ngIf="counter.waitlistNew > 0">
					<span>{{counter.waitlistNew}}</span>
				</div>
			</div>
			<div class="notification-box _more-inventrory" [class.active]="activeTab === 3" (click)="onTabClicked(3)"
			(mouseenter)="onTabHovered($event)"
            (mouseleave)="onTabMouseLeave($event)">
				<div class="notification-box-sort" [ngClass]="{
					'_asc': activeTab === 3 && _notifCenterSvc.tabSortDirection === 'ASC',
					'_desc': activeTab === 3 && _notifCenterSvc.tabSortDirection === 'DESC'}">
					<div class="notification-box-sort-icon">
						<i class="notification-box-sort-icon__row _top"></i>
						<i class="notification-box-sort-icon__row _middle"></i>
						<i class="notification-box-sort-icon__row _btm"></i>
					</div>
				</div>
				<div class="notification-box-img">
					<img width="64" height="64" src="assets/images/notification/bar-more-inventory.svg">
				</div>
				<div class="notification-box-txt">
					<div class="notification-box-txt__val">
						{{totals.moreInventory}}
					</div>
					<div class="notification-box-txt__key">
						Buyers waiting <br>for more inventory
					</div>
				</div>
				<div class="notification-box-bage" *ngIf="counter.moreInventoryNew > 0">
					<span>{{counter.moreInventoryNew}}</span>
				</div>
			</div>
		</div>
		<div class="notification-filter-wrapper">
			<div class="notification-filter">
				<div class="notification-filter__search">
					<button>
			          <svg height="24" viewBox="0 0 32 32" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m25.84375 22.8029159-4.4166667-4.4727935c.71875-1.2548816 1.09375-2.6503515 1.09375-4.0562354 0-4.56131215-3.7083333-8.273887-8.2604166-8.273887-4.55208337 0-8.2604167 3.71257485-8.2604167 8.273887 0 4.5613122 3.70833333 8.273887 8.2604167 8.273887 1.453125 0 2.890625-.4009372 4.171875-1.1663629l4.3958333 4.4571726c.0989583.0989326.2395833.1614163.3802083.1614163s.28125-.0572768.3802084-.1614163l2.2552083-2.2806561c.2083333-.213486.2083333-.5467326 0-.7550117zm-11.5833333-13.57979692c2.78125 0 5.0416666 2.26503512 5.0416666 5.05076802s-2.2604166 5.050768-5.0416666 5.050768-5.0416667-2.2650351-5.0416667-5.050768 2.2604167-5.05076802 5.0416667-5.05076802z" fill="#fff"/></svg>
			        </button>
			        <input type="search" placeholder="Search" #search (keyup)="onSearch($event)">
				</div>
				<div class="notification-filter__archive" [class.active]="activeTab === 4" (click)="onTabClicked(4)">
					<i>
						<img src="assets/images/vendor-icons/24-archive-white.svg" width="24" height="24">
					</i>
					<span>Archived</span>
				</div>
			</div>
				<div class="notification-filter__bottom">
					<app-dropdown
					*ngIf="_notifCenterSvc.manufacturersData.length"
					[items]="getAllManufacturers()"
					[fieldToShow]="'name'" [fieldToSave]="'name'"
					[(ngModel)]="selectedManufName"

					(onSelect)="onManufacturerFilter($event)" darkTheme="true">
				  </app-dropdown>
				  <a (click)="export()" role="button" class="export-btn">
				  	<i *ngIf="exporting" class="loader"></i>
				  	<img *ngIf="!exporting" src="assets/images/vendor-icons/export.svg">
				  </a>
				</div>
		</div>
	</div>
</div>
