<div class="coming-soon-wrap">
	<div class="coming-soon">
		<div class="coming-soon-image">
			<img alt="Coming soon" src="assets/images/mobile-version@2x.png">
		</div>
		<div class="coming-soon-content">
			<h1>
				Mobile version is on the way
			</h1>
			<p>
				Please visit desktop website to explore your offers.
			</p>
		</div>
		<div class="coming-soon-action">
			<a class="button" (click)="gotoHome()">Back to Home</a>
		</div>
	</div>
</div>