<div class="row order-comments-mobile" [class.hide]="cartList.length === 0 || completed" *ngIf="topService.isMobile">
  <div class="col-sm-12">
    <h3>Order Comments</h3>
    <textarea class="form-control order-comments-form" rows="3"
              [(ngModel)]="cartService.comment" placeholder="Add Comments Here"
              #myCommnet
              [maxlength]="commentsLength">{{comments}}</textarea>
  </div>
</div>

<div class="row cart-terms margin-0" [class.mobile]="topService.isMobile">
  <div class="col-sm-12">
    <h3>By Placing Order You Agree to Our Terms and Conditions</h3>
    <p>Price & availability are subject to adjustment due to fluctuations in the market. Most products are covered by
    a limited warranty. HUBX does not provide warranty service. There may be additional charges for freight. We are
    not responsible for any typographical errors. We guarantee to ship correct part number. It is your
    responsibility
    to verify specifications with the manufacture. No returns. ALL ORDERS ARE FINAL, 15% RESTOCKING FEE APPLIES FOR
    CANCELLATIONS. HUBX reserves the right to cancel any sales order if payment confirmation is not received within
    72 business hours. At the time of the receipt of cargo any damage to packaging must be noted prior to signing
    for merchandise. If the carrier does not have such notes, insurance claim will not filed. Orders may take up
    to 2 business days, after credit release, to pick and pack.</p>
  </div>
</div>


<div class="bottom-controls-wrapper" *ngIf="cartList && !topService.isMobile">
  <div class="col-md-12 bottom-controls">
    <div class="row justify-content-md-between align-items-stretch">

      <div class="left-buttons-wrap col-auto" [ngClass]="{ 'hide': cartList.length === 0 || completed }">
        <a (click)="gotoShopping()" class="cart-btn-rebranding" [class.disabled]="cartService.isPurchaseInProgress">
          <i class="icon _back"></i><span class="text">Continue <br/>Shopping</span></a>
        <a (click)="toggleComments()" class="cart-btn-rebranding" 
        [class._active]="isCommentsShown" [class.disabled]="cartService.isPurchaseInProgress">
          <i class="icon _comment"></i><span class="text">Add Order <br/>Comments</span></a>
      </div>

      <div class="col-md-6" [ngClass]="{ 'hide': cartList.length === 0 || completed }" *ngIf="selectedOrderNumber && !isCart">
        <div class="row m-0">
          <div class="col-auto edit-order-no">
            <div class="freight-wrap" *ngIf="userRole !== 'BUYER'">
              <span>Freight: </span>
              <input type="number" class="freight-input" [(ngModel)]="editedOrderShippingCost" />
            </div>
            <div *ngIf="credit" class="processing-fee" [class.__show]="isTooltipShown">
              <div class="processing-fee-tooltip">
                <p>You are indicating your acceptance and agreement with the foregoing terms and conditions, including a 3%
                  Convenience Fee for all online purchases by card credit (incurred as a result of the ease and convenience
                  associated with conducting this transaction online), and acknowledging that you are a legally authorized user
                  of this credit card.</p>
              </div>
              <span class="processing-fee__title">Convenience Fee <i class="icon _info" 
                (mouseover)="showProcessingTitle(true)"
                (mouseleave)="showProcessingTitle(false)"></i></span>
              <span class="processing-fee__value">{{ processingFee | CustomCurrency }}</span>
            </div>   
            <div class="update-order-amount">
              <span>Updated Order:</span>
              <span class="updated-order-am">{{ (totalAmount + editedOrderShippingCost) |
                  CustomCurrency }} </span>
            </div>
          </div>

          <div class="col-auto">
            <div class="update-order-btns row align-items-center justify-content-sm-between justify-content-center"
          [ngClass]="{'hide': cartList.length === 0 || completed }" *ngIf="selectedOrderNumber && !isCart">
          <div class="a-existing-o row align-items-center justify-content-sm-between justify-content-center float-right">
            <div class="btn-cancel-wrap col-auto">
              <button class="edit-order btn btn-cancel" (click)="cancel()" [disabled]="cartService.isPurchaseInProgress">Cancel</button>
            </div>
            <div class="col-auto ">
              <button [disabled]="findAllErrors || loading || cartService.isPurchaseInProgress || topService.loading"
               [ngClass]="{'disabled': errorCollection.size > 0}"
                      *ngIf="selectedOrderNumber"
                class="edit-order btn btn-update-order" (click)="placeNew()">
                <span *ngIf="loading" class="cart-loader"></span>
                <span *ngIf="!loading">Update Order</span>
                <span *ngIf="loading">Updating Order...</span>
              </button>
            </div>
          </div>
        </div>
          </div>
        </div>
      </div>

      <div class="col-md-6" [ngClass]="{ 'hide': isCartEmptyAndCompleted() }" *ngIf="!selectedOrderNumber || isCart">
        <div class="row flex-column justify-content-between align-items-md-end bottom-control-right">
          <div class="col-auto">
            <span class="discount-am" *ngIf="discount>0">
              Discount ({{discount}}%):
            </span>
            <span class="total-am" *ngIf="discount>0">
              -{{ ((totalAmount * (discount / 100))) | CustomCurrency }}
            </span>
          </div>
          <div class="bottom-control-right-auto">
            <div class="freight-wrap" *ngIf="selectedOrderNumber">
              <span>Freight: </span>
              <input type="number" class="freight-input" [(ngModel)]="editedOrderShippingCost" />
            </div>
            <div class="col-auto refresh-total-col">
              <a (click)="refresh()" class="cart-btn-rebranding"><i class="icon _refresh"></i><span class="text">Refresh <br/>Available</span></a>
              <div *ngIf="credit" class="processing-fee" [class.__show]="isTooltipShown">
                <div class="processing-fee-tooltip">
                You are indicating your acceptance and agreement with the foregoing terms and conditions, including a 3% Convenience Fee for all online purchases by card credit (incurred as a result of the ease and convenience associated with conducting this transaction online), and acknowledging that you are a legally authorized user of this credit card</div>
                <span class="processing-fee__title">Convenience Fee <i class="icon _info" 
                  (mouseover)="showProcessingTitle(true)"
                  (mouseleave)="showProcessingTitle(false)"></i></span>
                <span class="processing-fee__value">{{ processingFee | CustomCurrency }}</span>
              </div>
              <strong>
                Total Order:
                <span class="discount-am">
                  {{ (totalAmount + editedOrderShippingCost) | CustomCurrency }}
                </span>
              </strong>
            </div>
          </div>
          <div class="col-auto">
            <div class="a-existing-o row align-items-center justify-content-sm-between justify-content-center">
              <div class="col-auto">

                <div>
                  <button [disabled]="findAllErrors" [class.disabled]="findAllErrors" *ngIf="showAddToExistingOrderBtn()"
                    class="cart-btn blue" (click)="onShowModal()">
                  <span>Add to Existing Order</span>
                  <!-- <span *ngIf="loading" class="cart-loader"></span> -->
                  </button>
                </div>
                <a *ngIf="selectedOrderNumber" (click)="cancel()" class="edit-order btn btn-cancel">
                  Cancel
                </a>
              </div>
              <div class="col-sm-auto col-12 or">
                <span *ngIf="showAddToExistingOrderBtn()" [ngClass]="{'hide': !isCart}">OR</span>
              </div>
              <div class="col-auto">

                <button [disabled]="findAllErrors" [class.disabled]="findAllErrors"
                *ngIf="!isOutOfStock && showAddToExistingOrderBtn()"
                  (click)="reject()" class="cart-btn red">
                  Proceed to Checkout
                </button>
                <button
                   class="cart-btn red" [disabled]="findAllErrors || loading || !cartService.paymentType" *ngIf="!isOutOfStock && !showAddToExistingOrderBtn()"
                  (click)="placeNew()"
                  [ngClass]="{'disabled': findAllErrors, 'edit-order btn btn-update-order': !loading && selectedOrderNumber || loading && selectedOrderNumber}">
                  <span *ngIf="loading" class="cart-loader"></span>
                  <span *ngIf="!loading && !selectedOrderNumber">Place New Order</span>
                  <!-- <span *ngIf="loading && !selectedOrderNumber">Placing New Order...</span> -->
                  <span *ngIf="!loading && selectedOrderNumber">Update Order</span>
                  <!-- <span *ngIf="loading && selectedOrderNumber">Updating Order...</span> -->
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>

  <div class="order-comments-rebranding" [ngClass]="{ 'hide': cartList.length === 0 || completed, '_shown': isCommentsShown }">
    <textarea class="form-control order-comments-form" rows="1"
              [(ngModel)]="cartService.comment" placeholder="Add Comments Here"
              #myCommnet
              [maxlength]="commentsLength">{{comments}}</textarea>
  </div>

</div>


<div class="bottom-controls-wrapper mobile" *ngIf="cartList && topService.isMobile">
  <div class="bottom-controls">

      <div class="left-buttons-wrap" [ngClass]="{ 'hide': cartList.length === 0 || completed }">
        <a (click)="gotoShopping()" class="cart-btn-rebranding" [class.disabled]="cartService.isPurchaseInProgress">
          <i class="icon _back"></i></a>
          <a (click)="refresh()" class="cart-btn-rebranding"><i class="icon _refresh"></i><span class="text">Refresh <br/>Available</span>
        </a>

        <div class="total-fee" *ngIf="credit">
          <div class="total-fee-tooltip">
            <div class="total-fee-tooltip__text">
              <p>
                You are indicating your acceptance and agreement with the foregoing terms and conditions, 
                including a 3% Convenience Fee for all online purchases by card credit (incurred as a 
                result of the ease and convenience associated with conducting this transaction online), 
                and acknowledging that you are a legally authorized user of this credit card.
              </p>
            </div>
            <div class="total-fee-tooltip__btm">
              <div>
                <span class="total-fee-tooltip__btm-label">Convenience Fee</span>
                <span class="total-fee-tooltip__btm-value">{{ processingFee | CustomCurrency }}</span>
              </div>
              <div>
                <span class="total-fee-tooltip__btm-label">Total Order</span>
                <span class="total-fee-tooltip__btm-value">{{ (totalAmount + editedOrderShippingCost) | CustomCurrency }}</span>
              </div>
            </div>
          </div>
          <span *ngIf="credit" class="total-fee__title">Total Order + Fee<i class="icon _info"></i></span>
          <span class="total-fee__value">{{ (totalAmount + editedOrderShippingCost) | CustomCurrency }}</span>
        </div>

        <div class="total-order" *ngIf="!credit">
            Total Order:
            <span class="discount-am">
              {{ (totalAmount + editedOrderShippingCost) | CustomCurrency }}
            </span>
        </div>

      </div>

      <div class="bottom-control" [ngClass]="{ 'hide': isCartEmptyAndCompleted() }" *ngIf="!selectedOrderNumber || isCart">

            <div class="bottom-control-wrap">
                  <button [disabled]="findAllErrors" [class.disabled]="findAllErrors" *ngIf="showAddToExistingOrderBtn()"
                    class="cart-btn blue" (click)="onShowModal()">
                  <span>Add to Existing Order</span>
                  </button>

                  <button [disabled]="findAllErrors" [class.disabled]="findAllErrors"
                  *ngIf="!isOutOfStock && showAddToExistingOrderBtn()"
                    (click)="reject()" class="cart-btn red">
                    Proceed to Checkout
                  </button>

                  <button
                     class="cart-btn red" [disabled]="findAllErrors || loading || !cartService.paymentType || topService.loading" 
                     *ngIf="!isOutOfStock && !showAddToExistingOrderBtn()"
                    (click)="placeNew()"
                    [ngClass]="{'disabled': findAllErrors, 'edit-order btn btn-update-order': !loading && selectedOrderNumber || loading && selectedOrderNumber}">
                    <span *ngIf="loading" class="cart-loader"></span>
                    <span *ngIf="!loading && !selectedOrderNumber">Place New Order</span>
                    <span *ngIf="!loading && selectedOrderNumber">Update Order</span>
                  </button>
            </div>

      </div>

  </div>

</div>

<div *ngIf="showTerms">
  <app-credit-card-terms (agreementEmit)="sendAgreement($event)" (close)="showTerms=false;"></app-credit-card-terms>
</div>

<app-error-modal [bodyText]="errorModal.bodyText" [title]="errorModal.title" [isError]="errorModal.isShown"
(onConfirmError)="hideError()"></app-error-modal>