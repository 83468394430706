<div *ngIf="preCartItem" class="ok" [class.active]="index === showDetailsActive" [class.last-item]="(index === (cartService.cartList.length - 1)) && cartService.itemAdded">
  <div class="pc-item-wrap">
    <div class="pc-item col-md-12 row">
      <div [hidden]="user_role === 'SALES'  && preCartItem.orderLineNum !== -1  " class="pc-close" (click)="!topService.loading?deleteItem():''"
        [class.spinner]="deleteSpinner && deleteSpinnerItem === preCartItem.id">
        <img src="assets/images/deleteRed.svg" height="24" width="24" />
      </div>
      <div (click)="showDetails(index)" class="col-md-7 item-descr">{{truncateDescription(preCartItem.item.description, 90)}}</div>
      <div class="col-md-2 pc-item-price">Price
        <br />{{ (preCartItem.overwrittenPrice ? preCartItem.overwrittenPrice:preCartItem.unitPrice) | CustomCurrency }}</div>
      <div class="col-md-3 pc-item-qty">
        <span class="pre-cart-qty-text">QTY</span>
        <br />
        <input [disabled]="isQtyEditable(preCartItem)" type="number" [min]="preCartItem.item.moq" (keyup)="updateQty(preCartItem, qty.value)"
          [max]="preCartItem.item.inventory.availableToSell" step="1" [(ngModel)]="preCartItem.quantity" appOnlyDigits
          [value]="preCartItem.quantity" (focus)="handleText(preCartItem, qty.value)" #qty />
      </div>
    </div>
    <div class="col-md-12 pc-item-dd row" *ngIf="index === showDetailsActive">
      <div class="col-md-12 row pc-item-wraps">
        <div class="col-md-7 pc-item-q">
          <span class="font-weight-bold">Part:</span>
          <span class="cart-item-code">{{preCartItem.itemCode}}</span>
        </div>
        <div class="col-md-5 available-wrap">
          <span class="font-weight-bold">Available:</span> {{preCartItem.item.inventory.availableToSell | inventoryLimit}}
        </div>
      </div>
    </div>
  </div>
</div>
