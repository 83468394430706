import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pending-sold-empty',
  templateUrl: './pending-sold-empty.component.html',
  styleUrls: ['./pending-sold-empty.component.scss']
})
export class PendingSoldEmptyComponent implements OnInit {
	items = [];

  constructor() { }

  ngOnInit() {
  	this.items.length = 8;
  }

}
