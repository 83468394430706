export enum OfferStatusEnum {
  disabled = 0,
  enabled = 1,
  disabledNoEnoughInventoryAndPricebasedOnCurrentRules = 2,
  disabledItemSoldOut = 6,
  disabledVendorDoesntAcceptOffers = 8,
  offerMade = 16,
  disabledItemStatusChanged = 32,
  disabledItemPriceChanged = 64,
  disabledOffersRestricted = 128
}

export enum OfferItemStatus {
  vendorDisabled,
  itemDeactivated,
  itemSoldOut
}

