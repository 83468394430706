import { environment } from './../../../../environments/environment';
import { IBidDesk } from './../../interfaces/IBidDesk';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BiddeskBannerService {
  isBiddeskModalShown = false;
  isBidSubmited = false;


  constructor(private http: HttpClient) { }

  sendBidDesk(bid: IBidDesk): Observable<any> {
    const url = `${environment.buyerPortalBaseUrl}biddesks`;
    return this.http.post<any>(url, bid);
  }
}