import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TopService } from '../../shared/services/top.service';

@Component({
  selector: 'app-nothing-found-buyer',
  templateUrl: './nothing-found-buyer.component.html',
  styleUrls: ['./nothing-found-buyer.component.scss']
})
export class NothingFoundBuyerComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() buttonText: string;
  @Input() loading: boolean;
  @Output() clickEmitter = new EventEmitter<null>();

  constructor(
    public topService: TopService
    ) { }

  ngOnInit() {
  }

  clickEvent(): void {
    if (!this.loading) {
      this.clickEmitter.emit();
    }
  }
}
