import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { CartComponent } from '../buyer/cart/cart.component';

@Injectable()
export class CanDeactivateCartGuard implements CanDeactivate<CartComponent> {
  canDeactivate(component: CartComponent): boolean {

    if (!component.purchaseCompleted) {
        return false;
    }
    return true;
  }
}
