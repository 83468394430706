import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { AuthService } from './auth/auth.service';
import { SharedService } from './shared/shared.service';
import { environment } from '../environments/environment';
import { AuctionsService } from './buyer/auctions/auctions.service';
import { ItemsService } from './shared/services/items.service';
import { GlobalSearchService } from './services/search.service';
import { SessionService } from './services/session.service';
import { CartService } from './buyer/cart/cart.service';
import { UserService } from './user/user.service';
import { TopService } from './shared/services/top.service';
import { ConnectionManagerService } from './shared/services/signalR/connection-manager.service';
import { eConnectionType } from './shared/services/signalR/connection-type';
import { AuctionSignalrService } from './buyer/auctions/signalr/auction-signalr.service';
import { LoaderService } from './shared/services/loader.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

declare let gtag: Function;
export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  subscriptions: Subscription[] = [];
  constructor(
    public userService: UserService,
    public topService: TopService,
    private router: Router,
    private auth: AuthService,
    public sharedService: SharedService,
    private _auctionsService: AuctionsService,
    private _searchService: GlobalSearchService,
    private _sessionService: SessionService,
    private _cartService: CartService,
    private _itemsService: ItemsService,
    private connManagerService: ConnectionManagerService,
    private auctionSignalrService: AuctionSignalrService,
    private loaderService: LoaderService,
    private gtmService: GoogleTagManagerService
  ) {

    this.connManagerService.addConnection(eConnectionType.NewVersion);

    if (environment.maintenanceMode) {
      this.userService.logout();
    }

    this.auth.handleAuthentication();
    this.auth.scheduleRenewal();

    this._itemsService.navigationTreeAndManus();

    // this._itemsService.getConditionMapping();

    this.subscriptions.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          // preventing user with expired or no token to navigate anywhere but login page
          if (!this.auth.isAuthenticated() && this._sessionService._is_logged_in === 'true' &&
            this.router.url !== '/' && event.url !== this.router.url
            && !['/login', '/guest/home', '/'].includes(event.url)) {
            this._itemsService.isLandingPage = true;
            this._cartService.resetCart();
            this.userService.logout();
            return;
          }
          if (this.auth.isAuthenticated() && event.url.search(/((buyer\/.)|(outlets\/.)|(cart))/g) >= 0 &&
            this._sessionService.userCanBuy) {
            this.userService.checkCreditCardListEmpty();
          }
        }
        if (event instanceof NavigationEnd) {
          gtag('config', environment.googleAnalyticsAppId, { 'page_path': event.urlAfterRedirects });
          gtag('set', { 'user_id': sessionStorage.getItem('user_id') });
        }
        if (event instanceof NavigationStart) {
          browserRefresh = !router.navigated;
        }
      })
    );

    this.subscriptions.push(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        const corpSiteUrls = [
          '/corphome', '/products', '/sell', '/aboutUs', '/faq', '/contact-support'
        ];
        this.sharedService.isCorpSite = corpSiteUrls.includes(this.router.url) ? true : false;
      })
    );




    this.auctionSignalrService.initialize();
    this.isMobile();

    this.gtmService.addGtmToDom();
  }

  isMobile() {
    this.topService.isMobileMode();
  }
}
