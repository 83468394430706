import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import {
  NotificationTypesEnum,
  eNotificationAction,
  NotificationFiltersEnum,
  NotificationSortingDirection
} from '../../shared/interfaces/INotificationDetail';
import { INotificationBuyerRequest } from './notification-center.component';
import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';


@Injectable({
  providedIn: 'root'
})
export class NotificationCenterBuyerService {
  showHeaderMobile = false;
  activeTab = NotificationFiltersEnum.all;
  sortDirection = NotificationSortingDirection.desc;

  constructor(
    private http: HttpClient,
    private pubSubService: PubSubService) {
  }

  notifyMeList(action: eNotificationAction, activeTab: NotificationFiltersEnum, items: any[]): Observable<any> {
    let requestObjs = [];
    items.forEach(item => {
      //filter notification with timestamp and isnew aren't equals to null (bussiness logic)
      let notificationDetails = item.notificationDetails.filter(notificationDetail => notificationDetail.timeStamp != null && notificationDetail.isNew != null);
      if(activeTab !== NotificationFiltersEnum.all && activeTab !== NotificationFiltersEnum.archive){
        notificationDetails = item.notificationDetails.filter(notificationDetail => notificationDetail.notificationType == this.convertNotificationFiltersToNotificationType(activeTab));
      }
      //preparing the object
      notificationDetails.forEach(notificationDetail => {
        let tempObj = {
          itemId: item.id,
          vendorId: null,
          actionType: action,
          notificationType: notificationDetail.notificationType
        };
        requestObjs.push(tempObj);
      });
    });
    
    const url = environment.adminUrl + 'notifications';
    return this.http.post<any>(url, requestObjs).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  notifyMe(notificationType: NotificationTypesEnum, action: eNotificationAction, itemId: string): Observable<any> {
    const requestObj = {
      itemId: itemId,
      vendorId: null,
      actionType: action,
      notificationType: notificationType
    };
    const url = environment.adminUrl + 'notifications';
    return this.http.post<any>(url, new Array(requestObj)).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  set markForNotificatonForCheck(itemId:string){
    this.pubSubService.sharedSubject.next({ name: SharedSource.refreshNotificationItem, data: itemId });
  }

  getNotifications(notificationRequest: INotificationBuyerRequest): Observable<any> { // expected: list of item model extended
    const url = environment.buyerPortalBaseUrl + 'notifications';
    return this.http.post<any>(url, notificationRequest).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getAllNotificationItemsTotals(): Observable<any> {
    const totals = '/api/notifications/totals';
    const url = environment.notificationCenterUrl + totals;
    return this.http.get<any>(url);
  }

  markNotificationsAsRead(notifType: NotificationTypesEnum): Observable<any> {
    const url = environment.buyerPortalBaseUrl + 'notifications/seen';
    const obj = {notificationType: notifType};
    return this.http.post<any>(url, obj).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getHeaderCounters(): Observable<any> {
    const url = environment.buyerPortalBaseUrl + 'notifications/totals';
    return this.http.get<any>(url);
  }

  getNotificationType(filter: NotificationFiltersEnum): NotificationTypesEnum {
    if (filter === NotificationFiltersEnum.priceDrop) {
      return NotificationTypesEnum.priceDrop;
    } else if (filter === NotificationFiltersEnum.moreInventory) {
      return NotificationTypesEnum.moreInventory;
    } else if (filter === NotificationFiltersEnum.waitlist) {
      return NotificationTypesEnum.waitlist;
    }
    return null;
  }

  convertNotificationFiltersToNotificationType(notificationFilter: NotificationFiltersEnum){
    switch(notificationFilter){
      case NotificationFiltersEnum.moreInventory:
        return NotificationTypesEnum.moreInventory;
      case NotificationFiltersEnum.priceDrop:
        return NotificationTypesEnum.priceDrop;
      case NotificationFiltersEnum.waitlist:
        return NotificationTypesEnum.waitlist;
    }
  }
}
