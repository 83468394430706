<div class="vendor-home-wrap" *ngIf="!topService.isMobile">
	<div class="vendor-home">
		<div class="left-menu-wrap">
			<app-left-menu></app-left-menu>
		</div>
		<div class="content-wrap">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
<div class="vendor-home-mobile" [class.selected-offer]="offerService.selectedOffer?.id" [class.offers-list]="isOfferListPage" *ngIf="topService.isMobile">
	<div class="vendor-home">
		<router-outlet></router-outlet>
	</div>
	<div class="vendor-home-back-wrap" *ngIf="!isOfferListPage || (isOfferListPage && offerService.selectedOffer?.id) ">
		<a class="vendor-home-back__btn" (click)="goToOffers()">
			<i class="icon">
				<img src="assets/images/icons/24-back-black.svg" width="24" height="24" alt="Back" />
			</i>
			<span>Back to Offers</span>
		</a>
	</div>
</div>
<app-loader></app-loader>

<app-mobile-notifications-modal *ngIf="showPhonePopup()" (closeModal)="closePhonePopup()"></app-mobile-notifications-modal>