import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import {
  DashboardService
} from '../dashboard.service';
import {
  ChartComponent
} from 'angular2-highcharts';
import {
  ListingSkuStatusNamesEnum
} from '../../enums/listing-sku-status';
import {
  Router
} from '@angular/router';
import {
  SharedService
} from '../../../shared/shared.service';
import {
  VendorService
} from '../../vendor.service';
import {
  IChartSeriesData
} from '../../interface/IChartSeriesData';
@Component({
  selector: 'app-listing-details',
  templateUrl: './listing-details.component.html',
  styleUrls: ['./listing-details.component.css']
})
export class ListingDetailsComponent implements AfterViewInit {
  @ViewChild('chartComp', { static: true }) chartComp: ChartComponent;
  chart: any;
  isLoading: boolean = false;
  options: Object;
  listingStatus = ListingSkuStatusNamesEnum;
  defaultChartData: IChartSeriesData[] = [{
    name: ListingSkuStatusNamesEnum.active,
    y: 48,
    color: '#79BD5A'
  }, {
    name: ListingSkuStatusNamesEnum.inactive,
    y: 24,
    color: '#1A1A1A'
  }, {
    name: ListingSkuStatusNamesEnum.paused,
    y: 18,
    color: '#F5C854'
  }, {
    name: ListingSkuStatusNamesEnum.soldOut,
    y: 5,
    color: '#EB3B44'
  }, {
    name: ListingSkuStatusNamesEnum.removed,
    y: 5,
    color: '#4687D6'
  }, {
    name: ListingSkuStatusNamesEnum.archived,
    y: 0,
    color: '#B4B4B4'
  }];

  constructor(private dashboardService: DashboardService,
    public sharedService: SharedService,
    private vendorService: VendorService,
    private router: Router) {

    this.options = {
      chart: {
        type: 'pie',
        borderColor: 'transparent',
        backgroundColor: '#F6F6F6',
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          events: {
            click: (event: MouseEvent) => this.seriesClicked(event)
          },
          enableMouseTracking: true,
          dataLabels: {
            enabled: false
          },
          states: {
            hover: {
              enabled: true
            }
          }
        }
      },
      tooltip: {
        enabled: true,
        headerFormat: '',
        pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name} </b>' +
          '<b> {point.y}</b><br/>'
      },
      series: [{
        innerSize: '70%',
        borderWidth: 0,
        name: 'Listings Details',
        data: this.defaultChartData
      }]
    };

    this.vendorService.onBrowserRefreshEvent()
    .subscribe(event => {
      if (event.id === 1 && event.url === event.urlAfterRedirects && this.vendorService.isVendorManager ) {
        this.getChartData();
      }
    })

  }

  ngAfterViewInit() {
    if (this.chartComp) {
      this.chart = this.chartComp.chart;
    }
    if (!this.vendorService.isVendorManager || this.vendorService.isImpersonationReady) {
      this.getChartData();
    }
  }

  getChartData() {
    this.isLoading = true;
    this.vendorService.getStatusCounts({})
      .subscribe(statuses => {
        this.dashboardService.listingStatuses = statuses;
        this.dashboardService.vendorHasItems = !!statuses.some(status => status.count > 0);

        if (this.dashboardService.vendorHasItems) {
          this.updateChartData();
        }
        this.isLoading = false;
      }, (err) => {
        this.isLoading = false;
        console.log('Error getting listing details chart data', err);
      });
  }

  getCountByStatus(statusName: ListingSkuStatusNamesEnum) {
    if (!this.dashboardService.listingStatuses && !this.dashboardService.listingStatuses.length) {
      return 0;
    }
    const status = this.dashboardService.listingStatuses.find(stat => stat.name === statusName);
    if (status) {
      return status.count;
    }
    return 0;
  }

  updateChartData() {
    const newData: IChartSeriesData[] = JSON.parse(JSON.stringify(this.defaultChartData));

    newData.map(serie => {
      serie.y = this.getCountByStatus(serie.name);
      return serie;
    });

    this.chart.series[0].setData(newData);
  }

  hideSeries(serie: ListingSkuStatusNamesEnum) {
    if (this.chart && this.chart.series[0]) {
      const selectedSeries = this.chart.series[0].data.find(p => p.name === serie);

      if (selectedSeries) {
        selectedSeries.visible ? selectedSeries.setVisible(false) : selectedSeries.setVisible(true);
      }
    }
  }

  seriesClicked(event) {
    const seriesName: string = (event && event.point) ? event.point.name : '';
    this.router.navigate(['/vendor/items/status/' + seriesName]);
  }

}
