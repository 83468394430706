<div class="item">
  <div class="item-checkbox"></div>
  
  <div class="item-logo"></div>

  <!-- part number and status -->
  <div class="item-part-number">
    <span class="item-label"></span>
    <span class="item-input"></span>
    <span class="item-input"></span>
  </div>

  <!-- description -->
  <div class="item-description">
    <span class="item-label"></span>
    <span class="item-input"></span>
  </div>

  <!-- available/eta -->
  <div class="item-available-eta">
    <div class="item-col _indent">
      <span class="item-label"></span>
      <span class="item-input"></span>
    </div>
    <div class="item-col _group-item-indent">
      <span class="item-label"></span>
      <span class="item-input"></span>
    </div>
  </div>

  <!-- discounts -->
  <div class="item-dicsount item-col _indent">
    <span class="item-label"></span>
    <span class="item-input _high"></span>
  </div>
  <div class="item-dicsount item-col _group-item-indent">
    <span class="item-label"></span>
    <span class="item-input _high"></span>
  </div>
  <div class="item-dicsount item-col _group-item-indent">
    <span class="item-label"></span>
    <span class="item-input _high"></span>
  </div>
  
  <div class="item-right">
    <div class="item-right-top">
      <div class="item-moq-mxq item-col _indent">
        <span class="item-label"></span>
        <span class="item-input"></span>
      </div>
      <div class="item-moq-mxq item-col _group-item-indent">
        <span class="item-label"></span>
        <span class="item-input"></span>
      </div>
      <div class="item-col _group-item-indent">
        <span class="item-label"></span>
        <span class="item-input"></span>
      </div>
      <div class="item-col _group-item-indent">
        <span class="item-label"></span>
        <span class="item-input"></span>
      </div>
    </div>
    <div class="item-right-btm">
      <div class="item-right-btm__labels">
        <span class="item-label"></span>
        <span class="item-label"></span>
      </div>
      <span class="item-btn">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
          <path d="m6.00421756 12c0 3.3145476 2.685161 6 5.99819244 6 3.3130315 0 5.9981925-2.6854524 5.9981925-6 0-2.93735818-2.1307842-5.42920384-4.9978674-5.90946034l-.3577692-.05992884v-3.01214526l.4814055.05980358c4.4775011.55622674 7.8736286 4.37074396 7.8736286 8.92173086 0 4.9716573-4.0279056 9-8.99759 9-4.96968431 0-8.99758993-4.0283427-8.99758993-9 0-2.08356377.71259734-4.052563 1.99325746-5.63828975l-1.39602921-1.39460219-.10570299-.10190197c-.06353372-.06205206-.11475278-.11449547-.16190711-.16674877-.21437519-.23755639-.33443822-.46158458-.33443822-.73417161 0-.53166627.43160298-.96428571.96413509-.96428571h5.35563658c.14451306 0 .28211456.01920102.44139649.09157803.32099494.14585869.52273864.45091876.52273864.87270768v5.35714286c0 .53154704-.43202033.96428573-.96413513.96428573-.32186896 0-.51778503-.1144239-.7985355-.39298086l-.23290517-.23937411-1.14870224-1.14942643c-.73244391 1.01204442-1.1354112 2.2226953-1.1354112 3.4960671z" fill="#D8D8D8"/>
        </svg>
      </span>
      <span class="item-btn">
        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="m20.0590831 5.34740573 1.5154151 1.30518854-11.2788541 13.09553753-6.94434539-5.9192518 1.29740258-1.5220862 5.42929871 4.6282062z" fill="#D8D8D8"/>
        </svg>
      </span>
      <span class="item-btn _bordered">
        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="m19 6.41-1.41-1.41-5.59 5.59-5.59-5.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59z" fill="#D8D8D8"/>
        </svg>
      </span>
    </div>
  </div>
</div>