import { SharedSource } from './../../core/shared-source';
import { PubSubService } from '../../core/pubsub.service';
import { UserService } from './../user.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { OfferService } from '../offers/offer.service';
import { Subscription } from 'rxjs';
import { browserRefresh } from '../../app.component';

@Component({
  selector: 'app-user-navigation',
  templateUrl: './user-navigation.component.html',
  styleUrls: ['./user-navigation.component.scss']
})

export class UserNavigationComponent implements OnInit, OnDestroy {
  @Input() isOfferPage:boolean = false;
  userRole = sessionStorage.getItem('user_role');
  subscriptions: Subscription[] = [];
  isSuperOrSaleImpersonating = null;
  constructor(
    public sessionService: SessionService,
    public userService: UserService,
    public offerService: OfferService,
    private pubSubService: PubSubService) {
  }

  ngOnInit() {
    this.subscribeToPubService();
    if (!browserRefresh) {
      this.handleBPUnseenEventCounter();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.offerService.bpUnseenEventCount = 0;
  }

  isBuyerPortal(): boolean {
    return ['BUYER', 'SALES', 'SUPER'].includes(this.userRole);
  }

  subscribeToPubService() {
    this.subscriptions.push(
      this.pubSubService.sharedSubject.subscribe(
        (sharedValue) => {
          switch (sharedValue.name) {
            case SharedSource.sessionSwitchedAndSummaryReady:
              this.handleBPUnseenEventCounter();
              break;

            default:
              break;
          }
        }
      ));
  }

  handleBPUnseenEventCounter() {
    this.isSuperOrSaleImpersonating = ['SUPER', 'SALES'].includes(this.userRole) && sessionStorage.getItem('isCustomerSelected') == 'YES';
    if (this.isSuperOrSaleImpersonating) {
      this.offerService.getBpUnseenOffersCounterNoStream();
    }
  }

}
