import { Routes } from '@angular/router';
import { PageErrorComponent } from './page-error/page-error.component';
import { BuyerHomeComponent } from './buyer/buyer-home/buyer-home.component';
import { LoginComponent } from './user/login/login.component';

const isGuest = !sessionStorage.getItem('user_role') ||
['GUEST', 'null'].includes(sessionStorage.getItem('user_role'));

export const ROUTES: Routes = [
  {
    path: 'home',
    redirectTo: `${(isGuest) ? '/guest' : '/buyer'}/home${window.location.search}`,
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: '/guest/home',
    pathMatch: 'full'
  },
  {
    path: 'vendor-login',
    component: LoginComponent
  },
  {
    path: 'login',
    redirectTo: '/guest/home',
    pathMatch: 'full'
  },
  {
    path: 'buyer/buyer-home',
    redirectTo: '/guest/home',
    pathMatch: 'full'
  },
  {
    path: 'buyer-home',
    redirectTo: '/guest/home',
    pathMatch: 'full'
  },
  {
    path: 'page-error',
    component: PageErrorComponent
  },
  {
    path: 'user_filters/:id',
    redirectTo: `${(isGuest) ? '/guest' : '/buyer'}/user_filters/:id`,
    pathMatch: 'full'
  },
  {
    path: 'items/:code',
    component: BuyerHomeComponent
  },
  {
    path: 'items/:code/:code',
    component: BuyerHomeComponent
  },
  {
    path: ':main',
    component: BuyerHomeComponent
  },
  {
    path: ':main/:sub',
    component: BuyerHomeComponent
  },
  {
    path: '**',
    component: PageErrorComponent
  }
];
