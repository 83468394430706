<div class="purchase" *ngIf="item">
	<div class="row align-items-center">
		<div class="col-auto purchase-icon">
			<img width="40" height="40" src="assets/images/purchases/listing-past-purchase.svg">
		</div>
		<div class="col-auto">
			<div class="purchase-number">You've ordered this before</div>
			<div class="purchase-order"
			(click)="serachInPastPurchase(item.pastPurchaseInfo.documentNumber)">
				Order #{{ item.pastPurchaseInfo.documentNumber }}
			</div>
		</div>
		<div class="col-auto text-center">
			<div class="name">Date</div>
			<div class="value">{{ item.pastPurchaseInfo.purchaseDate | date:'shortDate' }}</div>
		</div>
		<div class="col-auto text-center">
			<div class="name">Total Units</div>
			<div class="value">
				<span>{{ item.pastPurchaseInfo.quantitySold }}</span>
			</div>
		</div>
		<div class="col-auto">
			<div class="name">Purchase Price</div>
			<div class="value">{{ item.pastPurchaseInfo.priceSold | currency:'USD':'symbol-narrow' }}</div>
		</div>
		<div class="col-auto">
			<div class="name">Total</div>
			<div class="value">{{ item.pastPurchaseInfo.priceSold * item.pastPurchaseInfo.quantitySold | currency:'USD':'symbol-narrow' }}</div>
		</div>
		<div class="col-auto">
			<div class="name">Part</div>
			<div class="value part-number"
			(click)="serachInPastPurchase(item.partNumber)">{{ item.partNumber }}</div>
		</div>
	</div>
</div>

