<div class="item-wrapper" (click)="openEditDetailedMode()" *ngIf="vendorService.isUser(['SELLER', 'ADMIN', 'SUPER'])" (mouseover)="updateRowStyle(true)" (mouseout)="updateRowStyle(false)" [ngClass]="{'rowOnHover': addHighlight, 'active': item.selected}">
	<div class="item-part-number">
		<a role="button" class="item-detail" [ngClass]="{'active': item.selected}">
			<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
				<g id="plus-grid" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<polygon id="+" points="13.2712871 10.7287129 18 10.7287129 18 13.2712871 13.2712871 13.2712871 13.2712871 18 10.7287129 18 10.7287129 13.2712871 6 13.2712871 6 10.7287129 10.7287129 10.7287129 10.7287129 6 13.2712871 6"></polygon>
				</g>
			</svg>
		</a>
		<div class="input-gorizontal" (click)="$event.stopPropagation()">
			<input type="text" class="form-control disableMe" readonly="readonly" [tooltip]="item.seller.name" [(ngModel)]="item.vendorItemCode" (click)="copyText(item.vendorItemCode)">
		</div>
	</div>
	<div class="item-attribute-column">
		<div class="item-attribute-descriptipn">
			{{ item.status.overallStatus }}
		</div>
	</div>
	<div class="item-attribute-column">
		<div class="item-attribute-descriptipn">
			{{ item.lastActivatedOn | date:'shortDate' }}
		</div>
	</div>
	<div class="item-attribute-column">
		<div class="item-attribute-descriptipn">
			{{ item.lastUpdatedOn | date:'shortDate' }}
		</div>
	</div>
	<div class="item-attribute-column">
		<div class="item-attribute-descriptipn">
			{{ item.inventoryInfo.currentInventory.availableToSell }}
		</div>
	</div>
	<div class="item-attribute-column">
		<div class="item-attribute-descriptipn">
			{{ item.salesInfo.totalSale }}
		</div>
	</div>
	<div class="item-attribute-column">
		<div class="item-attribute-descriptipn">
			{{ item.salesInfo.unitsShipped }}
		</div>
	</div>
	<div class="item-attribute-column">
		<div class="item-attribute-descriptipn">
			{{ item.salesInfo.revenue | currency:'USD':'symbol-narrow' }}
		</div>
	</div>
	<div class="item-attribute-column">
		<div class="item-attribute-descriptipn">
			{{ item.salesInfo.averageCost | currency:'USD':'symbol-narrow' }}
		</div>
	</div>
	<div class="item-attribute-column">
		<div class="item-attribute-descriptipn">
			{{ item.salesInfo.averagePrice | currency:'USD':'symbol-narrow' }}
		</div>
	</div>
</div>
