import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ItemsService } from '../../shared/services/items.service';
import { IExw } from '../interfaces/IExw';
import { forkJoin, BehaviorSubject, Subscription, Observable, of } from 'rxjs';
import { Attribute, Category } from '../interfaces/model';
import { Router } from '@angular/router';
import { TopService } from '../../shared/services/top.service';
import * as models from '../../buyer/interfaces/model';
import { IManufacturer } from '../interfaces/IManufacturer';
import { MappedUserFilter } from '../interfaces/MappedUserFilter';
import { UserFilter } from '../interfaces/UserFilter';
import { ITopNav } from '../interfaces/ITopNav';
import { IUserFilter } from '../interfaces/IUserFilter';

@Injectable({
  providedIn: 'root'
})
export class UserFiltersService {
  manufacturersOutlet: models.Attribute[] = [];
  private _filteredUserList: BehaviorSubject<UserFilter[]> = new BehaviorSubject([]);
  private _userFilterList: UserFilter[];
  filterSubscription: Subscription;
  selectedUserFilter: UserFilter;
  loading: boolean;

  set userFilterList(list) {
    this._userFilterList = list;
    this._filteredUserList.next(list);
  }

  get userFilterList(): UserFilter[] {
    return this._userFilterList;
  }

  get filteredUserList$() {
    return this._filteredUserList.asObservable();
  }


  constructor(private http: HttpClient,
    private router: Router,
    private itemsService: ItemsService,
    private topService: TopService) { }

  getUserFilterById(id: string) {
    const url = environment.userFilterUrl + `/${id}`;
    return this.http.get<any>(url);
  }

  getUserFilters() {
    this.loading = true;

    const payload = this.itemsService.getEmptyRequestPayload();
    const filters$ = this.http.get<any>(environment.userFilterUrl);
    const attributes$ = (this.itemsService._allAttributes?.length) ?
      of([...this.itemsService._allAttributes]) :
      this.http.post<Attribute[]>(environment.buyerPortalBaseUrl + 'items/filter/attributes', payload);

    const categories$ = this.http.post<Category[]>(environment.buyerPortalBaseUrl + 'items/filter/categories', payload);

    const manufacturers$ = (this.itemsService.itemFilterService.allManufacturers?.length) ?
      of([...this.itemsService.itemFilterService.allManufacturers]) :
      this.http.post<Attribute[]>(environment.buyerPortalBaseUrl + 'items/filter/manufacturers', payload);

    const exw$ = (this.itemsService._allExwPoints?.length) ?
      of([...this.itemsService._allExwPoints]) :
      this.http.post<IExw[]>(environment.buyerPortalBaseUrl + 'items/filter/exwPoints', payload);

    this.filterSubscription =
      forkJoin([filters$, attributes$, categories$, manufacturers$, exw$])
        .subscribe(
          ([filters, attributes, categories, manufacturers, exw]) => {
            // we need this data to show redable summary of filters in the list
            this.itemsService.allAttributes = attributes;
            this.itemsService.allCategories = categories;
            this.itemsService.itemFilterService.allManufacturers = manufacturers;
            this.itemsService.allExwPoints = exw;

            this.userFilterList = this.transformFilters(filters);
            this.loading = false;
            this.filterSubscription = undefined;
          },
          (err) => {
            this.loading = false;
            this.filterSubscription = undefined;
          });
  }

  getUserFiltersListOnly() {
    this.loading = true;
    const filters$ = this.http.get<any>(environment.userFilterUrl);
    filters$.subscribe(filters => {
      this.userFilterList = this.transformFilters(filters);
      this.loading = false;
    });
  }

  transformFilters(rawFilterList: any) {
    rawFilterList.forEach(uF => {
      const parsedFilter = JSON.parse(uF.filter);
      uF.filter = parsedFilter;
      uF.mappedFilter = this.itemsService.transFormFilter(parsedFilter);
      uF.redableText = this.stringifyFilter(uF.mappedFilter);
    });
    return rawFilterList;
  }

  stringifyFilter(filter: MappedUserFilter) {
    let textString = '';

    if (filter.selectedPromotion) {
      textString += filter.selectedPromotion.name + ' • ';
    }

    if (filter.selectedCategory) {
      textString += filter.selectedCategory.name + ' • ';
    }
    if (filter.selectedManufacturer) {
      textString += filter.selectedManufacturer.name + ' • ';
    }

    if (filter.priceStr) {
      textString += filter.priceStr + ' • ';
    }

    if (filter.selectedCondition) {
      textString += filter.selectedCondition.value + ' • ';
    }
    if (filter.selectedExw) {
      textString += filter.selectedExw.text;
    }

    return textString.replace(/ • $/, '');
  }

  navigateToItems(userFilter: UserFilter) {
    if (this.selectedUserFilter && userFilter.id === this.selectedUserFilter.id) {
      this.resetCurrentFilter();
      return;
    }
    this.itemsService.userFilterModalOpen = false;
    this.topService.searchString = '';
    this.filterListByName('');
    this.itemsService.initializeManufacturerData([]);
    this.itemsService.pageNavigationByManufaturers = []; // reset breakdowns per manufacturer
    this.itemsService.currentManufacturerNav = []; // outlets 2nd level cats
    this.itemsService.itemFilterService.clearAll(undefined, true);
    this.selectedUserFilter = userFilter;
    delete this.selectedUserFilter.filter.savedFilter;
    this.router.navigate([`user_filters/${userFilter.id}`]);
  }

  filterListByName(searchQuery: string) {
    const searchReg = new RegExp(searchQuery, 'i');
    const newList = (this.userFilterList) ?
      this.userFilterList.filter(f => f.name.match(searchReg)) : [];
    this._filteredUserList.next(newList);
  }

  filterCalledFromUserPage(): boolean {
    if (this.router.url === '/user/filters') {
      return true;
    }
    return false;
  }

  getManufacturersConditionsExws() {
    const payload = this.itemsService.getEmptyRequestPayload();

    const exw$ = (this.itemsService._allExwPoints?.length) ?
      of([...this.itemsService._allExwPoints]) :
      this.http.post<IExw[]>(environment.buyerPortalBaseUrl + 'items/filter/exwPoints', payload);

    const attributes$ = (this.itemsService._allAttributes?.length) ?
      of([...this.itemsService._allAttributes]) :
      this.http.post<Attribute[]>(environment.buyerPortalBaseUrl + 'items/filter/attributes', payload);

    const manufacturers$ = (this.itemsService.itemFilterService.allManufacturers?.length) ?
      of([...this.itemsService.itemFilterService.allManufacturers] as IManufacturer[]) :
      this.http.post<IManufacturer[]>(environment.buyerPortalBaseUrl + 'items/filter/manufacturers', payload);
    return forkJoin([attributes$, exw$, manufacturers$]);
  }

  createUserFilter(payload: IUserFilter): Observable<IUserFilter> {
    return this.http.post<IUserFilter>(environment.userFilterUrl, payload);
  }

  getUserFilter(userFilterId: string): Observable<IUserFilter> {
    const url = `${environment.userFilterUrl}/${userFilterId}`;
    return this.http.get<IUserFilter>(url);
  }

  getCategoriesOutlet(manufacturerId: string): Observable<ITopNav> {
    const url = `${environment.buyerPortalBaseUrl}items/filter/outletNavigation/${manufacturerId}`;
    return this.http.get<ITopNav>(url);
  }

  deleteUserFilter(id) {
    const url = environment.userFilterUrl + '/' + id;
    this.http.delete(url)
      .subscribe(() => {
        this.getUserFiltersListOnly();
      });
  }

  getCategoryIdByNavigationId(userFilterId: string): Observable<Category[]> {
    const payload = this.itemsService.getEmptyRequestPayload();
    payload.savedFilter = userFilterId;

    const url = `${environment.buyerPortalBaseUrl}items/filter/categories`;
    return this.http.post<Category[]>(url, payload);
  }

  getAttributes(savedFilter: string): Observable<Attribute[]> {
    const payload = this.itemsService.getEmptyRequestPayload();
    payload.savedFilter = savedFilter;
    const url = environment.buyerPortalBaseUrl + 'items/filter/attributes';
    return this.http.post<Attribute[]>(url, payload);
  }

  getNavigationIdByCategoryId(category: ITopNav): string {
    if (!category) {
      return '';
    }
    const navigationTree = this.itemsService.navigationTree;
    let navigationId = category.navId;

    outerLoop:
    for (let i = 0; i < navigationTree.length; i++) {
      const currentNavigation = navigationTree[i];
      if (currentNavigation.categoryId === category.categoryId && currentNavigation.navId !== category.navId) {
        navigationId = currentNavigation.navId;
        break;
      }
      if (currentNavigation.navChilds) {
        for (let j = 0; j < currentNavigation.navChilds.length; j++) {
          const childNavigation = currentNavigation.navChilds[j];
          if (childNavigation.categoryId === category.categoryId && childNavigation.navId !== category.navId) {
            navigationId = childNavigation.navId;
            break outerLoop;
          }
        }
      }
    }
    return navigationId;
  }

  checkIfNavigationExists(navigationId: string): boolean {
    let exist = false;
    const navigationTree = this.itemsService.navigationTree;

    outerLoop:
    for (let i = 0; i < navigationTree.length; i++) {
      const currentNavigation = navigationTree[i];
      if (currentNavigation.navId === navigationId) {
        exist = true;
        break;
      }
      if (currentNavigation.navChilds) {
        for (let j = 0; j < currentNavigation.navChilds.length; j++) {
          const childNavigation = currentNavigation.navChilds[j];
          if (childNavigation.navId === navigationId) {
            exist = true;
            break outerLoop;
          }
        }
      }
    }

    return exist;
  }

  resetCurrentFilter() {
    this.itemsService.itemsRequest = this.itemsService.getEmptyRequestPayload();
    const filterCopy = JSON.parse(JSON.stringify(this.selectedUserFilter.filter));

    delete filterCopy.savedFilter;
    Object.assign(this.itemsService.itemsRequest, filterCopy);
    this.itemsService.getItems();
  }

  getUserFilterAndNavigate(savedSearchId) {
    this.getUserFilterById(savedSearchId)
      .subscribe(
        (userFilter) => {
          userFilter.filter = JSON.parse(userFilter.filter);
          this.selectedUserFilter = userFilter;
          this.navigateToItems(this.selectedUserFilter);
        },
        (error: HttpErrorResponse) => {
          if (error.status === 404) {
            console.log('filter not found');
            this.router.navigate(['home']);
          }
        });
  }
  clearUserFilterList(){
    this.userFilterList = [];
  }
}
