import { Component, OnInit, Input, ChangeDetectionStrategy  } from '@angular/core';
import { ItemsService } from '../../../shared/services/items.service';

@Component({
  selector: 'app-item-dashboard',
  templateUrl: './item-dashboard.component.html',
  styleUrls: ['./item-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemDashboardComponent implements OnInit {

  @Input() item;

  constructor( public itemsService: ItemsService
    ) { }

  ngOnInit() {
  }

}
