import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener,  } from '@angular/core';
import * as models from '../../interfaces/model';
import { NotificationTypesEnum, eNotificationAction, NotificationFiltersEnum } from '../../../shared/interfaces/INotificationDetail';
import { NotificationCenterBuyerService } from '../../notification-center/notification-center.service';
import { IItem, eNotificationStatusBuyer } from '../../interfaces/model';
import { TopService } from '../../../shared/services/top.service';

@Component({
  selector: 'app-notification-tooltip',
  templateUrl: './notification-tooltip.component.html',
  styleUrls: ['./notification-tooltip.component.scss']
})
export class NotificationTooltipComponent implements OnInit {
  @Input() item: models.IItem;
  @Input() showNotificationTooltip;
  @Input() tooltipPosition:Event;
  @Output() onTooltipClose = new EventEmitter<null>();
  @Output() removeItem = new EventEmitter<string>();
  @Input() currentNotFilter: NotificationFiltersEnum;
  @Input() calledFromNotifCenter = false;
  buyerHome: HTMLElement;
  NotificationTypesEnum = NotificationTypesEnum;
  hideNotificationTooltip = false;
  tooltipToLeft = false;
  tooltipToTop = false;
  leftTooltip = 0;
  topTooltip = 0;


  constructor(
    public topService: TopService,
  	private notifCenterBuyer: NotificationCenterBuyerService) { }

  ngOnInit() {
    this.calculatePosition();

    if (!this.topService.isMobile) {
      this.isMouseOnTooltip();
    }
  }

  ngOnDestroy() {
    this.topService.notificationTooltipHovered = false;
  }

  submitNotification(event: Event) {
    if (this.topService.loading) {
      return ;
    }

    if (this.item.notificationInfoObj.moreInventory !== this.item.notificationInfoObj.moreInventoryInitialValue) {
      const action = this.item.notificationInfoObj.moreInventory ? eNotificationAction.add : eNotificationAction.remove;
      const type = NotificationTypesEnum.moreInventory;
      this.notifyMe(type, action, this.item.id);
    }
    if (this.item.notificationInfoObj.priceDrop !== this.item.notificationInfoObj.priceDropInitialValue) {
      const action = this.item.notificationInfoObj.priceDrop ? eNotificationAction.add : eNotificationAction.remove;
      const type = NotificationTypesEnum.priceDrop;
      this.notifyMe(type, action, this.item.id);
    }
    event.stopPropagation();
  }

  notifyMe(type, action, itemId) {
    this.notifCenterBuyer.notifyMe(type, action, itemId)
      .subscribe(
        (data) => {
          if (type === NotificationTypesEnum.priceDrop) {
            if (this.item.notificationInfoObj.priceDrop) {
              this.item.notificationInfoObj.priceDropInitialValue = true;
              this.item.notificationInfo.push(NotificationTypesEnum.priceDrop);
            } else {
              this.item.notificationInfoObj.priceDropInitialValue = false;
              this.item.notificationInfo = this.item.notificationInfo.filter(x => x !== NotificationTypesEnum.priceDrop);
            }
          } else if (type === NotificationTypesEnum.moreInventory) {
            if (this.item.notificationInfoObj.moreInventory) {
              this.item.notificationInfoObj.moreInventoryInitialValue = true;
              this.item.notificationInfo.push(NotificationTypesEnum.moreInventory);
            } else {
              this.item.notificationInfoObj.moreInventoryInitialValue = false;
              this.item.notificationInfo = this.item.notificationInfo.filter(x => x !== NotificationTypesEnum.moreInventory);
            }
          }

          if (this.calledFromNotifCenter) {
            if (this.currentNotFilter !== NotificationFiltersEnum.archive ||
               (this.currentNotFilter === NotificationFiltersEnum.archive && action === eNotificationAction.remove) ) {
              this.manageNotificationDetail(type, action);
            }
          }
          this.notifCenterBuyer.markForNotificatonForCheck = itemId;
        },
        (err) => {
          if (type === NotificationTypesEnum.priceDrop) {
            this.item.notificationInfoObj.priceDrop = this.item.notificationInfoObj.priceDropInitialValue ? true : false;
          } else if (type === NotificationTypesEnum.moreInventory) {
            this.item.notificationInfoObj.moreInventory = this.item.notificationInfoObj.moreInventoryInitialValue ? true : false;
          }
          this.notifCenterBuyer.markForNotificatonForCheck = itemId;
        }
      );

  }

  manageNotificationDetail(type: NotificationTypesEnum, action: eNotificationAction) {
    const notifDetail = {
      isNew: null,
      notificationType: type,
      notificationStatus: (action === eNotificationAction.add) ? eNotificationStatusBuyer.waiting : null,
      notificationValue: null,
      timeStamp: null,
    };

    let counterOfEmpty = 0;
    this.item.notificationDetails.forEach((nd, i) => {
      if (nd.notificationType === type) {
        this.item.notificationDetails[i] = notifDetail;

        if (action === eNotificationAction.remove) {
          counterOfEmpty++;
        }
      } else if (nd.notificationStatus === null) {
        counterOfEmpty++;
      }
    });

    if (counterOfEmpty === 3) {
      this.removeItem.next(this.item.id);
    }
  }

  calculatePosition() {
    if (!this.tooltipPosition) {
      return;
    }
    const btn = (this.tooltipPosition.target as HTMLElement).getBoundingClientRect();
    const left = btn.left;
    const top = btn.top;

    if (this.topService.isMobile) {
      if (top >= 250) {
        this.topTooltip = top - 194;
        this.leftTooltip = left - 235;
        this.tooltipToTop = true;
        return;
      } else {
        this.topTooltip = top + 40;
        this.leftTooltip = left - 235;
        this.tooltipToTop = false;
        return;
      }
    }

    if (top <= 256) {
      this.topTooltip = top - 26;
      this.leftTooltip = left - 277;
      this.tooltipToLeft = true;
      return;
    } else {
      this.leftTooltip = left - 114.5;
      this.topTooltip = top - 256;
      this.tooltipToLeft = false;
      return;
    }
  }

  isMouseOnTooltip() {
    setTimeout(() => {
        if (!this.topService.notificationTooltipHovered) {
        this.hideNotification();
      }
    }, 350);
  }

  activateTooltip() {
    this.topService.notificationTooltipHovered = true;
  }

  hideNotification(): void {
    this.onTooltipClose.emit();
  }

  confirmNotification(event: Event): void {
    this.hideNotification();
    event.stopPropagation();
  }

  onClickedOutside(event: Event): void {
    this.hideNotification();
    event.stopPropagation();
  }

  @HostListener('window:scroll', ['$event'])
  @HostListener('window:mousewheel', ['$event'])
  scroll(event: MouseEvent) {
    this.hideNotification();
  }

}
