import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { ItemsService } from '../../../shared/services/items.service';
import { LeftCategoriesService } from '../../../shared/services/left-categories.service';
import { TopService } from '../../../shared/services/top.service';
import { SharedService } from '../../../shared/shared.service';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';

@Component({
  selector: 'app-items-breadcrumbs',
  templateUrl: './items-breadcrumbs.component.html',
  styleUrls: ['./items-breadcrumbs.component.scss']
})
export class ItemsBreadcrumbsComponent implements OnInit {
  @Output() changeView = new EventEmitter<string>();
  public listings = true;
  public excelGrid = false;
  navTitle: string;

  constructor(
    public sharedService: SharedService,
    public itemsService: ItemsService,
    public leftCategoriesService: LeftCategoriesService,
    private pubSubService: PubSubService,
    public topService: TopService) { }

  ngOnInit() {
    this.pubSubService.sharedSubject.subscribe(
      (myEvent) => {
        if (myEvent.name === SharedSource.breadCrumbsTitle) {
          if (myEvent.data !== '') {
            this.navTitle = myEvent.data;
          }
        }
      });
  }
}
