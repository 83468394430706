import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { Dictionary } from '../../classes/dictionary';
import { DynamicComponent } from './dynamic-component';
import { OfferEventTypes } from '../../enums/offer-types';


@Injectable({
  providedIn: 'root'
})
export class DynamicComponentManagerService {
  components: Dictionary;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    this.components = new Dictionary();
  }

  addDynamicComponent(dynamicComponent: DynamicComponent): DynamicComponent {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(dynamicComponent.componentType);
    const componentRef = componentFactory.create(dynamicComponent.container.injector);
    dynamicComponent.addComponentRef(componentRef);
    if (dynamicComponent.attributes) {
      var instance: any = componentRef.instance;
      Object.assign(instance, dynamicComponent.attributes);

      if(
        [OfferEventTypes.OfferMade,
        OfferEventTypes.BuyerCounteroffered,
        OfferEventTypes.VendorCounteroffered,
        OfferEventTypes.VendorUpdatedOfferEvent,
        OfferEventTypes.BuyerUpdatedOfferEvent].includes(dynamicComponent.attributes.offerEvent.eventType)){
        if (dynamicComponent.attributes?.displayCounterOfferForm) {
          instance.counterOfferForm.get('quantity').setValue(dynamicComponent.attributes.metadata.quantity);
          instance.priceOptions = dynamicComponent.attributes.priceOptions;
        }
      }
    }
    dynamicComponent.container.insert(componentRef.hostView);
    this.components.set(dynamicComponent.id, dynamicComponent);
    dynamicComponent.cdr.detectChanges();

    return dynamicComponent;
  }

  getDynamicComponent(id: string): DynamicComponent {
    return this.components.get(id);
  }

  removeDynamicComponent(dynamicComponentId: string): void {
    const dynamicComponent: DynamicComponent = this.getDynamicComponent(dynamicComponentId);
    if(!dynamicComponent){
      return;
    }
    const viewIndex = dynamicComponent.container.indexOf(dynamicComponent.componentRef.hostView);
    if (viewIndex !== -1) {
      dynamicComponent.container.remove(viewIndex);
      dynamicComponent.componentRef.destroy();
      this.components.remove(dynamicComponentId);
    }
  }

  removeDynamicComponentsByViewContainerRef(container: ViewContainerRef) {
    this.components.values().forEach(dynamicComponent => {
      if (dynamicComponent.container === container) {
        this.removeDynamicComponent(dynamicComponent.id);
      }
    });
  }

  removeAllDynamicComponents() {
    this.components.values().forEach(dynamicComponent => {
      this.removeDynamicComponent(dynamicComponent.id);
    });
  }

  removeLastDynamicComponents() {
    if (this.components.size() === 0) {
      return;
    }
    var keys = Object.keys(this.components.getItems());
    var lastKey = keys.pop();
    this.removeDynamicComponent(lastKey);
  }

  getLastDynamicComponentByViewContainerRef(container: ViewContainerRef) {
    if (this.components.size() === 0) {
      return null;
    }
    var length = this.components.size();
    for (let i = length - 1; i >= 0; i--) {
      const component =  this.components.values()[i];
      if (component.container === container) {
        return component;
      }
    }
  }

}


