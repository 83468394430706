export enum ItemErrorCodesEnum {
  zeroCostError,
  zeroPriceError,
  invalidCost,
  invalidUnitPrice,
  invalidMinQty,
  invalidDiscount,
  invalidDiscountVsPrice,
  invalidQtyForDiscount
}
