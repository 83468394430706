<!-- SELLER -->
<div class="item-wrapper item-detailed-modal hide" *ngIf="vendorService.isUser(['SELLER']) && item">
  <div *ngIf="loader" class="loader"></div>
  <div class="item-top-info">
    <div class="item-status">
      <div class="item-info-title">
        Status
      </div>
      <select disabled class="status-select" [ngClass]="{'hide': !(item.status.skuStatus === 'Active'  &&   item.inventoryInfo.currentInventory.availableToSell <= 0)}">
        <option value="Sold Out">Out of Stock</option>
      </select>
      <select disabled class="status-select"
        [ngClass]="{'hide': item.status.skuStatus !== 'Inactive'}">
        <option value="Inactive">Inactive</option>
      </select>
      <select disabled class="status-select"
      [ngClass]="{'hide': item.status.skuStatus !== 'Killed'}">
      <option value="Inactive">Killed</option>
    </select>
      <select class="status-select" [(ngModel)]="item.status.skuStatus" [ngClass]="{ 'hide': (item.status.skuStatus === 'Active'  &&   item.inventoryInfo.currentInventory.availableToSell <= 0  ) ||  item.status.skuStatus === 'Killed' ||   item.status.skuStatus === 'Inactive' || item.status.skuStatus==='Out of Stock'}"
        (change)="onStatusChange($event)">
        <option *ngFor="let status of skuDropDown" [value]="status.text">{{status.text | statusPipe:
          item.status.skuStatus}}</option>
      </select>
      <div class="wrapper" *ngIf="false">
        <div class="tooltip status">
          <div class="title">Note</div>
          <div class="content">

          </div>
        </div>
        <div class="note-msg">
          <span class="badge" [ngClass]="'badge-' + item.status.businessStatus.toLowerCase()">{{item.status.businessStatus}}</span>
        </div>
      </div>
    </div>
    <div class="item-part-number">
      <div class="item-info-title">
        Part Number
      </div>
      <div>
        <input type="text" class="form-control disableMe" disabled [(ngModel)]="item.vendorItemCode">
      </div>
    </div>
    <div class="item-description">
      <div class="item-info-title">
        Description
      </div>
      <textarea class="form-control disableMe" [(ngModel)]="item.description" disabled></textarea>
    </div>
    <div class="item-promo">
      <div class="item-info-title">
        Promo
      </div>
      <div class="item-promo-group">
        <a role="button" class="item-icon-btn">
          <img [src]="isPromoActive(item,'Today\'s Deals')?  'assets/images/vendor-icons/today-deal-active.svg' : 'assets/images/vendor-icons/today-deal-inactive.svg'" />
        </a>
        <a role="button" class="item-icon-btn">
          <img [src]="isPromoActive(item,'Price Drop')?  'assets/images/vendor-icons/price-drop-active.svg' : 'assets/images/vendor-icons/price-drop-inactive.svg'" />
        </a>
        <a role="button" class="item-icon-btn">
          <img [src]="isPromoActive(item,'Just Launched') ? 'assets/images/vendor-icons/just-launched-active.svg' : 'assets/images/vendor-icons/just-launched-inactive.svg'" />
        </a>
        <a role="button" class="item-icon-btn">
          <img [src]="isPromoActive(item,'New') ?   'assets/images/vendor-icons/new-inactive.svg' :  'assets/images/vendor-icons/new-active.svg'" />
        </a>
      </div>
    </div>
    <div class="item-availability">
      <div class="item-info-title">
        Available
      </div>
      <div>
        <input appOnlyDigits type="number" class="form-control" [(ngModel)]="item.inventoryInfo.currentInventory.availableToSell"
          (keyup)="inventoryUpdated()" [disabled]="isFieldDisabled()">
      </div>
      <div class="item-availability-statistic">
        <div class="item-availability-statistic-sold hide">
          <span class="statistic-sold-key hide">Pending PO</span>
          <span class="statistic-sold-value hide">{{ item.salesInfo.saleQty }}</span>
        </div>
        <div class="item-availability-statistic-sold-lifetime hide ">
          <span class="statistic-sold-lifetime-key hide">Lifetime</span>
          <span class="statistic-sold-lifetime-value hide">{{ item.salesInfo.notPurchasedQty }}</span>
        </div>
      </div>
    </div>

    <div class="item-moq">
      <div class="item-info-title">
        MOQ
      </div>
      <div class="input-group-vertical">
        <div class="input-qty moq">
          <input type="number" class="form-control disableMe" [(ngModel)]="item.moq" disabled>
        </div>
      </div>
    </div>
    <div class="item-price">
      <div class="item-info-title">
        Cost
      </div>
      <div class="input-price">
        <input type="text" class="form-control disableMe" [(ngModel)]="item.cost" disabled>
      </div>
    </div>
  </div>

  <div class="center-block">
    <div class="line-error col-md-12" *ngIf="item.errors && item.errors.errors && item.showError">
      <img src="https://cdn.hubx.com/assets/images/exclamation-mark.svg" width="24px" height="24px" /> {{'Error: ' +
      item.errors.errors[0].errorMessage}}</div>
  </div>

  <div class="item-bottom-info">
    <div class="launched">
      <span> {{ item.lastActivatedOn ? 'Launched' : '' }}</span>
      <span>{{ item.lastActivatedOn ? ( item.lastActivatedOn | date:'MMM d, y, h:mm' ) : '' }}</span>
    </div>
    <div class="category-select">
    </div>
    <div class="item-attributes">
      <div class="input-group">

        <select class="item-bottom-select input-group-condition disableMe" [(ngModel)]='condVal.code' (ngModelChange)="updateAttributeSelected(condition.name)"
          disabled>
          <option *ngFor="let cond of condition.values" value="{{cond.code}}">{{cond.value}}</option>
        </select>
      </div>
      <div class="input-group">
        <select class="item-bottom-select input-group-warranty disableMe" [(ngModel)]='warrantyVal.code'
          (ngModelChange)="updateAttributeSelected(warranty.name)" disabled>
          <option *ngFor="let wart of warranty.values" value="{{wart.code}}">{{wart.value}}</option>
        </select>
      </div>
      <div class="input-group">
        <select class="item-bottom-select input-group-packaging disableMe" [(ngModel)]='packagingVal.code'
          (ngModelChange)="updateAttributeSelected(packaging.name)" disabled>
          <option *ngFor="let pckg of packaging.values" value="{{pckg.code}}">{{pckg.value}}</option>
        </select>
      </div>
      <div class="input-group">
        <select class="item-bottom-select input-group-restrictions disableMe" [(ngModel)]='restrictionsVal.code'
          (ngModelChange)="updateAttributeSelected(restrictions.name)" disabled>
          <option *ngFor="let restr of restrictions.values" value="{{restr.code}}">{{restr.value}}</option>
        </select>
      </div>
    </div>
    <div class="bottom-icons">
      <div class="bottom-icons-group">
        <a role="button" class="item-icon-btn">
          <img src="assets/images/vendor-icons/more-info.svg" />
        </a>
        <a role="button" class="item-icon-btn">
          <img src="assets/images/vendor-icons/info.svg" />
        </a>
        <a role="button" class="item-icon-btn">
          <img src="assets/images/vendor-icons/api.svg" />
        </a>
        <a role="button" class="item-icon-btn">
          <img src="assets/images/vendor-icons/picture.svg" />
        </a>
      </div>
    </div>
    <div class="updated" [ngClass]="{'updatedNow':item.updatedNow}">
      <span> {{ item.lastUpdatedOn ? 'Updated' : '' }}</span>
      <span> {{ item.lastUpdatedOn ? ( item.lastUpdatedOn | date:'MMM d, y, h:mm' ) : '' }}</span>
    </div>
    <div class="bottom-icons-group control-btns">
      <a role="button" class="item-icon-btn" [ngClass]="{'vendor-item-updated': vendorService.updatedMappedItems.has(item.id)}" (click)="undoItemUpdates($event)"
        [tooltip]="item.updatedNow ? 'Undo' : ''" placement="bottom">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          width="20px" height="20px" viewBox="0 0 497.25 497.25" xml:space="preserve">
          <g id="undo">
            <path fill="#B2B2B2" d="M248.625,89.25V0l-127.5,127.5l127.5,127.5V140.25c84.15,0,153,68.85,153,153c0,84.15-68.85,153-153,153
              c-84.15,0-153-68.85-153-153h-51c0,112.2,91.8,204,204,204s204-91.8,204-204S360.825,89.25,248.625,89.25z" />
          </g>
        </svg>
      </a>

      <a role="button" class="item-icon-btn item-icon-btn-update" (click)="updateSingleItem($event)" [ngClass]="{'vendor-item-updated': vendorService.updatedMappedItems.has(item.id)}"
        [tooltip]="item.updatedNow ? 'Save' : ''" placement="bottom" *ngIf="itemId">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
          <path fill="#B2B2B2" d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" />
        </svg>
      </a>
    </div>
  </div>
</div>


<!-- ADMIN -->
<div class="item-wrapper item-detailed-modal">
  <div *ngIf="loader" class="loader"></div>
  <div class="item-top-info">
    <div class="item-status">
      <div class="item-info-title">
        Status
      </div>
      <select disabled class="status-select" [ngClass]="{'hide':!(item.status.skuStatus === 'Active'  &&   item.inventoryInfo.currentInventory.availableToSell <= 0) &&  item.status.skuStatus!=='Out of Stock'}">
        <option value="Out of Stock">Out of Stock</option>
      </select>
      <div class="input-group">
        <select class="status-select" [ngClass]="{ 'hide':  (item.status.skuStatus === 'Active'  &&   item.inventoryInfo.currentInventory.availableToSell <= 0  ) ||  item.status.skuStatus==='Out of Stock'}"
          [(ngModel)]="item.status.skuStatus" (change)="onStatusChange($event)">
          <option class="skudd" *ngFor="let status of skuDropDown" [value]="status.text">{{status.text | statusPipe:
            item.status.skuStatus}}</option>
        </select>
      </div>
      <!-- <div class="item-info-title" *ngIf="false">
        Business
      </div>
      <div class="input-group" *ngIf="false">
        <select class="status-select" [(ngModel)]="item.status.businessStatus" (change)="businessStatusUpdated()">
          <option *ngFor="let status of businessStatusDropdown" [value]="status.text">{{status.text}}</option>
        </select>
      </div> -->
    </div>
    <div class="item-part-number">
      <div class="item-info-title">
        Part Number
      </div>
      <!-- <div [tooltip]="item.seller.name">
        <input type="text" class="form-control" [ngClass]="{'disableMe': item.isItemCodeLocked}" (change)="itemCodeUpdated()"
          [tooltip]="item.isItemCodeLocked ? item.seller.name : ''" [(ngModel)]="item.vendorItemCode" [disabled]="item.isItemCodeLocked">
      </div> -->
      <div [tooltip]="isAll ? item.seller.name : ''">
        <input type="text" class="form-control disableMe"
         [(ngModel)]="item.vendorItemCode" disabled>
      </div>

      <div *ngIf="vendorService.userRole === 'ADMIN'" class="item-info-title hbx">
        XID
      </div>
      <div *ngIf="vendorService.userRole === 'ADMIN'" [tooltip]="isAll ? item.seller.name : ''">
        <input type="text" class="form-control disableMe"
         [(ngModel)]="item.hubxItemCode" disabled>
      </div>
    </div>
    <div class="item-description">
      <div class="item-info-title">
        Description
      </div>
      <textarea class="form-control" [ngClass]="{'disableMe': !item.descriptionCanEdit}" [(ngModel)]="item.description"
        (change)="descriptionUpdated($event)" [disabled]="!item.descriptionCanEdit"></textarea>
    </div>
    <div class="item-promo">
      <div class="item-info-title">
        Promo
      </div>
      <div class="item-promo-group">
        <a role="button" class="item-icon-btn">
          <img [src]="isPromoActive(item,'Today\'s Deals')?  'assets/images/vendor-icons/today-deal-active.svg' : 'assets/images/vendor-icons/today-deal-inactive.svg'" />
        </a>
        <a role="button" class="item-icon-btn">
          <img [src]="isPromoActive(item,'Price Drop')?  'assets/images/vendor-icons/price-drop-active.svg' : 'assets/images/vendor-icons/price-drop-inactive.svg'" />
        </a>
        <a role="button" class="item-icon-btn">
          <img [src]="isPromoActive(item,'Just Launched') ? 'assets/images/vendor-icons/just-launched-active.svg' : 'assets/images/vendor-icons/just-launched-inactive.svg'" />
        </a>
        <a role="button" class="item-icon-btn">
          <img [src]="isPromoActive(item,'New') ?   'assets/images/vendor-icons/new-inactive.svg' :  'assets/images/vendor-icons/new-active.svg'" />
        </a>
      </div>
    </div>
    <div class="item-availability">
      <div class="item-info-title">
        Available
      </div>
      <div>
        <input appOnlyDigits type="number" class="form-control" [(ngModel)]="item.inventoryInfo.currentInventory.availableToSell"
          (keyup)="inventoryUpdated()" [disabled]="isFieldDisabled()">
      </div>
      <div class="item-availability-statistic">
        <div class="item-availability-statistic-sold">
          <span class="statistic-sold-key hide">Pending PO</span>
          <span class="statistic-sold-value hide">{{ item.salesInfo.saleQty }}</span>
        </div>
        <div class="item-availability-statistic-sold-lifetime hide">
          <span class="statistic-sold-lifetime-key hide">Lifetime</span>
          <span class="statistic-sold-lifetime-value hide">{{ item.salesInfo.notPurchasedQty }}</span>
        </div>
      </div>
    </div>
    <ng-template ngFor let-price [ngForOf]="item.prices" let-i="index">
      <div class="item-moq" *ngIf="vendorService.isAdmin">
        <div class="item-info-title">
          DQ #{{i+1}}
        </div>
        <div class="input-group-vertical">
          <div class="input-qty">
            <input type="number" class="form-control disableMe" [(ngModel)]="price.fromQty" disabled>
          </div>
          <div class="input-price">
            <input type="number" class="form-control disableMe" [(ngModel)]="price.unitPrice" disabled>
          </div>
        </div>
      </div>
    </ng-template>
    <div class="item-moq">
      <div class="item-info-title">
        MOQ
      </div>
      <div class="input-group-vertical">
        <div class="input-qty moq">
          <input type="number" class="form-control disableMe" [(ngModel)]="item.moq" disabled>
        </div>
      </div>
    </div>
    <div class="item-moq" *ngIf="vendorService.isAdmin">
        <div class="item-info-title">
          MXQ
        </div>
        <div class="input-group-vertical">
          <div class="input-qty moq">
            <input type="number" class="form-control disableMe" [(ngModel)]="item.mxq" disabled>
          </div>
        </div>
      </div>
    <div class="item-price">
      <div class="item-info-title">
        Cost
      </div>
      <div class="input-price">
        <input type="text" [ngClass]="{'disableMe': costUpdated()}" class="form-control" [(ngModel)]="item.cost" (change)="costUpdated()" [disabled]="isFieldDisabled() || vendorService.isSeller" appOnlyDigits>
      </div>
      <div class="item-info-title" *ngIf="vendorService.isAdmin">
        Price
      </div>
      <div class="input-price" *ngIf="vendorService.isAdmin">
        <input type="text" class="form-control" [(ngModel)]="item.unitPrice" (change)="priceUpdated()" [ngClass]="{'disableMe': isFieldDisabled()}" [disabled]="isFieldDisabled()" appOnlyDigits>
      </div>
    </div>
  </div>

  <div class="center-block">
    <div class="line-error col-md-12" *ngIf="item.errors && item.errors.errors && item.showError">
      <img src=".https://cdn.hubx.com/assets/images/exclamation-mark.svg" width="24px" height="24px" /> {{'Error: ' +
      item.errors.errors[0].errorMessage}}</div>
  </div>

  <div class="item-bottom-info">
    <div class="launched">
      <span> {{ item.lastActivatedOn ? 'Launched' : '' }}</span>
      <span>{{ item.lastActivatedOn ? ( item.lastActivatedOn | date:'MMM d, y, h:mm' ) : '' }}</span>
    </div>
    <div class="category-select">
    </div>
    <div class="item-attributes">
      <div class="input-group">
        <select class="item-bottom-select input-group-condition disableMe" [(ngModel)]='condVal.code' (ngModelChange)="updateAttributeSelected(condition.name)"
          disabled>
          <option *ngFor="let cond of condition.values" value="{{cond.code}}">{{cond.value}}</option>
        </select>
      </div>
      <div class="input-group">
        <select class="item-bottom-select input-group-warranty disableMe" [(ngModel)]='warrantyVal.code'
          (ngModelChange)="updateAttributeSelected(warranty.name)" disabled>
          <option *ngFor="let wart of warranty.values" value="{{wart.code}}">{{wart.value}}</option>
        </select>
      </div>
      <div class="input-group">
        <select class="item-bottom-select input-group-packaging disableMe" [(ngModel)]='packagingVal.code'
          (ngModelChange)="updateAttributeSelected(packaging.name)" disabled>
          <option *ngFor="let pckg of packaging.values" value="{{pckg.code}}">{{pckg.value}}</option>
        </select>
      </div>
      <div class="input-group">
        <select class="item-bottom-select input-group-restrictions disableMe" [(ngModel)]='restrictionsVal.code'
          (ngModelChange)="updateAttributeSelected(restrictions.name)" disabled>
          <option *ngFor="let restr of restrictions.values" value="{{restr.code}}">{{restr.value}}</option>
        </select>
      </div>
    </div>
    <div class="bottom-icons">
      <div class="bottom-icons-group">
        <a role="button" class="item-icon-btn">
          <img src="assets/images/vendor-icons/more-info.svg" />
        </a>
        <a role="button" class="item-icon-btn">
          <img src="assets/images/vendor-icons/info.svg" />
        </a>
        <a role="button" class="item-icon-btn">
          <img src="assets/images/vendor-icons/api.svg" />
        </a>
        <a role="button" class="item-icon-btn">
          <img src="assets/images/vendor-icons/picture.svg" />
        </a>
      </div>
    </div>
    <div class="updated" [ngClass]="{'updatedNow':item.updatedNow}">
      <span> {{ item.lastUpdatedOn ? 'Updated' : '' }}</span>
      <span> {{ item.lastUpdatedOn ? ( item.lastUpdatedOn | date:'MMM d, y, h:mm' ) : '' }}</span>
    </div>
    <div class="bottom-icons-group control-btns">
      <a role="button" class="item-icon-btn" [ngClass]="{'vendor-item-updated cursor': vendorService.updatedMappedItems.has(item.id)}" (click)="undoItemUpdates($event)"
        [tooltip]="item.updatedNow ? 'Undo' : ''" placement="bottom">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          width="20px" height="20px" viewBox="0 0 497.25 497.25" xml:space="preserve">
          <g id="undo">
            <path fill="#B2B2B2" d="M248.625,89.25V0l-127.5,127.5l127.5,127.5V140.25c84.15,0,153,68.85,153,153c0,84.15-68.85,153-153,153
              c-84.15,0-153-68.85-153-153h-51c0,112.2,91.8,204,204,204s204-91.8,204-204S360.825,89.25,248.625,89.25z" />
          </g>
        </svg>
      </a>
      <a role="button" class="item-icon-btn item-icon-btn-update" (click)="updateSingleItem($event)" [ngClass]="{'vendor-item-updated': vendorService.updatedMappedItems.has(item.id)}"
        [tooltip]="item.updatedNow ? 'Save' : ''" placement="bottom" *ngIf="itemId">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
          <path fill="#B2B2B2" d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" />
        </svg>
      </a>
    </div>
  </div>
</div>
