<div class="item-notification-tooltip-wrapper" 
[class.__mobile]="topService.isMobile"
[class.__mobile-top]="topService.isMobile && tooltipToTop"
[class.__left]="tooltipToLeft"
[class.active]="showNotificationTooltip"
[style.left]="leftTooltip+'px'"
[style.top]="topTooltip+'px'"
(mouseleave)="hideNotification()"
(mouseenter)="activateTooltip()"
(clickOutside)="onClickedOutside($event)">
  <div class="item-notification-tooltip">
    <span class="item-notification-tooltip__close" *ngIf="topService.isMobile">
      <img (click)="hideNotification()" src="../../../assets/images/icons/mobile/menu/m-close-white.svg" width="24" height="24" alt="Close">
    </span>
    <div class="col">
      <div class="item-notification-tooltip-img" *ngIf="!topService.isMobile">
        <img src="assets/images/icons/popup-bell.svg" width="60" height="60" alt="bell">
      </div>
      <div class="item-notification-tooltip-form">
        <div class="item-notification-tooltip-header">
          Notify me when
        </div>
        <div class="row item-notification-tooltip-form__checkbox">
          <input type="checkbox" name="notify-price-drop" id="price-drop-{{item.id}}"
            (change)="submitNotification($event)"
            [(ngModel)]="item.notificationInfoObj.priceDrop">
          <img src="assets/images/icons/popup-price-drop.svg">
          <label class="label-text" for="price-drop-{{item.id}}">Item Price Drops</label>
          <label for="price-drop-{{item.id}}"></label>
        </div>
        <div class="row item-notification-tooltip-form__checkbox">
          <input type="checkbox" name="notify-price-drop" id="more-inventory-{{item.id}}"
            (change)="submitNotification($event)"
            [(ngModel)]="item.notificationInfoObj.moreInventory">
          <img src="assets/images/icons/popup-more-inventory.svg">
          <label class="label-text" for="more-inventory-{{item.id}}">Inventory is Added</label>
          <label for="more-inventory-{{item.id}}"></label>
        </div>
      </div>
    </div>
  </div>
</div>
