import { Pipe, PipeTransform } from '@angular/core';
/*
 * Receives a numeric value and returns a formatted string:
 * -Negative values will be converted to '0'.
 * -Values between 0 and limit=300 will same.
 * -Values bigger than limit=300 will be formatted as '300+'.
 * Usage:
 *   value | inventoryLimit
 * Example:
 *   {{ 325 | inventoryLimit }}
 *   formats to: '300+'
*/
@Pipe({name: 'inventoryLimit'})
export class InventoryLimitPipe implements PipeTransform {
  transform(value: number): string {
    const limit = 300;
    return (value < 0)
        ? '0'
        : (value > limit)
            ? `${limit}+`
            : value.toString();
    }
}
