import { Injectable, EventEmitter } from '@angular/core';
import { UtilityService } from '../../../core/utility.service';
import * as models from '../../interfaces/model';

@Injectable()
export class CartItemsService {
  copied = 'Copy';



  constructor(
    private utilService: UtilityService
  ) { }


  copyState(emitter: any): void {
    emitter.emit();
    this.copied = 'Copy';
  }

  copyText(event: any, val: string): void {
    event.stopPropagation();
    event.preventDefault();
    this.utilService.copyTextToClipboard(val);
    this.copied = 'Copied!';
  }

  allowsEdition(cartItem, user_role): boolean {
    return (cartItem.isEditable || user_role === 'SUPER');
  }

  handlePriceUpdate(cartItem: models.ICartItem, newPrice: any, handlePriceUpdateEmitter: any): void {
    const price = Number(newPrice.replace(/[$,]/g, ''));
    handlePriceUpdateEmitter.emit({ cartItem: cartItem, newPrice: price });
  }

  checkMoreThanOnHand(cartItem, user_role): boolean {
    return user_role === 'BUYER' && cartItem.item.inventory.onHand >= 300;
  }

  checkMoreThanEnRoute(cartItem, user_role): boolean {
    return user_role === 'BUYER' && cartItem.item.inventory.enRoute >= 300 && cartItem.item.inventory.onHand === 0;
  }

  calculateInventoryEnRoute(cartItem, user_role): string {
    if (user_role === 'BUYER') {
      if (cartItem.item.inventory.enRoute > 300) {
        return '300+';
      } else {
        return (cartItem.item.inventory.enRoute).toString() + '';
      }
    } else {
      return (cartItem.item.inventory.enRoute).toString() + '';
    }
  }

  isQtyEditable(cartItem, user_role): boolean {
    const res = cartItem.orderLineNum === -1 || user_role === 'SUPER';
    return !res;
  }

  handleQuantityUpdate(cartItem, searchSubject): void {
    if (cartItem.quantity === 0) { cartItem.quantity = cartItem.item.moq || 1; }
    searchSubject.next(cartItem);
  }

  deleteItem(cartItem, event: MouseEvent, user_role, loading, deleteSpinner, deleteItemEmitter): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.allowsEdition(cartItem, user_role) && !loading && !deleteSpinner) {
      deleteItemEmitter.emit(cartItem);
    }
  }

  openGradingGuide(event: any, cartItem, gradingGuideEmitter) {
    event.stopPropagation();
    gradingGuideEmitter.emit(cartItem);
  }

}
