<div class="nothing-found row">
	<div class="col">
		<div class="info-block">
			<img width="64" height="64" src="assets/images/vendor-icons/64-pending-po-grey.svg">
			<span>
				You have no pending sold
			</span>
		</div>
	</div>
</div>

<div class="ps">
	<div class="ps-header">
		<div class="ps-header-item __button">
		</div>
		<div class="ps-header-item __date">
			<span>Date</span>
			<img src="assets/images/vendor-icons/icon-sort-default.svg"/>
		</div>
		<div class="ps-header-item __part-number">
			<span>Part Number</span>
			<img src="assets/images/vendor-icons/icon-sort-default.svg"/>
		</div>
		<div class="ps-header-item">
			<span>Units Sold</span>
			<img src="assets/images/vendor-icons/icon-sort-default.svg"/>
		</div>
		<div class="ps-header-item">
			<span>Cost</span>
			<img src="assets/images/vendor-icons/icon-sort-default.svg"/>
		</div>
		<div class="ps-header-item">
			<span>Total Revenue</span>
			<img src="assets/images/vendor-icons/icon-sort-default.svg"/>
		</div>
	</div>
	<div class="ps-items" *ngFor="let item of items">
		<div class="ps-item">
			<div class="ps-item-part __button">
				<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g id="plus-grid" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<polygon id="+" points="13.2712871 10.7287129 18 10.7287129 18 13.2712871 13.2712871 13.2712871 13.2712871 18 10.7287129 18 10.7287129 13.2712871 6 13.2712871 6 10.7287129 10.7287129 10.7287129 10.7287129 6 13.2712871 6"></polygon>
					</g>
				</svg>
			</div>
			<div class="ps-item-part __date">
				<span></span>
			</div>
			<div class="ps-item-part __part-number">
				<span></span>
			</div>
			<div class="ps-item-part">
				<span></span>
			</div>
			<div class="ps-item-part">
				<span></span>
			</div>
			<div class="ps-item-part">
				<span></span>
			</div>
		</div>
	</div>
</div>