<div  class="confirm-modal">
  <div class="confirm-modal-wrap animated" [class.zoomIn]="true" [class.zoomOut]="animateOut">
    <div class="confirm-modal-message">
        <span class="confirm-modal-message__span" *ngFor="let messages of message">
          {{ messages }}
        </span>
    </div>

    <div class="confirm-modal-buttons">
      <button type="button" class="confirm-modal-buttons__cancel" (click)="confirm(false)">Cancel</button>
      <button type="button" class="confirm-modal-buttons__continue" (click)="confirm(true)">Continue</button>
    </div>

  </div>
</div>
