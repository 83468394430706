<svg id="iconfont-svg-group" aria-hidden="true" style="position: absolute; width: 0px; height: 0px; overflow: hidden;">
	<symbol id="icon-add-notification" viewBox="0 0 24 24">
		<linearGradient id="icon-add-notification-gradient" x1="74.588658%" x2="16.243437%" y1="17.361718%"
			y2="82.294216%">
			<stop offset="0" stop-color="#fe563b" />
			<stop offset="1" stop-color="#d8214d" />
		</linearGradient>
		<path
			d="m13.78 19.7181605c0 1.0499261-.8554974 2.2818395-1.9 2.2818395h-.76c-1.0445026 0-1.9-1.2319134-1.9-2.2818395zm-7.3234555-11.87516484c.3382199-6.08957172 3.43591623-5.84158916 5.0434555-5.84158916l.0859603-.00019339c1.6294207-.0065559 4.625209-.1408844 4.9574952 5.84578227.2805236 5.08564232 4.0208377 8.28541732 4.0208377 8.28541732.2595818.2489958.4153299.5878449.4357068.9479333v.9199353c-.0032625.316075-.2585269.570883-.5729843.5719598v.0039997h-17.85403141c-.31599725-.0010972-.57189275-.2583214-.57298429-.5759595v-.923935c.02017979-.3601379.17595983-.6990563.43570681-.9479334 0 0 3.74031413-3.1937753 4.02083769-8.28541724z" />
	</symbol>
	<symbol id="icon-timer">
		<path
			d="M12.35 5.17c4.14 0 7.5 3.33 7.5 7.43s-3.36 7.43-7.5 7.43c-4.14 0-7.5-3.33-7.5-7.43s3.36-7.43 7.5-7.43Zm0 2.06c-3.01 0-5.45 2.4-5.45 5.37s2.44 5.37 5.45 5.37c3.01 0 5.45-2.4 5.45-5.37s-2.44-5.37-5.45-5.37Zm0 .48c.72 3.06 1.08 4.7 1.08 4.89 0 .59-.48 1.07-1.08 1.07-.6 0-1.08-.48-1.08-1.07 0-.2.36-1.83 1.08-4.89ZM11.22 3h2.27c.56 0 1.02.46 1.02 1.03v.08c0 .56-.46 1.02-1.02 1.02h-2.27c-.57 0-1.03-.46-1.03-1.02v-.08c0-.57.46-1.03 1.03-1.03Z"
			fill="#1A1A1A" fill-rule="nonzero" />
	</symbol>
	<symbol id="icon-arrow">
		<path fill="#1A1A1A" fill-rule="nonzero" d="m30 16-5.8 6v-4.9H2v-2.22l22.2.01V10z" />
	</symbol>
	<symbol id="icon-arrow-previous">
		<g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M11.8717521,5.22305673 C12.0268041,4.99074024 12.3378749,4.93010751 12.5665483,5.08762973 L22.7804385,12.1234936 C22.833734,12.1602064 22.8795517,12.207027 22.9153764,12.2613853 C23.0690886,12.494619 23.0075886,12.8102857 22.7780125,12.9664467 L12.5641222,19.9140813 C12.4817737,19.970096 12.3849042,20 12.2858018,20 C12.0095182,20 11.7855461,19.7724597 11.7855461,19.4917744 L11.7855461,15.7724543 C4.95396646,14.6833151 0.0922550852,11.3246472 0,5.70068057 C-0.0111250308,5.00252803 0.642967239,4.79222059 0.975686237,5.39617302 C2.3093095,7.81696959 5.01487616,9.67807808 11.7841507,9.5822638 L11.7855461,5.50827593 C11.7855461,5.40658192 11.8155758,5.30722644 11.8717521,5.22305673 Z" id="Path-Copy" fill="#D9D9D9"></path>
    </g>
	</symbol>
</svg>
<ng-container *ngIf="!topService.isMobile" >
	<div class="offers" [class.from-vendor]="isVendor"
		*ngIf="(isBuyer && offerService.offersSummary?.hasMadeOffers) || (isVendor && offerService.offerByStatusResponse?.offerDataResponses.length)">
		<div class="offers-list">
			<app-offer-list [offersByStatus]="offerService.offerByStatusResponse"
				[totalOffersByStatus]="totalOffersByStatusResponse"
				(onOffersByStatusRequest)="onOffersByStatusRequest($event)" (onSelectOffer)="onSelectOffer($event)"
				[isLoading]="isOfferListLoading" [defaultTitleDropdown]="defaultTitleDropdown" [isBuyer]="isBuyer"
				[paramOfferId]="paramOfferId">
			</app-offer-list>
		</div>
		<div class="offers-chat">
			<ng-container *ngIf="!offerService.selectedOffer?.offerEvents?.length && !paramOfferId">
				<div class="offers-chat-empty">
					<img src="assets/images/icons/offers/offer-empty.svg" width="42" height="42">
					<span>Select offer on the left</span>
				</div>
			</ng-container>
			<ng-container *ngIf="offerService.selectedOffer?.offerEvents?.length">
				<div class="offers-chat-selected">
					<div class="offers-chat-item">
						<div class="offers-chat-item__logo" *ngIf="offerService.isOfferItemDataReady()">
							<div class="__logo-wrap">
								<div class="__logo-img"
									[ngStyle]="{'background-image': 'url('+ ( !offerService.selectedOfferItem?.manufacturerLogoUrl?.includes(imageBaseUrl) ? imageBaseUrl : '') + offerService.selectedOfferItem?.manufacturerLogoUrl + ')'}">
								</div>
							</div>
						</div>
						<div class="offers-chat-item__text" *ngIf="offerService.isOfferItemDataReady()">
							<span class="__name">{{offerService.selectedOffer?.qty}}<span>&#215;</span>
								{{offerService.selectedOfferItem.itemCode}}</span>
							<span class="__desk">{{offerService.selectedOfferItem.description}} -
								{{offerService.selectedOfferItem.itemCode}}</span>
						</div>
					</div>
					<div class="offers-chat-messages">
						<ng-template #eventMessages></ng-template>
						<ng-template #dynamicMessages></ng-template>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="topService.isMobile" >
	<div class="offers mobile" [class.from-vendor]="isVendor" 
		[hidden]="!((isBuyer && offerService.offersSummary?.hasMadeOffers) || (isVendor && offerService.offerByStatusResponse?.offerDataResponses.length) || isOfferListLoading)">
		<div class="offers-list" [hidden]="!(((!offerService.selectedOffer?.offerEvents?.length && !paramOfferId) || (!offerService.selectedOffer?.offerEvents?.length || topService.loading ) ) && !offerChatLoaded())">
			<app-offer-list [offersByStatus]="offerService.offerByStatusResponse"
				[totalOffersByStatus]="totalOffersByStatusResponse"
				(onOffersByStatusRequest)="onOffersByStatusRequest($event)" (onSelectOffer)="onSelectOffer($event)"
				[isLoading]="isOfferListLoading" [defaultTitleDropdown]="defaultTitleDropdown" [isBuyer]="isBuyer"
				[paramOfferId]="paramOfferId">
			</app-offer-list>
		</div>
		<div class="offers-chat" *ngIf="offerService.selectedOffer?.offerEvents?.length">
				<div class="offers-chat-selected">
					<div class="offers-chat-item">
						<div class="offers-chat-item__logo" *ngIf="offerService.isOfferItemDataReady()">
							<div class="__logo-wrap">
								<div class="__logo-img"
									[ngStyle]="{'background-image': 'url('+ ( !offerService.selectedOfferItem?.manufacturerLogoUrl?.includes(imageBaseUrl) ? imageBaseUrl : '') + offerService.selectedOfferItem?.manufacturerLogoUrl + ')'}">
								</div>
							</div>
						</div>
						<div class="offers-chat-item__text" *ngIf="offerService.isOfferItemDataReady()">
							<span class="__name">{{offerService.selectedOffer?.qty}}<span>&#215;</span>
								{{offerService.selectedOfferItem.itemCode}}</span>
							<span class="__desk">{{offerService.selectedOfferItem.description}} -
								{{offerService.selectedOfferItem.itemCode}}</span>
						</div>
					</div>
					<div class="offers-chat-messages">
						<ng-template #eventMessages></ng-template>
						<ng-template #dynamicMessages></ng-template>
					</div>
				</div>
		</div>
	</div>
</ng-container>

<div *ngIf="!topService.loading && isSuperOrSaleNoImpersonated" class="offers-empty-wrap">
	<div class="offers-empty">
		<img src="assets/images/icons/offers/offer-empty.svg" width="42" height="42">
		<span>
			Please select your BP <br>
			to view offers
		</span>
	</div>
</div>

<div *ngIf="!topService.loading &&
	(isBuyer && !isSuperOrSaleNoImpersonated && (offerService.offersSummary && !offerService.offersSummary.hasMadeOffers)) ||
	(isVendor && (offerService.offerByStatusResponse && !offerService.offerByStatusResponse?.offerDataResponses.length))"
	class="offers-tutorial-wrap" [class.from-vendor]="isVendor" [class.mobile]="topService.isMobile">

	<div class="offers-tutorial-banner__wrap">
		<div class="offers-tutorial-banner">
			<div class="offers-tutorial-banner-img">
				<img src="assets/images/icons/offers/tutorial/empty-text.png" width="292" height="83">
			</div>
		</div>
	</div>
	<!-- Buyer tutorial -->

	<div class="offers-tutorial" *ngIf="isBuyer">
		<div class="offers-tutorial-section">
			<div class="offers-tutorial-section-img">
				<img src="assets/images/icons/offers/tutorial/image1@2x.png" width="400" height="242">
			</div>
			<div class="offers-tutorial-section-text">
				<h3>Create your<br /> offers with ease</h3>
				<p>You have 3 tokens at your disposal. This<br /> allows you to have up to 3 active offers at the<br />
					same time. At the end of the offer, the token<br /> is returned to your balance.
				</p>
				<hr>
			</div>
		</div>
		<div class="offers-tutorial-section">
			<div class="offers-tutorial-section-text">
				<h3>Manage like a chat</h3>
				<p>Engage with the vendor through counter<br /> offers until you reach an optimal price for<br /> both
					parties. This does not require tokens.
				</p>
				<hr>
			</div>
			<div class="offers-tutorial-section-img">
				<img src="assets/images/icons/offers/tutorial/image2@2x.png" width="400" height="242">
			</div>
		</div>
		<div class="offers-tutorial-section">
			<div class="offers-tutorial-section-img">
				<img src="assets/images/icons/offers/tutorial/image3@2x.png" width="400" height="242">
			</div>
			<div class="offers-tutorial-section-text">
				<h3>Always be aware</h3>
				<p>Receive notifications of all replies<br /> on the site and SMS.
				</p>
			</div>
		</div>
		<div class="offers-tutorial-divider"></div>
	</div>
	<!-- Vendor tutorial -->
	<div class="offers-tutorial" *ngIf="isVendor">
		<div class="offers-tutorial-section">
			<div class="offers-tutorial-section-img">
				<img src="assets/images/icons/offers/tutorial/offers-1.png" width="400" height="242">
			</div>
			<div class="offers-tutorial-section-text">
				<h3>Receive Direct<br /> Offers from Buyers</h3>
				<p>Streamline your sales process by receiving direct<br /> offers from buyers. All offers meet the
					requirement of<br /> no less than 5% of the total price and minimum<br /> threshold of $20k.
				</p>
				<hr>
			</div>
		</div>
		<div class="offers-tutorial-section">
			<div class="offers-tutorial-section-text">
				<h3>Full control over<br /> your negotiations</h3>
				<p>Accept, reject, or send counteroffers with just a few<br /> clicks. Make your counteroffer last or
					set a timer to<br /> encourage quick decision making.
				</p>
				<hr>
			</div>
			<div class="offers-tutorial-section-img">
				<img src="assets/images/icons/offers/tutorial/offers-2.png" width="400" height="242">
			</div>
		</div>
		<div class="offers-tutorial-section">
			<div class="offers-tutorial-section-img">
				<img src="assets/images/icons/offers/tutorial/offers-3.png" width="400" height="242">
			</div>
			<div class="offers-tutorial-section-text">
				<h3>Stay Informed<br /> with Notifications</h3>
				<p>Receive timely notifications about the status of offers,<br /> ensuring you never miss a potential
					sale opportunity.
				</p>
			</div>
		</div>
		<div class="offers-tutorial-divider"></div>
	</div>
</div>



<!-- Accept offer popup -->
<app-confirmation-popup *ngIf="offerService.confirmAcceptanceSettings.show"
	[settings]="offerService.confirmAcceptanceSettings" [isMobile]="topService.isMobile" (onAccept)="acceptOffer()">
</app-confirmation-popup>

<!-- Counteroffer confirmation popup -->
<app-confirmation-popup *ngIf="offerService.confirmCounterofferSettings.show"
	[settings]="offerService.confirmCounterofferSettings" [isMobile]="topService.isMobile" (onAccept)="submitCounteroffer()">
</app-confirmation-popup>

<!-- Counteroffer error popup -->
<app-confirmation-popup *ngIf="offerService.confirmCounterofferErrorSettings.show"
	[settings]="offerService.confirmCounterofferErrorSettings" [isMobile]="topService.isMobile">
</app-confirmation-popup>

<!-- Reject offer popup -->
<app-confirmation-popup *ngIf="offerService.confirmRejectionSettings.show"
	[settings]="offerService.confirmRejectionSettings" [isMobile]="topService.isMobile" (onReject)="rejectOffer()">
</app-confirmation-popup>

<app-confirmation-popup *ngIf="offerService.offerErrorPopupSettings.show"
	[settings]="offerService.offerErrorPopupSettings" [isMobile]="topService.isMobile">
</app-confirmation-popup>

<!-- Offer expired -->
<app-confirmation-popup *ngIf="offerService.offerExpiredSettings.show" [settings]="offerService.offerExpiredSettings" [isMobile]="topService.isMobile">
</app-confirmation-popup>

<!-- Accept prior offer popup -->
<app-confirmation-popup *ngIf="offerService.confirmPriorAcceptanceSettings.show"
  [settings]="offerService.confirmPriorAcceptanceSettings" [isMobile]="topService.isMobile" (onAccept)="acceptPreviousOffer()">
</app-confirmation-popup>

<!-- Offer cancellation -->
<app-confirmation-popup *ngIf="offerService.confirmCancellationSettings.show"
  [settings]="offerService.confirmCancellationSettings" [isMobile]="topService.isMobile" (onReject)="cancelOffer()">
</app-confirmation-popup>

<!-- Update confirmation popup -->
<app-confirmation-popup *ngIf="offerService.confirmUpdateSettings.show"
	[settings]="offerService.confirmUpdateSettings" [isMobile]="topService.isMobile" (onAccept)="submitUpdateCounterOffer()">
</app-confirmation-popup>