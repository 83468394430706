<div class="cart-modal-wrap" 
[class.animation]="firstAnimation"
[class.second-animation]="secondAnimation && !shortAnimation"
[class.short-animation]="secondAnimation && shortAnimation"
[class.mobile]="topService.isMobile">
    <div class="fly-check">
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g>
          <circle id="Oval" fill="url(#linearGradient-1)" cx="10" cy="10" r="10"></circle>
          <path d="M13.0851802,6.97832872 C13.4794999,6.55805457 14.1398587,6.53701434 14.5601328,6.93133404 C14.9480782,7.29532145 14.9958479,7.88599306 14.6907584,8.30526884 L14.6071275,8.40628667 L9.79561975,13.5344918 C9.42946945,13.9247424 8.83448279,13.97044 8.41522291,13.6603942 L8.31430304,13.575465 L5.4335031,10.8267289 C5.01655453,10.4288944 5.00105951,9.76838227 5.39889399,9.35143371 C5.76612582,8.96655811 6.35717769,8.92374943 6.77387712,9.23234845 L6.87418921,9.3168246 L8.993,11.339 L13.0851802,6.97832872 Z" id="Path-2" fill="#FFFFFF" fill-rule="nonzero"></path>
        </g>
      </svg>
  </div>
  <div class="cart-modal-content">
    <div class="cart-modal-icon">
      <svg width="60px" height="39px" viewBox="0 0 60 39" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient x1="100%" y1="0%" x2="0%" y2="100%" id="linearGradient-1">
                <stop stop-color="#FE563B" offset="0%"></stop>
                <stop stop-color="#D8214D" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g id="icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path class="first-block" d="M36.9624854,6.52485888 C37.3833893,6.51871816 37.7669353,6.68376381 38.0467906,6.95557063 C38.3284146,7.22909544 38.505033,7.61073529 38.509047,8.03483559 L38.509047,8.03483559 L38.3528126,18.7735208 C38.3467981,19.185767 38.1774237,19.5582574 37.9064912,19.8291899 C37.6355588,20.1001224 37.2630683,20.2694968 36.8508221,20.2755113 L36.8508221,20.2755113 L26.1486258,20.4316496 C25.7277218,20.4377903 25.3441758,20.2727447 25.0643206,20.0009379 C24.7826965,19.7274131 24.6060781,19.3457732 24.6020641,18.9216729 L24.6020641,18.9216729 L24.7582985,8.18298775 C24.764313,7.77074147 24.9336875,7.39825106 25.2046199,7.1273186 C25.4755524,6.85638615 25.8480428,6.68701166 26.2602891,6.68099725 L26.2602891,6.68099725 Z" id="box-1" stroke="#1A1A1A" fill="#FFFFFF" fill-rule="nonzero"></path>
            <path class="second-block" d="M46.2958187,8.03567795 C46.7167226,8.02953722 47.1002687,8.19458287 47.3801239,8.46638969 C47.661748,8.7399145 47.8383663,9.12155435 47.8423803,9.54565466 L47.8423803,9.54565466 L47.6861459,20.2843398 C47.6801315,20.6965861 47.510757,21.0690765 47.2398245,21.340009 C46.9688921,21.6109414 46.5964017,21.7803159 46.1841554,21.7863303 L46.1841554,21.7863303 L35.4819591,21.9424687 C35.0610551,21.9486094 34.6775091,21.7835638 34.3976539,21.5117569 C34.1160298,21.2382321 33.9394114,20.8565923 33.9353975,20.432492 L33.9353975,20.432492 L34.0916319,9.69380681 C34.0976463,9.28156054 34.2670208,8.90907012 34.5379532,8.63813767 C34.8088857,8.36720521 35.1813761,8.19783073 35.5936224,8.19181631 L35.5936224,8.19181631 Z" id="box-2" stroke="#1A1A1A" fill="#FFFFFF" fill-rule="nonzero"></path>
            <path class="cart-part" d="M16.7083966,8.28361871 L22.712361,27.1273384 L24.738937,27.1273384 L20.0436467,12.1585664 L51.7676149,12.1585664 L42.8894449,32.2480685 L19.0313577,32.2480685 L12.993725,13.3095384 L9.93647801,13.3095384 L8.31305854,8.28361871 L16.7083966,8.28361871 Z" id="cart-body" stroke="#1A1A1A" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M26.5,34 C27.8807594,34 29,35.1192406 29,36.5 C29,37.8807594 27.8807594,39 26.5,39 C25.1192406,39 24,37.8807594 24,36.5 C24,35.1192406 25.1193336,34 26.5,34 Z M35.500093,34 C36.880801,34 38,35.1192406 38,36.5 C38,37.8807594 36.880801,39 35.500093,39 C34.119292,39 33,37.8807594 33,36.5 C33,35.1192406 34.119292,34 35.500093,34 Z" id="Combined-Shape" fill="#FFFFFF"></path>
              <g id="check" transform="translate(40.000000, 0.000000)">
                  <circle id="Oval" fill="url(#linearGradient-1)" cx="10" cy="10" r="10"></circle>
                  <path d="M13.0851802,6.97832872 C13.4794999,6.55805457 14.1398587,6.53701434 14.5601328,6.93133404 C14.9480782,7.29532145 14.9958479,7.88599306 14.6907584,8.30526884 L14.6071275,8.40628667 L9.79561975,13.5344918 C9.42946945,13.9247424 8.83448279,13.97044 8.41522291,13.6603942 L8.31430304,13.575465 L5.4335031,10.8267289 C5.01655453,10.4288944 5.00105951,9.76838227 5.39889399,9.35143371 C5.76612582,8.96655811 6.35717769,8.92374943 6.77387712,9.23234845 L6.87418921,9.3168246 L8.993,11.339 L13.0851802,6.97832872 Z" id="Path-2" fill="#FFFFFF" fill-rule="nonzero"></path>
              </g>
          </g>
      </svg>
    </div>
    <span class="cart-modal-text">Added to Cart</span>
  </div>
</div>