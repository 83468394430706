import { Component, OnInit, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { TopComponent } from '../top.component';

@Component({
  selector: 'app-top-mobile',
  templateUrl: './top-mobile.component.html',
  styleUrls: ['./top-mobile.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopMobileComponent extends TopComponent implements OnInit {
  isClearBtnActive = false;

  clearSearch(): void {
    this.topService.searchString = '';
    this.isShowClearBtn();
  }

  isShowClearBtn(value?: string): void {
    if (value && value.length > 0) {
      this.topService.searchString = value;
      this.isClearBtnActive = true;
    } else {
      this.isClearBtnActive = false;
    }
  }

  onSearchClicked() {
    if (this.topService.isSearchActive) {
      this.searchItems(this.topService.searchString);
      this.isShowClearBtn();
    } else {
      this.showSearch();
      this.isShowClearBtn();
    }
  }
}
