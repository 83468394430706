import { OfferEventTypes } from './../../shared/enums/offer-types';
import { ConfirmationPopupSettings } from './../../shared/interfaces/IConfirmationPopupSettings';
import { BehaviorSubject, Observable } from 'rxjs';
import { OfferStatusEnum } from './../../buyer/enums/offerStatusEnum';
import { IItem } from './../../buyer/interfaces/IItem';
import { PubSubService } from '../../core/pubsub.service';
import { TopService } from './../../shared/services/top.service';
import { environment } from './../../../environments/environment';
import { IOfferValidationRules, IOffersSummary } from './../../buyer/interfaces/IOffersSummary';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SharedSource } from '../..//core/shared-source';
import { IMakeOfferErrorResponse } from './../../buyer/interfaces/IMakeOfferErrorResponse';
import { IOffer } from './../../buyer/interfaces/IOffer';
import { IMakeOfferRequest } from './../../buyer/interfaces/IMakeOfferRequest';
import * as models from './../../shared/interfaces/model';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import 'moment-timezone/builds/moment-timezone-with-data';
import { SharedService } from './../../shared/shared.service';
import { PercentPipe } from '@angular/common';
import { FullOfferStatusEnum } from './../../shared/enums/enum';
import * as _ from 'lodash';
import { EffectivePriceResponse } from '../../shared/interfaces/effectivePriceResponse';
@Injectable({
  providedIn: 'root'
})
export class OfferService {
  submitCounterofferReference = {
    fn: null,
    scope: null
  }

  submitUpdateCounterofferReference = {
    fn: null,
    scope: null
  }

  isBuyer;
  isSales;
  isVendorMgr;

  lastDynamicComponentId: string;
  offersInitialized = false;
  confirmationPopupSettings: ConfirmationPopupSettings;
  confirmAcceptanceSettings: ConfirmationPopupSettings;
  confirmCounterofferSettings: ConfirmationPopupSettings;
  confirmCounterofferErrorSettings: ConfirmationPopupSettings;
  confirmRejectionSettings: ConfirmationPopupSettings;
  confirmPriorAcceptanceSettings: ConfirmationPopupSettings;
  confirmUpdateSettings: ConfirmationPopupSettings;

  disableCounterofferFormActions = false;
  offerExpiredSettings: ConfirmationPopupSettings;
  confirmCancellationSettings: ConfirmationPopupSettings;
  selectedOffer: models.Offer;
  selectedOfferItem: models.OfferItemDetail;
  selectedOfferStatusFilter: models.TotalOfferByStatusResponse = {
    displayName: 'All Offers',
    status: FullOfferStatusEnum.All
  };
  offerErrorPopupSettings: ConfirmationPopupSettings;
  offerByStatusResponse: models.OffersByStatusResponse;
  priceOptions: models.PriceOptions[] = [];
  OFFER_VALIDATION_ERROR_STATUS_CODE = 409;
  PRECONDITION_FAILED = 412;

  private _offersSummary: IOffersSummary;
  private _bpUnseenEventCount = 0;
  offerByStatusRequest: models.OffersByStatusRequest;
  showSubmitOfferConfirmationPopup = false;
  showOfferInformationPopup = false;

  lastOwnCounterofferEvent: models.OfferEvent;

  offersCancellationPopupSettings: ConfirmationPopupSettings;
  loadingOfferCancelationData = false;

  itemChanged$: BehaviorSubject<IItem> = new BehaviorSubject<IItem>(undefined);

  constructor(private http: HttpClient,
    private topService: TopService,
    private pubSubService: PubSubService,
    private router: Router,
    private sharedService: SharedService) {
    this.setPopupsSettings();
  }

  setPopupsSettings() {
    this.confirmationPopupSettings = {
      show: false,
      headerText: 'Feature not available',
      bodyText: 'Please, ask your account manager',
      acceptButton: {
        show: true,
        text: 'Got it'
      }
    }

    this.confirmAcceptanceSettings = {
      show: false,
      bodyText: '',
      headerText: `Are you sure you want<br>to accept the offer?`,
      zoomIn: true,
      zoomOut: true,
      acceptButton: {
        show: true,
        text: 'Accept Offer'
      },
      cancelButton: {
        show: true,
        text: 'Back'
      }
    };

    this.confirmPriorAcceptanceSettings = {
      show: false,
      bodyText: '',
      headerText: `Are you sure you want<br>to accept the offer?`,
      zoomIn: true,
      zoomOut: true,
      acceptButton: {
        show: true,
        text: 'Accept Offer'
      },
      cancelButton: {
        show: true,
        text: 'Back'
      }
    };

    this.confirmCounterofferSettings = {
      show: false,
      bodyText: `If ${this.isBuyer ? 'seller' : 'buyer'} accepts your offer, it automatically<br>places a binding order and is non-cancelable<br>or returnable`,
      headerText: `Are you sure you want<br>to make a counteroffer?`,
      zoomIn: true,
      zoomOut: true,
      acceptButton: {
        show: true,
        text: 'Submit Offer'
      },
      cancelButton: {
        show: true,
        text: 'Back'
      }
    };

    this.confirmRejectionSettings = {
      show: false,
      bodyText: '',
      headerText: `Are you sure you want<br>to reject the offer?`,
      zoomIn: true,
      zoomOut: true,
      rejectButton: {
        show: true,
        text: 'Reject Offer'
      },
      cancelButton: {
        show: true,
        text: 'Back'
      }
    };

    this.offerExpiredSettings = {
      show: false,
      headerText: `${this.isBuyer ? 'Your offer has expired<br>(timed out)' : 'Offer expired<br>(timed out)'}`,
      bodyText: '00h 00m 00s',
      zoomIn: true,
      zoomOut: true,
      acceptButton: {
        show: true,
        text: 'Got it'
      }
    }

    this.confirmCounterofferErrorSettings = {
      show: false,
      headerText: '',
      bodyText: '',
      zoomIn: true,
      zoomOut: true,
      acceptButton: {
        show: true,
        text: 'Got it'
      }
    }

    this.offerErrorPopupSettings = {
      show: false,
      headerText: 'Unable to perform this action. Offers status has changed',
      bodyText: '',
      acceptButton: {
        show: true,
        text: 'Got it'
      }
    }

    this.confirmCancellationSettings = {
      show: false,
      bodyText: '',
      headerText: `Are you sure you want<br>to cancel the offer?`,
      zoomIn: true,
      zoomOut: true,
      rejectButton: {
        show: true,
        text: 'Cancel Offer'
      },
      cancelButton: {
        show: true,
        text: 'Back'
      }
    };

    this.confirmUpdateSettings = {
      show: false,
      bodyText: `Editing thts offer/counteroffer will reset the timer to x business day(s)`,
      headerText: `Are you sure you want<br>to update the offer?`,
      zoomIn: true,
      zoomOut: true,
      acceptButton: {
        show: true,
        text: 'Submit Offer'
      },
      cancelButton: {
        show: true,
        text: 'Back'
      }
    };

    this.offersCancellationPopupSettings = {
      show: false,
      headerText: `You are about to cancel offers. Please confirm the process`,
      rejectButton: {
        show: true,
        text: 'Update'
      },
      cancelButton: {
        show: true,
        text: 'Back'
      }
    }
  }

  //#region Buyer Functions
  public get offersSummary(): IOffersSummary {
    return this._offersSummary;
  }

  public set offersSummary(value: IOffersSummary) {
    this._offersSummary = value;
    this.pubSubService.sharedSubject.next({ name: SharedSource.offerSummary, data: true });
  }

  public get bpUnseenEventCount(): number {
    return this._bpUnseenEventCount;
  }

  public set bpUnseenEventCount(value: number) {
    this._bpUnseenEventCount = value;
  }

  public getOfferById(id: string): Observable<models.Offer> {
    const url = environment.adminUrl + 'Offers/' + id;
    return this.http.get<models.Offer>(url);
  }

  public getOfferByIdSummary(id: string): Observable<models.OfferByStatusData> {
    const url = environment.adminUrl + `Offers/${id}/summary`;
    return this.http.get<models.OfferByStatusData>(url);
  }

  getOffersByStatus(payload: models.OffersByStatusRequest): Observable<models.OffersByStatusResponse> {
    const url = environment.adminUrl + 'Offers/status';
    return this.http.post<models.OffersByStatusResponse>(url, payload);
  }

  getTotalOffersByStatus(): Observable<models.TotalOfferByStatusResponse[]> {
    const url = environment.adminUrl + 'offers/total-by-statuses';
    return this.http.get<models.TotalOfferByStatusResponse[]>(url);
  }

  seenOffer(payload: models.seenOfferRequest): Observable<boolean> {
    const url = environment.adminUrl + 'Offers/offerSeen';
    return this.http.post<boolean>(url, payload);
  }

  getOffersSummary(updateUnseenEventCount: boolean = true): Observable<IOffersSummary> {
    const url = `${environment.buyerPortalBaseUrl}Offers/Summary`;
    this.topService.loading = true;
    return this.http.get<IOffersSummary>(url).pipe(
      map((offersSummary: IOffersSummary) => {
        this.topService.loading = false;
        if (updateUnseenEventCount) {
          this.offersSummary = offersSummary;
        } else {
          this.offersSummary.hasMadeOffers = offersSummary.hasMadeOffers;
          this.offersSummary.offersEnabled = offersSummary.offersEnabled;
          this.offersSummary.tokenCount = offersSummary.tokenCount;
          this.offersSummary.validationRules = offersSummary.validationRules;
        }
        return offersSummary;
      })
    );
  }

  getOffersSummaryNoStream(): void {
    const url = `${environment.buyerPortalBaseUrl}Offers/Summary`;
    this.http.get(url).subscribe(
      (offersSummary: IOffersSummary) => {
        this.offersSummary = offersSummary;
      },
      (err) => {
        console.log('Error trying to get offers summary: ', err);
      }
    )
  }

  getEffectivePrice(
    vendorItemId: string, offerQuantity: number
  ): Observable<number> {
    const url = `${environment.adminBaseUrl}api/Offers/vendoritem/${vendorItemId}/quantity/${offerQuantity}/effectivePrice`;
    return this.http
      .get<EffectivePriceResponse>(url)
      .pipe(
        map((effectivePriceResponse) => effectivePriceResponse.effectivePrice),
      );
  }

  handleOfferFeatureSwitched(offerFeatureSwitchedMsg: models.IOfferFeatureSwitchedMessage, cdr: ChangeDetectorRef): void {
    var offersEnabledOldValue = this.offersSummary.offersEnabled;
    this.getOffersSummary()
      .subscribe(
        (offersSummary: IOffersSummary) => {
          this.offersSummary = offersSummary;
          var offersEnabledNewValue = offersSummary.offersEnabled;
          if (offersEnabledOldValue !== offersEnabledNewValue) {
            var isVendor = ['SELLER', 'ADMIN', 'VENDORMGR'].includes(sessionStorage.getItem('user_role'));
            var isSuperOrSalesImpersonated = (['SUPER', 'SALES'].includes(sessionStorage.getItem('user_role')) && sessionStorage.getItem('isCustomerSelected') == 'YES');
            if (isVendor || sessionStorage.getItem('user_role') === 'BUYER' || isSuperOrSalesImpersonated
            ) {
              this.topService.confirmationPopupSettings = {
                show: true,
                bodyText: `If you have any question please contact your representative`,
                headerText: `Your offers have been ${this.offersSummary?.offersEnabled ? 'enabled' : 'disabled'}`,
                acceptButton: {
                  show: true,
                  text: 'Accept'
                }
              };
              this.pubSubService.sharedSubject.next({ name: SharedSource.offerFeatureSwitched, data: models.OfferNotificationTypesEnum.offerFeatureSwitched });
              return;
            }
          }
        },
        (err) => {
          console.log('Error trying to get offers summary: ', err);
        }
      );
  }

  handleCountersDueToNewOfferEvent(event: models.IOfferEventMessage) {
    const userId = sessionStorage.getItem('user_id');
    if (userId === event.senderId) {
      return;
    }
    const instanceId = sessionStorage.getItem('sessionId');
    if (this.selectedOffer?.id === event.offerId) {
      this.seenOffer({ offerId: event.offerId, instanceId: instanceId })
        .subscribe(() => {
          console.info('seenOffer requested when offerEvent Notification Msg received...');
        });
    } else {
      this.getCountersForUserAndBp();
    }
    this.pubSubService.sharedSubject.next({
      name: SharedSource.handleNewOfferEventReceived,
      data: event
    });
  }

  handleCountersDueToOfferSeenReceived(event: models.IOfferSeenMessage) {
    const instanceId = sessionStorage.getItem('sessionId');
    if (event.instanceId === instanceId) {
      return;
    }
    this.getCountersForUserAndBp();
  }

  getCountersForUserAndBp(): void {
    const role = sessionStorage.getItem('user_role');
    var isSuperOrSaleImpersonating = ['SUPER', 'SALES'].includes(role) && sessionStorage.getItem('isCustomerSelected') == 'YES';
    var isAdminOrVendorMgrImpersonating = ['ADMIN', 'VENDORMGR'].includes(role);
    this.getUnseenOffersCounterNoStream();
    if (isSuperOrSaleImpersonating || isAdminOrVendorMgrImpersonating) {
      this.getBpUnseenOffersCounterNoStream();
    }
  }

  getTokenCountNotream(): void {
    const url = `${environment.buyerPortalBaseUrl}offers/summary/tokencount`;
    this.topService.loading = true;
    this.http.get(url).subscribe(
      (tokenCount: number) => {
        this.topService.loading = false;
        this.offersSummary = {
          ...this.offersSummary,
          tokenCount: tokenCount
        };
      },
      (err) => {
        console.log('Error trying to get offers token count: ', err);
        this.topService.loading = false;
      }
    )
  }

  getValidationRulesNotream(): void {
    const url = `${environment.buyerPortalBaseUrl}offers/summary/validationRules`;
    this.topService.loading = true;
    this.http.get(url).subscribe(
      (rules: IOfferValidationRules) => {
        this.topService.loading = false;
        this.offersSummary = {
          ...this.offersSummary,
          validationRules: rules
        };
        this.pubSubService.sharedSubject.next({ name: SharedSource.refreshItemList, data: models.OfferNotificationTypesEnum.offerValidationRulesUpdated });
      },
      (err) => {
        console.log('Error trying to get validation rules: ', err);
        this.topService.loading = false;
      }
    )
  }

  getUnseenOffersCounterNoStream(): void {
    const url = `${environment.adminUrl}offers/summary/unseenEvents`;
    this.http.get(url).subscribe(
      (count: number) => {
        this.offersSummary = {
          ...this.offersSummary,
          unseenEventCount: count
        }
      },
      (err) => {
        console.log('Error trying to get unseen offers counter: ', err);
      }
    )
  }

  makeOfferButtonDisabled(item: IItem): boolean {
    if (!this.offersSummary) { // in case user is guest
      return true;
    }

    // in case user role is super or sales and has not impersonalized other user
    if (['SUPER', 'SALES'].includes(sessionStorage.getItem('user_role')) && !this.topService.isCustomerSelected) {
      return true;
    }

    var itemHasOfferFeatureDisabled = item.offerStatus !== OfferStatusEnum.enabled;
    var buyerHasNoToken = !this.offersSummary.tokenCount;
    var buyerHasOfferFeatureDisabled = !this.offersSummary.offersEnabled;
    var offerItemRestricted = item.offerStatus === OfferStatusEnum.disabledOffersRestricted;

    return itemHasOfferFeatureDisabled || buyerHasNoToken || buyerHasOfferFeatureDisabled || offerItemRestricted;
  }

  offerMade(item: IItem): boolean {
    return item.offerStatus === OfferStatusEnum.offerMade
  }

  makeOffer(offerReq: IMakeOfferRequest): Observable<IOffer | IMakeOfferErrorResponse> {
    const url = environment.notificationCenterUrl + '/api/offers';
    return this.http.post<IOffer | IMakeOfferErrorResponse>(url, offerReq);
  }

  getOfferId(itemId: string): Observable<number> {
    const url = `${environment.buyerPortalBaseUrl}offers/vendoritem/${itemId}/offerId`;
    return this.http.get<number>(url);
  }
  //#endregion

  getBpUnseenOffersCounterNoStream(): void {
    const url = `${environment.adminUrl}offers/summary/unseenEvents/bp`;
    this.topService.loading = true;
    this.http.get(url).subscribe(
      (count: number) => {
        this.topService.loading = false;
        this.bpUnseenEventCount = count;
        this.pubSubService.sharedSubject.next({ name: SharedSource.bpUnseenEventCountUpdated, data: true });
      },
      (err) => {
        console.log('Error trying to get bp unseen offers counter: ', err);
        this.topService.loading = false;
      }
    )
  }

  resetService() {
    this.offerByStatusRequest = null;
    this.offersSummary = null;
    this.showSubmitOfferConfirmationPopup = false;
    this.showOfferInformationPopup = false;
    this.bpUnseenEventCount = 0;
    this.disableCounterofferFormActions = false;
    this.setPopupsSettings();
    this.offersInitialized = false;
  }

  counterOffer(offerId: string, payload: models.CounterOfferRequest) {
    const url = `${environment.adminUrl}offers/${offerId}/counteroffer`;
    return this.http.post<models.CounterOfferResponse>(url, payload);
  }

  showOffersDisabledMessage(newTab = false) {
    const role = sessionStorage.getItem('user_role');
    if (!this.offersSummary || (['SUPER', 'SALES'].includes(role) && !sessionStorage.getItem('isCustomerSelected'))) {
      this.router.navigate(['/user/offers']);
      return;
    }

    if (this.offersSummary?.offersEnabled) {
      if (['BUYER', 'SUPER', 'SALES'].includes(role)) {
        this.router.navigate(['/user/offers']);
        return;
      }

      if (['ADMIN', 'SELLER', 'VENDORMGR'].includes(role)) {
        const route = ['/vendor/user/offers'];
        if (newTab) {
          const url = this.router.serializeUrl(this.router.createUrlTree(route));
          window.open(url, '_blank');
          return;
        }
        this.router.navigate(route);

      }
      return;
    }

    this.confirmationPopupSettings.show = true;
  }

  acceptOffer(payload: models.AcceptOfferRequest): Observable<string> {
    const url = environment.adminUrl + 'Offers/events/accepted';
    return this.http.post<string>(url, payload);
  }

  acceptPreviousOffer(payload: models.AcceptPreviousOfferRequest): Observable<models.AcceptPreviousOfferResponse> {
    const url = `${environment.adminUrl}Offers/${this.selectedOffer.id}/events/previousaccepted`;
    return this.http.post<models.AcceptPreviousOfferResponse>(url, payload);
  }

  rejectOffer(offerId: string, payload: models.RejectOfferRequest): Observable<string> {
    const url = `${environment.adminUrl}offers/${offerId}/rejection`;
    return this.http.post<string>(url, payload);
  }

  cancelOffer(payload: models.CancelOfferRequest): Observable<string> {
    const url = `${environment.adminUrl}Offers/${this.selectedOffer.id}/cancellation`;
    return this.http.post<string>(url, payload);
  }

  getEmptyOfferState(): models.Offer {
    return {
      id: null,
      customerId: null,
      status: null,
      qty: null,
      price: null,
      vendorItemId: null,
      createdOn: null,
      expiresOn: null,
      offerEvents: [],
      companiesInfo: null,
      version: null,
      description: null,
      effectivePrice: null
    };
  }

  verifyIfOfferExpired(pUtcDate): boolean {
    const utcDate = moment.utc(pUtcDate);
    const userTimeZone = moment.tz.guess();
    const timeDiffSeconds = utcDate.tz(userTimeZone).diff(moment(), 'seconds');
    return timeDiffSeconds <= 0;
  }

  getEmptyOfferItemState(): models.OfferItemDetail {
    return {
      itemCode: '',
      manufacturerLogoUrl: '',
      description: '',
      availability: 0,
      listPrice: 0
    };
  }

  isOfferItemDataReady(): boolean {
    return this.selectedOfferItem.itemCode !== ''
      && this.selectedOfferItem.manufacturerLogoUrl !== ''
      && this.selectedOfferItem.description !== ''
  }

  counterOfferPrices(effectivePrice: number, fromMakeOffer = false, isEditingCounteroffer = false): models.PriceOptions[] { //For Buyer
    let result = new Array<models.PriceOptions>();
    const { percentVariation, percentPrecision, maxPercentage } = this.offersSummary.validationRules;

    const fixedPrecision = this.sharedService.countDecimals(percentVariation / 100);
    const pipeDigitInfo = `1.${percentPrecision}-${percentPrecision}`;
    const percentPipe = new PercentPipe('en_US');
    let i = parseFloat((percentVariation / 100).toFixed(fixedPrecision));

    var currentOfferPercentDiscount = 0;
    var previousOfferPercentDiscount = 0;

    var previousOfferQty = 0;
    var previousEffectivePrice = 0;

    var currentOfferQty = 0;
    var length = this.selectedOffer ? this.selectedOffer.offerEvents.length : 0;

    if (isEditingCounteroffer && !this.lastOwnCounterofferEvent) {
      length--;
    }

    if (!fromMakeOffer) {
      const oe = this.selectedOffer.offerEvents[length - 1];

      if (!oe?.metadata) {
        return;
      }

      const currentOfferPrice = JSON.parse(oe.metadata, this.sharedService.toCamelCase).price;
      const previousOfferPrice = length === 1
        ? JSON.parse(this.selectedOffer.offerEvents[0].metadata, this.sharedService.toCamelCase).price
        : JSON.parse(this.selectedOffer.offerEvents[length - 2].metadata, this.sharedService.toCamelCase).price;

      currentOfferQty = JSON.parse(oe.metadata, this.sharedService.toCamelCase).quantity;
      previousOfferQty = length === 1
        ? JSON.parse(this.selectedOffer.offerEvents[0].metadata, this.sharedService.toCamelCase).quantity
        : JSON.parse(this.selectedOffer.offerEvents[length - 2].metadata, this.sharedService.toCamelCase).quantity;

      previousEffectivePrice = JSON.parse(this.selectedOffer.offerEvents[length === 1 ? 0 : length - 2].metadata, this.sharedService.toCamelCase).effectivePrice;

      currentOfferPercentDiscount = parseFloat((100 - (currentOfferPrice * 100 / effectivePrice)).toFixed(2));
      previousOfferPercentDiscount = parseFloat((100 - (previousOfferPrice * 100 / previousEffectivePrice)).toFixed(2));
    }

    var discountApplied = (currentOfferQty !== previousOfferQty) && previousEffectivePrice != effectivePrice;
    var discountOriginatedByBuyerEvent = (length - 1) % 2 === 0; // events in even positions were originated by the buyer


    while (i <= parseFloat((maxPercentage / 100).toFixed(fixedPrecision))) {
      const currentPrice = this.priceOff(effectivePrice, i);
      var disabled =
        fromMakeOffer || (discountApplied && discountOriginatedByBuyerEvent)
          || (discountApplied && !discountOriginatedByBuyerEvent && (i * 100) >= previousOfferPercentDiscount)
          ? false
          : (i * 100) <= currentOfferPercentDiscount || (i * 100) >= previousOfferPercentDiscount;
      result.push({
        price: currentPrice,
        percent: '-'.concat(percentPipe.transform(i, pipeDigitInfo)),
        dropdownOptionDisabled: disabled
      } as models.PriceOptions);
      i = parseFloat((i + percentVariation / 100).toFixed(fixedPrecision));
    }

    return result;
  }

  priceOff(price: number, percent: number) {
    var result = (1 - percent) * price;
    result = Math.ceil(result * 100) / 100;
    return result;
  }

  percentOff(price: number, offerPrice: number) {
    var percent = parseFloat((offerPrice * 100 / price).toFixed(2));
    var percentDiscount = 100 - percent;
    return percentDiscount / 100;

  }

  isFinalCounteroffer(selectedValue: number, isBuyer: boolean, discountApplied = false) {

    if (discountApplied) {
      const length = this.priceOptions.length;

      if (this.isBuyer && this.priceOptions[0].price === selectedValue) {
        return true;
      }
      if (!this.isBuyer && this.priceOptions[length - 1].price === selectedValue) {
        return true;
      }

      return false;
    }
    return isBuyer
      ? !this.priceOptions.filter(po => po.price > selectedValue && !po.dropdownOptionDisabled).length
      : !this.priceOptions.filter(po => po.price < selectedValue && !po.dropdownOptionDisabled).length;
  }

  updateOfferEvent(payload: models.OfferEventUpdate, offerEventId: string) {
    const url = `${environment.adminUrl}offers/${this.selectedOffer.id}/events/${offerEventId}/update`;
    return this.http.post<models.OfferEventUpdateResponse>(url, payload);
  }

  getOfferEventMetadata(eventMessage: models.OfferEvent): models.OfferMetadata {
    let metadata = null;

    if (
      [OfferEventTypes.OfferMade,
      OfferEventTypes.VendorCounteroffered,
      OfferEventTypes.BuyerCounteroffered,
      OfferEventTypes.BuyerAcceptedPreviousOffer,
      OfferEventTypes.VendorAcceptedPreviousOffer,
      OfferEventTypes.BuyerUpdatedOfferEvent,
      OfferEventTypes.VendorUpdatedOfferEvent,
      OfferEventTypes.OrderCreated].includes(eventMessage.eventType)) {
      metadata = JSON.parse(eventMessage.metadata, this.sharedService.toCamelCase) as models.OfferMetadata;
    }

    return metadata;
  }

  getPendingOffers(
    payload: models.PendingOfferRequest
  ): Observable<models.ItemWithPendingOffers[]> {
    const url = `${environment.vendorPortalBaseUrl}/api/offers/status/pending`;
    return this.http.post<models.ItemWithPendingOffers[]>(url, payload);
  }

  getPendingOffersFromDate(payload: models.PendingOfferRequest): Observable<models.ItemWithPendingOffers[]> {
    var cancellationWarningFromDate = sessionStorage.getItem('cancellationWarningFromDate');
    const url = `${environment.vendorPortalBaseUrl}/api/offers?after=${cancellationWarningFromDate}`;
    return this.http.post<models.ItemWithPendingOffers[]>(url, payload);
  }

  showMakeOfferPopup(item: IItem): boolean {
    if(!this.offersSummary){ // in case user is guest
      return false;
    }

    // in case user role is super or sales and has not impersonalized other user
    if (['SUPER', 'SALES'].includes(sessionStorage.getItem('user_role')) && !this.topService.isCustomerSelected ) {
      return false;
    }

    var vendorAcceptOffers = !(OfferStatusEnum.disabledVendorDoesntAcceptOffers === (item.offerStatus & OfferStatusEnum.disabledVendorDoesntAcceptOffers));
    var itemIsNotSoldOut = !(OfferStatusEnum.disabledItemSoldOut === (item.offerStatus & OfferStatusEnum.disabledItemSoldOut));
    var isNotDisabled = item.offerStatus !== OfferStatusEnum.disabled;
    var buyerHasOfferFeatureEnabled = this.offersSummary.offersEnabled;
    var offerItemUnrestricted = item.offerStatus !== OfferStatusEnum.disabledOffersRestricted;

    return vendorAcceptOffers && itemIsNotSoldOut && isNotDisabled && buyerHasOfferFeatureEnabled && offerItemUnrestricted;
  }


}
