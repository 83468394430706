<div class="notification-center-header" [class.mobile]="topService.isMobile">
  <h1 class="notification-center-header__title">
    Notification Center
    <span *ngIf="notificationSvcBuyer.activeTab===NotificationFiltersEnum.all">All Notifications</span>
    <span *ngIf="notificationSvcBuyer.activeTab===NotificationFiltersEnum.archive">Archive</span>
    <span *ngIf="notificationSvcBuyer.activeTab===NotificationFiltersEnum.priceDrop">Price Dropped</span>
    <span *ngIf="notificationSvcBuyer.activeTab===NotificationFiltersEnum.waitlist">Back in Stock!</span>
    <span *ngIf="notificationSvcBuyer.activeTab===NotificationFiltersEnum.moreInventory">Inventory Added</span>
  </h1>
  <ul class="notification-center-header-menu" [class.darken-active]="darkenActive">
    <li class="notification-center-header-menu__item _all">
      <a role="button" (click)="onTabClicked(NotificationFiltersEnum.all)" [class.active]="notificationSvcBuyer.activeTab===NotificationFiltersEnum.all"
      (mouseenter)="onTabHovered($event)" (mouseleave)="onTabMouseLeave()">
        <span class="_sort" [ngClass]="{
                          '_asc': notificationSvcBuyer.activeTab === NotificationFiltersEnum.all && notificationSvcBuyer.sortDirection === NotificationSortingDirection.asc,
                          '_desc': notificationSvcBuyer.activeTab === NotificationFiltersEnum.all && notificationSvcBuyer.sortDirection === NotificationSortingDirection.desc }"> <!--_asc, _desc class modifiers-->
          <span class="_sort-icon">
						<i class="_sort-icon__row _top"></i>
						<i class="_sort-icon__row _middle"></i>
						<i class="_sort-icon__row _btm"></i>
					</span>
				</span>
        <span class="_icon">
          <i class="icon-img _all"></i>
          <i class="counter" *ngIf="counter.all > 0">{{counter.all}}</i>
        </span>
        <span class="_txt">All<br/><span>Notifications</span></span>
      </a>
    </li>
    <li class="notification-center-header-menu__item">
      <a role="button" (click)="onTabClicked(NotificationFiltersEnum.archive)" [class.active]="notificationSvcBuyer.activeTab===NotificationFiltersEnum.archive"
        (mouseenter)="onTabHovered($event)" (mouseleave)="onTabMouseLeave()">
        <span class="_sort"> <!--_asc, _desc class modifiers-->
					<!--<span class="_sort-icon">-->
						<!--<i class="_sort-icon__row _top"></i>-->
						<!--<i class="_sort-icon__row _middle"></i>-->
						<!--<i class="_sort-icon__row _btm"></i>-->
					<!--</span>-->
				</span>
        <span class="_icon">
          <i class="icon-img _archive"></i>
        </span>
        <span class="_txt">Archive</span>
      </a>
    </li>
    <li class="notification-center-header-menu__item">
      <a role="button" (click)="onTabClicked(NotificationFiltersEnum.priceDrop)" [class.active]="notificationSvcBuyer.activeTab===NotificationFiltersEnum.priceDrop"
        (mouseenter)="onTabHovered($event)" (mouseleave)="onTabMouseLeave()">
        <span class="_sort" [ngClass]="{
                          '_asc': notificationSvcBuyer.activeTab === NotificationFiltersEnum.priceDrop && notificationSvcBuyer.sortDirection === NotificationSortingDirection.asc,
                          '_desc': notificationSvcBuyer.activeTab === NotificationFiltersEnum.priceDrop && notificationSvcBuyer.sortDirection === NotificationSortingDirection.desc }"> <!--_asc, _desc class modifiers-->
					<span class="_sort-icon">
						<i class="_sort-icon__row _top"></i>
						<i class="_sort-icon__row _middle"></i>
						<i class="_sort-icon__row _btm"></i>
					</span>
				</span>
        <span class="_icon">
          <i class="icon-img _price-drop"></i>
          <i class="counter" *ngIf="counter.dropPrice > 0">{{counter.dropPrice}}</i>
        </span>
        <span class="_txt">Price<br/>Dropped</span>
      </a>
    </li>
    <li class="notification-center-header-menu__item">
      <a role="button" (click)="onTabClicked(NotificationFiltersEnum.waitlist)" [class.active]="notificationSvcBuyer.activeTab===NotificationFiltersEnum.waitlist"
        (mouseenter)="onTabHovered($event)" (mouseleave)="onTabMouseLeave()">
        <span class="_sort" [ngClass]="{
                          '_asc': notificationSvcBuyer.activeTab === NotificationFiltersEnum.waitlist && notificationSvcBuyer.sortDirection === NotificationSortingDirection.asc,
                          '_desc': notificationSvcBuyer.activeTab === NotificationFiltersEnum.waitlist && notificationSvcBuyer.sortDirection === NotificationSortingDirection.desc }"> <!--_asc, _desc class modifiers-->
					<span class="_sort-icon">
						<i class="_sort-icon__row _top"></i>
						<i class="_sort-icon__row _middle"></i>
						<i class="_sort-icon__row _btm"></i>
					</span>
				</span>
        <span class="_icon">
          <i class="icon-img _back-in-stock"></i>
          <i class="counter" *ngIf="counter.waitlist > 0">{{counter.waitlist}}</i>
        </span>
        <span class="_txt">Back in<br/>Stock!</span>
      </a>
    </li>
    <li class="notification-center-header-menu__item">
      <a role="button" (click)="onTabClicked(NotificationFiltersEnum.moreInventory)" [class.active]="notificationSvcBuyer.activeTab===NotificationFiltersEnum.moreInventory"
        (mouseenter)="onTabHovered($event)" (mouseleave)="onTabMouseLeave()">
        <span class="_sort" [ngClass]="{
                          '_asc': notificationSvcBuyer.activeTab === NotificationFiltersEnum.moreInventory && notificationSvcBuyer.sortDirection === NotificationSortingDirection.asc,
                          '_desc': notificationSvcBuyer.activeTab === NotificationFiltersEnum.moreInventory && notificationSvcBuyer.sortDirection === NotificationSortingDirection.desc }"> <!--_asc, _desc class modifiers-->
					<span class="_sort-icon">
						<i class="_sort-icon__row _top"></i>
						<i class="_sort-icon__row _middle"></i>
						<i class="_sort-icon__row _btm"></i>
					</span>
				</span>
        <span class="_icon">
          <i class="icon-img _inventory-added"></i>
          <i class="counter" *ngIf="counter.moreInventory > 0">{{counter.moreInventory}}</i>
        </span>
        <span class="_txt">Inventory<br/>Added</span>
      </a>
    </li>
    <!--<li class="notification-center-header-menu__item">-->
      <!--<a role="button" (click)="onTabClicked(4)" [class.active]="activeTab===4">-->
        <!--<span class="_icon">-->
          <!--<img src="../../../../assets/images/notification-center/bar-updates.svg" width="48" href="48" alt="">-->
          <!--<i class="counter">{{counter.updates}}</i>-->
        <!--</span>-->
        <!--<span class="_txt">Updates</span>-->
      <!--</a>-->
    <!--</li>-->
  </ul>
  <div class="notification-center-header-select-all">
    <label class="notification-checkbox">
      <input type="checkbox" [indeterminate]="indeterminate" [(checked)]="allSelected" (change)="selectUnselectAll($event)">
      <span class="_label">Select All</span>
      <i class="_icon"></i>
    </label>
  </div>
</div>
