import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { IAuctionItem } from '../../buyer/interfaces/model';

@Component({
  selector: 'app-auction-bid-notif',
  templateUrl: './auction-bid-notif.component.html',
  styleUrls: ['./auction-bid-notif.component.scss']
})
export class AuctionBidNotifComponent implements OnInit {

  @Output() confirmation: EventEmitter<any> = new EventEmitter<any>();
  @Input() auctionItem: IAuctionItem;
  @Input() isMobile: boolean;



  constructor() { }

  ngOnInit() { }

  confirm(data: boolean): void {
    event.stopPropagation();
    event.preventDefault();
    this.confirmation.emit(data);
  }

  getStatusMsg() {
    if (this.auctionItem.ActionCount) {

      if (this.auctionItem.Status === 'Active') {
        if (this.auctionItem.IsWinning) {
          return 'You are winning!';
        } else if (this.auctionItem.ReserveMet) {
          return 'You have been outbid!';
        }
      } else if (this.auctionItem.Status === 'Successful') {
        if (this.auctionItem.IsWinning) {
          return 'You won!';
        } else {
          return 'You lost!';
        }
      }
    }
    return '';
  }
}
