import { eConnectionType } from './connection-type';
import * as signalR from '@microsoft/signalr';
import { HubConnectionState, LogLevel } from '@microsoft/signalr';

export class SignalRConnection {
    hubUrl: string;
    hubConnection: signalR.HubConnection;
    broadcastFnName: string;
    signalName: string;
    manualStop = false;
    sharedSvc;
    options: signalR.IHttpConnectionOptions = { // currently only the offers connection is using the token
        accessTokenFactory: () => {
            return sessionStorage.getItem('access_token');
        }
    };

    constructor(hubUrl: string, signalName: string, broadcastFnName: string, sharedService: any) {
        this.hubUrl = hubUrl;
        this.signalName = signalName;
        this.broadcastFnName = broadcastFnName;
        this.sharedSvc = sharedService;

        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(hubUrl, this.options)
            .configureLogging(LogLevel.Information) // Log level: status messages without errors. Record Informationmessages , Warningand Error.
            // .withAutomaticReconnect([0, 2000, 10000, 30000]) // array of numbers representing the delay in milliseconds to wait before starting each reconnect attempt, yields the default behavior, but can be customized
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    console.assert(retryContext);
                    if (retryContext.previousRetryCount == 0) {
                        return 0;
                    }

                    if (retryContext.previousRetryCount == 1) {
                        return 2000;
                    }

                    if (retryContext.previousRetryCount == 2) {
                        return 10000;
                    }

                    if (retryContext.previousRetryCount >= 3) {
                        return 30000;
                    }
                }
            })
            .build();

        this.hubConnection.onclose(
            (err) => {
                if (this.manualStop) {
                    // console.log('Stopped manually');
                    this.manualStop = false;
                    return;
                }
                // console.log('OnClose event triggered for connection: ' + this.hubUrl);
            }
        );
    }

    start() {
        if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
            // console.log('Trying to start a connection that is already started on ' + this.hubUrl);
            return;
        }
        this.hubConnection.off(this.signalName); // unsubscribe from previous handlers before start to listen to new ones
        this.hubConnection
            .start()
            .then(
                () => {
                    // console.log('Connection started with url: ' + this.hubUrl + ')');
                    this.startListener();
                })
            .catch(
                err => {
                    // console.log('Error while starting connection to ' + this.hubUrl + ':' + err);
                });
    }

    stop() {
        this.manualStop = true;
        if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
            // console.log('Trying to stop a connection that is already stopped on ' + this.hubUrl);
            return;
        }

        this.hubConnection
            .stop()
            .then(
                () => {
                    // console.log('Connection stopped with url: ' + this.hubUrl + ')');
                })
            .catch(
                err => {
                    // console.log('Error while stoping connection to ' + this.hubUrl + ':' + err);
                });
    }


    startListener() {
        this.hubConnection.on(this.signalName, (data) => {
            // console.log(`SignalR '${this.signalName}' sent signal with data: `, data);
            this.sharedSvc[this.broadcastFnName] = data;
        });
    }
}
