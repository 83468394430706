<div class="container-fluid">
  <div class="col-9 split-line-wrap">
    <div class="col-12 app-split-line">

      <div class="row">
      <div class="col checkbox">
        <div class="filter-checkbox" (click)="selectLine()">
          <input type="checkbox" [checked]="line.lineSelected">
          <label></label>
        </div>
      </div>

      <div class="col split-bottom-top-wrap">
      <div class="split-item-content">
        <div class="row">
          <div class="col-12 cont">

            <div class="row adjust">
              <!--<div class="col item-logo">-->
                <!--<div class="hide"></div>-->
                <!--<div style="background-image: url('http://cdn.hubx.com/assets/images/item-placeholder.svg');"></div>-->
              <!--</div>-->

              <div class="col description">
              {{line.itemName}}&nbsp; - &nbsp;
                <span class="copy-number" (mouseover)="copyState()" (click)=copyText(line.itemCode)>{{line.itemCode}}
              <span ngModel="copied" ngDefaultControl>{{ copied }}</span>
            </span>

              </div>

              <div class="p-2 price">
                <span class="col-label">Price</span>
                <span class="col-value">{{line.unitPrice}}</span>
              </div>

              <div class="p-2 qty">
                <span class="col-label">QTY</span>
                <span class="col-value">{{line.quantity}}</span>
              </div>

              <div class="p-2 move">
                <span class="col-label">Move</span>
                <input [ngClass] = "{hide: !line.lineSelected}"
                       (focus)='prevQty = line.movedQuantity'
                       appOnlyDigits
                       type="number"
                       (keyup)="quantityChanged()"
                       [(ngModel)]="line.movedQuantity">
                <span [ngClass] = "{hide: line.lineSelected}">-</span>
              </div>

              <div class="p-2 split">
                <span class="col-label">Split</span>
                <select [ngClass] = "{hide: !line.lineSelected}"
                        [(ngModel)]="line.movedDocNumber">
                  <option value ="0">New Order</option>
                  <option *ngFor="let order of orderList" value={{order.docEntry}}>
                    {{order.docNumber}}
                  </option>
                </select>
                <span [ngClass] = "{hide: line.lineSelected}">-</span>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!--<div class="split-item-bottom">-->
        <!--<div class="row">-->
          <!--<div class="col-12">-->
            <!--<ul>-->
              <!--<li><img src="../../../../assets/images/icons/Condition.svg"/>-->
                <!--<span>Refurbished Open Box</span>-->
              <!--</li>-->
              <!--<li><img src="../../../../assets/images/icons/Condition.svg"/>-->
                <!--<span>Refurbished Open Box</span>-->
              <!--</li>-->
              <!--<li><img src="../../../../assets/images/icons/Condition.svg"/>-->
                <!--<span>Refurbished Open Box</span>-->
              <!--</li>-->
            <!--</ul>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
      </div><!-- SPLIT BOTTOM TOP WRAP -->

      </div><!-- ROW -->

    </div>
  </div>
</div>
