<div class="item" [class.err]="cartItem.statusCode!=='Ok'">
  <div class="item__order" [ngClass]="{'item__order_added' : selectedOrderNumber && cartItem.orderLineNum === -1}">
    <span>#{{i + 1}}</span>
  </div>
  <!-- delete btn -->
  <a [class.disabled]="!cartItemsService.allowsEdition(cartItem, user_role)" role="button"
    (click)="cartItemsService.deleteItem(cartItem, $event, user_role, loading, deleteSpinner, deleteItemEmitter)"
    class="close" [class.spinner]="deleteSpinner && deleteSpinnerItem === cartItem.id"
    [hidden]="userRole === 'SALES' && cartItem.orderLineNum !== -1">
    <!--<img src="assets/images/delete.svg" width="24px" height="24px" />-->
  </a>


  <div class="item__main">
    <div class="row margin-0">
      <div class="col item__main--left">
        <div class="row">

          <!-- logo -->
          <div class="item__logo--wrapper">
            <div class="item__logo" (click)="openModal($event)">
              <div *ngIf="cartItem.item.manufacturerLogoUrl"
                [ngStyle]="{'background-image': 'url(' + ( !cartItem.item.manufacturerLogoUrl.includes(imageBaseUrl) ? imageBaseUrl : '') + cartItem.item.manufacturerLogoUrl + ')'}">
              </div>
              <div *ngIf="!cartItem.item.manufacturerLogoUrl"
                style="background-image: url('http://cdn.hubx.com/assets/images/item-placeholder.svg');">
              </div>
            </div>
          </div>

          <!-- description and code -->
          <div class="col item__description pl-0">
            {{cartItem.item.description}}&nbsp; - &nbsp;
            <span class="copy-number" (mouseover)="cartItemsService.copyState(copyStateEmitter)"
              (click)="cartItemsService.copyText($event, cartItem.itemCode)">{{cartItem.itemCode}}
              <span ngModel="cartItemsService.copied" ngDefaultControl>{{ cartItemsService.copied }}</span></span>
          </div>

          <!-- grading guide -->
          <div class="col grading" *ngIf="cartItem.item.gradingGuideUrl"
            (click)="cartItemsService.openGradingGuide($event, cartItem, gradingGuideEmitter)">
            <div class="row">
              <div class="col-6 align-self-center grading-back"></div>
              <div class="col-6 no-padding grading-label align-self-center">Grading Guide</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-auto item__main--right">
        <!-- exw  -->
        <div class="row justify-content-end">
          <div class="col item__attr">
            <span class="attr__title">EXW</span>
            <span class="attr__value"> {{ cartItem.item.exwPoint }}</span>
          </div>

          <!-- Available -->
          <div class="col item__attr">
            <span class="attr__title">Available </span>
            <div class="text-center item-available-wrap">
              <ng-container *ngIf="cartItem.item.inventory.availableToSell>0">
                <div [ngClass]="{'adjust': cartItem.item.inventory.enRoute}">
                  <div
                    [ngClass]="{'item-avail': cartItem.item.inventory.enRoute, 'hide': !cartItem.item.inventory.onHand}"
                    class="d-flex justify-content-center">
                    <span class="item-available in-stock">In
                      Stock</span>
                    <span *ngIf="!cartItemsService.checkMoreThanOnHand(cartItem, userRole)"
                      class="item-available-label">{{cartItem.item.inventory.onHand}}</span>
                    <span class="item-available-label" *ngIf="cartItemsService.checkMoreThanOnHand(cartItem, userRole)">300+</span>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div [ngClass]="{'hide': !cartItem.item.inventory.enRoute}">
                  <div class="d-flex justify-content-center">
                    <span class="item-available in-transit">In Transit</span>
                    <span class="item-available-label">
                      {{cartItemsService.calculateInventoryEnRoute(cartItem, userRole)}}
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="clearfix"></div>
              </ng-container>

              <ng-container *ngIf="cartItem.item.inventory.availableToSell<1">
                <div class="item-sold">
                  Sold Out
                </div>
              </ng-container>
            </div>
          </div>

          <!-- from qty + price -->
          <div class="col item__count">
            <div [ngClass]="{'hide': (cartItem.item.moq>1 || cartItem.item.prices.length<1)}">
              <div *ngFor="let price of cartItem.item.prices">
                <div class="item__count--wrapper">
                  <div class="moq-wrap">
                    <span class="price-qty">{{price.fromQty}}+</span>
                    <span class="unit-price">{{price.unitPrice | CustomCurrency}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- moq -->
          <div *ngIf="cartItem.item.moq >1" class="item__attr" [ngClass]="{'item__attr__input': user_role!=='BUYER'}">
            <div class="wrapper col">
              <div [ngClass]="{'displayItemMoq': showItemMOQ}" class="itemMoqTooltip listing-moq-tt">Minimum Order Quantity</div>
              <span (mouseenter)="itemMOQTooltip()" (mouseleave)="itemMOQTooltip()" class="attr__title">MOQ</span>
            </div>
            <span class="attr__value attr_price">{{cartItem.item.moq}}</span>
          </div>

          <!-- mxq -->
          <div *ngIf="cartItem.item.mxq > 0" class="item__attr">
            <div class="wrapper col">
              <div [ngClass]="{'displayItemMxq': showItemMXQ}" class="itemMxqTooltip listing-mxq-tt">Maximum Quantity</div>
              <span (mouseenter)="itemMXQTooltip(1)" (mouseleave)="itemMXQTooltip(1)" class="attr__title">MXQ</span>
            </div>
            <span class="attr__value attr_price">{{ cartItem.item.mxq }}</span>
          </div>

          <!-- unitPrice -->
          <div class="col item__attr" [ngClass]="{'item__attr__input': userRole!=='BUYER'}">
            <span class="attr__title">Price</span>
            <span *ngIf="userRole==='BUYER'" class="attr__value attr_price">{{cartItem.unitPrice |
              CustomCurrency }}</span>


            <span class="attr__value attr_price" *ngIf="userRole !=='BUYER'">
              <input [disabled]="isOutOfStock || !cartItemsService.allowsEdition(cartItem, user_role)" type="text"
                (change)="cartItemsService.handlePriceUpdate(cartItem, $event.target.value, handlePriceUpdateEmitter)"
                class="edit-order-input" [ngModel]="cartItem.unitPriceHelper | currency:'USD'"
                onkeypress='return event.charCode ===0 || event.charCode ===46 || (event.charCode >= 48 && event.charCode <= 57)' />

              <span class="original-price hide">{{cartItem.overwrittenPrice?cartItem.overwrittenPrice:cartItem.unitPrice
                | CustomCurrency}}</span>
              <span class="warning pop animated zoomIn hide">
                <span (click)="showWarning = !showWarning" class="close"></span>Warning, price is below minimum</span>
            </span>
          </div>


          <!-- quantity -->
          <div class="col item__attr qty item__attr__input">
            <div class="qty-wrap" [ngClass]="{'redSquare': cartItem.statusCode!=='Ok'}">
              <span class="attr__title">QTY</span>

              <div class="wrapper qty-wrap">
                <div *ngIf="userRole!=='BUYER' && cartItem.item.mxq <= 0" [ngClass]="{'hide': cartItem.reservedQty === 0}"
                  class="tooltip qty">Original
                  Order QTY: {{ cartItem.reservedQty }}</div>
                <div *ngIf="cartItem.item.mxq > 0" 
                  [ngClass]="{'displayItemMxq': showInputMXQ, 'two-rows': userRole!=='BUYER' && cartItem.reservedQty !== 0}" 
                  class="itemMxqTooltip listing-mxq-tt"
                >
                  <p *ngIf="userRole!=='BUYER' && cartItem.reservedQty !== 0">Original Order QTY: {{ cartItem.reservedQty }}</p>
                  <p class="_mxq-txt">Maximum Quantity {{ cartItem.item.mxq }}</p>
                </div>
                <input [disabled]="isOutOfStock  || cartItemsService.isQtyEditable(cartItem, user_role)"
                  type="number" (mouseenter)="itemMXQTooltip(2)" (mouseleave)="itemMXQTooltip(2)"
                  [ngClass]="{'edit-order-input': userRole!=='BUYER'}"
                  (keyup)="cartItemsService.handleQuantityUpdate(cartItem, searchSubject)"
                  [(ngModel)]="cartItem.quantity" appOnlyDigits #qty />
              </div>
            </div>
          </div>

          <!-- total -->
          <div class="col item__attr _total-col">
            <span class="attr__title">Total</span>
            <span class="attr__value attr_total">
              <strong>
                {{((cartItem.overwrittenPrice)?cartItem.overwrittenPrice:cartItem.unitPrice) * cartItem.quantity
                | CustomCurrency}}
              </strong>
            </span>
          </div>

        </div>
      </div>

      <!-- message  -->
      <div *ngIf="cartItem.statusMessage" class="line-error col-12">
        <div class="d-flex flex-row">
          <div class="icon">
            <img src="https://cdn.hubx.com/assets/images/exclamation-mark.svg" width="24px" height="24px" /> </div>
          <div class="align-self-center" *ngIf="cartItem.statusCode !== 'RestrictedCountry'">
            {{cartItem.statusMessage}}
          </div>
          <div *ngIf="cartItem.statusCode === 'RestrictedCountry'" class="align-self-center">
            Restrictions Notice! This item has territory restrictions, you're not authorized to purchase this item. Please remove from cart.
          </div>
        </div>
      </div>
      
      <!-- Restriction based on country error  -->
      <!-- <ng-template ngFor let-restrictedId [ngForOf]="cartService.restrictedItemIds">
        <div *ngIf="restrictedId === cartItem.id" class="line-error col-12">
          <div class="d-flex flex-row">
            <div class="icon">
              <img src="https://cdn.hubx.com/assets/images/exclamation-mark.svg" width="24px" height="24px" /> </div>
            <div class="align-self-center">
              Restrictions Notice! This item has territory restrictions, you're not authorized to purchase this item. Please remove from cart.
            </div>
          </div>
        </div>
      </ng-template> -->

    </div>
  </div>

  <!-- attributes -->
  <div class="item__bottom">
    <div class="row">
      <app-item-attributes class="col item__attributes item-details" [isCart]="true" [item]="cartItem.item"
        [hideText]="hideText" [moreThanOnHand]="cartItemsService.checkMoreThanOnHand(cartItem, user_role)"></app-item-attributes>
    </div>
  </div>
</div>
