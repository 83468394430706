import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vendor-items-preloader',
  templateUrl: './vendor-items-preloader.component.html',
  styleUrls: ['./vendor-items-preloader.component.scss']
})
export class VendorItemsPreloaderComponent implements OnInit {
	@Input() source: string;
  @Input() isCheckbox: boolean = false;

  preloaderItemList = Array(4);

  constructor() { }

  ngOnInit(): void {
  	switch (this.source) {
      case 'detailed':
      	this.preloaderItemList.length = 4;
        break;
      case 'simple':
        this.preloaderItemList.length = 5;
        break;
      case 'notifications':
        this.preloaderItemList.length = 5;
        break;
      case 'dashboardNotifications':
        this.preloaderItemList.length = 5;
        break;
      case 'pendingPo':
        this.preloaderItemList.length = 15;
        break;
      case 'purchase':
        this.preloaderItemList.length = 15;
        break;
      default:
      this.preloaderItemList.length = 4;
    }
  }


}
