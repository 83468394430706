<div class="item-waitlist-tooltip-wrapper" [style.left]="leftTooltip+'px'" [style.top]="topTooltip+'px'" [class.active]="showWaitlistTooltip">
	<div class="item-waitlist-tooltip">
	  <div class="col">
	    <div class="item-waitlist-tooltip-img">
	      <img *ngIf="!item.notificationInfoObj.waitlist" src="assets/images/icons/popup-bell.svg"
	        width="60" height="60" alt="bell">
	      <img *ngIf="item.notificationInfoObj.waitlist" src="assets/images/icons/popup-bell-added.svg"
	        width="60" height="60" alt="bell">
	    </div>
	    <div class="item-waitlist-tooltip-header">
	      <span *ngIf="!item.notificationInfoObj.waitlist">This item is sold out!</span>
	      <span *ngIf="item.notificationInfoObj.waitlist">Nice!</span>
	    </div>
	    <div class="item-waitlist-tooltip-desc">
	      <span *ngIf="!item.notificationInfoObj.waitlist">We can add you to the waitlist and notify you
	        when item is back in stock.</span>
	      <span *ngIf="item.notificationInfoObj.waitlist">You have been added to the waitlist for this
	        item.</span>
	    </div>
	  </div>
	</div>
</div>