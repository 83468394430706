<div class="top-total" [class.mobile]="topService.isMobile">
  <div class="row justify-content-md-between align-items-center">
    <div class="col-auto header-title-col">
      <h1>Shopping Cart</h1>

      <div *ngIf="topService.isMobile" class="export-to-excel-mobile">
        <button (click)="exportToCSV()">
          <i>
            <img src="../../../../assets/images/icons/item-filter/excel.svg" width="24"
            height="24" />
          </i>
        </button>
      </div>

    </div>
    <div class="col-auto top-total__numbers">
      <div class="row justify-content-md-between justify-content-sm-start top-total__numbers-item">
        <div class="col-auto cart-total-items">Total Items</div>
        <div class="col-auto cart-total-items-sum text-center">{{totalItems}}</div>
      </div>
      <div class="row justify-content-md-between justify-content-sm-start top-total__numbers-item">
        <div class="col-auto cart-total-items tu">
          Total Units
        </div>
        <div class="col-auto cart-total-items-sum text-center">
          {{totalUnits}}
        </div>
      </div>

      <div class="row justify-content-md-between justify-content-sm-start top-total__numbers-item">
        <div class="col-auto cart-total-items tu">
          <strong>Total Order</strong>
        </div>
        <div class="col-auto cart-total-items-sum">
          <strong>
            {{totalAmount | CustomCurrency}}
          </strong>
        </div>
      </div>

    </div>
    <div class="col-auto top-total__btns" *ngIf="!topService.isMobile">
      <div class="row align-items-center justify-content-sm-between justify-content-center top-total__btns-row">
        <div class="col-auto export-to-excel-icon-btn">
          <app-export-to-excel [data]="items" [isCart]="true" (exportToExcelEmitter)="exportToCSV()"></app-export-to-excel>
        </div>
        <div class="col-auto">
          <div>
            <button [disabled]="findAllErrors"
                    [class.disabled]="findAllErrors"
                    *ngIf="showAddToExistingOrder"
                    (click)="showModalToggle()"
                    class="cart-btn blue">
              Add to Existing Order
            <!-- <span *ngIf="loading" class="cart-loader"></span> -->
            </button>
          </div>
          <a *ngIf="selectedOrderNumber"
              (click)="cancelItem()"
              class="cart-btn blue">
            Cancel
          </a>
        </div>
        <div class="col-sm-auto col-xs-12 or" *ngIf="showAddToExistingOrder ">
          <span>OR</span>
        </div>
        <div class="col-auto">
             <button [disabled]="findAllErrors" *ngIf="!isOutOfStock" (click)="rejectAddtoExisting()" class="cart-btn red">
            Proceed to Checkout
          </button>
        </div>
        <div class="col-auto hide">
          <button [disabled]="findAllErrors || loading || topService.loading"
                      class="edit-order btn btn-update-order"
                      [ngClass]="{'disabled': findAllErrors, 'edit-order btn btn-update-order': !loading && selectedOrderNumber || loading && selectedOrderNumber}"
          >
            <span *ngIf="loading" class="cart-loader"></span>
            <span *ngIf="!selectedOrderNumber">Place New Order</span>
            <span *ngIf="loading && !selectedOrderNumber">Placing New Order...</span>
            <span *ngIf="!loading && selectedOrderNumber">Update Order</span>
            <span *ngIf="loading && selectedOrderNumber">Updating Order...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
