<div class="preloader" #preloader>
	<ng-container *ngIf="!topService.gridView && !isFromPastPurchases && !isFromJustSold && !isFromNotificationCenter">
	  <div class="preloader-item" [class.animation-on]="true" *ngFor="let preloaderItem of preloaderItemList">
	    <div class="preloader-item-left">
	      <div class="preloader-item-text __desc"></div>
	    </div>
	    <div class="preloader-item-shadow">
	    </div>
	    <div class="preloader-item-right">
	      <div class="preloader-item-circle"></div>
	      <div class="preloader-item-text __margin-30"></div>
	      <div class="preloader-item-text"></div>
	      <div class="preloader-item-text"></div>
	      <div class="preloader-item-offer"></div>
	      <div class="preloader-item-bell">
	        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
	            <g id="preload" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
	                <g id="preloader-desktop" transform="translate(-1049.000000, -541.000000)" fill="#D7D7D7" fill-rule="nonzero">
	                    <g id="done-bottom-copy-4" transform="translate(35.000000, 532.000000)">
	                        <g id="Icons/header-bell-Copy-2" transform="translate(1014.000000, 9.000000)">
	                            <path d="M13.54,18.9463444 C13.54,19.891278 12.774555,21 11.84,21 L11.84,21 L11.16,21 C10.225445,21 9.46,19.891278 9.46,18.9463444 L9.46,18.9463444 Z M11.3348925,3.00064852 C11.3916285,3.0009597 11.4467287,3.00126585 11.5,3.00126585 L11.5812545,3.0010739 C11.6087804,3.00095849 11.6367395,3.0008042 11.6651075,3.0006487 L11.9308089,3.0002334 C13.4304204,3.00886717 15.7379678,3.28587917 16.0125654,8.26229584 C16.2635602,12.8393739 19.6101571,15.7191714 19.6101571,15.7191714 C19.8424144,15.9432676 19.9817681,16.2482318 20,16.5723114 L20,16.5723114 L20,17.4002532 C19.997081,17.6847207 19.7686864,17.9140479 19.4873298,17.915017 L19.4873298,17.915017 L19.4873298,17.9186167 L3.51267016,17.9186167 C3.22993577,17.9176292 3.00097664,17.6861274 3,17.4002532 L3,17.4002532 L3,16.5687117 C3.0180556,16.2445876 3.15743774,15.939561 3.38984293,15.7155717 C3.38984293,15.7155717 6.73643979,12.8411738 6.98743455,8.2586961 C7.26203219,3.28554586 9.56957961,3.00883631 11.0691911,3.00023055 Z" id="bell"/>
	                        </g>
	                    </g>
	                </g>
	            </g>
	        </svg>
	      </div>
	      <div class="preloader-item-input"></div>
	      <div class="preloader-item-button"></div>
	    </div>
	  </div>
	</ng-container>

	<ng-container *ngIf="topService.gridView || isFromPastPurchases || isFromJustSold || isFromNotificationCenter">
	   <div class="preloader-item __listing" 
	   [class.animation-on]="true"
	   [class.__past-purchases]="isFromPastPurchases"
	   [class.__just-sold]="isFromJustSold"
	   [class.__notifications]="isFromNotificationCenter"
	   *ngFor="let preloaderItem of preloaderItemList"
	   >
	    <div class="preloader-item-top">
	      <div class="preloader-item-logo">
	      </div>
	      <div class="preloader-item-description">
	        <span></span>
	        <span></span>
	      </div>
	      <div class="preloader-item-values">
	        <div>
	          <span></span>
	          <span></span>
	        </div>
	        <div>
	          <span></span>
	          <span></span>
	        </div>
	        <div>
	          <span></span>
	          <span></span>
	        </div>
	      </div>
	    </div>
	    <div class="preloader-item-bottom">
	      <div class="preloader-item-circle"></div>
	      <div class="preloader-item-circle"></div>
	      <div class="preloader-item-text __long"></div>
	      <div class="preloader-item-circle"></div>
	      <div class="preloader-item-text"></div>
	      <div class="preloader-item-circle"></div>
	      <div class="preloader-item-text"></div>
	      <div *ngIf="!isFromNotificationCenter" class="preloader-item-offer"></div>
	      <div class="preloader-item-bell">
	        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
	            <g id="preload" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
	                <g id="preloader-desktop" transform="translate(-1049.000000, -541.000000)" fill="#D7D7D7" fill-rule="nonzero">
	                    <g id="done-bottom-copy-4" transform="translate(35.000000, 532.000000)">
	                        <g id="Icons/header-bell-Copy-2" transform="translate(1014.000000, 9.000000)">
	                            <path d="M13.54,18.9463444 C13.54,19.891278 12.774555,21 11.84,21 L11.84,21 L11.16,21 C10.225445,21 9.46,19.891278 9.46,18.9463444 L9.46,18.9463444 Z M11.3348925,3.00064852 C11.3916285,3.0009597 11.4467287,3.00126585 11.5,3.00126585 L11.5812545,3.0010739 C11.6087804,3.00095849 11.6367395,3.0008042 11.6651075,3.0006487 L11.9308089,3.0002334 C13.4304204,3.00886717 15.7379678,3.28587917 16.0125654,8.26229584 C16.2635602,12.8393739 19.6101571,15.7191714 19.6101571,15.7191714 C19.8424144,15.9432676 19.9817681,16.2482318 20,16.5723114 L20,16.5723114 L20,17.4002532 C19.997081,17.6847207 19.7686864,17.9140479 19.4873298,17.915017 L19.4873298,17.915017 L19.4873298,17.9186167 L3.51267016,17.9186167 C3.22993577,17.9176292 3.00097664,17.6861274 3,17.4002532 L3,17.4002532 L3,16.5687117 C3.0180556,16.2445876 3.15743774,15.939561 3.38984293,15.7155717 C3.38984293,15.7155717 6.73643979,12.8411738 6.98743455,8.2586961 C7.26203219,3.28554586 9.56957961,3.00883631 11.0691911,3.00023055 Z" id="bell"/>
	                        </g>
	                    </g>
	                </g>
	            </g>
	        </svg>
	      </div>
	      <div class="preloader-item-input"></div>
	      <div class="preloader-item-button"></div>
	    </div>
	    <div class="preloader-item-additional" 
	    *ngIf="isFromPastPurchases || isFromJustSold"
	    [class.just-sold]="isFromJustSold">
	    	<div class="preloader-item-additional-wrap">
	    		<ng-container *ngIf="isFromPastPurchases">
	    			<div class="preloader-item-additional-header">
	    				<span></span>
	    				<span></span>
	    			</div>
	    			<div class="preloader-item-additional-values">
		    			<div>
		    				<span class="_long"></span>
		    				<span class="_long"></span>
		    				<span ></span>
		    			</div>
		    			<div>
		    				<span></span>
		    				<span></span>
		    				<span></span>
		    			</div>
		    		</div>
		    	</ng-container>
		    	<ng-container *ngIf="isFromJustSold">
		    		<div class="preloader-item-additional-header">
	    				<span></span>
	    			</div>
	    			<div class="preloader-item-additional-values">
		    			<div>
		    				<span class="_long"></span>
		    				<span class="_long"></span>
		    				<span ></span>
		    			</div>
		    			<div>
		    				<span></span>
		    				<span></span>
		    				<span></span>
		    			</div>
		    		</div>
		    	</ng-container>
	    	</div>
	    </div>
	    <div class="preloader-item-notifications" *ngIf="isFromNotificationCenter">
	    	<div class="preloader-item-notifications-wrap">
	    		<div class="preloader-item-notification">
	    			<div class="preloader-item-notification-icon">
	    				<img src="../../../assets/images/icons/items-preloader/price-drop.svg" alt="Notification Icon">
	    			</div>
	    			<div class="preloader-item-notification-text">
	    				<span></span>
	    				<span></span>
	    			</div>
	    		</div>
	    		<div class="preloader-item-notification">
	    			<div class="preloader-item-notification-icon">
	    				<img src="../../../assets/images/icons/items-preloader/back.svg" alt="Notification Icon">
	    			</div>
	    			<div class="preloader-item-notification-text">
	    				<span></span>
	    				<span></span>
	    			</div>
	    		</div>
	    		<div class="preloader-item-notification">
	    			<div class="preloader-item-notification-icon">
	    				<img src="../../../assets/images/icons/items-preloader/added.svg" alt="Notification Icon">
	    			</div>
	    			<div class="preloader-item-notification-text">
	    				<span></span>
	    				<span></span>
	    			</div>
	    		</div>
	    	</div>
	    </div>
	  </div>
	</ng-container>
</div>