<div class="faq-modal" [class.mobile]="topService.isMobile" (click)="clickOnWrapper($event)">
  <div class="faq-modal-dialog">
    <div class="faq-modal-content">
      <div class="faq-modal-slider">
        <owl-carousel
          [options]="sliderOptions"
          [carouselClasses]="['faq-slider']" #owlSlider>
          <div class="faq-slider-step">
            <div class="faq-slider-step__img _first"></div>
            <span class="faq-slider-step__title">Create your offers with ease</span>
            <p class="faq-slider-step__txt">You have 3 tokens at your disposal. This allows<br/> you to have up to 3 active offers at the same<br/> time. At the end of the offer, the token is<br/> returned to your balance.</p>
          </div>
          <div class="faq-slider-step">
            <div class="faq-slider-step__img _second"></div>
            <span class="faq-slider-step__title">Manage like a chat</span>
            <p class="faq-slider-step__txt">Engage with the vendor through counteroffers<br/> until you reach an optimal price for both parties.<br/> This does not require tokens.</p>
          </div>
          <div class="faq-slider-step">
            <div class="faq-slider-step__img _third"></div>
            <span class="faq-slider-step__title">Always be aware</span>
            <p class="faq-slider-step__txt">Receive notifications of all replies<br/> on the site and SMS.</p>
          </div>
        </owl-carousel>
        <div class="faq-slider-buttons">
	        <button type="button" (click)="closeModal()" class="faq-slider-btn __close">
	          Close
	        </button>
	        <button type="button" (click)="goToNextSlide()" class="faq-slider-btn">
	          {{currentSliderIndex === 2 ? 'Got It': 'Next'}}
	        </button>
	    </div>
      </div>
    </div>
  </div>
</div>