import {Component, OnInit, ViewChild} from '@angular/core';
import {OwlCarousel} from 'ng2-owl-carousel';

@Component({
  selector: 'app-notification-center-slider',
  templateUrl: './notification-center-slider.component.html',
  styleUrls: ['./notification-center-slider.component.scss']
})
export class NotificationCenterSliderComponent implements OnInit {

  private _timestamp_mousewheel = 0;

  @ViewChild('owlSlider', { static: true }) owlSlider: OwlCarousel;

  constructor() {
  }

  ngOnInit() {
  }

  onSliderMousewheel(event: MouseWheelEvent): void {
    event.preventDefault();
    const date = new Date();
    if ((date.getTime() - this._timestamp_mousewheel) > 700) {
      this._timestamp_mousewheel = date.getTime();
      if (event.deltaY > 0.5) {
        this.owlSlider.next([400]);
      } else if (event.deltaY < -0.5) {
        this.owlSlider.previous([400]);
      }
    }

  }

}
