<div class="purchase" *ngIf="item" [class.collapsed]="!showInfo">
	<div class="purchase-row">
		<div class="purchase-col purchase-icon">
			<img width="32" height="32" src="assets/images/icons/header/past-purchase-white.svg">
		</div>
		<div class="purchase-col">
			<div class="purchase-number">Past Purchase <span class="order" (click)="serachInPastPurchase(item.pastPurchaseInfo.documentNumber)">#{{ item.pastPurchaseInfo.documentNumber }} </span></div>
			<div class="purchase-date">
				{{ item.pastPurchaseInfo.purchaseDate | date:'shortDate' }}
			</div>
		</div>
		<div class="purchase-col collapse-btn" (click)="triggerInfo()">
			<svg 
			[class.closed]="!showInfo"
			height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m7.41 9 4.59 4.3265857 4.59-4.3265857 1.41 1.3319838-6 5.6680162-6-5.6680162z" fill="#fff" opacity=".5"/></svg>
		</div>
	</div>
	<div class="purchase-row collapsed-row">
		<div class="purchase-col">
			<div class="name">Total Units</div>
			<div class="value">{{ item.pastPurchaseInfo.quantitySold }}</div>
		</div>
		<div class="purchase-col">
			<div class="name">Purchase Price</div>
			<div class="value">{{ item.pastPurchaseInfo.priceSold | currency:'USD':'symbol-narrow' }}</div>
		</div>
		<div class="purchase-col">
			<div class="name">Total</div>
			<div class="value">{{ item.pastPurchaseInfo.priceSold * item.pastPurchaseInfo.quantitySold | currency:'USD':'symbol-narrow' }}</div>
		</div>
	</div>
</div>
