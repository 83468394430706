<div *ngIf="!userService.foundBP" class="vendor-login-wrap" [class.mobile]="topService.isMobile">
  <div class="vendor-login">
    <div class="vendor-login-modal-wrap">
      <div class="vendor-login-modal">
        <div class="vendor-login-modal-header">
          <i [class._loading]="userService.loading"
             [class._on-error]="!userService.loading && userService.loginFail"
             [class._on-success]="!userService.loading && userService.loginSuccess">
          </i>
        </div>
        <div *ngIf="!showReset && topService.isMobile" class="vendor-login-modal-mobile-header animated" [ngClass]="{'flipInY': fromForgot}">
          Vendor Login
        </div>
        <div class="vendor-login-modal-body">
          <form *ngIf="!showReset">
            <div class="vendor-login-modal-input">
              <input [ngClass]="{'flipInY': fromForgot}"
              type="email" class="animated"
              tabindex="1" autofocus name="email"
              #email="ngModel" [(ngModel)]="emailLogin" placeholder="Email">
            </div>
            <div class="vendor-login-modal-input">
              <input [ngClass]="{'flipInY': fromForgot}"
              (keyup.enter)="login(email.value, password.value)"
              tabindex="2" type="password" name="password"
              class="animated"
              #password="ngModel" [(ngModel)]="passwordLogin" placeholder="Password">
            </div>
            <div class="vendor-login-modal-ctrl">
              <a [ngClass]="{'flipInY': fromForgot}"
              [class.loadingHelper]="userService.loading"
              (click)="login(email.value, password.value)"
              class="animated">
                <span *ngIf="(!userService.loading && topService.isMobile) || !topService.isMobile">Vendor Login</span>
                <div *ngIf="userService.loading && topService.isMobile" class="login-loader"></div>
              </a>
            </div>
          </form>
          <app-forgot-password (hideForgotPwd)="hideLogin($event)" *ngIf="showReset" [fromVendor]="true" [shownEmail]="emailLogin"></app-forgot-password>
        </div>
        <div class='error-box-wrapper'>
          <div *ngIf="!showReset &&  userService.loginFail && !userService.loading" class='error-box animated' [class.flipInY]="fromForgot">
            Login failed!
          </div>
        </div>

        <div *ngIf="!showReset" class="vendor-login-modal-footer">
          <a [ngClass]="{'flipInY': fromForgot}"
          (click)="reset()" tabindex="3"
          class="animated">Forgot Password?</a>
        </div>
      </div>
    </div>
  </div>
</div>
