<div class="modal" [class.mobile]="topService.isMobile">
	<div class="modal-wrap animated">
		<div class="modal-content">
			<div class="modal-guest-login-wrapper" [class.hide]="registrationComponent.nextStep && topService.isMobile">
				<app-guest-login (mouseover)="disableScroll(true)" (mouseout)="disableScroll(false)" ></app-guest-login>
			</div>
			<div class="modal-guest-registration-wrapper">
  				<app-guest-registration (registerDataEmitter)="registerUserInformation($event)" (mouseover)="disableScroll(true)" (mouseout)="disableScroll(false)"></app-guest-registration>
			</div>
			<div class="modal-bonus-wrapper">
				<app-bonus-offer (mouseover)="disableScroll(true)" (mouseout)="disableScroll(false)"></app-bonus-offer>
			</div>
		</div>
	</div>
	<div class="modal-info animated" [class.show-info]="passwordSubmited" (clickOutside)="hideInfo()">
		<span>
			Password successfully updated
		</span>
	</div>
</div>