import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'currentDeal'
})
export class CurrentDealPipe implements PipeTransform {

  transform(value: any, plural= false): any {
    if ([environment.todaysDealsLabel, 'Todays Deals'].includes(value)) {
      if (plural) {
        return environment.currentDeal.displayLabel;
      } else {
        return environment.currentDeal.displaySingularLabel;
      }
    }
    return value;
  }

}
