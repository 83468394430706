import { OfferErrorCodes } from "../enums/offer-error-codes";

export class OfferErrorCodeMap {
    static VariablePercentage = '5';
    static ErrorCodeMap: { [key in OfferErrorCodes]: string } = {
        [OfferErrorCodes.CreateOffer_ItemPriceChanged]: `Item price has changed. Offers cannot be less than ${OfferErrorCodeMap.VariablePercentage}% off listed price.`,
        [OfferErrorCodes.CreateOffer_ItemAvailabilityChanged]: 'Please refresh the page. This inventory is no longer available.',
        [OfferErrorCodes.CreateOffer_ItemStatusChanged]: 'This item is no longer available.',
        [OfferErrorCodes.CreateOffer_NoTokensAvailable]: 'You\'re out of tokens.',
        [OfferErrorCodes.CreateOffer_ItemAlreadyBided]: 'An offer has already been made for this item.',
        [OfferErrorCodes.CreateOffer_OrderCreationNotAllowed]: 'Unable to accept offer. You are not allowed to place orders in the system.',
        //Accept
        [OfferErrorCodes.AcceptOffer_ItemPriceChanged]: 'Unable to accept offer. The item price has changed and this offer will be canceled shortly.',
        [OfferErrorCodes.AcceptOffer_ItemAvailabilityChanged]: 'Unable to accept offer. The item inventory has changed and this offer will be canceled shortly.',
        [OfferErrorCodes.AcceptOffer_InvalidStatus]: 'The offer cannot be accepted due to the current status of this offer.',
        [OfferErrorCodes.AcceptOffer_InvalidPreviousEventType]: 'You must wait for your counterpart to react before accepting.',
        [OfferErrorCodes.AcceptOffer_OfferEnabledInvalid]: 'A party associated with this offer is no longer able to manage offers.',
        [OfferErrorCodes.AcceptOffer_ItemStatusChanged]: 'Unable to accept offer. The item status has changed and this offer will be canceled shortly.',
        [OfferErrorCodes.AcceptOffer_OrderCreationNotAllowed_Buyer]: 'Unable to accept offer. You are not allowed to place orders in the system and this offer will be canceled shortly.',
        [OfferErrorCodes.AcceptOffer_OrderCreationNotAllowed_Vendor]: 'A party associated with this offer is no longer able to manage offers and this offer will be canceled shortly.',

        //Counter
        [OfferErrorCodes.CounterOffer_ExistAndPending]: 'This offer is no longer pending and a Counteroffer cannot be accepted at this time.',
        [OfferErrorCodes.CounterOffer_PreviousEventValid]: 'Please wait for your counterpart to respond before submitting a Counteroffer.',
        [OfferErrorCodes.CounterOffer_IsFinalResult]: 'Counteroffer cannot be accepted because the last counter was marked as the Final Offer.',
        [OfferErrorCodes.CounterOffer_PriceInvalid]: 'The price submitted was invalid.',
        [OfferErrorCodes.CounterOffer_OfferEnabledInvalid]: 'A connected party in this pending offer is no longer able to process offers.',
        [OfferErrorCodes.CounterOffer_OfferInvalidQuantity]: 'Unable to counteroffer. The offer quantity must be greater than zero.',
        [OfferErrorCodes.CounterOffer_ItemAvailabilityChanged]: 'Unable to counteroffer. The item inventory has changed and this offer will be canceled shortly.',
        [OfferErrorCodes.CounterOffer_ItemPriceChanged]: 'Unable to counteroffer. The item price has changed and this offer will be canceled shortly.',
        [OfferErrorCodes.CounterOffer_ItemStatusChanged]: 'Unable to counteroffer. The item status has changed and this offer will be canceled shortly.',
        [OfferErrorCodes.CounterOffer_OrderCreationNotAllowed_Buyer]: 'Unable to counteroffer. You are not allowed to place orders in the system and this offer will be canceled shortly.',
        [OfferErrorCodes.CounterOffer_OrderCreationNotAllowed_Vendor]: 'A party associated with this offer is no longer able to manage offers and this offer will be canceled shortly.',

        //Reject
        [OfferErrorCodes.RejectOffer_NotPending]: 'This offer cannot be rejected because it is no longer a pending offer.',
        [OfferErrorCodes.RejectOffer_WrongCustomer]: 'This offer cannot be rejected because the wrong customer is associated with the operation.',
        [OfferErrorCodes.RejectOffer_WrongVendor]: 'This offer cannot be rejected because the wrong vendor is associated with the operation.',
        [OfferErrorCodes.RejectOffer_LastEventRoleSameAsIncoming]: 'This offer cannot be rejected because you must wait for your counterpart to respond.',
        [OfferErrorCodes.RejectOffer_EitherSideDisabled]: 'This offer cannot be rejected because either you or your counterpart are disabled.',
        [OfferErrorCodes.RejectOffer_ItemAvailabilityChanged]: 'This offer cannot be rejected because the item inventory has changed.',
        [OfferErrorCodes.RejectOffer_ItemPriceChanged]: 'This offer cannot be rejected because the item price has changed. ',

        //Cancel
        [OfferErrorCodes.CancelOffer_NotPending]: 'This offer cannot be canceled because it is no longer a pending offer.',
        [OfferErrorCodes.CancelOffer_WrongCustomer]: 'This offer cannot be canceled because the wrong customer is associated with the operation.',
        [OfferErrorCodes.CancelOffer_WrongVendor]: 'This offer cannot be canceled because the wrong vendor is associated with the operation.',
        [OfferErrorCodes.CancelOffer_EitherSideDisabled]: 'This offer cannot be canceled because either you or your counterpart are disabled.',
        [OfferErrorCodes.CancelOffer_OfferVersionMismatch]: 'The offer has been modified. Please fetch the latest version before updating',

        //General
        [OfferErrorCodes.General_PreconditionFailed]: 'The offer has been modified. Please reload your offer to see the changes.',

        //Update
        [OfferErrorCodes.UpdateOffer_ExistAndPending]: 'This offer cannot be updated because it is no longer a pending offer.',
        [OfferErrorCodes.UpdateOffer_PreviousEventInValid]: 'Please wait for your counterpart to respond before submitting an updated offer.',
        [OfferErrorCodes.UpdaterOffer_IsFinalResult]: 'Update offer cannot be accepted because the last counter was marked as the Final Offer.',
        [OfferErrorCodes.UpdateOffer_PriceInvalid]: 'The price submitted was invalid.',
        [OfferErrorCodes.UpdateOffer_OfferEnabledInvalid]: 'A connected party in this pending offer is no longer able to process offers.',
        [OfferErrorCodes.UpdateOffer_OfferInvalidQuantity]: 'Unable to update offer. The offer quantity must be greater than zero.',
        [OfferErrorCodes.UpdateOffer_ItemAvailabilityChanged]: 'Unable to update offer. The item inventory has changed and this offer will be canceled shortly.',
        [OfferErrorCodes.UpdateOffer_ItemPriceChanged]: 'Unable to update offer. The item price has changed and this offer will be canceled shortly.',
        [OfferErrorCodes.UpdateOffer_ItemStatusChanged]: 'This item is no longer available.',
        [OfferErrorCodes.UpdateOffer_OrderCreationNotAllowed_Buyer]: 'Unable to update offer. You are not allowed to place orders in the system and this offer will be canceled shortly.',
        [OfferErrorCodes.UpdateOffer_OrderCreationNotAllowed_Vendor]: 'A party associated with this offer is no longer able to manage offers and this offer will be canceled shortly.',
        [OfferErrorCodes.UpdateOffer_WrongCustomer]: 'This offer cannot be updated because the wrong customer is associated with the operation.',
        [OfferErrorCodes.UpdateOffer_WrongVendor]: 'This offer cannot be updated because the wrong vendor is associated with the operation.',
		[OfferErrorCodes.UpdateOffer_NoModifications]: 'Unable to update the offer due to lack of substantive modifications'
    }
}