<div class="preloader">
	 <div class="preloader-item  __mobile" 
	 [class.animation-on]="true"
	 [class.__past-purchases]="isFromPastPurchases"
	 [class.__just-sold]="isFromJustSold"
	 [class.__notifications]="isFromNotificationCenter"
	 *ngFor="let preloaderItem of preloaderItemList">
	  <div class="preloader-item-header" *ngIf="isFromPastPurchases || isFromJustSold">
		<ng-container *ngIf="isFromPastPurchases">
			<div class="preloader-item-header-top">
				<span></span>
				<span></span>
			</div>
			<div class="preloader-item-header-bottom">
				<div>
					<span></span>
					<span></span>
				</div>
				<div>
					<span></span>
					<span></span>
				</div>
				<div>
					<span></span>
					<span></span>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="isFromJustSold">
			<div class="preloader-item-header-top">
				<span></span>
			</div>
			<div class="preloader-item-header-bottom">
				<div>
					<span></span>
					<span></span>
				</div>
				<div>
					<span></span>
					<span></span>
				</div>
				<div>
					<span></span>
					<span></span>
				</div>
			</div>
		</ng-container>
	  </div>
	  <div class="preloader-item-top">
	    <div class="preloader-item-logo">
	    </div>
	    <div class="preloader-item-values">
	      <div>
	        <span></span>
	        <span></span>
	      </div>
	    </div>
	    <div class="preloader-item-promo">
	    </div>
	  </div>
	  <div class="preloader-item-middle">
	    <div class="preloader-item-description">
	      <span></span>
	      <span></span>
	      <span></span>
	      <span></span>
	    </div>
	  </div>
	  <div class="preloader-item-bottom">
	    <div class="preloader-item-values">
	      <div>
	        <span></span>
	        <span></span>
	      </div>
	      <div>
	        <span></span>
	        <span></span>
	      </div>
	    </div>
	    <div class="preloader-item-input"></div>
	    <div class="preloader-item-button"></div>
	  </div>
	  <div class="preloader-item-offer-wrap">
		  <div class="preloader-item-offer"></div>
	  </div>
	  <div class="preloader-item-notifications" *ngIf="isFromNotificationCenter">
	  	<div class="preloader-item-notifications-wrap">
    		<div class="preloader-item-notification">
    			<div class="preloader-item-notification-icon">
    				<img src="../../../assets/images/icons/items-preloader/price-drop.svg" alt="Notification Icon">
    			</div>
    			<div class="preloader-item-notification-text">
    				<span></span>
    			</div>
    		</div>
    		<div class="preloader-item-notification">
    			<div class="preloader-item-notification-icon">
    				<img src="../../../assets/images/icons/items-preloader/back.svg" alt="Notification Icon">
    			</div>
    			<div class="preloader-item-notification-text">
    				<span></span>
    			</div>
    		</div>
    		<div class="preloader-item-notification">
    			<div class="preloader-item-notification-icon">
    				<img src="../../../assets/images/icons/items-preloader/added.svg" alt="Notification Icon">
    			</div>
    			<div class="preloader-item-notification-text">
    				<span></span>
    			</div>
    		</div>
    	</div>
	  </div>
	</div>
</div>