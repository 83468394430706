<div class="auction-items-loader" *ngIf="!auctionService.itemsReady || topService.loading">
  <div class="loader"></div>
</div>
<div class="auctions-list"
  *ngIf="auctionService.itemsReady && !topService.loading && auctionService.auctionItems?.length && (topService.isMobile || !auctionService.isGridView)"
  [class.mobile]="topService.isMobile">
  <div *ngFor="let item of auctionService.auctionItems" class="auctions-item" (click)="viewDetails(item.ID)">
    <div class="auctions-item-img" *ngFor="let image of item.MediaResponse.Original"
      [class.hide]="image.DefaultImage===false">
      <img *ngIf="image.DefaultImage===true" [src]="image.PhysicalURI" alt="Image">
    </div>
    <ng-container *ngIf="item.ActionCount && !isSuperUser && item.HasBid && item.ReserveMet">
      <div *ngIf="item.Status === 'Active'">
        <span *ngIf="item.IsWinning" class="auctions-item-bid _win">You are winning</span>
        <span *ngIf="!item.IsWinning" class="auctions-item-bid _lost">You have been outbid</span>
      </div>
      <div *ngIf="item.Status === 'Successful'">
        <span *ngIf="item.IsWinning" class="auctions-item-bid _win">You won</span>
        <span *ngIf="!item.IsWinning" class="auctions-item-bid _lost">You lost</span>
      </div>
    </ng-container>
    <div class="auctions-item-desc">
      <h2 class="auctions-item-desc__title">{{ item.Title }}</h2>
      <div class="auctions-item-desc__txt" [innerHTML]="truncateDescription(item.Description)"></div>
    </div>
    <div class="auctions-item-actions">
      <div class="auctions-item-actions__header">
        <span class="auctions-item-desc__status" [ngClass]="{
        '_active': item.Status === 'Active',
        '_comming-soon': item.Status === 'Preview',
        '_completed': item.Status === 'Successful' || item.Status === 'Unsuccessful'
        }">
          <span *ngIf="item.Status === 'Active'">LIVE</span>
          <span *ngIf="item.Status === 'Preview'">COMING SOON</span>
          <span *ngIf="item.Status === 'Successful'">COMPLETED</span>
          <span *ngIf="item.Status === 'Unsuccessful'">COMPLETED</span>
        </span>
      </div>
      <div class="auctions-item-actions__top" *ngIf="item?.Status !== 'Preview'">
        <div class="auctions-item-actions-infolist">
          <div class="auctions-item-actions-info _timer">
            <span class="auctions-item-actions-info__label">Remaining</span>
            <span class="auctions-item-actions-info__value">
              <app-countdown [dates]="{start: item?.StartDTTM, end:item?.EndDTTM, id: item?.ID }" (ended)="countdownEnd($event, item?.ID, item?.Status )" *ngIf="item">
              </app-countdown>
            </span>
          </div>
          <div class="auctions-item-actions-info">
            <span class="auctions-item-actions-info__label" *ngIf="item?.ReserveMet === false">&nbsp;</span>
            <span class="auctions-item-actions-info__label __met" 
            [ngClass]="topService.isMobile ? 'mobile' : 'web'"
             *ngIf="item?.ReserveMet === true || item?.ReserveMet === null">
              Reserve Price Met
            </span>
            <span class="auctions-item-actions-info__value">{{ item.CurrentPrice | CustomCurrency}}</span>
          </div>
          <div class="auctions-item-actions-info _bid">
            <span class="auctions-item-actions-info__label">Bid(s)</span>
            <span class="auctions-item-actions-info__value">{{ item.ActionCount }}</span>
          </div>
        </div>
      </div>

      <div class="auctions-item-actions__btm">
        <div class="auctions-item-actions-btns" *ngIf="!['Successful', 'Unsuccessful', 'Deleted'].includes(item?.Status)">
          <button (click)="addToWatchlist(item)" class="btn-watch" [class._active]="item.IsWatching" [ngClass]="{'disabled-div': isSuperUser}"
           *ngIf="!['Successful', 'Unsuccessful', 'Deleted'].includes(item?.Status)">
            <span *ngIf="item.WatchLoading" class="cart-loader"></span>
            <svg *ngIf="!item.WatchLoading" height="24" viewBox="0 0 24 24" width="24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="m12 6.4c-6.561 0-10 4.832-10 5.6 0 .766 3.439 5.6 10 5.6 6.56 0 10-4.834 10-5.6 0-.768-3.44-5.6-10-5.6zm0 9.907c-2.455 0-4.445-1.928-4.445-4.307s1.99-4.309 4.445-4.309 4.444 1.93 4.444 4.309-1.989 4.307-4.444 4.307zm0-4.307c-.407-.447.663-2.154 0-2.154-1.228 0-2.223.965-2.223 2.154s.995 2.154 2.223 2.154c1.227 0 2.223-.965 2.223-2.154 0-.547-1.877.379-2.223 0z"
                fill="#fff" />
            </svg>
          </button>
          <button type="button" class="btn-go" *ngIf="item?.Status === 'Active'">
            GO
          </button>

          <!-- Starts-->
          <div class="auctions-item-actions-starts _list-view" *ngIf="item.Status === 'Preview'">
            <div class="auctions-item-actions-starts__title">Starts</div>
            <div class="auctions-item-actions-starts__countdown">
              <app-countdown [dates]="{start: item?.StartDTTM, end:item?.EndDTTM, id: item?.ID }" (ended)="countdownEnd($event, item?.ID, 'Preview' )" *ngIf="item">
              </app-countdown>
            </div>
          </div>

          <!--<button (click)="quickBid(item)" class="btn-quick-bid"-->
          <!--[disabled]="topService.loading || item.Status !== 'Active'">-->
          <!--Quick Bid $-->
          <!--<span *ngIf="item.ActionCount">-->
          <!--{{item.CurrentPrice + item.Increment}}-->
          <!--</span>-->
          <!--<span *ngIf="!item.ActionCount">-->
          <!--{{item.CurrentPrice}}-->
          <!--</span>-->
          <!--</button>-->
        </div>
        <div class="auctions-item-actions-result" *ngIf="item?.Status === 'Successful'">
          <span class="auctions-item-actions-result__label">Winning Bid</span>
          <span class="auctions-item-actions-result__value">{{item.CurrentPrice | CustomCurrency}}</span>
        </div>

        <div class="auctions-item-actions-result" *ngIf="item?.Status === 'Unsuccessful'">
          <span class="auctions-item-actions-result__label">Ended</span>
          <span class="auctions-item-actions-result__value"></span>
        </div>

      </div>
    </div>

    <app-auction-bid-notif *ngIf="item.NotificationMessages" [auctionItem]="item" (confirmation)="gotIt($event, item)"
    [isMobile]="topService.isMobile" class="auctions-item-bid-notif">
    </app-auction-bid-notif>

  </div>
</div>


<div class="auctions-grid"
  *ngIf="auctionService.itemsReady && !topService.loading && auctionService.auctionItems?.length && !topService.isMobile && auctionService.isGridView">
  <div *ngFor="let item of auctionService.auctionItems" class="auctions-item" (click)="viewDetails(item.ID)">
    <div class="auctions-item-bg" *ngFor="let gridImage of item.MediaResponse.Original"
      [class.hide]="gridImage.DefaultImage===false">
      <img *ngIf="gridImage.DefaultImage===true" [src]="gridImage.PhysicalURI" alt="Image">
    </div>
    <div class="auctions-item-stats">

      <div *ngIf="item.ActionCount && !isSuperUser && item.HasBid && item.ReserveMet">
        <div *ngIf="item.Status === 'Active'">
          <span *ngIf="item.IsWinning" class="auctions-item-stats__bid _win">You are winning</span>
          <span *ngIf="!item.IsWinning" class="auctions-item-stats__bid _lost">You have been outbid</span>
        </div>
        <div *ngIf="item.Status === 'Successful'">
          <span *ngIf="item.IsWinning" class="auctions-item-stats__bid _win">You won</span>
          <span *ngIf="!item.IsWinning" class="auctions-item-stats__bid _lost">You lost</span>
        </div>
      </div>



      <span class="auctions-item-stats__status" [ngClass]="{
      '_active': item.Status === 'Active',
      '_comming-soon': item.Status === 'Preview',
      '_completed': item.Status === 'Successful' || item.Status === 'Unsuccessful'
      }">
        <span *ngIf="item.Status === 'Active'">LIVE</span>
        <span *ngIf="item.Status === 'Preview'">COMING SOON</span>
        <span *ngIf="item.Status === 'Successful'">COMPLETED</span>
        <span *ngIf="item.Status === 'Unsuccessful'">COMPLETED</span>
      </span>
    </div>
    <div class="auctions-item-actions">
      <div class="auctions-item-actions__top">
        <div class="auctions-item-actions-infolist">
          <div class="auctions-item-actions-info _timer">
            <span class="auctions-item-actions-info__label">Remaining</span>
            <span class="auctions-item-actions-info__value">
              <app-countdown [dates]="{start: item?.StartDTTM, end:item?.EndDTTM, id: item?.ID }" (ended)="countdownEnd($event, item?.ID, item?.Status )" *ngIf="item">
              </app-countdown>
            </span>
          </div>
          <div class="auctions-item-actions-info">
            <span class="auctions-item-actions-info__value">{{ item.CurrentPrice | CustomCurrency}}</span>
          </div>
          <div class="auctions-item-actions-info _bid">
            <span class="auctions-item-actions-info__label">Bid(s)</span>
            <span class="auctions-item-actions-info__value">{{ item.ActionCount }}</span>
          </div>
        </div>

      </div>
      <div class="auctions-item-actions__btm">
        <div class="auctions-item-desc">
          <h2 class="auctions-item-desc__title">{{ item.Title }}</h2>
          <div class="auctions-item-desc__txt" [innerHTML]="item.Description"></div>
        </div>
        <div class="auctions-item-actions-btns">
          <button (click)="addToWatchlist(item)" class="btn-watch" [class._active]="item.IsWatching" [ngClass]="{'disabled-div': isSuperUser}"
          *ngIf="item?.Status !== 'Successful' && item?.Status !== 'Unsuccessful'">
            <span *ngIf="item.WatchLoading" class="cart-loader"></span>
            <svg *ngIf="!item.WatchLoading" height="24" viewBox="0 0 24 24" width="24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="m12 6.4c-6.561 0-10 4.832-10 5.6 0 .766 3.439 5.6 10 5.6 6.56 0 10-4.834 10-5.6 0-.768-3.44-5.6-10-5.6zm0 9.907c-2.455 0-4.445-1.928-4.445-4.307s1.99-4.309 4.445-4.309 4.444 1.93 4.444 4.309-1.989 4.307-4.444 4.307zm0-4.307c-.407-.447.663-2.154 0-2.154-1.228 0-2.223.965-2.223 2.154s.995 2.154 2.223 2.154c1.227 0 2.223-.965 2.223-2.154 0-.547-1.877.379-2.223 0z"
                fill="#fff" />
            </svg>
          </button>
          <button type="button" class="btn-go" *ngIf="item?.Status === 'Active'">
            GO
          </button>

          <!-- Starts -->
          <div class="auctions-item-actions-starts _grid-view" *ngIf="item.Status === 'Preview'">
            <div class="auctions-item-actions-starts__title">Starts</div>
            <div class="auctions-item-actions-starts__countdown">
              <app-countdown [dates]="{start: item?.StartDTTM, end:item?.EndDTTM, id: item?.ID }" (ended)="countdownEnd($event, item?.ID, 'Preview' )" *ngIf="item">
              </app-countdown>
            </div>
          </div>

          <!--<button (click)="quickBid(item)" class="btn-quick-bid"-->
          <!--[disabled]="topService.loading || item.Status !== 'Active'">-->
          <!--Quick Bid $-->
          <!--<span *ngIf="item.ActionCount">-->
          <!--{{item.CurrentPrice + item.Increment}}-->
          <!--</span>-->
          <!--<span *ngIf="!item.ActionCount">-->
          <!--{{item.CurrentPrice}}-->
          <!--</span>-->
          <!--</button>-->
        </div>

        <!-- Winning bid -->
        <div class="auctions-item-actions-result" *ngIf="item?.Status === 'Successful'">
          <span class="auctions-item-actions-result__label">Winning Bid</span>
          <span class="auctions-item-actions-result__value">{{item.CurrentPrice | CustomCurrency}}</span>
        </div>

        <!-- No bid -->
        <div class="auctions-item-actions-result" *ngIf="item?.Status === 'Unsuccessful'">
          <span class="auctions-item-actions-result__label">Ended</span>
          <span class="auctions-item-actions-result__value"></span>
        </div>

      </div>
    </div>

    <app-auction-bid-notif *ngIf="item.NotificationMessages" [auctionItem]="item" (confirmation)="gotIt($event, item)" [isMobile]="topService.isMobile">
    </app-auction-bid-notif>

  </div>
</div>

<app-auction-confirm *ngIf="showBidConfirm" [auctionItem]="currentItem" (confirmation)="bidConfirm($event)" [isMobile]="topService.isMobile">
</app-auction-confirm>

<!-- for My Bids Page -->
<div *ngIf="auctionService.itemsReady && !topService.loading && !auctionService.auctionItems?.length && auctionService.titleText === auctionService.MyBidsTitleText"
  class="auctions-empty-state" [class.mobile]="topService.isMobile">
  <div class="auctions-empty-state__content">
    <img src="../../../../assets/images/icons/auctions/bid-history-empty@2x.png" width="156" height="155"
      alt="auctions empty">
    <h3>{{auctionService.getEmptyStateMsgForMyBids()?.header}}</h3>
    <p>{{auctionService.getEmptyStateMsgForMyBids()?.body}}</p>
  </div>
</div>

<!-- for live auctions list -->
<div
  *ngIf="auctionService.itemsReady && !topService.loading && !auctionService.auctionItems?.length && auctionService.titleText === auctionService.LiveAuctionTitleText"
  class="auctions-empty-state _live-auctions" [class.mobile]="topService.isMobile">
  <div class="auctions-empty-state__content">
    <img src="../../../../assets/images/icons/auctions/no-live-auctions@2x.png" width="156" height="155"
      alt="auctions empty">
      <h3>{{auctionService.getEmptyStateFromAuctions()?.header}}</h3>
      <p>{{auctionService.getEmptyStateFromAuctions()?.body}}</p>

  </div>
</div>
