import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortLabel'
})
export class ShortLabelPipe implements PipeTransform {

  transform(value: string, length: number, symbol: string): string {
    if (value.length > 11) {
      return value.substring(0,length).concat(symbol);
    }
    return value;
  }

}
