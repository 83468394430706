<div class="loader" *ngIf="topService.loading"></div>
<div class="auction-wrap" *ngIf="!topService.loading" [class.mobile]="topService.isMobile">
  <div class="auction-block _details">
    <div class="auction-top">
      <div class="auction-top__img">
        <div class="auction-item-img">
          <owl-carousel [options]="carouselOptions" *ngIf="auctionsService?.currentAuctionItem?.MediaResponse?.Original?.length && showCarousel"
                        [carouselClasses]="['owl-theme', 'auction-item-slider']" class="auction-item-slider-wrap">
            <img *ngFor="let item of auctionsService?.currentAuctionItem.MediaResponse.Original" src="{{item.PhysicalURI}}" width="526" height="526" alt />
          </owl-carousel>
          <span class="auction-item-slider__count">
            {{sliderImagesCounter.current}} / {{sliderImagesCounter.length}}
          </span>
        </div>
      </div>
      <div class="auction-top__details">
        <span class="auction-item-status" [ngClass]="{'_live': auctionsService?.currentAuctionItem?.Status === 'Active', '_comming-soon': auctionsService?.currentAuctionItem?.Status === 'Preview'}">
            <span *ngIf="auctionsService.currentAuctionItem?.Status === 'Active'">LIVE</span>
            <span *ngIf="auctionsService.currentAuctionItem?.Status === 'Preview'">COMING SOON</span>
            <span *ngIf="auctionsService.currentAuctionItem?.Status === 'Successful'">COMPLETED</span>
           <span *ngIf="auctionsService.currentAuctionItem?.Status === 'Unsuccessful'">COMPLETED</span>
        </span>

        <div class="auction-item-info">
          <div class="auction-item-info_row">
            <!-- winning -->
            <div
            *ngIf=
            "auctionsService.currentAuctionItem?.ActionCount &&
            !isSuperUser &&
            auctionsService.currentAuctionItem?.HasBid &&
            auctionsService.currentAuctionItem?.ReserveMet">
              <div *ngIf="auctionsService.currentAuctionItem?.Status === 'Active'">
                 <span *ngIf="auctionsService.currentAuctionItem?.IsWinning" class="auction-item-bid _win">You are winning</span>
                <span *ngIf="!auctionsService.currentAuctionItem?.IsWinning" class="auction-item-bid _lost">You have been outbid</span>
              </div>
              <div *ngIf="auctionsService.currentAuctionItem?.Status === 'Successful'">
                  <span *ngIf="auctionsService.currentAuctionItem?.IsWinning" class="auction-item-bid _win">You won</span>
                 <span *ngIf="!auctionsService.currentAuctionItem?.IsWinning" class="auction-item-bid _lost">You lost</span>
              </div>
            </div>

            <!-- watch -->
            <div class="auction-item-watch">
              <button type="button" class="btn-watch" [class._active]="auctionsService.currentAuctionItem?.IsWatching"
               (click)="auctionsService.addToWatchlist(auctionsService.currentAuctionItem)" [disabled]="isSuperUser">
               <span *ngIf="auctionsService.currentAuctionItem?.WatchLoading" class="cart-loader"></span>
                <svg *ngIf="!auctionsService.currentAuctionItem?.WatchLoading" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="m12 6.4c-6.561 0-10 4.832-10 5.6 0 .766 3.439 5.6 10 5.6 6.56 0 10-4.834 10-5.6 0-.768-3.44-5.6-10-5.6zm0 9.907c-2.455 0-4.445-1.928-4.445-4.307s1.99-4.309 4.445-4.309 4.444 1.93 4.444 4.309-1.989 4.307-4.444 4.307zm0-4.307c-.407-.447.663-2.154 0-2.154-1.228 0-2.223.965-2.223 2.154s.995 2.154 2.223 2.154c1.227 0 2.223-.965 2.223-2.154 0-.547-1.877.379-2.223 0z"
                    fill="#1B1B1B" />
                </svg>
              </button>
            </div>

            <div class="auction-item-desc">
              <!-- title -->
              <h2 class="auction-item-desc__title">{{auctionsService.currentAuctionItem?.Title}} </h2>
              <div class="auction-item-desc__txt">
                <p [innerHTML]="htmlDescription"></p>
              </div>
            </div>
          </div>

          <!-- remaining -->
          <div class="auction-item-info_row" *ngIf="auctionsService.currentAuctionItem?.Status !== 'Preview'">
            <div class="auction-item-remaining-row">
              <div class="auction-item-remaining __price">
                <span class="auction-item-remaining__value">{{auctionsService.currentAuctionItem?.CurrentPrice | CustomCurrency}}</span>

                    <span class="auction-item-remaining__label" *ngIf="auctionsService.currentAuctionItem?.ReserveMet === false">Reserve Price Not Met</span>
                    <span class="auction-item-remaining__label __met"
                      *ngIf="auctionsService.currentAuctionItem?.ReserveMet === true || auctionsService.currentAuctionItem?.ReserveMet === null">
                      Reserve Price Met
                    </span>
              </div>
              <div class="auction-item-remaining _timer">
                <span class="auction-item-remaining__label">Remaining</span>
                <span class="auction-item-remaining__value" id="countdown-bid" *ngIf="auctionsService.currentAuctionItem" >
                  <app-countdown [dates]="{start: auctionsService.currentAuctionItem?.StartDTTM, end:auctionsService.currentAuctionItem?.EndDTTM, id: auctionsService.currentAuctionItem?.ID }"
                  (ended)="countdownEnd($event, auctionsService.currentAuctionItem?.ID, auctionsService.currentAuctionItem?.Status )"></app-countdown>
                </span>
              </div>
              <div class="auction-item-remaining _bid">
                <span class="auction-item-remaining__label">Bid(s)</span>
                <span class="auction-item-remaining__value">{{auctionsService.currentAuctionItem?.ActionCount}}</span>
              </div>
            </div>
          </div>

          <div class="auction-item-info_row">
            <!-- quick bid
            <div class="auction-item-quick-bid">
              <button type="button" class="btn-quick-bid" (click)="quickBid()"
              [disabled]="topService.loading || auctionsService.currentAuctionItem?.Status !== 'Active'" >
                Quick Bid $
                <span *ngIf="auctionsService.currentAuctionItem?.ActionCount">
                  {{auctionsService.currentAuctionItem?.CurrentPrice + auctionsService.currentAuctionItem?.Increment}}
                </span>
                <span *ngIf="!auctionsService.currentAuctionItem?.ActionCount">
                  {{auctionsService.currentAuctionItem?.CurrentPrice}}
                </span>
              </button>
            </div>-->

            <!-- bid amount -->
            <div class="auction-item-bid-amount"
            *ngIf="auctionsService.currentAuctionItem?.Status === 'Active' && !auctionsService.currentAuctionItem?.NotificationMessages ">
              <div class="auction-item-bid-amount__input">
                <div class="auction-item-bid-amount-input">
                  <span>$</span>
                  <input type="number" class="form-control" [(ngModel)]="auctionsService.bidAmount" [disabled]="isSuperUser" pattern="[0-9]*" inputmode="numeric" appOnlyDigits >
                </div>
                <span class="auction-item-bid-amount__input-min">Minimum Bid:
                  <span *ngIf="auctionsService.currentAuctionItem?.ActionCount">
                    {{auctionsService.currentAuctionItem?.CurrentPrice + auctionsService.currentAuctionItem?.Increment | CustomCurrency}}
                  </span>
                  <span *ngIf="!auctionsService.currentAuctionItem?.ActionCount">
                    {{auctionsService.currentAuctionItem?.CurrentPrice | CustomCurrency}}
                  </span>
                </span>
                <span class="auction-item-bid-amount__input-max" *ngIf="auctionsService.currentAuctionItem?.MaxBid && topService.isMobile">
                  Your Maximum Bid Is: {{auctionsService.currentAuctionItem?.MaxBid | CustomCurrency}}
                </span>
              </div>
              <div class="auction-item-bid-amount__btn">
                <button type="button" class="btn btn-submit-bid" (click)="manualBid()"
                [disabled]="!auctionsService.validBidAmount(auctionsService.currentAuctionItem, auctionsService.bidAmount) ||
                auctionsService.currentAuctionItem?.BidLoading || auctionsService.currentAuctionItem?.Status !== 'Active' || isSuperUser">
                <span *ngIf="auctionsService.currentAuctionItem?.BidLoading" class="cart-loader"></span>
                <span>Submit Bid</span>
                </button>
                <span *ngIf="auctionsService.currentAuctionItem?.MaxBid && !topService.isMobile"  class="auction-item-bid-amount__input-min">
                  Your Maximum Bid Is: {{auctionsService.currentAuctionItem?.MaxBid | CustomCurrency}}
                </span>
              </div>
            </div>

            <!-- winning bid -->
            <div class="auction-item-result" *ngIf="auctionsService.currentAuctionItem?.Status === 'Successful' && !won()">
              <span class="auction-item-result__label">Winning Bid</span>
              <span class="auction-item-result__value"> {{auctionsService.currentAuctionItem?.CurrentPrice | CustomCurrency}}</span>
            </div>

            <!-- no bids -->
            <div class="auction-item-result" *ngIf="auctionsService.currentAuctionItem?.Status === 'Unsuccessful'">
              <span class="auction-item-result__label">Ended</span>
              <span class="auction-item-result__value"> </span>
            </div>

            <!-- Starts -->
            <div class="auction-item-starts" *ngIf="auctionsService.currentAuctionItem?.Status === 'Preview'">
              <div class="auction-item-starts__title">Starts</div>
              <div class="auction-item-starts__countdown">
                  <app-countdown [dates]="{start: auctionsService.currentAuctionItem?.StartDTTM, end:auctionsService.currentAuctionItem?.EndDTTM, id: auctionsService.currentAuctionItem?.ID }"
                  (ended)="countdownEnd($event, auctionsService.currentAuctionItem?.ID, 'Preview' )"></app-countdown>
              </div>
            </div>

            <!-- You Won -->
            <div class="auction-item-won" *ngIf="won()">
              <p> You Won! You are the final winner. <br/>{{auctionsService.currentAuctionItem?.CurrentPrice | CustomCurrency}}</p>
            </div>

            <!-- Notification message -->
            <app-auction-bid-notif *ngIf="auctionsService.currentAuctionItem?.NotificationMessages" [auctionItem]="auctionsService.currentAuctionItem"
            (confirmation)="gotIt($event, auctionsService.currentAuctionItem)" [isMobile]="topService.isMobile" class="auction-item-bid-notif"></app-auction-bid-notif>

            <!-- bid FAQ -->
            <div class="auction-item-bid-faq" [class.__opened]="isShowFaq"
            *ngIf="!topService.isMobile && auctionsService.currentAuctionItem?.Status === 'Active' && !auctionsService.currentAuctionItem?.NotificationMessages">
              <div class="auction-item-bid-faq__btn" (click)="showFaq()">
                <span>Proxy Bid:  How do our Live Auctions work?</span>
                <i>
                  <div [ngClass]="{'div-closed': !isShowFaq, 'div-opened': isShowFaq}">
                      <div class="hor-ver">
                        <div class="horizontal"></div>
                        <div class="vertical"></div>
                      </div>
                    </div>
                </i>
              </div>
              <div class="auction-item-bid-faq__txt"  (click)="showFaq()">
                <h3>Proxy Bid</h3>
                <p>
                  Proxy bidding places bids on your behalf at the lowest possible increments. Simply enter your maximum bid (the highest amount you are willing to pay).
                </p>
                <p>
                  The goal of proxy bidding is for you to win the auction at the lowest possible price. Your maximum bid is only placed when another bidder also bids up to that amount or when the reserve price is equal to or greater than your maximum bid.
                </p>
                <p>
                  Bids will increase based on the bid increment set for the auction. Therefore, the current bid will equal the former bid plus the bid increment.
                </p>
                <h3>Example</h3>
                <p>
                  An item’s current bid is $3.00, and the bid increment is $0.10. Jane chooses automatic bidding and enters a maximum bid of $6.00, so Jane becomes the high bidder and the current bid reads $3.10.
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="auction-btm">
    <div class="auction-btm__col">
      <!-- bid FAQ mobile -->
      <div class="auction-block"
      *ngIf="topService.isMobile && auctionsService.currentAuctionItem?.Status === 'Active' && !auctionsService.currentAuctionItem?.NotificationMessages">
        <div class="auction-item-bid-faq" [class.__opened]="isShowFaq">
          <div class="auction-item-bid-faq__btn" (click)="showFaq()">
            <span>Proxy Bid: <br/>How do our Live Auctions work?</span>
            <i>
              <div [ngClass]="{'div-closed': !isShowFaq, 'div-opened': isShowFaq}">
                  <div class="hor-ver">
                    <div class="horizontal"></div>
                    <div class="vertical"></div>
                  </div>
                </div>
            </i>
          </div>
          <div class="auction-item-bid-faq__txt"  (click)="showFaq()">
            <h3>Proxy Bid</h3>
            <p>
              Proxy bidding places bids on your behalf at the lowest possible increments. Simply enter your maximum bid (the highest amount you are willing to pay).
            </p>
            <p>
              The goal of proxy bidding is for you to win the auction at the lowest possible price. Your maximum bid is only placed when another bidder also bids up to that amount or when the reserve price is equal to or greater than your maximum bid.
            </p>
            <p>
              Bids will increase based on the bid increment set for the auction. Therefore, the current bid will equal the former bid plus the bid increment.
            </p>
            <h3>Example</h3>
            <p>
              An item’s current bid is $3.00, and the bid increment is $0.10. Jane chooses automatic bidding and enters a maximum bid of $6.00, so Jane becomes the high bidder and the current bid reads $3.10.
            </p>
          </div>
        </div>
      </div>
      <!-- description -->
      <div class="auction-block auction-block-description">
        <h2 class="auction-block__title">Description</h2>
        <p [innerHTML]="htmlDescription"></p>

        <span class="download" (click)="downloadSpecifications()" *ngIf="auctionsService?.currentAuctionItem?.MediaResponse?.Pdf?.length">
          Download Auction Manifest
        </span>
      </div>

      <!-- details -->
      <div class="auction-block">
        <h2 class="auction-block__title">Details</h2>
        <div class="details-box">

          <div class="details details-header">
            <span>Auction #</span>
            <span>{{auctionsService.currentAuctionItem?.ID}}</span>
          </div>
          <div class="details">
            <span>End Date</span>
            <span>{{ moment(auctionsService.currentAuctionItem?.EndDTTM).format('LLLL') }}</span>
          </div>
          <div class="details">
            <span>Start Date</span>
            <span>{{moment(auctionsService.currentAuctionItem?.StartDTTM).format('LLLL')}}</span>
          </div>
          <i class="details-divider"></i>

          <div class="details">
            <span>Current Price</span>
            <span>{{auctionsService.currentAuctionItem?.CurrentPrice | CustomCurrency}}</span>
          </div>
          <div class="details">
            <span>Starting Price</span>
            <span>{{auctionsService.currentAuctionItem?.OriginalPrice | CustomCurrency}}</span>
          </div>
          <div class="details">
            <span>Number of Bids</span>
            <span>{{auctionsService.currentAuctionItem?.ActionCount}}</span>
          </div>
          <hr>

          <div class="details">
            <span>Seller</span>
            <span *ngIf="auctionsService.currentAuctionItem?.OwnerUserName !== 'admin'">{{auctionsService.currentAuctionItem?.OwnerUserName}}</span>
            <span *ngIf="auctionsService.currentAuctionItem?.OwnerUserName === 'admin'">HUBX</span>
          </div>
        </div>
      </div>
    </div>
    <div class="auction-btm__col">
      <div class="auction-block">
        <app-auction-bid-history *ngIf="auctionsService?.currentAuctionItem?.ID" ></app-auction-bid-history>
      </div>
    </div>
  </div>
</div>

<app-auction-confirm *ngIf="showQuickBidConfirm" [auctionItem]="auctionsService.currentAuctionItem" (confirmation)="quickBidConfirm($event)" [isMobile]="topService.isMobile"></app-auction-confirm>

<app-auction-confirm *ngIf="showManualBidConfirm" [auctionItem]="auctionsService.currentAuctionItem" (confirmation)="manualBidConfirm($event)"
[bidAmount]="auctionsService.bidAmount" [isMobile]="topService.isMobile"></app-auction-confirm>
