import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';
import { SharedService } from '../shared/shared.service';
import * as _ from 'lodash';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'app-main-body',
  templateUrl: './main-body.component.html',
  styleUrls: ['./main-body.component.scss']
})
export class MainBodyComponent implements OnInit {
  maintenanceModeOn = environment.maintenanceMode;

  constructor(
    public sessionService: SessionService,
    public auth: AuthService,
    public sharedService: SharedService,
    public router: Router,
    private notificationService: NotificationService,
    ) { }

  ngOnInit() {
    if (_.isEmpty(this.sharedService.justSoldValues)) {
      this.notificationService.getJustSoldTotals();
    }
  }
}
