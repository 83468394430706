import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '../user/user.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardBuyerService implements CanActivate {

  constructor(
    public router: Router,
    public authService: AuthService,
    private userService: UserService,
    ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isAuthenticated() && (
      sessionStorage.getItem('user_role') === 'BUYER'
      || sessionStorage.getItem('user_role') === 'SALES'
      || sessionStorage.getItem('user_role') === 'SUPER')) {
      return true;
    } else {
      if (['ADMIN', 'SELLER', 'VENDORMGR'].includes(sessionStorage.getItem('user_role'))) {
        const urlTree: UrlTree = this.router.parseUrl(this.router.url);
        if(urlTree.queryParams?.redirectTo === state.url) {
          // second time attempting same page
          this.router.navigate(['/vendor/dashboard']);
          return;
        }
        this.userService.resetSessionWrongPortal();
      }
      // guest user
      this.authService.redirectUrl = state.url;
      this.router.navigate(['/guest/home'],  {queryParams: {redirectTo: state.url}});
      return false;
    }
  }
}
