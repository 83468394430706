// Modules
import { NgModule, NO_ERRORS_SCHEMA, Injectable } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { VendorModule } from './vendor/vendor.module';
import { BuyerModule } from './buyer/buyer.module';
import { UserModule } from './user/user.module';
import { CoreModule } from './core/core.module';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { ClickOutsideModule } from 'ng4-click-outside';
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChartModule } from 'angular2-highcharts';
import { HighchartsStatic } from 'angular2-highcharts/dist/HighchartsService';
import * as highcharts from 'highcharts';
import { ROUTES } from './app.routes';
import { AuthInterceptor } from './auth/auth.interceptor';
// import { ItemDetailDesktopComponent } from './shared/item-detail-desktop/item-detail-desktop.component';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { Ng5SliderModule } from 'ng5-slider';

//Swipe module
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig  {
  overrides = <any>{
      // override hammerjs default configuration
      'swipe': { direction: Hammer.DIRECTION_ALL  }
  }
};



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: false,
  suppressScrollX: true
};


// Services
import { AuthService } from './auth/auth.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { EmailService } from './services/email.service';
import { UserService } from './user/user.service';
import { PageErrorService } from './page-error/page-error.service';
import { TopCategoriesFileSaverService } from './buyer/catalog/top-categories/top-categories-file-saver.service';
import { SharedService } from './shared/shared.service';
import { GlobalSearchService } from './services/search.service';
import { SessionService } from './services/session.service';
import { ResetPasswordService } from './shared/services/reset-password.service';
import { CookieService } from 'ngx-cookie-service';

// Components
import { TopComponent } from './buyer/top/top.component';
import { TopMobileComponent } from './buyer/top/top-mobile/top-mobile.component';
import { AppComponent } from './app.component';
import { PageErrorComponent } from './page-error/page-error.component';
import { MainTopComponent } from './main-top/main-top.component';
import { MainBodyComponent } from './main-body/main-body.component';
import { TopVendorComponent } from './vendor/top-vendor/top-vendor.component';
import { UserSupportComponent } from './shared/user-support/user-support.component';
import { TopCategoriesComponent } from './buyer/catalog/top-categories/top-categories.component';
import { GuestTopComponent } from './buyer/top/guest-top/guest-top.component';
import { GuestModalComponent } from './buyer/guest-login/guest-modal/guest-modal.component';
import { GuestRegistrationComponent } from './buyer/guest-login/guest-registration/guest-registration.component';
import { GuestLoginComponent } from './buyer/guest-login/guest-login/guest-login.component';
import { GuestLoginMobileComponent } from './buyer/guest-login/guest-login-mobile/guest-login-mobile.component';
import { GuestRegistrationMobileComponent } from './buyer/guest-login/guest-registration-mobile/guest-registration-mobile.component';
import { BonusOfferComponent } from './buyer/guest-login/bonus-offer/bonus-offer.component';
import { BonusOfferFullComponent } from './buyer/guest-login/bonus-offer-full/bonus-offer-full.component';
import { StaticModule } from './static/static.module';
import { LoginComponent } from './user/login/login.component';
import { BulkUploadComponent } from './buyer/cart/bulk-upload/bulk-upload.component';
import { ConfirmModalComponent } from './shared/confirm-modal/confirm-modal.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { UserFiltersTypeaheadComponent } from './buyer/user-filters/user-filters-typeahead/user-filters-typeahead.component';
import { PricelistLoaderComponent } from './shared/ui-components/pricelist-loader/pricelist-loader.component';
import { TopVendorMobileComponent } from './vendor/top-vendor/top-vendor-mobile/top-vendor-mobile.component';

// PIPES
import { BlackCategoriesImagesPipe } from './shared/pipes/black-categories-images.pipe';
import { BlackLogosOutletPipe } from './shared/pipes/black-logos-outlet.pipe';
import { DropdownCategoriesComponent } from './buyer/categories/dropdown-categories.component';
import { GuestTopFullComponent } from './buyer/top/guest-top-full/guest-top-full.component';
import { MainTopMobileComponent } from './main-top/main-top-mobile/main-top-mobile.component';
import { VersionNotifierComponent } from './shared/version-notifier/version-notifier.component';
import { SharedModule } from './shared/shared.module';
import { GuestTopMobileComponent } from './buyer/top/guest-top/guest-top-mobile/guest-top-mobile.component';
import { LoaderService } from './shared/services/loader.service';
import { environment } from '../environments/environment';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

@NgModule({
  declarations: [
    AppComponent,
    TopComponent,
    TopMobileComponent,
    PageErrorComponent,
    MainTopComponent,
    MainBodyComponent,
    TopVendorComponent,
    GuestTopComponent,
    UserSupportComponent,
    GuestModalComponent,
    GuestRegistrationComponent,
    GuestLoginComponent,
    TopCategoriesComponent,
    GuestLoginMobileComponent,
    GuestRegistrationMobileComponent,
    BonusOfferComponent,
    BonusOfferFullComponent,
    LoginComponent,
    BulkUploadComponent,
    ConfirmModalComponent,
    MaintenanceComponent,
    BlackCategoriesImagesPipe,
    BlackLogosOutletPipe,
    DropdownCategoriesComponent,
    GuestTopFullComponent,
    MainTopMobileComponent,
    VersionNotifierComponent,
    GuestTopMobileComponent,
    UserFiltersTypeaheadComponent,
    PricelistLoaderComponent,
    TopVendorMobileComponent
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    CoreModule,
    ClickOutsideModule,
    HttpClientModule,
    UserModule,
    InfiniteScrollModule,
    Ng5SliderModule,
    RouterModule.forRoot(ROUTES),
    Angulartics2Module.forRoot(),
    VendorModule,
    BuyerModule,
    TooltipModule.forRoot(),
    BrowserAnimationsModule,
    ChartModule.forRoot(require('highcharts')),
    ChartModule.forRoot(require('highcharts/highstock')),
    BsDropdownModule.forRoot(),
    ReactiveFormsModule,
    StaticModule,
    SharedModule,
    GoogleTagManagerModule.forRoot({
      id: environment.gtmId,
    })
  ],
  providers: [
    AuthService,
    AuthGuardService,
    CurrencyPipe,
    SessionService,
    GlobalSearchService,
    ResetPasswordService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    Title,
    SharedService,
    PageErrorService,
    GoogleAnalyticsService,
    EmailService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HighchartsStatic,
      useValue: highcharts
    },
    CookieService,
    LoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
