import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-updating-modal',
  templateUrl: './item-updating-modal.component.html',
  styleUrls: ['./item-updating-modal.component.scss']
})
export class ItemUpdatingModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
