<div *ngIf="(topService.loading || !userService.isUserProfileReady) && !isProcessingPopup" class="loader-wrapper">
  <div class="loader"></div>
</div>
<div *ngIf="userService.isUserProfileReady" class="main-cart-wrap" [class.mobile]="topService.isMobile">
  <!-- top component -->
  <div class="container-fluid cart-container" *ngIf="showAddToExistingOrder()">
    <div class="row">
      <div *ngIf="!isCartEmpty()" class="col-md-12 top">
        <div class="top-total">
          <app-cart-top [totalItems]="cartService.cartList.length" [totalUnits]="totalUnits()"
            [findAllErrors]="findAllErrors()" [showAddToExistingOrder]="showAddToExistingOrder()"
            [erroronline]="cartService.erroronline" [items]="cartService.cartList"
            [selectedOrderNumber]="ordersService.selected_order_number" [isOutOfStock]="isOutOfStock"
            (rejectAddtoExistingEmitter)="rejectAddtoExisting()" (cancelItemEmitter)="onCancelCart()"
            [loading]="loading" (showModalToggleEmitter)="onShowModalToggle()" [totalAmount]="totalAmount()"
            (exportToExcelEmmiter)="cartService.exportToCSV()"></app-cart-top>
        </div>
      </div>
    </div>
  </div>

<!-- edit order -->
<div class="cart-top-edit-wrap container-fluid cart-container" *ngIf="isCompletedAndOrderSelected()" [class._next-proceed]="!showAddToExistingOrder()">
  <div class="cart-top-edit align-items-stretch" [ngClass]="{'hide': isCartEmptyAndCompleted() }">
    <app-edit-order [selected_doc_number]="ordersService.selected_doc_number" [selected_order_number]="ordersService.selected_order_number"
     [cartList]="cartService.cartList" [errorCollection]="errorCollection" [loading]="loading"
      (cancelCartEmitter)="onCancelCart()" (placeNewEmitter)="placeNew()">
    </app-edit-order>
  </div>
</div>

  <!-- proceed to checkout -->
  <div class="container-fluid cart-container _proceed-to-checkout-top" *ngIf="!showAddToExistingOrder() && cartService?.cartList?.length"
  [ngClass]="{'hide': hideBox}">
    <app-proceed-to-checkout-top *ngIf="!sharedService.editOrderMode || (sharedService.editOrderMode && selectedOrder)"
      [terms]="userService.terms" [prepay]="prepay" [erroronline]="cartService.erroronline"
      [billing]="userService.billing" [shipping]="userService.shipping"
      [showAddToExistingOrder]="showAddToExistingOrder()" [Baddress]="Baddress" [Saddress]="Saddress" [isCart]="isCart"
      [selectedOrderNumber]="ordersService.selected_order_number" [isMobile]="isMobile"
      [selectedDocNumber]="ordersService.selected_doc_number" [completed]="completed" [totalUnits]="totalUnits()"
      [findAllErrors]="findAllErrors()" [loading]="loading"
      [isOutOfStockAndSelectedOrderNumber]="isOutOfStockAndSelectedOrderNumber()" [isOutOfStock]="isOutOfStock"
      [totalAmount]="totalAmount()" [cartListLength]="cartService.cartList.length"
      [isCartEmptyAndCompleted]="isCartEmptyAndCompleted()" [isCreditCardAllowed]="userService.isCreditCardAllowed" (choosePaymentTypeEmitter)="ChoosePaymentType($event)"
      (selectedAddress)="onSelectedAddress($event)" (exportToExcelEmmiter)="cartService.exportToCSV()"
      (gotoCheckoutEmitter)="gotoCheckout()" (toggleConfirmModalEmitter)="toggleConfirmModal()"
      (placeNewEmitter)="initPlaceNew()" [order]="selectedOrder">
    </app-proceed-to-checkout-top>
  </div>
  <div class="confirm-modal" [hidden]="!confirmModal">
    <div class="confirm-modal-wrap">
      <div class="row margin-0">
        <div class="col-auto confirm-modal-body">
          <p>Are you sure you want to
            <span *ngIf="!ordersService.selected_order_number">place this order</span>
            <span *ngIf="ordersService.selected_order_number">update this order</span>?
          </p>
        </div>
      </div>
      <div class="controls">
        <a (click)="!topService.loading?toggleConfirmModal():''" class="cancel-btn btn">No</a>
        <a (click)="!topService.loading?placeNew():''" class="btn">Yes</a>
      </div>
    </div>
  </div>
  <div class="container-fluid cart-container" [ngClass]="{'hide': completed}" [class.disabled]="isOutOfStock">
    <ng-container *ngIf="!topService.isMobile">
      <div class="row">
          <div [ngClass]="{'hide': completed}" class="item-wrap col-md-12"
          *ngFor="let cartItem of cartService.cartList; let i = index">
            <app-cart-items [selectedOrderNumber]="ordersService.selected_order_number" [cartItem]="cartItem"
              [loading]="loading" [userRole]="user_role" [isOutOfStock]="isOutOfStock" [i]="i" [showWarning]="showWarning"
              [deleteSpinnerItem]="deleteSpinnerItem" [user_role]="user_role" [openMobileInfo]="openMobileInfo"
              [deleteSpinner]="deleteSpinner" [hideText]="itemsService.hideText" [isMobile]="isMobile"
              (itemDetailedMode)="openitemDetailedMode($event)" [selectedCartItem]="selectedCartItem"
              (deleteItemEmitter)="deleteItem($event)" [imageBaseUrl]="imageBaseUrl" (copyStateEmitter)="copyState()"
              (validateCartItemEmitter)="validateCartItem($event)"
              (handleQuantityUpdateEmitter)="handleQuantityUpdate($event)"
              (handlePriceUpdateEmitter)="onHandlePriceUpdateEmitter($event)" (showTolltipEmitter)="showTooltip($event)"
              (onClickedOutsideEmitter)="onClickedOutside($event)" (gradingGuideEmitter)="openGradingGuide($event)">
            </app-cart-items>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="topService.isMobile">
        <div [ngClass]="{'hide': completed}" class="item-wrap-mobile" *ngFor="let cartItem of cartService.cartList; let i = index">
          <app-cart-items-mobile
           [i]="i" [cartItem]="cartItem"
           [imageBaseUrl]="imageBaseUrl"
           (copyStateEmitter)="copyState()"
           [userRole]="user_role"
           [isOutOfStock]="isOutOfStock"
           (handlePriceUpdateEmitter)="onHandlePriceUpdateEmitter($event)"
           [showWarning]="showWarning"
           (handleQuantityUpdateEmitter)="handleQuantityUpdate($event)"
           [deleteSpinner]="deleteSpinner"
           [deleteSpinnerItem]="deleteSpinnerItem"
           [loading]="loading"
           (deleteItemEmitter)="deleteItem($event)"
           (gradingGuideEmitter)="openGradingGuide($event)"

           (notificationTooltipEmitter)="showNotification($event)"
           (onTooltipClose)="hideNotificationTooltip($event)"

          ></app-cart-items-mobile
          >
        </div>
      </ng-container>

  </div>
<!-- </div> -->

<div class="container-fluid cart-container hide" [ngClass]="{'hide': completed || cartService.cartList.length === 0 || hideBox}">
  <app-cart-bottom [cartList]="cartService.cartList" [completed]="completed" [userRole]="user_role" [discount]="userService.discount" [processingFee]="processingFee"
    [totalAmount]="totalAmount()" [editedOrderShippingCost]="editedOrderShippingCost" (editOrderShippingCostEmitter)="updateFreight($event)" [hasOpenOrders]="topService.hasOpenOrders"
    [addToExistingRejected]="addToExistingRejected" (gotoShoppingEmitter)="gotoShopping()" (refreshEmitter)="refresh()" (cancelEmitter)="onCancel()"
    (placeNewEmitter)="initPlaceNew()" [isCart]="isCart" [findAllErrors]="findAllErrors()" [errorCollection]="errorCollection"
    [loading]="loading" (rejectAddtoExistingEmitter)="rejectAddtoExisting()" (commentEmitter)="updateComment($event)"
    [comments]="ordersService.orderChosen.comments" [commentsLength]="cartService.orderCommentsLength" [selectedOrderNumber]="ordersService.selected_order_number"
    (showModalEmitter)="onShowModalToggle()" [showModal]="showModal" [credit]="credit"></app-cart-bottom>
</div>

<div *ngIf="isCartReady && !topService.loading && !cartService.cartList.length" class="container-fluid cart-container" [ngClass]="{'hide': cartService.cartList.length !== 0 || completed }">
  <app-nothing-found-buyer [title]="nothingFoundTitle" [message]="nothingFoundMessage" [buttonText]="nothingFoundButtonText"
    [loading]="topService.loading" (clickEmitter)="gotoShopping()"></app-nothing-found-buyer>
</div>


<div *ngIf="!topService.isMobile">
    <app-item-detail-desktop *ngIf="showDetailedPopUp && selectedCartItem"
      [item]="selectedCartItem.item"
      [openItemDetails]="showDetailedPopUp"
      (notificationTooltipEmitter)="showNotification($event)"
      (onModalClose)="hideDetailedPopUp()"
      (onError)="showError($event)"
      (gradingGuideEmitter)="openGradingGuide($event)"
      (updateQtyOfCartItem)="updateCartQtyFromPopup($event)"
    ></app-item-detail-desktop>
</div>

<div *ngIf="showTerms">
  <app-credit-card-terms (agreementEmit)="sendAgreement($event)" (close)="showTerms=false;"></app-credit-card-terms>
</div>

<div *ngIf="sharedService.isGradingGuideOpen" class="modal" [class.mobile]="topService.isMobile" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="add-to-wrap modal-dialog" role="document" (clickOutside)="alertConfirmBtn()">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="alertConfirmBtn()" aria-label="Close">
            <img src="assets/images/icons/delete-white.svg" width="24" height="24">
          </button>
        </div>
        <div class="modal-body">
          <app-grading-guide2 [isMobile]="topService.isMobile"></app-grading-guide2>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="alertConfirmBtn()">Close</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showModal" class="add-to-existing-modal" [class.mobile]="topService.isMobile">
    <ng-container *ngIf="!topService.isMobile">
      <app-add-to-existing class="add-to-existing" [selectedOpenOrder]="selectedOpenOrder"
      [openOrdersDetails]="openOrdersDetails" [isMobile]="isMobile" [showModal]="showModal"
      [loading2]="topService.loading" [loading]="loading" (openOrderDetailEmitter)="openOrderDetail()"
      [openOrders]="openOrders" (closeOrderModalEmitter)="closeOrderModal()"
      (selectOrderEmitter)="onSelectOrder($event)" (placeExistingEmitter)="placeExisting($event)"></app-add-to-existing>
    </ng-container>

    <ng-container *ngIf="topService.isMobile">
      <app-add-to-existing-mobile [selectedOpenOrder]="selectedOpenOrder"
      [openOrdersDetails]="openOrdersDetails" [isMobile]="isMobile" [showModal]="showModal"
      [loading2]="topService.loading" [loading]="loading"
      [openOrders]="openOrders" (closeOrderModalEmitter)="closeOrderModal()"
      (selectOrderEmitter)="onSelectOrder($event)" (placeExistingEmitter)="placeExisting($event)"></app-add-to-existing-mobile>
    </ng-container>

  </div>

  <app-cart-processing-modal *ngIf="isProcessingPopup" [isAddToExisting]="isProcessingAddToExisting"></app-cart-processing-modal>

  <app-error-modal [bodyText]="errorModal.bodyText" [title]="errorModal.title" [isError]="errorModal.isShown"
    (onConfirmError)="hideError()"></app-error-modal>

    <app-notification-tooltip *ngIf="showNotificationTooltip && notificationItem" [item]="notificationItem" [showNotificationTooltip]="showNotificationTooltip" [tooltipPosition]="tooltipPosition" (onTooltipClose)="hideNotificationTooltip()"></app-notification-tooltip>
</div>

 <app-bottom-nav-mobile [showBackBtn]="isCartEmptyAndCompleted()" *ngIf="topService.isMobile"></app-bottom-nav-mobile>
 <app-restriction-notice *ngIf="showRestrictedCountryPupup" (closeModalEmitter)="showRestrictedCountryPupup = false"></app-restriction-notice>

