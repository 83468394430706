import { Component, OnInit, Output, Input } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-item-success',
  templateUrl: './item-success.component.html',
  styleUrls: ['./item-success.component.scss']
})
export class ItemSuccessComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Input() sucessMsg: string;
  constructor() { }

  ngOnInit() {}

}
