<div class="registration-wrap" [class.mobile]="topService.isMobile">
	<form #f="ngForm">
		<div class="registration-step step-one" *ngIf="!nextStep">
			<div class="registration-header">
				<h2>Guest Access</h2>
				<!-- <img width="221" height="33" src="../../assets/images/tech.svg"> -->
				<p>
					{{ guestRegisterMessage }}
				</p>
			</div>
			<div class="registration-form">
				<div class="form-group" [ngClass]="{'error':!checkFullName() && fullName.touched}">
					<div class="input-group">
						<input type="text" autocomplete="off" class="form-control" [(ngModel)]="userData.fullName" name="fullName"
						 required #fullName="ngModel" placeholder="Full Name" pattern="[a-zA-Z ]*" (blur)="checkFullName()">
						<!-- [ngClass]="{'err': fullName.errors?.pattern && fullName.touched || !validFullName && fullName.touched}"> -->
					</div>
					<div *ngIf="!isFullName() && fullName.touched" style="color:red">
						Please enter first and last name.
					</div>
				</div>

				<!-- <div *ngIf="fullName.errors?.pattern || !validFullName"> -->
				<!-- <div *ngIf="fullName.errors?.pattern && fullName.touched || !validFullName && fullName.touched">
					User name not valid.
			  </div> -->

				<div class="form-group" [ngClass]="{'error':!email.valid && email.touched || emailConfirm() || invalidFeedback !== ''}">
					<div class="input-group">
						<input type="email" autocomplete="off" class="form-control" [(ngModel)]="userData.email" name="email" required
							#email="ngModel" placeholder="Your Company Email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}$" (blur)="emailConfirm()">
						<div class="invalid-feedback" [class.is-invalid]="invalidFeedback !== ''">
					          {{invalidFeedback}}
					    </div>
							<!-- [ngClass]="{'err': email.errors?.pattern && email.touched}"> -->
					</div>
				</div>

				<div class="form-group" [ngClass]="{'error':!email.valid && email.touched || confirmEmail}">
					<div class="input-group">
						<input type="text" autocomplete="off" class="form-control" [(ngModel)]="userData.emailConfirm" name="emailConfirm" required
							#email="ngModel" placeholder="Confirm Your Email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}$" (blur)="emailConfirm()">
							<!-- [ngClass]="{'err': email.errors?.pattern && email.touched}"> -->
					</div>
				</div>

				<div class="form-group" [ngClass]="{'error':!password.valid && password.touched}">
					<div class="input-group">
						<input [type]="isShowPassword ? 'text' : 'password'" readonly onfocus="this.removeAttribute('readonly');"
						 autocomplete="false" class="form-control password" [(ngModel)]="userData.password" name="password"
						 required #password="ngModel" placeholder="Create Password" aria-describedby="passwordHelpBlock"
						 minlength="6" maxlength="8">

						<a role="button" [class.active]="isShowPassword" (click)="showPassword(isShowPassword)" class="show-pw"></a>

					</div>
				</div>

				<div class="accept-checkbox animated" [ngClass]="{'tada': checkboxAnimation}">
						<input type="checkbox" name="" id="accept" (change)="checkAccept($event)">
						<label for="accept"></label>
						<label [ngClass]="{'red-text': checkboxIsChecked, 'white-text': checkboxCheck}" class="label-text" for="accept">
							I hereby consent I am a wholesale buyer, and NOT a consumer or end-user.
						</label>
					</div>

				<button class="btn btn-primary" [disabled]="goneForward
						? (!isFullName() || !password.valid || !fullName.valid || !email.valid || !checkboxCheck ||  emailConfirm())
						: (!isFullName() || !password.valid || !password.dirty || !fullName.valid || !fullName.touched || !email.valid || !email.touched || checkboxAnimation ||  emailConfirm())"
				 type="button" (click)="onSubmit(f,1,$event)">
				 <span *ngIf="!loading">Guest Access</span>
					<div *ngIf="loading" class="login-loader"></div>
				</button>
				<p>HUBX does not sell or ship to consumers</p>
			</div>


		</div>
		<div class="step-two-wrapper" *ngIf="nextStep">
			<div class="registration-step animated step-two" *ngIf="nextStep" [class.flipInY]="nextStep">
				<div class="registration-header">
          <button class="back-btn" (click) = "goBack()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" class="mdl-js"><path d="m16 7.41-4.58 4.59 4.58 4.59-1.41 1.41-6-6 6-6z" fill="#fff"/></svg>
          </button>
					<h2>Guest Access</h2>
				</div>
				<div class="registration-form">
					<div class="form-group">
						<div class="input-group">
							<input type="text" autocomplete="off" class="form-control" [(ngModel)]="userData.companyName" name="companyName"
							 required #companyName="ngModel" placeholder="Company Name">
						</div>
					</div>

					<div class="form-group" [ngClass]="{'error':!companyWeb.valid && companyWeb.touched}">
						<div class="input-group">
							<input type="text" autocomplete="off" class="form-control" [(ngModel)]="userData.companyWeb" name="companyWeb"
							 required #companyWeb="ngModel" pattern="(http(s)?://)?([\w-]+\.)+[\w-]+(/[\w- ;,./?%&=]*)?" placeholder="Company Website">
            </div>
            <div  *ngIf= "!companyWeb.valid && companyWeb.touched"  style="color:red">Please enter a valid web site</div>
					</div>

					<div class="form-group">
							<div class="input-group" [formGroup]="phoneForm">
								<!-- <ngx-intl-tel-input [cssClass]="'custom-phone'" [preferredCountries]="['us', 'gb']" [enablePlaceholder]="true"
									name="phone" formControlName="phone" [(ngModel)]="userData.phoneNumber"></ngx-intl-tel-input> -->
									<input class="form-control" type="text" placeholder="Enter your phone number" (keypress)="onKeyUp($event)"
									[(ngModel)]="userData.phoneNumber" name="phone" formControlName="phone" required
									pattern="^([\(]{1}[0-9]{3}[\)]{1}[\.| |\-]{0,1}|^[0-9]{3}[\.|\-| ]?)?[0-9]{3}(\.|\-| )?[0-9]{4}$"/>
							</div>
						</div>

					<div class="form-group  country-input-group">
						<div class="input-group">
							<select class="form-control select-dropdown-menu" [class.selected]="userData.country !== ''" [(ngModel)]="userData.country" name="country"
							 required #country="ngModel">
                <option value="">Country</option>
                <option value="United States of America (USA)">United States of America (USA)</option>
								<option value="Afghanistan">Afghanistan</option>
								<option value="Albania">Albania</option>
								<option value="Algeria">Algeria</option>
								<option value="Andorra">Andorra</option>
								<option value="Angola">Angola</option>
								<option value="Antigua and Barbuda">Antigua and Barbuda</option>
								<option value="Argentina">Argentina</option>
								<option value="Armenia">Armenia</option>
								<option value="Australia">Australia</option>
								<option value="Austria">Austria</option>
								<option value="Azerbaijan">Azerbaijan</option>
								<option value="Bahamas">Bahamas</option>
								<option value="Bahrain">Bahrain</option>
								<option value="Bangladesh">Bangladesh</option>
								<option value="Barbados">Barbados</option>
								<option value="Belarus">Belarus</option>
								<option value="Belgium">Belgium</option>
								<option value="Belize">Belize</option>
								<option value="Benin">Benin</option>
								<option value="Bhutan">Bhutan</option>
								<option value="Bolivia">Bolivia</option>
								<option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
								<option value="Botswana">Botswana</option>
								<option value="Brazil">Brazil</option>
								<option value="Brunei">Brunei</option>
								<option value="Bulgaria">Bulgaria</option>
								<option value="Burkina Faso">Burkina Faso</option>
								<option value="Burundi">Burundi</option>
								<option value="Cabo Verde">Cabo Verde</option>
								<option value="Cambodia">Cambodia</option>
								<option value="Cameroon">Cameroon</option>
								<option value="Canada">Canada</option>
								<option value="Central African Republic (CAR)">Central African Republic (CAR)</option>
								<option value="Chad">Chad</option>
								<option value="Chile">Chile</option>
								<option value="China">China</option>
								<option value="Colombia">Colombia</option>
								<option value="Comoros">Comoros</option>
								<option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
								<option value="Republic of the Congo">Republic of the Congo</option>
								<option value="Costa Rica">Costa Rica</option>
								<option value="Cote d'Ivoire">Cote d'Ivoire</option>
								<option value="Croatia">Croatia</option>
								<option value="Cuba">Cuba</option>
								<option value="Cyprus">Cyprus</option>
								<option value="Czech Republic">Czech Republic</option>
								<option value="Denmark">Denmark</option>
								<option value="Djibouti">Djibouti</option>
								<option value="Dominica">Dominica</option>
								<option value="Dominican Republic">Dominican Republic</option>
								<option value="Ecuador">Ecuador</option>
								<option value="Egypt">Egypt</option>
								<option value="El Salvador">El Salvador</option>
								<option value="Equatorial Guinea">Equatorial Guinea</option>
								<option value="Eritrea">Eritrea</option>
								<option value="Estonia">Estonia</option>
								<option value="Ethiopia">Ethiopia</option>
								<option value="Fiji">Fiji</option>
								<option value="Finland">Finland</option>
								<option value="France">France</option>
								<option value="Gabon">Gabon</option>
								<option value="Gambia">Gambia</option>
								<option value="Georgia">Georgia</option>
								<option value="Germany">Germany</option>
								<option value="Ghana">Ghana</option>
								<option value="Greece">Greece</option>
								<option value="Grenada">Grenada</option>
								<option value="Guatemala">Guatemala</option>
								<option value="Guinea">Guinea</option>
								<option value="Guinea-Bissau">Guinea-Bissau</option>
								<option value="Guyana">Guyana</option>
								<option value="Haiti">Haiti</option>
								<option value="Honduras">Honduras</option>
								<option value="Hungary">Hungary</option>
								<option value="Iceland">Iceland</option>
								<option value="India">India</option>
								<option value="Indonesia">Indonesia</option>
								<option value="Iran">Iran</option>
								<option value="Iraq">Iraq</option>
								<option value="Ireland">Ireland</option>
								<option value="Israel">Israel</option>
								<option value="Italy">Italy</option>
								<option value="Jamaica">Jamaica</option>
								<option value="Japan">Japan</option>
								<option value="Jordan">Jordan</option>
								<option value="Kazakhstan">Kazakhstan</option>
								<option value="Kenya">Kenya</option>
								<option value="Kiribati">Kiribati</option>
								<option value="Kosovo">Kosovo</option>
								<option value="Kuwait">Kuwait</option>
								<option value="Kyrgyzstan">Kyrgyzstan</option>
								<option value="Laos">Laos</option>
								<option value="Latvia">Latvia</option>
								<option value="Lebanon">Lebanon</option>
								<option value="Lesotho">Lesotho</option>
								<option value="Liberia">Liberia</option>
								<option value="Libya">Libya</option>
								<option value="Liechtenstein">Liechtenstein</option>
								<option value="Lithuania">Lithuania</option>
								<option value="Luxembourg">Luxembourg</option>
								<option value="Macedonia (FYROM)">Macedonia (FYROM)</option>
								<option value="Madagascar">Madagascar</option>
								<option value="Malawi">Malawi</option>
								<option value="Malaysia">Malaysia</option>
								<option value="Maldives">Maldives</option>
								<option value="Mali">Mali</option>
								<option value="Malta">Malta</option>
								<option value="Marshall Islands">Marshall Islands</option>
								<option value="Mauritania">Mauritania</option>
								<option value="Mauritius">Mauritius</option>
								<option value="Mexico">Mexico</option>
								<option value="Micronesia">Micronesia</option>
								<option value="Moldova">Moldova</option>
								<option value="Monaco">Monaco</option>
								<option value="Mongolia">Mongolia</option>
								<option value="Montenegro">Montenegro</option>
								<option value="Morocco">Morocco</option>
								<option value="Mozambique">Mozambique</option>
								<option value="Myanmar (Burma)">Myanmar (Burma)</option>
								<option value="Namibia">Namibia</option>
								<option value="Nauru">Nauru</option>
								<option value="Nepal">Nepal</option>
								<option value="Netherlands">Netherlands</option>
								<option value="New Zealand">New Zealand</option>
								<option value="Nicaragua">Nicaragua</option>
								<option value="Niger">Niger</option>
								<option value="Nigeria">Nigeria</option>
								<option value="North Korea">North Korea</option>
								<option value="Norway">Norway</option>
								<option value="Oman">Oman</option>
								<option value="Pakistan">Pakistan</option>
								<option value="Palau">Palau</option>
								<option value="Palestine">Palestine</option>
								<option value="Panama">Panama</option>
								<option value="Papua New Guinea">Papua New Guinea</option>
								<option value="Paraguay">Paraguay</option>
								<option value="Peru">Peru</option>
								<option value="Philippines">Philippines</option>
								<option value="Poland">Poland</option>
								<option value="Portugal">Portugal</option>
								<option value="Qatar">Qatar</option>
								<option value="Romania">Romania</option>
								<option value="Russia">Russia</option>
								<option value="Rwanda">Rwanda</option>
								<option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
								<option value="Saint Lucia">Saint Lucia</option>
								<option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
								<option value="Samoa">Samoa</option>
								<option value="San Marino">San Marino</option>
								<option value="Sao Tome and Principe">Sao Tome and Principe</option>
								<option value="Saudi Arabia">Saudi Arabia</option>
								<option value="Senegal">Senegal</option>
								<option value="Serbia">Serbia</option>
								<option value="Seychelles">Seychelles</option>
								<option value="Sierra Leone">Sierra Leone</option>
								<option value="Singapore">Singapore</option>
								<option value="Slovakia">Slovakia</option>
								<option value="Slovenia">Slovenia</option>
								<option value="Solomon Islands">Solomon Islands</option>
								<option value="Somalia">Somalia</option>
								<option value="South Africa">South Africa</option>
								<option value="South Korea">South Korea</option>
								<option value="South Sudan">South Sudan</option>
								<option value="Spain">Spain</option>
								<option value="Sri Lanka">Sri Lanka</option>
								<option value="Sudan">Sudan</option>
								<option value="Suriname">Suriname</option>
								<option value="Swaziland">Swaziland</option>
								<option value="Sweden">Sweden</option>
								<option value="Switzerland">Switzerland</option>
								<option value="Syria">Syria</option>
								<option value="Taiwan">Taiwan</option>
								<option value="Tajikistan">Tajikistan</option>
								<option value="Tanzania">Tanzania</option>
								<option value="Thailand">Thailand</option>
								<option value="Timor-Leste">Timor-Leste</option>
								<option value="Togo">Togo</option>
								<option value="Tonga">Tonga</option>
								<option value="Trinidad and Tobago">Trinidad and Tobago</option>
								<option value="Tunisia">Tunisia</option>
								<option value="Turkey">Turkey</option>
								<option value="Turkmenistan">Turkmenistan</option>
								<option value="Tuvalu">Tuvalu</option>
								<option value="Uganda">Uganda</option>
								<option value="Ukraine">Ukraine</option>
								<option value="United Arab Emirates (UAE)">United Arab Emirates (UAE)</option>
								<option value="United Kingdom (UK)">United Kingdom (UK)</option>
								<option value="Uruguay">Uruguay</option>
								<option value="Uzbekistan">Uzbekistan</option>
								<option value="Vanuatu">Vanuatu</option>
								<option value="Vatican City (Holy See)">Vatican City (Holy See)</option>
								<option value="Venezuela">Venezuela</option>
								<option value="Vietnam">Vietnam</option>
								<option value="Yemen">Yemen</option>
								<option value="Zambia">Zambia</option>
								<option value="Zimbabwe">Zimbabwe</option>
							</select>
						</div>
					</div>

					<div class="btn-group" dropdown #dropdownCompanyType="bs-dropdown" [dropup]="true" [autoClose]="false">
              <button id="button-dropup" dropdownToggle type="button" class="btn btn-company-category dropdown-toggle"
               [class.selected]="companyType.length > 0" aria-controls="dropdown-dropup">
                <span *ngIf="!dropdownCompanyType.isOpen">
                  <span *ngIf="!companyType.length">I'm a Buyer/Seller</span>
                  <span *ngIf="companyType.length">{{ getCompanyTypeText() }}</span>
                </span>
                <span *ngIf="dropdownCompanyType.isOpen" class="btn dropdown-done">
                  Done
                </span>
                <span class="caret"></span>
              </button>
              <div id="dropdown-dropup" *dropdownMenu class="dropdown-menu" required>
                <div class="dropdown-header">I'm a Buyer/Seller</div>
                <div class="category-checkbox">
									<label class="checkbox-wrap">
                  <input type="checkbox" name="" id="Buyer" (change)="companyTypeClick($event, 'Buyer')">
                  <label for="Buyer"></label>
                  <label class="label-text" for="Buyer">
                      Buyer
									</label>
									</label>
                </div>

                <div class="category-checkbox">
									<label class="checkbox-wrap">
                  <input type="checkbox" name="" id="Seller" (change)="companyTypeClick($event, 'Seller')">
                  <label for="Seller"></label>
                  <label class="label-text" for="Seller">
                    Seller
									</label>
									</label>
                </div>

                <!-- <div class="category-checkbox">
									<label class="checkbox-wrap">
                  <input type="checkbox" name="" id="Wholesale" (change)="companyTypeClick($event, 'Wholesale')">
                  <label for="Wholesale"></label>
                  <label class="label-text" for="Wholesale">
                      Wholesale
									</label>
									</label>
                </div> -->
                <!-- <div class="category-checkbox">
										<label class="checkbox-wrap">
                    <input type="checkbox" name="" id="e-commerce" (change)="companyTypeClick($event, 'E-commerce')">
                    <label for="e-commerce"></label>
                    <label class="label-text" for="e-commerce">
                        e-commerce
										</label>
										</label>
                  </div> -->
              </div>
            </div>

					<div class="form-group">
						<div class="input-group">
							<select class="form-control select-dropdown-menu" [class.selected]="userData.companyRevenue !== ''" [(ngModel)]="userData.companyRevenue"
							 name="companyRevenue" required #companyRevenue="ngModel">
								<option value="">Company Revenues</option>
								<option value="0 - $999,999">0 - $999,999</option>
								<option value="$1,000,000-$4,999,999">$1,000,000-$4,999,999</option>
								<option value="$5,000,000-$9,999,999">$5,000,000-$9,999,999</option>
								<option value="$10,000,000-$19,999,999">$10,000,000-$19,999,999</option>
								<option value="$20,000,000-$49,999,999">$20,000,000-$49,999,999</option>
								<option value="$50,000,000-$99,999,999">$50,000,000-$99,999,999</option>
								<option value="$100,000,000+">$100,000,000+</option>
							</select>
						</div>
					</div>

					<!-- 	<div class="form-group" [ngClass]="{'error':!companyCategory.valid && companyCategory.touched}">
							<div class="input-group">
							    <select class="form-control select-dropdown-menu"
							    [(ngModel)]="userData.companyCategory"
							    name="companyCategory"
							    required
							    #companyCategory="ngModel">
									<option value="">What Categories do you purchase?</option>
									<option value="someCat">somecat</option>
							    </select>
							</div>
						</div> -->

					<div class="btn-group" dropdown #dropdown="bs-dropdown" [dropup]="true" [autoClose]="false">
						<button id="button-dropup" dropdownToggle type="button" class="btn btn-company-category dropdown-toggle"
						 [class.selected]="categories.length>0" aria-controls="dropdown-dropup">
							<span *ngIf="!dropdown.isOpen">
								<span *ngIf="!categories.length">I'm interested in:</span>
								<span *ngIf="categories.length">{{ getCategoriesText() }}</span>
							</span>
							<span *ngIf="dropdown.isOpen" class="btn dropdown-done">
								Done
							</span>
							<span class="caret"></span>
						</button>
						<div id="dropdown-dropup" *dropdownMenu class="dropdown-menu" required>
							<div class="dropdown-header">I'm interested in:</div>
							<div class="category-checkbox">
									<label class="checkbox-wrap">
								<input type="checkbox" name="" id="computers" (change)="recordClick($event, 'Computers')">
								<label for="computers"></label>
								<label class="label-text" for="computers">
									Computers
								</label>
								</label>
							</div>

							<div class="category-checkbox">
								<label class="checkbox-wrap">
								<input type="checkbox" name="" id="mobile-phones" (change)="recordClick($event, 'Mobile Phones')">
								<label for="mobile-phones"></label>
								<label class="label-text" for="mobile-phones">
									Mobile Phones
								</label>
							</label>
							</div>

							<div class="category-checkbox">
								<label class="checkbox-wrap">
								<input type="checkbox" name="" id="consumer-electronics" (change)="recordClick($event, 'Consumer Electronics')">
								<label for="consumer-electronics"></label>
								<label class="label-text" for="consumer-electronics">
									Consumer Electronics
								</label>
								</label>
							</div>

						</div>
					</div>

					<button class="btn btn-primary step-two-login" type="submit" [disabled]="!f.valid || !categories.length || !companyType.length || dropdown.isOpen || dropdownCompanyType.isOpen"
					 (click)="onSubmit(f,2,$event)">
						<span *ngIf="!loading">Login</span>
						<div *ngIf="loading" class="login-loader"></div>
					</button>
					<p>HUBX does not sell or ship to consumers</p>

				</div>
			</div>
		</div>
	</form>
</div>
