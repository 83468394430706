import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'statusPipe'})
export class StatusPipe implements PipeTransform {
  transform(value: string, selectedValue: string): string {
    if (value !== selectedValue || value !== 'Pause') {
      return value;
    }

    return 'Paused';
  }
}
