<div class="activity-box-wrap">
  <div class="title">Products Selling on HUBX</div>

  <table>
    <thead>
      <tr>
        <th>SKU</th>
        <th class="text-right">SOLD</th>
        <th class="text-right">AMOUNT</th>
      </tr>
    </thead>

      <tbody>
        <tr>
          <td>
            1478435345
            <span class="row-hover"></span>
          </td>
          <td class="text-right">1,500</td>
          <td class="text-right">$156,000</td>
        </tr>

        <tr>
            <td>
              1478435345
              <span class="row-hover"></span>
            </td>
            <td class="text-right">750</td>
            <td class="text-right">$96,000</td>
        </tr>

        <tr>
            <td>
              1478435345
              <span class="row-hover"></span>
            </td>
            <td class="text-right">750</td>
            <td class="text-right">$96,000</td>
        </tr>

       <tr>
          <td>
            1478435345
            <span class="row-hover"></span>
          </td>
          <td class="text-right">1,500</td>
          <td class="text-right">$156,000</td>
        </tr>

        <tr>
            <td>
              1478435345
              <span class="row-hover"></span>
            </td>
            <td class="text-right">750</td>
            <td class="text-right">$96,000</td>
        </tr>

        <tr>
            <td>
              1478435345
              <span class="row-hover"></span>
            </td>
            <td class="text-right">1,500</td>
            <td class="text-right">$156,000</td>
       </tr>

       <tr>
          <td>
            1478435345
            <span class="row-hover"></span>
          </td>
          <td class="text-right">750</td>
          <td class="text-right">$96,000</td>
      </tr>

        <tr>
            <td>
              1478435345
              <span class="row-hover"></span>
            </td>
            <td class="text-right">1,500</td>
            <td class="text-right">$156,000</td>
        </tr>

        <tr>
            <td>
              1478435345
              <span class="row-hover"></span>
            </td>
            <td class="text-right">750</td>
            <td class="text-right">$96,000</td>
        </tr>
      </tbody>
  </table>

</div>



<!-- <div class="activity-box-wrap">
    <div class="title">Products Selling on HUBX</div>
    <table>
      <thead>
        <tr>
          <th>SKU</th>
          <th class="text-right">SOLD</th>
          <th class="text-right">AMOUNT</th>
        </tr>
      </thead>
        <tbody>
          <tr *ngFor="let product of products">
            <td>{{product.sku}}</td>
            <td class="text-right">{{product.sold}} </td>
            <td class="text-right">{{product.amount | currency:'USD':'symbol-narrow'}}</td>
          </tr>
        </tbody>
    </table>
  </div> -->