import { Component, OnInit } from '@angular/core';
import { IUserInfo } from '../interfaces/IUserInfo';
import { NgForm } from '@angular/forms';
import { StaticService } from '../static.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { SessionService } from '../../services/session.service';
import { SharedService } from '../../shared/shared.service';
import { GuestService } from '../../shared/services/guest.service';
import { IBuyerErrorModal } from '../../buyer/interfaces/model';
import { UserService } from '../../user/user.service';
import { TopService } from '../../shared/services/top.service';

@Component({
  selector: 'app-customer-application',
  templateUrl: './customer-application.component.html',
  styleUrls: ['./customer-application.component.scss']
})
export class CustomerApplicationComponent implements OnInit {
  userInfo: IUserInfo = {};
  isExportCompany = '';
  firstName = '';
  lastName = '';
  address1 = '';
  address2 = '';
  isResolved = false;
  recaptchaKey = '';
  loading = false;
  errorModal: IBuyerErrorModal = {
    bodyText: '',
    title: '',
    isShown: false
  };

  constructor(
    private staticService: StaticService,
    private router: Router,
    private authService: AuthService,
    public sessionService: SessionService,
    private sharedService: SharedService,
    private guestService: GuestService,
    private userService: UserService,
    public topService: TopService) { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.recaptchaKey = environment.recaptchaKey;
    this.sharedService.isCorpSite = true;
    this.sharedService.isStaticHtml = true;
    this.getLeadProfileIfAvailable();
  }

  getLeadProfileIfAvailable(): void {
    if (this.sessionService.userEmail) {
      this.guestService.getGuestEmail(this.sessionService.userEmail).subscribe(
        (result) => {
          this.userService.leadProfile = result;
          this.initVariables();
        },
        (error) => {
          // alert('Error occured on get guest email translation.');
          console.log('error occured on get guest email translation.');
        }
      );
    } else {
      this.initVariables();
    }
  }

  initVariables(): void {
    this.firstName = this.userService.leadProfile.fullName ? this.userService.leadProfile.fullName.split(' ')[0] : '';
    this.lastName = this.userService.leadProfile.fullName ? this.userService.leadProfile.fullName.split(' ')[1] : '';
    this.userInfo.email = this.userService.leadProfile.email ? this.userService.leadProfile.email : '';
    this.userInfo.companyName = this.userService.leadProfile.companyName ? this.userService.leadProfile.companyName : '';
    this.userInfo.companyWeb = this.userService.leadProfile.companyWeb ? this.userService.leadProfile.companyWeb : '';
    this.userInfo.country = this.userService.leadProfile.country ? this.userService.leadProfile.country : '';
    this.userInfo.phoneNumber = this.userService.leadProfile.phoneNumber ? this.userService.leadProfile.phoneNumber : '';
  }

  navigateTo(url: string): void {
    this.router.navigate([url]);
  }

  onSubmit(form: NgForm, step, $event): void {
    event.preventDefault();
    event.stopPropagation();
    this.setUpIsExport();
    this.userInfo.sourceInfo = 'customer';
    this.userInfo.companyType = 'Buyer';
    this.userInfo.fullName = this.firstName + ' ' + this.lastName;
    this.userInfo.address = this.address1 + ' ' + this.address2;
    this.loading = true;


    this.staticService.registerUser(this.userInfo).subscribe(
      (data) => {
        this.navigateTo('thank-you');
        this.loading = false;
      },
      (err) => {
        this.errorModal.bodyText = 'Unable to register customer.';
        this.errorModal.title = 'Server Error';
        this.errorModal.isShown = true;
        this.loading = false;
      }
    );
  }

  hideError(): void {
    this.errorModal.isShown = false;
  }

  showError(errorModal: IBuyerErrorModal): void {
    this.errorModal = errorModal;
  }

  resolved(event: string): void {
    this.isResolved = true;
  }

  setUpIsExport(): void {
    if (this.isExportCompany === 'Yes') {
      this.userInfo.isExportCompany = true;
    } else {
      this.userInfo.isExportCompany = false;
    }
  }
}
