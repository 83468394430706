<div *ngIf="topService.loading" class="loader"></div>
<div class="mobile-notifications-wrapper" [class.mobile]="topService.isMobile" [class.from-vendor]="isVendor">
  <div class="notifications-content">
    <div class="notifications-section">
      <div class="notifications-section__header">
        <h2>Mobile Notifications {{ (fgToggle?.value.notifToggle) ? 'Enabled' : 'Disabled' }}</h2>
      </div>
      <div class="toggle-content">
        <form [formGroup]="fgToggle">
          <label class="checkbox-toggle">
            <input type="checkbox" name="" id="" formControlName="notifToggle">
            <i class="checkbox-toggle__icon" [class.disabled]="isEdit || isConfirm"></i>
          </label>
        </form>

        <div class="notifications-info" *ngIf="userService.userProfile?.phoneNumber">
          <div class="notifications-info__txt" [class.hide]="!fgToggle?.value.notifToggle">{{ getPhoneNumberAndMsgOptionsText() }}</div>
          <span class="notifications-info__btn" (click)="editPhoneNumb()" [class.hide]="!fgToggle?.value.notifToggle || isConfirm || isEdit">Edit</span>
        </div>
      </div>
    </div>
    <div class="notifications-section" *ngIf="phoneIsVerified">
      <div class="thanks-section">
        <div class="thanks-section__txt">
          <div class="notifications-section__header">
            <h2>Thank you {{ userService.userProfile?.firstName }}!</h2>
          </div>
          <p>You’re all set. You will now receive HUBX notifications to your phone. </p>
        </div>
        <div class="thanks-section__btn">
            <button type="button" class="button" (click)="phoneIsVerified = false;">Got It</button>
        </div>
      </div>
    </div>
    <div class="notifications-section" *ngIf="isConfirm">
      <div class="verification-section">
        <div class="verification-section__top">
          <div class="notifications-section__header">
            <h2>We sent a 4 digit confirmation code to your phone or WhatsApp.
              Please confirm.</h2>
          </div>
          <form [formGroup]="fgConfirm">
            <div class="verification-section-input-list">
              <div class="verification-section-input">
                <input type="tel" placeholder="_" maxlength="1" formControlName="n1" mask="0" #n1>
              </div>
              <div class="verification-section-input">
                <input type="tel" placeholder="_" maxlength="1" formControlName="n2" mask="0" #n2>
              </div>
              <div class="verification-section-input">
                <input type="tel" placeholder="_" maxlength="1" formControlName="n3" mask="0" #n3>
              </div>
              <div class="verification-section-input">
                <input type="tel" placeholder="_" maxlength="1" formControlName="n4" mask="0" #n4>
              </div>
            </div>
            <p class="cancel-error" *ngIf="showError">Invalid code, please try again.</p>
          </form>
        </div>
        <div class="verification-section__btm">
          <span class="verification-section-resend" (click)="resendVerificationCode()">Click here to resend</span>
          <div class="verification-section-ctrls">
            <button type="button" class="button-transparent back" (click)="isEdit = true; isConfirm = false;">Back</button>
            <!-- <a role="button" class="button-animated"
            [class.disabled]="!fgConfirm.valid || topService.loading"
            (click)="confirmPhoneNumber()"
            >
              <span class="button-animated__wrap">
                <div class="button-animated__text">Confirm</div>
                <svg width="166px" height="44px" viewBox="-1 -1 168 46" class="button-animated__svg"><path class="button-animated__path"></path></svg>
              </span>
            </a> -->
          </div>
        </div>
      </div>
    </div>
    <div class="notifications-section" *ngIf="isEdit && !topService.loading">
      <div class="form-section">
        <div class="form-section__txt">
          <div class="notifications-section__header">
            <h2>Hey {{ userService.userProfile?.firstName }}!</h2>
          </div>
          <p>We launched a new feature which allows you to receive your HUBX notifications via SMS or WhatsApp.</p>
          <p>Please provide your number.</p>
          <span>(You will only receive SMS or WhatsApp from notifications you selected in your HUBX account for price
            drops, more inventory or back in stock items)</span>
        </div>
        <form [formGroup]="fgPhoneNumb">
          <div class="form-section__form">
            <div class="notifications-form">
              <span class="label-select"><strong>Select:</strong></span>
              <div class="notifications-form__radio">
                <label class="radio-btn">
                  <input type="radio" id="" formControlName="msgType" [value]="1">
                  <span class="radio-btn__label">SMS/Text</span>
                </label>
                <label class="radio-btn">
                  <input type="radio" id="" formControlName="msgType" [value]="2">
                  <span class="radio-btn__label">WhatsApp</span>
                </label>
              </div>
              <div class="notifications-form__input">
                <div class="notifications-dropdown" (clickOutside)="hideDropdown($event)">
                  <div class="notifications-dropdown__selected" (click)="toggleDropdown()">
                    <img class="icon" width="24" height="24" src="https://cdn.hubx.com/{{selectedCountry?.flagUrl}}"/>
                    <span>+{{selectedCountry?.code}}</span>
                    <i>
                      <img src="../../../assets/images/vendor-icons/icon-dropdown-black.svg">
                    </i>
                  </div>
                  <div class="notifications-dropdown__wrapper" [class.active]="isDropdownToggled">
                    <div class="notifications-dropdown__select" *ngFor="let country of countries" [class.active]="country.id===selectedCountry?.id" (click)="selectCountry(country)">
                      <img class="icon" width="24" height="24" src="https://cdn.hubx.com/{{country.flagUrl}}"/>
                      <span class="code">+{{country.code}}</span>
                      <span>{{country.countryName}}</span>
                    </div>
                  </div>
                </div>
                <input type="tel" placeholder="Enter number here" formControlName="phoneNumb" mask="000 000 0000 0*" [validation]="false">
              </div>
              <div class="notifications-form__ctrls">
                <button type="button" class="button-transparent" (click)="isEdit= false;" [class.hide]="!userService.userProfile?.phoneNumber">Cancel</button>
                <button type="button" [disabled]="
                !fgPhoneNumb.valid || 
                topService.loading || 
                !selectedCountry ||
                (!isThereAChange() && this.userService.userProfile.isVerified) ||
                !isSMSAllowed" class="button" (click)="savePhoneNumber()">Confirm</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<app-error-modal
  [title]="'Warning'" [isError]="showSMSPopup" [bodyText]="'SMS/Text is not available in your country. Please select WhatsApp instead.'" (onConfirmError)="onSmsPopupClose()">
</app-error-modal>
