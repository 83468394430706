<section class="main-login col-12" [class.mobile]="topService.isMobile">
  <div class="login-box-wrap">
    <div class="login-box">

      <div class="login-box-header">
        <i [class._loading]="userService.loading"
           [class._on-error]="!userService.loading && userService.loginFail"
           [class._on-success]="!userService.loading && userService.loginSuccess">
        </i>
      </div>
      <div *ngIf="!showForgot" class="login-header animated" [ngClass]="{'flipInY': fromForgot}">
        Customer Login
      </div>

      <div *ngIf="showForgot" class="forgot-password">
        <app-forgot-password (hideForgotPwd)="hideLogin($event)" [shownEmail]="loginData.email"></app-forgot-password>
      </div>

      <div *ngIf="!showForgot" class="login-body">
         <form (ngSubmit)="loginForm.form.valid && login($event)" (keyup.enter)="login($event)" #loginForm="ngForm">
          <div class="form-group">
            <div class="input-group" (click)="focusInput(emailInput)">
              <input type="email" class="form-control animated"
              autofocus tabindex=1
              [ngClass]="{'flipInY': fromForgot, 'err': loginForm.submitted && email.invalid}"
              [(ngModel)]="loginData.email" name="email"
              placeholder="Email"
              pattern="^([\w0-9_\-\.]+)@([\w0-9_\-\.]+)\.([\w]{2,5})$"
              required #email="ngModel"
              #emailInput>
            </div>
          </div>

          <div class="form-group" (click)="focusInput(passInput)">
            <div class="input-group">
              <input type="password"
              tabindex=2
              class="form-control animated"
              [ngClass]="{'flipInY': fromForgot, 'err': loginForm.submitted && password.invalid}"
              [(ngModel)]="loginData.password"
              name="password"
              placeholder="Password"
              required #password="ngModel"
              #passInput>
            </div>
          </div>

        <button class="btn login-button animated" type="submit"
           [class.onerror]="userService.loginFail"
           [ngClass]="{'flipInY': fromForgot}">
            <span *ngIf="(!userService.loading && topService.isMobile) || !topService.isMobile">Login</span>
            <div *ngIf="userService.loading && topService.isMobile" class="login-loader"></div>
          </button>
         </form>
      </div>

      <div class='error-box-wrapper'>
        <div class='error-box animated' [class.flipInY]="fromForgot" *ngIf="!showForgot  && userService.loginFail && !userService.loading">
          Login failed!
        </div>
      </div>

      <div class="login-footer animated" [class.error]="loginFail" *ngIf="!showForgot">
        <a (click)="showForgotPwd()"  tabindex="3" class="login-link animated" [ngClass]="{'flipInY': fromForgot}">Forgot Password?</a>
      </div>
    </div>
  </div>
</section>
