import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { CreditCardService } from '../credit-card.service';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TopService } from '../../../shared/services/top.service';
import { ICreditCard, IBillInfo, ICreditCardRequest } from './../../../shared/interfaces/ICreditCard';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CartService } from '../../cart/cart.service';
import { UserService } from '../../../user/user.service';


@Component({
  selector: 'app-credit-card-list',
  templateUrl: './credit-card-list.component.html',
  styleUrls: ['./credit-card-list.component.scss']
})
export class CreditCardListComponent implements OnInit, OnDestroy {
  @Output() closeCreditCardListModal = new EventEmitter();
  creditCardIdToEdit: string;
  calledFrom: string;
  showCreditCardModal = false;
  verificationForm: FormGroup;

  constructor(
    public topService: TopService,
    private router: Router,
    public creditCardService: CreditCardService,
    public cartService: CartService,
    private formB: FormBuilder,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.calledFrom = this.creditCardService.verifyUrl();

    // modal in cart
    if (this.calledFrom !== 'user-profile') {
      document.documentElement.classList.add('gg-modal');
      if (this.topService.isMobile) {
        document.documentElement.classList.add('is-modal');
      }
    } else {
      // user profile route
      this.getCreditCardList();
    }

    this.createVerificationForm();
  }

  ngOnDestroy() {
    document.documentElement.classList.remove('gg-modal');
    if (this.topService.isMobile) {
      document.documentElement.classList.remove('is-modal');
    }
  }

  createVerificationForm() {
    this.verificationForm = this.formB.group({
      code: ['', [Validators.minLength(3), Validators.maxLength(4)]]
    });
  }

  getCreditCardList(): void {
    this.topService.loading = true;
    this.creditCardService.getCreditCardList()
      .subscribe(
        (list: ICreditCard[]) => {
          this.topService.loading = false;
          if (list.length === 0) {
            this.showCreditCard();
          }
        },
        (err) => {
          this.topService.loading = false;
          console.log('Error trying to get a credit card: ' + err);
        });
  }

  chooseCard(card: ICreditCard): void {
    if (this.calledFrom === 'cart') {
      if (sessionStorage.getItem('user_role') !== 'BUYER') {
        this.toggleVerificationForm(card, this.creditCardService.creditCardList);
      } else {
        this.creditCardService.selectedCreditCard = card;
        this.cartService.isCreditCardDeclined = false;
        this.closeCreditCardListModal.emit();
      }
    }
  }

  toggleVerificationForm(card, list): void {
    this.verificationForm.reset();
    list.forEach(cc => {
      card.valid = true;
      card.verficationCode = '';
      if (cc.id === card.id) {
        card.showVerificationForm = !card.showVerificationForm;
      } else {
        cc.showVerificationForm = false;
      }
    });
  }

  verifyCardCode(card: ICreditCard) {
    this.topService.loading = true;
    this.creditCardService.verifyCreditCardCode(card['verficationCode'], card.id)
      .subscribe(
        (data) => {
          this.topService.loading = false;
          this.creditCardService.selectedCreditCard = card;
          this.cartService.isCreditCardDeclined = false;
          this.toggleVerificationForm(card, this.creditCardService.creditCardList);
          card['valid'] = true;
          card['verficationCode'] = '';
          this.closeCreditCardListModal.emit();
        },
        (err) => {
          console.log('Error trying to update a credit card: ' + err);
          card['valid'] = false;
          this.topService.loading = false;
        });
  }

  showCreditCard(creditCardId?: string): void {
    this.creditCardIdToEdit = creditCardId ? creditCardId : null;
    this.showCreditCardModal = true;
  }

  closeCreditCardModal(): void {
    this.showCreditCardModal = false;

    if (this.creditCardService.creditCardList.length === 0 && this.calledFrom === 'cart') {
      this.closeCreditCardListModal.emit();
    }
  }

  delete(creditCardId: string) {
    this.topService.loading = true;
    this.creditCardService.deleteCreditCard(creditCardId)
      .subscribe(
        (data) => {
          this.userService.checkCreditCardListEmpty();
          this.topService.loading = false;
          const index = this.creditCardService.creditCardList.findIndex(cc => cc.id === creditCardId);
          this.creditCardService.creditCardList.splice(index, 1);
          if (this.creditCardService.creditCardList.length === 0 || this.creditCardService.selectedCreditCard.id === creditCardId ) {
            this.creditCardService.selectedCreditCard = null;
          }
        },
        (err) => {
          console.log('Error trying to delete a credit card: ' + err);
          this.topService.loading = false;
        });
  }


}
