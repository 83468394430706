export enum OfferCancelationType
{
  ItemChanged = 1,
  BuyerDisabled,
  VendorDisabled,
  BuyerCancelled,
  VendorCancelled,
  BuyerRejected,
  VendorRejected,
  OfferExpired
}
