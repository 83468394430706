<div class="filters _today-deals" [class._price-drop]="itemFilterService.selectedZeroLevel?.navName === 'Price Drop'"
  [class._just-launched]="itemFilterService.selectedZeroLevel?.navName === 'Just Launched'">
  <div class="filters-heading"
    [style.marginBottom.px]="( itemsService.isJustSoldUrl || (itemsService.isPastPurchase && !userService.isGuest)) ? 20 : 0">
    <h3 class="filters__title">
      <div class="breadCrumb">
        <span (click)="resetSearch()" *ngIf="itemsService.currentNavigation?.navName === 'searching'">Search results
          for:
          '{{ topService.searchString }}' </span>

        <!-- Navigation e.g('Todays Deals, Price Drop, etc') -->
        <span *ngIf="itemFilterService.selectedZeroLevel?.redableTitle"
          (click)="resetOriginalNav(itemFilterService.selectedZeroLevel)" class="_has-icon"
          [class._has-saved-search-label]="itemFilterService.userFilter">
          <!-- categoty icons -->
          <ng-container [ngSwitch]="itemFilterService.selectedZeroLevel?.redableTitle">
            <img *ngSwitchCase="'Just Launched'" src="../../../assets/images/icons/item-filter/28-just-l.svg" width="28"
              height="28" alt="">
            <!-- just sold animation-->
            <svg height="28" viewBox="0 0 56 56" width="28" xmlns="http://www.w3.org/2000/svg"
              *ngSwitchCase="'Just Sold'">
              <g fill="none" fill-rule="nonzero">
                <g fill="#FFFFFF">
                  <path class="move-down-first"
                    d="m9.69230769 11h33.61538461c.9346357 0 1.6923077.757672 1.6923077 1.6923077v3.6153846c0 .9346357-.757672 1.6923077-1.6923077 1.6923077h-33.61538461c-.93463573 0-1.69230769-.757672-1.69230769-1.6923077v-3.6153846c0-.9346357.75767196-1.6923077 1.69230769-1.6923077z" />
                  <path class="move"
                    d="m9.69230769 14h33.61538461c.9346357 0 1.6923077.757672 1.6923077 1.6923077v3.6153846c0 .9346357-.757672 1.6923077-1.6923077 1.6923077h-33.61538461c-.93463573 0-1.69230769-.757672-1.69230769-1.6923077v-3.6153846c0-.9346357.75767196-1.6923077 1.69230769-1.6923077z" />
                  <path class="move"
                    d="m9.69230769 24h33.61538461c.9346357 0 1.6923077.757672 1.6923077 1.6923077v3.6153846c0 .9346357-.757672 1.6923077-1.6923077 1.6923077h-33.61538461c-.93463573 0-1.69230769-.757672-1.69230769-1.6923077v-3.6153846c0-.9346357.75767196-1.6923077 1.69230769-1.6923077z" />
                  <path class="move-down-last"
                    d="m9.69230769 34h33.61538461c.9346357 0 1.6923077.757672 1.6923077 1.6923077v3.6153846c0 .9346357-.757672 1.6923077-1.6923077 1.6923077h-33.61538461c-.93463573 0-1.69230769-.757672-1.69230769-1.6923077v-3.6153846c0-.9346357.75767196-1.6923077 1.69230769-1.6923077z" />
                </g>
                <path
                  d="m37 51c-8.2842712 0-15-6.7157288-15-15s6.7157288-15 15-15 15 6.7157288 15 15-6.7157288 15-15 15z"
                  fill="#1B1B1B" />
                <path
                  d="m37 48c-6.627417 0-12-5.372583-12-12s5.372583-12 12-12 12 5.372583 12 12-5.372583 12-12 12zm0-20c-1.1045695 0-2 .8954305-2 2v6c0 1.1045695.8954305 2 2 2s2-.8954305 2-2v-6c0-1.1045695-.8954305-2-2-2z"
                  fill="#FFFFFF" transform="matrix(0 1 -1 0 73 -1)" />
                <rect class="rotate-this" fill="#1B1B1B" height="10" rx="2" width="4" x="35" y="28" />
              </g>
            </svg>
            <img *ngSwitchCase="'Price Drop'" src="../../../assets/images/icons/item-filter/28-price-drop.svg"
              width="28" height="28" alt="">
            <img *ngSwitchCase="'Todays Deals'" src="../../../assets/images/icons/item-filter/doorbuster-28x28.svg"
              width="28" height="28" alt="">
            <img *ngSwitchCase="'Past Purchases'" src="../../../assets/images/icons/header/past-purchase-white.svg"
              width="28" height="28" alt="">
            <img *ngSwitchDefault src="../../../assets/images/icons/item-filter/28-categories.svg" width="28"
              height="28" alt="">
          </ng-container>
          <span class="_saved-search-label" *ngIf="itemFilterService.userFilter" (click)="$event.stopPropagation()">
            Saved Search:&nbsp;
          </span>
          <span (click)="reloadSavedFilter($event)">
            {{ itemFilterService.selectedZeroLevel?.redableTitle | currentDeal : true}}
          </span>
        </span>
        <span *ngIf="itemFilterService.selectedFirstLevel"
          (click)="resetOriginalNav(itemFilterService.selectedFirstLevel, 1)">
          {{ itemFilterService.selectedFirstLevel?.navName}} </span>

        <!-- manufacturer -->
        <span *ngIf="itemFilterService.selectedManufacturer"
          (click)="changeManufacturer(itemFilterService.selectedManufacturer)">
          {{ itemFilterService.selectedManufacturer.name }} </span>
        <!-- Second Level -->
        <span *ngIf="itemFilterService.selectedSecondLevel"
          (click)="selectChildNav(itemFilterService.selectedSecondLevel, 2)">
          {{ itemFilterService.selectedSecondLevel.navName}} </span>
        <!-- Third level -->
        <span *ngIf="itemFilterService.selectedThirdLevel"
          (click)="selectChildNav(itemFilterService.selectedThirdLevel, 3)">
          {{ itemFilterService.selectedThirdLevel.navName}} </span>
        <!-- Conditions -->
        <span
          *ngIf="itemFilterService.selectedCondition && itemFilterService.selectedCondition.value !== 'All Conditions'">
          {{ itemFilterService.selectedCondition.value }}
        </span>
      </div>
      <span class="_count" [class.hide]="!itemsListFeched" *ngIf="itemsListFeched && itemsListFeched?.totalCount > 0 
        && itemFilterService.selectedZeroLevel?.redableTitle != 'Just Sold'
        && itemFilterService.selectedZeroLevel?.redableTitle != 'Past Purchases'">
       {{getActiveNavCounter()}}
      </span>
      <span class="_count" [class.hide]="topService.loading"
        *ngIf="itemFilterService.selectedZeroLevel?.redableTitle=='Just Sold'">
        {{ sharedService.justSoldValues.totalLineItems ? sharedService.justSoldValues.totalLineItems : 0 }}
      </span>
      <span class="_count" [class.hide]="topService.loading"
        *ngIf="itemFilterService.selectedZeroLevel?.redableTitle=='Past Purchases'">
        {{ sharedService.pastPurchasesTotal ? sharedService.pastPurchasesTotal : 0 }}
      </span>

    </h3>
    <div class="filters-saved-searches">
      <!--View state-->
      <button type="button" (click)="goToSavedFilters()" *ngIf="showViewUserFiltersBtn()"
        [disabled]="topService.loading" class="__view">
        View Saved Searches
        <img src="../../../assets/images/icons/item-filter/arrow-right.svg" width="24" height="24" alt="">
      </button>

      <button type="button" (click)="openUserFilterModal($event)" [disabled]="topService.loading"
        *ngIf="showSaveUserFilterBtn()">
        <!--add state-->
        <ng-container>
          <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
              <circle cx="12" cy="12" fill="#4687d6" r="10" />
              <path d="m13 7v3.999l4 .001v2l-4-.001v4.001h-2v-4.001l-4 .001v-2l4-.001v-3.999z" fill="#fff" />
            </g>
          </svg>
          Save New Search
        </ng-container>

        <!--edit state
        <ng-container *ngIf="!isNewSearch() && sessionService.userRole !== 'GUEST'">
          <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m19.361 4.23846618c-1.442-1.443-2.525-1.227-2.525-1.227l-6.052 6.052-6.774 6.77300002-1.01 4.762 4.763-1.01 6.774-6.771 6.052-6.05200002c-.001 0 .216-1.083-1.228-2.527zm-11.881 14.77800002-1.624.35c-.156-.293-.345-.586-.69-.932-.346-.346-.639-.533-.932-.691l.35-1.623.47-.469s.883.018 1.881 1.016c.997.996 1.016 1.881 1.016 1.881z" fill="#ffffff" opacity=".8"/></svg>
          Edit Saved Search
        </ng-container>-->
      </button>
    </div>
    <div class="filters-search" *ngIf="itemsService.isPastPurchase && !userService.isGuest">
      <div class="filters-search-group">
        <span class="filters-search__btn" (click)="searchPastPurchase()">
          <img width="24" height="24" src="assets/images/icons/auctions/filter-search.svg">
        </span>
        <input class="filters-search__input" type="search" name="purchases-search"
          placeholder="Search Order or Part Number" (keyup)="isKeyEnter($event)"
          [(ngModel)]="topService.pastPurchasesSearchString">
        <span class="filters-search__btn __close"
          (click)="topService.pastPurchasesSearchString = ''; searchPastPurchase()"
          *ngIf="topService.pastPurchasesSearchString?.length">
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="delete/white" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <polygon id="Shape" fill="#FFFFFF" fill-rule="nonzero"
                points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12">
              </polygon>
            </g>
          </svg>
        </span>
      </div>
    </div>
  </div>
  <div class="filters-top" *ngIf="!itemsService.isJustSoldUrl && !itemsService.isPastPurchase">
    <span class="filter-listing__nav" (click)="scrollTo('left')">
      <img src="../../../assets/images/icons/item-filter/arrow-left.svg" width="24" height="24" alt="">
    </span>
    <ul class="filter-listing" #scrollFilter>
      <ng-container *ngIf="isOutletPage(); else catalog">
        <!-- Manufacturer logo box -->
        <li>
          <div class="filter-listing-item" [class._opened]="itemFilterService.selectedManufacturer">
            <div class="filter-listing-item-btn _has-right-arrow"
              [class._active]="itemFilterService.selectedManufacturer"
              [class._loading]="itemFilterService.selectedManufacturer && !itemFilterService.selectedSecondLevel && topService.loading"
              (click)="selectAllFromManu()">
              <div class="filter-listing-item-btn__icon">
                <img [src]="'https://cdn.hubx.com' + outletsService.selectedOutlet?.logoUrl"
                  alt="{{ outletsService.selectedOutlet?.navName }}">
              </div>
              <span class="filter-listing-count">{{ outletsService.selectedOutlet?.count }}</span>
            </div>
          </div>
        </li>

        <!-- Subcats second level starts here -->
        <ng-container *ngIf="itemFilterService.isSecondLevelExpanded">
          <li *ngFor="let manufacturer of itemsService.currentManufacturerNav"
            [class.hide]="manufacturer.name==='All Manufacturers'
              || ['Todays Deals', 'Just Launched', 'Price Drop'].includes(manufacturer.navName) || manufacturer.count===0">
            <div class="filter-listing-item"
              [class._opened]="itemFilterService.selectedSecondLevel?.filterId === manufacturer.filterId && itemFilterService.isSecondLevelExpanded">
              <div class="filter-listing-item-btn _outlets-first-level _catalog-second-level"
                (click)="selectChildNav(manufacturer, 2)"
                [class._has-right-arrow]="itemFilterService.selectedSecondLevel?.navChilds?.length && itemFilterService.selectedSecondLevel?.filterId === manufacturer.filterId"
                [class._active]="itemFilterService.selectedSecondLevel?.filterId === manufacturer.filterId"
                [class._loading]="itemFilterService.selectedSecondLevel?.filterId === manufacturer.filterId && !itemFilterService.selectedThirdLevel && topService.loading">
                <span class="filter-sublisting-item__label">{{ manufacturer.navName }}</span>
                <span class="filter-listing-count">{{ manufacturer.count }}</span>
              </div>

              <!-- SubCat third level starts here -->
              <ul class="filter-sublisting" [@toggleList]="closeOpenThirdLevelOutletAnimation(manufacturer.filterId)"
                [class._opened]="closeOpenThirdLevelClass(manufacturer.filterId)">
                <li *ngFor="let manuChild of manufacturer.navChilds">
                  <div class="filter-sublisting-item _outlets" (click)="selectChildNav(manuChild, 3)" [class._active]="itemFilterService.selectedSecondLevel?.navChilds?.length &&
                    manufacturer.filterId === itemFilterService.selectedSecondLevel.filterId &&
                    manuChild.filterId === itemFilterService.selectedThirdLevel?.filterId" [class._loading]="itemFilterService.selectedSecondLevel?.navChilds?.length &&
                    manufacturer.filterId === itemFilterService.selectedSecondLevel.filterId &&
                    manuChild.filterId === itemFilterService.selectedThirdLevel?.filterId && topService.loading">
                    <span class="filter-sublisting-item__label">{{ manuChild.navName }}</span>
                    <span class="filter-sublisting-count">{{ manuChild.count }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ng-container>
      </ng-container>

      <ng-template #catalog>
        <!-- Navigation logo box -->
        <li>
          <div class="filter-listing-item" [class._opened]="!itemFilterService.selectedManufacturer">
            <div class="filter-listing-item-btn _has-right-arrow"
              (click)="(!topService.searchString) ? resetOriginalNav(itemFilterService.selectedZeroLevel) : resetSearch()"
              [class._active]="!itemFilterService.selectedManufacturer"
              [class._loading]="!itemFilterService.selectedManufacturer && topService.loading">
              <div class="filter-listing-item-btn__icon">
                <!-- categoty icons -->
                <ng-container [ngSwitch]="itemFilterService.selectedZeroLevel?.redableTitle">
                  <img *ngSwitchCase="'Just Launched'" src="../../../assets/images/icons/item-filter/28-just-l.svg"
                    width="28" height="28" alt="">
                  <!-- <img *ngSwitchCase="'Just Sold'" src="../../../assets/images/icons/item-filter/28-just-s.svg" width="28" height="28" alt=""> -->
                  <img *ngSwitchCase="'Price Drop'" src="../../../assets/images/icons/item-filter/28-price-drop.svg"
                    width="28" height="28" alt="">
                  <img *ngSwitchCase="environment.currentDeal.displayLabel"
                    src="../../../assets/images/icons/item-filter/doorbuster-28x28.svg" width="28" height="28" alt="">
                  <img *ngSwitchDefault src="../../../assets/images/icons/item-filter/28-categories.svg" width="28"
                    height="28" alt="" #defaulZeroLevel>
                </ng-container>
              </div>
              <span class="filter-listing-count"
                [class.hide]="!itemFilterService.selectedManufacturer && topService.loading">
                {{
                (topFilterActive?.navName == environment.currentDeal.displayLabel) ?
                topService.getTopFilterByName(environment.currentDeal.displayLabel).count :
                (topFilterActive?.navName == topFiltersEnum.priceDrop) ?
                topService.getTopFilterByName(topFiltersEnum.priceDrop).count :
                (topFilterActive?.navName == topFiltersEnum.justLaunched) ?
                topService.getTopFilterByName(topFiltersEnum.justLaunched).count :
                (itemFilterService.selectedZeroLevel &&
                !itemFilterService.userFilter) ?
                itemFilterService.selectedZeroLevel.count : (topService.searchString == '' &&
                !itemFilterService.userFilter) ? getCount() :
                getZeroLevelSearchCount() }}</span>
            </div>
          </div>
        </li>

        <li *ngIf="preloaderActive && topService.loading">
          <ul class="filter-preloader">
            <li *ngFor="let item of preloaderFilterList" [class.animation-on]="true">
              <div class="filter-listing-item">
                <div class="filter-listing-item-btn">
                </div>
              </div>
            </li>
          </ul>
        </li>



        <!-- Manufacturer logo box -->
        <li *ngFor="let manufacturer of itemsService.manufacturersData"
          [class.hide]="manufacturer.name==='All Manufacturers'">
          <div class="filter-listing-item"
            [class._opened]="manufacturer.selected || itemFilterService.selectedManufacturer?.id === manufacturer.id">
            <div class="filter-listing-item-btn" (click)="changeManufacturer(manufacturer)"
              [class._has-right-arrow]="itemFilterService.selectedManufacturer?.id === manufacturer.id"
              [class._active]="itemFilterService.selectedManufacturer?.id === manufacturer.id"
              [class._loading]="itemFilterService.selectedManufacturer?.id === manufacturer.id && !itemFilterService.selectedSecondLevel && topService.loading">
              <div class="filter-listing-item-btn__icon">
                <img *ngIf="manufacturer.logoUrl" [src]="'https://cdn.hubx.com' + manufacturer.logoUrl"
                  alt="{{manufacturer.name}}">
              </div>
              <span class="filter-listing-count">{{
                manufacturer.count }}</span>
            </div>
            <!-- </div>
        </li> -->

            <ul class="filter-sublisting" [@toggleList]="closeOpenSecondLevelAnimation(manufacturer.id)"
              [class._opened]="closeOpenSecondLevelClass(manufacturer.id)">
              <!-- Subcats second level starts here -->
              <li *ngFor="let manuChild of itemFilterService.secondLevelCats">
                <div class="filter-listing-item">
                  <div class="filter-listing-item-btn _outlets-first-level _catalog-second-level"
                    (click)="selectChildNav(manuChild, 2)"
                    [class._loading]="itemFilterService.selectedSecondLevel?.filterId === manuChild.filterId && topService.loading && !itemFilterService.selectedThirdLevel"
                    [class._active]="itemFilterService.selectedSecondLevel?.filterId === manuChild.filterId"
                    [class._has-right-arrow]="itemFilterService.selectedSecondLevel?.navChilds?.length &&
                      itemFilterService.selectedSecondLevel?.filterId === manuChild.filterId">
                    <span class="filter-sublisting-item__label"> {{ manuChild.navName }}</span>
                    <span class="filter-listing-count">{{ manuChild.count }}</span>
                  </div>

                  <!-- SubCat third level starts here -->
                  <ul class="filter-sublisting"
                    [class._opened]="closeOpenThirdLevelClass(manufacturer.id, manuChild.filterId)"
                    [@toggleList]="closeOpenThirdLevelAnimation(manufacturer.id, manuChild.filterId)">
                    <li *ngFor="let catChild of itemFilterService.selectedSecondLevel?.navChilds">
                      <div class="filter-sublisting-item _outlets" [class._active]="itemFilterService.selectedSecondLevel?.navChilds?.length &&
                    manuChild.filterId === itemFilterService.selectedSecondLevel.filterId &&
                    itemFilterService.selectedManufacturer?.id === manufacturer.id &&
                    catChild.filterId === itemFilterService.selectedThirdLevel?.filterId" [class._loading]="itemFilterService.selectedSecondLevel?.navChilds?.length &&
                    manuChild.filterId === itemFilterService.selectedSecondLevel.filterId &&
                    itemFilterService.selectedManufacturer?.id === manufacturer.id &&
                    catChild.filterId === itemFilterService.selectedThirdLevel?.filterId && topService.loading"
                        (click)="selectChildNav(catChild, 3)">
                        <span class="filter-sublisting-item__label"> {{ catChild.navName }}</span>
                        <span class="filter-sublisting-count">{{ catChild.count }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>

          </div>
        </li>
      </ng-template>
    </ul>
    <span class="filter-listing__nav" (click)="scrollTo('right')">
      <img src="../../../assets/images/icons/item-filter/arrow-right.svg" width="24" height="24" alt="">
    </span>
    <div class="filter-listing-droprdown-wrap" [ngStyle]="firstLevelListingDropdownStyles">
      <div class="filter-listing-droprdown">
        <ul>
          <li *ngFor="let manuChild of itemFilterService.secondLevelCats" (click)="selectChildNav(manuChild, 2)">
            <div class="filter-listing-droprdown-item _active">
              <span class="filter-listing-droprdown-item__txt"> {{ manuChild.navName }}</span>
              <span class="filter-listing-droprdown-item__count"> {{ manuChild.count }} </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="filter-listing-droprdown-sub-wrap" [ngStyle]="secondLevelListingDropdownStyles">
      <div class="filter-listing-droprdown-sub">
        <ul>
          <li *ngFor="let manuChild of itemFilterService.selectedSecondLevel?.navChilds"
            (click)="selectChildNav(manuChild, 3)">
            <div class="filter-listing-droprdown-item">
              <span class="filter-listing-droprdown-item__txt"> {{ manuChild.navName }}</span>
              <span class="filter-listing-droprdown-item__count"> {{ manuChild.count }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="filters-btm _show-by-model-visible" [ngClass]="{
      '_outlets-page': isOutletPage(),
      '_past-purchase-page': itemsService.isPastPurchase,
      '_just-sold-page': itemsService.isJustSoldUrl
    }">
    <div class="filters-btm__col">
      <!-- Manufacturers Dropdown -->
      <div class="filters-btm-item _manufacturer" *ngIf="!itemFilterService.isOutletsSinglePage && !itemFilterService.isOutletUserFilter &&
      !(userService.isGuest && itemsService.isPastPurchase)">
        <app-dropdown [items]="getManufacturersDataDropDown()" [fieldToShow]="'name'" [fieldToSave]="'name'"
          [isFromBuyerPortal]="true" [(ngModel)]="selectedManufName" [defaultTitle]="getDefaultTitleForManufacturer()"
          (onSelect)="changeManufacturer($event)" darkTheme="true">
        </app-dropdown>
      </div>

      <!-- Price Range -->
      <div class="filters-btm-item _price-range" *ngIf="!(itemsService.isJustSoldUrl || itemsService.isPastPurchase)">
        <button class="filter-price-range" [class.active]="priceRangeFilterVisible" type="button"
          (click)="priceRangeFilterVisible = !this.priceRangeFilterVisible">Price</button>
        <div class="price-range-popover" (clickOutside)="closePriceRangeFilter()" [excludeBeforeClick]="true"
          [exclude]="'.filter-price-range'">
          <div class="price-range-popover__title">Price Range</div>
          <div class="price-range-popover__inputs">
            <div class="price-range-popover-input">
              <div class="price-range-popover-input__label">min price</div>
              <div class="price-range-popover-input__input">
                <input [ngClass]="{'invalid': !isMinMaxValid}" (keyup)="maxPriceSuffix()" type="text" prefix="$ "
                  mask="00000" [(ngModel)]="minPrice" />
              </div>
            </div>
            <div class="price-range-popover__inputs-divider">-</div>
            <div class="price-range-popover-input">
              <div class="price-range-popover-input__label">max price</div>
              <div class="price-range-popover-input__input">
                <input [ngClass]="{'invalid': !isMinMaxValid}" (keyup)="maxPriceSuffix()" type="text" prefix="$ "
                  [suffix]="plusSignSuffix" mask="00000" [(ngModel)]="maxPrice" />
              </div>
            </div>
          </div>
          <div class="price-range-popover__slider">
            <ng5-slider [(value)]="sliderMinPrice" [(highValue)]="sliderMaxPrice" [options]="priceRangeOptions"
              (userChange)="adjustPriceInputs()" (userChangeEnd)="adjustPriceInputs()"></ng5-slider>
          </div>
          <div class="price-range-popover__buttons">
            <button (click)="clearPriceRange()" type="button" class="btn-clear">Clear</button>
            <button class="btn-apply" (click)="applyPriceRange()" [disabled]="!isMinMaxValid">Apply</button>
          </div>
        </div>
      </div>

      <!-- Conditions Dropdown -->
      <div class="filters-btm-item _conditions" *ngIf="!itemsService.isJustSoldUrl && !itemsService.isPastPurchase">
        <app-dropdown [items]="itemsService.conditionAttributeValues"
          [disabled]="itemsService.conditionAttributeValues.length <= 1" [fieldToShow]="'value'" [fieldToSave]="'value'"
          [isFromBuyerPortal]="true" [counterFieldForDropdownOption]="'count'" [(ngModel)]="selectedCondName"
          [defaultTitle]="getDefaultTitleForCondition()" (onSelect)="selectCondition($event)" darkTheme="true">
        </app-dropdown>
      </div>

      <!-- Sort Dropdown -->
      <div class="filters-btm-item _available" *ngIf="!itemsService.isJustSoldUrl">
        <button class="filter-asc-desc" type="button" [ngClass]="(isAsc) ? '_asc' : '_desc'"
          (click)="changeSortingDirection()" *ngIf="showSortButton">
          <i class="icon"></i>
        </button>
        <app-dropdown [items]="sortingOptions" [fieldToShow]="'text'" [fieldToSave]="'text'" [isFromBuyerPortal]="true"
          [(ngModel)]="selectedSortName" [defaultTitle]="getDefaultTitleForSort()" (onSelect)="selectSort($event)"
          darkTheme="true">
        </app-dropdown>

      </div>
      <form [formGroup]="modelFg" *ngIf="!this.itemsService.isJustSoldUrl" [style.display]="'flex'">
        <div class="filters-btm-item _by-model" *ngIf="!itemsService.isPastPurchase">
          <label class="filter-checkbox">
            <span class="filter-checkbox__label">Sort by Model</span>
            <input type="checkbox" formControlName="sortByModel"
              [checked]="itemsService.currentSort === sortTypesEnum.model">
            <i class="filter-checkbox__icon"></i>
          </label>
        </div>
        <!-- EXW Dropdown -->
        <div class="filters-btm-item _exw" *ngIf="!itemsService.isJustSoldUrl && !itemsService.isPastPurchase">
          <app-dropdown *ngIf="!itemsService.isJustSoldUrl && !itemsService.isPastPurchase"
            [items]="itemsService.exwPoints" [disabled]="itemsService.exwPoints.length <= 1" [fieldToShow]="'text'"
            [fieldToSave]="'text'" [isFromBuyerPortal]="true" [(ngModel)]="selectedExwName"
            [ngModelOptions]="{standalone: true}" [defaultTitle]="getDefaultTitleForExw()"
            (onSelect)="selectExw($event)" darkTheme="true">
          </app-dropdown>
        </div>

        <div class="filters-btm-item _instock" *ngIf="!itemsService.isJustSoldUrl && !itemsService.isPastPurchase">
          <label class="filter-toggle">
            <input type="checkbox" formControlName="inStock" [checked]="itemsService?.itemsRequest.inStock">
            <span class="filter-toggle__label _off">All</span>
            <span class="filter-toggle__icon"></span>
            <span class="filter-toggle__label _on">In Stock</span>
          </label>
        </div>
      </form>
    </div>
    <div class="filters-btm__col" *ngIf="!itemsService.isJustSoldUrl && !itemsService.isPastPurchase">
      <div class="filters-btm-item _excel">
        <button class="filter-button" (click)="exportToCSV()"
          [style.cursor]="isExportBtnDisabled() ? 'auto' : 'pointer'">
          <img *ngIf="!isExporting" src="../../../assets/images/icons/item-filter/excel.svg" width="24" height="24" />
          <i *ngIf="isExporting" class="loader"></i>
          <span>Export Results</span>
        </button>
      </div>
      <div class="filters-btm-item" *ngIf="!itemsService.isJustSoldUrl && !itemsService.isPastPurchase">
        <div class="filter-view">
          <span (click)="changeView()" class="filter-view-item" title="Listing View"
            [ngClass]="{'_active': !topService.gridView}">
            <img src="../../../assets/images/icons/item-filter/view-grid.svg" width="24" height="24" />
          </span>
          <span (click)="changeView()" class="filter-view-item" title="Grid View"
            [ngClass]="{'_active': topService.gridView}">
            <img src="../../../assets/images/icons/item-filter/view-listing.svg" width="24" height="24" />
          </span>
        </div>
      </div>
    </div>
  </div>
</div>