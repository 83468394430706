<header *ngIf="!userService.isInIframe && (!role || role === 'null' || !authService.completedLoad)" class="header _dark-header"
  [ngClass]="{
    '_corp-site-header': sharedService.isCorpSite
  }"
>
  <div class="wrapper-header" [class.nav-opened]="navToggle === true">
    <a (click)="clickLogoToReload()">
      <img src="../../assets/images/logo-white-title.svg" width="91" height="24" alt="HUBX" class="logo _white-title" />
    </a>
    <nav class="nav-wrap" [ngClass]="{'mobile-nav': navToggle}">
      <ul (click)="close()" class="nav">
        <li><a [ngClass]="{'activeLink': sharedService.corporateSiteSelectedLink === 'corphome'}" (click)="navigateTo('corphome')">Our Company</a></li>
        <li><a [ngClass]="{'activeLink': sharedService.corporateSiteSelectedLink === 'products'}" (click)="navigateTo('products')" >Products</a></li>
        <li><a [ngClass]="{'activeLink': sharedService.corporateSiteSelectedLink === 'sell'}" (click)="navigateTo('sell')" >Sell On HUBX</a></li>
        <li><a [ngClass]="{'activeLink': sharedService.corporateSiteSelectedLink === 'aboutUs'}"  (click)="navigateTo('aboutUs')">About Us</a></li>
        <li><a [ngClass]="{'activeLink': sharedService.corporateSiteSelectedLink === 'faq'}" (click)="navigateTo('faq')">FAQ</a></li>
        <li><a [ngClass]="{'activeLink': sharedService.corporateSiteSelectedLink === 'contact-support'}" (click)="navigateTo('contact-support')" (click)="navigateTo('contact-support')">Contact</a></li>
      </ul>
      <ul (click)="close()" class="nav buttons">
        <li *ngIf="!isOnBuyerLogin()"><a  [routerLink]="['']" (click)="navigateTo('guest/home')" class="buyer-button">Buyer Portal</a></li>
        <li *ngIf="!isOnVendorLogin() && !maintenanceModeOn"><a [routerLink]="['/vendor-login']" (click)="navigateTo('vendor-login')" class="vendor-button">Vendor Portal</a></li>
      </ul>
    </nav>
    <div class="toggle-mobile-menu" [class.nav-open]="navToggle === true" (click)="toggleNav()">
      <div class="top"></div>
      <div class="middle"></div>
      <div class="bottom"></div>
    </div>

  </div>

</header>
<app-guest-top-full *ngIf="!userService.isInIframe && !sharedService.isVendorLoginUrl &&
!sharedService.isVendorPortal() && (!role || role === 'null' || !authService.completedLoad)"></app-guest-top-full>

<app-top *ngIf="!sharedService.isVendorPortal() && !userService.isInIframe && authService.completedLoad && (role === 'BUYER' || role==='SALES' || role ==='SUPER') " ></app-top>
<app-top-vendor *ngIf="authService.completedLoad && (role === 'SELLER' || role === 'ADMIN' || role === 'VENDORMGR')" (impersonationListEmpty)="handleImpersonationListEmpty()"></app-top-vendor>
<app-guest-top *ngIf="!userService.isInIframe && authService.completedLoad && role === 'GUEST'"></app-guest-top>

<app-error-modal [bodyText]="sessionErrorModal.bodyText" [title]="sessionErrorModal.title" [isError]="!isAuthenticated() && sessionService._is_logged_in === 'true'" (onConfirmError)="handleExpiredSession()"></app-error-modal>
<app-error-modal
  [title]="'Missing Assigned Vendors'" [isError]="impersonationListEmptyShowPopup"
  [bodyText]="'We are preventing this account from logging in to the HUBX Vendor Portal because it has not been assigned any HUBX vendors.'" 
  (onConfirmError)="hideImpersonationErrorPopup()">
</app-error-modal>


<app-confirmation-popup *ngIf="topService.confirmationPopupSettings.show"
  [settings]="topService.confirmationPopupSettings">
</app-confirmation-popup>