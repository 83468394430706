import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';

import { ItemsService } from '../shared/services/items.service';
import { CartService } from '../buyer/cart/cart.service';
import { environment } from '../../environments/environment';
import { UserService } from '../user/user.service';
import { SessionService } from './session.service';
declare let gtag: Function;
declare global {
  interface Window { dataLayer: any[]; }
}

@Injectable()
export class GoogleAnalyticsService {

  constructor(private itemsService: ItemsService,
    private cartService: CartService,
    private userService: UserService,
    private angulartics2: Angulartics2,
    private sessionService: SessionService) {
  }

  sendOrderDataToGoogleAnalytics(isNew: boolean): void {
    const order_total = this.cartService.getTotalOrderPrice();
    const order_id: string = isNew ?
      this.cartService.order.docNumber + '' : this.cartService.order.docNumber + '_' + order_total + '_' + Date.now();
    const items = this.cartService.cartList.map((cartItem, index) => {
      const userNameEmail = sessionStorage.getItem('user_email');
      const isCartAbandonment = (this.sessionService.isCartAbandonment && this.sessionService.isCartAbandonment === 'true');

      if (isCartAbandonment) {
        window.dataLayer.push({
          'event': 'Checkout',
          'ecommerce': {
            'transaction_id': order_id,
            'value': order_total,
            'currencyCode': 'USD',
            'checkout': {
              'actionField': {'list': 'Cart Abandonment'},
              'products': [
                {
                  'name': cartItem.item.description,
                  'id': cartItem.itemCode,
                  'brand': cartItem.item.manufacturer,
                  'dimension1': userNameEmail,
                  'dimension2': this.userService.companyName,
                  'dimension3': userNameEmail + ' / ' + this.userService.companyName,
                  'dimension4': 'Cart Abandonment',
                  'price': cartItem.unitPrice,
                  'quantity':  cartItem.quantity - cartItem.reservedQty,
                  'position': (index + 1)
                },
              ]
            }
          }
        });
      }

      return {
        id: cartItem.itemCode,
        name: cartItem.item.description,
        brand: cartItem.item.manufacturer,
        position: (index + 1),
        price: cartItem.unitPrice,
        quantity: cartItem.quantity - cartItem.reservedQty,
        item_list_name: isCartAbandonment ? 'Cart Abandonment' : '',
        'dimension1': isCartAbandonment ? userNameEmail : '',
        'dimension2': isCartAbandonment ? this.userService.companyName : '',
        'dimension3': isCartAbandonment ? userNameEmail + ' / ' + this.userService.companyName : '',
        'dimension4': isCartAbandonment ? 'Cart Abandonment' : ''
      };


    });

    gtag('event', 'purchase', {
      transaction_id: order_id,
      value: order_total,
      currency: 'USD',
      items
    });

  }


}
