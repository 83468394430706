import { PubSubService } from './../../core/pubsub.service';
import { SharedSource } from './../../core/shared-source';
import { Subscription } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ResetPasswordService } from '../../shared/services/reset-password.service';
import { environment } from '../../../environments/environment';
import { GuestService } from '../../shared/services/guest.service';
import { TopService } from '../../shared/services/top.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  @Output() hideForgotPwd = new EventEmitter<boolean>();
  @Input() shownEmail = '';
  @Input() fromVendor = false;
  @Input() mobile: false;
  hideForgot: boolean;
  success: boolean;
  successMessage: string;
  errorMsg: string;
  loading = false;
  resetIsuccessful: boolean;
  resetFailed: boolean;
  error_on_email_input = false;
  error_on_email_not_found = false;
  public navToggle = false;
  forgotPassPreMessage = environment.forgotPasswordPreMessage.message;
  isMobile = false;
  subscription: Subscription;

  constructor(
    public topService: TopService,
    public user: ResetPasswordService,
    public guestService: GuestService,
    private titleService: Title,
    private pubSubService: PubSubService) {
  }

  ngOnInit() {
    this.titleService.setTitle('Forgot Password');
    this.errorMsg = 'An error ocurred. Please, contact the administrator.';

    this.subscription = this.pubSubService.sharedSubject.subscribe(myEvent => {
      if (myEvent.name === SharedSource.forgotPasswordRequestFailed) {
        this.resetIsuccessful = (myEvent.data) ? false : true;
        this.resetFailed = (myEvent.data) ? true : false;
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  validateEmail(email): void {
    this.clearMessages();
    const x = email;
    const atpos = x.indexOf('@');
    const dotpos = x.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= x.length) {
      this.error_on_email_input = true;
    } else {
      this.loading = true;
      this.guestService.getGuestAuthRealm(email).subscribe(
        (data) => {
          this.user.resetPassword(email, data['connection']);
        },(error) => {
          this.error_on_email_not_found = true;
          this.loading = false;
      });
    }
  }

  clearMessages(): void {
    this.resetIsuccessful = false;
    this.error_on_email_input = false;
    this.error_on_email_not_found = false;
  }

  toggleNav(): void {
    this.navToggle = !this.navToggle;
  }

  showLogin(): void {
    this.hideForgot = true;
    this.hideForgotPwd.emit(this.hideForgot);
  }
}
