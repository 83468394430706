<div class="nothing-found row">
	<div class="col">
		<div class="info-block">
			<img width="64" height="64" src="assets/images/vendor-icons/64-manage-order-grey.svg">
			<span>
				You have no purchase orders
			</span>
		</div>
	</div>
</div>

<div class="po">
	<div class="po-header">
		<div class="po-header-item __date">
			Date
		</div>
		<div class="po-header-item __po">
			Purchase Order
		</div>
		<div class="po-header-item">
			Order Amount
		</div>
		<div class="po-header-item">
			Status
		</div>
	</div>
	<div class="po-items" *ngFor="let item of items">
		<div class="po-item">
			<div class="po-item-part __date">
				<span></span>
			</div>
			<div class="po-item-part __po">
				<span></span>
			</div>
			<div class="po-item-part">
				<span></span>
			</div>
			<div class="po-item-part">
				<span></span>
			</div>
			<div class="po-item-part __button">
			</div>
		</div>
	</div>
</div>