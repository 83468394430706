import * as models from './model';

export enum MobileMsgOptionsEnum {
    notValid,
    sms,
    whatsApp
}

export interface UserProfile {
    firstName: string;
    lastName: string;
    email: string;
    roles: string[];
    businessPartners: string[];
    discountLimit: number;
    remindMeLater?: boolean;
    isMobileEnabled: boolean;
    isVerified: boolean;
    messageOptions: MobileMsgOptionsEnum;
    phoneNumber: string;
    verificationCode: string;
    countryCode: string;
    countryId: string;
}
