import { Injectable } from '@angular/core';
import { Dictionary } from '../../classes/dictionary';
import { eConnectionType } from './connection-type';
import { SignalRConnection } from './signalr-connection';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../shared.service';
import * as config from '../../../../../package.json';
import { HubConnectionState } from '@microsoft/signalr';


@Injectable({
  providedIn: 'root'
})
export class ConnectionManagerService {
  connections: Dictionary;
  baseUrl: string;
  versionFromPackage: string = config.version;

  constructor(private sharedService: SharedService) {
    this.connections = new Dictionary();
    this.baseUrl = environment.notificationHubUrl;
  }

  addConnection(connectionType: eConnectionType, receiver?: string, force = false) {
    if (!this.connections.has(eConnectionType[connectionType]) || force) {
      const url = this.buildUrl(connectionType, receiver);
      const signalName = this.getSignalName(connectionType);
      const broadCastFn = this.getBroadcastFnName(connectionType);
      const connection = new SignalRConnection(url, signalName, broadCastFn, this.sharedService);
      this.connections.set(eConnectionType[connectionType], connection);
      connection.start();
    }
  }

  getConnection(connectionType: eConnectionType): SignalRConnection {
    return this.connections.get(eConnectionType[connectionType]);
  }

  buildUrl(connectionType: eConnectionType, receiver?: string) {
    let url = '';
    if (connectionType === eConnectionType.Notification) {
      url = this.baseUrl + '/notifications?receiver=' + receiver;
    } else if (connectionType === eConnectionType.JustSold) {
      url = this.baseUrl + '/justSold';
    } else if (connectionType === eConnectionType.NewVersion) {
      url = this.baseUrl + '/release?versionNumber=' + this.versionFromPackage;
    } else if (connectionType === eConnectionType.Offer) {
      url = this.baseUrl + '/offers';
    }
    return url;
  }

  switchConnection(connectionType: eConnectionType, receiver: string) {
    const currentConnection: SignalRConnection = this.connections.get(eConnectionType[connectionType]);
    if (currentConnection) {
      currentConnection.hubConnection.off(currentConnection.signalName);
      currentConnection.stop();
      this.connections.remove(eConnectionType[connectionType]);
    }

    this.addConnection(connectionType, receiver, true);
  }

  verifyConnectionStatus() {
    this.connections.values().forEach(signalRConn => {
      console.log(signalRConn.hubUrl + ': ' + HubConnectionState[signalRConn.hubConnection.state]);
    });
  }

  stopConnection(connectionType: eConnectionType) {
    const currentConnection = this.connections.get(eConnectionType[connectionType]);
    if (currentConnection) {
      currentConnection.hubConnection.off(currentConnection.signalName);
      currentConnection.stop();
      this.connections.remove(eConnectionType[connectionType]);
    }
  }

  getBroadcastFnName(connectionType: eConnectionType) {
    if (connectionType === eConnectionType.JustSold) {
      return 'justSoldValues';
    } else if (connectionType === eConnectionType.Notification) {
      return 'handleNotificationSignals';
    } else if (connectionType === eConnectionType.NewVersion) {
      return 'updateVersion';
    } else if (connectionType === eConnectionType.Offer) {
      return 'handleOffer';
    }
  }

  getSignalName(connectionType: eConnectionType) {
    if (connectionType === eConnectionType.JustSold) {
      return 'ReceiveJustSold';
    } else if (connectionType === eConnectionType.Notification) {
      return 'receiveNotification';
    } else if (connectionType === eConnectionType.NewVersion) {
      return 'ReceiveRelease';
    } else if (connectionType === eConnectionType.Offer) {
      return 'handleOffer';
    }
  }
}


