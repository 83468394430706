import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ISplitOrderLine } from '../../../interfaces/ISplitorderLine';
import { IOrderListCombo } from '../../../interfaces/IOrderListCombo';
import { UtilityService } from '../../../../core/utility.service';

import * as models from '../../../interfaces/model';

@Component({
  selector: 'app-split-line',
  templateUrl: './split-line.component.html',
  styleUrls: ['./split-line.component.scss']
})
export class SplitLineComponent implements OnInit {
  @Output() QuantityChange = new EventEmitter();
  @Input() orders: models.IOrder[];
  @Input() line: ISplitOrderLine;
  @Input() orderList: IOrderListCombo[];
  @Input() mainSelect: string;
  @Input() targetOrder: number;
  prevQty: number;
  copied = 'Copy';

  constructor(private utilService: UtilityService) { }

  ngOnInit() {
  }

  copyText(val: string): void {
    this.utilService.copyTextToClipboard(val);
    this.copied = 'Copied!';
  }

  copyState(): void {
    this.copied = 'Copy';
  }

  selectLine(): void {
    this.line.lineSelected = !this.line.lineSelected;
    if (this.line.lineSelected) {
      if (this.mainSelect === '0') {
        this.line.movedDocNumber = 0;
      } else {
        this.line.movedDocNumber = this.targetOrder;
      }
      this.line.movedQuantity = this.line.quantity;
    } else {
      this.line.movedDocNumber = this.targetOrder;
      this.line.movedQuantity = 0;
    }
  }

  quantityChanged(): void {
    if (!this.line.movedQuantity || this.line.movedQuantity > this.line.quantity) {
      this.line.movedQuantity = this.prevQty;
      this.QuantityChange.emit();
    }
  }
}
