import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardVendorService implements CanActivate {

  constructor(public router: Router, public authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isAuthenticated() && sessionStorage.getItem('user_role') === 'SELLER'
    || sessionStorage.getItem('user_role') === 'ADMIN' || sessionStorage.getItem('user_role') === 'VENDORMGR') {
      return true;
    } else {

      sessionStorage.setItem('comapny_name', '');
      this.authService.redirectUrl = state.url;
      this.router.navigate(['/vendor-login'], {queryParams: {redirectTo: state.url}});
      return false;
    }
  }
}
