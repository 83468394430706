import { OfferService } from './../../user/offers/offer.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { Router, ActivatedRoute } from '@angular/router';
import { VendorService } from '../vendor.service';
import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {
  notificationCount = 0;
  userRole = sessionStorage.getItem('user_role');
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private pubSubService: PubSubService,
    private activatedRoute: ActivatedRoute,
    public vendorService: VendorService,
    public offerService:OfferService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.pubSubService.sharedSubject
    .subscribe( myEvent => {
      switch(myEvent.name) {
        case SharedSource.bpUnseenEventCountUpdated:
        case SharedSource.offerSummary:
          this.cdr.detectChanges();
          break;
        case SharedSource.vendorNotificationCount:
          this.notificationCount = myEvent.data;
          break;
      }
    });
  }

  goTo() {
    this.sharedService.currentComponent = this.activatedRoute.children[0].component['name'];

    // resets the selected po line when user navigates to anything but the PoFullDetail or the PoComponent.
    if (this.sharedService.currentComponent !== 'PoFullDetailsComponent' && this.sharedService.currentComponent !== 'PoComponent') {
      this.vendorService.selectedPoLine = {};
    }
  }
}
