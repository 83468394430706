<!-- <div class="empty-state" [class.mobile]="topService.isMobile">
  <img *ngIf="!isArchive" src="../../../../assets/images/notification-center/empty-state.svg" width="60" height="60" alt="">
  <img *ngIf="isArchive" src="../../../../assets/images/notification/archive-empty.svg" width="60" height="60" alt="">
  <p class="empty-state__txt">You Have No {{ text }} Yet!</p>
</div> -->
<div class="notification-center-guide" [class.mobile]="topService.isMobile">
  <div class="notification-center-guide-row">
    <div class="notification-center-guide-col">
      <img *ngIf="!topService.isMobile" src="../../../../assets/images/notification-center/notifications-empty-1_rebranding@2x.png" width="564" height="427" alt="">
      <img *ngIf="topService.isMobile" src="../../../../assets/images/notification-center/notifications-empty-1-mobile_rebranding@2x.png" width="328" height="306" alt="">
    </div>
    <div class="notification-center-guide-col">
      <div class="notification-center-guide-col__txt">
        <h2>How do you add <br/>items to notification? <br/>It’s easy.</h2>
        <p>Get notified per item for price drops, new inventory added or watch an item by clicking on “Notify Me” and
          when activity changes we will notify you.</p>
      </div>
    </div>
  </div>
  <div class="notification-center-guide-row">
    <div class="notification-center-guide-col">
      <div class="notification-center-guide-col__txt">
        <h2>Item SOLD OUT?</h2>
        <p>You can get on waitlist on items that are sold out by clicking on “waitlist” and adding to notifications. We
          will notify you when the item is back in stock!</p>
      </div>
    </div>
    <div class="notification-center-guide-col _mobile-order-first">
      <img *ngIf="!topService.isMobile" src="../../../../assets/images/notification-center/notifications-empty-2_rebranding@2x.png" width="427" height="427" alt="">
      <img *ngIf="topService.isMobile" src="../../../../assets/images/notification-center/notifications-empty-2-mobile_rebranding@2x.png" width="328" height="262" alt="">
    </div>
  </div>
</div>
