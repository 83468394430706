import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, UrlTree } from '@angular/router';

import { environment } from '../../environments/environment';
import { TopService } from '../shared/services/top.service';
import { UserService } from '../user/user.service';
import { OrdersService } from '../buyer/order-history/orders/orders.service';
import { ItemsService } from '../shared/services/items.service';
import { SharedService } from '../shared/shared.service';

import * as models from '../buyer/interfaces/model';
import { SessionService } from './session.service';
import { Subscription } from 'rxjs';
import { PubSubService } from '../core/pubsub.service';
import { SharedSource } from '../core/shared-source';
import { ItemFilterService } from '../shared/item-filter/item-filter.service';

@Injectable()
export class GlobalSearchService {
  private _searchLoading = 0;
  isEditingOrder = false;
  showErr = false;
  errorMessage: string;
  orderDocNumber: string;
  restApiVerb = 'orders/byDocNum/';
  userRole: string;
  subscription: Subscription;

  set searchLoading(val) {
    this._searchLoading = (val < 0) ? 0 : val;
  }

  get searchLoading() {
    return this._searchLoading;
  }

  constructor(private http: HttpClient,
    private topService: TopService,
    private guestTopService: TopService,
    private _router: Router,
    private userService: UserService,
    private ordersService: OrdersService,
    private itemsService: ItemsService,
    private itemFilterService: ItemFilterService,
    private sharedService: SharedService,
    public sessionService: SessionService,
    public pubSubService: PubSubService) {

    this.subscription = this.pubSubService.sharedSubject.subscribe(
      mySharedValues => {
        if (mySharedValues.name === SharedSource.IsSearch && mySharedValues.data) {
          this.searchByURL();
        }
      });
  }

  searchString(): void {
    // needed so we dont show previous value while it's updating
    this.sharedService.breadCrumbsTitle = '';
    this.itemsService.selectedUrlFilterId = undefined;
    this.itemFilterService.selectedSearch = this.topService.searchString;
    this.validateSearchContainsOrderId();
  }

  validateSearchContainsOrderId(): void {
    this.itemsService.selectedTopAttribute = 'SEARCHING';
    const searchString = this.topService.searchString.substr(0, 1);
    if ((this.userService.getRole() === 'SALES' || this.userService.getRole() === 'SUPER')
      && searchString === environment.globalSearchIndicator) {
      this.searchLoading++;
      this.topService.loading = true;
      this.orderDocNumber = this.topService.searchString.substr(1);
      const baseUrl = environment.buyerPortalBaseUrl + this.restApiVerb + this.orderDocNumber;

      this.http.get<models.IOrder>(baseUrl).subscribe(data => {
        this.sharedService.UorderSearch(data);
        this.getOrderAndRedirect(data);
        return false;
      },
        err => {
          this.errorMessage = !err.status ? err.statusText : err.error;
          this.showErr = true;
          this.topService.loading = false;
          return;
        });
    } else {
      this.topService.loading = true;

      const userUrl = this.sharedService.isBuyerPortal() ? 'buyer' : 'guest';
      const navigateUrl = this._router.url.match(/buyer|guest/) ? '' : userUrl;
      this.itemsService.paginationInfo = null;

      if (!this._router.url.match(/items/) ||
        (this.itemsService.itemsRequest.searchQuery &&
          this.itemsService.itemsRequest.searchQuery !== this.topService.searchString)) {
        // you are in an items url and search something using global search
        this._router.navigate([userUrl + '/search'], { queryParams: { val: this.topService.searchString } });
      } else {
        // for items url navigatebyurl so special characters are not lost like #ABA
        this._router.navigateByUrl(navigateUrl + this._router.url.trim());
      }
      this.itemsService.resetLeftNav();
      this.itemsService.getItems();
    }
  }

  getOrderAndRedirect(order): void {
    this.topService.loading = false;
    this.ordersService.orderChosen = order;
    this._router.navigate(['/buyer/full-order', order.docEntry]);
    this.searchLoading--;
  }

  searchByURL() {
    let url = this._router.url.replace('/buyer/', '');
    url = url.replace('/guest/', '');

    if (url.match(/^search|\/search/)) {

      const urlTree: UrlTree = this._router.parseUrl(url);
      const searchQuery = urlTree.queryParams;
      if (searchQuery.val) {
        this.topService.searchString = searchQuery.val.trim();
        this.searchString();
      }
    } else if (url.match(/items/)) {
      const searchItem = url.split('/');
      this.topService.searchString = decodeURIComponent(searchItem[searchItem.length - 1].trim());
      this.searchString();
    }
  }
}
