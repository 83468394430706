export enum PoSortCondition {
    Date,
    DocNumber,
    Amount,
    Status
}

export enum PpoSortCondition {
    Date = 'date',
    PartNumber = 'itemCode',
    UnitsSold = 'qty',
    Cost = 'cost',
    Revenue = 'totalRevenue'
  }
