<div *ngIf="userService.isLogginPopupOpen" class="modal-mobile-scrollable" [class.mobile]="topService.isMobile">
  <div class="modal">
    <div class="modal-wrap animated" [class.zoomOut]="zoomeOutModal" [class.zoomIn]="userService.isLogginPopupOpen">
      <div class="modal-content">
        <button type="button" class="close" aria-label="Close" (click)="closeAll()"></button>
        <div class="modal-info-wrapper">
          <div class="header-block">
            <h2>Hello {{ ' ' + getUserFullName() + '!'}} <br> You are now Logged in as a HUBX Guest.</h2>
          </div>
          <div class="text-block">
            <p>
              With limited Guest access, you cannot purchase products or place orders. You will need to contact
              Customer Sales Support to complete your registration as a HUBX Customer.<br />
              <br />
              Please let us know how you would like us to contact you to learn more about opening a HUBX Customer
              account.
            </p>
          </div>
        </div>
        <div class="modal-form-wrapper">
          <div class="form" *ngIf="userService.loginFirstPopup">
            <div class="header-block">
              <h2>
                Please contact us to learn more about becoming a customer.
              </h2>
            </div>
            <div class="form-block">
              <form #f="ngForm">

                <div class="form-group" [ngClass]="{'error':!name.valid && name.touched}">
                  <div class="input-group">
                    <input type="text" class="form-control" [(ngModel)]="userService.leadProfile.fullName" name="name"
                      required #name="ngModel" placeholder="Full Name" disabled>
                  </div>
                </div>

                <div class="form-group" [ngClass]="{'error':!email.valid && email.touched}">
                  <div class="input-group">
                    <input type="email" class="form-control" [(ngModel)]="userService.leadProfile.email" name="email"
                      required #email="ngModel" placeholder="Company Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                      disabled>
                  </div>
                </div>

                <div class="form-group">
                  <div class="input-group">
                    <input type="tel" class="form-control" [(ngModel)]="userService.leadProfile.phoneNumber" name="phone"
                      required #phone="ngModel" placeholder="Phone number">
                  </div>
                </div>

                <div class="form-group">
                  <!-- <p>I would like you to (please select):</p> -->
                  <p>I want to Buy! Please contact me:</p>

                  <div class="input-group">

                    <div class="form-check form-check-inline">
                      <input type="checkbox" name="mail-me" id="mail-me" [(ngModel)]="userService.leadProfile.isEmail">
                      <label for="mail-me"></label>
                      <label class="label-text" for="mail-me">
                        Email Me
                      </label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input type="checkbox" name="call-me" id="call-me" [(ngModel)]="userService.leadProfile.isCall">
                      <label for="call-me"></label>
                      <label class="label-text" for="call-me">
                        Call Me
                      </label>
                    </div>
                  </div>

                </div>

                <button class="btn btn-primary" type="submit" [disabled]="!f.valid || (!userService.leadProfile.isCall && !userService.leadProfile.isEmail)"
                  (click)="onSubmit(f,$event)">
                  <span *ngIf="!loading">Send</span>
                  <div *ngIf="loading" class="login-loader"></div>

                </button>
              </form>
            </div>
            <div class="bottom-block">
              <!-- <p>OR CONTACT US TODAY <a href="tel:786-600-6505">786-600-6505</a></p> -->
              <!-- <p>Sales Support Hours M-F 7AM-6PM EST</p> -->

              <div class="note">In the meantime please fill out the new customer or vendor application here:</div>
              <div class="clearfix"></div>
              <div>
                <button class="hubx-red-btn btn" (click)="navigateTo('customer')">Customer Application</button>
                <button class="hubx-purple-btn btn" (click)="navigateTo('vendor')">Vendor Application</button>
              </div>
            </div>
          </div>

          <div class="message-wrapper animated" [ngClass]="{'flipInY': userService.loginSecondPopup}" *ngIf="userService.loginSecondPopup">
            <div class="message">
              <h2>Your message has been received!</h2>
              <p>We will be in touch with you shortly.</p>
              <button class="btn btn-primary" (click)="closeAll()">Got it!</button>
            </div>
            <div class="bottom-block">
              <!-- <p>OR CONTACT US TODAY <a href="tel:786-600-6505">786-600-6505</a></p> -->
              <!-- <p>Sales Support Hours M-F 7AM-6PM EST</p> -->
              <div *ngIf="!userService.loginSecondPopup" class="note">In the meantime please fill out the new customer or vendor application here:</div>
              <div *ngIf="userService.loginSecondPopup" class="note">In the meantime to expedite this process, please fill out the new customer or new vendor application here:</div>
              <div class="clearfix"></div>
              <div>
                <button class="hubx-red-btn btn" (click)="navigateTo('customer')">Customer Application</button>
                <button class="hubx-purple-btn btn" (click)="navigateTo('vendor')">Vendor Application</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
