import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
// import { Select2Module } from 'ng2-select2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VendorRoutingModule } from './vendor-routing.module';
import { VendorHomeComponent } from './vendor-home/vendor-home.component';
import { AuthGuardVendorService } from '../auth/auth-guard-vendor-service';
import { ItemsComponent } from './products/items/items.component';
import { ItemDetailedComponent } from './products/item-detailed/item-detailed.component';
import { ItemSimpleComponent } from './products/item-simple/item-simple.component';
import { VendorService } from './vendor.service';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { PoComponent } from './orders/po/po.component';
import { PoLineComponent } from './orders/po/po-line/po-line.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ItemAnalyticsComponent } from './products/item-analytics/item-analytics.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PoFullDetailsComponent } from './orders/po-full-details/po-full-details.component';
import { ClickOutsideModule } from 'ng4-click-outside';
import { MainDashboardComponent } from './dashboard/main-dashboard/main-dashboard.component';
import { ChartComponent } from './dashboard/chart/chart.component';
import { SalesOnHubxComponent } from './dashboard/sales-on-hubx/sales-on-hubx.component';
import { SalesActivityComponent } from './dashboard/sales-activity/sales-activity.component';
import { TopSalesComponent } from './dashboard/top-sales/top-sales.component';
import { ListingDetailsComponent } from './dashboard/listing-details/listing-details.component';
import { ChartModule } from 'angular2-highcharts';
import { DashboardService } from './dashboard/dashboard.service';
import { PendingSoldComponent } from './orders/pending-sold/pending-sold.component';
import { PendingSoldItemsComponent } from './orders/pending-sold/pending-sold-items/pending-sold-items.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NothingFoundComponent } from './nothing-found/nothing-found.component';
import { EditItemDetailedComponent } from './edit-item-detailed/edit-item-detailed.component';
import { StatusPipe } from './shared/custom-pipes/vendor-status.pipe';
import { SharedModule } from '../shared/shared.module';
import { NotificationCenterVendorComponent } from './notifications/notification-center-vendor/notification-center-vendor.component';
import { NotificationHeaderComponent } from './notifications/notification-center-vendor/notification-header/notification-header.component';
import { NotificationComponent } from './notifications/notification-center-vendor/notification/notification.component';
import { NotificationHelperComponent } from './notifications/notification-center-vendor/notification-helper/notification-helper.component';
import { NotificationPopupComponent } from './notifications/notification-center-vendor/notification-popup/notification-popup.component';
import { NotificationCenterVendorService } from './notifications/notification-center-vendor/notification-center-vendor.service';
import { ItemCommentComponent } from './products/item-comment/item-comment.component';
import { ItemService } from './products/item.service';
import { ItemRestrictionComponent } from './products/item-restriction/item-restriction.component';
import { ItemDashboardComponent } from './products/item-dashboard/item-dashboard.component';
import { PoEmptyComponent } from './orders/po/po-empty/po-empty.component';
import { PendingSoldEmptyComponent } from './orders/pending-sold/pending-sold-empty/pending-sold-empty.component';
import { MiniProgressChartComponent } from './shared/ui-components/mini-progress-chart/mini-progress-chart.component';
import { ItemsEmptyComponent } from './products/items-empty/items-empty.component';
import { ItemSuccessComponent } from './products/item-success/item-success.component';
import { ItemUpdatingModalComponent } from './products/item-updating-modal/item-updating-modal.component';

@NgModule({
  imports: [
    CommonModule,
    VendorRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    ChartModule.forRoot(require('highcharts')),
    ChartModule.forRoot(require('highcharts/highstock')),
    PaginationModule.forRoot(),
    SharedModule,
    ClickOutsideModule,
    ScrollToModule.forRoot(),
    // Select2Module
  ],
  declarations: [
    VendorHomeComponent,
    ItemsComponent,
    ItemDetailedComponent,
    LeftMenuComponent,
    ItemAnalyticsComponent,
    PoComponent,
    PoLineComponent,
    PoFullDetailsComponent,
    ItemSimpleComponent,
    LeftMenuComponent,
    PoComponent, PoLineComponent, PoFullDetailsComponent,
    MainDashboardComponent,
    ChartComponent,
    SalesOnHubxComponent,
    SalesActivityComponent,
    TopSalesComponent,
    ListingDetailsComponent,
    PendingSoldComponent,
    PendingSoldItemsComponent,
    NothingFoundComponent,
    EditItemDetailedComponent,
    StatusPipe,
    NotificationCenterVendorComponent,
    NotificationHeaderComponent,
    NotificationComponent,
    NotificationHelperComponent,
    NotificationPopupComponent,
    ItemCommentComponent,
    ItemRestrictionComponent,
    ItemDashboardComponent,
    PoEmptyComponent,
    PendingSoldEmptyComponent,
    MiniProgressChartComponent,
    ItemsEmptyComponent,
    ItemSuccessComponent,
    ItemUpdatingModalComponent
  ],
  providers: [
    AuthGuardVendorService,
    FormsModule,
    VendorService,
    DashboardService,
    NotificationCenterVendorService,
    ItemService,
    ItemService,
    DatePipe
  ]

})
export class VendorModule { }
