<div class="restriction-notice-modal" [class.mobile]="topService.isMobile">
  <div class="restriction-notice-modal-wrap animated" [class.zoomIn]="true" [class.zoomOut]="animateOut">
  	<div class="restriction-notice-modal-icon">
  		<img src="../../../assets/images/icons/icon-restrictions-40px.svg" alt="Restriction Icon">
  	</div>
    <div class="restriction-notice-modal-header">
      Item Restrictions
    </div>
    <div class="restriction-notice-modal-message">
      Some items have territory restrictions, you're not authorized to purchase these items.
    </div>

    <div class="restriction-notice-modal-buttons">
      <button type="button" class="restriction-notice-modal-button" (click)="closeModal()">Got It</button>
    </div>
  </div>
</div>
