import { Component, OnInit } from '@angular/core';
import { TopService } from '../../services/top.service';

@Component({
  selector: 'app-offers-banner',
  templateUrl: './offers-banner.component.html',
  styleUrls: ['./offers-banner.component.scss']
})
export class OffersBannerComponent implements OnInit {
  isFaqIsOpen = false;

  constructor(
    public topService: TopService
    ) { }

  ngOnInit(): void {
  }

}
