<div class="my-bids-navigation">
  <div class="my-bids-navigation__header">
    <h3>My Bids</h3>
  </div>
  <div class="user-navigation__content">
    <ul class="my-bids-navigation-list">
      <li>
        <a class="my-bids-navigation-list-link" 
        routerLink="/auctions/my_bids" 
        routerLinkActive="_active"
        [routerLinkActiveOptions]="{exact: true}">
          <span>All</span>
          <span class="_counter">{{ auctionsService.myBidsTotals?.AllTotal }}</span>
        </a>
      </li>
      <li>
        <a 
        class="my-bids-navigation-list-link" 
        [routerLink]="['winning']" 
        routerLinkActive="_active">
          <span>Winning</span>
          <span class="_counter">{{ auctionsService.myBidsTotals?.WinningTotal }}</span>
        </a>
      </li>
      <li>
        <a class="my-bids-navigation-list-link" 
        [routerLink]="['not_winning']" 
        routerLinkActive="_active">
          <span>Not Winning</span>
          <span class="_counter">{{ auctionsService.myBidsTotals?.NotWinningTotal  }}</span>
        </a>
      </li>
      <li>
        <a class="my-bids-navigation-list-link" 
        [routerLink]="['won']"
        routerLinkActive="_active">
          <span>Won</span>
          <span class="_counter">{{ auctionsService.myBidsTotals?.WonTotal }}</span>
        </a>
      </li>
      <li>
        <a class="my-bids-navigation-list-link" 
        [routerLink]="['watching']"
        routerLinkActive="_active">
          <span>Watching</span>
          <span class="_counter">{{ auctionsService.myBidsTotals?.WatchingTotal }}</span>
        </a>
      </li>
    </ul>
  </div>
</div>
