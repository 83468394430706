<div class="header">
  <div class="header-logo">
    <a class="header-logo__link" (click)="clickLogoToReload()">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" width="91px" height="28px" viewBox="0 0 124 32" enable-background="new 0 0 121 32"
          xml:space="preserve">
          <path class="rotate-this" fill="#F15B5D"
            d="M119.143,22.692c-1.205-1.204-2.835-1.696-4.406-1.522c0.126-0.014,0.25-0.024,0.376-0.029
                c-1.127-0.118-2.225-0.626-3.089-1.49c-0.877-0.877-1.391-1.988-1.497-3.136c-0.005,0.16-0.018,0.319-0.037,0.479
                c0.194-1.591-0.296-3.249-1.517-4.47c-0.915-0.915-2.085-1.432-3.283-1.546c-0.005-0.004-0.009-0.008-0.014-0.004
                c-0.25,0.013-0.491,0.009-0.732-0.021c-1.127-0.119-2.225-0.606-3.089-1.47c-0.878-0.877-1.369-1.987-1.475-3.136
                c-0.005-0.013-0.005-0.021,0-0.034c-0.021-0.191-0.03-0.395-0.021-0.598c-0.098-1.225-0.618-2.424-1.555-3.36
                c-2.106-2.106-5.521-2.105-7.627,0c-2.106,2.106-2.106,5.521,0,7.627c0.911,0.911,2.076,1.432,3.263,1.55
                c0.013-0.004,0.025,0,0.033,0c0.25,0.03,0.5,0.034,0.75,0.021h0.025c1.114,0.123,2.208,0.614,3.063,1.47
                c0.848,0.848,1.335,1.919,1.462,3.03c-0.013,0.284-0.008,0.568,0.03,0.843c-0.009,0.008-0.005,0.013-0.005,0.021
                c0.127,1.178,0.645,2.331,1.547,3.233c1.225,1.225,2.891,1.715,4.485,1.515c-0.139,0.018-0.269,0.029-0.407,0.036
                c1.114,0.123,2.201,0.636,3.058,1.491c0.847,0.848,1.355,1.92,1.482,3.029c0.008-0.139,0.021-0.275,0.039-0.414
                c-0.209,1.603,0.28,3.28,1.513,4.512c2.105,2.106,5.521,2.106,7.627,0C121.248,28.213,121.248,24.799,119.143,22.692" />
          <path class="rotate-this" fill="#F15B5D" d="M91.177,22.692c2.105-2.106,5.521-2.106,7.627,0c2.105,2.106,2.105,5.521,0,7.627
                c-2.106,2.106-5.521,2.106-7.627,0C89.07,28.213,89.07,24.799,91.177,22.692" />
          <path class="rotate-this" fill="#F15B5D" d="M111.516,2.354c2.105-2.106,5.521-2.106,7.627,0c2.105,2.106,2.105,5.521,0,7.627
                c-2.106,2.106-5.521,2.106-7.627,0C109.409,7.875,109.409,4.46,111.516,2.354" />
          <path fill="#fff" d="M18.63,7.601H7.266v-7.702H0.279v32h6.987V14.589h10.647v17.311H24.9V13.872
                C24.9,10.472,22.029,7.601,18.63,7.601" />
          <path fill="#fff" d="M47.687,7.601v17.311H37.038V7.601h-6.987v18.027c0,3.458,2.813,6.271,6.271,6.271h12.08
                c3.458,0,6.271-2.813,6.271-6.271V7.601H47.687z" />
          <path fill="#fff" d="M78.175,7.601H66.811v-7.702h-6.987v32h18.351c3.458,0,6.271-2.813,6.271-6.271V13.872
                C84.446,10.414,81.633,7.601,78.175,7.601 M66.811,14.589H77.46v10.323H66.811V14.589z" />
        </svg>
      </a>
  </div>

  <div class="header-menu">
    <ul class="header-menu-list">
      <li *ngIf="customers.length > 0 && (vendorService.isAdmin || vendorService.isVendorManager)"
        class="vendor-select">
        <app-dropdown [items]="customers" [fieldToShow]="'companyName'" [fieldToSave]="'bpCode'" [enableFilter]="true"
          [(ngModel)]="selectedBpCode" (onSelect)="chooseVendor($event, true)">
        </app-dropdown>
      </li>
      <li class="notif-bell">
        <a [routerLink]="['/vendor/notifications']" routerLinkActive="active">
          <i class="_icon">
            <img src="assets/images/icons/rebranding/header-bell.svg" width="24" height="24" alt="Notification">
          </i>
          <span class="_badge-notification" *ngIf="notificationCount > 0">{{notificationCount}}</span>
        </a>
      </li>
      <li>
        <a (click)="offerService.showOffersDisabledMessage()">
          <i class="_icon">
            <img src="assets/images/icons/rebranding/offers-header.svg" width="24" height="24" alt="Offers">
          </i>
          <span class="_badge-offer" *ngIf="offerService.offersSummary?.unseenEventCount > 0">
            {{offerService.offersSummary?.unseenEventCount}}
          </span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/vendor/user/profile']" class="_profile">
          <span>{{companyName}}</span>
        </a>
      </li>
    </ul>
  </div>

</div>

<app-confirmation-popup *ngIf="offerService.confirmationPopupSettings.show"
  [settings]="offerService.confirmationPopupSettings">
</app-confirmation-popup>