import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SessionService } from '../../../services/session.service';
import { AuctionSignalRStatusEnum } from '../../enums/AuctionSignalRStatusEnum';
declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class AuctionSignalrService {
  signalR: any;

  constructor(private sessionService: SessionService) { }

  initialize() {
    if (['ADMIN', 'SELLER', 'VENDORMGR'].includes(this.sessionService.userRole)) {
      if (![null, AuctionSignalRStatusEnum.disconnected].includes(this.getConnectionStatus())) {
        this.stopConnection();
      }
    } else {
      this.connectToAuctionsSignalR();
    }
  }

  connectToAuctionsSignalR() {
    if (typeof ($.signalR) !== 'function') {
      throw new Error('SignalR: SignalR is not loaded. Please ensure jquery.signalR-x.js is referenced before ~/signalr/js.');
    }
    this.signalR = $.signalR;

    function makeProxyCallback(hub, callback) {
      return function () {
        // Call the client hub method
        callback.apply(hub, $.makeArray(arguments));
      };
    }

    function registerHubProxies(instance, shouldSubscribe) {
      let key, hub, memberKey, memberValue, subscriptionMethod;

      for (key in instance) {
        if (instance.hasOwnProperty(key)) {
          hub = instance[key];

          if (!(hub.hubName)) {
            // Not a client hub
            continue;
          }

          if (shouldSubscribe) {
            // We want to subscribe to the hub events
            subscriptionMethod = hub.on;
          } else {
            // We want to unsubscribe from the hub events
            subscriptionMethod = hub.off;
          }

          // Loop through all members on the hub and find client hub functions to subscribe/unsubscribe
          for (memberKey in hub.client) {
            if (hub.client.hasOwnProperty(memberKey)) {
              memberValue = hub.client[memberKey];

              if (!$.isFunction(memberValue)) {
                // Not a client hub function
                continue;
              }

              // Use the actual user-provided callback as the "identity" value for the registration.
              subscriptionMethod.call(hub, memberKey, makeProxyCallback(hub, memberValue), memberValue);
            }
          }
        }
      }
    }

    $.hubConnection.prototype.createHubProxies = function () {
      // console.log('CREATING PROXIS ....');
      const proxies = {};
      this.starting(function () {
        // console.log('STARTING PROXIS ....');
        // Register the hub proxies as subscribed
        // (instance, shouldSubscribe)
        registerHubProxies(proxies, true);
        this._registerSubscribedHubs();
      }).disconnected(function () {
        // console.log('DISCONNECTING PROXIS ....');
        // Unsubscribe all hub proxies when we "disconnect".  This is to ensure that we do not re-add functional call backs.
        // (instance, shouldSubscribe)
        registerHubProxies(proxies, false);
      });

      proxies['listingHub'] = this.createHubProxy('listingHub');
      proxies['listingHub'].client = {};
      proxies['listingHub'].server = {
        registerEventInterest: function (eventID) {
          return proxies['listingHub'].invoke.apply(proxies['listingHub'], $.merge(['RegisterEventInterest'], $.makeArray(arguments)));
        },

        registerListingInterest: function (listingID) {
          return proxies['listingHub'].invoke.apply(proxies['listingHub'], $.merge(['RegisterListingInterest'], $.makeArray(arguments)));
        },

        registerMultipleInterest: function (ids, username) {
          return proxies['listingHub'].invoke.apply(proxies['listingHub'], $.merge(['RegisterMultipleInterest'], $.makeArray(arguments)));
        },

        registerUserName: function (userName) {
          return proxies['listingHub'].invoke.apply(proxies['listingHub'], $.merge(['RegisterUserName'], $.makeArray(arguments)));
        }
      };

      return proxies;
    };
    this.signalR.hub = $.hubConnection(environment.auctionSignalRUrl, { useDefaultPath: false });
    $.extend(this.signalR, this.signalR.hub.createHubProxies());

    // this will call initialize() method on AWE-SignalR.js
    // this needs to be done only after proxies are created
    $.event.trigger({
      type: 'auctionSignalRInitialize'
    });
  }

  getConnectionStatus(): AuctionSignalRStatusEnum | null {
    if (this.signalR) {
      return this.signalR.connectionState;
    }
    return null;
  }

  stopConnection() {
    if (this.signalR) {
      $.event.trigger({
        type: 'auctionSignalRStop'
      });
      this.signalR.hub.stop();
    }
  }

  startConnection() {
    if (!this.signalR) {
      this.connectToAuctionsSignalR();
    } else {
      $.event.trigger({
        type: 'auctionSignalRStart'
      });
    }
  }
}


