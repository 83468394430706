<div class="scrollable-wrap" [class.mobile]="topService.isMobile">
    <div class="main-wrap">
    <div class="about-splash">
    
    <div class="about-overview">
         <div class="wrapper text-page" style="padding-bottom: 0;">
            <div class="mission__title">
                Mission statement
            </div>
            <div class="mission__text">
                "Our mission is to make it easy to conduct B2B commerce anywhere. We provide the fundamental technology infrastructure to give manufacturers, brands, vendors, buyers and customers the ability to leverage the power of our global ecosystem."
            </div>
        </div>
<!--         <div class="wrapper text-page" style="padding-bottom: 0;">
            <div class="mission__title">
                Company Overview
            </div>
            <div class="mission__text">
                HUBX was founded by serial entrepreneur Derek Wall while attending HBX Harvard Business School in an effort to democratize and disrupt traditional distribution. The Company launched on May 01, 2018 and has quickly amassed tens of millions a month in revenues with customers in 100+ countries. The Company's main objective is to empower both vendors and B2B customers worldwide.
            </div>
        </div> -->
    </div>

    <div class="about-mission">
<!--         <div class="wrapper text-page" style="padding-bottom: 0;">
            <div class="mission__title">
                Mission Statement
            </div>
            <div class="mission__text">
                "Our mission is to make it easy to conduct B2B commerce anywhere. We provide the fundamental technology infrastructure to give manufacturers, brands, vendors, buyers and customers the ability to leverage the power of our global ecosystem."
            </div>
        </div> -->
    </div>
    </div>

    <section class="text-page-section about-page">
    <div class="wrapper text-page">
        <h1 class="about__header">Core Values</h1>
        <div class="about__subheader">We are entrepreneurial, transparent, positive and humble. We believe that strong core values are the foundation for creating an environment which benefits and enriches our vendors, our customers and our team.</div>

        <div class="about__features">
            <div class="about__feature">
                <div class="about__feature--inner">
                    <div class="about__feature--icon">
                        <img src="../../../assets/corp-site/img/about/be-obsessed-with-passion.png" srcset="../../../assets/corp-site/img/about/be-obsessed-with-passion@2x.png 2x" width="370" height="182" alt="Be obsessed">
                    </div>
                    <div class="about__feature--title">
                        Be Obsessed<br>with Passion
                    </div>
                    <div class="about__feature--text">
                        Passion is the internal motivator, the energy within that drives us to evolve to provide services our customers don’t even know they need yet.  It’s our obsession with passion that keeps us up late learning the newest technology and it’s passion and only passion that will hold our standards high.
                    </div>
                </div>
            </div>
            <div class="about__feature">
                <div class="about__feature--inner">
                    <div class="about__feature--icon">
                        <img src="../../../assets/corp-site/img/about/deliver-awesomeness.png" srcset="../../../assets/corp-site/img/about/deliver-awesomeness@2x.png 2x" width="370" height="182" alt="About HUBX">
                    </div>
                    <div class="about__feature--title">
                        Deliver Awesomeness Through Service
                    </div>
                    <div class="about__feature--text">
                        When we commit to serving others at all levels, we commit to delivering on our promises. Exceptional service is the reason our customers love doing business with us.  It’s also the reason our employees enjoy coming to work everyday and are motivated and committed to doing their very best.
                    </div>
                </div>
            </div>
            <div class="about__feature">
                <div class="about__feature--inner">
                    <div class="about__feature--icon">
                        <img src="../../../assets/corp-site/img/about/open-honest-communication.png" srcset="../../../assets/corp-site/img/about/open-honest-communication@2x.png 2x" width="370" height="182" alt="About HUBX">
                    </div>
                    <div class="about__feature--title">
                        Open, Honest Communication, NO Bullsh*t
                    </div>
                    <div class="about__feature--text">
                        We encourage everyone in our company to commit to candid conversation. The key to the success of any Team is mutual support, respect and tolerance of any differences. In order to build an environment of trust, it is essential that all of us openly communicate our desires and expectations, and try to work together in a courteous manner to resolve our differences.
                    </div>
                </div>
            </div>
            <div class="about__feature">
                <div class="about__feature--inner">
                    <div class="about__feature--icon">
                        <img src="../../../assets/corp-site/img/about/never-be-satisfied.png" srcset="../../../assets/corp-site/img/about/never-be-satisfied@2x.png 2x" width="370" height="182" alt="About HUBX">
                    </div>
                    <div class="about__feature--title">
                        Never be Satisfied with the “Status Quo”
                    </div>
                    <div class="about__feature--text">
                        Never be Satisfied with the “Status Quo”. We will never succeed by accepting mediocrity.  Exceeding expectations and going beyond the status quo is a proactive effort that is always looking for a way to express itself in our organization.
                    </div>
                </div>
            </div>
            <div class="about__feature">
                <div class="about__feature--inner">
                    <div class="about__feature--icon">
                        <img src="../../../assets/corp-site/img/about/build-a-positive-team.png" srcset="../../../assets/corp-site/img/about/build-a-positive-team@2x.png 2x" width="370" height="182" alt="About HUBX">
                    </div>
                    <div class="about__feature--title">
                        Build a Positive Team and Family Environment
                    </div>
                    <div class="about__feature--text">
                        We take a family approach that emphasizes trust and values and our culture is based on recognition of our core values.  We know exactly what behaviors and achievements are required for success and how  these behaviors will be praised and recognized once demonstrated.
                    </div>
                </div>
            </div>
            <div class="about__feature">
                <div class="about__feature--inner">
                    <div class="about__feature--icon">
                        <img src="../../../assets/corp-site/img/about/take-ownership.png" srcset="../../../assets/corp-site/img/about/take-ownership@2x.png 2x" width="370" height="182" alt="About HUBX">
                    </div>
                    <div class="about__feature--title">
                        Take Ownership & Accountability
                    </div>
                    <div class="about__feature--text">
                        We empower employees to take ownership and accountability which leads to high levels of trust between all levels of our organization.  Our team members stay well informed on the status their commitments and take great pride in results of their work.
                    </div>
                </div>
            </div>
            <div class="about__feature">
                <div class="about__feature--inner">
                    <div class="about__feature--icon">
                        <img src="../../../assets/corp-site/img/about/have-humility.png" srcset="../../../assets/corp-site/img/about/have-humility@2x.png 2x" width="370" height="182" alt="About HUBX">
                    </div>
                    <div class="about__feature--title">
                        Have Humility and a Hunger to Learn
                    </div>
                    <div class="about__feature--text">
                        Humility gives permission to acknowledge and learn from mistakes and successes in equal measure. Being open to receiving feedback, trying new ideas and valuing curiosity to learn from anyone, anywhere creates a successful environment personal change and continuous improvement.
                    </div>
                </div>
            </div>
            <div class="about__feature">
                <div class="about__feature--inner">
                    <div class="about__feature--icon">
                        <img src="../../../assets/corp-site/img/about/be-creative.png" srcset="../../../assets/corp-site/img/about/be-creative@2x.png 2x" width="370" height="182" alt="About HUBX">
                    </div>
                    <div class="about__feature--title">
                        Be Creative<br>and Innovative
                    </div>
                    <div class="about__feature--text">
                        Our best ideas have not yet been thought of.  Excellence through innovation is a journey on a never-ending road, where progression and originality are the foundation of the pacesetters that are willing to lead.
                    </div>
                </div>
            </div>
            <div class="about__feature">
                <div class="about__feature--inner">
                    <div class="about__feature--icon">
                        <img src="../../../assets/corp-site/img/about/integrity.png" srcset="../../../assets/corp-site/img/about/integrity@2x.png 2x" width="370" height="182" alt="About HUBX">
                    </div>
                    <div class="about__feature--title">
                        Integrity - Do<br>The Right Thing
                    </div>
                    <div class="about__feature--text">
                        Our reputation is the hallmark of our success. Honesty and trust are the foundation of our sound moral and ethical principles.  We pride ourselves on the strong fundamental sense to do the right thing in all circumstances.
                    </div>
                </div>
            </div>
            <div class="about__feature">
                <div class="about__feature--inner">
                    <div class="about__feature--icon">
                        <img src="../../../assets/corp-site/img/about/speed.png" srcset="../../../assets/corp-site/img/about/speed@2x.png 2x" width="370" height="182" alt="About HUBX">
                    </div>
                    <div class="about__feature--title" style="min-height: inherit;">
                        Speed. Speed. Speed.
                    </div>
                    <div class="about__feature--text">
                        Speed is the most critical component of our business.  Respond first, act fast, stay relevant, be agile and support one other. After all, its all about SPEED if you want to WIN.
                    </div>
                </div>
            </div>
        </div>

    </div>
    </section>

    <section class="text-page-section about-page" style="background-color: #fafafa;">
    <div class="wrapper text-page">

        <div class="team__subheader">Our Team</div>

        <!--div class="display-flex team__row">
            <div class="team__item">
                <div class="team__item--inner">
                    <div class="team__item--icon">
                        <img src="img/about/wizard-oz.png" width="200" height="200" alt="HUBX team">
                    </div>
                    <div class="team__item--title">
                        Wizard of X
                    </div>
                    <div class="team__item--text">
                        Part Artificial Intelligence, Part Human
                    </div>
                </div>
            </div>
        </div-->

        <div class="display-flex team__row">
            <div class="team__item">
                <div class="team__item--inner">
                    <div class="team__item--icon">
                        <img src="../../../assets/corp-site/img/about/avatars/generic-avatar-m.png" 
                        srcset="../../../assets/corp-site/img/about/avatars/generic-avatar-m@2x.png 2x"
                        width="200" height="200" alt="HUBX team">
                    </div>
                    <div class="team__item--title">
                        Ahmed Rayyan
                    </div>
                    <div class="team__item--text">
                        Sales Support
                    </div>
                </div>
            </div>
            <div class="team__item">
                    <div class="team__item--inner">
                        <div class="team__item--icon">
                            <img src="../../../assets/corp-site/img/about/avatars/Alex-Santos-de-la-Horra.png" 
                            srcset="../../../assets/corp-site/img/about/avatars/Alex-Santos-de-la-Horra@2x.png 2x"
                            width="200" height="200" alt="HUBX team">
                        </div>
                        <div class="team__item--title">
                                Alex Santos de la Horra
                        </div>
                        <div class="team__item--text">
                                Software Ninja
                        </div>
                    </div>
                </div>
            <div class="team__item">
                    <div class="team__item--inner">
                        <div class="team__item--icon">
                            <img src="../../../assets/corp-site/img/about/avatars/generic-avatar-w.png" 
                            srcset="../../../assets/corp-site/img/about/avatars/generic-avatar-w@2x.png 2x"
                            width="200" height="200" alt="HUBX team">
                        </div>
                        <div class="team__item--title">
                                Andrea Mercado
                        </div>
                        <div class="team__item--text">
                                Onboarding
                        </div>
                    </div>
                </div>
                <div class="team__item">
                    <div class="team__item--inner">
                        <div class="team__item--icon">
                            <img src="../../../assets/corp-site/img/about/avatars/Bogdan-Deac.png" 
                            srcset="../../../assets/corp-site/img/about/avatars/Bogdan-Deac@2x.png 2x"
                            width="200" height="200" alt="HUBX team">
                        </div>
                        <div class="team__item--title">
                                Bogdan Deac
                        </div>
                        <div class="team__item--text">
                                Global Sales
                        </div>
                    </div>
                </div>
                <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/generic-avatar-w.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/generic-avatar-w@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Celenny Taveras
                            </div>
                            <div class="team__item--text">
                                    Accounting
                            </div>
                        </div>
                    </div>
                <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Daniel-Bornemann.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Daniel-Bornemann@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Daniel Bornemann
                            </div>
                            <div class="team__item--text">
                                    Support
                            </div>
                        </div>
                    </div>
                <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/generic-avatar-m.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/generic-avatar-m@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    David Mendez
                            </div>
                            <div class="team__item--text">
                                    Sales Support
                            </div>
                        </div>
                    </div>
                                <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Debbie-Downer.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Debbie-Downer@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Debbie Downer
                            </div>
                            <div class="team__item--text">
                                    Moral Support
                            </div>
                        </div>
                    </div>
                                <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Derek-Wall.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Derek-Wall@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Derek Wall
                            </div>
                            <div class="team__item--text">
                                    Janitor
                            </div>
                        </div>
                    </div>
                                <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Ernesto-Cabrera.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Ernesto-Cabrera@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Ernesto Cabrera
                            </div>
                            <div class="team__item--text">
                                    CTO
                            </div>
                        </div>
                    </div>
                                <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/generic-avatar-m.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/generic-avatar-m@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Fiorelly Loero
                            </div>
                            <div class="team__item--text">
                                    Support
                            </div>
                        </div>
                    </div>
                                <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Frank-Fader.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Frank-Fader@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Frank Fader
                            </div>
                            <div class="team__item--text">
                                    Sales Support
                            </div>
                        </div>
                    </div>
                                <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/generic-avatar-m.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/generic-avatar-m@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Gonzalo Beytia
                            </div>
                            <div class="team__item--text">
                                    Sales Support
                            </div>
                        </div>
                    </div>
                                <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Henry-Londono.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Henry-Londono@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Henry Londono
                            </div>
                            <div class="team__item--text">
                                    Marketplace Support
                            </div>
                        </div>
                    </div>
                                <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/generic-avatar-w.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/generic-avatar-w@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Jackie Thomas
                            </div>
                            <div class="team__item--text">
                                    Sales
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Jacob-Gruia.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Jacob-Gruia@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Jacob Gruia
                            </div>
                            <div class="team__item--text">
                                    Finance & Accounting
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Jaime-Sheriden.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Jaime-Sheriden@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Jaime Sheriden
                            </div>
                            <div class="team__item--text">
                                    Chief Marketplace Officer
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/generic-avatar-w.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/generic-avatar-w@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Janelle Guillama
                            </div>
                            <div class="team__item--text">
                                    Accounting
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Launch-Bot.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Launch-Bot@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Launch Bot
                            </div>
                            <div class="team__item--text">
                                    Price Wizard
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Lisday-Bello.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Lisday-Bello@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Lisday Bello
                            </div>
                            <div class="team__item--text">
                                    Accounting
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Louis-Vasz.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Louis-Vasz@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Louis Vasz
                            </div>
                            <div class="team__item--text">
                                    Operations
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/generic-avatar-w.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/generic-avatar-w@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Maggie Forero
                            </div>
                            <div class="team__item--text">
                                    Logistics
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/generic-avatar-w.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/generic-avatar-w@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Maria Jimenez
                            </div>
                            <div class="team__item--text">
                                    Logistics
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Matthew-Schwartz.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Matthew-Schwartz@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Matthew Schwartz
                            </div>
                            <div class="team__item--text">
                                    Software Ninja
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Mildred-Glynn.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Mildred-Glynn@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Mildred Glynn
                            </div>
                            <div class="team__item--text">
                                    Sales Support
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Nikolai-Stepanov.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Nikolai-Stepanov@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Nikolai Stepanov
                            </div>
                            <div class="team__item--text">
                                    Software Ninja
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Pablo-Santos.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Pablo-Santos@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Pablo Santos
                            </div>
                            <div class="team__item--text">
                                    Mobility Marketplace
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Philip-Dowding.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Philip-Dowding@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Philip Dowding
                            </div>
                            <div class="team__item--text">
                                    Sales Support
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                            <div class="team__item--inner">
                                <div class="team__item--icon">
                                    <img src="../../../assets/corp-site/img/about/avatars/generic-avatar-w.png" 
                                    srcset="../../../assets/corp-site/img/about/avatars/generic-avatar-w@2x.png 2x"
                                    width="200" height="200" alt="HUBX team">
                                </div>
                                <div class="team__item--title">
                                        Rene Perryman
                                </div>
                                <div class="team__item--text">
                                        Data Scientist
                                </div>
                            </div>
                        </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Sergey-Mykhailov.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Sergey-Mykhailov@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Sergey Mykhailov
                            </div>
                            <div class="team__item--text">
                                    Software Ninja
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Stephanie-Larsen.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Stephanie-Larsen@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Stephanie Larsen
                            </div>
                            <div class="team__item--text">
                                    Marketplace Support
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Susana-Borjas.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Susana-Borjas@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Susana Borjas
                            </div>
                            <div class="team__item--text">
                                    Software Ninja
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/generic-avatar-m.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/generic-avatar-m@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Victor De La Guardia
                            </div>
                            <div class="team__item--text">
                                    Sales Support
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Vivianne-Baez.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Vivianne-Baez@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Vivianne Baez
                            </div>
                            <div class="team__item--text">
                                    Accounting
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Wilder-Baro.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Wilder-Baro@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Wilder Baro
                            </div>
                            <div class="team__item--text">
                                    Software Ninja
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/Luis-Morejon.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/Luis-Morejon@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    Luis Morejon
                            </div>
                            <div class="team__item--text">
                                    Software Ninja
                            </div>
                        </div>
                    </div>
                    <div class="team__item">
                        <div class="team__item--inner">
                            <div class="team__item--icon">
                                <img src="../../../assets/corp-site/img/about/avatars/generic-avatar-m.png" 
                                srcset="../../../assets/corp-site/img/about/avatars/generic-avatar-m@2x.png 2x"
                                width="200" height="200" alt="HUBX team">
                            </div>
                            <div class="team__item--title">
                                    William Ripley
                            </div>
                            <div class="team__item--text">
                                    Sales Support
                            </div>
                        </div>
                    </div>
        </div>
    </div>
    </section>
    </div>
    <app-footer></app-footer>
</div>