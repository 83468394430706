import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import * as models from '../../interfaces/model';
import { UtilityService } from '../../../core/utility.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CartItemsService } from './cart-items.service';
import { CartService } from '../cart.service';

@Component({
  selector: 'app-cart-items',
  templateUrl: './cart-items.component.html',
  styleUrls: ['./cart-items.component.scss']
})
export class CartItemsComponent implements OnInit, OnDestroy {
  @Input() i: number;
  @Input() selectedOrderNumber;
  @Input() selectedCartItem: models.ICartItem = {};
  @Input() isOutOfStock: boolean;
  @Input() userRole: string;
  @Input() deleteSpinner: boolean;
  @Input() deleteSpinnerItem: string;
  @Input() isMobile: boolean;
  @Input() openMobileInfo: string;
  @Input() user_role: string;
  @Input() cartItem: models.ICartItem;
  @Input() hideText: boolean;
  @Input() showWarning: boolean;
  @Input() loading: boolean;
  @Input() imageBaseUrl: string;
  @Output() deleteItemEmitter = new EventEmitter<models.ICartItem>();
  @Output() copyStateEmitter = new EventEmitter();
  @Output() validateCartItemEmitter = new EventEmitter<models.ICartItem>();
  @Output() handleQuantityUpdateEmitter = new EventEmitter<models.ICartItem>();
  @Output() handlePriceUpdateEmitter = new EventEmitter<object>();
  @Output() showTolltipEmitter = new EventEmitter<string>();
  @Output() onClickedOutsideEmitter = new EventEmitter<any>();
  @Output() itemDetailedMode = new EventEmitter<models.IItem>();
  @Output() gradingGuideEmitter = new EventEmitter<models.IItem>();
  openItemDetails = false;
  animateOut = false;
  searchSubject: BehaviorSubject<models.ICartItem> = new BehaviorSubject(null);
  subscription: Subscription;

  showItemMOQ = false;
  showItemMXQ = false;
  showInputMXQ = false;

  constructor(public cartItemsService: CartItemsService,
              public cartService: CartService) { }

  ngOnInit() {
    this.subscription = this.searchSubject.pipe(
      debounceTime(500)
    ).subscribe(cartI => {
        if ( !cartI || !cartI.quantity ) { return; }
        this.handleQuantityUpdateEmitter.emit(cartI);
      }
    );
  }

  openModal(event: any): void {
    if (event.target.tagName === 'DIV' && ['BUYER', 'SUPER', 'SALES'].includes(this.userRole) ) {
      this.openItemDetails = true;
      this.itemDetailedMode.emit(this.cartItem);
    }
  }

  closeModal(isCancel: boolean): void {
    if (isCancel) {
      this.cartItem.item.qty = this.cartItem.item.previousQuantityAdded > 0 ? this.cartItem.item.previousQuantityAdded : 1;
    }

    this.animateOut = true;
    setTimeout(() => {
      this.openItemDetails = false;
    }, 400);
  }


  showTooltip(id: string): void {
    this.showTolltipEmitter.emit(id);
  }

  validateCartItem(cartItem: models.ICartItem): void {
    this.validateCartItemEmitter.emit(cartItem);
  }

  onClickedOutside(this: any): void {
    this.onClickedOutsideEmitter.emit(this);
  }

  itemMOQTooltip(): void {
    this.showItemMOQ = !this.showItemMOQ;
  }

  itemMXQTooltip(v): void {
    switch (v) {
      case 1: this.showItemMXQ = !this.showItemMXQ; break;
      case 2: this.showInputMXQ = !this.showInputMXQ; break;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
