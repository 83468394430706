import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { OrdersService } from './orders.service';

import * as models from '../../interfaces/model';
import { IOrder } from '../../interfaces/model';
import { Observable, of } from 'rxjs';

@Injectable()
export class OrdersResolve implements Resolve<models.IOrder> {
  constructor(private ordersService: OrdersService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<IOrder> {

    return this.ordersService.orderChosen?.docNumber ?
      of(this.ordersService.orderChosen) : this.ordersService.getOrderDetails(route.paramMap.get('docEntry'));
  }
}
