<div class="loader" *ngIf="topService.loading"></div>
<div class="offer-wrap" [class.mobile]="topService.isMobile">

    <div class="offer"
        *ngIf="offerService.offersSummary?.tokenCount && !showNoEnoughInventoryAndPriceError() && 
        item.offerStatus !== OfferStatusEnum.offerMade && 
        item.offerStatus !== OfferStatusEnum.disabledItemStatusChanged">
        <div class="offer-header">
            <div class="offer-header-heading">
                <h3>Make Offer</h3>
            </div>
            <div class="tokens-wrap">
              <div class="tokens-images">
                <i [class.active]="offerService.offersSummary?.tokenCount>=3"></i>
                <i [class.active]="offerService.offersSummary?.tokenCount>=2"></i>
                <i [class.active]="offerService.offersSummary?.tokenCount>=1"></i>
              </div>
              <div class="tokens-info">
                <i><img src="assets/images/icons/offers/info.svg" alt="info"></i><span>Available Tokens</span>
              </div>
              <div class="tokens-tooltip">
                You have 3 tokens at your disposal. This allows you to have up to 3 active offers at the same time. At the end of the offer, the token is returned to your balance.
              </div>
            </div>
        </div>

        <form [formGroup]="offerForm">

            <!-- quantity -->
            <div class="offer-row">
                <div class="control"
                    [class.__has-error]="offerForm.get('quantity').errors?.validQuantityBasedOnAvailability === false || formErrors.quantity.validQuantityBasedOnAvailability === false || error?.serverErrorCode==OfferErrorCodes.CreateOffer_ItemAvailabilityChanged">
                    <div class="control__label">I want
                        <span>
                            ({{item.inventory.availableToSell < 0 ? 0:item.inventory.availableToSell | number:0:'en'}} Available)
                                </span>
                    </div>
                    <div>
                        <input type="text" formControlName="quantity" placeholder="0" mask="0*" pattern="[0-9]*" inputmode="numeric" appOnlyDigits>
                        <div class="control__postfix">unit(s)</div>
                    </div>

                    <div class="control__error">
                        <span *ngIf="error && error.serverErrorCode==OfferErrorCodes.CreateOffer_ItemAvailabilityChanged">
                            {{error.description}}
                        </span>
                        <span *ngIf="offerForm.get('quantity').errors?.validQuantityBasedOnAvailability === false || (formErrors.quantity.validQuantityBasedOnAvailability === false && !error)">
                            Your quantity is more than available
                        </span>
                    </div>
                </div>
            </div>

            <!-- price -->
            <div class="offer-row" [hidden]="true">
                <div class="control" [class.__has-error]="formErrors.price.validPrice === false || formErrors.price.validRegardsToMaxPrice === false ||
                    error?.serverErrorCode==OfferErrorCodes.CreateOffer_ItemPriceChanged">
                    <div class="control__label">I will pay <span>({{effectivePrice | CustomOfferCurrency:2}} List Price)</span></div>
                    <div>
                        <input type="text" formControlName="price" placeholder="$0" >
                        <div class="control__postfix">per unit</div>
                    </div>

                    <div class="control__error">
                        <span *ngIf="error && error.serverErrorCode==OfferErrorCodes.CreateOffer_ItemPriceChanged">
                            {{error.description}}
                        </span>
                        <span *ngIf="formErrors.price.validPrice === false && !error">
                            Your offer is too low, try again. Offers cannot be less than
                            {{offerService.offersSummary.validationRules.maxPercentage}}% off
                        </span>
                        <span *ngIf="formErrors.price.validRegardsToMaxPrice === false && !error">
                            Amount should not exceed current list price
                        </span>
                    </div>
                </div>
            </div>

            <div class="offer-row">
                <div class="control" [class.__has-error]="formErrors.price.validPrice === false || formErrors.price.validRegardsToMaxPrice === false ||
                error?.serverErrorCode==OfferErrorCodes.CreateOffer_ItemPriceChanged"
                (click)="togglePriceDropdown()" (clickOutside)="priceDropdown.expanded=false"
                    [attachOutsideOnClick]="true">
                    <div class="control__label">I will pay <span>({{effectivePrice | CustomOfferCurrency:2}} List Price)</span></div>
                    <div class="app-dropdown">
                        <div class="app-dropdown-white">
                            <button class="btn dropdown-toggle"
                                [class.active]="priceDropdown.valueWasSelectedByUser"
                                [class.expanded]="priceDropdown.expanded"
                                >
                                <span class="items-filter-select-name" *ngIf="!priceDropdown.selectedValue">
                                    Select Unit Price
                                </span>
                                <span class="items-filter-select-name" *ngIf="priceDropdown.selectedValue">
                                    {{priceDropdown.selectedValue | CustomOfferCurrency:2}} ({{priceDropdown.selectedPercent}})
                                </span>
                            </button>
                            <div class="dropdown-menu" [class.show]="priceDropdown.expanded">
                                <div class="dropdown-search-results">
                                    <ng-container *ngFor="let priceOption of priceOptions; let i = index">
                                        <a class="dropdown-item"
                                            (click)="onSelectPrice(priceOption.price, priceOption.percent, $event)"
                                            [class.active]="priceDropdown.selectedValue == priceOption.price && goodForDropdown.valueWasSelectedByUser">
                                            <span class="dropdown-item__value">{{priceOption.price |
                                                CustomOfferCurrency:2}}</span>
                                            <span class="dropdown-item__badge">{{priceOption.percent}}</span>
                                        </a>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="control__error">
                                <span *ngIf="error && error.serverErrorCode==OfferErrorCodes.CreateOffer_ItemPriceChanged">
                                    {{error.description}}
                                </span>
                                <span *ngIf="formErrors.price.validPrice === false && !error">
                                    Your offer is too low, try again. Offers cannot be less than
                                    {{offerService.offersSummary.validationRules.maxPercentage}}% off
                                </span>
                                <span *ngIf="formErrors.price.validRegardsToMaxPrice === false && !error">
                                    Amount should not exceed current list price
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Good For -->
            <div class="offer-row">
                <div class="control" (click)="toggleGoodForDropdown()" (clickOutside)="goodForDropdown.expanded=false"
                    [attachOutsideOnClick]="true">
                    <div class="control__label">My Offer is good for</div>
                    <div class="app-dropdown">
                        <div class="app-dropdown-white">
                            <button class="btn dropdown-toggle"
                                [class.active]="goodForDropdown.valueWasSelectedByUser"
                                [class.expanded]="goodForDropdown.expanded">
                                <span class="items-filter-select-name">
                                    {{goodForDropdown.selectedValue}} Business Day{{goodForDropdown.selectedValue === 1 ? '' : 's'}}
                                </span>
                            </button>
                            <div class="dropdown-menu" [class.show]="goodForDropdown.expanded">
                                <div class="dropdown-search-results">
                                    <a class="dropdown-item" (click)="onSelectDuration(1, $event)"
                                        [class.active]="goodForDropdown.selectedValue == 1 && goodForDropdown.valueWasSelectedByUser">
                                        1 Business Day
                                    </a>
                                    <a class="dropdown-item" (click)="onSelectDuration(2, $event)"
                                        [class.active]="goodForDropdown.selectedValue == 2 && goodForDropdown.valueWasSelectedByUser">
                                        2 Business Days
                                    </a>
                                    <a class="dropdown-item" (click)="onSelectDuration(3, $event)"
                                        [class.active]="goodForDropdown.selectedValue == 3 && goodForDropdown.valueWasSelectedByUser">
                                        3 Business Days
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <!-- total offer -->
        <div class="offer-row">
            <div class="offer-total" [class.__active]="offerForm.dirty" [class.__has-error]="!validTotalOffer">
                <div class="offer-total__name">
                    Total Offer:
                </div>
                <div class="offer-total__value">
                    {{ (offerForm.value.quantity * offerForm.value.price) > 0
                    ? ((offerForm.value.quantity * offerForm.value.price) | currency: 'USD' : true : '1.2-2')
                    :'$0'}}
                </div>
            </div>
        </div>

        <div class="offer-row">
            <div class="offer-footer" [class.__has-error]="!validTotalOffer">
                <div class="offer-footer-error" *ngIf="!validTotalOffer">
                    <span>
                        Only offers that with a minimum of ${{offerService.offersSummary.validationRules.minAmount/1000}}k will be
                        considered.
                    </span>
                </div>
                <div class="offer-footer-buttons">
                    <button type="button" class="_back" *ngIf="topService.isMobile" (click)="closeModal()">
                        Back
                    </button>
                    <button type="button" class="_secondary" (click)="showConfirm($event)"
                        [disabled]="loading || !offerForm.valid || !validTotalOffer">
                        Submit Offer<span>&nbsp;&nbsp;(1 token)</span>
                    </button>
                </div>

            </div>
        </div>


    </div>

    <!-- offer was made -->
    <div class="offer-success" *ngIf="item.offerStatus === OfferStatusEnum.offerMade">
        <div class="offer-row">
            <div class="offer-success-image">
                <img src="assets/images/icons/offers/offer-added.svg" alt="Token" width="48" height="48">
            </div>
        </div>
        <div class="offer-row">
            <div class="offer-success-heading">
                Offer Made
            </div>
        </div>
        <div class="offer-row">
            <div class="offer-success-message">
                You can check the current offer status on the offers page
            </div>
        </div>
        <div class="offer-row">
            <div class="offer-success-link">
                <a *ngIf="topService.isMobile" role="button" class="_close" (click)="closeModal()">Close</a>
                <a [routerLink]="['/user/offers', offerId]">Go to My Offers</a>
            </div>
        </div>
    </div>

        <!-- item is unavailable  -->
        <div class="offer-success" *ngIf="item.offerStatus === OfferStatusEnum.disabledItemStatusChanged">
            <div class="offer-row">
                <div class="offer-success-image">
                    <img src="assets/images/icons/offers/36-alert.svg" alt="unavailable" width="48" height="48">
                </div>
            </div>
            <div class="offer-row">
                <div class="offer-success-heading">
                    Item unavailable
                </div>
            </div>
            <div class="offer-row">
                <div class="offer-success-message">
                    This item is currently unavailable for purchasing.
                </div>
            </div>
            <div class="offer-row">
                <div class="offer-success-link">
                    <a *ngIf="topService.isMobile" role="button" class="_close" (click)="closeModal()">Close</a>
                </div>
            </div>
        </div>

    <!-- no token -->
    <div class="offer-token" *ngIf="!offerService.offersSummary?.tokenCount && item.offerStatus !== OfferStatusEnum.offerMade">
        <div class="offer-row">
            <div class="offer-token-image">
                <img src="assets/images/icons/offers/token@2x.png" alt="Token" width="auto" height="96">
            </div>
        </div>
        <div class="offer-row">
            <div class="offer-token-heading">
                You're out of tokens!
            </div>
        </div>
        <div class="offer-row">
            <div class="offer-token-text">
                All of your tokens are currently being used. Tokens are returned to your account when an offer is rejected, canceled or completed.
            </div>
        </div>
        <div class="offer-row" *ngIf="topService.isMobile" >
            <div class="offer-footer">
                <div class="offer-footer-buttons">
                    <button type="button" class="_got-it" (click)="closeModal()">
                        Got It
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Not enough inventory-->
    <div class="offer-inventory"
        *ngIf="showNoEnoughInventoryAndPriceError() && offerService.offersSummary?.tokenCount && item.offerStatus !== OfferStatusEnum.offerMade">
        <div class="offer-row">
            <div class="offer-inventory-image">
                <img src="assets/images/icons/offers/no-inventory.svg" alt="Token" width="48px" height="auto">
            </div>
        </div>
        <div class="offer-row">
            <div class="offer-inventory-heading">
                Not enough available inventory to make offer
            </div>
        </div>
        <div class="offer-row">
            <div class="offer-inventory-text">
                Notify you when inventory is added?
            </div>
        </div>
        <div class="offer-row">
            <div class="offer-inventory-link">
                <a *ngIf="!topService.isMobile" (mouseenter)="openNotificationTooltip($event)">Notify me</a>
                <a *ngIf="topService.isMobile" class="_got-it" (click)="closeModal()">Got It</a>
            </div>
        </div>
    </div>

</div>

<!-- confirmation popup -->
<div class="confirm-modal form-offer" *ngIf="offerService.showSubmitOfferConfirmationPopup" [class.mobile]="topService.isMobile">
    <div class="confirm-modal-wrap animated" [class.zoomIn]="true" [class.zoomOut]="animateOut"
        (clickOutside)="offerService.showSubmitOfferConfirmationPopup = false;">
        <div class="confirm-modal-message">
            <h3 class="confirm-modal-message__h3">Are you sure you want to make this offer?</h3>
            <span class="confirm-modal-message__span">
                If seller accepts your offer, it automatically places a binding order and is non-cancelable or
                returnable
            </span>
        </div>

        <div class="confirm-modal-buttons">
            <button type="button" class="confirm-modal-buttons__cancel" (click)="closeConfirm($event)">Back</button>
            <button type="button" class="confirm-modal-buttons__continue" (click)="submitOffer()">Submit Offer</button>
        </div>

    </div>
</div>

<!-- Information popup -->
<div class="confirm-modal form-offer __inform" *ngIf="offerService.showOfferInformationPopup" [class.mobile]="topService.isMobile">
    <div class="confirm-modal-wrap animated" [class.zoomIn]="true" [class.zoomOut]="animateOut"
        (clickOutside)="offerService.showOfferInformationPopup = false;">
        <div class="confirm-modal-message">
            <!-- <h3 class="confirm-modal-message__h3"
                *ngIf="error.serverErrorCode==OfferErrorCodes.CreateOffer_ItemAvailabilityChanged">
                Available quantity has changed. Please update your offer
            </h3>
            <h3 class="confirm-modal-message__h3"
                *ngIf="error.serverErrorCode==OfferErrorCodes.CreateOffer_ItemPriceChanged">
                Item price has changed. Please update your offer
            </h3> -->
            <h3 class="confirm-modal-message__h3">
                {{error.description}}
            </h3>
        </div>

        <div class="confirm-modal-buttons">
            <button type="button" class="confirm-modal-buttons__continue" (click)="closeInformationPopup($event)">
                Got It
            </button>
        </div>

    </div>
</div>
