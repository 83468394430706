<div class="item listing-view-container" [class.hideText]="itemsService.hideText"
  [ngClass]="{'purple':  (openItemDetails  || showRestrictedCountryPopup) && selectedItem.highlightId === item.highlightId , '_has-past-purchase-title': item.pastPurchaseInfo && source !== 'pastPurchase'}"
  [class.part-view]="source ==='justSold'||source ==='pastPurchase'"
  [class.notification-item]="source === 'notifications'">
  <app-past-purchase-title *ngIf="item.pastPurchaseInfo && source !== 'pastPurchase'" [item]="item"></app-past-purchase-title>
  <div class="item__main">
    <div class="row margin-0">
      <div class="col item__main--left">
        <div class="row">
          <div class="item__logo--wrapper">
            <div class="item__logo" (click)="openModal()">
              <div *ngIf="item.manufacturerLogoUrl"
                [ngStyle]="{'background-image': 'url(' + item.manufacturerLogoUrl + ')'}"></div>
              <div *ngIf="!item.manufacturerLogoUrl"
                style="background-image: url('http://cdn.hubx.com/assets/images/item-placeholder.svg');"> </div>
            </div>
            <ng-template ngFor let-details [ngForOf]="item.notificationDetails">
              <div *ngIf="details.notificationType === getNotificationType(currentNotFilter)"
                   class="item-notification-date">{{ details.timeStamp | date: 'MMM d, y' }}</div>
            </ng-template>
            <div class="item-notification-date"
                 *ngIf="currentNotFilter === NotificationFiltersEnum">
              {{ item.lastNotificationDate | date: 'MMM d, y' }}</div>
          </div>
          <div class="col item__descriptadd-notificationion pl-0">
            <div class="item-description-wrap">
              {{item.description}}&nbsp; - &nbsp;
              <span class="copy-number" (mouseover)="itemsService.copyState()"
                (click)=itemsService.copyText($event,item.partNumber)>{{item.partNumber}}
                <span ngModel="copied" ngDefaultControl>{{ itemsService.copied }}</span>
              </span>
            </div>
          </div>

          <div class="col grading" (click)="openGradingGuide($event)" *ngIf="item.gradingGuideUrl">
            <div class="row">
              <div class="col-6 align-self-center grading-back"></div>
              <div class="col-6 no-padding grading-label align-self-center">Grading Guide</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-auto item__main--right">
        <div class="row justify-content-end">
          <div class="col item__exw text-center">
              <div class="item-info-label">EXW</div>
              <div class="item-exw-value">{{ item.exwPoint }}</div>
          </div>
          <div class="col item__price">
            <div class="text-center mb-12 item-info-label">Available</div>
            <div class="text-center item-available-wrap">
              <div>
                <div [ngClass]="{'sold': item.inventory.availableToSell<1}" class="d-flex justify-content-center item-available-row">
                  <span  *ngIf="itemsService.showInStockTitle(item,sessionService.userRole)"
                        class="item-available in-stock">In Stock
                  </span>
                  <span class="item-available-label">
                      {{ item.inventory.availableToSell>0?itemsService.showInStockValue(item,sessionService.userRole):"Sold Out" }}
                  </span>
                </div>
              </div>
              <div *ngIf="itemsService.showInTransitTitle(item,sessionService.userRole)" class="d-flex justify-content-center item-available-row">
                <span class="item-available in-transit">In Transit</span>
                <span class="item-available-label">{{ itemsService.showInTransitValue(item,sessionService.userRole) }}</span>
              </div>
            </div>
          </div>

          <div class="col item__count">
            <div>
              <div *ngFor="let price of item.prices">
                <div class="item__count--wrapper">
                  <div class="moq-wrap">
                    <span class="price-qty">{{price.fromQty}}+</span>
                    <span class="unit-price">{{price.unitPrice | CustomCurrency}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>

          <div *ngIf="item.moq > 1" class="col item__price">
            <div class="wrapper">
              <div [ngClass]="{'displayItemMoq': showItemMOQ}" class="itemMoqTooltip listing-moq-tt">Minimum Order
                Quantity</div>
              <div (mouseenter)="itemMOQTooltip()" (mouseleave)="itemMOQTooltip()" class="text-center mb-12 item-info-label">MOQ</div>
            </div>
            <div class="text-center moq-mxq-value">{{item.moq}}</div>
          </div>

          <div *ngIf="item.mxq > 0" class="col item__price">
            <div class="wrapper">
              <div [ngClass]="{'displayItemMxq': showItemMXQ}" class="itemMxqTooltip listing-mxq-tt">Maximum Quantity</div>
              <div (mouseenter)="itemMXQTooltip(1)" (mouseleave)="itemMXQTooltip(1)" class="text-center mb-12 item-info-label">MXQ</div>
            </div>
            <div class="text-center moq-mxq-value">{{ item.mxq }}</div>
          </div>

          <div class="col item__price">
            <div class="text-center mb-12 item-info-label">Price</div>
            <div class="text-center item-price-value">{{item.baseUnitPrice | CustomCurrency}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="item__bottom">
    <div class="row item-det-ie-fix">

      <app-item-attributes class="col item__attributes item-details"
        [isJustSold]="source ==='justSold' || source ==='pastPurchase'" [isNotificationItem]="source === 'notifications'" [isCart]="false" [item]="item"
        [hideText]="itemsService.hideText || source ==='justSold' || source ==='pastPurchase'"
        [moreThanOnHand]="checkMoreThanOnHand">
      </app-item-attributes>

      <div class="col item__cart">
        <div class="row float-right">

          <div *ngIf="displayMakeOfferBtn" class="item-offer">
              <a class="item-offer__button"
              [class.disabled]="offerService.makeOfferButtonDisabled(item)"
              [class.active]="offerService.offerMade(item)"
              (click)="openModal()">
              <span class="_icon">
                <svg height="24" width="24">
                  <use xlink:href="#icon-offers-listing"></use>
                </svg>
              </span>
             {{offerService.offerMade(item) ? 'Offer Made' : 'Make Offer'}}
            </a>
          </div>

          <div class="item__notifications" *ngIf="item.inventory.availableToSell>0">
            <div class="add-notification">
              <span class="_icon"
                   [class.active]="item.notificationInfoObj.moreInventoryInitialValue || item.notificationInfoObj.priceDropInitialValue">
                <svg (click)="showGuestLogin()"
                (mouseleave)="topService.notificationTooltipHovered = false"
                (mouseenter)="userService.isGuest?'':openNotificationTooltip($event)" height="24" width="24">
                  <use xlink:href="#icon-add-notification"></use>
                </svg>
              </span>
            </div>
          </div>
          <div class="item__qty wrapper" [class.hide]='!item.added && item.inventory.availableToSell<1'>
            <div *ngIf="item.mxq > 1" [ngClass]="{'displayItemMxq': showInputMXQ}" class="itemMxqTooltip listing-mxq-tt">Maximum Quantity {{ item.mxq }}</div>
            <input type="number" [(ngModel)]="item.qty" (keyup)="updateItemPrice()" (blur)="onBlur($event)"
              appOnlyDigits (click)="selectText($event)" (mouseenter)="itemMXQTooltip(2)" (mouseleave)="itemMXQTooltip(2)"
              [disabled]="topService.loading || item.added || !item.added && item.inventory.availableToSell<1"
              [class.disabled]="item.added" #qty />
          </div>
          <div class="col" style="display: flex;">
            <a *ngIf='!item.added && item.inventory.availableToSell>0' (click)="!topService.loading?addToCart():''"
              class="item-to-cart">
              <svg *ngIf="!item.loading" width="24" height="24">
                <use xlink:href="#icon-add-to-cart"></use>
              </svg>
              <span *ngIf="item.loading" class="cart-loader"></span>
              <span *ngIf="!item.beingEdited && !item.loading">Add to Cart</span>
              <span *ngIf="!item.beingEdited && item.loading">Adding...</span>
              <span *ngIf="item.beingEdited && !item.loading">Update Cart</span>
              <span *ngIf="item.beingEdited && item.loading">Updating...</span>
            </a>

            <div class="add-waitlist" *ngIf="item.inventory.availableToSell<1"
              (click)="submitNotification($event)"
              (mouseenter)="openWaitlistTooltip($event)"
              (mouseleave)="hideWaitlistTooltip($event)"
              [class.added]="item.notificationInfoObj.waitlist">
              <i class="_icon"></i>
              <span [class.hide]="item.notificationInfoObj.waitlist">Add to Waitlist</span>
              <span [class.hide]="!item.notificationInfoObj.waitlist">Waitlisted</span>
            </div>

            <a *ngIf="item.added && item.inventory.availableToSell>0" (click)="!topService.loading?editItemQty():''" class="item-edit-qty" [class._delete-spinner]="deleteSpinner">
              <span *ngIf="item.loading || deleteSpinner" class="cart-loader"></span>
              <span *ngIf="!item.loading && !deleteSpinner" class="_edit-label">Edit QTY</span>
              <span *ngIf="item.loading && !deleteSpinner">Updating...</span>
              <span *ngIf="deleteSpinner">Deleting...</span>
            </a>
            <a *ngIf="item.added && item.inventory.availableToSell>0 && !deleteSpinner" class="item-remove-from-cart" (click)="removeItemFromCart()">
              <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                <use xlink:href="#icon-remove-item"></use>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
