import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../user/user.service';

import * as models from '../shared/interfaces/model';
import { map } from 'rxjs/operators';

@Injectable()
export class CreditCardGuard implements CanActivate {

  constructor(public userService: UserService) { }

  canActivate(): Observable<boolean> | boolean {

    if (this.userService.isCreditCardAllowed) {
      return true;
    } else {
      return this.userService.getProfile().pipe(
        map((profile: models.IProfile) => {
          if (profile.isCreditCardAllowed) {
            return true;
          } else {
            return false;
          }
        })
      );
    }


  }

}
