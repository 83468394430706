import { Component, OnInit, ChangeDetectorRef, AfterViewChecked, OnDestroy } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { SessionService } from '../services/session.service';
import { UserService } from '../user/user.service';
import { GuestService } from '../shared/services/guest.service';
import { Router } from '@angular/router';
import { SharedService } from '../shared/shared.service';
import { IBuyerErrorModal } from '../buyer/interfaces/IBuyerErrorModal';
import { CartService } from '../buyer/cart/cart.service';
import { environment } from '../../environments/environment';
import { ConnectionManagerService } from '../shared/services/signalR/connection-manager.service';
import { eConnectionType } from '../shared/services/signalR/connection-type';
import { ItemsService } from '../shared/services/items.service';
import { Subscription } from 'rxjs';
import { PubSubService } from '../core/pubsub.service';
import { SharedSource } from '../core/shared-source';
import { OfferService } from '../user/offers/offer.service';
import { IOfferFeatureSwitchedMessage, IOfferSeenMessage } from '../shared/interfaces/IOfferNotificationMessage';
import { TopService } from '../shared/services/top.service';
import { UserFiltersService } from '../buyer/user-filters/user-filters.service';


@Component({
  selector: 'app-main-top',
  templateUrl: './main-top.component.html',
  styleUrls: ['./main-top.component.scss', '../shared/confirm-modal/confirm-modal.component.scss']
})
export class MainTopComponent implements OnInit, AfterViewChecked, OnDestroy {
  role: string;
  navToggle = false;
  selectedLink = '';
  sessionErrorModal: IBuyerErrorModal = {
    bodyText: 'Your session has expired. Please login again.',
    title: 'Sorry!'
  };
  maintenanceModeOn = false;
  impersonationListEmptyShowPopup = false;
  subscription: Subscription = new Subscription();

  constructor(
    public authService: AuthService,
    public userService: UserService,
    public sessionService: SessionService,
    public cdr: ChangeDetectorRef,
    public guestService: GuestService,
    public router: Router,
    public itemsService: ItemsService,
    public sharedService: SharedService,
    public cartService: CartService,
    public pubSubService: PubSubService,
    public connManagerService: ConnectionManagerService,
    public offerService: OfferService,
    public userFiltersService: UserFiltersService,
    public topService: TopService) { }

  ngOnInit() {
    this.role = this.sessionService._user_role;
    this.startConnections();
    this.authService.roleChanged.subscribe(role => this.onRoleChanged(role));

    this.authService.getInitialOfferData();
    if (environment.maintenanceMode) {
      this.maintenanceModeOn = true;
    }
    
    this.subscription.add(
      this.pubSubService.sharedSubject
        .subscribe(myEvent => {
          if (myEvent.name === SharedSource.partnerIdReady) {
            if (!this.connManagerService.connections.has('Notification')) {
              this.startConnections();
            }
          }

          switch (myEvent.name) {
            case SharedSource.handleOfferFeatureSwitched:
              this.offerService.handleOfferFeatureSwitched(myEvent.data as IOfferFeatureSwitchedMessage, this.cdr );
              break;

            case SharedSource.tokenCountUpdated:
              this.offerService.getTokenCountNotream();
              break;

            case SharedSource.offerValidationRulesUpdated:
              this.offerService.getValidationRulesNotream();
              break;

            case SharedSource.offerSeen:
                this.offerService.handleCountersDueToOfferSeenReceived(myEvent.data as IOfferSeenMessage);
              break;

            case SharedSource.offerEvent:
              this.offerService.handleCountersDueToNewOfferEvent(myEvent.data);
              break;

              case SharedSource.userProfileLoaded:
                this.setUserProfile(myEvent.data);
                break;

            default:
              break;
          }

        }));
  }

  setUserProfile(data){
    this.userService.userProfile = Object.assign({},data);
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  getUserRole(): string {
    return (this.authService.userRole);
  }

  onRoleChanged(role: string): void {
    this.role = role;
  }

  navigateTo(url: string): void {
    this.sharedService.corporateSiteSelectedLink = url;
    this.router.navigate([url]);

    if (url === 'vendor-login') {
      this.userService.foundBP = false;
    }
    if (url === 'guest/home') {
      this.itemsService.isLandingPage = true;
      this.sharedService.isStaticHtml = false;
      this.sharedService.isCorpSite = false;
      return;
    }
    this.sharedService.staticHtmlUrl = url;
    if (this.sharedService.isVendorLoginUrl) {
      this.sharedService.isStaticHtml = false;
      this.sharedService.isCorpSite = false;
      return;
    }

    this.sharedService.isCorpSite = true;
    this.sharedService.isStaticHtml = true;
  }

  clickLogoToReload(): void {
    this.sharedService.corporateSiteSelectedLink = 'logo';
    this.sharedService.isStaticHtml = false;
    this.router.navigate(['/guest/home']);
  }

  toggleNav(): void {
    this.navToggle = !this.navToggle;
  }

  close() {
    this.navToggle = false;
  }

  isOnBuyerLogin(): boolean {
    return this.router.url !== '/products' && this.router.url !== '/faq' && this.router.url !== '/sell' && this.router.url !== '/corphome'
      && this.router.url !== '/aboutUs' && !this.sharedService.isVendorLoginUrl && this.router.url !== '/contact-support'
      && this.router.url !== '/page-error';

  }

  isOnVendorLogin(): boolean {
    return this.sharedService.isVendorLoginUrl;
  }

  showError(errorModal: IBuyerErrorModal): void {
    this.sessionErrorModal = errorModal;
  }

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  handleExpiredSession(): void {
    this.userFiltersService.clearUserFilterList();
    this.cartService.resetCart();
    this.userService.logout();
  }

  startConnections() {
    switch (this.role) {
      case 'BUYER': {
        const userId = sessionStorage.getItem('user_id');
        this.connManagerService.addConnection(eConnectionType.Notification, userId);
        this.connManagerService.addConnection(eConnectionType.JustSold);
        this.connManagerService.addConnection(eConnectionType.Offer);
        break;
      }
      case 'SALES': {
        const userId = sessionStorage.getItem('user_id');
        this.connManagerService.addConnection(eConnectionType.Notification, userId);
        this.connManagerService.addConnection(eConnectionType.JustSold);
        this.connManagerService.addConnection(eConnectionType.Offer);
        break;
      }
      case 'SUPER': {
        const userId = sessionStorage.getItem('user_id');
        this.connManagerService.addConnection(eConnectionType.Notification, userId);
        this.connManagerService.addConnection(eConnectionType.JustSold);
        this.connManagerService.addConnection(eConnectionType.Offer);
        break;
      }
      case 'SELLER':
      case 'ADMIN':
      case 'VENDORMGR': {
        this.connManagerService.addConnection(eConnectionType.Offer);
        const partnerId = sessionStorage.getItem('partner_id');
        if (partnerId) {
          this.connManagerService.addConnection(eConnectionType.Notification, partnerId);
          this.connManagerService.addConnection(eConnectionType.JustSold);
        }
        break;
      }
    }
  }

  handleImpersonationListEmpty(): void {
    this.impersonationListEmptyShowPopup = true;
    this.userService.logout();
  }

  hideImpersonationErrorPopup(): void {
    this.impersonationListEmptyShowPopup = false;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
