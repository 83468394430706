<div *ngIf="isError" class="reset-pwd-modal zero-qty-alert">
  <div class="add-to-wrap animated" [class.zoomIn]="zeroQtyAlert || notEnoughQty || notEnoughMOQ"
   [class.zoomOut]="errorAnimateOut">
   <div class="modal-content">
     <div class="modal-header">
       <button (click)="alertConfirmBtn()" type="button" class="close" aria-label="Close"></button>
       <div class="d-flex align-items-start">
         <div class="align-self-center title-icon"><img src="https://cdn.hubx.com/assets/images/exclamation-mark.svg"
            width="48" height="48"/></div>
         <div class="align-self-center modal-title">{{ title }}</div>
       </div>
     </div>
     <div class="modal-body">
       <span>{{bodyText}}</span>
     </div>
     <div class="modal-footer">
       <button type="button" (click)="alertConfirmBtn()" class="btn btn-modal btn-modal-confirm">Got it</button>
     </div>
   </div>
 </div>
</div>
