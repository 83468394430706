import { OfferEventTypes } from "../enums/offer-types";

export interface IOfferNotificationMessage {
    notificationType: OfferNotificationTypesEnum;
    data: IOfferFeatureSwitchedMessage | ITokenCountUpdatedMessage |
    IOfferValidationRulesUpdatedMessage | IOfferEventMessage | IOfferSeenMessage;
}

export enum OfferNotificationTypesEnum {
    offerFeatureSwitched,
    tokenCountUpdated,
    offerValidationRulesUpdated,
    offerEvent,
    offerSeen
}

export interface IOfferFeatureSwitchedMessage {
    businessPartnerId: string;
    partnerType: string; // BUYER VENDOR
    newStatus: boolean;
    instanceId: string;
}

export interface ITokenCountUpdatedMessage {
    businessPartnerId: string;
    instanceId: string;
    tokenCount: number;
}

export interface IOfferValidationRulesUpdatedMessage {
    minAmount: number;
    maxPercentage: number;
}

export interface IOfferEventMessage {
    eventId: string;
    eventType: OfferEventTypes;
    offerId: string;
    instanceId: string;
    senderId?: string;
}

export interface IOfferSeenMessage {
    offerId: string;
    instanceId: string;
}
