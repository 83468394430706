import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { CartService } from '../../../buyer/cart/cart.service';
import { TopService } from '../../services/top.service';

@Component({
  selector: 'app-add-to-cart-modal',
  templateUrl: './add-to-cart-modal.component.html',
  styleUrls: ['./add-to-cart-modal.component.scss']
})
export class AddToCartModalComponent implements OnInit {

  @Input() shortAnimation:boolean = false;

  firstAnimation = false;
  secondAnimation = false;

  constructor(
  	public topService: TopService,
    public cartService: CartService,
    ) { }

  ngOnInit(): void {
  	setTimeout(() => {
  		this.firstAnimation = true;
  	}, 1);
  	setTimeout(() => {
  		this.secondAnimation = true;
  	}, 900);

  }

}
