<!-- <div *ngIf="topService.loading" class="loader" [style.position]="topService.isMobile?'fixed':'absolute'"></div> -->
<div *ngIf="orders && orders.length > 0" class="orders" [class.mobile]="topService.isMobile" [class.__bottom-controls]="pagination.totalCount > pagination.pageSize">
  <!-- <div class="orders-top">
    <div class="orders-top-box _credit">
      <span>Available Credit</span>
      <span>Coming Soon</span>
    </div>
    <div class="orders-top-box _open-orders">
      <span>Open Orders</span>
      <span>Coming Soon</span>
    </div>
    <div class="orders-top-box _balance">
      <span>Balance</span>
      <span>Coming Soon</span>
    </div>
    <div class="orders-top-box _past-due">
      <span>Past Due</span>
      <span>Coming Soon</span>
    </div>
  </div> -->
  <div class="orders-table">
    <div class="orders-table-heading">
      <h1>Order History</h1>
    </div>
    <div class="orders-table-header">
      <div class="orders-table-col orders-table-header__text _order">Order ID</div>
      <div class="orders-table-col orders-table-header__text _offer">Offer</div>
      <div class="orders-table-col orders-table-header__text _date">Order Date</div>
      <div class="orders-table-col orders-table-header__text _price">Total Price</div>
      <div class="orders-table-col orders-table-header__text _status">Order Status</div>
      <div class="orders-table-col orders-table-header__text __controls" [class.expand]="user_role!=='BUYER'"></div>
    </div>
    <div class="orders-table-item" *ngFor="let order of orders" (click)="!topService.loading&&!topService.isMobile?ordersService.goToFullOrderPage(order.docEntry.toString()):''">
      <div class="orders-table-col orders-table-item__value _number">{{ order.docNumber }}</div>
      <div class="orders-table-col orders-table-item__value _offer">
        <svg *ngIf="order.isLocked" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m8 0c4.3998701 0 8 3.60005748 8 7.99998344 0 4.40015386-3.6000844 8.00001656-8.00001948 8.00001656-1.49097403 0-2.98194805-.4362848-4.25454546-1.2362961-.10907142-.0727986-.25466883-.1090712-.40001298-.1090712-.0727987 0-.14534416 0-.21814286.036272l-3.12727922.9455824.98188961-3.3454476c.03627208-.1818698.03627208-.3637395-.0727987-.5453495-.58187662-1.1637639-.90909091-2.43655342-.90909091-3.74570656 0-4.40012076 3.60006494-7.99998344 8-7.99998344zm-3.55555556 6.66666667c-.73342262 0-1.33333333.59994636-1.33333333 1.33333333 0 .73342242.59994642 1.3333336 1.33333333 1.3333336.73342263.0004645 1.33333334-.5999469 1.33333334-1.3333336 0-.73342269-.59994643-1.33333333-1.33333334-1.33333333zm3.63636364 0c-.73342263 0-1.33333333.59994636-1.33333333 1.33333333 0 .73342242.59994642 1.3333336 1.33333333 1.3333336.73342263.0004645 1.33333333-.5999469 1.33333333-1.3333336 0-.73342269-.59994642-1.33333333-1.33333333-1.33333333zm3.63636362 0c-.7334226 0-1.3333333.59994636-1.3333333 1.33333333 0 .73342242.5999464 1.3333336 1.3333333 1.3333336.7334226.0004645 1.3333334-.5999469 1.3333334-1.3333336 0-.73342269-.5999465-1.33333333-1.3333334-1.33333333z" transform="translate(4 4)"/></svg>
      </div>
      <div class="orders-table-col orders-table-item__value _date">{{ order.orderDate | date:"shortDate" }}</div>
      <div class="orders-table-col orders-table-item__value _price">{{ order.orderTotal | CustomCurrency }}</div>
      <div class="orders-table-col orders-table-item__value _status">
        <span class="_bage" [ngClass]="'_' + order.orderStatus.replaceAll(' ', '')">
          {{ ordersService.toPascalCase(order.orderStatus) }}
        </span>
      </div>
      <div class="orders-table-col orders-table-item__value __controls" [class.expand]="user_role!=='BUYER'">

        <a class="is-charged" *ngIf="user_role !=='BUYER' && order?.payment?.status === 'Applied'">
          <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m20 4h-16c-1.11 0-1.99.89-1.99 2l-.01 12c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2v-12c0-1.11-.89-2-2-2zm0 14h-16v-6h16zm0-10h-16v-2h16z" fill="#1a1a1a" opacity="0.5"/></svg>
          <svg class="charged" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m17.6562267 5 2.3437733 2.47812734-10.02199678 10.52187266-5.97800322-6.2761733 2.34640057-2.46343404 3.63097573 3.80146244z" fill="#1a1a1a" opacity="0.5"/></svg>
          <span class="charged">Charged</span>
        </a>

        <a class="charge" (click)="showChargeModal(order, $event)" *ngIf="user_role ==='SUPER' && order.payment.status === 'Authorize' && !order.beingUpdated">
          <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m20 4h-16c-1.11 0-1.99.89-1.99 2l-.01 12c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2v-12c0-1.11-.89-2-2-2zm0 14h-16v-6h16zm0-10h-16v-2h16z" fill="#1a1a1a" opacity="0.5"/></svg>
          <svg class="charged" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m17.6562267 5 2.3437733 2.47812734-10.02199678 10.52187266-5.97800322-6.2761733 2.34640057-2.46343404 3.63097573 3.80146244z" fill="#1a1a1a" opacity="0.5"/></svg>
          <span>Charge</span>
        </a>

        <a (click)="!topService.loading?ordersService.openPdf($event,order.docEntry):''" [class.__shrunk]="user_role !=='BUYER'">
          <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m18.4328953 18.6176471c.8121835 0 1.4705883.6584047 1.4705883 1.4705882v.4411765c0 .8121834-.6584048 1.4705882-1.4705883 1.4705882h-11.86938194c-.81218346 0-1.47058824-.6584048-1.47058824-1.4705882v-.4411765c0-.8121835.65840478-1.4705882 1.47058824-1.4705882zm-5.4434187-16.6176471c.7614331 0 1.3786966.61726346 1.3786966 1.37869653v6.37585387c0 .2618834.1477258.3243175.3319922.13733463 0 0 1.5154141-1.53687969 2.0595829-2.0954738 1.7787415-1.80504226 4.4209813.87596025 2.6596438 2.66313727l-5.7236023 5.7606872c-.641607.6448084-1.6856471.6479874-2.3315587.0067288l-5.78340493-5.767416c-1.76133758-1.78717702.8809023-4.46817953 2.64223983-2.6810025.51105453.52484651 1.8195231 1.8525693 2.0432185 2.07950902l.0327695.03324218c.1836479.1863602.332524.1249112.332524-.13674472v-6.37585595c0-.76143307.6172635-1.37869653 1.3786965-1.37869653z" fill="#1a1a1a" opacity="0.5"/></svg>
          <span>
            Download
          </span>
        </a>
        <div class="__controls-additional" 
          *ngIf="!order.beingUpdated"
          [ngClass]="{'__unseen': !(user_role !=='BUYER' && [OrderStatusEnum.paid, OrderStatusEnum.offerAccepted, OrderStatusEnum.open].includes(order.orderStatus)) }" >
            <a (click)="updateOrderConfirmDialog(order)" [ngClass]="{'__unseen': order.beingUpdated === true || order?.payment?.status === 'Applied' ||
            (order?.payment?.status === 'Authorize' && user_role ==='SALES') || (user_role ==='SALES' && order.orderStatus === OrderStatusEnum.offerAccepted)}" >
              <img src="assets/images/icons/rebranding/edit.svg" width="24" height="24" />
            </a>
            <a (click)="splitOrder(order)" [ngClass]="{'__unseen': user_role ==='SALES' || (['Applied', 'Authorize'].includes(order?.payment?.status)) || 
              (user_role === 'SALES' && order.orderStatus === OrderStatusEnum.offerAccepted)}">
              <img src="assets/images/icons/rebranding/split.svg" width="24" height="24" />
            </a>
            <a (click)="cancelConfirm(order)" class="delete" [ngClass]="{'__unseen': user_role ==='SALES' || (['Applied', 'Authorize'].includes(order?.payment?.status)) || 
             (user_role === 'SALES' && order.orderStatus === OrderStatusEnum.offerAccepted)}">
                <img src="assets/images/icons/rebranding/delete.svg" width="24" height="24" />
            </a>
          </div>
      </div>
    </div>
  </div>

  <div class="orders-bottom-controls" *ngIf="pagination.totalCount > pagination.pageSize">
    <ng-container *ngIf="topService.isMobile">
       <pagination
      [totalItems]="pagination.totalCount"
      [itemsPerPage]="pagination.pageSize" (pageChanged)="pageChanged($event)"
      [boundaryLinks]="true"
      [rotate]="true"
      [maxSize]="5"
      [customFirstTemplate]="firstTemplate"
      [customLastTemplate]="lastTemplate"
      [customPreviousTemplate]="prevTemplate"
      [customNextTemplate]="nextTemplate">
      </pagination>

      <ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
        <img src="assets/images/icons/pagination/24-forward-black.svg">
      </ng-template>

      <ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
        <img src="assets/images/icons/pagination/24-back-black.svg">
      </ng-template>

      <ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
        <img src="assets/images/icons/pagination/24-doubleforward-black.svg">
      </ng-template>

      <ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
        <img src="assets/images/icons/pagination/24-doubleback-black.svg">
      </ng-template>
    </ng-container>

    <ng-container *ngIf="!topService.isMobile">
      <pagination
      [totalItems]="pagination.totalCount"
      [itemsPerPage]="pagination.pageSize" (pageChanged)="pageChanged($event)"
      [boundaryLinks]="true"
      [rotate]="false"
      [maxSize]="5">
      </pagination>
    </ng-container>
  </div>

 <!--  <td (click)="!topService.loading?goToFullOrderPage(order):''" style="text-align:center;">
      <a *ngIf="order.orderStatus.toLowerCase() === 'open' && (!order.beingUpdated || user_role === 'BUYER')" class="btn open">{{ toPascalCase(order.orderStatus) }}</a>
      <a *ngIf="order.orderStatus.toLowerCase() === 'closed' && (!order.beingUpdated || user_role === 'BUYER')" class="btn processed">{{ toPascalCase(order.orderStatus) }}</a>
      <a *ngIf="order.orderStatus.toLowerCase() === 'cancelled' && (!order.beingUpdated  || user_role === 'BUYER')" class="btn cancelled">{{ toPascalCase(order.orderStatus)  }}</a>
      <a *ngIf="order.orderStatus.toLowerCase() === 'processing' && (!order.beingUpdated  || user_role === 'BUYER')" class="btn open">{{ toPascalCase(order.orderStatus) }}</a>
      <div  _ngcontent-c14="" class="wrapper eta-svg">
        <div _ngcontent-c14="" class="tooltip white locked-by">
          <span>locked by: {{order.updatedByName}} {{order.updateByUser}}</span>
        </div>
        <a *ngIf="(user_role === 'SALES' || user_role === 'SUPER') && order.beingUpdated && !(order.canCancelUpdate)" class="btn update">Locked</a>
      </div>
      <a   (click)="uncLockMe()"  *ngIf="(user_role === 'SALES' || user_role === 'SUPER') && order.beingUpdated && order.canCancelUpdate" class="btn update">Unlock</a>
    </td> -->

  <!-- EDIT ORDER CONFIRM POPUP -->
  <div  class="confirm-modal" *ngIf="showModal || showCancelModal">
    <div class="confirm-modal-wrap animated" [class.zoomIn]="animateIn" [class.zoomOut]="animateOut">
      <div class="confirm-modal-message">
          <span class="confirm-modal-message__span" [class.__error]="cancelError">
            {{ popUpTextMessages() }}
          </span>
      </div>

      <div class="confirm-modal-buttons">
        <button *ngIf="showModal" (click)="closeModal()" class="confirm-modal-buttons__cancel" [disabled]="topService.loading" >Cancel</button>
        <button *ngIf="showCancelModal" [ngClass]="{'hide': cancelError}" (click)="closeCancelConfirm()" class="confirm-modal-buttons__cancel" [disabled]="topService.loading">No</button>

        <button *ngIf="showModal" class="confirm-modal-buttons__continue" (click)="updateOrder();" [disabled]="topService.loading">Continue</button>
        <button *ngIf="showCancelModal" [ngClass]="{'hide': cancelError}" (click)="cancelOrderYes()" class="confirm-modal-buttons__continue" [disabled]="topService.loading">Yes</button>

        <button *ngIf="cancelError" (click)="closeCancelConfirm()" class="confirm-modal-buttons__continue" [disabled]="topService.loading">Got it</button>
      </div>

    </div>
  </div>

</div>
<div class="no-orders" *ngIf="orders && orders.length === 0 && !topService.loading && showEmpty">
  <span class="no-orders-title">No Orders Yet!</span>
  <div class="no-orders-message">After your first order, your orders will appear here</div>
  <a  (click)="gotoShopping()" class="no-orders-btn">Continue Shopping</a>
</div>

<div class="no-orders" *ngIf="!orders && ((user_role === 'SALES' || user_role === 'SUPER' ) && !sessionService.isCustomerSelected)">
  <span class="no-orders-title">No Customer Selected</span>
  <div class="no-orders-message">Please select a customer to display their order history.</div>
  <a  (click)="gotoShopping()" class="no-orders-btn">Continue Shopping</a>
</div>

<app-error-modal [bodyText]="ordersService.ordersErrorModal.bodyText" [title]="ordersService.ordersErrorModal.title" [isError]="ordersService.ordersErrorModal.isShown" (onConfirmError)="hideError()"></app-error-modal>

<!-- Charge Modals -->
<div class="charge-modal" *ngIf="showChargeOrder">
  <div class="charge-modal-dialog">
    <div class="charge-modal-content">
      <div class="charge-modal-body">
        Charge Order # {{ chargingOrder.docNumber }}<br/>{{ chargingOrder.orderTotal | CustomCurrency }}
      </div>
      <div class="charge-modal-footer">
        <button type="button" class="button-secondary" (click)="showChargeOrder = false;">Cancel</button>
        <button type="button" class="button-primary" (click)="chargeOrder()" [disabled]="topService.loading">Charge</button>
      </div>
    </div>
  </div>
</div>

<div class="charge-modal" *ngIf="showChargedOrder">
  <div class="charge-modal-dialog">
    <div class="charge-modal-content">
      <div class="charge-modal-body">
        Order # {{ chargingOrder.docNumber }} has been <br/>successfully charged {{ chargingOrder.orderTotal | CustomCurrency }}
      </div>
      <div class="charge-modal-footer">
        <button type="button" class="button-primary" (click)="closeChargedModal()">Got it</button>
      </div>
    </div>
  </div>
</div>
<!-- Charge Modals -->
