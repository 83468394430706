import { Component, OnInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { SessionService } from '../../services/session.service';
import { UserService } from '../../user/user.service';
import { GuestService } from '../../shared/services/guest.service';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { IBuyerErrorModal } from '../../buyer/interfaces/IBuyerErrorModal';
import { CartService } from '../../buyer/cart/cart.service';
import { environment } from '../../../environments/environment';
import { ConnectionManagerService } from '../../shared/services/signalR/connection-manager.service';
import { eConnectionType } from '../../shared/services/signalR/connection-type';
import { ItemsService } from '../../shared/services/items.service';
import { MainTopComponent } from '../main-top.component';

@Component({
  selector: 'app-main-top-mobile',
  templateUrl: './main-top-mobile.component.html',
  styleUrls: ['./main-top-mobile.component.scss']
})
export class MainTopMobileComponent extends MainTopComponent implements OnInit, AfterViewChecked {

}
