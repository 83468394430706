import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuctionsService } from '../auctions.service';
import { TopService } from '../../../shared/services/top.service';

@Component({
  selector: 'app-my-bids',
  templateUrl: './my-bids.component.html',
  styleUrls: ['./my-bids.component.scss']
})
export class MyBidsComponent implements OnInit, OnDestroy {

  constructor(private _auctionsService: AuctionsService,
    public topService: TopService) { }

  ngOnInit() {
    // this._auctionsService.isGridView = false;
    this._auctionsService.hideChangeViews = true;
  }

  ngOnDestroy() {
    // this._auctionsService.isGridView = true;
    this._auctionsService.hideChangeViews = false;

  }

}
