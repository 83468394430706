import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Email } from '../buyer/interfaces/Email';

@Injectable()
export class EmailService {


  constructor(private http: HttpClient) {
  }

  public sendEmail(from, to, subject, htmlContent, sendEmail = true): Observable<null> {
    const token = sessionStorage.getItem('access_token');
    const url = environment.adminUrl + 'mails/partner';
    const email_params = {
      From: from,
      Recipient: to,
      Subject: subject,
      HtmlContent: htmlContent,
      SendToCustomer: sendEmail
    };
      return this.http.post<null>(url, email_params);
  }

  public send(mail: Email): Observable<null> {
    const endPoint = 'mails';
    const url = `${environment.adminUrl}${endPoint}`;
    return this.http.post<null>(url, mail);
  }
}
