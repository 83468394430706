import { Component, OnInit, Input } from '@angular/core';
import { TopService } from '../../../services/top.service';

@Component({
  selector: 'app-items-preloader-mobile',
  templateUrl: './items-preloader-mobile.component.html',
  styleUrls: ['../items-preloader.component.scss']
})
export class ItemsPreloaderMobileComponent implements OnInit {

  @Input() isFromPastPurchases: boolean = false;
  @Input() isFromJustSold: boolean = false;
  @Input() isFromNotificationCenter: boolean = false;
  preloaderItemList = Array(4);

  constructor(
  	public topService: TopService,
  	) { }

  ngOnInit(): void {
  }

}
