import { Component, OnInit, Input, ViewChildren, QueryList, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NotificationCenterBuyerService } from '../notification-center.service';
import { IItem, eNotificationStatusBuyer, INotificationDetailBuyer } from '../../interfaces/IItem';
import { NotificationTypesEnum, eNotificationAction, NotificationFiltersEnum } from '../../../shared/interfaces/INotificationDetail';
import { TopService } from '../../../shared/services/top.service';
import * as _ from 'lodash';
import { SharedService } from '../../../shared/shared.service';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';
import { ItemsService } from '../../../shared/services/items.service';
import { environment } from '../../../../environments/environment';
import { ListingViewItemComponent } from '../../../shared/listing-view-item/listing-view-item.component';
import { IBuyerErrorModal } from '../../interfaces/IBuyerErrorModal';
import { ListingViewItemMobileComponent } from '../../../shared/listing-view-item/listing-view-item-mobile/listing-view-item-mobile.component';


@Component({
  selector: 'app-notification-center-items-list',
  templateUrl: './notification-center-items-list.component.html',
  styleUrls: ['./notification-center-items-list.component.scss']
})
export class NotificationCenterItemsListComponent implements OnInit, OnDestroy {
  @Input() items: IItem[];
  @Input() currentNotFilter: NotificationFiltersEnum;
  @Output() updatePagination = new EventEmitter<any>();
  @ViewChildren(ListingViewItemComponent) listItemComponents: QueryList<ListingViewItemComponent>;
  @ViewChildren(ListingViewItemMobileComponent) listItemMobileComponents: QueryList<ListingViewItemMobileComponent>;

  NotificationFiltersEnum = NotificationFiltersEnum;
  NotificationTypesEnum = NotificationTypesEnum;
  eNotificationStatusBuyer = eNotificationStatusBuyer;
  eNotificationAction = eNotificationAction;

  itemListErrorModal: IBuyerErrorModal = {
    bodyText: '',
    title: '',
    isShown: false
  };
  selectedItem: IItem;
  showDetailedPopUp = false;
  isMobile = false;
  isShowGradingGuide = false;
  showNotificationTooltip = false;
  notificationItem: IItem = {};
  tooltipPosition: Event;
  showWaitlistTooltip = false;
  waitlistItem: IItem = {};
  waitlistTooltipPosition: Event;
  showRestrictedCountryPopup = false;

  @Output() selected = new EventEmitter<boolean>();

  constructor(private notifCenterBuyer: NotificationCenterBuyerService,
    public topService: TopService,
    public sharedService: SharedService,
    private pubSubService: PubSubService,
    public itemsService: ItemsService) { }

  ngOnInit() {
    this.pubSubService.sharedSubject.subscribe(
      mySharedValues => {
        if (mySharedValues.name === SharedSource.mobileSize) {
          this.isMobile = mySharedValues.data;
          this.itemsService.hideText = this.isMobile;
        }
        if (mySharedValues.name === SharedSource.itemRestrictedForCountry) {
          this.showRestrictedCountryPopup = true;
          this.selectedItem = mySharedValues.data;
        }
      });
    this.isMobile = this.topService.isMobile;
  }  

  manageNotification(item: IItem, detail: INotificationDetailBuyer, action: eNotificationAction) {
    this.topService.loading = true;
    const type = detail.notificationType;
    const id = item.id;

    this.notifCenterBuyer.notifyMe(type, action, id)
      .subscribe(
        (data) => {
          let count = 0;
          for (let i = 0; i < 3; i++) {
            if (item.notificationDetails[i].notificationType === type) {
              item.notificationDetails[i] = {
                isNew: null,
                notificationType: type,
                notificationStatus: null,
                notificationValue: null,
                timeStamp: null,
              };

              if (action === eNotificationAction.remove) {
                if (type === NotificationTypesEnum.priceDrop) {
                  item.notificationInfoObj.priceDrop = false;
                  item.notificationInfoObj.priceDropInitialValue = false;
                } else if (type === NotificationTypesEnum.moreInventory) {
                  item.notificationInfoObj.moreInventory = false;
                  item.notificationInfoObj.moreInventoryInitialValue = false;
                } else if (type === NotificationTypesEnum.waitlist) {
                  item.notificationInfoObj.waitlist = false;
                  item.notificationInfoObj.waitlistInitialValue = false;
                }
              }

              count++;
            } else if (item.notificationDetails[i].notificationStatus === null) {
              count++;
            }
          }
          if (count === 3) {
            _.remove(this.items, function (it) {
              return it.id === item.id;
            });
          }
          this.topService.loading = false;
          this.updatePagination.emit();
        },
        (err) => { this.topService.loading = false; }
      );
  }

  getNotificationType(filter) {
    return this.notifCenterBuyer.getNotificationType(filter);
  }

  toggleActions(event: MouseEvent): void {
    (event.target as HTMLElement).classList.toggle('_shown-actions');
  }

  removeItem(itemId) {
    _.remove(this.items, function (it) {
      return it.id === itemId;
    });
  }

  openitemDetailedMode(item: IItem): void {
    this.selectedItem = item;
    this.showDetailedPopUp = true;
    this.sharedService.isMobileSortingPopup = true;
  }

  hideDetailedPopUp(): void {
    this.showDetailedPopUp = false;
    this.sharedService.isMobileSortingPopup = false;
    this.selectedItem = { id: '' };
  }

  openGradingGuide(item: IItem): void {
    this.selectedItem = item;
    this.sharedService.gradingGuideHtmlPath = environment.imageBaseUrl + item.gradingGuideUrl + 'index6.html';
    this.sharedService.isGradingGuideOpen = true;
  }

  alertConfirmBtn(): void {
    event.preventDefault();
    event.stopPropagation();
    this.sharedService.isGradingGuideOpen = false;
    this.isShowGradingGuide = false;
    if (!this.showDetailedPopUp) {
      this.selectedItem = { id: '' };
    }
  }

  showNotification(tooltip): void {
    // console.log(tooltip.event);
    this.showNotificationTooltip = false;
    setTimeout(() => {
        this.notificationItem = tooltip.item;
        this.tooltipPosition = tooltip.event;
        this.showNotificationTooltip = true;
    }, 100);
  }

  hideNotificationTooltip(): void {
    this.showNotificationTooltip = false;
  }

  showError(errorModal: IBuyerErrorModal): void {
    this.itemListErrorModal = errorModal;
  }

  hideError(): void {
    this.itemListErrorModal.isShown = false;
  }

  showWaitlist(tooltip): void {
    if (tooltip.isShowWaitlist) {
      this.waitlistItem = tooltip.item;
      this.waitlistTooltipPosition = tooltip.event;
      this.showWaitlistTooltip = true;
    } else {
      this.showWaitlistTooltip = false;
    }
  }

  hideWaitlistTooltip(): void {
    this.showWaitlistTooltip = false;
  }

  addToCart(event: Event) {
    this.itemsService.closeExwPopup(event);

    if ( !this.topService.isMobile ){
      const listingItemCmp = this.listItemComponents.find(lItem => {
        return this.itemsService.selectedItemExwPopup &&
        lItem.item.id === this.itemsService.selectedItemExwPopup.id;
      });

      if ( listingItemCmp ) {
        listingItemCmp.addToCart(true);
      }
    } else {
      const listingItemMobileCmp = this.listItemMobileComponents.find(lItem => {
       return this.itemsService.selectedItemExwPopup &&
       lItem.item.id === this.itemsService.selectedItemExwPopup.id;
      });

      if ( listingItemMobileCmp ) {
        listingItemMobileCmp.addToCart(true);
      }

    }

  }

  onSelect(item){
    item.isSelected = !item.isSelected;
    this.selected.emit(item.isSelected);
  }

  closeRestrictedCountryPopup(){
    this.showRestrictedCountryPopup = false;
    
    if (!this.showDetailedPopUp) {
      this.selectedItem = { id: '' };
    }    
  }

  ngOnDestroy() {
    this.selectedItem = undefined;
  }

}


