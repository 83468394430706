import { NgModule, Optional, SkipSelf } from '@angular/core';

import { UtilityService } from './utility.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { PubSubService } from './pubsub.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        UtilityService,
        PubSubService
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
