import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
   @Output() confirmed: EventEmitter<any> = new EventEmitter<any>();
   @Input() message: string[];
  animateOut;

  constructor() { }

  ngOnInit() { }

  confirm(flag: boolean): void {
    setTimeout(() => {
      this.confirmed.emit(flag);
    }, 200);
    this.animateOut = true;
  }

}
