
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CartService } from '../cart.service';
import { ItemsService } from '../../../shared/services/items.service';
import { TopService } from '../../../shared/services/top.service';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';
import { environment } from '../../../../environments/environment';
import { SessionService } from '../../../services/session.service';

import * as models from '../../interfaces/model';
import { SharedService } from '../../../shared/shared.service';
import { ICartItem } from '../../interfaces/model';
import { isString } from 'util';

@Component({
  selector: 'app-pre-cart',
  templateUrl: './pre-cart.component.html',
  styleUrls: ['./pre-cart.component.scss']
})
export class PreCartComponent implements OnInit {
  showDetailsActive = -1;
  itemDescription = '';
  currentIndex: number;
  cartIdfromLs: string;
  user_role: string;
  selected_customer: string;
  deleteSpinner = false;
  deleteSpinnerItem: string;

  switchCatalog = true;
  precartExpand = true;

  errorModal: models.IBuyerErrorModal = {
    bodyText: '',
    title: 'Server Error',
    isShown: false
  };

  constructor(
    public cartService: CartService,
    private router: Router,
    public itemsService: ItemsService,
    public topService: TopService,
    private pubSubService: PubSubService,
    private sessionService: SessionService,
    private sharedService: SharedService
  ) {
    this.cartIdfromLs = this.sessionService.newCartId;
  }

  ngOnInit() {
    this.pubSubService.sharedSubject.subscribe(myEvent => {
      if (myEvent.name === SharedSource.catalogViewSwitch) {
        if (myEvent.data === 'L') {
          this.switchCatalog = true;
          this.precartExpand = false;
        }
        if (myEvent.data === 'E') {
          this.switchCatalog = false;
          this.precartExpand = true;
        }
      }
    });

    // this.topService.loading = true;

    // GET CART AFTER LOGIN IF THIS IS BUYER
    this.selected_customer = this.sessionService.selectedCustomer;
    this.user_role = this.sessionService.userRole;

    if (this.user_role === 'BUYER' || (this.selected_customer && (this.user_role === 'SALES' || this.user_role === 'SUPER'))) {
      this.cartService.getcartItems().subscribe(
        (data) => {
          // this.topService.loading = false;
          if (data) {
            this.cartService.cartList = data;
          }
        },
        (err) => {
          // this.topService.loading = false;
          if (err.status === 400) {
            this.cartService.erroronline = true;
          }

          this.sharedService.handleBuyerHttpError(err, this.errorModal);
        }
      );
    }
  }

  getTotal(): number {
    let total = 0;
    for (let i = 0; i < this.cartService.cartList.length; i++) {
      total = total + (this.cartService.cartList[i].quantity * this.cartService.cartList[i].unitPrice);
    }

    return total;
  }

  deletePreCartItem(preCartItem: ICartItem): void {
    this.deleteSpinner = true;
    this.deleteSpinnerItem = preCartItem.id;

    this.cartService.deleteFromCart(preCartItem.lineSequence).subscribe(
      (data) => {
        this.cartService.cartList = data;
        this.removeInCartAttributeFromItem(preCartItem);
        this.topService.loading = false;
        this.deleteSpinner = false;
        this.deleteSpinnerItem = '';
      },
      (err) => {
        this.topService.loading = false;
        this.deleteSpinner = false;
        this.deleteSpinnerItem = '';
        this.cartService.resetCart();
        this.sharedService.handleBuyerHttpError(err, null);
      }
    );
  }

  removeInCartAttributeFromItem(preCartItem: ICartItem): void {
    if ( this.itemsService.itemsData ) {
    for (let i = 0; i < this.itemsService.itemsData.length; i++) {
      if (preCartItem.item.id === this.itemsService.itemsData[i].id) {
        this.itemsService.itemsData[i].inCart = false;
        this.itemsService.itemsData[i].added = false;
        this.itemsService.itemsData[i].beingEdited = false;
        this.itemsService.itemsData[i].qty = this.itemsService.itemsData[i].moq ? this.itemsService.itemsData[i].moq : 1;
        this.itemsService.updateItemPrice(this.itemsService.itemsData[i]);
        break;
      }
    }
  }
  }


  prepareErrorMessages(error400: string): void {

    let filter = error400;
    if (error400.startsWith('This item has a Max Unit Quantity')) {
      filter = 'This item has a Max Unit Quantity';
    } else if (error400.startsWith('Quantity must be equal or greater than Minimum Order Quantity of')) {
      filter = 'Quantity must be equal or greater than Minimum Order Quantity of';
    }

    const newError = environment.errorMapping.filter(c => c.server.startsWith(filter));
    if (newError && newError.length > 0) {
      this.errorModal.bodyText = error400;
      this.errorModal.title = newError[0].client.title;
      this.errorModal.isShown = true;
    } else {
      this.errorModal.title = 'Important';
      this.errorModal.bodyText = error400;
    }

    this.topService.loading = false;
  }

  gotoCheckout(): void {
    if (this.cartService.cartList.length) {
      this.router.navigate(['cart']);
    }
  }

  showError(errorModal: models.IBuyerErrorModal): void {

    this.errorModal = errorModal;
  }

  hideError(): void {
    this.errorModal.isShown = false;
  }

  updateQty(cartItem: models.ICartItem, newQty: number): void {
    if (cartItem.quantity.toString() === '0') {
      this.errorModal.title = 'Quantity Error';
      this.errorModal.bodyText = 'Quantity must be more than 0.';
      this.errorModal.isShown = true;
      // change back to previous qty.
      cartItem.quantity = cartItem.item.qty ? cartItem.item.qty : 1;
      return;
    } else if (cartItem.item.moq && newQty < cartItem.item.moq) {
      this.errorModal.title = 'Quantity Error';
      this.errorModal.bodyText = `This item has a minimum order quantity of ${cartItem.item.moq}. Please adjust accordingly.` ;
      this.errorModal.isShown = true;
      cartItem.quantity = cartItem.item.qty ? cartItem.item.qty : (cartItem.item.moq ? cartItem.item.moq : 1);
      return;
    } else if (cartItem.item.mxq && newQty > cartItem.item.mxq) {
      this.errorModal.title = 'Quantity Error';
      this.errorModal.bodyText = `This item has a maximum order quantity of ${cartItem.item.mxq}. Please adjust accordingly.` ;
      this.errorModal.isShown = true;
      cartItem.quantity = cartItem.item.qty ? cartItem.item.qty : ( cartItem.item.moq ? cartItem.item.moq : 1 );
      return;
    }

    cartItem.unitPrice = this.cartService.handleQuantityUpdate(cartItem);
    cartItem.item.baseUnitPrice = cartItem.unitPrice;

    // if the overwrittenPrice  ===  unitPrice we remove the overwrittenPrice
    if (!cartItem.overwrittenPrice && cartItem.overwrittenPrice === cartItem.unitPrice) {
      cartItem.overwrittenPrice = null;
    }


    this.topService.loading = true;
    this.cartService.updateCart(cartItem.lineSequence, newQty, cartItem.overwrittenPrice).subscribe(
      data => {
        cartItem.item.added = true;
        cartItem.item.previousQuantityAdded = cartItem.item.qty;
        cartItem.item.inCart = true;
        cartItem.item.qty = cartItem.quantity;
        cartItem.item.baseUnitPrice = cartItem.unitPrice;


        this.cartService.cartList = data;
        // this.itemsService.updateItemPrice(cartItem.item);
        this.updateItemCatalogData(cartItem.item);
        this.updateCartItemQty(cartItem);
        this.sharedService.itemNewPrice = cartItem.item;
        this.topService.loading = false;
      },
      err => {
        if (err.status === 400) {
          this.prepareErrorMessages(err.error);
          cartItem.quantity = cartItem.previousITemQuantity;
          this.errorModal.isShown = true;
          const message = isString(err.error) ? err.error : err.error.message;
          if (message.includes('Need to add')) {
            this.cartService.resetCart();
          }
          this.errorModal.title = 'Cart Is Not Valid';
          this.errorModal.bodyText = message;
          return;
        }

        this.topService.loading = false;
        this.sharedService.handleBuyerHttpError(err, this.errorModal, true);
        return;
      }
    );
  }

  updateItemCatalogData(item: models.IItem): void {
    const items = this.itemsService.itemsData;

    for (let i = 0; i < items.length; i++) {
      if (items[i].id === item.id) {
        items[i].added = true;
        items[i].inCart = true;
        items[i].qty = item.qty;
        items[i].previousQuantityAdded = item.previousQuantityAdded;
        items[i].unitPrice = item.unitPrice;
        return;
      }
    }
  }

  updateCartItemQty(cartItem: models.ICartItem): void {
    for (let i = 0; i < this.cartService.cartList.length; i++) {
      if (cartItem.id === this.cartService.cartList[i].id) {
        this.cartService.cartList[i].quantity = cartItem.quantity;
        this.cartService.cartList[i].item.qty = cartItem.quantity;
      }
    }
  }
}
