import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuctionHeaderComponent } from '../auction-header.component';
import { ISortOptionsAuctions } from '../../../../buyer/interfaces/ISortOptionsAuctions';
import { SortAuctionsTypesEnum } from '../../../../buyer/enums/sortAuctionsTypesEnum';

@Component({
  selector: 'app-auction-header-mobile',
  templateUrl: './auction-header-mobile.component.html',
  styleUrls: ['./auction-header-mobile.component.scss']
})
export class AuctionHeaderMobileComponent extends AuctionHeaderComponent implements OnInit {
  selectedSort = '';
  defaultSort: ISortOptionsAuctions = {Text: '', Value: SortAuctionsTypesEnum.BrowseOptions, Selected: true};

  changeSortByValue(val) {
    const sortItem = this.auctionsService.sortOptions.find(s => s.Value === val);

    this.onSortOptionSelected(sortItem ? sortItem : this.defaultSort);
  }

  hideFilter(){
  	document.documentElement.classList.remove('is-modal');
  	this.auctionsService.showMobileHeader = false;
  }

  ngOnDestroy() {
    document.documentElement.classList.remove('is-modal');
  	this.auctionsService.showMobileHeader = false;
  }
}
