<div class="activity-box-wrap">
  <div class="title">Selling Activity</div>

  <table>
    <thead>
      <tr>
        <th></th>
        <th class="text-right"></th>
      </tr>
    </thead>

      <tbody>
        <tr>
          <td>
            <span class="date">Jul 23, 2018 12:40 PM</span>
            <div class="clearfix"></div>
            <span class="status-1">Item 35685 Rejected</span>
          </td>
          <td class="text-right">
            <span class="s-icon">
              <img src="../../../assets/images/vendor-icons/dashboard/activity/rejected.svg" width="24px" height="24px"/>
            </span>
          </td>
        </tr>

        <tr>
            <td>
                <span class="date">Jul 23, 2018 12:40 PM</span>
                <div class="clearfix"></div>
                <span class="status-1">PO 1232 Shipped</span>
              </td>
              <td class="text-right">
                <span class="s-icon shipped">
                  <img src="../../../assets/images/vendor-icons/dashboard/activity/shipped.svg" width="24px" height="24px"/>
                </span>
              </td>
        </tr>

        <tr>
            <td>
                <span class="date">Jul 23, 2018 12:40 PM</span>
                <div class="clearfix"></div>
                <span class="status-1">Item 300007 Approved</span>
              </td>
              <td class="text-right">
                <span class="s-icon approved">
                  <img src="../../../assets/images/vendor-icons/dashboard/activity/approved.svg" width="24px" height="24px"/>
                </span>
              </td>
        </tr>

       <tr>
          <td>
              <span class="date">Jul 23, 2018 12:40 PM</span>
              <div class="clearfix"></div>
              <span class="status-1">Item 300007 (1,000) Units Sold</span>
            </td>
            <td class="text-right">
              <span class="s-icon units-sold">
                <img src="../../../assets/images/vendor-icons/dashboard/activity/units-sold.svg" width="24px" height="24px"/>
              </span>
            </td>
        </tr>

        <tr>
            <td>
                <span class="date">Jul 23, 2018 12:40 PM</span>
                <div class="clearfix"></div>
                <span class="status-1">Item 2342-22 Cost reduced from $100 to $95</span>
              </td>
              <td class="text-right">
                <span class="s-icon reduced">
                  <img src="../../../assets/images/vendor-icons/dashboard/activity/price-down.svg" width="24px" height="24px"/>
                </span>
              </td>
        </tr>

        <tr>
            <td>
                <span class="date">Jul 23, 2018 12:40 PM</span>
                <div class="clearfix"></div>
                <span class="status-1">Item 35685 Rejected</span>
              </td>
              <td class="text-right">
                <span class="s-icon">
                  <img src="../../../assets/images/vendor-icons/dashboard/activity/rejected.svg" width="24px" height="24px"/>
                </span>
              </td>
       </tr>

       <tr>
          <td>
              <span class="date">Jul 23, 2018 12:40 PM</span>
              <div class="clearfix"></div>
              <span class="status-1">PO 1232 Shipped</span>
            </td>
            <td class="text-right">
              <span class="s-icon">
                <img src="../../../assets/images/vendor-icons/dashboard/activity/rejected.svg" width="24px" height="24px"/>
              </span>
            </td>
      </tr>

        <tr>
            <td>
                <span class="date">Jul 23, 2018 12:40 PM</span>
                <div class="clearfix"></div>
                <span class="status-1">Item 300007 Approved</span>
              </td>
              <td class="text-right">
                <span class="s-icon approved">
                  <img src="../../../assets/images/vendor-icons/dashboard/activity/approved.svg" width="24px" height="24px"/>
                </span>
              </td>
        </tr>

        <tr>
            <td>
                <span class="date">Jul 23, 2018 12:40 PM</span>
                <div class="clearfix"></div>
                <span class="status-1">Item 35685 Rejected</span>
              </td>
              <td class="text-right">
                <span class="s-icon">
                  <img src="../../../assets/images/vendor-icons/dashboard/activity/rejected.svg" width="24px" height="24px"/>
                </span>
              </td>
        </tr>
      </tbody>
  </table>

</div>



<!-- <div class="activity-box-wrap">
  <div class="title">Selling Activity</div>
  <table>
    <thead>
      <tr>
        <th></th>
        <th class="text-right"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let sale of salesActivity">
        <td>
          <span class="date">{{sale.date}}</span>
          <div class="clearfix"></div>
          <span class="status-1">{{ (sale.category | titlecase) + ' ' + sale.id + ' ' + (sale.status | titlecase) }}</span>
        </td>
        <td class="text-right">
          <span class="s-icon" [ngClass]="{'rejected': sale.status === 'rejected','approved': sale.status === 'approved', 'shipped': sale.status === 'shipped' }">
            <img *ngIf="sale.status === 'rejected'" src="../../../assets/images/vendor-icons/dashboard/activity/rejected.svg" width="24px" height="24px" />
            <img *ngIf="sale.status === 'shipped'" src="../../../assets/images/vendor-icons/dashboard/activity/shipped.svg" width="24px" height="24px" />
            <img *ngIf="sale.status === 'approved'" src="../../../assets/images/vendor-icons/dashboard/activity/approved.svg" width="24px" height="24px" />
            <img *ngIf="sale.status === 'unitsSold'" src="../../../assets/images/vendor-icons/dashboard/activity/units-sold.svg" width="24px" height="24px" />
            <img *ngIf="sale.status === 'priceDown'" src="../../../assets/images/vendor-icons/dashboard/activity/units-sold.svg" width="24px" height="24px" />
            <img *ngIf="sale.status === 'unitsSold'" src="../../../assets/images/vendor-icons/dashboard/activity/price-down.svg" width="24px" height="24px" />
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div> -->