import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { IDashboardProductSelling } from '../../interface/IDashboardProductSelling';

@Component({
  selector: 'app-sales-on-hubx',
  templateUrl: './sales-on-hubx.component.html',
  styleUrls: ['./sales-on-hubx.component.scss']
})
export class SalesOnHubxComponent implements OnInit {
  @Input() products: IDashboardProductSelling[];

  constructor(private dashboardService: DashboardService) { }

  ngOnInit() {
  }
}
