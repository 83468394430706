import { environment } from '../../environments/environment';

interface AuthConfig {
  clientID: string;
  domain: string;
  adminDomain: string;
  callbackURL: string;
  callbackURL_localHost: string;
  realm: string;
  scope: string;
}

export const AUTH_CONFIG: AuthConfig = {
  clientID: environment.hubx_auth_clientId,
  domain: environment.hubx_auth_domain,
  adminDomain: environment.hubx_auth_apiUrl,
  callbackURL: environment.hubx_auth_RedirectUrl,
  callbackURL_localHost: environment.hubx_auth_RedirectUrl_LocalHost,
  realm: environment.hubx_auth_realm,
  scope: environment.hubx_auth_scope,
};
/* export const AUTH_CONFIG: AuthConfig = {
  clientID: environment.auth0_ClientID,
  domain: environment.auth0_Domain,
  callbackURL: environment.auth0_RedirectUrl,
  callbackURL_localHost: environment.auth0_RedirectUrl_LocalHost,
  realm: environment.auth0_realm
}; */
