<div class="notification-popup">
  <form class="notification-popup__form" [formGroup]="fg">
  <div class="notification-popup-left" [class.decline-notifyin]="isShowDeclineNotifying">
      <div class="notification-popup-left__bg"></div>
      <h2>Important!</h2>
      <p>By updating your listing buyers will instantly receive an Email, SMS and notification in their HUBX buyers portal.</p>
    </div>
    <div class="notification-popup-right">
      <div *ngIf="!isShowDeclineNotifying && !isShowNotify">
        <div class="item-info">
          <div
            class="item-info__logo"
            [style.background-image]="'url(https://cdn.hubx.com' + notification.vendorItem.manufacturerLogoUrl + ')'"
          ></div>
          <div class="item-info__desc">
            <p class="desc">{{ notification.vendorItem.description }}  -
              <span class="copy-number" (mouseover)="copied = 'Copy'" (click)=copyText($event,notification.vendorItem.vendorItemCode)>
                {{ notification.vendorItem.vendorItemCode }}
                <span>{{ copied }}</span>
              </span></p>
          </div>
        </div>
        <div class="item-management">
          <div class="item-management__left">
            <div class="item-management__left-row">
              <span class="icon">
                <img src="assets/images/vendor-icons/32-price-drop.svg" width="32" height="32" alt="price drop" />
              </span>
              <span class="counter">
                {{ (notification.mappedNotDetails?.priceDrop?.seenCount +
                        notification.mappedNotDetails?.priceDrop?.newCount) || 0 }}
              </span>
              <span class="label">
                Waiting for <br/>Price Drop
              </span>
            </div>
          </div>
          <div class="item-management__right">
            <div class="item-management-value">
              <div class="item-management-value__title">Price Drop</div>
              <div class="item-management-value__current">
                Current Price {{notification.vendorItem.cost | CustomCurrency }}
              </div>
              <div class="item-management-value-checkboxes">
                <div class="item-management-value-checkboxes__item">
                  <label class="value-checkbox">
                    <input id="no-pricedrop" type="checkbox" [value]="true" formControlName="pdActionNo">
                    <span class="value-checkbox__label _no">
                      <span class="_icon"></span>No
                    </span>
                  </label>
                </div>
                <div class="item-management-value-checkboxes__item">
                  <label class="value-checkbox">
                    <input id="yes-pricedrop" type="checkbox" [value]="true" formControlName="pdActionYes">
                    <span class="value-checkbox__label _yes">
                      <span class="_icon"></span>Yes
                    </span>
                  </label>
                </div>
              </div>
              <div class="control-input" *ngIf="fg.value.pdActionYes">
                <label for="" class="control-input__label">Updated Price</label>
                <input type="number" name="price-drop" autocomplete="off"
                      [placeholder]="notification.vendorItem.cost" formControlName="pdValue">
                <span class="error-message">{{ errors }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item-management">
          <div class="item-management__left">
            <div class="item-management__left-row">
              <span class="icon">
                <img src="assets/images/vendor-icons/32-back-to-stock.svg" width="32" height="32" alt="inventory" />
              </span>
              <span class="counter">
                {{ (notification.mappedNotDetails?.waitlist?.seenCount +
                        notification.mappedNotDetails?.waitlist?.newCount) || 0}}
              </span>
              <span class="label">
                Waiting for <br/>Inventory
              </span>
            </div>
            <div class="item-management__left-row">
              <span class="icon">
                <img src="assets/images/vendor-icons/32-more-inventory.svg" width="32" height="32" alt="more inventory" />
              </span>
              <span class="counter">
                {{ (notification.mappedNotDetails?.moreInventory?.seenCount +
                        notification.mappedNotDetails?.moreInventory?.newCount) || 0 }}
              </span>
              <span class="label">
                Waiting for <br/>More Inventory
              </span>
            </div>
          </div>
          <div class="item-management__right">
            <div class="item-management-value">
              <div class="item-management-value__title">Update Inventory</div>
              <div class="item-management-value__current">
                Current Inventory {{ notification.vendorItem.inventoryInfo.currentInventory.availableToSell }}
              </div>
              <div class="item-management-value-checkboxes">
                <div class="item-management-value-checkboxes__item">
                  <label class="value-checkbox">
                    <input id="no-inventory" type="checkbox" [value]="true" formControlName="uIActionNo">
                    <span class="value-checkbox__label _no">
                      <span class="_icon"></span>No
                    </span>
                  </label>
                </div>
                <div class="item-management-value-checkboxes__item">
                  <label class="value-checkbox">
                    <input id="yes-inventory" type="checkbox" [value]="true" formControlName="uIActionYes">
                    <span class="value-checkbox__label _yes">
                      <span class="_icon"></span>Yes
                    </span>
                  </label>
                </div>
              </div>
              <div class="control-input" *ngIf="fg.value.uIActionYes">
                <label for="" class="control-input__label">Updated Inventory</label>
                <input type="number" name="inventory" autocomplete="off"
                    [placeholder]="notification.vendorItem.inventoryInfo.currentInventory.availableToSell"
                    formControlName="uIValue">
                <span class="error-message">Value has to be greater than
                  {{notification.vendorItem.inventoryInfo.currentInventory.availableToSell}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isShowDeclineNotifying" class="decline-row">
        <div>
          <h2>By selecting accept 2 buyers will be notified you are not able to meet their requests.</h2>
          <p>Do you still want to decline and notify these buyers?</p>
          <div class="bottom-controls">
            <button type="button" role="button" class="cancel" (click)="cancelDeclineNotifying()">
              <span>Back</span>
            </button>
            <button type="button" role="button" class="accept" (click)="declineNotifying()">
              <span>Accept</span>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="isShowNotify && requestsCount === 0" class="notify-row">
        <div class="col">
          <h2>You just notified {{ notifiedUsers }} Buyers!</h2>
          <p *ngIf="(fg.value.pdActionNo + fg.value.uIActionNo) && refusalsCount > 0">Including {{ refusalsCount }} notifications with a refusal.</p>
          <div class="bottom-controls">
            <button type="button" role="button" class="accept" (click)="closePopup()">
              <span>Got It!</span>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="isShowBottomControls"
        class="bottom-controls">
          <button role="button" type="button" class="cancel" (click)="closePopup()">
            <span>Cancel</span>
          </button>
          <button role="button" class="accept" (click)="notifyGate()" [disabled]="!fg.valid || fg.pristine">
            <span>Update Listing & Notify Your Buyers</span>
          </button>
      </div>
    </div>
  </form>

</div>

<app-error-modal [title]="'Warning'" 
[bodyText]="dqErrors"
[isError]="showDqWarning"
(onConfirmError)="errorPopupNotify()">
</app-error-modal>
