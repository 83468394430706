<div class="address-modal">
  <div class="add-to-wrap animated" [class.zoomIn]="isAddressOpen" [class.zoomOut]="animateOut">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" (click)="closeModal()" class="close" aria-label="Close"></button>
        <h4 class="modal-title" id="billToAddressLabel">{{dropdownTitle}}</h4>
      </div>
      <div class="modal-body">
        <div *ngFor="let address of addresses; let i = index" class="modal-address-radio"
        [ngClass]="{'active':address.addressCode === currentAddress}">
          <div class="form-group">
            <div class="address-descriptlon-block">
              <div class="address-title">
                {{address.addressCode}}
              </div>
              <div class="address-description-text">
                <p>{{address.address1}} {{address.address2}}</p>
                <p>{{address.city}} {{address.state}} {{address.zip}} {{address.country}}</p>
              </div>
            </div>
            <div class="address-radio-block">
              <input type="radio"
                     [(ngModel)]="currentAddress"
                     (click)="currentAddress=address.addressCode"
                     name="addressCode"
                     id="addressCode{{i + 1}}"
                     value="{{address.addressCode}}">
              <label for="addressCode{{i + 1}}"></label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="currentAddress=addressCode;closeModal()" class="btn btn-modal btn-modal-cancel"
          data-dismiss="modal">Cancel</button>
        <button type="button" (click)="selectAddress()" class="btn btn-modal btn-modal-confirm">Confirm</button>
      </div>
    </div>
  </div>
</div>