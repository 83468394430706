<div class="nothing-found row">
	<div class="col">
		<div class="info-block">
			<img width="64" height="64" src="assets/images/vendor-icons/64-product-list-grey.svg">
			<span>
				You have no product listings
			</span>
		</div>
	</div>
</div>

<div class="items">
	<div class="items-items" *ngFor="let item of items">
		<div class="items-item">
			<div class="item-wrapper">
				<div class="item-logo-number">
					<div class="manufacturer-logo"></div>
				</div>

				<!-- part number and status -->
				<div class="item-part-number">
					<div class="item-info-title"></div>
					<div class="input-gorizontal">
						<div class="input"></div>
					</div>

					<!-- status -->
					<div class="item-status">
						<div class="input"></div>
					</div>
				</div>

				<!-- description -->
				<div class="item-description">
					<div class="item-info-title"></div>
					<div class="input"></div>
				</div>

				<div class="item-availability">
					<!-- available -->
					<div class="item-info-title"></div>
					<div class="input-group-vertical">
						<div class="input"></div>
					</div>
				</div>
				
				<!-- ETA -->
				<div class="item-eta">
					<div class="item-info-title"></div>
					<div class="input-group-vertical">
						<div class="input"></div>
					</div>
				</div>

    		<!-- discounts -->
				<div class="item-price moq">
					<div class="item-info-title"></div>
					<div class="input-group-vertical">
						<div class="input"></div>
					</div>
				</div>
				<div class="item-price moq">
					<div class="item-info-title"></div>
					<div class="input-group-vertical">
						<div class="input"></div>
					</div>
				</div>
				<div class="item-price moq">
					<div class="item-info-title"></div>
					<div class="input-group-vertical">
						<div class="input"></div>
					</div>
				</div>

				<!-- MOQ -->
				<div class="item-price moq _left-indent">
					<div class="item-info-title"></div>
					<div class="input"></div>
				</div>

				<!-- MXQ -->
				<div class="item-price moq">
					<div class="item-info-title"></div>
					<div class="input"></div>
				</div>

				<!-- Master Carton -->
				<div class="item-price carton">
					<div class="item-info-title"></div>
						<div class="input"></div>
				</div>

				<!-- cost, price and updated -->
				<div class="align-right-block">
					<div class="item-half-block">
						<div class="item-cost">
							<div class="item-info-title"></div>
							<div class="input-price">
								<div class="input"></div>
							</div>
						</div>
						<div class="item-price">
							<div class="item-info-title"></div>
							<div class="input-price">
								<div class="input"></div>
							</div>
						</div>
					</div>
					<div class="item-half-block ctrls">
						<div class="labels">
							<div class="item-info-title"></div>
							<div class="item-info-title"></div>
						</div>
						<div class="input"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>