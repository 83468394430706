<div class="scrollable-wrap" [class.mobile]="topService.isMobile">
    <div class="main-wrap">
        <div class="decorate-header no-before">
            <div class="display-flex inner-header">
            <h1 *ngIf="!topService.isMobile">Frequently Asked Questions</h1>
            <h1 *ngIf="topService.isMobile">FAQ</h1>
            </div>
        </div>

    <div class="support-schedule-row" *ngIf="!topService.isMobile">
        <div class="wrapper">
            <div class="schedule-row-col">
                <strong>Customer Support Hours of Operation</strong>
                <p>Mon-Fri: 8:00am - 5:30pm (Eastern)</p>
            </div>
            <div class="schedule-row-col">
                <a  class="ask-question-link" (click)="navigateTo('contact-support')">Ask a New Question</a>
            </div>
        </div>
    </div>
    <div class="support-dropdown" *ngIf="topService.isMobile">
        <div class="support-dropdown_btn">Customer Popular Questions</div>
        <ul class="support-dropdown_list">
            <li data-id="vendor">Vendor Popular Questions</li>
            <li data-id="customer" class="active">Customer Popular Questions</li>
        </ul>
    </div>
    <section class="questions-section">
        <div class="wrapper">
            <div class="questions-col" data-block="customer">
                <h1 class="questions-col-title">Customer Popular Questions</h1>

            <div class="question-collapse-list">
                    <div  *ngFor="let faq of customerFaq" class="question-collapse-wrap"
                    (click)="getAnswer($event,faq)"
                    [ngClass]="{'opened':faq.open}"
                    >
                        <div class="question-collapse-icon">
                            <div class="vertical-icon"></div>
                            <div class="horizontal-icon"></div>
                        </div>
                        <strong class="question-collapse-header">{{faq.question}}</strong>
                        <div class="question-collapse-body">
                            <p>{{faq.answer}}</p>
                        </div>
                    </div>
                </div>
            </div>


            <div class="questions-col" data-block="vendor">
                <h2 class="questions-col-title">Vendor Popular Questions</h2>
                <div class="question-collapse-list">
                    <div  *ngFor="let faq of vendorFaq" class="question-collapse-wrap" (click)="getAnswer($event,faq)"
                    [ngClass]="{'opened':faq.open}"
                    >
                        <div class="question-collapse-icon">
                            <div class="vertical-icon"></div>
                            <div class="horizontal-icon"></div>
                        </div>
                        <strong class="question-collapse-header">{{faq.question}}</strong>
                        <div class="question-collapse-body">
                            <p>{{faq.answer}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="support-schedule-row" *ngIf="topService.isMobile">
        <div class="wrapper">
            <div class="schedule-row-col">
                <strong>Customer Support Hours of Operation</strong>
                <p>Mon-Fri: 8:00am - 5:30pm (Eastern)</p>
            </div>
            <div class="schedule-row-col">
                <a (click)="navigateTo('contact-support')" class="ask-question-link">Ask a New Question</a>
            </div>
        </div>
    </div>
    </div>
    <app-footer></app-footer>
</div>

