import { OfferService } from './../../user/offers/offer.service';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ITopNav } from '../../buyer/interfaces/ITopNav';
import { ItemFilterService } from '../../shared/item-filter/item-filter.service';
import { TopService } from '../../shared/services/top.service';
import { ItemsService } from '../../shared/services/items.service';
import { OutletsService } from '../outlets/outlets.service';
import { Router } from '@angular/router';
import { TopFiltersEnum } from '../enums/topFiltersEnum';
import { SharedService } from '../../shared/shared.service';
import { IJustSoldMessage } from '../../shared/interfaces/IJustSoldMessage';
import { NotificationService } from '../../shared/services/notification.service';
import * as _ from 'lodash';
import { TopCategoriesFileSaverService } from '../catalog/top-categories/top-categories-file-saver.service';
import { SessionService } from '../../services/session.service';
import { CartService } from '../cart/cart.service';
import { Location } from '@angular/common';
import { IDropdown } from '../../vendor/interface/IDropdown';
import { UserService } from '../../user/user.service';
import { PubSubService } from '../../core/pubsub.service';
import { NotificationCenterBuyerService } from '../notification-center/notification-center.service';
import { AuctionsService } from '../auctions/auctions.service';
import { GuestService } from '../../shared/services/guest.service';
import { sortTypesEnum } from '../../shared/enums/sort-types';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dropdown-categories',
  templateUrl: './dropdown-categories.component.html',
  styleUrls: ['./dropdown-categories.component.scss']
})
export class DropdownCategoriesComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  isCategoriesOpened = false;
  visibleCategoryId = null;
  hovered;
  hoveredIndex: number;
  visibleCategoryPosition = { top: '0px', bottom: 'auto' };
  topFiltersEnum = TopFiltersEnum;
  justSoldValues: IJustSoldMessage = { newLineItems: 0, totalLineItems: 0 };
  environment = environment;


  constructor(
    public itemFilterService: ItemFilterService,
    public topService: TopService,
    public itemsService: ItemsService,
    public outletsService: OutletsService,
    public auctionsService: AuctionsService,
    public router: Router,
    public sharedService: SharedService,
    public notificationService: NotificationService,
    public topCategoriesFileSaverService: TopCategoriesFileSaverService,
    public sessionService: SessionService,
    public cartService: CartService,
    public userService: UserService,
    public location: Location,
    public pubSubService: PubSubService,
    public notifBuyerService: NotificationCenterBuyerService,
    public guestService: GuestService,
    public offersService: OfferService
  ) { }

  ngOnInit() { }

  toggleCategories(isVisible: boolean): void {
    this.close.emit(isVisible);
  }

  resetNavigationVariables() {
    this.itemsService.resetSelectedConditions();
    this.itemsService.deleteTagAttributes();
    this.itemsService.showFilterComponent = false;
    this.itemsService.currentNavigation = undefined;
    this.itemsService.isCampainUrl = false;
    this.itemsService.exwPoints = undefined;
    this.itemsService.itemsRequest.exwPoint = null;
    this.itemsService.itemsRequest.inStock = false;
    this.topService.searchString = '';
    this.itemsService.currentSort = sortTypesEnum.none;
    this.outletsService.topcatNavName = undefined;
    this.itemsService.paginationInfo = null;

    // reset exw dropdown to default
    const exwDropdown: IDropdown = { dropdownId: 'exw', action: 'resetToDefaultTitle' };
    this.sharedService.setDropdown(exwDropdown);
  }

  parentNav(parentNav: ITopNav) {
    this.resetNavigationVariables();

    if (parentNav.navName.match(/dell|hp|apple|lenovo/i)) {
      this.itemsService.itemsRequest.categoryId = null;
      const outletName = parentNav.navName.split(' ')[0].toLowerCase();
      if (outletName) {
        if (this.itemFilterService.allManufacturers && this.itemFilterService.allManufacturers.length) {
          this.outletsService.isCalledFromMenu = true;
          const selectedOutlet = this.itemFilterService.allManufacturers
            .find(m => m.name.toLowerCase() === outletName);
          this.outletsService.manufacturersData(selectedOutlet, 'Laptops');
        } else {
          this.router.navigate([`${this.userService.getUrlByRole(true)}/outlets/`, outletName]);
        }
        this.toggleCategories(false);
        return;
      }
    } else {
      this.itemsService.selectedUrlFilterId = parentNav.filterId;
      this.itemsService.updateUrl(parentNav);
    }
    this.toggleCategories(false);
  }


  childNav(subNav: ITopNav) {
    this.resetNavigationVariables();

    if (this.hovered.navName.match(/dell|hp|apple|lenovo/i)) {
      this.itemsService.itemsRequest.categoryId = null;
      const outletName = this.hovered.navName.split(' ')[0].toLowerCase();
      if (outletName) {
        if (this.itemFilterService.allManufacturers && this.itemFilterService.allManufacturers.length) {
          this.outletsService.isCalledFromMenu = true;
          const selectedOutlet = this.itemFilterService.allManufacturers
            .find(m => m.name.toLowerCase() === outletName);
          this.outletsService.manufacturersData(selectedOutlet, subNav.navName);
        } else {
          this.router.navigate([`${this.userService.getUrlByRole(true)}/outlets/`, outletName]);
        }
        this.toggleCategories(false);
        return;
      }
    } else {
      this.itemsService.selectedUrlFilterId = subNav.filterId;
      this.itemsService.updateUrl(subNav);
    }
    this.toggleCategories(false);
  }

  setVisibleCategoryId(id: number): void {
    this.visibleCategoryId = id;
  }

  showCategorySubmenu(id: number, category, i: number, event: MouseEvent): void {
    this.hovered = category;
    this.hoveredIndex = i + 1;
    this.setVisibleCategoryId(id);
    let resultTop = 0;
    const coords = (event.target as Element).getBoundingClientRect();
    const dropdownHeight = category.navChilds ? category.navChilds.length * 56 : 0; // 56 item height
    if ((window.innerHeight - 91 - dropdownHeight) < 0) { // 91 - categories dropdown indents (header + paddings)
      this.visibleCategoryPosition.bottom = '0px';
      this.visibleCategoryPosition.top = '0px';
    } else {
      this.visibleCategoryPosition.bottom = 'auto';
      if ((window.innerHeight - coords.top - 11) < dropdownHeight) { // 11 categories bottom padding
        resultTop = window.innerHeight - dropdownHeight - 11; // 11 categories bottom padding
      } else {
        resultTop = coords.top;
      }
      this.visibleCategoryPosition.top = `${resultTop <= 80 ? 0 : resultTop - 80}px`;
    }
  }



}
