import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import * as models from '../../buyer/interfaces/model';
import { ITopNav } from '../../buyer/interfaces/ITopNav';


@Injectable()
export class LeftCategoriesService {
  restapiverb = '';
  parentNavName = '';
  childNavName = '';
  leftFilters: models.INav[];

  constructor(private http: HttpClient) { }

  getLeftNavItems(): Observable<models.LeftNav> {
    this.restapiverb = 'items/filter/navigation';
    const url = environment.buyerPortalBaseUrl + this.restapiverb;
    return this.http.get<models.LeftNav>(url)
    .pipe(
      map(navigation => {
        const currentDealNav = navigation.top.find((nav) => nav.filterId === environment.todaysDeals);
        if (currentDealNav) {
          currentDealNav.navName = environment.currentDeal.displayLabel;
          currentDealNav.url = environment.currentDeal.url;
        }
        return navigation;
      })
    );
  }

  getCampaingNavByUrl(campaingUrl): Observable<ITopNav> {
    this.restapiverb = `items/filter/guestscampaign/${campaingUrl}`;
    const url = environment.buyerPortalBaseUrl + this.restapiverb;
    return this.http.get<ITopNav>(url);
  }

  setParentandChildNavName(filterId: string): void {
    this.getLeftNavItems().subscribe(navData => {
      this.leftFilters = navData.left;
      this.leftFilters.forEach(left => {
        if (left.navChilds) {
          left.navChilds.forEach(child => {
            if (child.filterId === filterId) {
              this.parentNavName = left.navName;

              if (child.navName !== 'New' && child.navName !== 'Refurbished') {
                this.childNavName = ' - ' + child.navName;
              } else {
                this.childNavName = '';
              }
            }
          });
        }
      });
    });
  }
}
