import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  const scriptEl = window.document.createElement('script');
  scriptEl.src = '//script.crazyegg.com/pages/scripts/0106/3438.js';
  window.document.body.appendChild(scriptEl);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
