import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { SharedValues } from './shared-values';

@Injectable()
export class PubSubService {
  sharedSubject: Subject<SharedValues>;

  constructor() {
    this.sharedSubject = new Subject<SharedValues>();
  }
}
