import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { FaqComponent } from './faq/faq.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { ContactSupportComponent } from './contact-support/contact-support.component';
import { VendorApplicationComponent } from './vendor-application/vendor-application.component';
import { CustomerApplicationComponent } from './customer-application/customer-application.component';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { StaticHtmlComponent } from '../static/static-html/static-html.component';


const routes: Routes = [
  {
    path: 'aboutUs',
    component: AboutUsComponent
  },
  {
    path: 'products',
    component: StaticHtmlComponent
  },
  {
    path: 'thank-you',
    component: ThankYouComponent
  },
  {
    path: 'corphome',
    component: StaticHtmlComponent
  },
  {
    path: 'faq/:iframe',
    component: FaqComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'customer-application',
    component: CustomerApplicationComponent
  },
  {
    path: 'vendor-application',
    component: VendorApplicationComponent
  },
  {
    path: 'contact-support',
    component: ContactSupportComponent
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent
  },
  {
    path: 'sell',
    component: StaticHtmlComponent
  },
  {
    path: 'private-policy',
    component: PrivacyPolicyComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StaticRoutingModule { }
