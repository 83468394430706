import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bonus-offer-full',
  templateUrl: './bonus-offer-full.component.html',
  styleUrls: ['./bonus-offer-full.component.scss']
})
export class BonusOfferFullComponent implements OnInit {
  @Output() onHideBonusFullOffer = new EventEmitter<null>();
  onModalClose = false;

  constructor() { }

  ngOnInit() {
  }

  closeModal():void{
    this.onModalClose = true;
    setTimeout(() => {
    	this.onHideBonusFullOffer.emit();
      this.onModalClose = false;
    }, 200);
  }

}
