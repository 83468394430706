import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ICartItem } from '../../interfaces/model';
import { TopService } from '../../../shared/services/top.service';

@Component({
  selector: 'app-cart-top',
  templateUrl: './cart-top.component.html',
  styleUrls: ['./cart-top.component.scss']
})
export class CartTopComponent  {
  @Input() totalItems: number;
  @Input() totalUnits: number;
  @Input() totalAmount: number;
  @Input() findAllErrors: boolean;
  @Input() selectedOrderNumber: number;
  @Input() items: ICartItem[];
  @Input() erroronline: boolean;
  @Input() showAddToExistingOrder: boolean;
  @Input() isOutOfStock: boolean;
  @Input() loading: boolean;
  @Output() showModalToggleEmitter = new EventEmitter();
  @Output() rejectAddtoExistingEmitter = new EventEmitter();
  @Output() cancelItemEmitter = new EventEmitter();
  @Output() exportToExcelEmmiter = new EventEmitter();

  constructor(
    public topService: TopService
    ) { }

  rejectAddtoExisting(): void {
    this.rejectAddtoExistingEmitter.emit();
  }

  cancelItem(): void {
    this.cancelItemEmitter.emit();
  }

  showModalToggle(): void {
    this.showModalToggleEmitter.emit();
  }

  exportToCSV(): void {
    this.exportToExcelEmmiter.emit();
  }
}
