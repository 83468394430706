import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-export-to-excel',
  templateUrl: './export-to-excel.component.html',
  styleUrls: ['./export-to-excel.component.scss']
})
export class ExportToExcelComponent implements OnInit {
  @Input() data: any[]; // generic list since it is used all over the system
  @Input() isCart: boolean;
  @Output() exportToExcelEmitter = new EventEmitter<null>();

  constructor() { }

  ngOnInit() {}


/**
 * Emits the export to excel emitter which propagates to the cart-top component
 */
  exportToExcel(): void {
    this.exportToExcelEmitter.emit();
  }
}
