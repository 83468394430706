<div class="po-item" [ngClass]="{'highlight-line': highlight}">
  <div class="po-item-part __date">
    <span> {{poLine.orderDate | date:'shortDate' }} </span>
  </div>
  <div class="po-item-part __po">
    <span> {{poLine.docNumber}} </span>
  </div>
  <div class="po-item-part">
    <span> {{poLine.orderTotal | currency:'USD':'symbol-narrow'}} </span>
  </div>
  <div class="po-item-part">
    <span> {{poLine.orderStatus | titlecase}} </span>
  </div>
  <div class="po-item-part __button">
    <a class="line-button" [ngClass]="{'blue': getTrackingButtonTitle()==='Add Tracking'}" (click)="showTracking($event)"
    (mouseenter) ="poLine.isOnHover=true" (mouseleave) ="poLine.isOnHover=false" >
      {{ getTrackingButtonTitle() }}
    </a>
  </div>
</div>
