<div class="outlet-wrapper-notification" *ngIf="isCreditCardBanner() && !topService.isMobile">
  <app-credit-card-notification (onClose)="closeCCardNotification()"></app-credit-card-notification>
</div>

<div class="outlet-wrapper" #wrapper [class.mobile]="topService.isMobile"
  [class.credit-banner]="isCreditCardBanner() && !topService.isMobile">

  <div class="outlet-wrapper-notification __mobile" *ngIf="isCreditCardBanner() && topService.isMobile">
    <app-credit-card-notification (onClose)="closeCCardNotification()"></app-credit-card-notification>
  </div>

  <ng-container *ngIf="!topService.isMobile">
    <div class="outlet-banner">
      <div class="outlet-banner__txt">
        <h1>{{ outletsService.selectedOutlet?.name }} Deals</h1>
        <p>Shop below wholesale on new and factory refurbished inventory. Available inventory changes by the minute.</p>
      </div>
      <div class="outlet-banner__img"
        *ngIf="outletsService.selectedOutlet && outletsService.selectedOutlet?.heroImageUrl">
        <img *ngIf="outletsService.selectedOutlet"
          [src]="'https://cdn.hubx.com'+outletsService.selectedOutlet?.heroImageUrl" alt="">
      </div>
    </div>
    <div class="outlet-nav">
      <a class="outlet-nav__back" (click)="goBack()">
        <span class="_icon">
          <img src="../../../../assets/images/notification-center/updates/arrow-left-black.svg" width="24" height="24"
            alt="">
        </span>
        Back
      </a>
      <div class="outlet-nav__dd">
        <app-dropdown [items]="outletsOptions"
         [fieldToShow]="'name'" [fieldToSave]="'name'" [isFromBuyerPortal]="true"
          [(ngModel)]="selectedOutletName" [defaultTitle]="getDefaultTitleForManufacturer()"
          (onSelect)="selectOutlet($event)" whiteBorderedTheme="true">
        </app-dropdown>
      </div>
    </div>
  </ng-container>

  <div class="loader" *ngIf="!outletsService.selectedOutlet"></div>

  <!--   <div class="listings-views __mobile" *ngIf="topService.isMobile">

    <div class="outlet-header" *ngIf="itemFilterService.isOutletsSinglePage">
      <div class="outlet-header-wrap">
        <div class="outlet-header-logo">
          <i class="outlet-header-logo_icon" *ngIf="outletsService.selectedOutlet && outletsService.selectedOutlet.blackLogoUrl">
            <img class="__img" [src]="'https://cdn.hubx.com' + outletsService.selectedOutlet.blackLogoUrl" width="81" height="48" />
          </i>
        </div>

        <div [routerLink]="['/outlets']" class="outlet-header__close">
          <svg width="22px" height="22px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="close-bold" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <polygon id="Path" fill="#000001" fill-rule="nonzero" points="17.9791684 3 21 6.02083158 15.0208316 12 21 17.9791684 17.9791684 21 12 15.0208316 6.02083158 21 3 17.9791684 8.97916842 12 3 6.02083158 6.02083158 3 12 8.97916842"></polygon>
            </g>
          </svg>
        </div>
      </div>
    </div>

  </div> -->

  <div class="outlet-items" *ngIf="outletsService.selectedOutlet"
    [class.owerflow]="topService.loading && !topService.isMobile">
    <app-items-list [outletWrapper]="wrapper"></app-items-list>
  </div>

</div>

<!-- <app-login-popup></app-login-popup> -->
<app-bottom-nav-mobile *ngIf="topService.isMobile"></app-bottom-nav-mobile>