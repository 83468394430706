<form [formGroup]="fg" *ngIf="fg">
  <div class="item-wrapper"
    [class.disableddiv]="item.disabled"
    [class._reduse]="item.reactivationThreshold && item.status?.skuStatus === listingSkuStatusNamesEnum.removed"
    [class._archived]="fg.controls.status_skuStatus.value === listingSkuStatusNamesEnum.archived"
    [class.error-border]="item.errors && item.errors.errors && item.showError"
    [class.selected]="item.selected"
    [class._select-active]="item.reactivationThreshold && this.vendorService?.search?.filters?.skuStatus === this.listingSkuStatusValuesEnum.removed">

    <div class="item-select" *ngIf="item.reactivationThreshold && this.vendorService?.search?.filters?.skuStatus === this.listingSkuStatusValuesEnum.removed">
      <label class="item-checkbox">
        <input type="checkbox" formControlName="reactivationCheckBox" (change)="addReactivationCount()">
        <i class="_icon"></i>
      </label>
    </div>

    <div *ngIf="loader" class="loader"></div>
    <div class="item-logo-number">
      <div class="manufacturer-logo" (click)="openEditDetailedMode()">
        <img width="50" height="50" [src]="'https://cdn.hubx.com' + getManufacturerLink()" />
      </div>
    </div>

    <!-- part number and status -->
    <div class="item-part-number">
      <div class="item-info-title">
        Part Number
      </div>
      <div class="input-horizontal" [tooltip]="isAll ? sellerName : ''"
      (click)="copyText(item.vendorItemCode)">
        <input type="text" class="form-control disableMe" formControlName="vendorItemCode">
      </div>

      <!-- status -->
      <div class="item-status">
        <app-dropdown formControlName="status_skuStatus" [showCostPopup]="verifyToShowCostPopup()" [muteClick]="internalStatuses.length===1"
          *ngIf="internalStatuses" [items]="internalStatuses" [fieldToShow]="'text'" [fieldToSave]="'text'" [showInRed]="isRemovedSelected()" >
       </app-dropdown>
      </div>
    </div>

    <!-- description -->
    <div class="item-description">
      <div class="item-info-title">
        Description
      </div>
      <textarea class="form-control" formControlName="description"
        [class.disableMe]="vendorService.isSeller"
        [attr.disabled]="item.disabled ? '' : null">
      </textarea>

    </div>


    <div class="can-break">
        <div class="item-availability">
        <!-- available -->
        <div class="item-info-title">
          Available
        </div>
        <div class="input">
            <input type="number" class="form-control" formControlName="inventory_availableToSell"
              [class.empty-quantity-textbox]="vendorService.isSeller && !item.inventoryInfo.currentInventory.availableToSell"
              appOnlyDigits>
        </div>
      </div>
      <!-- ETA -->
      <div class="item-eta" *ngIf="vendorService.isAdmin || vendorService.isVendorManager">
        <div class="item-info-title">ETA</div>
        <div class="input">
            <input type="text" class="form-control" formControlName="eta" mask="10*" [validation]="false" [patterns]="customPatterns">
        </div>
      </div>
    </div>


    <!-- discounts -->
    <div class="item-price" *ngFor="let price of formPrices; let i = index" formArrayName="prices">
      <div class="item-info-title" [tooltip]="'Discount Quantity'" containerClass="moqdq" placement="top">
        DQ #{{i+1}}
      </div>
      <div class="input-group-vertical" [formGroupName]="i"
      [class.error]="fg.invalid && fg.errors?.controlName === i &&
      [errorCodesEnum.invalidDiscount, errorCodesEnum.invalidDiscountVsPrice].includes(fg.errors.errorCode)">
        <div class="input-qty">
          <input type="text" [class.disableMe]="vendorService.isSeller"
            class="form-control" formControlName="fromQty" prefix='<' mask="0*" (blur)="checkPrefix(qtyInput)" #maskElem #qtyInput>
        </div>

        <div class="input-price disc" [class.not-empty]="price.unitPrice !== null">
          <input type="text" class="form-control" [class.disableMe]="vendorService.isSeller"
             formControlName="unitPrice" prefix='$' mask="separator.2" thousandSeparator="," (blur)="checkPrefix(unitPrice)" #maskElem #unitPrice>
        </div>
      </div>
    </div>

    <!-- MOQ -->
    <div class="item-price moq margin-left-24">
      <div class="item-info-title" [tooltip]="'Minimum Order Quantity'" containerClass="moqdq" placement="top">
        MOQ
      </div>
      <div class="input">
        <input [class.error]="fg.invalid && fg.errors?.errorCode === errorCodesEnum.invalidMinQty"
        type="text" class="form-control" formControlName="moq" mask="10*" [validation]="false" [patterns]="customPatterns">
      </div>
    </div>

    <!-- MXQ -->
    <div class="item-price moq" *ngIf="vendorService.isAdmin  || vendorService.isVendorManager">
      <div class="item-info-title" [tooltip]="'Maximum Order Quantity'" containerClass="moqdq" placement="top">
        MXQ
      </div>
      <div class="input">
        <input [class.error]="fg.invalid && fg.errors?.errorCode === errorCodesEnum.invalidMinQty"
        type="text" class="form-control" formControlName="mxq" mask="10*"  [validation]="false" [patterns]="customPatterns">
      </div>
    </div>

    <!-- cost, price and updated -->
    <div class="align-right-block">
      <div class="item-half-block">
        <div class="item-cost" [class.red-border]="item.reactivationThreshold && item.status?.skuStatus === listingSkuStatusNamesEnum.removed && fg.controls.cost.value > item.reactivationThreshold">
          <div class="item-info-title">
            Cost
          </div>
          <div class="input-price" [class.not-empty]="item.cost !== null">
            <input type="text" class="form-control" formControlName="cost"
              [class.disableMe]="isFieldDisabled()"
              prefix='$' mask="separator.2" thousandSeparator=",">

              <div class="reduse-cost-tooltip" *ngIf="item.reactivationThreshold && item.status?.skuStatus === listingSkuStatusNamesEnum.removed && reduceTooltipShow" [class.for-seller]="vendorService.isSeller">
                <div class="reduse-cost-body">
                  <div class="reduse-cost-header">
                    <h3>
                      0 items were sold within a week
                    </h3>
                    
                  </div>
                  <div class="reduse-cost-content">
                    <p>
                      To activate listing, <br> you must reduce cost <br> by at least {{getReductionRuleDisplayValue()}}
                    </p>
                    <div class="reduse-cost-content__ctrl">
                      <button type="button" class="btn" (click)="applyReactivationThreshold()">
                        <span> Reduce to {{item.reactivationThreshold | CustomCurrency}}</span>
                      </button>
                    </div>
                  </div>
                  <div class="reduse-cost-footer">
                    <span>
                      The above cost is the minimum requirement to activate the status
                    </span>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="item-price" *ngIf="vendorService.isAdmin || vendorService.isVendorManager">
          <div class="item-info-title">
            Price
          </div>
          <div class="input-price" [ngClass]="{'not-empty': item.unitPrice !== null}">
            <input type="text" class="form-control" formControlName="unitPrice"
              [ngClass]="{'disableMe': isFieldDisabled()}"
              [class.error]="fg.invalid && fg.errors?.errorCode === errorCodesEnum.invalidDiscountVsPrice"
              prefix='$' mask="separator.2" thousandSeparator=",">
          </div>
        </div>
      </div>
      <div class="item-half-block ctrls" [class.admin]="vendorService.isAdmin || vendorService.isVendorManager">
        <div class="updated">
          <span>Updated</span>
          <span>{{item.lastUpdatedOn | date:'shortDate'}}</span>
        </div>
        <div class="bottom-icons-group control-btns">
          <a role="button" class="item-icon-btn has-item-btn-tooltip"
            [class.vendor-item-updated]="vendorService.updatedMappedItems.has(item.id)"
            (click)="item.disabled ? return : undoItemUpdates($event)" placement="bottom">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="m6.00421756 12c0 3.3145476 2.685161 6 5.99819244 6 3.3130315 0 5.9981925-2.6854524 5.9981925-6 0-2.93735818-2.1307842-5.42920384-4.9978674-5.90946034l-.3577692-.05992884v-3.01214526l.4814055.05980358c4.4775011.55622674 7.8736286 4.37074396 7.8736286 8.92173086 0 4.9716573-4.0279056 9-8.99759 9-4.96968431 0-8.99758993-4.0283427-8.99758993-9 0-2.08356377.71259734-4.052563 1.99325746-5.63828975l-1.39602921-1.39460219-.10570299-.10190197c-.06353372-.06205206-.11475278-.11449547-.16190711-.16674877-.21437519-.23755639-.33443822-.46158458-.33443822-.73417161 0-.53166627.43160298-.96428571.96413509-.96428571h5.35563658c.14451306 0 .28211456.01920102.44139649.09157803.32099494.14585869.52273864.45091876.52273864.87270768v5.35714286c0 .53154704-.43202033.96428573-.96413513.96428573-.32186896 0-.51778503-.1144239-.7985355-.39298086l-.23290517-.23937411-1.14870224-1.14942643c-.73244391 1.01204442-1.1354112 2.2226953-1.1354112 3.4960671z" fill="#191a19"/></svg>
            <div class="item-btn-tooltip">Undo</div>
          </a>
          <a role="button" class="item-icon-btn update has-item-btn-tooltip" (click)="fg.invalid
           ? return : updateSingleItem(item)"
          [class.vendor-item-updated]="vendorService.updatedMappedItems.has(item.id) && !fg.invalid">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
              <path fill="#191a19" d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" />
            </svg>
            <div class="item-btn-tooltip">Save</div>
          </a>
          <div class="item-archive-controls">
            <a role="button" class="item-archive-controls__btn restore has-item-btn-tooltip" (mouseenter)="tooltipMsg = 'Archived'"
            *ngIf="fg.controls.status_skuStatus.value === listingSkuStatusNamesEnum.archived" (click)="item.disabled ? return : restoreItem()">Restore</a>
            <a role="button" class="item-archive-controls__btn archive has-item-btn-tooltip"
            (mouseenter)="tooltipMsg = 'Archive'"
            (click)="item.disabled ? return : archiveItem()"
            *ngIf="fg.controls.status_skuStatus.value !== listingSkuStatusNamesEnum.archived">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g fill="#EB3B44" fill-rule="nonzero">
                    <polygon points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"></polygon>
                  </g>
                </g>
              </svg>
            </a>
            <div class="item-btn-tooltip">{{tooltipMsg}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="item-error-block">
      <div *ngIf="item.reactivationThreshold && item.status?.skuStatus === listingSkuStatusNamesEnum.removed" class="__reduse-cost">
        <img src="/assets/images/alert.svg" width="24px" height="24px" />
        <span> In order to reactivate, reduce cost by {{getReductionRuleDisplayValue()}}</span>
      </div>

      <!-- backend errors -->
      <div class="line-error col-md-12" *ngIf="showBackendError">
        <img src="https://cdn.hubx.com/assets/images/exclamation-mark.svg" width="24px" height="24px" />
        {{'Error: ' + item.errors.errors[0].errorMessage}}
      </div>

      <!-- Form validation errors   -->
      <div class="line-error col-md-12" *ngIf="fg.invalid && !showBackendError">
        <img src="https://cdn.hubx.com/assets/images/exclamation-mark.svg" width="24px" height="24px" />
        <span>
          {{ fg.errors?.errorMsg }}
        </span>
      </div>     
    </div>
  </div>
</form>

<app-error-modal [title]="errorPopupTitle"
[bodyText]="dqErrMessage"
[isError]="showDqWarningPopUp"
(onConfirmError)="errorPopupNotify(item)">
</app-error-modal>
