import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-po-empty',
  templateUrl: './po-empty.component.html',
  styleUrls: ['./po-empty.component.scss']
})
export class PoEmptyComponent implements OnInit {

  items = [];


  constructor() { }

  ngOnInit() {
  	this.items.length = 8;
  }

}
