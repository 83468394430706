import { UserService } from '../../user/user.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { } from '../products/items/items.component';
import { VendorService } from '../vendor.service';
import { NotificationService } from '../../shared/services/notification.service';
import { SessionService } from '../../services/session.service';
import { AuthService } from '../../auth/auth.service';
import { TopService } from '../../shared/services/top.service';
import { Location } from '@angular/common';
import { OfferService } from '../../user/offers/offer.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-vendor-home',
  templateUrl: './vendor-home.component.html',
  styleUrls: ['./vendor-home.component.scss']
})
export class VendorHomeComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  isOfferListPage = false;

  constructor(private vendorService: VendorService,
    public notificationService: NotificationService,
    private sessionService: SessionService,
    private authService: AuthService,
    public topService: TopService,
    private location: Location,
    public offerService: OfferService,
    private router: Router,
    public userService: UserService,
  ) { }

  ngOnInit() {
    this.setSubscriptions();
    this.isOfferListPage = this.router.url === '/vendor/user/offers';
    document.body.style.paddingTop = this.vendorService.isUser(['ADMIN', 'SELLER', 'VENDORMGR']) ? '0px' : '150px';
    //this.topService.removeMobile();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setSubscriptions() {
    this.subscriptions.push(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.isOfferListPage = this.router.url === '/vendor/user/offers';
      })
    );
  }

  goToOffers() {
    const url = 'vendor/user/offers';
    if (this.router.url.match(/offers/)) {
      this.location.replaceState(url);
      this.offerService.selectedOffer = null;
      window.scrollTo(0, 0);
      return;
    }
    this.router.navigate([url]);
  }

  showPhonePopup() {
    const showPhone = sessionStorage.getItem('show_phone_popup');

    return (showPhone === '1' &&
      this.userService.userProfile &&
      !this.userService.userProfile.isVerified &&
      !this.userService.isGuest);
  }
  
  closePhonePopup() {
    sessionStorage.setItem('show_phone_popup', '0');
  }
}
