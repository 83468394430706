<div class="support-modal" *ngIf="showModalTrigger">
  <div class="add-to-wrap animated" [class.zoomIn]="animateIn" [class.zoomOut]="animateOut" [class.error]="error">
    <div class="modal-content" (clickOutside)="onClickedOutside($event)" [excludeBeforeClick]="true" [exclude]="'.support-button'">
      <div class="modal-header">
        <button type="button" (click)="closeModal()" class="close" aria-label="Close"></button>
	        <div *ngIf="!complete">
	        	<h4 class="modal-title">Support Team</h4>
	        	<p class="modal-accost">Our support team is here to assist you for technical issues related to our portal. All other questions regarding products, accounting and shipping, please contact your sales rep.</p>
	        </div>
	        <div *ngIf="complete">
	        	<h4 class="message-callback-title">Message Sent!</h4>
	        </div>        
      </div>
      <div class="modal-body">
      	<div *ngIf="!complete">
			<span>Message</span>
			<textarea [(ngModel)]="message" class="form-control order-comments-form" rows="3" placeholder="Write your message here"></textarea>
      	</div>
      	<div *ngIf="complete">
      		<div class="message-callback-text">
      			<b>Thank you for your message!</b> <br/> A HUBX support team member will respond to you VIA email.
      		</div>      		
      	</div>		
      </div>
      <div class="modal-footer">
      	<div *ngIf="!complete">
			<button type="button" (click)="sendMessage($event)" class="btn btn-modal btn-modal-confirm">Send</button>
      	</div>
      	<div *ngIf="complete">
      		<button type="button" (click)="closeModal()" class="btn btn-modal btn-modal-confirm btn-complete">Got it!</button>      		
      	</div>        
      </div>
    </div>
  </div>
</div>
