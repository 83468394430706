<div class="auctions-page" [class.mobile]="topService.isMobile">
  <app-auction-header *ngIf="!topService.isMobile"></app-auction-header>
  <!-- Mobile header -->
  <div class="filter" *ngIf="topService.isMobile && !auctionsService.isDetailsPage">
    <div class="filter-row">
      <div class="filter-current">
        <span class="filter-current__value">{{ auctionsService.titleText }}</span>
        <span class="filter-count" *ngIf="auctionsService.totalsReady"
        [class.zeroCount]="auctionsService.totalToDisplay === 0">
          {{auctionsService.totalToDisplay}}</span>
      </div>
    </div>
  </div>

  <!-- <app-login-popup></app-login-popup> -->
  <router-outlet></router-outlet>

  <app-bottom-nav-mobile *ngIf="topService.isMobile"></app-bottom-nav-mobile>
</div>

<ng-container *ngIf="topService.isMobile">
  <app-auction-header-mobile></app-auction-header-mobile>
  <app-bottom-nav-mobile></app-bottom-nav-mobile>
</ng-container>