<div class="support-wrapper" [class.mobile]="topService.isMobile">
	<div class="support-content">
		<div class="support-section">
			<div class="support-section__txt">
	          <div class="support-section__header">
	            <h2 *ngIf="!complete">Support Team</h2>
	            <h2 *ngIf="complete">Message sent!</h2>
	          </div>
	          <p *ngIf="!complete">
	          	Our support team is here to assist you for technical issues related to our portal. All other questions regarding products, accounting and shipping, please contact your sales rep.
	          </p>
	          <p *ngIf="complete">
	          	You will receive a response by a <br> support team member VIA email.
	          </p>
	        </div>
			<div class="support-section-form">
				<div *ngIf="!complete" class="support-section-form__input">
					<textarea placeholder="Your Message" name="message" [(ngModel)]="message"></textarea>
				</div>
				<div class="support-section-form__ctrls">
	                <button *ngIf="!complete" type="button" class="button" (click)="submitForm()" [disabled]="message.trim().length < 4">Send</button>
	                <button *ngIf="complete" type="button" class="button" (click)="resetForm()">Got It</button>
	            </div>
			</div>
		</div>
	</div>
</div>
