import { Component, OnInit } from '@angular/core';

import { TopService } from '../../services/top.service';
import { ItemsService } from '../../../shared/services/items.service';
import { SessionService } from '../../../services/session.service';
import { ItemFilterService } from '../../item-filter/item-filter.service';

@Component({
  selector: 'app-landin-page-user-filters-banner',
  templateUrl: './landin-page-user-filters-banner.component.html',
  styleUrls: ['./landin-page-user-filters-banner.component.scss']
})
export class LandinPageUserFiltersBannerComponent implements OnInit {
  isFaqIsOpen = false;
  showAddNewSearch = false;

  constructor(public topService: TopService,
    public itemsService: ItemsService,
    public sessionService: SessionService,
    private itemFilterService: ItemFilterService) { }

  ngOnInit() { }

  openUserFilterModal(event: MouseEvent) {
    event.stopPropagation();
    this.itemFilterService.searchesList = false;
    this.itemsService.userFilterModalOpen = true;
  }

}
