import { Component, Input, EventEmitter, Output, OnDestroy, OnInit } from '@angular/core';
import { ICartItem } from '../../interfaces/model';
import { CartService } from '../cart.service';
import { Router } from '@angular/router';
import { TopService } from '../../../shared/services/top.service';

@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.scss']
})
export class EditOrderComponent implements OnInit, OnDestroy {
  @Input() selected_doc_number: number;
  @Input() selected_order_number: number;
  @Input() errorCollection: any;
  // @Input() sendMail: boolean;
  @Input() loading: boolean;
  @Input() cartList: ICartItem[];
  @Output() cancelCartEmitter = new EventEmitter();
  @Output() placeNewEmitter = new EventEmitter();

  constructor(public cartService: CartService,
    private _router: Router, public topService: TopService) { }

  ngOnInit() {
    this.cartService.isEditing = true;
    this.cartService.isPurchaseInProgress = false;
  }

  placeNew() {
    this.placeNewEmitter.emit();
  }
  cancelCart() {
    this.cancelCartEmitter.emit();
  }

  findAllErrors(): boolean {
    return this.errorCollection.size > 0 || this.cartList.filter(item => item.statusCode !== 'Ok').length > 0;
  }

  ngOnDestroy() {
    // this._router.navigate(['/user/orders']);
    this.cartService.isEditing = false;
  }
}
