import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ItemsService } from '../../shared/services/items.service';
import { TopService } from '../../shared/services/top.service';
import { OutletsService } from './outlets.service';
import * as models from '../interfaces/model';
import { ItemFilterService } from '../../shared/item-filter/item-filter.service';
import { IDropdown } from '../../vendor/interface/IDropdown';
import { SharedService } from '../../shared/shared.service';
import * as _ from 'lodash';
import { GuestService } from '../../shared/services/guest.service';
import { UserService } from '../../user/user.service';
import { SessionService } from '../../services/session.service';
import { Subscription } from 'rxjs';
import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';

@Component({
  selector: 'app-outlets',
  templateUrl: './outlets.component.html',
  styleUrls: ['./outlets.component.scss']
})
export class OutletsComponent implements OnInit, OnDestroy {
  isCardView = true;
  selectedManu;

  sortingOptions = [
    {
      value: 1,
      text: 'Sort by Popularity'
    },
    {
      value: 2,
      text: 'Sort by A-Z'
    }
  ];
  sortBy = this.sortingOptions[0];
  manufacturersReady = false;

  isFiltered = false;
  selectedCondition;
  currentSelectedChildNav = [];
  alphabet = ['All', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q',
    'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  lowerCaseCharacter: any;
  filterCurrentLetter: string;
  manufacturersDataSorted: models.AttributeValue[] = [];
  subscription: Subscription;
  selectedManufName;

  constructor(
    private router: Router,
    public topService: TopService,
    public itemsService: ItemsService,
    public outletsService: OutletsService,
    public itemFilterService: ItemFilterService,
    public sharedService: SharedService,
    public guestService: GuestService,
    private userService: UserService,
    private sessionService: SessionService,
    public pubSubService: PubSubService,
  ) { }

  ngOnInit() {
    if (this.sessionService.userRole === 'SELLER' || this.sessionService.userRole === 'ADMIN' || this.sessionService.userRole === 'VENDORMGR') {
      this.router.navigate(['/vendor/dashboard']);
      return;
    }
    // TODO LOADING RESET, IS ALWAYS RETURNING TRUE ON LOAD (-_-), THIS IS TEMPORARY FIX UNTIL WE FIND THE PROBLEM!
    this.topService.loading = false;

    // TODO THIS CAN BE DONE IN ANOTHER MORE CLEAN WAY INSTEAD OF JUST SETTING IT HERE TO FALSE ||-_-||
    this.userService.isLogginPopupOpen = false;

    this.sharedService.isCorpSite = false;
    this.resetSettingsFromCatalogToOutlet();

    if (this.itemFilterService.allReactiveManufacturers?.length) {
      this.setUpManufacturesList();
    }

    this.subscription = this.pubSubService.sharedSubject.subscribe(
      mySharedValues => {
        if (mySharedValues.name === SharedSource.navigationReady) {
          // all manufacturers is ready here too
          this.setUpManufacturesList();
        }
      }
    );

    this.itemFilterService.clearAll(undefined, true);
    this.getDefaultTitleForManufacturer();
  }

  setUpManufacturesList() {
    const manufacturers = _.cloneDeep(this.itemFilterService.allReactiveManufacturers);
    this.itemsService.initializeManufacturerData([...manufacturers]);
    this.outletsService.currentManufacturers = manufacturers;
    this.manufacturersReady = true;

    this.manufacturersDataSorted = _.orderBy(this.outletsService.currentManufacturers, ['name'], ['asc']);
  }

  resetSettingsFromCatalogToOutlet() {
    // reseting search and navigation
    this.itemsService.currentNavigation = undefined;
    this.topService.searchString = '';
    this.sharedService.isSearch = false;
    this.outletsService.selectedOutlet = null;
    this.itemsService.deleteTagAttributes();
  }

  toggleView(isCardView: boolean): void {
    this.isCardView = isCardView;
  }

  onOutletItemClick(outlet): void {
    this.outletsService.manufacturersData(outlet);
  }

  sortingSelected(sortBy): void {
    this.sortBy = sortBy;
  }

  manufacturersData(data): void {
    this.getManufacturerBreakDown(data.id);
    this.outletsService.manufacturersData(data);
  }

  getManufacturerBreakDown(manufacturerId: string) {
    if (this.itemsService.pageNavigationByManufaturers &&
      this.itemsService.pageNavigationByManufaturers.length &&
      this.itemsService.manufacturersData &&
      this.itemsService.manufacturersData.length) {
      const filteredList = this.itemsService.pageNavigationByManufaturers
        .find(pNav => pNav.filterId === manufacturerId);

      this.currentSelectedChildNav = (filteredList) ?
        filteredList.navChilds.filter(nav => nav.count > 0) : [];
      return;
    }
    this.currentSelectedChildNav = [];
  }

  filterByCharacter(character) {
    this.filterCurrentLetter = character;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  reload() {
    this.filterByCharacter('All');
  }

  getDefaultTitleForManufacturer(): string {
    this.selectedManufName = this.itemFilterService.selectedManufacturer ? this.itemFilterService.selectedManufacturer.name : 'All Manufacturers';
    return this.selectedManufName;
  }

}
