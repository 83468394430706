<div class="offers-banner" [class.mobile]="topService.isMobile">
  <div class="offers-banner-content">
    <div class="offers-banner-content__image" *ngIf="topService.isMobile">
      <img src="assets/images/icons/offers/offers-banner-mobile.jpg" width="361" alt="Offers Banner">
    </div>
    <div class="offers-banner-content__text">
      <i>
      	<img src="assets/images/icons/offers/offers-banner-text.png" width="392" alt="Make Offer">
      </i>
      <div class="offers-banner-buttons">
      	<a (click)="isFaqIsOpen = true">How it works</a>
      </div>
    </div>
  </div>
</div>


<app-offers-popup *ngIf="isFaqIsOpen" (onCloseModal)="isFaqIsOpen = false;"></app-offers-popup>