import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StateService {
    pastPurchaseInitialState = {
        conditionSelected: 'All Conditions',
        sortByAvailable: false,
        sortByPrice: false,
        sortByPurchaseDate: false,
        manufacturersSelected: []
    };
    pastPurchaseChangedState = {
        conditionSelected: '',
        sortByAvailable: false,
        sortByPrice: false,
        sortByPurchaseDate: false,
        manufacturersSelected: []
    };

    constructor() { }
}
