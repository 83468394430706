<div class="loader" *ngIf="activeRequests > 0 && activeRequestsForInitialization === 0"></div>

<div class="search-modal" [class.mobile]="topService.isMobile"
  [class.show-banner]="!topService.isMobile">

  <div class="search-modal-dialog animated" [class.zoomIn]="animateIn" [class.zoomOut]="animateOut">
    <div class="search-modal-content-wrap">

      <!-- left banner -->
      <div class="search-modal-banner" *ngIf="!topService.isMobile"
        (clickOutside)="itemsService.userFilterModalOpen = false" [excludeBeforeClick]="true"
        [exclude]="'.filters-saved-searches,.search-modal-content,.filters-banner-content'">
        <div class="search-modal-banner__text">
          <span class="search-modal-banner-heading">
            Save your searches
          </span>
          <span class="search-modal-banner-info">
            and get all new added items in one email
          </span>
        </div>
        <div class="search-modal-banner__img">
        </div>
      </div>

      <div class="search-modal-content" (clickOutside)="itemsService.userFilterModalOpen = false"
        [excludeBeforeClick]="true" [exclude]="'.filters-saved-searches,.search-modal-banner,.filters-banner-content'">

        <!-- title -->
        <div class="search-modal-header" [class.on-scroll]="activeScroll">
          <span *ngIf="itemFilterService.searchesList">Saved Searches</span>
          <span *ngIf="!itemFilterService.searchesList">
            {{userFilterId || userFilter?.id ? 'Edit Search' : 'Save New Search'}}
          </span>
          <button type="button" class="delete" *ngIf="(userFilterId || userFilter?.id) && !itemFilterService.searchesList"
            (click)="deleteFilter()">Delete</button>
          <a role="button"  *ngIf="router.url !== '/user/filters'" routerLink="/user/filters">Manage Saved Searches</a>
          <span class="search-modal-header__close" (click)="close()">
            <img src="../../../../assets/images/close.svg" width="24" height="24" alt="close">
          </span>
        </div>

        <div [class.disable-content]="activeRequests > 0">
          <!-- user filter -->
          <div class="search-modal-body" *ngIf="!showPreselectForm && !itemFilterService.searchesList"
            [class.animated]="initFormAnimation" [class.flipInY]="!showPreselectForm">

            <!-- Name -->
            <div class="grid-row">
              <div class="grid-col">
                <div class="control">
                  <div class="control__label">Search Nickname</div>
                  <div>
                    <input type="text" placeholder="Create a Search Nickname" [(ngModel)]="userFilter.name"
                      (ngModelChange)="validateForm()" [class.required]="duplicatedFilterName || userFilterNameInvalid">
                    <!-- <span class="has-error" *ngIf="duplicatedFilterName">
                      Nickname already exist
                    </span> -->
                  </div>
                </div>
              </div>
            </div>

            <!-- Switch view -->
            <div class="grid-row">
              <div class="grid-col">
                <div class="control-btns">
                  <a role="button" class="animated"
                    [class.active]="userFilter.source === UserFilterSourceEnum.manufacturerOutlet"
                    (click)="toggleOutletForm(UserFilterSourceEnum.manufacturerOutlet)">Manufacturer Outlet</a>
                  <a role="button" class="animated"
                    [class.active]="userFilter.source === UserFilterSourceEnum.globalSearch"
                    (click)="toggleOutletForm(UserFilterSourceEnum.globalSearch)">Site-wide Search</a>
                </div>
              </div>
            </div>

            <!-- Forms -->
            <div class="flip-wrapper">

              <!-- Site-wide -->
              <div [hidden]="userFilter.source === UserFilterSourceEnum.manufacturerOutlet"
                [class.animated]="initAnimation"
                [class.flipInY]="userFilter.source === UserFilterSourceEnum.globalSearch">

                <!-- Promotional Feature -->
                <div class="grid-row">
                  <div class="grid-col">
                    <app-dropdown *ngIf="promotions" [items]="promotions" [fieldToShow]="'navName'"
                      [fieldToSave]="'navId'" [label]="'Promotional Feature'"
                      [(ngModel)]="userFilter.promotionNavigationId" (ngModelChange)="validateForm()">
                    </app-dropdown>
                  </div>
                </div>

                <!-- Category  -->
                <div class="grid-row">
                  <div class="grid-col">
                    <app-dropdown *ngIf="categories" [items]="categories" [fieldToShow]="'navName'"
                      [fieldToSave]="'navId'" (onSelect)="onSelectCategorySiteWide($event)" [label]="'Category'"
                      [(ngModel)]="userFilter.categoryNavigationId" (ngModelChange)="validateForm()"
                      [defaultTitle]="preselectedUserFilter?.category">
                    </app-dropdown>
                  </div>
                </div>

                <!-- Subcategory -->
                <div class="grid-row">
                  <div class="grid-col">
                    <app-dropdown *ngIf="subcategories" [items]="subcategories" [fieldToShow]="'navName'"
                      [fieldToSave]="'navId'" [label]="'Sub Category'" [(ngModel)]="userFilter.subcategoryNavigationId"
                      (ngModelChange)="validateForm()" [disabled]="disableField.subcategorySiteWide"
                      [defaultTitle]="preselectedUserFilter?.subcategory">
                    </app-dropdown>
                  </div>
                </div>

                <!-- Manufacturer -->
                <div class="grid-row">
                  <div class="grid-col">
                    <app-dropdown [items]="manufacturers" [fieldToShow]="'name'" [fieldToSave]="'id'"
                      [label]="'Manufacturer'" [(ngModel)]="userFilter.manufacturerId" (ngModelChange)="validateForm()"
                      [defaultTitle]="preselectedUserFilter?.manufacturer">
                    </app-dropdown>
                  </div>
                </div>
              </div>


              <!-- Outlet -->
              <div [hidden]="userFilter.source === UserFilterSourceEnum.globalSearch" class="animated"
                [class.flipInY]="userFilter.source === UserFilterSourceEnum.manufacturerOutlet">

                <!-- Manufacturer Outlet -->
                <div class="grid-row">
                  <div class="grid-col">
                    <app-dropdown *ngIf="manufacturers" [items]="manufacturers" [fieldToShow]="'name'"
                      [fieldToSave]="'id'" (onSelect)="onSelectManufacturerOutlet($event)"
                      [label]="'Manufacturer Outlet'" [(ngModel)]="userFilter.manufacturerId"
                      (ngModelChange)="validateForm()" [defaultTitle]="preselectedUserFilter?.manufacturer">
                    </app-dropdown>
                  </div>
                </div>

                <!-- Promotional Feature -->
                <div class="grid-row">
                  <div class="grid-col">
                    <app-dropdown *ngIf="promotions" [items]="promotions" [fieldToShow]="'navName'"
                      [fieldToSave]="'navId'" [label]="'Promotional Feature'"
                      [(ngModel)]="userFilter.promotionNavigationId" (ngModelChange)="validateForm()">
                    </app-dropdown>
                  </div>
                </div>

                <!-- Category  -->
                <div class="grid-row">
                  <div class="grid-col">
                    <app-dropdown *ngIf="categories" [items]="categories" [fieldToShow]="'navName'"
                      [fieldToSave]="'navId'" (onSelect)="onSelectCategoryOutlet($event)" [label]="'Category'"
                      [disabled]="disableField.categoryOutlet" [(ngModel)]="userFilter.categoryNavigationId"
                      (ngModelChange)="validateForm()" [defaultTitle]="preselectedUserFilter?.category">
                    </app-dropdown>
                  </div>
                </div>

                <!-- Subcategory -->
                <div class="grid-row">
                  <div class="grid-col">
                    <app-dropdown *ngIf="subcategories" [items]="subcategories" [fieldToShow]="'navName'"
                      [fieldToSave]="'navId'" [label]="'Sub Category'" [disabled]="disableField.subcategoryOutlet"
                      [(ngModel)]="userFilter.subcategoryNavigationId" (ngModelChange)="validateForm()"
                      [defaultTitle]="preselectedUserFilter?.subcategory">
                    </app-dropdown>
                  </div>
                </div>

              </div>
            </div>

            <!-- Price -->
            <div class="grid-row _price-range">
              <div class="grid-col _half _mobile-full">
                <div class="control">
                  <div class="control__label">Min price</div>
                  <div>
                    <input type="text" prefix="$ " mask="00000" [ngModel]="userFilter.price.min"
                      [formControl]="minPriceFrm" pattern="[0-9]*" inputmode="numeric" appOnlyDigits />
                  </div>
                </div>
              </div>
              <div class="grid-col _half _mobile-full">
                <div class="control">
                  <div class="control__label">Max price</div>
                  <div>
                    <input type="text" prefix="$ " [suffix]="plusSignSuffix" mask="00000"
                      [ngModel]="userFilter.price.max" [formControl]="maxPriceFrm" pattern="[0-9]*" inputmode="numeric" appOnlyDigits />
                  </div>
                </div>
              </div>
              <div class="grid-col _slider">
                <ng5-slider *ngIf="initSlider" [(value)]="userFilter.price.min" [(highValue)]="userFilter.price.max"
                  [options]="priceRangeOptions"></ng5-slider>
              </div>
            </div>

            <!-- Condition -->
            <div class="grid-row">
              <div class="grid-col">
                <app-dropdown *ngIf="conditions" [items]="conditions" [fieldToShow]="'value'" [fieldToSave]="'name'"
                  [label]="'Condition'" [(ngModel)]="userFilter.conditionId" (ngModelChange)="validateForm()"
                  [defaultTitle]="preselectedUserFilter?.condition">
                </app-dropdown>
              </div>
            </div>

            <!-- Exw -->
            <div class="grid-row">
              <div class="grid-col">
                <app-dropdown *ngIf="exws" [items]="exws" [fieldToShow]="'text'" [fieldToSave]="'value'" [label]="'EXW'"
                  [(ngModel)]="userFilter.exw" (ngModelChange)="validateForm()"
                  [defaultTitle]="preselectedUserFilter?.exw">
                </app-dropdown>
              </div>
            </div>
          </div>

          <!-- preselect form -->
          <div class="search-modal-body preselect" *ngIf="showPreselectForm && !itemFilterService.searchesList"
            [class.animated]="initFormAnimation" [class.flipInY]="showPreselectForm">
            <div class="grid-row">
              <div class="grid-col">
                <div class="control">
                  <div>
                    <input type="text" placeholder="Create a Search Nickname" [(ngModel)]="userFilter.name"
                      (ngModelChange)="validateForm()" [class.required]="duplicatedFilterName">
                  </div>
                </div>
              </div>
            </div>
            <div class="grid-row">
              <div class="grid-col">
                <div class="preselect-item _heading">
                  <div class="preselect-item__key">
                    <h3>Search Settings</h3>
                  </div>
                  <!-- <div class="preselect-item__value">
                  <a role="button" (click)="showEditForm()"> Edit</a>
                </div> -->
                </div>
              </div>
            </div>

            <div class="grid-row">
              <div class="grid-col">
                <div class="preselect-item">
                  <div class="preselect-item__key">
                    <span>Promotional Feature</span>
                  </div>
                  <div class="preselect-item__value">
                    <span>
                      {{preselectedUserFilter?.promotion || 'None'}}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-row">
              <div class="grid-col">
                <div class="preselect-item">
                  <div class="preselect-item__key">
                    <span>Category</span>
                  </div>
                  <div class="preselect-item__value">
                    <span>
                      {{preselectedUserFilter?.category}}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-row">
              <div class="grid-col">
                <div class="preselect-item">
                  <div class="preselect-item__key">
                    <span>Manufacturer</span>
                  </div>
                  <div class="preselect-item__value">
                    <span>{{preselectedUserFilter?.manufacturer}} </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-row">
              <div class="grid-col">
                <div class="preselect-item">
                  <div class="preselect-item__key">
                    <span>Sub Category</span>
                  </div>
                  <div class="preselect-item__value">
                    <span>{{preselectedUserFilter?.subcategory}} </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-row">
              <div class="grid-col">
                <div class="preselect-item">
                  <div class="preselect-item__key">
                    <span>Price</span>
                  </div>
                  <div class="preselect-item__value">
                    <span>{{preselectedUserFilter?.price}} </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-row">
              <div class="grid-col">
                <div class="preselect-item">
                  <div class="preselect-item__key">
                    <span>Condition</span>
                  </div>
                  <div class="preselect-item__value">
                    <span>{{preselectedUserFilter?.condition}} </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-row">
              <div class="grid-col">
                <div class="preselect-item">
                  <div class="preselect-item__key">
                    <span>EXW</span>
                  </div>
                  <div class="preselect-item__value">
                    <span>{{preselectedUserFilter?.exw}} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- user filter list -->
          <div class="search-modal-body searches" *ngIf="itemFilterService.searchesList" [class.animated]="initFormAnimation"
            [class.flipInY]="itemFilterService.searchesList" (scroll)="onScroll($event)">
            <div class="searches-item" *ngFor="let filter of userFiltersService.userFilterList"
              (click)="userFiltersService.navigateToItems(filter)">
              <div class="searches-item-info">

                <div class="searches-item-search">

                  <a role="button">
                    <i>
                      <img alt="Search" src="assets/images/icons/32-search.svg" width="24" height="24">
                    </i>
                  </a>

                </div>
                <div class="searches-item-text">
                  <span class="searches-item-text-name"> {{filter.name}}</span>
                  <span class="searches-item-text-description"> {{filter.redableText}}</span>
                </div>
              </div>
            </div>

          </div>

        </div>

        <!-- actions -->
        <div class="search-modal-footer" *ngIf="!itemFilterService.searchesList">
          <div class="grid-row">
            <div class="grid-col _half">
              <button type="button" class="_secondary" (click)="close()">
                Cancel
              </button>
            </div>
            <div class="grid-col _half">
              <button type="button" class="_primary" (click)="save()" [disabled]="!validForm || activeRequests > 0">
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
