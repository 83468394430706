<div class="offers-list-wrap" [class.mobile]="topService.isMobile">
	<div class="offers-list">
		<div class="offers-list-header">
			<div class="offers-list-header__heading">
				<h3>My Offers</h3>
        <span *ngIf="['BUYER','SELLER'].includes(userRole) && offerService.offersSummary?.unseenEventCount > 0"
          class="_badge-counter">
          {{offerService.offersSummary?.unseenEventCount}}
        </span>
        <span *ngIf="(isSuperOrSaleImpersonating || ['ADMIN','VENDORMGR'].includes(userRole)) && offerService.bpUnseenEventCount > 0"
          class="_badge-counter">
          {{ offerService.bpUnseenEventCount}}
        </span>
			</div>
			<div class="offers-list-header__tokens" *ngIf="isBuyer">
        <div class="tokens-wrap">
          <div class="tokens-images">
            <i [class.active]="offerService.offersSummary?.tokenCount>=3"></i>
            <i [class.active]="offerService.offersSummary?.tokenCount>=2"></i>
            <i [class.active]="offerService.offersSummary?.tokenCount>=1"></i>
          </div>
          <div class="tokens-info">
            <i><img src="assets/images/icons/offers/info.svg" alt="info"></i><span>Available Tokens</span>
          </div>
          <div class="tokens-tooltip">
            You have 3 tokens at your disposal. This allows you to have up to 3 active offers at the same time. At the end of the offer, the token is returned to your balance.
          </div>
        </div>
			</div>
		</div>

		<ng-container>
			<div class="offers-list-filter" [hidden]="isLoading" >
				<app-dropdown
          [items]="totalOffersByStatus"
          [fieldToShow]="'displayName'"
          [counterFieldForDropdownOption]="'total'"
          [fieldToSave]="'status'"
          [disabled]="false"
          [defaultTitle]="defaultTitleDropdown"
          [offersSelect]="true"
          [displayCounterInSelectedTitle]="true"
          (onSelect)="onSelectOfferStatusFilter($event)">
        </app-dropdown>
			</div>
			<div class="offers-list-body" [hidden]="isLoading" *ngIf="offersByStatus?.offerDataResponses"
      infiniteScroll
      [infiniteScrollDistance]="infiniteScrollConfig.scrollDistance"
      [infiniteScrollUpDistance]="infiniteScrollConfig.scrollUpDistance"
      [infiniteScrollThrottle]="infiniteScrollConfig.throttle"
      (scrolled)="onScrollDown()"
      [scrollWindow]="false">
        <div *ngFor="let offer of offersByStatus?.offerDataResponses" (click)="selectOffer(offer.offerId, offer.itemCode, offer.manufacturerLogoUrl)">
          <div class="offers-list-item"
          [class.selected]="offer.offerId===offerService.selectedOffer?.id"
          [class.unseen]="offer.unseenEventsCount > 0"
          [class.unanswered]="offer.unseenEventsCount == 0 && (
           (isBuyer && [OfferEventTypes.VendorCounteroffered, OfferEventTypes.VendorUpdatedOfferEvent].includes(offer.lastEventType)) ||
           (isVendor && [OfferEventTypes.BuyerCounteroffered, OfferEventTypes.OfferMade, OfferEventTypes.BuyerUpdatedOfferEvent].includes(offer.lastEventType)))">
            <div class="offers-list-item__logo">
              <div class="__logo-wrap">
                <div class="__logo-img"
                [ngStyle]="{'background-image': 'url(' + ( !offer.manufacturerLogoUrl?.includes(imageBaseUrl) ? imageBaseUrl : '') + offer.manufacturerLogoUrl + ')'}">
                </div>
              </div>
            </div>
            <div class="offers-list-item-desk">
              <div class="offers-list-item-desk__top">
                <span class="__name">{{offer.quantity | number:0:'en'}} <span>&#215;</span> {{offer.itemCode | shortLabel:10:'...'}}</span>
                <span class="__date">{{offer.lastEventTimestampUtc | utcToNowOffers: fromOfferList}}</span>
              </div>
              <div class="offers-list-item-desk__bottom">
                <span class="__status">{{offerEventTypeMessages.get(offer.lastEventType)}}</span>
                <span class="__counter" *ngIf="offer.unseenEventsCount > 0">
                  <ng-container *ngIf="!topService.isMobile">
                    {{offer.unseenEventsCount}}
                  </ng-container>
                </span>
                <span class="__counter" *ngIf="offer.unseenEventsCount == 0 && (
                  (isBuyer && [OfferEventTypes.VendorCounteroffered, OfferEventTypes.VendorUpdatedOfferEvent].includes(offer.lastEventType)) ||
                  (isVendor && [OfferEventTypes.BuyerCounteroffered, OfferEventTypes.OfferMade, OfferEventTypes.BuyerUpdatedOfferEvent].includes(offer.lastEventType) )
                  )">
                  <ng-container *ngIf="!topService.isMobile" >
                    1
                  </ng-container>
                </span>
              </div>
            </div>
          </div>
        </div>
			</div>
		</ng-container>

		<ng-container *ngIf="isLoading">
			<div class="offers-list-preloader">
        <div class="offers-list-preloader-filter">
          <span></span>
        </div>
        <div class="offers-list-preloader-items">
          <div class="offers-list-preloader-item" *ngFor="let preloaderItem of preloaderItems">
            <div class="offers-list-preloader-item__logo">
              <span></span>
            </div>
            <div class="offers-list-preloader-item__text">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
			</div>
		</ng-container>


	</div>
</div>
