<div class="chart-item-svg">

  <span class="chart-item-svg__percentage"> {{ percentage }}<span class="chart-item-svg__percent">%</span> </span>
  <svg width="46px" height="46px" viewBox="0 0 46 46" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <rect [attr.stroke]="color" stroke-width="4" x="2" y="2" width="42" height="42" rx="12" opacity="0.15">
          </rect>


          <rect id="Rectangle" [attr.stroke]="color" [attr.stroke-dashoffset]="strokeDashOffset" stroke-dasharray="600" stroke-width="4" x="2" y="2" width="42" height="42" rx="12">
            <animate attributeName="stroke-dashoffset" begin="0s" from="600" [attr.to]="strokeDashOffset" dur="1s" repeatCount="1" />
          </rect>
      </g>
  </svg>
</div>
