import { IBidDesk } from './../../../interfaces/IBidDesk';
import { BiddeskBannerService } from './../biddesk-banner.service';
import { UserService } from './../../../../user/user.service';
import { AuthService } from './../../../../auth/auth.service';
import { ItemsService } from './../../../services/items.service';
import { TopService } from './../../../services/top.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as JWT from 'jwt-decode';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-biddesk-popup',
  templateUrl: './biddesk-popup.component.html',
  styleUrls: ['./biddesk-popup.component.scss']
})
export class BiddeskPopupComponent implements OnInit, OnDestroy {
  public formBid: FormGroup;
  formErrors: any;
  subscriptions: Subscription[] = [];
  user_info;
  totalOffer = null;
  animateIn = false;
  animateOut = false;
  animateSubmitIn = false;
  animateSubmitOut = false;
  isFirefox = false;
  loading = false;
  validPartNumber: boolean = null;
  validListPrice: boolean = null;
  currentCorrectListPrices: number[] = null;
  minimumTotalOffer = 10000;

  constructor(public topService: TopService,
    public itemsService: ItemsService,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    public userService: UserService,
    public biddeskBannerService: BiddeskBannerService) { }

  ngOnInit(): void {
    this.detectUserAgent();
    this.showBiddeskModal();
    const access_token_decoded = JWT(this.authService.getAccessToken());
    this.user_info = JSON.parse(access_token_decoded['https://www.hubx.com/user_info']);

    this.formErrors = this.getDefaultFormErrors();
    this.formBid = this.createBidform();
    this.subscriptions.push(
      this.formBid.valueChanges.subscribe(() => {
        this.onFormValuesChanged(false);
      })
    );
    this.formBid.patchValue({ email: this.user_info.email || '' });
    this.subscriptions.push(
      this.formBid.get('partNumber').valueChanges.pipe(debounceTime(700)).subscribe(partNumber => {
        this.getItemsByPartNumber(partNumber.trim());
      })
    );

    this.subscriptions.push(
      this.formBid.get('partNumber').valueChanges.subscribe(partNumber => {
        this.validPartNumber = false;
      })
    );

    this.subscriptions.push(this.formBid.get('hubxListPrice').valueChanges.subscribe(hubxListPrice => { this.validateOfferPrice(); }));
    this.subscriptions.push(this.formBid.get('offerPrice').valueChanges.subscribe(offerPrice => { this.validateOfferPrice(); }));
    this.subscriptions.push(this.formBid.get('offerQty').valueChanges.subscribe(offerQty => { this.validateOfferPrice(); }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  detectUserAgent() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Firefox") > -1) {
      this.isFirefox = true;
    }
  }

  getDefaultFormErrors() {
    return {
      email: {},
      partNumber: {},
      hubxListPrice: {},
      offerPrice: {},
      offerQty: {}
    };
  }

  createBidform(): FormGroup {
    const formBid: FormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, this.validateEmailExtension.bind(this)]],
      partNumber: ['', Validators.required],
      hubxListPrice: [null, Validators.required],
      offerPrice: [null, Validators.required],
      offerQty: [null, Validators.required]
    });

    return formBid;
  }

  onFormValuesChanged(submitting: Boolean) {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      const control = this.formBid.get(field);

      if (submitting) {
        if (control && !control.valid) {
          this.formErrors[field] = control.errors;
        }
      } else {
        if (control) {
          this.subscriptions.push(
            control.valueChanges.subscribe(newValue => {
              if (control && !control.valid) {
                this.formErrors[field] = control.errors;
              } else {
                this.formErrors[field] = {};
              }
            })
          );
        }
      }
    }
  }

  getItemsByPartNumber(partNumber: string) {
    if (!partNumber.length) {
      this.formErrors.partNumber.required = true;
      return;
    }
    this.loading = true;
    this.validListPrice = false;
    this.subscriptions.push(
      this.itemsService.getItemsByPartNumber(partNumber)
        .subscribe(
          (data: any) => {
            this.loading = false;
            this.validPartNumber = true;
            const items = data.values.filter(x => x.partNumber.toLowerCase() === partNumber.toLowerCase())
            if (!items || items.length === 0) {
              this.formErrors.partNumber.required = true; // force to show error
              this.validPartNumber = false;
            } else{
              this.currentCorrectListPrices = items.map(i => i.unitPrice);
              const hubxListPrice = this.formBid.get('hubxListPrice').value;
              if (hubxListPrice && this.currentCorrectListPrices.find(p => p === hubxListPrice)){
                this.validListPrice = true;
              }
            }
          },
          (err) => {
            this.loading = false;
            this.formErrors.partNumber.required = true;
            this.validPartNumber = false;
            this.validListPrice = false;
            console.log('Error trying to get an item by part number: ' + err);
          }
        )
    );
  }

  validateIfHasValue(fieldName: string): boolean {
    const fieldValue = this.formBid.value[fieldName];
    if (!fieldValue && fieldValue !== 0) {
      return false;
    }
    return String(this.formBid.value[fieldName]).length > 0;
  }

  validateEmailExtension(control: AbstractControl) {
    const email = control.value;
    if (!(/\S+@\S+\.\S+/).test(email)) {
      return { validateEmailExtension: false };
    }
    return null;
  }

  validateOfferPrice() {
    const hubxListPrice = this.formBid.get('hubxListPrice').value;
    const offerPrice = this.formBid.get('offerPrice').value;
    const offerQty = this.formBid.get('offerQty').value;
    this.totalOffer = null;
    this.validListPrice = false;
    this.formErrors.invalidTotalOffer = false;

    this.formErrors.invalidPercentRule = false;
    this.formErrors.lowAcceptance = false;
    this.formErrors.highAcceptance = false;
    
    // Validate list price is correct
    if(hubxListPrice && this.currentCorrectListPrices && this.currentCorrectListPrices.find(p => p === hubxListPrice) ){
      this.validListPrice = true;
    } 

    if (hubxListPrice !== null && offerPrice !== null && offerQty !== null) {
     
      if (hubxListPrice && offerPrice) {
        // Offers are made on a line item basis and can not be more than 5% off the current list price
        const discountPercent = 100 - (offerPrice * 100 / hubxListPrice);
        this.formErrors.invalidPercentRule = discountPercent > 5 ? true : false;

        // low acceptance rate
        this.formErrors.lowAcceptance = discountPercent >= 4 && discountPercent <= 5;

        // high acceptance rate
        this.formErrors.highAcceptance = discountPercent > 0 && discountPercent < 4;
      }
      // All offers must be a minimum of $10k to be considered.
      if (offerPrice >= 0 && offerQty >= 0) {
        this.totalOffer = (offerPrice * offerQty).toFixed(2);
        this.formErrors.invalidTotalOffer = this.totalOffer < this.minimumTotalOffer ? true : false;
      }
    }
  }

  paste() {
    navigator.clipboard.readText()
      .then(clipText => { // not working if !window.isSecureContext
        this.formBid.patchValue({ partNumber: clipText });
      })
      .catch(err => {
        console.error('Failed to read clipboard contents: ', err);
      });
  }

  onSubmit() {
    if (this.loading) {
      return;
    }
    if (!this.isValidForm()) {
      this.onFormValuesChanged(true);
      return;
    }

    const bid = this.formBid.value;
    const payload: IBidDesk = {
      email: bid.email,
      itemCode: bid.partNumber,
      hubxListPrice: bid.hubxListPrice,
      offerPrice: bid.offerPrice,
      offerQty: bid.offerQty,
      totalOffer: this.totalOffer,
      bpCode: this.userService.bpCode,
      companyName: this.userService.companyName
    }

    this.loading = true;
    this.subscriptions.push(
      this.biddeskBannerService.sendBidDesk(payload)
        .subscribe(
          (data) => {
            this.loading = false;
            if (data) {
              this.hideonSubmitBiddeskModal();
            }
          },
          (err) => { console.log('Error submiting a bidDesk: ' + err); this.loading = false; })
    );
  }

  hideonSubmitBiddeskModal() {
    this.animateIn = false;
    this.animateOut = true;
    setTimeout(() => {
      this.biddeskBannerService.isBiddeskModalShown = false;
      this.animateOut = false;
      this.biddeskBannerService.isBidSubmited = true;
      this.animateSubmitIn = true;
      setTimeout(() => {
        this.animateSubmitIn = false;
        this.animateSubmitOut = true;
        setTimeout(() => {
          this.biddeskBannerService.isBidSubmited = false;
          this.animateSubmitOut = false;
          document.documentElement.classList.remove('gg-modal');
          if (this.topService.isMobile) {
            document.documentElement.classList.remove('is-modal');
          }
        }, 200);
      }, 1000);
    }, 200);
  }

  showBiddeskModal() {
    this.biddeskBannerService.isBiddeskModalShown = true;
    this.animateIn = true;
    document.documentElement.classList.add('gg-modal');
    if (this.topService.isMobile) {
      document.documentElement.classList.add('is-modal');
    }
  }

  hideBiddeskModal() {
    this.animateIn = false;
    this.animateOut = true;
    setTimeout(() => {
      this.biddeskBannerService.isBiddeskModalShown = false;
      this.animateOut = false;
      document.documentElement.classList.remove('gg-modal');
      if (this.topService.isMobile) {
        document.documentElement.classList.remove('is-modal');
      }
    }, 200);
  }

  isValidForm(): boolean {
    if (!this.formBid.valid || this.formErrors.invalidPercentRule || this.formErrors.invalidTotalOffer || 
         this.formErrors.partNumber.required || !this.validPartNumber || !this.validListPrice) {
      return false
    }
    return true;
  }

}
