<div class="scrollable-wrap" [class.mobile]="topService.isMobile">
  <div class="main-wrap">
    <div class="decorate-header no-before">
      <div class="display-flex inner-header">
        <h1>Vendor Application</h1>
      </div>
    </div>
    <div class="contact-page-section">
      <div class="wrapper">
        <div class="contact-form-col">
          <form id="vendor-form" #f="ngForm">
            <div class="controls-row">
              <div class="control-item desktop-half">
                <div class="control-label-top">First Name</div>
                <div class="control-textbox" [ngClass]="{'error': firstname.touched && !firstname.valid}">
                  <input type="text" name="firstname" autocomplete="off" [(ngModel)]="firstName" pattern="[a-zA-Z ]*"
                    required #firstname="ngModel" [disabled]="sessionService.isLoggedIn === 'true'" />
                </div>
              </div>
              <div class="control-item desktop-half">
                <div class="control-label-top">Last Name</div>
                <div class="control-textbox" [ngClass]="{'error': lastname.touched && !lastname.valid}">
                  <input type="text" name="lastname" autocomplete="off" [(ngModel)]="lastName" pattern="[a-zA-Z ]*"
                    required #lastname="ngModel" [disabled]="sessionService.isLoggedIn === 'true'"/>
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item desktop-half">
                <div class="control-label-top">Company Name</div>
                <div class="control-textbox" [ngClass]="{'error': companyname.touched && !companyname.valid}">
                  <input type="text" name="companyname" [(ngModel)]="userInfo.companyName" #companyname="ngModel" required
                  [disabled]="sessionService.isLoggedIn === 'true'" />
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item desktop-half">
                <div class="control-label-top">Company Website</div>
                <div class="control-textbox" [ngClass]="{'error': companywebsite.touched && !companywebsite.valid}">
                  <input type="text" name="companywebsite" [(ngModel)]="userInfo.companyWeb" #companywebsite="ngModel"
                    required [disabled]="sessionService.isLoggedIn === 'true'" />
                </div>
              </div>
              <div class="control-item desktop-half">
                <div class="control-label-top">Email</div>
                <div class="control-textbox" [ngClass]="{'error': email.touched && !email.valid}">
                  <input type="email" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" [(ngModel)]="userInfo.email" #email="ngModel" required
                  [disabled]="sessionService.isLoggedIn === 'true'" />
                  <label class="error" for="Email"
                    *ngIf="!email.valid && email.touched">Invalid Email</label>
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item">
                <div class="control-label-top">Address</div>
                <div class="control-textbox" [ngClass]="{'error': address.touched && !address.valid}">
                  <input type="text" name="address" [(ngModel)]="address1" #address="ngModel" required />
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item">
                <div class="control-label-top">Address 2</div>
                <div class="control-textbox" >
                  <input type="text" name="addresstwo" [(ngModel)]="address2" #addresstwo="ngModel"  />
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item desktop-half">
                <div class="control-label-top">City</div>
                <div class="control-textbox" [ngClass]="{'error': city.touched && !city.valid}">
                  <input type="text" name="city" [(ngModel)]="userInfo.city" #city="ngModel" required />
                </div>
              </div>
              <div class="control-item desktop-half">
                <div class="controls-row">
                    <div class="control-item tablet-45">
                        <div class="control-label-top">State/Province</div>
                        <div class="control-textbox" [ngClass]="{'error': state.touched && !state.valid}">
                          <input type="text" name="state" [(ngModel)]="userInfo.state" #state="ngModel" required />
                        </div>
                      </div>
                  <div class="control-item tablet-55">
                    <div class="control-label-top">ZIP</div>
                    <div class="control-textbox" [ngClass]="{'error': zip.touched && !zip.valid}">
                      <input type="text" name="zip" [(ngModel)]="userInfo.zip" #zip="ngModel" required />
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item desktop-half">
                <div class="control-label-top">Phone Number</div>
                <div class="control-textbox" [ngClass]="{'error': phone.touched && !phone.valid}">
                  <input type="tel" name="phone" minlength="5" [(ngModel)]="userInfo.phoneNumber" type="number" #phone="ngModel"
                    required [disabled]="sessionService.isLoggedIn === 'true'" />
                    <label class="error" for="phone"
                      *ngIf="phone.touched && !phone.valid">Only Digits</label>
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item desktop-half">
                <div class="control-label-top">Vendor Type</div>
                <div class="control-select dropdown-contact-us" [ngClass]="{'error': contactType.touched && !contactType.valid}">
                  <select name="contactType" [(ngModel)]="userInfo.contactType" #contactType="ngModel" required>
                    <option value="" selected>-Select-</option>
                    <option value="Retailer/Reseller">Retailer/Reseller</option>
                    <option value="Manufacturer">Manufacturer</option>
                    <option value="Distributor">Distributor</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div class="control-item desktop-half">
                <div class="control-label-top">Annual Sales</div>
                <div class="control-select dropdown-contact-us" [ngClass]="{'error': totalSales.touched && !totalSales.valid}">
                  <select name="totalSales" [(ngModel)]="userInfo.companyRevenue" #totalSales="ngModel" required>
                    <option value="" selected>-Select-</option>
                    <option value="0 - $999,999">0 - $999,999</option>
                    <option value="$1,000,000-$4,999,999">$1,000,000-$4,999,999</option>
                    <option value="$5,000,000-$9,999,9999">$5,000,000-$9,999,999</option>
                    <option value="$10,000,000-$19,999,999">$10,000,000-$19,999,999</option>
                    <option value="$20,000,000-$49,999,999">$20,000,000-$49,999,999</option>
                    <option value="$50,000,000-$99,999,999">$50,000,000-$99,999,999</option>
                    <option value="$100,000,000+">$100,000,000+</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item desktop-half">
                <div class="control-label-top">Primary Category</div>
                <div class="control-select dropdown-contact-us" [ngClass]="{'error': primaryCategory.touched && !primaryCategory.valid}">
                  <select name="primaryCategory" [(ngModel)]="userInfo.primaryCategory" #primaryCategory="ngModel"
                    required>
                    <option value="" selected>-Select-</option>
                    <option value="TV & Video">TV & Video</option>
                    <option value="Home Audio & Theater">Home Audio & Theater</option>
                    <option value="Camera, Photo & Video">Camera, Photo & Video</option>
                    <option value="Cell Phones & Accessories">Cell Phones & Accessories</option>
                    <option value="Headphones">Headphones</option>
                    <option value="Video Games">Video Games</option>
                    <option value="Bluetooth & Wireless Speakers">Bluetooth & Wireless Speakers</option>
                    <option value="Car Electronics">Car Electronics</option>
                    <option value="Wearable Technology">Wearable Technology</option>
                    <option value="Monitors">Monitors</option>
                    <option value="Accessories">Accessories</option>
                    <option value="Networking">Networking</option>
                    <option value="Drives & Storage">Drives & Storage</option>
                    <option value="Computer Parts & Components">Computer Parts & Components</option>
                    <option value="Software">Software</option>
                    <option value="Printers & Ink">Printers & Ink</option>
                    <option value="Office & School Supplies">Office & School Supplies</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div class="control-item desktop-half">
                <div class="control-label-top">How did you hear about us?</div>
                <div class="control-select dropdown-contact-us" [ngClass]="{'error': referedBy.touched && !referedBy.valid}">
                  <select name="referedBy" [(ngModel)]="userInfo.referedBy" #referedBy="ngModel" required>
                    <option value="" selected>-Select-</option>
                    <option value="Web Search">Web Search</option>
                    <option value="Trade Show">Trade Show</option>
                    <option value="Media">Media</option>
                    <option value="Sales Rep">Sales Rep</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item">
                <div class="control-label-top">
                  Tell us a little more about your company
                  <span class="control-label-optional">optional</span>
                </div>
                <div class="control-textarea height-80">
                  <textarea name="aboutYourCompany" class="textarea-contact-us" [(ngModel)]="userInfo.aboutYourCompany" #aboutYourCompany="ngModel"></textarea>
                </div>
              </div>
            </div>
            <div class="controls-row">
              <div class="control-item">
                  <re-captcha siteKey="{{recaptchaKey}}" (resolved)="resolved($event)"></re-captcha>
              </div>
          </div>
          <button class="form-submin-btn" type="submit"
            [disabled]="!f.valid || !isResolved" (click)="onSubmit(f,2,$event)">
            <div class="static-apps-loader" *ngIf="loading"></div>
            Send</button>
          </form>
          <div class="application-disclaimer">
            <p style="text-align: justify">HUBX will treat as confidential, and will not disclose to any third party,
              vendor's non-public financial information as provided on or in support of this Vendor Application. Vendor
              will provide HUBX with sales and marketing information relating to vendor's business, including information
              that is requested herein.</p>
          </div>
        </div>
        <div class="contact-schedule-col">
          <strong>Customer Support Hours of Operation</strong>
          <p>Mon-Fri: 8:00am - 5:30pm (Eastern)<br />Have you seen our FAQ section?<br />It contains answers to common
            questions about HUBX.</p>
          <!-- <a href="support.html" class="ask-question-link dark-blue">FAQ</a> -->
          <a  class="ask-question-link dark" (click)="navigateTo('faq')">FAQ</a>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<app-error-modal [bodyText]="errorModal.bodyText" [title]="errorModal.title" [isError]="errorModal.isShown" (onConfirmError)="hideError()"></app-error-modal>
