import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ILookupMain } from '../../interface/ILookupMain';
import { IVendorItemAttributes } from '../../interface/IVendorItemAttributes';
import { IVendorItemInfo } from '../../interface/IVendorItemInfo';
import { IVendorDropDownData } from '../../interface/IvendorDropDownData';
import * as _ from 'lodash';
import { ItemSimpleComponent } from '../item-simple/item-simple.component';
import { FormGroup } from '@angular/forms';
import { IExw } from '../../interface/IExw';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-item-detailed',
  templateUrl: './item-detailed.component.html',
  styleUrls: ['./item-detailed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemDetailedComponent extends ItemSimpleComponent {
  @Input() condition: ILookupMain = {};
  @Input() warranty: ILookupMain = {};
  @Input() packaging: ILookupMain = {};
  @Input() restrictions: ILookupMain = {};
  @Input() exwOptions: IExw[];
  @Input() viewType = '';
  @Output() updateItem = new EventEmitter<IVendorItemInfo>();
  @Output() deleteItem = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() undoChange = new EventEmitter<IVendorItemInfo>();
  @Output() openCommentModal = new EventEmitter<FormGroup>();

  condVal: IVendorItemAttributes = {};
  warrantyVal: IVendorItemAttributes = {};
  packagingVal: IVendorItemAttributes = {};
  restrictionsVal: IVendorItemAttributes = {};
  statusVal: number;
  loader = false;
  businessStatusDropdown: IVendorDropDownData[] = [];
  skuDropDown: IVendorDropDownData[] = [];
  showRestrictionModal = false;
  environment = environment;

  getRestrictionCodeByValue(value: string) {
    if (this.restrictions && this.restrictions.values) {
      const restrictionObj = this.restrictions.values.find(restr => restr.value === value);
      if (restrictionObj) {
        return restrictionObj.code;
      }
    }
    return '';
  }

  applyChangetoUpdatedItem(control: string, val: any) {

    super.applyChangetoUpdatedItem(control, val);
    switch (control) {
      case 'exwPointValue':
        const valNumber = +val;
        if (this.item.exwPointValue !== valNumber) {
          this.itemRequest.itemInformation.exw = valNumber;
          this.updatedItem[control] = valNumber;
        } else {
          delete this.itemRequest.itemInformation.exw;
        }
        break;
      case 'condition':
        this.handleAttributeChange(control, val, this.condition);
        break;
      case 'warranty':
        this.handleAttributeChange(control, val, this.warranty);
        break;
      case 'packaging':
        this.handleAttributeChange(control, val, this.packaging);
        break;
      case 'restrictions':
        this.handleAttributeChange(control, val, this.restrictions);
        if (val === this.getRestrictionCodeByValue('Yes') &&
          !this.item.mappedAttributes.restrictions.description) {
          this.openRestrictionModal();
        } else if (val === this.getRestrictionCodeByValue('No') &&
          this.fg.controls.restrictionDescription.value) {
          this.fg.controls.restrictionDescription.patchValue('');
        }
        break;
      case 'restrictionDescription':
        if (this.item.mappedAttributes.restrictions.description !== val) {
          this.updatedItem.mappedAttributes.restrictions.description = val;
          this.updatedItem.attributes.filter(x => x.label === 'Restrictions')[0].description = val;
          this.itemRequest.itemInformation.vendorAttributes = this.updatedVendorAttrForRequest();
        } else {
          if (!this.isThereAChange()) {
            delete this.itemRequest.itemInformation.vendorAttributes;
          }
        }
        break;
      case 'comments':
        const comments = this.fg.getRawValue().comments;
        this.updatedItem.comments = comments;
        const change = comments.some((c, i) =>
          !this.item.comments[i] ||
          this.item.comments[i].caption !== c.caption ||  // it's disabled for now
          this.item.comments[i].comment !== c.comment
        );
        if (change || comments.length !== this.item.comments.length) {
          this.itemRequest.itemInformation.comments = comments;
        } else {
          delete this.itemRequest.itemInformation.comments;
        }
        break;
    }

  }

  handleAttributeChange(controlName, attribute: IVendorItemAttributes, attributeLookuplist: ILookupMain) {
    const currentAttr = this.item.mappedAttributes[controlName];
    if (!currentAttr || currentAttr.code !== attribute) {
      const attrNewArray = this.updatedItem.attributes.filter(a => a.label !== attributeLookuplist.name);
      const newAttribute = attributeLookuplist.values.find(c => c.code === attribute);
      if (newAttribute) {
        Object.assign(newAttribute, {
          attrId: attributeLookuplist.id,
          label: attributeLookuplist.name,
          iconUri: '',
          description: null
        });
        attrNewArray.push(newAttribute);
      }
      this.updatedItem.attributes = attrNewArray;
      this.updatedItem.mappedAttributes[controlName] = newAttribute;
      this.itemRequest.itemInformation.vendorAttributes = this.updatedVendorAttrForRequest();
    } else {
      // we need this in case the user changed but then put it back to what it was before
      this.updatedItem.mappedAttributes[controlName] = this.item.mappedAttributes[controlName];
      const index = this.updatedItem.attributes.findIndex(a => a.label === attributeLookuplist.name);
      if (index > -1) {
        this.updatedItem.attributes[index] = this.item.mappedAttributes[controlName];
      }

      this.itemRequest.itemInformation.vendorAttributes = this.updatedVendorAttrForRequest();
      if (!this.isThereAChange()) {
        delete this.itemRequest.itemInformation.vendorAttributes;
      }
    }
  }

  updatedVendorAttrForRequest() {
    return this.updatedItem.attributes.map(aVendor => {
      return {
        code: aVendor.code,
        value: aVendor.value,
        description: aVendor.description || '',
        vendorItemAttributeId: aVendor.attrId,
        name: aVendor.label
      };
    });
  }

  isThereAChange() {
    // e.g condition, restrictions, warranty
    const highLevelkeys = Object.keys(this.updatedItem.mappedAttributes);
    // e.g label, value, code
    const lowLevelKeys = Object.keys(this.item.mappedAttributes[highLevelkeys[0]]);

    const change = highLevelkeys.some(hk => {
      return lowLevelKeys.some(lk => {
        return ((this.item.mappedAttributes[hk][lk] && this.updatedItem.mappedAttributes[hk][lk]) &&
          this.item.mappedAttributes[hk][lk] !== this.updatedItem.mappedAttributes[hk][lk]);
      });
    });

    return change;
  }

  isPromoActive(item: IVendorItemInfo, promoLabel: string): boolean {
    return item.attributes.filter(attr => attr.label === promoLabel).length > 0;
  }

  getStatusValue(text: string): number {
    return this.dropDowns.skuStatus.filter(item => item.text === text)[0].value;
  }

  showCommentModal(): void {
    this.openCommentModal.emit(this.fg);
  }

  isRestriccionEnabled() {
    return this.updatedItem.mappedAttributes.restrictions.value === 'Yes';
  }

  closePopUp(): void {
    this.onClose.emit();
  }

  openRestrictionModal(): void {
    this.showRestrictionModal = true;
  }

  closeRestrictionModal() {
    event.stopPropagation();
    event.preventDefault();
    if (!this.fg.controls.restrictionDescription.value) {
      this.fg.controls.restrictions.patchValue(null);
    }
    this.showRestrictionModal = false;
  }

  undoItemUpdates(event: MouseEvent) {
    if (window.location.pathname.match(/pending-sold$/)) {
      const originalVal = this.itemService.generateItemFormGroup(this.item).value;
      this.fg.reset(originalVal, { emitEvent: false });
      return;
    }

    this.valueChangesSubscription.unsubscribe();
    super.undoItemUpdates(event);
  }
}
