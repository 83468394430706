<div class="repair">
  <div class="repair-content">
    <div class="repair-content-img">
      <i class="repair-content-img__bg"></i>
    </div>
    <div class="repair-content-title">Ugh!</div>
	<div class="repair-content-message">Sorry something broke</div>
	<div *ngIf="!isProduction"> {{errorMessage}}</div>
	<button class="repair-content-btn" (click)="navigateHome()">
      Back to Home
    </button>
  </div>
</div>