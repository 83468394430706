import { Component, OnInit, OnDestroy } from '@angular/core';
import { IVendorPendingSoldList } from '../../interface/IVendorPendingSoldList';
import { IVendorPendingSoldItem } from '../../interface/IVendorPendingSoldItem';
import { IVendorItemRequest } from '../../interface/IVendorItemRequest';
import { Subscription, Subject } from 'rxjs';
import { SharedSource } from '../../../core/shared-source';
import { IVendorPendingSoldFilter } from '../../interface/IVendorPendingSoldFilter';
import * as _ from 'lodash';
import { PpoSortCondition } from '../../enums/po-sort-condition';
import { ItemsComponent } from '../../products/items/items.component';
import { IVendorItemInfo } from '../../interface/IVendorItemInfo';
import { switchMap } from 'rxjs/operators';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-pending-sold',
  templateUrl: './pending-sold.component.html',
  styleUrls: ['./pending-sold.component.scss']
})
export class PendingSoldComponent extends ItemsComponent implements OnInit, OnDestroy {
  selectedCustomer = '';
  loading = false;
  searched = false;
  pendingPurchaseOrdersCopy: IVendorPendingSoldList;
  pendingPurchaseOrders: IVendorPendingSoldList;
  PpoSortCondition = PpoSortCondition;
  pendingSoldFilters: IVendorPendingSoldFilter = {
    vendor: {},
    searchString: ''
  };
  currentSort: PpoSortCondition = PpoSortCondition.Date;
  isAsc = false;

  subscriptions: Subscription[] = [];
  sharedSubjectSubscription: Subscription = new Subscription();
  selectedItemId$: Subject<string> = new Subject();
  isExporting = false;
  showItemList = true;

  ngOnInit() {
    this.vendorService.isPendingPo = true;
    this.vendorService.isPendingPoAll = true;
    this.selectedCustomer = sessionStorage.getItem('pending_po_all');

    setTimeout(() => {
      this.pubSubService.sharedSubject.next({ name: SharedSource.isPendingPo, data: true });
    }, 0);
    

    this.getPendingPurchaseOrders();
    this.getVendorDropDownData(false);

    this.subscribeToCustomerVendorChanges();
    this.subscribeToItemChanges();
  }

  ngOnDestroy() {
    this.pubSubService.sharedSubject.next({ name: SharedSource.isPendingPo, data: false });
    this.vendorService.isPendingPo = false;
    this.vendorService.isPendingPoAll = false;
    this.subscriptions.forEach(s => s.unsubscribe());
    this.sharedSubjectSubscription.unsubscribe();
  }

  subscribeToCustomerVendorChanges() {
    this.sharedSubjectSubscription.add(
      this.pubSubService.sharedSubject.subscribe(data => {
        if (data.name === SharedSource.changeCustomerVendor) {
          this.currentSort = PpoSortCondition.Date;
          this.isAsc = false;
          this.subscriptions.forEach(s => s.unsubscribe());
          this.subscribeToItemChanges();
          this.getPendingPurchaseOrders();
        }
      })
    );
  }

  getPendingPurchaseOrders(): void {
    this.loading = true;
    this.showItemList = true;
    this.loaderService.setLoaderVendorVisible(true);
    this.subscriptions.push(
      this.vendorService.getPendingSoldItems().subscribe(
        (data) => {
          if (data) {
            this.pendingPurchaseOrders = data;
            this.pendingPurchaseOrdersCopy = _.cloneDeep(this.pendingPurchaseOrders);
            if (this.pendingSoldFilters.searchString.length) {
              this.filterPendingPurchaseOrders();
            }
            this.sortOrders();
          } else {
            this.pendingPurchaseOrders = { revenue: 0, skusSold: 0, unitsSold: 0, details: [] };
          }
          this.loading = false;
          this.showItemList = this.pendingPurchaseOrders.details.length ? true : false;
          this.loaderService.setLoaderVendorVisible(false);
        },
        (err) => {
          this.loading = false;
          this.loaderService.setLoaderVendorVisible(false);
        }
      )
    );

  }

  verifyIfSearch(event): void {
    if (event.key === 'Enter' && this.pendingSoldFilters.searchString.length > 0) {
      this.searched = true;
      this.filterPendingPurchaseOrders();
    } else if (this.pendingSoldFilters.searchString.length === 0 && this.searched) {
      this.searched = false;
      this.pendingPurchaseOrders = _.cloneDeep(this.pendingPurchaseOrdersCopy);
    }
  }

  filterPendingPurchaseOrders() {
    this.pendingPurchaseOrders = _.cloneDeep(this.pendingPurchaseOrdersCopy);
    this.pendingPurchaseOrders.details = this.pendingPurchaseOrders.details.filter(ppo => {
      return ppo.itemCode.toLowerCase().startsWith(this.pendingSoldFilters.searchString.toLowerCase());
    });
  }

  refresh(): void {
    this.pendingSoldFilters.searchString = '';
    this.getPendingPurchaseOrders();
  }

  sortBy(field: PpoSortCondition): void {
    this.currentSort = field;
    this.isAsc = !this.isAsc;
    this.sortOrders(field);
  }

  sortOrders(property: string = this.currentSort): void {
    if (this.isAsc) {
      this.pendingPurchaseOrders.details.sort((a: IVendorPendingSoldItem, b: IVendorPendingSoldItem) =>
        ((a[property] < b[property]) ? -1 : ((a[property] > b[property]) ? 1 : 0)));
    } else {
      this.pendingPurchaseOrders.details.sort((a: IVendorPendingSoldItem, b: IVendorPendingSoldItem) =>
        ((a[property] > b[property]) ? -1 : ((a[property] < b[property]) ? 1 : 0)));
    }
  }

  highlightItem(item: IVendorPendingSoldItem): void {
    this.vendorService.updatedMappedItems.clear();
    const highlighted = this.pendingPurchaseOrders.details.find(i => i.selected);
    if (highlighted) {
      highlighted.selected = false;
    }
    item.selected = true;
    this.selectedItemId$.next(item.vendorItemId);
  }

  subscribeToItemChanges() {
    this.subscriptions.push(
      this.selectedItemId$.pipe(
        switchMap((id) => this.vendorService.getVendorItemById([id], false))
      ).subscribe(
        (data: any) => {
          if (data) {
            this.items = Object.assign([], data.items);
            const item: IVendorItemInfo = data['items'][0];
            this.openEditVendorItemInDetailedMode(item, 0);
          }
        }
      )
    );
  }

  closeDetailsModal() {
    const selectedPO = this.pendingPurchaseOrders.details.find(po => po.selected);
    if (selectedPO) { selectedPO.selected = false; }
    super.closeEditItemModal();
  }

  isExportBtnDisabled() {
    return this.loading || this.isExporting;
  }

  exportToCSV(): void {
    if (this.isExportBtnDisabled()) {
      return;
    }
    this.isExporting = true;
    this.subscriptions.push(
      this.vendorService.downloadPendingPOList().subscribe(data => {
        const arr = data.headers.get('content-disposition').split(';');
        const fileName = arr[1].split('"')[1];
        importedSaveAs(data.body, fileName);
        this.isExporting = false;
      })
    );
  }
}
