import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { IRegistrationData } from '../interfaces/IRegistrationData';
import { IRegistrationDataRequest } from '../interfaces/IRegistrationDataRequest';
import { IHotLeadRequest } from '../interfaces/IHotLeadRequest';

import { INav } from '../../buyer/interfaces/INav';
import { INavCampaign } from '../interfaces/INavCampaign';
import { IBuyerErrorModal } from '../../buyer/interfaces/model';
import { StaticService } from '../../static/static.service';

@Injectable({
  providedIn: 'root'
})
export class GuestService {
  restapiverb = 'register';
  userRole = '';
  restAPI = '';
  isRegistered = false;
  isLoggedIn = false;
  // private _isLogginPopupOpen = false;
  leadId = null;

  errorModal: IBuyerErrorModal = {
    bodyText: '',
    title: 'Server Error',
    isShown: false
  };

  constructor(private http: HttpClient, private router: Router,
    private authService: AuthService,
    public staticService: StaticService
  ) {
  }

  // Called when user clicks the register button after filling out the registration form.
  registerUser(userData: IRegistrationDataRequest): Observable<any> {
    this.restAPI = 'guests';
    const url = environment.adminUrl + this.restAPI;
    return this.http.post<any>(url, userData);
  }

  registerHotLeadUser(userData: IHotLeadRequest): Observable<string> {
    this.restAPI = 'guests';
    const url = environment.adminUrl + this.restAPI;
    return this.http.put<string>(url, userData);
  }

  getGuestEmail(email?: string): Observable<{}> {
    if (email) {
      this.restAPI = 'api/guests/email/' + email;
    } else {
      this.restAPI = 'api/guests/email/' + this.leadId;
    }
    const url = environment.adminBaseUrl + this.restAPI;
    return this.http.get(url);
  }

  getAllTypesEmail(email?: string): Observable<{}> {
    if (email) {
      this.restAPI = 'api/guests/emailExist/' + email;
    } else {
      this.restAPI = 'api/guests/emailExist/' + this.leadId;
    }
    const url = environment.adminBaseUrl + this.restAPI;
    return this.http.get(url);
  }

  getGuestEmailByEmail(email: string): Observable<{}> {
    this.restAPI = 'api/guests/email/' + email;
    const url = environment.adminBaseUrl + this.restAPI;
    return this.http.get(url);
  }
  getGuestAuthRealm(email: string): Observable<{}> {
    this.restAPI = `api/guests/connection/${email}`;
    const url = environment.adminBaseUrl + this.restAPI;
    return this.http.get(url);
  }
}

