import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ISplitOrderLine } from '../../interfaces/ISplitorderLine';
import { ISplitOrderLinePost } from '../../interfaces/ISplitOrderLinePost';
import { IOrderListCombo } from '../../interfaces/IOrderListCombo';
import { OrdersService } from '../orders/orders.service';

import * as models from '../../interfaces/model';
import { SharedService } from '../../../shared/shared.service';
import { TopService } from '../../../shared/services/top.service';

@Component({
  selector: 'app-split-order',
  templateUrl: './split-order.component.html',
  styleUrls: ['./split-order.component.scss']
})
export class SplitOrderComponent implements OnInit {
  selectedOrder: models.IOrder;
  mainSelect = '0';
  orderLines: ISplitOrderLine[] = [];
  orderLinesPost: ISplitOrderLinePost;
  orderList: IOrderListCombo[] = [];
  targetOrder = 0;
  loading = false;
  moreThanQty = false;
  errorModal: models.IBuyerErrorModal = {
    bodyText: '',
    title: 'Server Error',
    isShown: false
  };
  nothingFoundTitle = 'No More Lines';
  nothingFoundMessage = '';
  nothingFoundButtonText = 'Back to Order History';


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ordersService: OrdersService,
    private sharedService: SharedService,
    public topService: TopService,
  ) {}

  ngOnInit() {
    this.selectedOrder = this.route.snapshot.data['order'];
    this.orderList = this.route.snapshot.data['ordersList'] || [];

    this.createOrderList();
  }

  changedMain(): void {
    if (this.mainSelect === '0') {
      this.targetOrder = 0;
    } else {
      this.targetOrder = this.orderList[0].docEntry;
    }
  }

  errorOnQty(): void {
    this.moreThanQty = true;
  }

  reset(): void {
    this.createOrderList();
  }

  submit(): void {
    this.topService.loading = true;
    this.orderLinesPost = {
      lines: []
    };
    this.orderLines.filter(o => o.lineSelected).forEach(line => {
      this.orderLinesPost.lines.push({
        movedDocEntry: line.movedDocNumber,
        lineNum: line.lineNum,
        movedQuantity: line.movedQuantity,
      });
    });
    this.ordersService.splitLinePost(this.selectedOrder.docEntry, this.orderLinesPost).subscribe(
      (data) => {
        if (data.orderStatus === 'closed') {
          this.selectedOrder.orderDetails = [];
          this.orderLines = [];
        } else {
          this.selectedOrder = data;
          this.createOrderList();
        }
        this.topService.loading = false;
      },
      (err) => {
        this.topService.loading = false;
        this.sharedService.handleBuyerHttpError(err, this.errorModal);
      }
    );
  }

  linesSelected(): boolean {
    return this.orderLines.filter(o => o.lineSelected).length === 0;
  }

  createOrderList(): void {
    this.orderLines = [];
    this.selectedOrder.orderDetails.forEach(l => {
      this.orderLines.push(
        {
          lineSelected: false,
          lineNum: l.lineNum,
          quantity: l.quantity,
          itemName: l.itemName,
          itemCode: l.itemCode,
          unitPrice: l.unitPrice
        });
    });
  }

  alertConfirmBtn(): void {
    if (this.moreThanQty) {
      this.moreThanQty = false;
    }
  }

  orderHistory(): void {
    this.router.navigate(['/user/orders']);
  }

  showError(errorModal: models.IBuyerErrorModal): void {
    this.errorModal = errorModal;
  }

  hideError(): void {
    this.errorModal.isShown = false;
  }
}
