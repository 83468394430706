import { IUserFilterPayload } from './../../interfaces/IUserFilter';
import { Component, OnInit, EventEmitter, Output, OnDestroy, Input } from '@angular/core';
import { TopService } from '../../../shared/services/top.service';
import { Options as SliderOptions } from 'ng5-slider';
import { UserFiltersService } from '../user-filters.service';
import { ItemsService } from '../../../shared/services/items.service';
import { Subscription } from 'rxjs';
import { IUserFilter, IPreselectedUserFilter } from '../../interfaces/IUserFilter';
import { IExw } from '../../interfaces/IExw';
import { ITopNav } from '../../interfaces/ITopNav';
import * as _ from 'lodash';
import * as models from '../../../buyer/interfaces/model';
import { IManufacturer } from '../../interfaces/IManufacturer';
import { UserFilterDropdownEnum } from '../../enums/UserFilterDropdownEnum';
import { OutletsService } from '../../outlets/outlets.service';
import { ItemFilterService } from '../../../shared/item-filter/item-filter.service';
import { UserFilterSourceEnum } from '../../enums/userFilterSource';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { TopFiltersEnum } from '../../enums/topFiltersEnum';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { AttrtypeEnum } from '../../enums/AttrTypeEnum';
import { AttrDataTypeEnum } from './../../enums/AttrDataTypeEnum';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-user-filters-modal',
  templateUrl: './user-filters-modal.component.html',
  styleUrls: ['./user-filters-modal.component.scss']
})
export class UserFiltersModalComponent implements OnInit, OnDestroy {
  @Output() closeFilterModal = new EventEmitter();
  @Input() userFilterId: string;
  @Input() preselectedUserFilter: IPreselectedUserFilter;
  subscriptions: Subscription[] = [];
  UserFilterDropdownEnum = UserFilterDropdownEnum;
  UserFilterSourceEnum = UserFilterSourceEnum;
  validForm = false;
  duplicatedFilterName = false;
  userFilterNameInvalid = false;
  activeRequests = 0;
  activeRequestsForInitialization = 0;

  initFormAnimation = false;
  showPreselectForm = false;
  activeScroll = false;
  searchesList = false;

  initAnimation = false;
  animateIn = false;
  animateOut = false;
  calledFromUserPage = false;
  priceRangeOptions: SliderOptions;
  minPrice = 0;
  maxPrice = 10000;
  plusSignSuffix = '+';
  initSlider = false;
  minPriceFrm = new FormControl();
  maxPriceFrm = new FormControl();

  promotions: ITopNav[];
  categories: ITopNav[];
  subcategories: ITopNav[];
  manufacturers: IManufacturer[];
  conditions: models.AttributeValue[];
  exws: IExw[];

  userFilter: IUserFilter;
  disableField;

  requestManCondExw = true;
  forceToCreate = false;

  constructor(
    public topService: TopService,
    public userFiltersService: UserFiltersService,
    public itemsService: ItemsService,
    public itemFilterService: ItemFilterService,
    public outletsService: OutletsService,
    private location: Location,
    public router: Router
  ) { }

  ngOnInit() {
    this.initializeVariables();
    this.validateForm();
    this.animateIn = true;
    // this.itemFilterService.searchesList = false;
    document.documentElement.classList.add('gg-modal');
    if (this.topService.isMobile) {
      document.documentElement.classList.add('is-modal');
    }

    if (this.preselectedUserFilter) {
      this.userFilter = this.preselectedUserFilter.userFilter;
      this.calledFromUserPage = this.userFiltersService.filterCalledFromUserPage();
      // this.showPreselectForm = this.calledFromUserPage ? false : true;
    }

    this.onPriceChange();
    this.initialize();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.itemFilterService.preselectedUserFilter = null;
    this.initAnimation = false;
    this.activeScroll = false;
    this.initFormAnimation = false;
    document.documentElement.classList.remove('gg-modal');
    if (this.topService.isMobile) {
      document.documentElement.classList.remove('is-modal');
    }
    this.itemsService.userFilterModalOpen = false;
  }


  close() {
    this.animateIn = false;
    this.animateOut = true;
    setTimeout(() => {
      this.animateOut = false;
      this.closeFilterModal.emit();
      this.itemsService.userFilterModalOpen = false;
    }, 200);
  }

  onScroll(event: MouseEvent) {
    if (event.target && (event.target as Element).scrollTop > 0) {
      this.activeScroll = true;
    } else {
      this.activeScroll = false;
    }
  }

  initializeVariables() {
    this.priceRangeOptions = this.getDefaultPriceRangeOptions();
    this.userFilter = this.getDefaultUserFilter();
    this.disableField = this.getDefaultDisableFields();
    this.setDefaultDropdownsData();
  }

  getDefaultPriceRangeOptions(): SliderOptions {
    return {
      floor: this.minPrice,
      ceil: this.maxPrice,
      animate: false,
      hidePointerLabels: true,
      hideLimitLabels: true
    };
  }

  getDefaultUserFilter(): IUserFilter {
    return {
      name: '',
      promotionNavigationId: '',
      categoryNavigationId: '',
      manufacturerId: '',
      subcategoryNavigationId: '',
      price: {
        min: this.minPrice,
        max: this.maxPrice,
      },
      conditionId: '',
      exw: null,
      source: UserFilterSourceEnum.globalSearch,
      filter: ''
    };
  }

  getDefaultDisableFields() {
    return {
      categoryOutlet: true,
      subcategoryOutlet: true,
      subcategorySiteWide: true,
    };
  }

  setDefaultDropdownsData(): void {
    this.categories = [{ navChilds: [], navId: '', navName: 'Select Category' }];
    this.subcategories = [{ navChilds: [], navId: '', navName: 'All Sub Categories' }];
    this.getPromotions();

    if (!this.conditions) {
      this.conditions = [{ count: '', id: '', name: '', userValue: '', value: 'All Conditions' }];
      this.exws = [{ count: null, text: 'All EXW', value: null }];
      this.manufacturers = [{ count: null, logoUrl: '', selected: null, id: '', name: 'All Manufacturers' }];
    }
  }

  getPromotions() {
    this.promotions = [
      { navChilds: null, navId: '', navName: 'All Features' },
      this.topService.getTopFilterByName(TopFiltersEnum.justLaunched),
      this.topService.getTopFilterByName(TopFiltersEnum.priceDrop),
      this.topService.getTopFilterByName(environment.currentDeal.displayLabel)
    ];
    if (this.promotions[1].navName === '') {
      setTimeout(() => {
        this.getPromotions();
        return;
      }, 300);
    }
  }

  maxPriceSuffix() {
    +this.userFilter.price.max === this.maxPrice ? this.plusSignSuffix = '+' : this.plusSignSuffix = '';
  }

  onPriceChange() {
    this.subscriptions.push(
      this.minPriceFrm.valueChanges.pipe(
        debounceTime(700)
      ).subscribe(newMinPrice => {
        if(this.userFilter.price.min != newMinPrice){
          this.userFilter.price.min = newMinPrice;
          this.minPriceFrm.setValue(newMinPrice);
          this.maxPriceSuffix();
        }
      })
    );

    this.subscriptions.push(
      this.maxPriceFrm.valueChanges.pipe(
        debounceTime(700)
      ).subscribe(newMaxPrice => {
        if(this.userFilter.price.max != newMaxPrice){
          this.userFilter.price.max = newMaxPrice;
          this.maxPriceFrm.setValue(newMaxPrice);
        }
        this.maxPriceSuffix();
      })
    );
  }

  prices() {
    setTimeout(() => {
      this.initSlider = true;
    }, 500);
    this.maxPriceSuffix();
  }

  resetDropdowns(list: UserFilterDropdownEnum[]) {
    if (list.includes(UserFilterDropdownEnum.all) || list.includes(UserFilterDropdownEnum.category)) {
      this.userFilter.categoryNavigationId = '';
    }

    if (list.includes(UserFilterDropdownEnum.all) || list.includes(UserFilterDropdownEnum.subcategory)) {
      this.userFilter.subcategoryNavigationId = '';
    }

    if (list.includes(UserFilterDropdownEnum.all) || list.includes(UserFilterDropdownEnum.manufacturer)) {
      this.userFilter.manufacturerId = '';
    }

    if (list.includes(UserFilterDropdownEnum.all) || list.includes(UserFilterDropdownEnum.condition)) {
      this.userFilter.conditionId = '';
    }

    if (list.includes(UserFilterDropdownEnum.all) || list.includes(UserFilterDropdownEnum.exw)) {
      this.userFilter.exw = null;
    }

    if (list.includes(UserFilterDropdownEnum.all) || list.includes(UserFilterDropdownEnum.promotional)) {
      this.userFilter.promotionNavigationId = '';
    }
  }

  async initialize() {
    this.validForm = false;
    this.prices();
    this.calledFromUserPage = this.userFiltersService.filterCalledFromUserPage();
    // this.showPreselectForm = (this.calledFromUserPage) ? false : true;

    if (this.requestManCondExw) {
      await this.getManufacturersConditionsExws();
      if(!this.userFiltersService.userFilterList?.length) {
        this.userFiltersService.getUserFilters();
      }
      this.requestManCondExw = false;
    }

    if ((this.userFilterId || this.userFilter.id) && !this.forceToCreate) { // update
      // this.getUserFilter();
    } else { // create
      this.forceToCreate = false;
      if (this.userFilter.source === UserFilterSourceEnum.globalSearch) {
        this.getCategoriesSiteWide();
      }
    }
  }

  getManufacturersConditionsExws(): Promise<boolean> {
    this.activeRequests++;
    this.activeRequestsForInitialization++;
    this.wordingManufacturer(this.userFilter.source);
    return new Promise(resolve => {
      this.subscriptions.push(
        this.userFiltersService.getManufacturersConditionsExws()
          .subscribe(
            ([attributes, exws, manufacturers]) => {
              // saving theses lists so we don't have to make extra calls
              this.itemsService.allAttributes = [...attributes];
              this.itemsService.allExwPoints = [...exws];

              const conditionAttr = attributes.find(x => x.name === 'Condition');
              if (conditionAttr) {
                this.conditions = [this.conditions[0]].concat(conditionAttr.attributeValues);
              }
              this.exws = [this.exws[0]].concat(exws);
              this.manufacturers = [this.manufacturers[0]].concat(manufacturers);

              resolve(true);
              this.activeRequests--;
              this.activeRequestsForInitialization--;

              // Populating dropdown
              if (this.userFilter.categoryNavigationId && this.userFilter.source === UserFilterSourceEnum.manufacturerOutlet) {
                const selectedManufacturer = this.manufacturers.find(x => x.id === this.userFilter.manufacturerId);
                this.userFilter.manufacturerId = selectedManufacturer.id;
                this.onSelectManufacturerOutlet(selectedManufacturer, false);
              }

            },
            (err) => {
              console.log('Error trying to get manufacturers, conditions and exws: ' + err);
              this.activeRequests--;
              this.activeRequestsForInitialization--;
            })
      );
    });
  }

  toggleOutletForm(source: UserFilterSourceEnum) {
    if (this.activeRequests > 0 || source === this.userFilter.source) {
      return;
    }
    const name = this.userFilter.name;
    this.initAnimation = true;
    this.resetDropdowns([UserFilterDropdownEnum.all]);
    this.initializeVariables();
    this.userFilter.source = source;
    this.userFilter.name = name;

    this.wordingManufacturer(source);

    this.forceToCreate = true;
    this.initialize();
  }


  // SITE WIDE
  getCategoriesSiteWide() {
    if (!this.itemsService.navigationTree) {
      setTimeout(() => {
        this.getCategoriesSiteWide();
        return;
      }, 200);
    }
    const navigationTree = this.itemsService.navigationTree.filter(x => x.categoryId);
    this.categories = [this.categories[0]].concat(navigationTree);

    // Populating dropdown
    if (this.userFilter.categoryNavigationId) {
      const selectedCategory = this.categories.find(x => x.navId === this.userFilter.categoryNavigationId);
      this.onSelectCategorySiteWide(selectedCategory, false);
    }
  }

  onSelectCategorySiteWide(category: ITopNav, resetDropdown = true) {
    if (this.activeRequests > 0 || !category) {
      return;
    }

    if (resetDropdown) {
      this.resetDropdowns([UserFilterDropdownEnum.subcategory]);
    }
    if (category.navChilds) {
      this.subcategories = [this.subcategories[0]]
        .concat(category.navChilds.filter(x => x.navName !== 'New' && x.navName !== 'Refurbished'));
      this.disableField.subcategorySiteWide = false;

      if (this.subcategories.length === 2) {
        this.userFilter.subcategoryNavigationId = this.subcategories[1].navId;
        this.disableField.subcategorySiteWide = true;
      }
    } else {
      this.subcategories = [this.subcategories[0]];
      this.disableField.subcategorySiteWide = true;
    }
  }

  // OUTLET
  onSelectManufacturerOutlet(manufacturer: IManufacturer, resetDropdown = true) {
    if (this.activeRequests > 0) {
      return;
    }
    if (resetDropdown) {
      this.resetDropdowns([UserFilterDropdownEnum.category, UserFilterDropdownEnum.subcategory]);
    }

    this.categories = [{ navChilds: [], navId: '', navName: 'Select Category' }];
    this.subcategories = [{ navChilds: [], navId: '', navName: 'All Sub Categories' }];
    this.getCategoriesOutlet();
  }

  getCategoriesOutlet() {
    this.activeRequests++;
    this.subscriptions.push(
      this.userFiltersService.getCategoriesOutlet(this.userFilter.manufacturerId)
        .subscribe(
          data => {
            this.categories = [this.categories[0]]
              .concat(data.navChilds
                .filter(x => !['Todays Deals', 'Price Drop', 'Just Launched'].includes(x.navName)));

            this.disableField.categoryOutlet = this.categories.length === 1;
            if (this.categories.length === 2) {
              this.disableField.categoryOutlet = true;
              this.userFilter.categoryNavigationId = this.categories[1].navId;
            }

            if (this.userFilter.categoryNavigationId) {
              const category: ITopNav = this.categories.find(x => x.navId === this.userFilter.categoryNavigationId);
              if (category && category.navChilds) {
                this.disableField.subcategoryOutlet = false;
                this.subcategories = [this.subcategories[0]].concat(category.navChilds);
              } else {
                this.subcategories = [this.subcategories[0]];
              }
            }

            this.activeRequests--;
          },
          (err) => {
            console.log('Error trying to get categories by manufacturer: ' + err);
            this.activeRequests--;
          })
    );
  }

  onSelectCategoryOutlet(category: ITopNav) {
    if (this.activeRequests > 0) {
      return;
    }
    this.resetDropdowns([UserFilterDropdownEnum.subcategory]);
    if (category.navChilds) {
      this.disableField.subcategoryOutlet = false;
      this.subcategories = [this.subcategories[0]]
        .concat(category.navChilds.filter(x => x.navName !== 'New' && x.navName !== 'Refurbished'));
      this.disableField.subcategoryOutlet = false;

      if (this.subcategories.length === 1) {
        this.disableField.subcategoryOutlet = true;
      }

      if (this.subcategories.length === 2) {
        this.userFilter.subcategoryNavigationId = this.subcategories[1].navId;
        this.disableField.subcategoryOutlet = true;
      }
    } else {
      this.subcategories = [this.subcategories[0]];
      this.disableField.subcategoryOutlet = true;
    }
  }

  async save() {
    if (this.activeRequests > 0 || !this.validForm) {
      return;
    }
    const userFilterPayload: IUserFilterPayload = await this.prepareDataToSave();

    if (this.userFilterId || this.userFilter.id) { // update
      // TODO:
    } else { // create
      this.activeRequests++;

      this.subscriptions.push(
        this.userFiltersService.createUserFilter(userFilterPayload)
          .subscribe(
            (filter: IUserFilterPayload) => {
              this.activeRequests--;
              this.userFiltersService.getUserFiltersListOnly();
              this.itemFilterService.searchesList = true;
              if (this.calledFromUserPage) {
                this.close();
              }
            },
            (err) => {
              console.log('Error trying to create an user filter: ' + err);
              this.activeRequests--;
            })
      );
    }
  }

  async prepareDataToSave(): Promise<IUserFilterPayload> {
    const filter = await this.convertUserFilterToIRequest();
    const userFilterPayload: IUserFilterPayload = {
      name: this.userFilter.name,
      manufacturerId: this.userFilter.manufacturerId,
      price: {
        min: this.userFilter.price.min,
        max: this.userFilter.price.max,
      },
      conditionId: this.userFilter.conditionId,
      exw: this.userFilter.exw,
      source: this.userFilter.source,
      filter: JSON.stringify(filter),
    };
    return userFilterPayload;
  }

  convertUserFilterToIRequest(): Promise<models.IRequest> {
    const attributes: models.IReqAttribute[] = [];

    if (this.userFilter.conditionId) {
      attributes.push({
        attrType: AttrtypeEnum.vendorItem,
        attrDataType: AttrDataTypeEnum.multiple,
        id: '3389ee35-d719-4bb4-bd0a-ac190f6b4a8a', // Condition
        values: [this.userFilter.conditionId]
      });
    }

    if (this.userFilter.manufacturerId) {
      attributes.push({
        attrType: AttrtypeEnum.manufacturer,
        values: [this.userFilter.manufacturerId]
      });
    }

    let categoryId = '';
    let savedFilterCategory = '';
    const categoryNavigationId = this.userFilter.subcategoryNavigationId || this.userFilter.categoryNavigationId;
    if (categoryNavigationId) {
      outerLoop:
      for (let i = 0; i < this.categories.length; i++) {
        const currentNavigation = this.categories[i];
        if (currentNavigation.navId === categoryNavigationId) {
          categoryId = currentNavigation.categoryId;
          savedFilterCategory = currentNavigation.filterId;
          break;
        }

        if (currentNavigation.navChilds) {
          for (let j = 0; j < currentNavigation.navChilds.length; j++) {
            const navigationChild = currentNavigation.navChilds[j];
            if (navigationChild.navId === categoryNavigationId) {
              categoryId = navigationChild.categoryId;
              savedFilterCategory = navigationChild.filterId;
              break outerLoop;
            }
          }
        }
      }
    }

    const resolveObj = {
      attributes: attributes,
      pagination: {
        pageNumber: 1,
        pageSize: 9999
      },
      savedFilter: savedFilterCategory,
      exwPoint: this.userFilter.exw,
      price: {
        minPrice: this.userFilter.price.min,
        maxPrice: this.userFilter.price.max
      },
      searchQuery: null,
      orderBy: null,
      inStock: this.userFilter.inStock,
      categoryId: categoryId
    };

    return new Promise(resolve => {
      if (this.userFilter.promotionNavigationId !== '') {
        const savedFilterPromotion = this.promotions.filter(x => x.navId === this.userFilter.promotionNavigationId)[0].filterId;

        this.activeRequests++;
        this.userFiltersService.getAttributes(savedFilterPromotion)
          .subscribe(
            attrs => {
              const selectedAttr = attrs.find(x => x.selected);
              if (selectedAttr) {
                resolveObj.attributes.push({
                  attrType: AttrtypeEnum.vendorItem,
                  attrDataType: AttrDataTypeEnum.tag,
                  id: selectedAttr.id
                });
              }
              resolveObj.savedFilter = savedFilterPromotion;
              resolve(resolveObj);
              this.activeRequests--;
            },
            (err) => {
              console.log('Error trying to get categoryId of promotion: ' + err);
              this.activeRequests--;
            });
      } else {
        resolve(resolveObj);
      }
    });

  }

  validateForm(): void {
    this.validForm = true;
    this.duplicatedFilterName = false;
    this.userFilterNameInvalid = false;

    this.userFilter.name = this.userFilter.name.trim();
    if (!this.userFilter.name.length) {
      this.validForm = false;
      this.userFilterNameInvalid = true;
    }

    if (this.userFiltersService.userFilterList?.find(uf => uf.name.toLowerCase() === this.userFilter.name.toLowerCase())) {
      this.validForm = false;
      this.duplicatedFilterName = true;
    }

    if (this.showPreselectForm) {
      return;
    }

    if (this.userFilter.source === UserFilterSourceEnum.globalSearch) {
      if (this.userFilter.promotionNavigationId === '' && this.userFilter.categoryNavigationId === '') {
        this.validForm = false;
      }
    }

    if (this.userFilter.source === UserFilterSourceEnum.manufacturerOutlet) {
      if (this.userFilter.promotionNavigationId === '' && this.userFilter.manufacturerId === '') {
        this.validForm = false;
      }
    }
  }

  wordingManufacturer(_source: UserFilterSourceEnum) {
    this.manufacturers[0].name = _source === UserFilterSourceEnum.manufacturerOutlet ? 'Select Manufacturer' : 'All Manufacturers';
  }
}
