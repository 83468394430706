  <div class="wrapper-header" [class.fixed]="offerService.selectedOffer?.offerEvents?.length">
    <a (click)="clickLogoToReload()">
      <img src="../../assets/images/logo-white-title.svg" width="76" alt="HUBX" class="logo _white-title" />
    </a>
    <nav class="nav-wrap" [class.nav-open]="navToggle">
      <ul class="nav" (click)="closeNav()">
        <li>
        	<a class="nav-link" [routerLink]="['/vendor/user/profile']" routerLinkActive="active">
		        <i class="icon">
		          <img src="assets/images/icons/profile/profile.svg" width="24" height="24" alt="User Profile" />
		        </i>
		        <span>User Profile</span>
	        </a>
	    </li>
        <li>
        	<a class="nav-link" [routerLink]="['/vendor/user/mobile_notifications']" routerLinkActive="active">
	            <i class="icon">
	              <img src="assets/images/icons/profile/mobile-sms.svg" width="24" height="24"
	                alt="Mobile | SMS" />
	            </i>
	            <span>Mobile | SMS</span>
            </a>
        </li>
	    <li>
	    	<a class="nav-link" [routerLink]="['/vendor/user/offers']" routerLinkActive="active">
	    		<i class="icon">
					<img src="assets/images/icons/rebranding/offers-header.svg"  width="24" height="24"
	                alt="My Offers"/>
				</i>
				<span>My Offers</span>
				<ng-container>
					<span class="badge" *ngIf="offerService.offersSummary?.unseenEventCount > 0">
						{{offerService.offersSummary?.unseenEventCount}}
					</span>
				</ng-container>
		    </a>
		</li>
      </ul>
    </nav>
    <div class="toggle-mobile-menu" [class.nav-open]="navToggle" (click)="toggleNav($event)">
      <div class="top"></div>
      <div class="middle"></div>
      <div class="bottom"></div>
    </div>
  </div>