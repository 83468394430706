import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { OrdersService } from './orders.service';

import * as models from '../../interfaces/model';
import { Observable } from 'rxjs';
import { IOrderListCombo } from '../../interfaces/IOrderListCombo';

@Injectable()
export class OrdersListResolve implements Resolve<models.IOrder[]> {
  constructor(private ordersService: OrdersService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<IOrderListCombo[]> {
    return this.ordersService.getOpenOrdersShort(route.paramMap.get('docEntry'))
    }
}
