export interface INotificationDetail {
    newCount: number;
    seenCount: number;
    notificationType: NotificationTypesEnum;
}

export enum NotificationFiltersEnum {
    all,
    priceDrop,
    archive,
    moreInventory,
    waitlist
}

export enum NotificationTypesEnum {
    priceDrop,
    moreInventory,
    waitlist,
    priceDropRejected,
    moreInventoryRejected
}

export enum eNotificationAction {
    add,
    remove,
    restore,
    archive
}

export enum NotificationSortingDirection {
  asc,
  desc
}
