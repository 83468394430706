import { Component, OnInit } from '@angular/core';
import { IMyBidsLeftNavTotals } from '../../../../buyer/interfaces/IMyBidsLeftNavTotals';
import { AuctionsService } from '../../auctions.service';

@Component({
  selector: 'app-my-bids-left-nav',
  templateUrl: './my-bids-left-nav.component.html',
  styleUrls: ['./my-bids-left-nav.component.scss']
})
export class MyBidsLeftNavComponent implements OnInit {
  constructor(public auctionsService: AuctionsService) { }

  ngOnInit() {}

}
