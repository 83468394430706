<div class="loader" *ngIf="topService.loading"></div>
<div class="container-fluid split-order-wrap" *ngIf="orderLines && orderLines.length > 0">

  <div class="container split-container">
    <a class="rebranding-back-btn" [routerLink]="['/user/orders']">
      <i class="rebranding-back-btn__icon"></i>
    </a>
    <div class="col title">
      Split Order {{selectedOrder.docNumber}}
    </div>

<div class="col-12">
    <div class="row top-head">

       <div class="col move-to">

         <label>Move to:</label>
         <select class="existing-order-select" t [(ngModel)]="mainSelect" (change)="changedMain()">
           <option value='0'>New Order</option>
           <option *ngIf="orderList.length > 0 " value='1'>Existing Order</option>
         </select>
         <select class="target-order-select" [ngClass]="{hide: mainSelect == '0'}"
                 [(ngModel)]="targetOrder">
           <option *ngFor="let orderL of orderList" value={{orderL.docEntry}}>
             {{orderL.docNumber}}
           </option>
         </select>
       </div>

       <div class="col-5 buttons">
         <div class="col control-buttons-wrap">
           <div class="text-right">
             <div class="row float-right">
               <div class="col-6">
                 <button [disabled]='linesSelected() || topService.loading' class="reset" (click)="reset()">Reset</button>
               </div>
               <div class="col-6 text-right">
                 <button class="submit" (click)="submit()"
                         [disabled]='targetOrder===-1   ||   linesSelected() || topService.loading'>
                         <div *ngIf="loading" class="loader"></div>
                         Move
                 </button>
               </div>
             </div>
           </div>
         </div>
       </div>

     </div>
  </div>
  </div>

  <div class="container-fluid bottom-controls-container">
    <div class="container">
      <div class="buttons">

        <div class="row float-right">
          <div class="col-6">
            <button [disabled]='linesSelected() || topService.loading' class="reset" (click)="reset()">Reset</button>
          </div>
          <div class="col-6 text-right">
            <button class="submit" (click)="submit()"
                    [disabled]='targetOrder===-1   ||   linesSelected() || topService.loading'>
                    <div *ngIf="loading" class="loader"></div>
                    Move
            </button>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>

    </div>
  </div>


  <div *ngIf="orderLines">
    <div *ngFor="let line of orderLines">
      <app-split-line    [orderList]="orderList" (QuantityChange)='errorOnQty()' [orders]="orders" [line]="line" [targetOrder]="targetOrder"
                      [mainSelect]="mainSelect"></app-split-line>
    </div>
  </div>
</div>

<ng-container *ngIf="orderLines && orderLines.length === 0">
    <app-nothing-found-buyer [title]="nothingFoundTitle" [message]="nothingFoundMessage"
      [buttonText]="nothingFoundButtonText" [loading]="topService.loading" (clickEmitter)="orderHistory()">
    </app-nothing-found-buyer>
</ng-container>

<div *ngIf="moreThanQty" class="reset-pwd-modal split-modal">
  <div class="add-to-wrap animated" [class.zoomIn]="moreThanQty" [class.zoomOut]="animateOut">
    <div class="modal-content">
      <div class="modal-header">
        <button (click)="alertConfirmBtn()" type="button" class="close" aria-label="Close"></button>
        <div class="d-flex align-items-start">
          <div class="align-self-center title-icon">
            <img src="../../../assets/images/reset-password-icon.svg" width="48" height="48"/>
          </div>
          <div class="align-self-center modal-title">Quantity Error</div>
        </div>
      </div>
      <div class="modal-body">
        <span>Illegal  should be > 0 and < quantity</span>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="alertConfirmBtn()" class="btn btn-modal btn-modal-confirm">Got it</button>
      </div>
    </div>
  </div>
</div>


<app-error-modal [bodyText]="errorModal.bodyText" [title]="errorModal.title" [isError]="errorModal.isShown" (onConfirmError)="hideError()"></app-error-modal>
