import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Email } from '../../buyer/interfaces/Email';
import {EmailService} from '../../services/email.service';
import { SessionService } from '../../services/session.service';
import { TopService } from '../../shared/services/top.service';

@Component({
  selector: 'app-user-support',
  templateUrl: './user-support.component.html',
  styleUrls: ['./user-support.component.scss']
})
export class UserSupportComponent implements OnInit {
  complete = false;
  public message = '';
  private supportEmail = 'support@hubx.com';
  private isBuyer = false;
  private isVendor = false;

  constructor(private userService: UserService,
    private emailService: EmailService,
    private sessionService: SessionService,
    public topService: TopService) { }

  ngOnInit() {
    if (this.sessionService.userRole === 'BUYER')
      this.isBuyer = true;
    else if (this.sessionService.userRole === 'SELLER')
      this.isVendor = true;

    if (!this.userService.userProfile) {
      this.userService.getLoggedInUserProfile().subscribe(data => {
        this.userService.userProfile = Object.assign({},data);
      });
    }
    if ((this.isBuyer || this.isVendor) && !this.userService.bpCode && !this.userService.companyName) {
      this.userService.getProfile().subscribe(data => {
        this.userService.bpCode = data.bpCode;
        this.userService.companyName = data.companyName;
      });
    }
  }

  submitForm(): void {
    var companyInfo = this.isBuyer || this.isVendor
     ? `Business Partner ${this.userService.companyName} (${this.userService.bpCode}), `
     :  '';
    companyInfo += `a ${this.sessionService.userRole} user on HUBX:`;

    const data: Email = {
      senderName: `${this.userService.userProfile.firstName} ${this.userService.userProfile.lastName}`,
      from: this.userService.userProfile.email,
      recipient: this.supportEmail,
      subject: 'HELP REQUESTED',
      htmlContent: `
        The following is a support message receieved from <b>${companyInfo}</b><br><br>
        ${this.message.trim()}`
    };
    this.emailService.send(data).subscribe(resp => {
      // console.log(resp);
      this.complete = true;
    });
  }

  resetForm():void{
    this.complete = false;
    this.message = '';
  }
}
