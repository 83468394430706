import { ITopNav } from './../../buyer/interfaces/ITopNav';
import { TopFiltersEnum } from './../../buyer/enums/topFiltersEnum';
import { Component, OnInit, HostListener, ViewChild, ElementRef, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ItemsService } from '../services/items.service';
import { TopService } from '../services/top.service';
import { LeftCategoriesService } from '../services/left-categories.service';
import { SharedService } from '../shared.service';
import { IItemManufacturerFilter } from '../../buyer/interfaces/IItemManufacturerFilter';
import { IDropdown } from '../../vendor/interface/IDropdown';
import { sortTypesEnum } from '../enums/sort-types';
import { saveAs as importedSaveAs } from 'file-saver';
import { IManufacturer } from '../../buyer/interfaces/IManufacturer';
import { ActivatedRoute, Router } from '@angular/router';
import { AttributeValue, Attribute } from '../../buyer/interfaces/model';
import { OutletsService } from '../../buyer/outlets/outlets.service';
import { GlobalSearchService } from '../../services/search.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ItemFilterService } from './item-filter.service';
import { SessionService } from '../../services/session.service';
import * as models from '../../buyer/interfaces/model';
import { TopCategoriesFileSaverService } from '../../buyer/catalog/top-categories/top-categories-file-saver.service';
import { IExw } from '../../buyer/interfaces/IExw';
import { UserService } from '../../user/user.service';
import { GuestService } from '../services/guest.service';
import { AuthService } from '../../auth/auth.service';
import { Options as SliderOptions } from 'ng5-slider';

import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';
import { Subscription } from 'rxjs';
import { ISortingOptions } from '../../buyer/interfaces/ISortingOptions';
import { AttrtypeEnum } from '../../buyer/enums/AttrTypeEnum';
import { AttrDataTypeEnum } from '../../buyer/enums/AttrDataTypeEnum';
import { IUserFilter } from '../../buyer/interfaces/IUserFilter';
import { UserFiltersService } from '../../buyer/user-filters/user-filters.service';
import { UserFilterSourceEnum } from '../../buyer/enums/userFilterSource';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';

interface expandedPreviousState {
  id: string
  expanded: boolean
}
@Component({
  selector: 'app-item-filter',
  templateUrl: './item-filter.component.html',
  styleUrls: ['./item-filter.component.scss'],
  animations: [
    trigger('toggleList', [
      state('*, void', style({ width: '0px', minWidth: '0px', maxWidth: '0px' })),
      state('true', style({ width: '*', minWidth: 'auto', maxWidth: '100%' })),
      state('expanded', style({ width: '*', minWidth: 'auto', maxWidth: '100%' })),
      transition('true => expanded', animate('100ms ease')),
      transition('void => true, * => true', animate('500ms ease')),
      transition('expanded => void', animate('500ms ease'))

    ])
  ]
})
export class ItemFilterComponent implements OnInit, OnDestroy {
  @Input() set itemsReady(val: boolean) {
    if (val && this.itemFilterService.userFilter) {
      this.patchValues();
    }
  }
  private _selectedSort: ISortingOptions;
  selectedEwx: IExw;
  isAsc = false;
  manufacturer = null;
  shouldStick = false;
  showMobileFilter = false;
  conditionWidth = 122;
  manufacturerWidth = 145;
  itemsRequest: models.IRequest = {};
  isExporting = false;
  subscriptions: Subscription[] = [];
  showSortButton = true;
  sortTypesEnum = sortTypesEnum;
  selectedManufacturerOption;
  disableClickOut = true;
  widthToHideSortByModel = 1350;

  preloaderFilterList = Array(20);
  preloaderActive = false;
  itemsListFeched: any = false;

  // If you change maxPrice, make sure you set the condition at applyPriceRange()
  minPrice = 0;
  maxPrice = 10000;
  sliderMinPrice = 0;
  sliderMaxPrice = 10000;
  isMinMaxValid = true;
  plusSignSuffix: string;
  topFiltersEnum = TopFiltersEnum;
  priceRangeOptions: SliderOptions = {
    floor: 0,
    ceil: 10000,
    animate: false,
    hidePointerLabels: true,
    hideLimitLabels: true,
    noSwitching: true,
    enforceStep: false,
    enforceRange: false,
  };
  priceRangeFilterVisible = false;
  environment = environment;

  selectedManufName;
  selectedCondName;
  selectedSortName;
  selectedExwName;
  lodash = _;
  sortingOptions: ISortingOptions[] = [];

  secondLevelExpandedState: expandedPreviousState[] = [];
  thirdLevenExpandedState: expandedPreviousState[] = [];
  savedSearchId;

  protected topFilterActive: ITopNav;

  get showSortByModel() {
    return this.itemFilterService.selectedZeroLevel &&
      [environment.currentDeal.displayLabel, 'Price Drop', 'Just Launched'].includes(this.itemFilterService.selectedZeroLevel.navName);
  }

  getSortingOptions(): ISortingOptions[] {
    if (this.itemsService.isPastPurchase) {
      return [
        {
          value: sortTypesEnum.purchasedate,
          text: 'Purchase Date'
        },
      ];
    }
    const sortArray = [
      {
        value: sortTypesEnum.price,
        text: 'Sort by Price'
      },
      {
        value: sortTypesEnum.qty,
        text: 'Sort by Available'
      }
    ];
    if (this._router.url.match(/price_drop/)) {
      sortArray.push({ value: sortTypesEnum.pricedrop, text: 'Sort by Most Recent' });
    } else if (this._router.url.match(/just_launched/)) {
      sortArray.push({ value: sortTypesEnum.justlaunched, text: 'Sort by Most Recent' });
    }

    // for responsive only
    sortArray.push({ value: sortTypesEnum.model, text: 'Sort by Model' });

    return sortArray;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.showSortButton = this.showSortDirectionButton();

    if (window.innerWidth <= this.widthToHideSortByModel && this.itemsService.currentSort === sortTypesEnum.model) {
      const dropdownData: IDropdown = {
        dropdownId: 'sortDropdown', action: 'resetToDefaultTitle',
        values: [{ value: sortTypesEnum.model, text: 'Sort by Model' }]
      };
      this.sharedService.setDropdown(dropdownData);
    } else if (window.innerWidth > this.widthToHideSortByModel && this.itemsService.currentSort === sortTypesEnum.model) {
      this.selectedSort = null;
      this.resetSortDropdown();
    }
  }

  showSortDirectionButton(): boolean {
    if (window.innerWidth <= this.widthToHideSortByModel && this.itemsService.currentSort === sortTypesEnum.model) {
      return false;
    }
    return true;
  }

  // selections
  set selectedSort(opt: ISortingOptions) {
    this._selectedSort = opt;

    setTimeout(() => {
      this.showSortButton = this.showSortDirectionButton();
    });
  }

  get selectedSort(): ISortingOptions {
    if (window.innerWidth > this.widthToHideSortByModel && this.itemsService.currentSort === sortTypesEnum.model) {
      return { value: sortTypesEnum.model, text: 'Select Sort' };
    }

    if (this.itemsService.isPastPurchase) {
      return this.sortingOptions.find(s => s.value === sortTypesEnum.purchasedate);
    }
    // Select default sort if none
    if (!this._selectedSort) {
      if (this.itemsService.currentSort === sortTypesEnum.none) {
        this.resetSortDropdown();
      }
      return this.sortingOptions.find(s => s.value === this.itemsService.currentSort);
    }

    return this._selectedSort;
  }

  modelFg: FormGroup = new FormGroup({
    sortByModel: new FormControl(),
    inStock: new FormControl(false)
  }
  );

  isFiltered = false;
  conditionSavedSelected = 'All Conditions';
  @ViewChild('scrollFilter', { read: ElementRef }) public scrollFilter: ElementRef<any>;

  firstLevelListingDropdownStyles = {
    left: '0px',
    display: 'none'
  };

  secondLevelListingDropdownStyles = {
    left: '0px',
    top: '0px',
    display: 'none'
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public itemsService: ItemsService,
    public topService: TopService,
    public itemFilterService: ItemFilterService,
    public topCategoriesFileSaverService: TopCategoriesFileSaverService,
    public leftCategoriesService: LeftCategoriesService,
    public sharedService: SharedService,
    public outletsService: OutletsService,
    public userService: UserService,
    public pubSubService: PubSubService,
    protected _router: Router,
    private _searchService: GlobalSearchService,
    private _userFilterService: UserFiltersService,
    public sessionService: SessionService,
    private guestService: GuestService,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.topService.gridView = false;
    this.subscribeToAllControlChanges();
    this.minPrice = this.itemsService.itemsRequest.price ? this.itemsService.itemsRequest.price.minPrice : this.minPrice;
    this.maxPrice = this.itemsService.itemsRequest.price ? this.itemsService.itemsRequest.price.maxPrice : this.maxPrice;
    this.maxPriceSuffix();
    this.preloaderActive = true;
    this.sortingOptions = this.getSortingOptions();

    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.savedSearchId = params.id;
      }));
  }

  protected setTopFilterActive(url: string) {
    let topFilterName = this.topService.getTopFilterByUrl(url)
    this.topFilterActive = this.topService.getTopFilterByName(topFilterName)
  }

  protected updateManufacturerDataSelectedManufacturer() {
    //updating the selected manufacturer 
    for (const manufacturer of this.itemsService.manufacturersData) {
      manufacturer.selected = manufacturer.id === (this.itemFilterService.selectedManufacturer as Attribute)?.id;
    }
  }

  subscribeToAllControlChanges() {
    const inStockControl = this.modelFg.get('inStock');
    const sortModelControl = this.modelFg.get('sortByModel');

    if (inStockControl) {
      inStockControl.valueChanges
        .subscribe(v => {
          this.itemsService.paginationInfo = null;
          //updating the selected manufacturer 
          this.updateManufacturerDataSelectedManufacturer();

          this.itemsService.itemsRequest.inStock = v;
          this.itemsService.skipManufacturers = true;
          this.itemsService.skipConditions = false;
          this.itemsListFeched = false;
          this.itemsService.getItems();
        });
    }

    if (sortModelControl) {
      sortModelControl.valueChanges
        .subscribe(v => {
          this.itemsService.paginationInfo = null;
          //updating the selected manufacturer 
          this.updateManufacturerDataSelectedManufacturer();

          if (v) {
            this.sortByModel();
          } else {
            // this.topService.gridView = true;
            this.itemsService.currentSort = sortTypesEnum.none;
            this.itemsListFeched = false;
            this.itemsService.getItems();
            const dropdownData: IDropdown = {
              dropdownId: 'sortDropdown', action: 'resetToDefaultTitle',
              values: [this.selectedSort || { value: 0, text: 'Select Sort' }]
            };
            this.sharedService.setDropdown(dropdownData);
          }
        });
    }

    this.subscriptions.push(
      this.pubSubService.sharedSubject.subscribe(myEvent => {
        /*if (myEvent.name === SharedSource.selectedZeroLevelChanged) {
          if (sortModelControl) {
            sortModelControl.setValue(false, { emitEvent: false });
          }
        }*/
        if (myEvent.name === SharedSource.clearAllFilter) {
          this.selectedSort = undefined;
          const dropdownData: IDropdown = {
            dropdownId: 'sortDropdown', action: 'resetToDefaultTitle',
            values: [this.selectedSort || { value: 0, text: 'Select Sort' }]
          };
          this.sharedService.setDropdown(dropdownData);
        }

        if (myEvent.name === SharedSource.itemsListChanged) {
          this.preloaderActive = false;
        }

        if (myEvent.name === SharedSource.itemsListFetched) {
          this.itemsListFeched = myEvent.data;
          this.setTopFilterActive(this._router.url);
        }

        if (myEvent.name === SharedSource.manufacturersDataChanged) {
          if (this.itemFilterService.isOutletsSinglePage) {
            this.outletsService.selectedOutlet = this.itemFilterService.selectedManufacturer;
          }
        }

      })
    );
  }

  resetSortDropdown() {
    const dropdownData: IDropdown = {
      dropdownId: 'sortDropdown', action: 'resetToDefaultTitle', values: [{
        value: 0,
        text: 'Select Sort'
      }]
    };
    this.sharedService.setDropdown(dropdownData);
  }

  // TODO refactor all sorting methods in one
  sortByModel() {
    if (this.selectedSort && this.selectedSort.value !== sortTypesEnum.model) {
      // resets sort dropdown to no value
      this.resetSortDropdown();
      this.selectedSort = undefined;
    }

    this.itemsService.currentSort = sortTypesEnum.model;
    this.itemsService.sortItemsByCurrentType(false);
  }

  sortItems(value: sortTypesEnum): void {

    //updating the selected manufacturer 
    this.updateManufacturerDataSelectedManufacturer();

    this.itemsService.scrollToTopVar = true;
    this.itemsService.currentSort = value;

    if (value === sortTypesEnum.none) {
      this.itemsListFeched = false;
      this.itemsService.getItems();
      return;
    }

    this.itemsService.sortItemsByCurrentType(this.isAsc);
  }

  applyPriceRange() {
    this.itemsService.skipConditions = false;
    this.itemsService.skipEXW = false;
    this.itemsService.skipManufacturers = true;
    this.itemsService.paginationInfo = null;

    if (this.minPrice === 0 && this.maxPrice === 10000) {
      this.itemsService.itemsRequest.price = null;
    } else if (this.minPrice === 0) {
      this.itemsService.itemsRequest.price = { minPrice: null, maxPrice: this.maxPrice }
    } else if (this.maxPrice === 10000) {
      this.itemsService.itemsRequest.price = { minPrice: this.minPrice, maxPrice: null }
    } else {
      this.itemsService.itemsRequest.price = { minPrice: this.minPrice, maxPrice: this.maxPrice }
    }

    this.itemFilterService.minPrice = this.minPrice;
    this.itemFilterService.maxPrice = this.maxPrice;
    this.itemsListFeched = false;

    //updating the selected manufacturer 
    this.updateManufacturerDataSelectedManufacturer();

    this.itemsService.getItems();
    if (this.priceRangeFilterVisible) {
      this.priceRangeFilterVisible = false;
    }
  }

  clearPriceRange() {
    this.itemsService.paginationInfo = null;
    //updating the selected manufacturer 
    this.updateManufacturerDataSelectedManufacturer();

    this.resetPriceRange();
    this.itemsListFeched = false;
    this.itemsService.getItems();
    setTimeout(() => {
      this.plusSignSuffix = '+';
    }, 0);
  }

  resetPriceRange() {
    this.minPrice = 0;
    this.maxPrice = 10000;
    this.sliderMinPrice = 0;
    this.sliderMaxPrice = 10000;
    this.itemFilterService.minPrice = 0;
    this.itemFilterService.maxPrice = 10000;
    this.isMinMaxValid = true;
    this.itemsService.itemsRequest.price = null;
  }

  maxPriceSuffix() {
    if (this.minPrice > this.maxPrice) {
      this.sliderMinPrice = 0;
      this.sliderMaxPrice = 10000;
      this.isMinMaxValid = false;
    } else {
      this.sliderMinPrice = this.minPrice;
      this.sliderMaxPrice = this.maxPrice;
      this.itemFilterService.minPrice = this.sliderMinPrice;
      this.itemFilterService.maxPrice = this.sliderMaxPrice;
      this.isMinMaxValid = true;
    }
    this.maxPrice === 10000 ? this.plusSignSuffix = '+' : this.plusSignSuffix = '';
  }

  adjustPriceInputs() {
    this.minPrice = this.sliderMinPrice;
    this.maxPrice = this.sliderMaxPrice;
    this.itemFilterService.minPrice = this.sliderMinPrice;
    this.itemFilterService.maxPrice = this.sliderMaxPrice;
    this.isMinMaxValid = true;
    this.disableClickOut = false;

    setTimeout(() => {
      this.disableClickOut = true;
    }, 500);
    this.maxPrice === 10000 ? this.plusSignSuffix = '+' : this.plusSignSuffix = '';
  }

  changeSortingDirection() {
    this.isAsc = !this.isAsc;
    if (!this.selectedSort) { return; }

    if (this.selectedSort.value === sortTypesEnum.model) {
      return;
    }

    if (this.itemsService.isPastPurchase) {
      this.pubSubService.sharedSubject.next({
        name: SharedSource.sortChangedInFilter,
        data: { sortBy: 'purchasedate', isAsc: this.isAsc }
      });
      return;
    }
    this.itemsService.paginationInfo = null;
    this.sortItems(this.selectedSort.value);
  }

  getTotalManufacturerSelected(): number {
    return this.itemsService.getTotalManufacturerSelected();
  }

  selectCondition(condition: AttributeValue) {

    //updating the selected manufacturer 
    this.updateManufacturerDataSelectedManufacturer();

    if (!this.itemFilterService.userFilter){
      this.resetPriceRange();
    }
    // e.g ../outlets/dell/todays_deals
    if (this.outletsService.topcatNavName) {
      this.outletsService.resetEverythingButManufacturers(true);
    }
    this.itemFilterService.selectedCondition = (condition.name) ? condition : undefined;
    this.itemsService.skipManufacturers = true;
    this.itemsService.skipConditions = (condition.value !== 'All Conditions');
    this.itemsService.skipEXW = false;
    this.itemsService.paginationInfo = null;

    this.itemsService.setSelectedCondition(condition.name);
  }

  selectExw(exw: IExw) {
    this.itemsService.paginationInfo = null;
    //updating the selected manufacturer 
    this.updateManufacturerDataSelectedManufacturer();

    this.selectedEwx = exw;
    this.itemsService.skipEXW = (exw.value !== -1);
    this.itemsService.skipManufacturers = true;
    // no exw selected
    if (exw.value === -1) {
      this.itemsService.itemsRequest.exwPoint = null;
    } else {
      this.itemsService.itemsRequest.exwPoint = exw.value;
    }
    this.itemsListFeched = false;
    this.itemsService.getItems();
  }

  selectSort(sort: ISortingOptions) {
    this.itemsService.paginationInfo = null;
    if (sort.value === sortTypesEnum.model) {
      this.isAsc = false;
      this.selectedSort = sort;
      this.sortByModel();
      return;
    }
    if (this.itemsService.isPastPurchase) {
      this.pubSubService.sharedSubject.next({
        name: SharedSource.sortChangedInFilter,
        data: { sortBy: 'purchasedate', isAsc: this.isAsc }
      });
      // this.sortChanged.next({ sortBy: 'purchasedate', isAsc: this.isAsc });
      return;
    }

    // unselect the sort by model when user selects other sort
    this.modelFg.get('sortByModel').setValue(false, { emitEvent: false });
    this.selectedSort = sort;

    this.sortItems(sort.value);
  }

  checkWidth(target: string): void {
    if (target === 'condition') {
      const element = document.getElementsByClassName('condition-selected');
      this.conditionWidth = element[0].scrollWidth + 35;
    } else if (target === 'manufacturer') {
      const element = document.getElementsByClassName('manufacturer-selected');
      if (element[0].scrollWidth > 0) {
        this.manufacturerWidth = element[0].scrollWidth + 36;
      }
    }
  }


  selectAllFromManu(): void {
    this.changeManufacturer(this.outletsService.selectedOutlet);
  }

  changeManufacturer(manufacturer: Attribute): void {
    if (!this.itemFilterService.userFilter){
      this.resetPriceRange();
    }
    this.itemsService.skipConditions = false;
    this.itemsService.skipEXW = false;

    // e.g you are in price_drop of manufacturer
    if (this.outletsService.topcatNavName) {
      this.outletsService.clearTopNavInOutletDetail();
    }

    // if user clicks all manufacturers from dropdown
    if (!manufacturer.id) {
      this.resetOriginalNav(this.itemFilterService.selectedZeroLevel);
      return;
    }

    if (this.itemsService.isJustSoldUrl) {
      this.itemFilterService.selectedManufacturer = manufacturer;
      this.pubSubService.sharedSubject.next({ name: SharedSource.manufacturerChangedInFilter, data: { manufacturer: manufacturer.id } });
      return;
    }

    if (this.itemsService.isPastPurchase) {
      this.itemFilterService.selectedManufacturer = manufacturer;
      this.pubSubService.sharedSubject.next({ name: SharedSource.manufacturerChangedInFilter, data: { manufacturer: manufacturer.id } });
      return;
    }

    // just closing and opening the sliding
    if (!this.outletsService.isManufacturerOutlet() &&
      this.itemFilterService.selectedManufacturer?.id === manufacturer.id &&
      !this.itemFilterService.selectedSecondLevel &&
      (!this.itemFilterService.selectedCondition || this.itemFilterService.selectedCondition?.value === 'All Conditions') &&
      (!this.selectedEwx || this.selectedEwx?.text === 'EXW')) {
      this.itemFilterService.isSecondLevelExpanded = !this.itemFilterService.isSecondLevelExpanded;
      return;
    } else {
      if (!this.itemFilterService.userFilter){
        this.resetPriceRange();
        this.itemsService.resetSelectedConditions();
      }
      this.itemFilterService.isSecondLevelExpanded = true;
    }

    this.itemFilterService.selectedManufacturer = manufacturer;
    this.itemFilterService.selectedSecondLevel = undefined;
    this.itemFilterService.selectedThirdLevel = undefined;

    if (!this.itemFilterService.userFilter)
      this.itemsService.resetSelectedConditions();
    this.itemsService.skipManufacturers = true;

    // in topFilters we need to remove the category but not in floating nav
    // as in floating nav users navigate first to cat and then to manufacturer
    if (this.itemFilterService.selectedZeroLevel &&
      ([environment.currentDeal.displayLabel, 'Price Drop', 'Just Launched'].includes(this.itemFilterService.selectedZeroLevel.navName) ||
        this.itemsService.isCampainUrl)) {
      this.itemsService.itemsRequest.categoryId = '';
    }

    if (this.outletsService.isManufacturerOutlet()) {
      const transformedText = String(manufacturer.name.replace(/\s/g, '_')).toLowerCase();
      this.itemsService.itemsRequest.savedFilter = null;
      this.itemsService.itemsRequest.categoryId = '';
      this.itemsService.currentManufacturerNav = [];
      this.outletsService.selectedOutlet = manufacturer;
      this._router.navigate([`${this.userService.getUrlByRole(true)}/outlets/`, transformedText]);

      this.itemsService.getManufacturersBreakDownById(manufacturer.id);
    }

    this.itemsService.paginationInfo = null;
    this.itemsListFeched = false;
    if(this.itemFilterService.userFilter){
      this.itemsService.setSelectedManufacturer(manufacturer.id, true, false);
    } else {
      this.itemFilterService.selectedCondition = undefined;
      this.selectedEwx = undefined;
      this.itemsService.setSelectedManufacturer(manufacturer.id, true, true);
      this.getSecondLevelBreakDownCatalog(manufacturer.id);
    }

    this.outletsService.topcatNavName = undefined;
  }

  selectChildNav(childNavFilter, level) {
    // reset category
    this.itemsService.itemsRequest.categoryId = '';
    this.resetPriceRange();

    // e.g you are in price_drop of manufacturer
    if (this.outletsService.topcatNavName) {
      this.outletsService.clearTopNavInOutletDetail();
    }

    const filteredNavChild = (childNavFilter.navChilds) ?
      childNavFilter.navChilds.filter(nav => nav.count > 0) : [];

    childNavFilter.navChilds = filteredNavChild;
    if (level === 2) {

      if ((this.itemFilterService.selectedSecondLevel &&
        this.itemFilterService.selectedSecondLevel.filterId === childNavFilter.filterId) && !this.itemFilterService.selectedThirdLevel &&
        (!this.itemFilterService.selectedCondition ||
          (this.itemFilterService.selectedCondition && this.itemFilterService.selectedCondition.value === 'All Conditions'))) {
        this.itemFilterService.isThirdLevelExpanded = !this.itemFilterService.isThirdLevelExpanded;
        return;
      } else {
        this.itemFilterService.isThirdLevelExpanded = true;
      }

      this.itemFilterService.selectedSecondLevel = childNavFilter;
      this.itemFilterService.selectedThirdLevel = undefined;
      this.itemsService.paginationInfo = null;
      this.itemsListFeched = false;
    } else if (level === 3) {
      this.itemFilterService.selectedThirdLevel = childNavFilter;
      this.itemsService.paginationInfo = null;
      this.itemsListFeched = false;
    } else {
      return;
    }

    //updating the selected manufacturer 
    this.updateManufacturerDataSelectedManufacturer();

    // Setting skipManufacturers to False to load manufacturers on childNavs click
    this.itemsService.skipManufacturers = true;
    this.itemFilterService.selectedCondition = undefined;
    this.itemsService.resetSelectedConditions();
    // reset condition dropdown
    const dropdownData: IDropdown = { dropdownId: 'conditionDropdown', action: 'resetToDefaultTitle' };
    this.sharedService.setDropdown(dropdownData);

    // in topFilters we need to remove the category but not in floating nav
    // as in floating nav users navigate first to cat and then to manufacturer
    if (this.itemFilterService.selectedZeroLevel &&
      ([environment.currentDeal.displayLabel, 'Price Drop', 'Just Launched'].includes(this.itemFilterService.selectedZeroLevel.navName) ||
        this.itemsService.isCampainUrl)) {
      this.itemsService.itemsRequest.savedFilter = this.itemFilterService.selectedZeroLevel.filterId;
    }

    // preselect child if there's only 1
    if (level === 2 && childNavFilter.navChilds.length === 1) {
      this.selectChildNav(childNavFilter.navChilds[0], 3);
    } else {
      this.itemsService.selectAttrAndGetItems(childNavFilter);
    }
  }

  getSecondLevelBreakDownCatalog(manufacturerId: string) {
    if (this.itemsService.pageNavigationByManufaturers &&
      this.itemsService.pageNavigationByManufaturers.length &&
      this.itemsService.manufacturersData &&
      this.itemsService.manufacturersData.length) {
      const filteredList = this.itemsService.pageNavigationByManufaturers
        .find(pNav => pNav.manufacturerId === manufacturerId);

      this.itemFilterService.secondLevelCats = (filteredList) ?
        filteredList.navChilds.filter(nav => nav.count > 0) : [];
      return;
    }
    this.itemFilterService.secondLevelCats = [];
  }

  @HostListener('window:scroll', ['$event'])
  handleScrollEvent(e): void {
    if (window.pageYOffset > 29) {
      this.shouldStick = true;
    } else {
      this.shouldStick = false;
    }
  }

  isExportBtnDisabled() {
    return this.topService.loading || this.isExporting ||
      (this.itemsService.itemsData && !this.itemsService.itemsData.length);
  }

  exportToCSV(): void {
    if (this.isExportBtnDisabled()) {
      return;
    }

    if (this.userService.isGuest) {
      this.userService.guestPop();
    } else {
      this.isExporting = true;

      this.topCategoriesFileSaverService.downloadItemList(this.itemsService.itemsRequestHelper).subscribe(data => {
        const arr = data.headers.get('content-disposition').split(';');
        const fileName = arr[1].split('"')[1];
        importedSaveAs(data.body, fileName);
        this.isExporting = false;
      });
    }
  }

  hideMobilePopup(isCanceled?: boolean): void {
    this.showMobileFilter = false;
    this.isFiltered = false;
    this.sharedService.isMobileSortingPopup = false;
    if (!isCanceled) {
      this.itemsListFeched = false;
      this.itemsService.getItems();
    }
  }

  changeView() {
    this.topService.gridView = !this.topService.gridView;
  }

  scrollTo(direction: string): void {
    if (direction === 'right') {
      this.scrollFilter.nativeElement.scrollTo({ left: (this.scrollFilter.nativeElement.scrollLeft + 150), behavior: 'smooth' });
    } else if (direction === 'left') {
      this.scrollFilter.nativeElement.scrollTo({ left: (this.scrollFilter.nativeElement.scrollLeft - 150), behavior: 'smooth' });
    }
  }

  isOutletPage() {
    return !!this._router.url.match(/outlets/);
  }

  reloadSavedFilter(e?: any) {
    if (this.itemFilterService.userFilter) {
      if (e) { e.stopPropagation(); }
      this._userFilterService.selectedUserFilter ?
        this._userFilterService.navigateToItems(this._userFilterService.selectedUserFilter) :
        this._userFilterService.getUserFilterAndNavigate(this.savedSearchId);

    }
  }

  resetOriginalNav(navItem, level1?: number) {
    if (this.userService.isGuest && this.itemsService.isPastPurchase) { return; }

    if (level1) {
      // if it is first level dont reset that state
      // but reset all others after that
      this.clearAllSelections(true);
    } else {
      this.clearAllSelections();
    }

    this.itemsService.paginationInfo = null;

    if (this.itemFilterService.userFilter) {
      let userFilterWithoutManufacturerAttr = { ...this.itemFilterService.userFilter };
      if (!this.itemFilterService.isOutletUserFilter) {
        userFilterWithoutManufacturerAttr.attributes = userFilterWithoutManufacturerAttr.attributes?.filter((value) => {
          return value.attrType != AttrtypeEnum.manufacturer
        })
      }
      this.itemsService.itemsRequest = userFilterWithoutManufacturerAttr;
      this.itemsService.itemsRequest.savedFilter = null;
      this.itemsListFeched = false;
      this.itemsService.getItems();
      return;
    }

    this.itemsService.itemsRequest.exwPoint = null;
    this.itemsService.skipEXW = false;

    if (navItem && ['Just Sold', 'Past Purchases'].includes(navItem.navName)) {
      if (this.itemsService.isPastPurchase) {
        this.topService.pastPurchasesSearchString = '';
        this.pubSubService.sharedSubject.next({ name: SharedSource.pastPurchaseSearch, data: true });
      }
      if (this.itemsService.isJustSoldUrl) {
        this.pubSubService.sharedSubject.next({ name: SharedSource.manufacturerChangedInFilter, data: { manufacturer: undefined } });
        this.itemFilterService.justSoldManufacturers.forEach(m => m.selected = !m.id);
      }
      return;
    }
    this.itemsService.selectAttrAndGetItems(navItem);
  }

  resetSearch() {
    this.clearAllSelections();
    this._searchService.searchByURL();
  }

  clearAllSelections(exceptFirst?: boolean) {
    this.resetPriceRange();
    this.itemFilterService.selectedManufacturer = undefined;
    if (!exceptFirst) {
      this.itemFilterService.selectedFirstLevel = undefined;
    }
    this.itemFilterService.secondLevelCats = [];
    this.itemFilterService.selectedSecondLevel = undefined;
    this.itemFilterService.selectedThirdLevel = undefined;
    this.itemFilterService.selectedCondition = undefined;
    this.selectedEwx = undefined;
    this.itemsService.skipConditions = false;

    if (!this.itemFilterService.userFilter) {
      this.itemsService.itemsRequest.categoryId = '';
    }
    this.itemsService.itemsRequest.inStock = false;
    this.modelFg.controls['inStock'].setValue(false, { emitEvent: false });
    this.itemsService.resetSelectedManufacturers();
    this.itemsService.resetSelectedConditions();
    const dropdownData: IDropdown = { group: 'filter', action: 'resetToDefaultTitle' };
    this.sharedService.setDropdown(dropdownData);

    const exwDropdown: IDropdown = {
      dropdownId: 'exwDropdown', action: 'resetToDefaultTitle', values: [{
        value: -1,
        text: 'EXW'
      }]
    };

    this.sharedService.setDropdown(exwDropdown);

    if (this.topService.isMobile) {
      this.pubSubService.sharedSubject.next({ name: SharedSource.clearAllFilter, data: true });
    }

  }

  getCount() {
    if (this.itemsService.isJustSoldUrl && this.sharedService.justSoldValues) {
      return this.sharedService.justSoldValues.totalLineItems;
    }
    if (this.itemsService.isPastPurchase) {
      if (this.userService.isGuest) { return 0; }
      return this.sharedService.pastPurchasesTotal;
    }

    if (this.itemsService.paginationInfo) {
      return this.itemsService.paginationInfo.totalCount;
    }

  }

  protected getZeroLevelSearchCount() {
    var searchCount = 0;
    this.itemsService.manufacturersData.forEach(m => searchCount += m.count);
    return searchCount;
  }

  getManufacturersDataDropDown(): Attribute[] {
    if (this.itemsService.isPastPurchase) {
      return this.itemFilterService.pastPurchasesManufacturers;
    }
    if (this.itemsService.isJustSoldUrl) {
      return this.itemFilterService.justSoldManufacturers;
    }
    return this.itemsService.manufacturersData;
  }

  isKeyEnter(event) {
    if (event.key === 'Enter') {
      this.searchPastPurchase();
    }
  }

  searchPastPurchase() {
    this.pubSubService.sharedSubject.next({ name: SharedSource.pastPurchaseSearch, data: true });
  }

  patchValues() {
    const transformedFilter = this.itemsService.transFormFilter(this.itemsService.itemsRequest);

    this.itemFilterService.selectedManufacturer = (transformedFilter.selectedManufacturer) ? transformedFilter.selectedManufacturer :
      undefined;

    this.itemFilterService.selectedCondition = (transformedFilter.selectedCondition) ?
      transformedFilter.selectedCondition : undefined;

    this.selectedEwx = (transformedFilter.selectedExw) ? transformedFilter.selectedExw :
      this.itemsService.exwPoints[0];

  }

  ngOnDestroy() {
    if (this.subscriptions.length) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  closePriceRangeFilter(): void {
    if (this.priceRangeFilterVisible && this.disableClickOut) {
      this.priceRangeFilterVisible = false;
    }
  }

  openUserFilterModal(event: MouseEvent) {
    event.stopPropagation();
    this.sharedService.closeAllDropdowns();
    const isEdit = this._router.url.match(/\/user_filters\//) ? true : false;
    const isOutletForm = this._router.url.match(/outlets/) ? true : false;
    const condition = this.itemFilterService.selectedCondition;
    const exw = this.itemsService.exwPoints.find(x => x.value === this.itemsService.itemsRequest.exwPoint) ||
      { count: null, text: 'All EXW', value: null };

    let manufacturer = { id: '', name: '' } as any;
    const manufacturerAttr = this.itemsService.itemsRequest.attributes.find(a => a.attrType === AttrtypeEnum.manufacturer);
    if (manufacturerAttr) {
      const manufacturerId = manufacturerAttr ? manufacturerAttr.values[0] : '';
      manufacturer = this.itemsService.manufacturersData.find(x => x.id === manufacturerId);
    }


    const selectedNavigations = [];

    this.itemFilterService.searchesList = false;

    if (isEdit) {
      // TODO:
    } else {
      if (this.itemFilterService.selectedZeroLevel) {
        selectedNavigations.push(this.itemFilterService.selectedZeroLevel);
      }
      if (this.itemFilterService.selectedFirstLevel) {
        selectedNavigations.push(this.itemFilterService.selectedFirstLevel);
      }
      if (this.itemFilterService.selectedSecondLevel) {
        selectedNavigations.push(this.itemFilterService.selectedSecondLevel);
      }
      if (this.itemFilterService.selectedThirdLevel) {
        selectedNavigations.push(this.itemFilterService.selectedThirdLevel);
      }
    }

    let category, subcategory, promotion;
    const promotionsSiteWide = [
      this.topService.getTopFilterByName(TopFiltersEnum.justLaunched),
      this.topService.getTopFilterByName(TopFiltersEnum.priceDrop),
      this.topService.getTopFilterByName(environment.currentDeal.displayLabel)
    ];
    const promotionsOutlet: ITopNav[] = this.itemsService.currentManufacturerNav
      .filter((x: ITopNav) => ['Todays Deals', 'Just Launched', 'Price Drop'].includes(x.navName));

    const promotions = isOutletForm ? promotionsOutlet : promotionsSiteWide;

    if (promotions.some((currValue: ITopNav) => {
      return currValue?.navId == (selectedNavigations[0] as ITopNav)?.navId
    })) {
      promotion = selectedNavigations[0];
      category = selectedNavigations[1] ? selectedNavigations[1] : null;
      subcategory = selectedNavigations[2] ? selectedNavigations[2] : null;
    } else {
      category = selectedNavigations[0];
      subcategory = selectedNavigations.length > 1 ? selectedNavigations[selectedNavigations.length - 1] : null;
    }

    // handling redundant break down case, and others...
    if (category && !this._userFilterService.checkIfNavigationExists(category.navId) && !isOutletForm) {
      category.navId = this._userFilterService.getNavigationIdByCategoryId(category);
    }
    if (subcategory && !this._userFilterService.checkIfNavigationExists(subcategory.navId) && !isOutletForm) {
      subcategory.navId = this._userFilterService.getNavigationIdByCategoryId(subcategory);
    }

    if (isOutletForm && (promotion && promotion.navId)) {
      switch (promotion.navName) {
        case 'Todays Deals':
          promotion = this.topService.getTopFilterByName(environment.currentDeal.displayLabel);
          break;
        case 'Just Launched':
          promotion = this.topService.getTopFilterByName(TopFiltersEnum.justLaunched);
          break;
        case 'Price Drop':
          promotion = this.topService.getTopFilterByName(TopFiltersEnum.priceDrop);
          break;
      }
    }

    const userFilter: IUserFilter = {
      id: null, // isEdit ? this._userFilterService.selectedUserFilter.id : null,
      name: '', // isEdit ? this.itemFilterService.selectedZeroLevel.navName : '',
      categoryNavigationId: category && category.navId ? category.navId : '',
      subcategoryNavigationId: subcategory && subcategory.navId ? subcategory.navId : '',
      manufacturerId: manufacturer && manufacturer.id ? manufacturer.id : '',
      price: {
        min: this.itemFilterService.minPrice,
        max: this.itemFilterService.maxPrice,
      },
      conditionId: condition && condition.name ? condition.name : '',
      exw: exw ? exw.value : null,
      source: isOutletForm ? UserFilterSourceEnum.manufacturerOutlet : UserFilterSourceEnum.globalSearch,
      promotionNavigationId: promotion && promotion.navId ? promotion.navId : '',
      inStock: this.itemsService.itemsRequest.inStock
    };

    this.itemFilterService.preselectedUserFilter = {
      userFilter: userFilter,
      category: category && category.navName ? category.navName : 'All Categories',
      subcategory: subcategory && subcategory.navName ? subcategory.navName : 'All Sub Categories',
      manufacturer: manufacturer && manufacturer.name ? manufacturer.name : 'All Manufacturers',
      condition: condition && condition.value ? condition.value : 'All Conditions',
      exw: exw.text,
      price: `$${this.minPrice} - $${this.maxPrice}${this.maxPrice >= 10000 ? '+' : ''}`,
      promotion: promotion && promotion.navName ? promotion.navName : null,
      inStock: this.itemsService.itemsRequest.inStock
    };
    this.itemsService.userFilterModalOpen = true;
  }

  isNewSearch(): boolean {
    return !this._router.url.match(/\/user_filters\//);
  }

  showSaveUserFilterBtn(): boolean {
    return this.isNewSearch() && this.sessionService.userRole !== 'GUEST' &&
      !(this.itemsService.isJustSoldUrl || this.itemsService.isPastPurchase) &&
      !this.itemsService.isCampainUrl && !this.topService.searchString;
  }

  showViewUserFiltersBtn(): boolean {
    return !this.isNewSearch() && this.sessionService.userRole !== 'GUEST' &&
      !(this.itemsService.isJustSoldUrl || this.itemsService.isPastPurchase) &&
      !this.itemsService.isCampainUrl && !this.topService.searchString;
  }

  goToSavedFilters() {
    this._router.navigate(['/user/filters']);
  }

  getDefaultTitleForManufacturer(): string {
    this.selectedManufName = this.itemFilterService.selectedManufacturer ? this.itemFilterService.selectedManufacturer.name : 'All Manufacturers';
    return this.selectedManufName;
  }

  getDefaultTitleForCondition(): string {
    this.selectedCondName = this.itemFilterService.selectedCondition ? this.itemFilterService.selectedCondition.value : 'All Conditions';
    return this.selectedCondName;
  }

  getDefaultTitleForSort(): string {
    this.selectedSortName = this.selectedSort ? this.selectedSort.text : 'Select Sort';
    return this.selectedSortName;
  }

  getDefaultTitleForExw(): string {
    this.selectedExwName = this.selectedEwx ? this.selectedEwx.text : 'EXW';
    return this.selectedExwName;
  }

  private populateExpandedState(state: expandedPreviousState[], stateId, level = 2, expandedValueResolver = (stateId, level) => {
    return (this.itemFilterService.selectedManufacturer?.id === stateId) ? (level == 2) ? this.itemFilterService.isSecondLevelExpanded : this.itemFilterService.isThirdLevelExpanded : false
  }) {
    let index = state.findIndex((v, i) => v.id == stateId);
    if (index >= 0) {
      state[index] = {
        id: stateId,
        expanded: expandedValueResolver(stateId, level)
      }
    }
    else {
      state.push({
        id: stateId,
        expanded: expandedValueResolver(stateId, level)
      });
    }
  }

  closeOpenSecondLevelClass(manufacturerId) {
    return (this.secondLevelExpandedState.find((v, i) => v.id == manufacturerId))?.expanded ?? false;
  }

  closeOpenSecondLevelAnimation(manufacturerId) {
    let condition: any;
    let expanded = this.secondLevelExpandedState?.findIndex((v, i) => JSON.stringify(v) == JSON.stringify({
      id: manufacturerId,
      expanded: this.itemFilterService?.isSecondLevelExpanded
    }));

    if (
      this.itemFilterService.selectedManufacturer?.id === manufacturerId &&
      this.itemFilterService?.isSecondLevelExpanded &&
      expanded >= 0)
      condition = 'expanded';
    else if (
      this.itemFilterService.selectedManufacturer?.id === manufacturerId &&
      this.itemFilterService?.isSecondLevelExpanded &&
      expanded < 0)
      condition = true;
    else
      condition = 'void';

    this.populateExpandedState(this.secondLevelExpandedState, manufacturerId);

    return condition;
  }

  closeOpenThirdLevelClass(manufacturerId) {
    return (this.thirdLevenExpandedState.find((v, i) => v.id == manufacturerId)) ?? false
  }

  closeOpenThirdLevelAnimation(manufacturerId, filterId) {
    let condition: any;
    let expanded = this.thirdLevenExpandedState?.findIndex((v, i) => JSON.stringify(v) == JSON.stringify({
      id: manufacturerId,
      expanded: this.itemFilterService?.isThirdLevelExpanded
    }));

    if (
      this.itemFilterService.selectedSecondLevel?.navChilds?.length &&
      filterId === this.itemFilterService.selectedSecondLevel.filterId &&
      this.itemFilterService.selectedManufacturer?.id === manufacturerId &&
      this.itemFilterService.isThirdLevelExpanded &&
      expanded >= 0)
      condition = 'expanded';
    else if (
      this.itemFilterService.selectedSecondLevel?.navChilds?.length &&
      filterId === this.itemFilterService.selectedSecondLevel.filterId &&
      this.itemFilterService.selectedManufacturer?.id === manufacturerId &&
      !this.itemFilterService.isThirdLevelExpanded &&
      expanded >= 0)
      condition = 'void';
    else if (
      this.itemFilterService.selectedSecondLevel?.navChilds?.length &&
      filterId === this.itemFilterService.selectedSecondLevel.filterId &&
      this.itemFilterService.selectedManufacturer?.id === manufacturerId &&
      this.itemFilterService.isThirdLevelExpanded &&
      expanded < 0)
      condition = true;
    else if (
      this.itemFilterService.selectedSecondLevel?.navChilds?.length &&
      filterId === this.itemFilterService.selectedSecondLevel.filterId &&
      this.itemFilterService.selectedManufacturer?.id === manufacturerId &&
      !this.itemFilterService.isThirdLevelExpanded &&
      expanded < 0)
      condition = 'void';
    else
      condition = 'void';

    this.populateExpandedState(this.thirdLevenExpandedState, manufacturerId, 3);
    return condition;
  }

  closeOpenThirdLevelOutletAnimation(filterId) {
    let condition: any;
    let expanded = this.thirdLevenExpandedState?.findIndex((v, i) => JSON.stringify(v) == JSON.stringify({
      id: filterId,
      expanded: this.itemFilterService?.isThirdLevelExpanded
    }));

    if (
      this.itemFilterService.selectedSecondLevel?.filterId === filterId &&
      this.itemFilterService.isThirdLevelExpanded &&
      expanded >= 0)
      condition = 'expanded';
    else if (
      this.itemFilterService.selectedSecondLevel?.filterId === filterId &&
      this.itemFilterService.isThirdLevelExpanded &&
      expanded < 0)
      condition = true;
    else
      condition = 'void';

    this.populateExpandedState(this.thirdLevenExpandedState, filterId, 3, (stateId, level) => {
      return (this.itemFilterService.selectedSecondLevel?.filterId === stateId) ? this.itemFilterService.isThirdLevelExpanded : false
    });


    return condition;
  }

  getActiveNavCounter() {
    let count = 0;

    if (this.isOutletPage()) {
      // MANUFACTURER
      if (this.itemFilterService.selectedManufacturer) {
        count = this.outletsService.selectedOutlet?.count;
      }
      // 2nd LEVEL
      if (this.itemFilterService.isSecondLevelExpanded) {
        this.itemsService.currentManufacturerNav.forEach(manufacturer => {
          if (this.itemFilterService.selectedSecondLevel?.filterId === manufacturer.filterId) {
            count = manufacturer.count;
          }
          // 3rd LEVEL
          if (this.closeOpenThirdLevelClass(manufacturer.filterId)) {
            manufacturer.navChilds.forEach(manuChild => {
              if (this.itemFilterService.selectedSecondLevel?.navChilds?.length &&
                manufacturer.filterId === this.itemFilterService.selectedSecondLevel.filterId &&
                manuChild.filterId === this.itemFilterService.selectedThirdLevel?.filterId) {
                count = manuChild.count;
              }
            });
          }
        });
      }
    } else {
      // NAVIGATION LOGO BOX
      if (!this.itemFilterService.selectedManufacturer) {
        if (this.topFilterActive?.navName == environment.currentDeal.displayLabel) {
          count = this.topService.getTopFilterByName(environment.currentDeal.displayLabel).count
        } else if (this.topFilterActive?.navName == this.topFiltersEnum.priceDrop) {
          count = this.topService.getTopFilterByName(this.topFiltersEnum.priceDrop).count;
        } else if (this.topFilterActive?.navName == this.topFiltersEnum.justLaunched) {
          count = this.topService.getTopFilterByName(this.topFiltersEnum.justLaunched).count;
        } else if (!this.itemFilterService.userFilter) {
          if (this.itemFilterService.selectedZeroLevel) {
            count = this.itemFilterService.selectedZeroLevel.count;
          }
          if (this.itemFilterService.selectedFirstLevel) {
            count = this.itemFilterService.selectedFirstLevel.count;
          }
          if (this.itemFilterService.selectedSecondLevel) {
            count = this.itemFilterService.selectedSecondLevel.count;
          }
          if (this.itemFilterService.selectedThirdLevel) {
            count = this.itemFilterService.selectedThirdLevel.count;
          }
        }
      }

      if (this.topService.searchString == '' && !this.itemFilterService.userFilter) {
        count = this.getCount();
      } else {
        count = this.getZeroLevelSearchCount();
      }

      // MANUFACTURER LOGO BOX
      this.itemsService.manufacturersData.forEach(manuf => {
        if (this.itemFilterService.selectedManufacturer?.id === manuf.id) {
          count = manuf.count;
        }

        //2nd LEVEL
        if (this.closeOpenSecondLevelClass(manuf.id)) {
          this.itemFilterService.secondLevelCats.forEach(manuChild => {
            if (this.itemFilterService.selectedSecondLevel?.filterId === manuChild.filterId) {
              count = manuChild.count;
            }

            // 3RD LEVEL
            if (this.closeOpenThirdLevelClass(manuf.id)) {
              this.itemFilterService.selectedSecondLevel?.navChilds.forEach(catChild => {
                if (this.itemFilterService.selectedSecondLevel?.navChilds?.length &&
                  manuChild.filterId === this.itemFilterService.selectedSecondLevel.filterId &&
                  this.itemFilterService.selectedManufacturer?.id === manuf.id &&
                  catChild.filterId === this.itemFilterService.selectedThirdLevel?.filterId) {
                  count = catChild.count;
                }
              });
            }
          });
        }
      });
    }
    return count;
  }

}
