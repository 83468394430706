import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
// import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UserComponent } from './user.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserSupportComponent } from './user-support/user-support.component';
import { MobileNotificationsComponent } from './mobile-notifications/mobile-notifications.component';
import { OrdersComponent } from '../buyer/order-history/orders/orders.component';
import { SplitOrderComponent } from '../buyer/order-history/split-order/split-order.component';
import { OrdersResolve } from '../buyer/order-history/orders/orders.resolve';
// import { CardListComponent } from '../buyer/payments/card-list/card-list.component';
import { CreditCardListComponent } from '../buyer/credit-cards/credit-card-list/credit-card-list.component';
import { CreditCardGuard } from '../auth/credit-card.guard';

import { UserFiltersListComponent } from '../buyer/user-filters/user-filters-list/user-filters-list.component';
import { RedirectAfterBuyerLoginGuard } from '../auth/redirect-after-buyer-login.guard';
import { OrdersListResolve } from '../buyer/order-history/orders/orders-list.resolve';
import { AuthGuardBuyerService } from '../auth/auth-guard-buyer-service';
import { OffersComponent } from './offers/offers.component';
import { OffersResolver } from './offers/offers-resolver.service';

const routes: Routes = [
  {
    path: 'user',
    component: UserComponent,
    children: [
      { path: 'profile', component: UserProfileComponent },
      { path: 'mobile_notifications', component: MobileNotificationsComponent },
      { path: 'orders', component: OrdersComponent },
      { path: 'support', component: UserSupportComponent },
      {
        path: 'filters',
        component: UserFiltersListComponent,
        canActivate: [RedirectAfterBuyerLoginGuard]
      },
      {
        path: 'credit_cards',
        component: CreditCardListComponent,
        canActivate: [CreditCardGuard]
      },
      {
        path: 'split/:docEntry',
        component: SplitOrderComponent,
        resolve: {
          order: OrdersResolve,
          ordersList: OrdersListResolve
        },
        canActivate: [AuthGuardBuyerService]
      },
      {
        path: 'offers/:id',
        component: OffersComponent,
        canActivate: [RedirectAfterBuyerLoginGuard],
        resolve: {
          id: OffersResolver 
        }
      },
      {
        path: 'offers',
        component: OffersComponent,
        canActivate: [RedirectAfterBuyerLoginGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
