<div class="processing-modal-wrap">
  <div class="processing-modal animated" [class.zoomIn]="true" [class.mobile]="topService.isMobile">
    <div class="processing-modal__icon">
      <img src="../../../assets/images/icons/cart.svg" width="40" height="40" alt="Price List"/>
    </div>
    <ng-container *ngIf="!isAddToExisting">
          <div class="processing-modal__title">
              Order Processing
            </div>
            <span class="processing-modal__txt">
              We are processing your order, please do not close browser.
          </span>
    </ng-container>
    <ng-container *ngIf="isAddToExisting">
          <div class="processing-modal__title">
              Adding <br>to Existing Order..
            </div>
            <span class="processing-modal__txt">
              We're adding items to your existing order, please do not close browser.
          </span>
    </ng-container>
    <div class="processing-modal__img">
      <img src="../../../assets/images/spinner-blue.gif" width="64" height="64" alt="loading"/>
    </div>
    <div class="processing-modal__bottom">
    	<span>
    		This process can take up to a minute, thank you for your patience.
    	</span>
   	</div>
  </div>
</div>