import { Injectable } from '@angular/core';
import { TopService } from '../../shared/services/top.service';
import { ItemsService } from '../../shared/services/items.service';
import { Router } from '@angular/router';
import { ItemFilterService } from '../../shared/item-filter/item-filter.service';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../../shared/shared.service';
import { SessionService } from '../../services/session.service';
import { UserService } from '../../user/user.service';
import { environment } from '../../../environments/environment';
import { IDropdown } from '../../vendor/interface/IDropdown';
import { sortTypesEnum } from '../../shared/enums/sort-types';
import * as models from '../../buyer/interfaces/model';

@Injectable({
  providedIn: 'root'
})
export class OutletsService {
  private _selectedOutlet;
  isCalledFromMenu = false;
  justSoldCount;
  currentManufacturers = [];

  // url subpath on outlet values --> 'todays_deal' | 'price_drop' | 'just_launched'
  topcatNavName: string;
  outletPath = '/outlets/';

  constructor(public topService: TopService,
    public itemFilterService: ItemFilterService,
    public itemsService: ItemsService,
    public router: Router,
    private http: HttpClient,
    public sharedService: SharedService,
    private sessionService: SessionService,
    private userService: UserService) { }

  public selectedReactiveOutlet: any;

  set selectedOutlet(val) {
    this._selectedOutlet = val;
  }

  get selectedOutlet() {
    return this._selectedOutlet;
  }

  manufacturersData(data, secondSubCat?: string) {
    this.selectedOutlet = data;
    // changing outlet dont show previus items
    this.itemsService.itemsReady = false;

    // redirect to outlet page
    this.itemsService.selectedUrlFilterId = null;
    this.itemsService.selectedTopAttribute = '';
    this.itemFilterService.isSecondLevelExpanded = true;
    this.itemFilterService.clearAll(undefined, true);
    this.itemFilterService.selectedManufacturer = data;

    // for outlets we need to look in the complete list of manufacturers
    if (this.itemFilterService.allManufacturers) {
      this.itemsService.initializeManufacturerData([...this.itemFilterService.allManufacturers]);
    }
    this.itemsService.getManufacturersBreakDownById(data.id, secondSubCat);

    // if is on the price_drop, todays_deal, o just_launched of an outlet do nothing
    if (!(this.topcatNavName && this.isManufacturerOutlet())) {
      this.itemsService.setSelectedManufacturer(data.id, true, true, !!secondSubCat);
      const transformedName = String(data.name.replace(/\s/g, '_')).toLowerCase();

      // dont navigate to the outlet as long as the current request matches the current manufacturer
      // this will prevent navigate to cancelled requests
      if (!this.itemsService.itemsSubscription || (this.itemsService.itemsSubscription &&
        this.itemsService.itemsRequest.attributes.find(a => a.attrType === 0 && a.values.includes(data.id)))) {

        this.router.navigate([`${this.userService.getUrlByRole(true)}/outlets/`, transformedName]);
      }

    }
  }

  isManufacturerOutlet() {
    return !!this.router.url.match(/outlets\/[a-zA-Z]+/);
  }

  clearTopNavInOutletDetail() {
    this.itemFilterService.selectedZeroLevel = undefined;
    this.itemsService.deleteTagAttributes();
    const transformedName = String(this.selectedOutlet.name.replace(/\s/g, '_')).toLowerCase();
    this.router.navigate([`${this.userService.getUrlByRole(true)}/outlets/` + transformedName]);
  }

  resetEverythingButManufacturers(exceptZeroLevel?: boolean) {
    // reset everything in the breadcrumbs
    this.itemFilterService.clearAll(exceptZeroLevel);
    this.itemsService.resetSelectedConditions();
    this.itemsService.itemsRequest.price = null;

    if (!exceptZeroLevel) {
      // do not remove tags if you are in a subpath outlet e.g 'outlets/hp/price_drop
      this.itemsService.deleteTagAttributes();
      this.itemsService.itemsRequest.inStock = false;
    }

    this.itemsService.itemsRequest.categoryId = null;
    this.itemsService.itemsRequest.exwPoint = null;
    this.itemsService.exwPoints = undefined;
    // Reseting sort to default
    this.itemsService.currentSort = sortTypesEnum.none;
    const dropdownData: IDropdown = { dropdownId: 'dd1', action: 'resetToDefaultTitle' };
    this.sharedService.setDropdown(dropdownData);
    this.itemFilterService.selectedManufacturer = this.selectedOutlet;
  }

  navigateToCategory(topFilter) {
    if (!this.selectedOutlet) { return; }

    const outletName = this.selectedOutlet.name.toLowerCase();

    this.userService.isGuest ? this.outletPath = '/guest/outlets/' : this.outletPath = '/outlets/';

    switch (topFilter.navName) {
      case 'Todays Deals':
      case environment.currentDeal.displayLabel:
        return this.outletPath + outletName + '/' + environment.currentDeal.url;
      case 'Price Drop': return this.outletPath + outletName + '/price_drop';
      case 'Just Launched': return this.outletPath + outletName + '/just_launched';
    }
  }

  getTopNavOutletItems(topNav) {
    this.itemsService.showFilterComponent = false;
    let topNavOutlet;
    switch (topNav.navName) {
      case 'Todays Deals':
      case environment.currentDeal.displayLabel:
        topNavOutlet = environment.currentDeal.url; break;
      case 'Price Drop': topNavOutlet = 'price_drop'; break;
      case 'Just Launched': topNavOutlet = 'just_launched'; break;
    }

    this.topcatNavName = topNavOutlet;
    this.resetEverythingButManufacturers();
    this.itemsService.paginationInfo = null;

    this.itemFilterService.selectedZeroLevel = topNav;
    this.itemsService.selectAttrAndGetItems(topNav);
  }

  getReactiveOutlet(selectedOutletName) {
    this.selectedOutlet = this.itemFilterService.allReactiveManufacturers.find(m => m.name.toLowerCase() === selectedOutletName.toLowerCase());
  }

  // justSoldOutlets(manufacturer) {
  //   let baseUrl = environment.buyerPortalBaseUrl;
  //   let url = `${baseUrl}/sales/lineitems/outlettotal/${manufacturer}`;
  //   this.http.get(url).subscribe(justSold => {
  //     this.sharedService.justSoldValues.totalLineItems = <number>justSold;
  //   });
  // }
}
