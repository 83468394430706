import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';
import { LoaderService } from '../../services/loader.service';
import { ILoaderLevel } from '../../interfaces/ILoaderLevel';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  loaderData: ILoaderLevel;
  subscription: Subscription;
  isVisible = false;

  constructor(
    private pubSubService: PubSubService,
    public loaderService: LoaderService,
    private router: Router) { }

  ngOnInit() {
    this.subscription = this.pubSubService.sharedSubject.subscribe(myEvent => {
      if (myEvent.name === SharedSource.loaderStatus) {
        this.loaderData = myEvent.data;
      }
      if (myEvent.name === SharedSource.loaderVisibility) {
        this.loaderData.hide = myEvent.data;
      }
      if (myEvent.name === SharedSource.loaderVendorVisibility) {
        this.isVisible = myEvent.data;
      }
    });
  }

  showSpinner(): boolean {
    return this.router.url !== '/vendor/dashboard';
  }

  ngOnDestroy() {

  }

}
