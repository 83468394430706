import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { IAuctionItem } from '../../buyer/interfaces/model';

@Component({
  selector: 'app-auction-confirm',
  templateUrl: './auction-confirm.component.html',
  styleUrls: ['./auction-confirm.component.scss']
})
export class AuctionConfirmComponent implements OnInit {
  @Output() confirmation: EventEmitter<any> = new EventEmitter<any>();
  @Input() auctionItem: IAuctionItem;
  @Input() bidAmount: number;
  @Input() isMobile: boolean;

  constructor() { }

  ngOnInit() { }

  confirm(data: boolean): void {
    this.confirmation.emit(data);
  }

}
