<div class="pre-cart col-md-12 animated fadeIn">
  <div class="col-md-12 pc-top">
    <div class="row">
      <div [class.faded]="cartService.cartList.length === 0" class="col-md-9 pc-btn">
        <a (click)="!topService.loading?gotoCheckout():''">
          <img src="assets/images/add-to-cart-pc.svg" width="27px" height="23px" />
          <span>Proceed to Checkout</span>
        </a>
      </div>
      <div *ngIf="cartService.cartList.length > 0" class="col-md-3 pc-price">{{getTotal() | CustomCurrency}}</div>
    </div>
  </div>
  <div *ngIf="cartService.cartList">
    <div *ngIf="cartService.cartList.length === 0" class="pre-cart-empty">
      <img src="assets/images/pre-cart-empty.fw.png" width="128px" height="100px" />
      <br/>
      <span class="title">Cart is Empty!</span>
      <div class="message">
        Did you know your Cart follows you on any device? Add items here, see them wherever you sign in.
      </div>
    </div>
  </div>
  <div class="oddEven" *ngIf="cartService.cartList">
    <app-pre-cart-child *ngFor="let cartItem of cartService.cartList; let i = index" (deleteItemEmitter)="deletePreCartItem($event)" [preCartItem]="cartItem" [deleteSpinner]="deleteSpinner" [deleteSpinnerItem]="deleteSpinnerItem" (updateItemQty)="updateQty($event.cartItem, $event.newQty)" [showDetailsActive]="showDetailsActive" [index]="i" (showError)="showError($event)"></app-pre-cart-child>
  </div>
</div>

<app-error-modal [bodyText]="errorModal.bodyText" [title]="errorModal.title"  [isError]="errorModal.isShown" (onConfirmError)="hideError()"></app-error-modal>  
