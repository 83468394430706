import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-credit-card-notification',
  templateUrl: './credit-card-notification.component.html',
  styleUrls: ['./credit-card-notification.component.scss']
})
export class CreditCardNotificationComponent implements OnInit {
  @Output() onClose = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  dismiss() {
    this.onClose.emit(true);
  }

}
