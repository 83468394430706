import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemsService } from '../../shared/services/items.service';
import { ItemFilterService } from '../../shared/item-filter/item-filter.service';
import { UserFiltersService } from './user-filters.service';
import { UserFilter } from '../interfaces/UserFilter';
import { SharedService } from '../../shared/shared.service';
import { Subscription } from 'rxjs';
import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';
import { UserService } from '../../user/user.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-user-filters',
  templateUrl: './user-filters.component.html',
  styleUrls: ['./user-filters.component.scss']
})
export class UserFiltersComponent implements OnInit, OnDestroy {
  savedSearchId: string;
  rawFilter: any;
  subscription = new Subscription();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private itemsService: ItemsService,
    private itemFilterService: ItemFilterService,
    private userFiltersService: UserFiltersService,
    private sharedService: SharedService,
    private pubSubService: PubSubService,
    private userService: UserService) { }

  ngOnInit() {
    if (/^\/guest/.test(this.router.url) && !this.userService.isGuest) {
      window.history.replaceState( {}, '', this.router.url.replace('guest', 'buyer'));
    }
    this.route.params
    .subscribe( params => {
      this.itemsService.currentNavigation = undefined;
      this.savedSearchId = params.id;

      // dont make request if we have the filter already
      if (this.userFiltersService.selectedUserFilter &&
        this.userFiltersService.selectedUserFilter.id === this.savedSearchId ) {
          this.itemFilterService.userFilter = this.userFiltersService.selectedUserFilter.filter;
          this.handleUserFilterChange(this.userFiltersService.selectedUserFilter);
          return;
        }
      this.getUserFilter(this.savedSearchId);
    });

  }


  getUserFilter(id: string) {
    this.userFiltersService.getUserFilterById(id)
      .subscribe(
        (userFilter) => {
          this.itemFilterService.userFilter = JSON.parse(userFilter.filter);
          this.handleUserFilterChange(userFilter);
          this.rawFilter = userFilter;
          if (this.sharedService.navigationReady) {
            this.userFiltersService.transformFilters([userFilter]);
            this.userFiltersService.selectedUserFilter = userFilter;
          }
        },
        (error: HttpErrorResponse) => {
          if (error.status === 404) {
            console.log('filter not found');
            this.router.navigate(['home']);
          }
        });
  }

  handleUserFilterChange(userFilter: UserFilter) {
    this.itemFilterService.isOutletUserFilter = userFilter.source === 1;
    const filterUrl = `user_filters/${userFilter.id}`;
    this.itemFilterService.selectedZeroLevel = {
      navName: userFilter.name,
      url: filterUrl
    };
    this.getItemsFromFilter();
  }

  getItemsFromFilter() {
    this.itemsService.itemsRequest = this.itemsService.getEmptyRequestPayload();
    const filterCopy = JSON.parse(JSON.stringify(this.itemFilterService.userFilter));

    delete filterCopy.savedFilter;
    Object.assign(this.itemsService.itemsRequest, filterCopy);
    this.itemsService.getItems();
  }

  ngOnDestroy() {
    this.itemFilterService.userFilter = undefined;
    this.itemFilterService.isOutletUserFilter = false;
    this.userFiltersService.selectedUserFilter = undefined;
    // this.itemsService.initializeManufacturerData([]);
    this.itemsService.itemsRequest = this.itemsService.getEmptyRequestPayload();
    this.itemFilterService.clearAll(undefined, true);
    this.subscription.unsubscribe();
  }

}
