import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'blackCategoriesImages'
})
export class BlackCategoriesImagesPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.replace(new RegExp('\\bcategories\\b','g'), 'categories/black');
  }

}
