<div class="success-wrapper">
  <div class="success animated zoomIn" (clickOutside)="close.emit()" [excludeBeforeClick]="true">
    <div class="success-header">
      <h3>Successful Update</h3>
      <div (click)="close.emit()" class="close"></div>
    </div>
    <div class="success-body">
      <div class="success-info">
        <p>
          {{sucessMsg}}
        </p>
      </div>
      <div class="success-ctrl">
        <a role="button" class="btn" (click)="close.emit()">
          Continue
        </a>
      </div>

    </div>
  </div>
</div>
