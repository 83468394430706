import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaticService {
  restapiverb = 'guests';
  restAPI = '';
  isSubmittedForm = false;

  constructor(private http: HttpClient) { }

  registerUser(userData: any): Observable<{}> {
    this.isSubmittedForm = true;
    this.restAPI = 'guests/contacts';
    const url = environment.adminUrl + this.restAPI;
    return this.http.post<any>(url, userData);
  }


}
