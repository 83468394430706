import { EventEmitter } from "@angular/core";

export class SafeEventEmitter<T> extends EventEmitter<T>{
    constructor(){
        super();
    }

    tryEmit(value: T): boolean {
        if(!this.closed){
            this.emit(value);
            return true;
        }
        return false;
    }
}