import { Component, OnInit, ViewChild, AfterViewInit, Input, SimpleChanges } from '@angular/core';
import { CustomCurrencyPipe } from '../../../shared/pipes/currency.pipe';
import { IChartSerie } from '../../interface/IPerformanceChart';
import { EPerformanceChartSerieName } from '../../enums/performance-chart-serie';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})

export class ChartComponent implements OnInit, AfterViewInit {
  @ViewChild('chartComp', { static: true }) chartComp: ChartComponent;
  @Input() series: IChartSerie[];
  @Input() categories: string[];
  @Input() totalUnits: number;
  @Input() totalRevenue: number;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['series'] && this.chart) {
      this.updateChart();
    }
  }

  chart: any;
  options: Object;
  hoveredPoint = {
    units: 0,
    revenue: 0
  };

  constructor(
    public currencyPipe: CustomCurrencyPipe) {
  }

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    const pointEvent = null;
    const componentScope = this;
    this.options = {
      chart: {
        backgroundColor: '#F6F6F6',
        borderColor: 'transparent',
        type: 'spline',
      },
      legend: {
        // https://jsfiddle.net/beatriz0015/L40nxdyq/5/
        // https://api.highcharts.com/highcharts/legend
        useHTML: true,
        symbolWidth: 0,
        backgroundColor: '#F6F6F6',
        floating: false,
        align: 'right',
        verticalAlign: 'bottom',
        x: -17,
        labelFormatter: function () {
          return componentScope.legendTemplate(this, pointEvent);
        }
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: this.categories,
        min: 0,
        max: this.categories.length > 20 ? 19 : this.categories.length - 1,
        scrollbar: {
          enabled: this.categories.length > 20
        },
      },
      yAxis: [{
        title: {
          text: null
        },
        labels: {
          formatter: function () {
            if (this.value >= 1000 && this.value < 1E6) {
              return '$' + (this.value / 1000).toFixed() + 'k';
            }
            if (this.value >= 1E6) {
              return '$' + (this.value / 1000000).toFixed(2) + 'M';
            }
            return '$' + this.value;
          }
        }
      },
      {
        title: {
          text: null
        },
        labels: {
          formatter: function () {
            if (this.value >= 1000 && this.value < 1E6) {
              return (this.value / 1000).toFixed() + 'k';
            }
            if (this.value >= 1E6) {
              return (this.value / 1000000).toFixed(2) + 'M';
            }
            return this.value;
          }
        },
        opposite: true,
        gridLineWidth: 0,
      }
      ],
      tooltip: {
        crosshairs: {
          color: '#e9e9e9'
        },
        shared: true,
        enabled: false
      },
      plotOptions: {
        series: {
          point: {
            events: {
              mouseOver: function () {
                componentScope.setHoverPoint(this);
                // console.log('Mouse Over', this);
              },
              mouseOut: function () {
                // console.log('Mouse out', this);
                componentScope.setHoverPoint(null);
              }
            }
          },
          states: {
            inactive: {
              opacity: 1
            }
          },
          lineWidth: 6,
          marker: {
            enabled: this.categories.length === 1 ? true : false,
            states: {
              hover: {
                enabled: false
              }
            }
          },
        }
      },
      series: [{
        data: componentScope.series.find(s => s.name === EPerformanceChartSerieName.Units)?.data || [],
        color: '#1A1A1A',
        yAxis: 1,
        name: EPerformanceChartSerieName[EPerformanceChartSerieName.Units]
      },
      {
        data: componentScope.series.find(s => s.name === EPerformanceChartSerieName.Revenue)?.data || [],
        color: '#8FD96D',
        name: EPerformanceChartSerieName[EPerformanceChartSerieName.Revenue]
      }
      ]
    };
  }

  ngAfterViewInit() {
    if (this.chartComp) {
      this.chart = this.chartComp.chart;
    }
  }

  getTotal(name, pointEvent) {
    if (name === 'Revenue') {
      return pointEvent ? this.currencyPipe.transform(this.hoveredPoint.revenue) : this.currencyPipe.transform(this.totalRevenue);
    }

    if (name === 'Units') {
      return pointEvent ? this.hoveredPoint.units : this.totalUnits
    }
  }

  setHoverPoint(pointEvent) {
    const unitsSerie = this.chart.series[0];
    const revenueSerie = this.chart.series[1];

    if (!pointEvent) {
      this.hoveredPoint.units = 0;
      this.hoveredPoint.revenue = 0;

      unitsSerie.legendItem.element.innerHTML = this.legendTemplate(unitsSerie, pointEvent);
      revenueSerie.legendItem.element.innerHTML = this.legendTemplate(revenueSerie, pointEvent);
      return;
    }

    this.hoveredPoint.units = unitsSerie.data[pointEvent.index].y;
    this.hoveredPoint.revenue = revenueSerie.data[pointEvent.index].y;

    unitsSerie.legendItem.element.innerHTML = this.legendTemplate(unitsSerie, pointEvent);
    revenueSerie.legendItem.element.innerHTML = this.legendTemplate(revenueSerie, pointEvent);
  }


  legendTemplate(serie, pointEvent): string {
    return `
    <div style="display: flex; font-family: HelveticaNeueCyr-Roman1; font-size: 1rem;">
      <div style="color:${serie.color}; min-width: 150px; font-size: 1.7rem; text-align: right; padding-right: 10px">
        <span>
          ${this.getTotal(serie.name, pointEvent)} 
        </span>
      </div>
      <div>
        <span style="font-weight: normal"> ${serie.name} </span>
        <div style="background-color:${serie.color}; height: 5px; width: 18px; border-radius: 3px; margin-top: 3px"></div>
      </div>
    </div>`;
  }

  updateChart() {
    this.chart.xAxis[0].setCategories(this.categories, true);

    for (let index = 0; index < this.chart.series?.length; index++) {
      this.chart.series[index].remove();
      index--;
    }

    for (let index = 0; index < this.series.length; index++) {
      const currentSerie = this.series[index];
      var serie = {};

      if (currentSerie.name === EPerformanceChartSerieName.Units) {
        serie = {
          data: currentSerie.data,
          color: '#1A1A1A',
          yAxis: 1,
          name: EPerformanceChartSerieName[EPerformanceChartSerieName.Units]
        };
      }

      if (currentSerie.name === EPerformanceChartSerieName.Revenue) {
        serie = {
          data: currentSerie.data,
          color: '#8FD96D',
          name: EPerformanceChartSerieName[EPerformanceChartSerieName.Revenue]
        }
      }

      this.chart.addSeries(serie, false);
    }

    const componentScope = this;
    this.chart.update({
      xAxis: {
        min: 0,
        max: this.categories.length > 20 ? 19 : this.categories.length - 1,
        scrollbar: {
          enabled: this.categories.length > 20
        }
      },
      plotOptions: {
        series: {
          point: {
            events: {
              mouseOver: function () {
                componentScope.setHoverPoint(this);
                // console.log('Mouse Over', this);
              },
              mouseOut: function () {
                // console.log('Mouse out', this);
                componentScope.setHoverPoint(null);
              }
            }
          },
          states: {
            inactive: {
              opacity: 1
            }
          },
          lineWidth: 6,
          marker: {
            enabled: this.categories.length === 1 ? true : false,
            states: {
              hover: {
                enabled: false
              }
            }
          },
        }
      },
      events: {
        redraw: this.hideLegend()
      }

    });
    this.chart.redraw();
  }

  hideLegend() {
    const legends = document.querySelectorAll('.highcharts-legend');
    legends.forEach(legend => {
      legend['style'].display = 'none';
    });
  }
}
