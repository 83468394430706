<div class="loader" *ngIf="loading2"></div>
<div class="add-to-wrap animated" [class.zoomIn]="showModal">
  <div class="title">
    <span *ngIf="!openOrdersDetails">Open Orders</span>
    <div (click)="closeModal()" class="close"></div>
  </div>
  <div class="row margin-0">
    <div class="col-lg-2 left">
      <div class="left" [@openOrdersDetailsTrigger]=" !openOrdersDetails ? 'show' : 'hide' ">
        <div class="open-order-no">
          <ul class="open-orders">
            <li *ngFor="let openOrder of openOrders" (click)="onSelectOrder(openOrder)"
              [class._selected]="selectedOpenOrder.docEntry === openOrder.docEntry">
              <span class="circle">
                <span *ngIf="selectedOpenOrder.docEntry === openOrder.docEntry"></span>
              </span>
              <span>{{openOrder.docNumber}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-lg-10 right">
      <div class="order-stat-wrap">
        <ul *ngIf="selectedOpenOrder.docNumber>0" class="order-stat">
          <li>
            <span class="order-items">Order Id</span>
            <br /> {{selectedOpenOrder.docNumber }}</li>
          <li>
            <span class="order-items">Order Date</span>
            <br />{{selectedOpenOrder.orderDate | date:'shortDate'}}</li>
          <li>
            <span class="order-items">Order Amount</span>
            <br />{{selectedOpenOrder.orderTotal | CustomCurrency}}</li>
          <div class="clear"></div>
        </ul>
        <div class="clear"></div>
      </div>

      <div *ngIf="selectedOpenOrder.docNumber>0" class="order-details-add">
        <table>
          <thead>
            <tr>
              <th>Part</th>
              <th>Price</th>
              <th>QTY</th>
              <th>Item Total</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of selectedOpenOrder.orderDetails">
              <td>{{ item.itemCode }}</td>
              <td>{{((item.overwrittenPrice)?item.overwrittenPrice:item.unitPrice) | CustomCurrency}}</td>
              <td>{{item.quantity}}</td>
              <td>{{((item.overwrittenPrice)?item.overwrittenPrice:item.unitPrice) * item.quantity |CustomCurrency}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>


      <div *ngIf="selectedOpenOrder.docNumber > 0" class="controls">
        <a (click)="closeModal()" class="cancel-btn btn" [class.disabled]="loading">Cancel</a>
        <a (click)="onPlaceExisting(selectedOpenOrder.docEntry)" [class.disabled]="loading" class="add-order-btn btn">
          <span>Add to Order #{{selectedOpenOrder.docNumber}}</span>
        </a>
      </div>
    </div>

  </div>
</div>
<div class="clear"></div>