import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { VendorService } from '../../vendor.service';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { INotificationVendor } from '../../interface/INotificationVendor';
import { SessionService } from '../../../services/session.service';
import { NotificationTypesEnum, NotificationFiltersEnum } from '../../../shared/interfaces/INotificationDetail';
import { LoaderService } from '../../../shared/services/loader.service';

export enum TabsEnum {
  allNotif,
  dropPrice,
  waitList,
  moreInventory,
  archive
}

@Injectable()
export class NotificationCenterVendorService {
  manufacturersData: any[] = [];
  private tabSubject: BehaviorSubject<number>;
  private _partnerId;
  tabSortDirection: string;

  constructor(
    private _vendorSvc: VendorService,
    private _sessionSvc: SessionService,
    private loaderService: LoaderService,
    private _http: HttpClient
    ) {
    this.tabSubject = new BehaviorSubject<number>(TabsEnum.allNotif);
  }

  set vendorDropdown(id) {
    this._partnerId = id;
  }

  get vendorDropdown() {
    return this._partnerId;
  }

  public announceTabSelectionChanged(value) {
    this.tabSubject.next(value);
  }

  public tabSubject$() {
    return this.tabSubject.asObservable();
  }

  fromTabToNotificationFilter(tab: TabsEnum) {
    let notifFilter;
    // we need this because the tabs enum and notification type enum
    // are not equal and dont have the same order
    switch (tab) {
      case TabsEnum.allNotif : notifFilter = NotificationFiltersEnum.all; break;
      case TabsEnum.dropPrice : notifFilter = NotificationFiltersEnum.priceDrop; break;
      case TabsEnum.waitList : notifFilter = NotificationFiltersEnum.waitlist; break;
      case TabsEnum.moreInventory : notifFilter = NotificationFiltersEnum.moreInventory; break;
      case TabsEnum.archive : notifFilter = NotificationFiltersEnum.archive; break;
    }

    return notifFilter;
  }

  fromTabToNotificationType(tab: TabsEnum) {
    let notifType;
    // we need this because the tabs enum and notification type enum
    // are not equal and dont have the same order
    switch (tab) {
      case TabsEnum.dropPrice : notifType = NotificationTypesEnum.priceDrop; break;
      case TabsEnum.waitList : notifType = NotificationTypesEnum.waitlist; break;
      case TabsEnum.moreInventory : notifType = NotificationTypesEnum.moreInventory; break;
    }

    return notifType;
  }

  fromNotificationTypeToTab(notificationType: NotificationTypesEnum) {
    let tab;
    // we need this because the tabs enum and notification type enum
    // are not equal and dont have the same order
    switch (notificationType) {
      case NotificationTypesEnum.priceDrop : tab = TabsEnum.dropPrice; break;
      case NotificationTypesEnum.waitlist : tab =  TabsEnum.waitList; break;
      case NotificationTypesEnum.moreInventory : tab = TabsEnum.moreInventory; break;
    }

    return tab;
  }

  getNotificationItems(payload) {
    const restAPI = 'api/notifications';
    const url = environment.vendorPortalBaseUrl + restAPI;
    return this._http.post<INotificationVendor[]>(url, payload);
  }

  getTotalCounts() {
    // const vendorID = this._vendorSvc.vendorId;
    const restAPI = `api/notifications/totals`;
    const url = environment.vendorPortalBaseUrl + restAPI;
    return this._http.get<any>(url);
  }

  updateInventoryRequest(payload) {
    const restAPI = 'VendorItem/moreinventory';
    const url = environment.vendorPortalBaseUrl + restAPI;
    return this._http.post(url, payload);
  }

  dropPriceRequest(payload) {
    const restAPI = 'VendorItem/price';
    const url = environment.vendorPortalBaseUrl + restAPI;
    return this._http.post(url, payload);
  }

  rejectNotificationRequest(payload) {
    const restAPI = 'api/notifications';
    const url = environment.adminBaseUrl + restAPI;
    return this._http.post(url, payload);
  }

  markAsSeenRequest(payload) {
    const restAPI = 'api/notifications/seen';
    const url = environment.vendorPortalBaseUrl + restAPI;
    return this._http.post(url, payload);
  }

  addConditionToDescription(notif) {
    const atributes = notif.vendorItem.attributes;
    let condition;
    if (atributes) {
      atributes.forEach(a => {

        if (a.label === 'Condition') {
          condition = a.value;
        }
      });
    }
    if (condition) {
      notif.vendorItem.description = notif.vendorItem.description.concat(` - ${condition}`);
    }
  }

  archiveNotification(id: string) {
    const url = environment.vendorPortalBaseUrl + 'api/notifications/archived/' + id;
    return this._http.put(url, null);
  }

  archiveNotifications(ids: string[]) {
    const url = environment.vendorPortalBaseUrl + 'api/notifications/archived';
    return this._http.post(url, ids);
  }

  exportNotifications(value) {
    const url = value.ids ? environment.vendorPortalBaseUrl + 'api/notifications/export/selection'
                          : environment.vendorPortalBaseUrl + 'api/notifications/export';
    this.loaderService.addNonDecrementalUrl(url); 
    return this. _http.post<Blob>(url, value, {observe: 'response', responseType: 'blob' as 'json'});
  }

}
