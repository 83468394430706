import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TopService } from '../../services/top.service';

@Component({
  selector: 'app-restriction-notice',
  templateUrl: './restriction-notice.component.html',
  styleUrls: ['./restriction-notice.component.scss']
})
export class RestrictionNoticeComponent implements OnInit {
  @Output() closeModalEmitter = new EventEmitter();
  animateOut = false;

  constructor(
    public topService: TopService
    ) { }

  ngOnInit(): void {
  }
  closeModal() {
    this.animateOut = true;
    setTimeout(() => {
      this.closeModalEmitter.emit();
    }, 300);
  }

}
