import { ChangeDetectorRef, ComponentRef, ViewContainerRef } from "@angular/core";


export class DynamicComponent {
    id: string; // lets use the offer event id
    container: ViewContainerRef;
    componentType: any;
    attributes: any;
    cdr: ChangeDetectorRef;
    componentRef: ComponentRef<any>;

    constructor(container: ViewContainerRef, componentType: any, attributes: any = null, id: string, cdr: ChangeDetectorRef) {
        this.container = container;
        this.componentType = componentType;
        this.attributes = attributes;
        this.id = id;
        this.cdr = cdr;
    }

    addComponentRef(componentRef: ComponentRef<any>) {
        this.componentRef = componentRef;
    }

}
