<div class="faq-modal" [class.mobile]="topService.isMobile" (click)="clickOnWrapper($event)">
  <div class="faq-modal-dialog">
    <div class="faq-modal-content">
      <i class="faq-modal-content__close" (click)="closeModal()"></i>
      <div class="faq-modal-slider">
        <owl-carousel
          [options]="sliderOptions"
          [carouselClasses]="['faq-slider']" #owlSlider>
          <div class="faq-slider-step">
            <div class="faq-slider-step__img _first"></div>
            <span class="faq-slider-step__title">Save your searches</span>
            <p class="faq-slider-step__txt">Use the (+) to save the current search</p>
          </div>
          <div class="faq-slider-step">
            <div class="faq-slider-step__img _second"></div>
            <span class="faq-slider-step__title">Quick access</span>
            <p class="faq-slider-step__txt">Your searches are available <br/>from the search bar</p>
          </div>
          <div class="faq-slider-step">
            <div class="faq-slider-step__img _third"></div>
            <span class="faq-slider-step__title">One email</span>
            <p class="faq-slider-step__txt">We send you all new added items based <br/>on your saved searches in one email</p>
          </div>
        </owl-carousel>
        <button type="button" (click)="goToNextSlide()" class="faq-slider-btn">
          {{currentSliderIndex === 2 ? 'Got It': 'Next'}}
        </button>
      </div>
    </div>
  </div>
</div>
