<div class="filter-animation" [class.__show]="auctionsService.showMobileHeader" (swipedown)="hideFilter()">
  <div class="filter-wrap">
    <div class="filter-wrap-header">
      <div class="filter-top">
        <div class="filter">
          <div class="filter-row">
            <div class="filter-current">
              <span class="filter-current__value">{{ auctionsService.titleText }}</span>
              <span class="filter-count" *ngIf="auctionsService.totalsReady"
              [class.zeroCount]="auctionsService.totalToDisplay === 0">{{ auctionsService.totalToDisplay }}</span>
            </div>
          </div>
        </div>
      </div>
      <i class="filter-wrap-header__close" (click)="hideFilter()">
        <img src="../../../../../assets/images/icons/mobile/menu/m-close-white.svg" width="24" height="24" alt="close">
      </i>
    </div>
    <div class="filter-wrap-content">
      <div class="filter-items-row">
        <div class="filter-items-row__title">Auctions</div>
        <div class="filter-items-row__content">
          <div class="filter-item-wrap">
            <div class="filter-item"
            [class._active]="auctionsService.isAuctionsPage && auctionsService.searchPayload.statusFilter === statusAuctionsEnum.active"
            (click)="selectQuickFilter(statusAuctionsEnum.active)">
              <span class="filter-item__txt">Live</span>
              <span class="filter-item__count">{{auctionsService.headerTotals?.LiveTotal}}</span>
            </div>
          </div>
          <div class="filter-item-wrap">
            <div class="filter-item"
            [class._active]="auctionsService.isAuctionsPage && auctionsService.searchPayload.statusFilter === statusAuctionsEnum.comingSoon"
            (click)="selectQuickFilter(statusAuctionsEnum.comingSoon)">
              <span class="filter-item__txt">Coming Soon</span>
              <span class="filter-item__count">{{auctionsService.headerTotals?.CommingSoonTotal}}</span>
            </div>
          </div>
          <div class="filter-item-wrap">
            <div class="filter-item"
            [class._active]="auctionsService.isAuctionsPage && auctionsService.searchPayload.statusFilter === statusAuctionsEnum.completed" 
            (click)="selectQuickFilter(statusAuctionsEnum.completed)">
              <span class="filter-item__txt">Completed</span>
              <span class="filter-item__count">{{auctionsService.headerTotals?.CompletedTotal}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="filter-items-row">
        <div class="filter-items-row__title">My Bids</div>
        <div class="filter-items-row__content">
          <div class="filter-item-wrap">
            <div class="filter-item"
              [routerLink]="guestUrl + '/auctions/my_bids'"
              routerLinkActive="_active"
              [routerLinkActiveOptions]="{exact: true}">
              <span class="filter-item__txt">All</span>
              <span class="filter-item__count">{{ auctionsService.myBidsTotals?.AllTotal }}</span>
            </div>
          </div>
          <div class="filter-item-wrap">
            <div class="filter-item"
            [routerLink]="guestUrl + '/auctions/my_bids/winning'" 
            routerLinkActive="_active">
              <span class="filter-item__txt">Winning</span>
              <span class="filter-item__count">{{ auctionsService.myBidsTotals?.WinningTotal }}</span>
            </div>
          </div>
          <div class="filter-item-wrap">
            <div class="filter-item"
            [routerLink]="guestUrl + '/auctions/my_bids/not_winning'" 
            routerLinkActive="_active">
              <span class="filter-item__txt">Not Winning</span>
              <span class="filter-item__count">{{ auctionsService.myBidsTotals?.NotWinningTotal  }}</span>
            </div>
          </div>
          <div class="filter-item-wrap">
            <div class="filter-item"
            [routerLink]="guestUrl + '/auctions/my_bids/won'"
            routerLinkActive="_active">
              <span class="filter-item__txt">Won</span>
              <span class="filter-item__count">{{ auctionsService.myBidsTotals?.WonTotal }}</span>
            </div>
          </div>
          <div class="filter-item-wrap">
            <div class="filter-item"
            [routerLink]="guestUrl + '/auctions/my_bids/watching'"
            routerLinkActive="_active">
              <span class="filter-item__txt">Watching</span>
              <span class="filter-item__count">{{ auctionsService.myBidsTotals?.WatchingTotal }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-wrap-footer">
      <select class="filter-select" [(ngModel)]="selectedSort" (change)="changeSortByValue(selectedSort)">
        <option value="" selected> Select Sorting </option>
        <option *ngFor="let sort of auctionsService.sortOptions" [ngValue]="sort.Value">{{ sort.Text }}</option>
      </select>
      <button type="button" class="filter-btn-apply">
        <img src="../../../../assets/images/icons/mobile/24-check-white.svg" alt="check" (click)="hideFilter()">
      </button>
    </div>
  </div>
</div>