<div class="auction-items-loader" *ngIf="topService.loading || !sharedService.navigationReady">
	<div class="loader"></div>
  </div>
<div class="user-filters" [class.mobile]="topService.isMobile">
	<div class="filters-header">
		<div class="filters-header-left">
			<h2>Saved Searches</h2>
			<a role="button" (click)="openFaq()">How it works?</a>
		</div>
		<div class="filters-header-right">
			<a role="button" (click)="openForm($event)" [class.disabled]="topService.loading || userFiltersService.loading">
				<i>
					<img alt="Search" src="assets/images/icons/plus-search.svg" width="24" height="24">
				</i>
				<span>Add New Search</span>
			</a>
		</div>
  </div>
	<div class="filters-list">
    <div class="loader" *ngIf="userFiltersService.loading"></div>
    <div class="filter" *ngFor="let filter of userFiltersService.userFilterList; let i = index"
      (click)="userFiltersService.navigateToItems(filter)">
			<div class="filter-info">

				<div class="filter-search">

					<a role="button">
						<i>
							<img alt="Search" src="assets/images/icons/32-search.svg" width="24" height="24">
						</i>
					</a>

				</div>
				<div class="filter-text">
					<span class="filter-text-name"> {{filter.name}}</span>
					<span class="filter-text-description"> {{ filter.redableText }}</span>

				</div>
				<div class="filter-ctrls">

					<!-- <a role="button" (click)="openForm($event, filter)">
						<i>
              <img alt="Edit" src="assets/images/icons/rebranding/edit.svg" width="24" height="24"/>
						</i>
					</a> -->

					<a role="button" (click)="showDelete(i)" class="_delete">
						<i>
              <img alt="Delete" src="assets/images/icons/rebranding/delete-red.svg" width="24" height="24">
						</i>
					</a>

					<div class="delete-search-tooltip" [class._confirm-visible]="showDelSavedSearch === i" (click)="onTooltipClick($event)">
						<div class="delete-search-tooltip-confirm">
							<div class="delete-search-tooltip-confirm__text">Are you sure you<br/>want to delete?</div>
							<div class="delete-search-tooltip-confirm__buttons">
								<button (click)="showDelete(null)" type="button" class="_cancel">Cancel</button>
								<button (click)="removeFilter(filter.id, $event)" type="button" class="_yes">Yes</button>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>

<app-user-filters-modal *ngIf="isFormIsOpen" (closeFilterModal)="closeForm()"
[userFilterId]="userFilterId" >
</app-user-filters-modal>
<app-user-filters-faq *ngIf="isFaqIsOpen" (onCloseModal)="closeFaq()"></app-user-filters-faq>
