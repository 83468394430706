import { OfferService } from './../../../user/offers/offer.service';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { CartService } from '../../cart/cart.service';
import { PageErrorService } from '../../../page-error/page-error.service';
import { UtilityService } from '../../../core/utility.service';
import { TopService } from '../../../shared/services/top.service';
import { environment } from '../../../../environments/environment';
import { InventoryLimitPipe } from '../../../shared/pipes/inventory-limit.pipe';
import { IItemAttribute } from '../../interfaces/IItemAttribute';
import { IBuyerErrorModal } from '../../interfaces/IBuyerErrorModal';
import { SessionService } from '../../../services/session.service';

import * as models from '../../interfaces/model';
import { SharedService } from '../../../shared/shared.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';
import { ItemsService } from '../../../shared/services/items.service';

import { NotificationTypesEnum, eNotificationAction } from '../../../shared/interfaces/INotificationDetail';
import { NotificationCenterBuyerService } from '../../notification-center/notification-center.service';
import { AuthService } from '../../../auth/auth.service';
import { StaticService } from '../../../static/static.service';
import { UserService } from '../../../user/user.service';
import { GuestService } from '../../../shared/services/guest.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-grid-view-item',
  templateUrl: './grid-view-item.component.html',
  styleUrls: ['./grid-view-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridViewItemComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  cartIsDone$: Subject<boolean> = new Subject<boolean>();

  @Input() item: models.IItem;
  @Input() selectedItem: models.IItem = {};
  @Input() showRestrictedCountryPopup: boolean;
  @Output() itemDetailedMode = new EventEmitter<models.IItem>();
  @Output() onError = new EventEmitter<IBuyerErrorModal>();
  @Output() notificationTooltipEmitter = new EventEmitter<{item: models.IItem, event: Event}>();
  @Output() waitlistTooltipEmitter = new EventEmitter<{item: models.IItem, event: Event, isShowWaitlist: boolean}>();
  itemsAvailable: number;
  todaysDeal = 'Today\'s Deals';
  priceDrop = 'Price Drop';
  justLaunched = 'Just Launched';
  visualCondition = 'Visual Condition';
  copied = 'Copy';
  zeroQtyAlert = false;
  notEnoughQty = false;
  notEnoughQtyMessage = '';
  notEnoughQtyTitle = '';
  notEnoughMOQ = false;
  animateOut = false;
  errorAnimateOut = false;
  openItemDetails = false;
  minimizeDetails = false;
  availableToSellItem: number;
  showPriceDropT = false;
  showTodaysDealtT = false;
  showjustLaunchedT = false;
  showRefurbishedT = false;
  showAvailableT = false;
  showItemMOQ = false;
  showUnitPrice = false;
  showExw = false;
  showItemMXQ = false;
  showInputMXQ = false;
  conditionIcon = false; //N = true, R = false
  isError = false;
  itemListErrorModal: IBuyerErrorModal = {
    bodyText: '',
    title: '',
    isShown: false
  };
  pubServiceSubscription: Subscription;
  NotificationTypesEnum = NotificationTypesEnum;
  environment = environment;
  attributes: IItemAttribute[];
  deleteSpinner = false;

  // Dont remove any parameter from here they
  // are being used in the extended components
  constructor(
    public cartService: CartService,
    public pageErrorService: PageErrorService,
    public itemsService: ItemsService,
    public topService: TopService,
    public utilService: UtilityService,
    protected router: Router,
    public sessionService: SessionService,
    public sharedService: SharedService,
    public pubSubService: PubSubService,
    private notifCenterBuyer: NotificationCenterBuyerService,
    public guestService: GuestService,
    public authService: AuthService,
    public staticService: StaticService,
    private cdr: ChangeDetectorRef,
    public userService: UserService,
    public offerService: OfferService
  ) { }

  ngOnInit() {
    if (!this.item.prevPrice) {
      this.item.prevPrice = this.item.baseUnitPrice;
    }
    this.onHandInTransit();
    this.prepareVisibleIcons();
    this.checkItemInCart();
    this.getVisibleAttributes();
    this.cartService.cartChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.checkItemInCart();
    });

    this.mapNotificationInfo();

    this.pubServiceSubscription = this.pubSubService.sharedSubject.pipe(takeUntil(this.destroy$)).subscribe(myEvent => {
      if (myEvent.name === SharedSource.offerSummary) {
        this.detectChangesInTemplate();
      }
      if (myEvent.name === SharedSource.selectedItemChanged) {
        this.openItemDetails = false;
      }
      if (myEvent.name === SharedSource.refreshNotificationItem) {
        if (myEvent.data === this.item.id) {
          this.mapNotificationInfo();
          this.cdr.markForCheck();
        }
      }
      if (myEvent.name === SharedSource.newPriceUpdated) {
        if (myEvent.data.id === this.item.id) {
          this.item.qty = myEvent.data.qty;
          this.itemsService.updateItemPrice(this.item);
          this.cdr.markForCheck();
        }
    }
    });
  }

  onBlur(event: any): void {
    if (!this.item.qty) {
      this.item.qty = this.item.previousQuantityAdded ? this.item.previousQuantityAdded : 1;
    }
  }

  /**
   * @param  {} attributeLabel
   * @returns string
   */
  getAttributeUri(attributeLabel: string): string {
    if (attributeLabel === 'Condition') {
      return 'assets/images/new-item-details/Condition.svg';
    } else if (attributeLabel === 'Warranty') {
      return 'assets/images/new-item-details/Warranty.svg';
    } else if (attributeLabel === 'Packaging') {
      return 'assets/images/new-item-details/Packaging.svg';
    } else if (attributeLabel === 'Restrictions') {
      return 'assets/images/new-item-details/restrictions.svg';
    } else if (attributeLabel === 'Price Drop') {
      return 'assets/images/new-item-details/price-drop-item-green.svg';
    } else if (attributeLabel === 'Just Launched') {
      return 'assets/images/new-item-details/rocket-item-red.svg';
    } else if (attributeLabel === 'Today\'s Deals') {
      return 'assets/images/icons/item-filter/doorbuster-24-white-opacity.svg';
    } else {
      return 'assets/images/icons/noimage.svg';
    }
  }

  /**
   * @returns void
   */
  prepareVisibleIcons(): void {
    const visConditionAttribute = this.item.attributes.find(a => a.label === this.visualCondition);
    if (visConditionAttribute) {
      visConditionAttribute.hide = true;
    }
    const conditionAttribute = this.item.attributes.find(a => a.label === 'Condition');
    if(this.itemsService.conditionMapping && conditionAttribute){
      const conditionIconChar = this.itemsService.conditionMapping[conditionAttribute.value.toLowerCase()];
      this.conditionIcon = conditionIconChar === 'N';
    }
  }

  /**
   * @returns IItemAttribute
   */
  getVisibleAttributes(): void {
    this.attributes = this.item.attributes.filter(a => !a.hide);
  }

  /**
   * @param  {string} error400
   * @returns void
   */
  prepareErrorMessages(error400: string): void {
    let filter = error400;
    if (error400.startsWith('This item has a Max Unit Quantity')) {
      filter = 'This item has a Max Unit Quantity';
    } else if (error400.startsWith('Quantity must be equal or greater than Minimum Order Quantity of')) {
      filter = 'Quantity must be equal or greater than Minimum Order Quantity of';
    }

    const newError = environment.errorMapping.filter(c => c.server.startsWith(filter));
    if (newError && newError.length > 0) {
      this.itemListErrorModal.bodyText = error400;
      this.itemListErrorModal.title = newError[0].client.title;
      // this.notEnoughQtyMessage = newError[0].client.message;
      // this.notEnoughQtyTitle = newError[0].client.title;
      // this.itemListErrorModal.bodyText = newError[0].client.message;
      // this.itemListErrorModal.title = newError[0].client.title;
      this.itemListErrorModal.isShown = true;
      this.onError.emit(this.itemListErrorModal);
    } else {
      // this.notEnoughQtyTitle = 'Quantity Error';
      // this.notEnoughQtyMessage = error400;
      this.itemListErrorModal.bodyText = error400;
      this.itemListErrorModal.title = 'Important';
      this.itemListErrorModal.isShown = true;
      this.onError.emit(this.itemListErrorModal);
    }

    this.item.qty = this.item.previousQuantityAdded;
    this.notEnoughQty = true;
    this.topService.loading = false;
  }

  /**
   * @returns void
   */
  addToCart(ignoreExw?: boolean): void {
    event.stopPropagation();

    if (this.userService.isGuest) {
      this.userService.guestPop();
      return;
    }

    this.topService.isSearchActive = false;

    if (this.item.loading || !this.sessionService.userCanBuy) {
      return;
    }

    if (this.itemsService.isItemRestrictedForCountry(this.item)) {
      this.sharedService.itemRestrictedForCountry(this.item);
      return;
    }

    if ( !this.item.exwPoint.match(/miami/i) && !ignoreExw ) {
      this.itemsService.showExwPopUp = true;
      this.itemsService.selectedItemExwPopup = this.item;
      return;
    }

    if (!this.item.qty) {
      this.item.qty = this.item.previousQuantityAdded;
      return;
    }

    this.item.loading = true;

    if (this.item.qty.toString() === '0') {
      this.zeroQtyAlert = true;
      this.notEnoughQtyMessage = 'Quantity must be more than 0.';
      this.notEnoughQtyTitle = 'Quantity Error';
      this.itemListErrorModal.bodyText = 'Quantity must be more than 0.';
      this.itemListErrorModal.title = 'Quantity Error';
      this.itemListErrorModal.isShown = true;
      this.onError.emit(this.itemListErrorModal);

      if (this.item.previousQuantityAdded) {
        this.item.qty = this.item.previousQuantityAdded;
        this.item.added = true;
        this.item.beingEdited = false;
      }

      this.item.loading = false;
      return;
    }

    if (this.item.inCart) {
      this.cartService.updateCart(this.findCartItem(), this.item.qty, this.item.unitPrice).pipe(takeUntil(this.cartIsDone$)).subscribe(
        (data) => {
          this.cartService.showAddedModal();
          this.item.added = true;
          this.item.beingEdited = false;
          this.item.previousQuantityAdded = this.item.qty;
          this.item.inCart = true;
          this.cartService.cartList = data;
          this.cartService.updateCartItemData(this.item);
          this.item.reservedQty > 0 ? this.item.added = false : this.item.added = true;
          this.topService.loading = false;
          this.item.loading = false;
          if (this.openItemDetails === true) {
            this.closeModal(false);
          }
          this.cdr.markForCheck();
          if(this.destroy$.closed) {
            this.cartIsDone$.next(true);
            this.cartIsDone$.unsubscribe();
          }

        },
        (err) => {
          if (err.status === 400) {
            this.isError = true;
            this.prepareErrorMessages(err.error);
            this.item.loading = false;
            this.cdr.markForCheck();
            if(this.destroy$.closed) {
              this.cartIsDone$.next(true);
              this.cartIsDone$.unsubscribe();
            }
            return;
          }

          this.topService.loading = false;
          this.sharedService.handleBuyerHttpError(err, this.itemListErrorModal, true);
        }
      );
    } else {

      this.cartService.addToCart(this.item.id, this.item.qty).pipe(takeUntil(this.cartIsDone$)).subscribe(
        (data) => {
          this.cartService.showAddedModal();
          this.item.previousQuantityAdded = this.item.qty;
          this.item.inCart = true;
          this.cartService.cartList = data;
          this.cartService.updateCartItemData(this.item);
          this.item.reservedQty > 0 ? this.item.added = false : this.item.added = true;
          this.topService.loading = false;
          this.item.loading = false;
          if (this.openItemDetails === true) {
            this.closeModal(false);
          }
          this.cdr.markForCheck();
          if(this.destroy$.closed) {
            this.cartIsDone$.next(true);
            this.cartIsDone$.unsubscribe();
          }
        },
        (err) => {
          if (err.status === 400) {
            this.isError = true;
            this.prepareErrorMessages(err.error);
            this.item.loading = false;
            this.cdr.markForCheck();
            if(this.destroy$.closed) {
              this.cartIsDone$.next(true);
              this.cartIsDone$.unsubscribe();
            }
            return;
          }

          this.item.loading = false;
          this.topService.loading = false;
          this.sharedService.handleBuyerHttpError(err, this.itemListErrorModal, true);
        }
      );
    }
  }

  /**
   * @returns number
   */
  findCartItem(): number {
    for (let i = 0; i < this.cartService.cartList.length; i++) {
      if (this.item.id === this.cartService.cartList[i].item.id) {
        return this.cartService.cartList[i].lineSequence;
      }
    }
  }

  /**
   * @param  {models.IItem} item
   * @returns void
   */
  updateItemPrice(item: models.IItem): void {
    if ( !this.sessionService.userCanBuy ) {
      return;
    }

    if (!item.qty || item.qty === 0) {
      item.qty = this.item.previousQuantityAdded;
      return;
    }
    this.itemsService.updateItemPrice(item);
  }


  /**
   * method to make sure items that are currently in cart have the same data
   * @returns void
   */
  checkItemInCart(): void {
    if (this.cartService.cartList) {
      let cartPosition = -1;
      for (let i = 0; i < this.cartService.cartList.length; i++) {
        if (this.cartService.cartList[i].item.id === this.item.id) {
          cartPosition = i;
        }
      }

      if (cartPosition >= 0) {
        this.item.added = true;
        this.item.beingEdited = false;
        this.item.qty = this.cartService.cartList[cartPosition].quantity;
        this.item.previousQuantityAdded = this.item.moq;
        this.item.inCart = true;
        this.item.unitPrice = this.cartService.cartList[cartPosition].unitPrice;
        this.item.reservedQty = this.cartService.cartList[cartPosition].reservedQty;
        this.cdr.markForCheck();
      } else {
        this.item.added = false;
        this.item.inCart = false;
        this.item.beingEdited = false;
        this.item.qty = 1;
        if (this.item.inventory.availableToSell < 1) {
          this.item.qty = 0;
        }
        if (this.item.moq > 1) {
          this.item.qty = this.item.moq;
        }
        this.item.previousQuantityAdded = this.item.moq;
        this.cdr.markForCheck();
      }
    }

  }


  /**
   * @returns void
   */
  editItemQty(): void {
    event.stopPropagation();
    this.topService.isSearchActive = false;
    this.item.loading = false;
    this.item.previousQuantityAdded = this.item.qty;
    this.item.added = false;
    this.item.beingEdited = true;
  }

  /**
   * @param  {any} event
   * @returns void
   */
  selectText(event: any): void {
    this.item.qty = null;
    event.target.select();
  }

  /**
   * @param  {string} val
   * @returns void
   */
  copyText(val: string): void {
    event.stopPropagation();
    this.utilService.copyTextToClipboard(val);
    this.copied = 'Copied!';
  }

  /**
   * @returns void
   */
  copyState(): void {
    this.copied = 'Copy';
  }

  /**
   * @returns void
   */
  alertConfirmBtn(): void {
    event.stopPropagation();
    this.errorAnimateOut = true;

    setTimeout(() => {
      if (this.zeroQtyAlert) {
        this.zeroQtyAlert = false;
      }
      if (this.notEnoughQty) {
        this.notEnoughQty = false;
      }
      if (this.notEnoughMOQ) {
        this.notEnoughMOQ = false;
      }
      this.errorAnimateOut = false;
    }, 400);
  }

  /**
   * @returns boolean
   */
  hideNegative(): boolean {
    return this.sessionService.isBuyer && this.item.inventory.onHand < 1 && this.item.inventory.enRoute < 1;
  }

  /**
   * @returns void
   */
  onHandInTransit(): void {
    this.availableToSellItem = this.item.inventory.onHand + this.item.inventory.enRoute;
  }

  /**
   * @returns void
   */
  openModal(): void {
    this.openItemDetails = true;
    this.itemDetailedMode.emit(this.item);
  }

  /**
   * @param  {boolean} isCancel
   * @returns void
   */
  closeModal(isCancel: boolean): void {
    if (isCancel) {
      this.item.qty = this.item.previousQuantityAdded > 0 ? this.item.previousQuantityAdded : 1;
    }

    this.animateOut = true;
    setTimeout(() => {
      this.openItemDetails = false;
    }, 400);
  }

  minimizeDetailsHover(): void {
    this.minimizeDetails = !this.minimizeDetails;
  }

  priceDropToolTip(): void {
    this.showPriceDropT = !this.showPriceDropT;
  }

  todaysDealsTooltip(): void {
    this.showTodaysDealtT = !this.showTodaysDealtT;
  }

  justLaunchedTooltip(): void {
    this.showjustLaunchedT = !this.showjustLaunchedT;
  }

  refurbishedTooltip(): void {
    this.showRefurbishedT = !this.showRefurbishedT;
  }

  itemMOQTooltip(): void {
    this.showItemMOQ = !this.showItemMOQ;
  }

  itemUnitPriceTooltip(): void {
    this.showUnitPrice = !this.showUnitPrice;
  }

  availableT(): void {
    this.showAvailableT = !this.showAvailableT;
  }

  exwTooltip(): void {
    this.showExw = !this.showExw;
  }

  itemMXQTooltip(v): void {
    switch (v) {
      case 1: this.showItemMXQ = !this.showItemMXQ; break;
      case 2: this.showInputMXQ = !this.showInputMXQ; break;
    }
  }

  /**
   * @returns boolean
   */
  checkMoreThanEnRoute(): boolean {
    return this.sessionService.isBuyer && this.item.inventory.enRoute >= 300 && this.item.inventory.onHand === 0;
  }

  /**
   * @returns string
   */
  calculateInventoryEnRoute(): string {
    if (this.sessionService.isBuyer) {
      if (this.item.inventory.enRoute + this.item.inventory.onHand > 300) {
        return (300 - this.item.inventory.onHand).toString() + '+';
      } else {
        return (this.item.inventory.enRoute).toString() + '';
      }
    } else {
      return (this.item.inventory.enRoute).toString() + '';
    }
  }

  submitNotification(event:any): void {
    if (this.topService.loading) {
      return ;
    }
    if (this.userService.isGuest) {
      this.userService.guestPop();
      return;
    }
    this.topService.isSearchActive = false;
    this.item.notificationInfoObj.waitlist = !this.item.notificationInfoObj.waitlist;
    if (this.item.notificationInfoObj.waitlist !== this.item.notificationInfoObj.waitlistInitialValue) {
      this.openWaitlistTooltip(event);
      const action = this.item.notificationInfoObj.waitlist ? eNotificationAction.add : eNotificationAction.remove;
      const type = NotificationTypesEnum.waitlist;
      this.notifyMe(type, action, this.item.id);
    }
  }

  notifyMe(type, action, itemId) {
    this.notifCenterBuyer.notifyMe(type, action, itemId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          if (this.item.notificationInfoObj.waitlist) {
            this.item.notificationInfoObj.waitlistInitialValue = true;
            this.item.notificationInfo.push(NotificationTypesEnum.waitlist);
          } else {
            this.item.notificationInfoObj.waitlistInitialValue = false;
            this.item.notificationInfo = this.item.notificationInfo.filter(x => x !== NotificationTypesEnum.waitlist);
          }
          this.cdr.markForCheck();
        },
        (err) => {
          this.item.notificationInfoObj.waitlist = this.item.notificationInfoObj.waitlistInitialValue ? true : false;
          this.cdr.markForCheck();
        }
      );
  }

  openNotificationTooltip(event: any): void {
    event.preventDefault();
    this.topService.notificationTooltipHovered = true;
    const item = this.item;
    this.notificationTooltipEmitter.emit({item,event});
    event.stopPropagation();
  }

  openWaitlistTooltip(event: any): void {
    event.preventDefault();
    const isShowWaitlist = true;
    const item = this.item;
    this.waitlistTooltipEmitter.emit({item,event,isShowWaitlist});
    event.stopPropagation();
  }

  hideWaitlistTooltip(event: any): void {
    event.preventDefault();
    const isShowWaitlist = false;
    const item = this.item;
    this.waitlistTooltipEmitter.emit({item,event,isShowWaitlist});
    event.stopPropagation();
  }

  removeItemFromCart(event: MouseEvent): void {
    event.stopPropagation();

    if(this.deleteSpinner){
      return;
    }

    this.deleteSpinner = true;
    this.cartService.deleteFromCart(this.findCartItem()).subscribe(
      (data) => {
        this.cartService.cartList = data;

        if (this.item.moq && this.item.moq > 1) {
          this.item.qty = this.item.moq;
        } else {
          this.item.qty = 1;
        }
        this.item.previousQuantityAdded = this.item.qty;
        this.sharedService.itemNewPrice = this.item;

        
        this.cartService.updateCartItemData(this.item);
        this.item.added = false;
        this.item.beingEdited = false;
        this.item.inCart = false;
        this.deleteSpinner = false;
        if (this.openItemDetails === true) {
          this.closeModal(false);
        }
        this.sharedService.itemNewPrice = this.item;
      },
      (err) => {
        if (err.status === 400) {
          this.prepareErrorMessages(err.error);
          return;
        } else {
          this.sharedService.handleBuyerHttpError(err, this.itemListErrorModal, true);
        }
        this.deleteSpinner = false;
      }
    );
  }

  mapNotificationInfo() {
    this.item.notificationInfoObj = {
      priceDrop: false,
      moreInventory: false,
      waitlist: false,
      priceDropInitialValue: false,
      moreInventoryInitialValue: false,
      waitlistInitialValue: false
    };

    if (this.item.notificationInfo) {
      if (this.item.notificationInfo.includes(NotificationTypesEnum.priceDrop)) {
        this.item.notificationInfoObj.priceDrop = true;
        this.item.notificationInfoObj.priceDropInitialValue = true;
      }
      if (this.item.notificationInfo.includes(NotificationTypesEnum.moreInventory)) {
        this.item.notificationInfoObj.moreInventory = true;
        this.item.notificationInfoObj.moreInventoryInitialValue = true;
      }
      if (this.item.notificationInfo.includes(NotificationTypesEnum.waitlist)) {
        this.item.notificationInfoObj.waitlist = true;
        this.item.notificationInfoObj.waitlistInitialValue = true;
      }
    }
  }


  showGuestLogin() {
    if (this.userService.isGuest) {
      this.userService.guestPop();
    }
  }

  ngOnDestroy(){
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  detectChangesInTemplate(): void {
    this.cdr.markForCheck();
  }

}
