
<div class="notification-item" (click)="openPopup()" [style.cursor]="(activeTab !== tabsEnum.archive) ? 'pointer' : 'default'" [class.purple]="isSelected" [class.dashboard]="isFromDashboard">
	<div class="notification-item-row">
		<div class="notification-item-select" *ngIf="!isFromDashboard" (click)="$event.stopPropagation()">
			<label class="notification-checkbox">
				<input type="checkbox" [(checked)]="notification.isSelected" (change)="onSelect()">
				<i class="_icon"></i>
			</label>
		</div>
		<div class="notification-item-description">
			<div class="logo">
				<div class="logo-img" [style.background-image]="'url(https://cdn.hubx.com' + notification.vendorItem.manufacturerLogoUrl + ')'"></div>
				<div class="item-date">
					{{ notification.lastNotificationDate | date: 'mediumDate' }}
				</div>
			</div>
			<div class="description">
				<p> {{ notification.vendorItem.description }} -
					<span class="copy-number" (mouseover)="copyState()" (click)=copyText($event,notification.vendorItem.vendorItemCode)>
						{{ notification.vendorItem.vendorItemCode }}
						<span ngModel="copied" ngDefaultControl>{{ copied }}</span>
					</span>
				</p>
			</div>
		</div>
		<div class="notification-item-boxes">
			<div class="notification _price-drop" [ngClass]="(activeTab === tabsEnum.archive) ? 'col-auto' : 'col' "
			[class.new-notification]="notification.mappedNotDetails.priceDrop.newCount > 0"
			[class.is-active-tab]="showColumn(tabsEnum.dropPrice)"
			>
				<div class="row" [class.transparent]="!showColumn(tabsEnum.dropPrice)">
					<div class="col-auto notification-icon">
						<img width="64" height="64" src="assets/images/notification/item-price-drop.svg">
					</div>
					<div class="col">
						<div class="qty">
							{{ notification.mappedNotDetails.priceDrop.seenCount }}
						</div>
						<div class="notification-name">
							Buyers waiting for price drop
						</div>
					</div>
				</div>
				<span class="qty-new" *ngIf="notification.mappedNotDetails.priceDrop.newCount > 0">
					+{{ notification.mappedNotDetails.priceDrop.newCount }}
				</span>
			</div>
			<div class="notification _inventrory" [ngClass]="(activeTab === tabsEnum.archive) ? 'col-auto' : 'col' "
			[class.new-notification]="notification.mappedNotDetails.waitlist.newCount > 0"
			[class.is-active-tab]="showColumn(tabsEnum.waitList)">
				<div class="row" [class.transparent]="!showColumn(tabsEnum.waitList)">
					<div class="col-auto notification-icon">
						<img width="64" height="64" src="assets/images/notification/back-on.svg">
					</div>
					<div class="col">
						<div class="qty">
							{{ notification.mappedNotDetails.waitlist.seenCount }}
						</div>
						<div class="notification-name">
							Buyers waiting for inventory
						</div>
					</div>
				</div>
				<span class="qty-new" *ngIf="notification.mappedNotDetails.waitlist.newCount > 0">
					+{{ notification.mappedNotDetails.waitlist.newCount }}
				</span>
			</div>
			<div class="notification _more-inventrory col" [ngClass]="{'_last-col': activeTab === tabsEnum.archive || isFromDashboard}"
			[class.new-notification]="notification.mappedNotDetails.moreInventory.newCount > 0"
			[class.is-active-tab]="showColumn(tabsEnum.moreInventory)">
				<div class="row" [class.transparent]="!showColumn(tabsEnum.moreInventory)">
					<div class="col-auto notification-icon">
						<img width="64" height="64" src="assets/images/notification/item-more-inventory.svg">
					</div>
					<div class="col">
						<div class="qty">
							{{ notification.mappedNotDetails.moreInventory.seenCount }}
						</div>
						<div class="notification-name">
							Buyers waiting for more inventory
						</div>
					</div>
				</div>
				<span class="qty-new" *ngIf="notification.mappedNotDetails.moreInventory.newCount > 0">
					+{{ notification.mappedNotDetails.moreInventory.newCount }}
				</span>
			</div>
			<ng-container *ngIf="!isFromDashboard">
				<div class="notification-action" *ngIf="showColumn(tabsEnum.archive)">
					<div class="notification-action-row">
						<div class="current">
							<div class="current-value-name">Current Price</div>
							<div class="current-value"> {{notification.vendorItem.cost | CustomCurrency }}  </div>
						</div>
						<div class="current">
							<div class="current-value-name">Current Inventory</div>
							<div class="current-value"> {{ notification.vendorItem.inventoryInfo.currentInventory.availableToSell }} </div>
						</div>
						<div class="archive-controls">
							<a role="button" class="archive-controls__btn" (click)="showConfirm($event)">
								<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<g fill="#EB3B44" fill-rule="nonzero">
											<polygon points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"></polygon>
										</g>
									</g>
								</svg>
							</a>
							<div class="archive-controls-tooltip" [class._confirm-visible]="isConfirmVisibleAnimate" (click)="onTooltipClick($event)">
								<div *ngIf="!isConfirmVisible" class="archive-controls-tooltip__text">Archive</div>
								<div *ngIf="isConfirmVisible" class="archive-controls-tooltip-confirm" (clickOutside)="hideConfirm()">
									<div class="archive-controls-tooltip-confirm__text">Are you sure you<br/>want to archive?</div>
									<div class="archive-controls-tooltip-confirm__buttons">
										<button type="button" class="_cancel" (click)="hideConfirm($event)">Cancel</button>
										<button type="button" class="_yes" (click)="archiveNotification($event)">Yes</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="notification-action-row">
						<a class="notify-buyers" role="button" (click)="openPopup()">
							<span>Update & Notify Buyers</span>
						</a>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
