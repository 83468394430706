import { OfferEventTypes } from "../../enums/offer-types";

class BaseEventMetadata {
  goodFor?: number;
  quantity?: number;
  price?: number;
  isFinal?: boolean;
  effectivePrice?: number;
}

export class OfferMetadata extends BaseEventMetadata {
  mainEventType?: OfferEventTypes;
  parentEvent: {
    goodFor: number,
    quantity: number,
    price: number,
    isFinal: boolean,
    eventId: string,
    eventType: OfferEventTypes
  };
  salesOrder?: OfferOrder; 
  purchaseOrder?: OfferOrder;
  referenceEventId?: string;
}
interface OfferOrder {
  documentId?: number;
  documentNumber?: number;
}
