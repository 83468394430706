<div class="details" [hidden]="openMobileInfo !== item.id">
	<div *ngFor="let attribute of getVisibleAttributes()">
	  <div *ngIf="isSingleIconShown(attribute)">
	    <img *ngIf="attribute.label == 'Warranty'" src="assets/images/icons/Warranty.svg" />
	    <img *ngIf="attribute.label == 'Packaging'" src="assets/images/icons/Packaging.svg" />
	    <img *ngIf="attribute.label == 'Condition'" src="assets/images/icons/Condition.svg" />
	    <img *ngIf="attribute.label == 'Restrictions'" src="assets/images/icons/Restrictions.svg" />
	    <span class="attribute-label">{{ attribute.label | currentDeal }}</span>
	    <span class="attribute-value">{{ attribute.value }}</span>
	  </div>
	</div>
	<div *ngIf="item.leadTimeDays">
			<img src="assets/images/icons/eta.svg"/>
			<span class="attribute-label eta-m">Lead time </span>
			<span class="attribute-value eta-m-v">{{ item.leadTimeDays }} Days</span>
		</div>
</div>
